import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
const { Option } = Select;

const UpdateDocument = ({
  isUpdateDocumentModalVisible,
  setIsUpdateDocumentModalVisible,
  documentRecord,
  workflowRecord,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const updateDocumentSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
      documentType: value.item,
    };
    dispatch({
      type: "UPDATE_DOCUMENT_REQUEST",
      payload: {
        id: documentRecord.id,
        formData,
        workflowId: workflowRecord.id,
      },
    });
    // form.resetFields();
    setIsUpdateDocumentModalVisible(false);
  };
  console.log("Document record", documentRecord);
  return (
    <Modal
      title="Update Document Checklist"
      open={isUpdateDocumentModalVisible}
      onCancel={() => {
        setIsUpdateDocumentModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={updateDocumentSubmitHandler}
        colon={true}
        form={form}
        fields={[
          {
            name: ["item"],
            value: documentRecord.item,
          },
          {
            name: ["documentSize"],
            value: documentRecord.documentSize,
          },
          {
            name: ["documentType"],
            value: documentRecord.documentType,
          },
        ]}
      >
        <Form.Item
          label="Document Name"
          name={"item"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter a item!",
            },
          ]}
        >
          <Input placeholder="Item" />
        </Form.Item>
        <Form.Item
          label="Document Size (in KB)"
          name={"documentSize"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter a document size!",
            },
          ]}
        >
          <Input type="number" placeholder="Document Size" />
        </Form.Item>
        {/* <Form.Item
          label="Document Type"
          name={"documentType"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter document type",
            },
          ]}
        >
          <Select>
            <Option value={"PORTABLE_DOCUMENT_FORMAT"}>
              Portable Document Format
            </Option>
            <Option value="WORD">Word</Option>
            <Option value="COMMA_SEPERATED_VALUE">Comma Seperated Value</Option>
            <Option value="EXCEL">Excel</Option>
            <Option value="IMAGE">Image</Option>
          </Select>
        </Form.Item> */}
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Update Document
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateDocument;
