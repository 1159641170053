import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Card, Form, Input, Modal } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import OrganizationalSettings from "../OrganizationalSettings";
import { formattedDate, formattedDateTime } from "../../../HelperFunction";
import { useNavigate } from "react-router-dom";
const { confirm } = Modal;

const Import = () => {
  const store = useSelector((state) => {
    return {
      generalState: state.OrganizationSettingsReducer,
      profileState: state.ProfileReducer,
    };
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  useEffect(() => {
    dispatch({
      type: "FETCH_REMINDER_EXIPRY_WORKING_HOUR_REQUEST",
      isSuccessFn: true,
      successFunction: successFunction,
    });
    dispatch({ type: "FETCH_PROFILE_REQUEST" });
  }, []);
  const successFunction = (response) => {
    console.log("response from reminder expiry date", response);
    setGeneralSettingsData((previousData) => {
      return {
        ...previousData,
        branchImported: response?.data?.branchImported,
        adminInstituteImported: response?.data?.adminInstituteImported,
      };
    });
  };
  const [generalSettingsData, setGeneralSettingsData] = useState({
    adminInstituteImported: false,
    branchImported: false,
  });

  console.log("general setting state", store.generalState?.isImportedOnce);

  return (
    <OrganizationalSettings>
      <div>
        <h3>Import Settings</h3>
        <Card>
          {generalSettingsData?.branchImported === false &&
            store.profileState.profile?.isBranch === true && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "0.5rem",
                }}
              >
                <span>
                  Would you like to import all dropdown data from head office?{" "}
                </span>
                <Button
                  type="primary"
                  style={{ borderRadius: "8px" }}
                  onClick={() => {
                    confirm({
                      title: "Do you want to import now?",
                      icon: <ExclamationCircleFilled />,
                      okText: "Import",
                      cancelText: "Cancel",
                      onOk() {
                        dispatch({
                          type: "IMPORT_DROPDOWN_DATA_FROM_HEAD_OFFICE_REQUEST",
                        });
                      },
                      onCancel() {
                        console.log("Cancel");
                      },
                    });
                  }}
                >
                  Import
                </Button>
              </div>
            )}
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <div className="flexColumnwithoutStyle" style={{ gap: "0.4rem" }}>
                Would you like to import all institute data with associated
                courses and country from admin?
                {store.generalState?.reminderExpiryWorkingHour
                  ?.adminDataLastImportedOn && (
                  <div>
                    <span className="greenTag">
                      Last imported on:{" "}
                      {store.generalState?.reminderExpiryWorkingHour
                        ?.adminDataLastImportedOn
                        ? formattedDate(
                            store.generalState?.reminderExpiryWorkingHour
                              ?.adminDataLastImportedOn
                          )
                        : null}
                    </span>
                  </div>
                )}
              </div>
              <Button
                type="primary"
                style={{ borderRadius: "8px" }}
                onClick={() => {
                  confirm({
                    title: "Do you want to import now?",
                    icon: <ExclamationCircleFilled />,
                    okText: "Import",
                    cancelText: "Cancel",
                    onOk() {
                      dispatch({
                        type: "IMPORT_INSTITUTE_DATA_FROM_ADMIN_REQUEST",
                      });
                    },
                    onCancel() {
                      console.log("Cancel");
                    },
                  });
                }}
              >
                Import
              </Button>
            </div>

            <div>
              <Button onClick={() => navigate("/institute")}>
                View Institute
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </OrganizationalSettings>
  );
};

export default Import;
