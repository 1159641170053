import {
  Card,
  Divider,
  Drawer,
  Image,
  Modal,
  Input,
  Tooltip,
  Checkbox,
  Button,
  Table,
  Space,
  Tag,
} from "antd";
import React, { useEffect } from "react";
import client from "../../Assets/client.png";
import {
  AiOutlineEye,
  AiOutlineMail,
  AiOutlinePhone,
  AiOutlineSend,
} from "react-icons/ai";
import { FcDocument } from "react-icons/fc";
import { IoEnterSharp } from "react-icons/io5";
import { GoLocation } from "react-icons/go";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CoursesDetail from "../Courses/CoursesDetail";
import { MdOutlineVerticalAlignCenter } from "react-icons/md";
import { ImCompass } from "react-icons/im";
import AddVisaCases from "../Visas/AddVisas";
import { BsChatLeftText } from "react-icons/bs";
import SendComposedMail from "../Mailbox/SendComposedMail";
const { Meta } = Card;
const columns = [
  {
    title: "Course Name",
    dataIndex: "name",
    width: 100,
    align: "center",
  },
  {
    title: "Degree",
    dataIndex: "degree",
    width: 100,
    align: "center",
  },
  {
    title: "Course Duration",
    dataIndex: "courseDuration",
    width: 100,
    align: "center",
  },

  {
    title: "Institute Name",
    dataIndex: "institute",
    width: 250,
    align: "center",
  },
  {
    title: "Onshore Fee",
    dataIndex: "onshoreFee",
    width: 250,
    align: "center",
  },
  {
    title: "Offshore Fee",
    dataIndex: "offshoreFee",
    width: 250,
    align: "center",
  },
  {
    title: "Intake/s",
    dataIndex: "intake",
    width: 250,
    align: "center",
  },

  {
    title: "Status",
    dataIndex: "isActive",
    width: 250,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const CollegeFinderInstituteDescription = ({
  fromCounselling,
  record,
  recordData,
  isInstituteDetailsModelVisible,
  setIsInstituteDetailsModelVisible,
  setIsStartCounsellingModalVisible,
}) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => {
    return { leadState: state.LeadReducer, clientState: state.ClientsReducer };
  });
  const [isCourseDetailsModelVisible, setIsCourseDetailsModelVisible] =
    useState(false);
  const [isAddVisasModalVisible, setIsAddVisasModalVisible] = useState(false);
  const [courseRecord, setCourseRecord] = useState({});
  console.table(courseRecord, "tung tung");
  const [current, setCurrent] = useState("description");
  const [enrollType, setEnrollType] = useState("ONSHORE");
  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);
  const clientDetailsTabList = [
    {
      key: "description",
      tab: "Description",
    },
    {
      key: "courses",
      tab: "Courses",
    },
    {
      key: "document",
      tab: "Forms and Documents",
    },
  ];
  const data = record?.filterResponse?.availableCourseList?.data?.map(
    (dataObj) => {
      console.log("courses map", dataObj);
      return {
        key: dataObj.id,
        name: (
          <Tooltip title="Course Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setCourseRecord(dataObj);
                  setIsCourseDetailsModelVisible(true);
                }}
              >
                {dataObj.name ?? "N/A"}
              </div>
            </a>
          </Tooltip>
        ),
        degree: dataObj?.name?.degree,
        courseDuration:
          dataObj.courseDuration + " " + dataObj.courseAccordingTo,
        institute: dataObj.institute?.name ?? "N/A",
        onshoreFee: dataObj.onShoreFee ?? "N/A",
        offshoreFee: dataObj.offShoreFee ?? "N/A",
        intake: dataObj?.collegeIntakeResponseList?.map((dataObj) => {
          return (
            <Tag key={dataObj.id} color="blue">
              {dataObj.intakeSessionName}
            </Tag>
          );
        }),
        degree: dataObj.degree?.name,
        isActive: dataObj.isActive ? (
          <p className="greenTag">ACTIVE</p>
        ) : (
          <p className="redTag">INACTIVE</p>
        ),
        actions: (
          <Space size="middle">
            {/* <Tooltip title="Update Course">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsUpdateCourseModelVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip> */}
            {/* <Tooltip title="Update Course">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsUpdateCourseModelVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
              <Tooltip title="Delete Course">
                <Popconfirm
                  title="Are you sure to delete this course?"
                  onConfirm={() => {
                    dispatch({
                      type: "DELETE_COURSE_REQUEST",
                      payload: { id: dataObj.id },
                    });
                  }}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <a>
                    <div className="bordered">
                      <DeleteOutlined />
                    </div>
                  </a>{" "}
                </Popconfirm>
              </Tooltip>

              <Tooltip title="Toggle course status">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus = dataObj.isActive
                        ? "inactive"
                        : "active";
                      dispatch({
                        type: "TOGGLE_COURSE_STATUS_REQUEST",
                        payload: { id: dataObj.id, actionStatus },
                      });
                    }}
                  >
                    <MdOutlineDisabledVisible />
                  </div>
                </a>
              </Tooltip>
              <Tooltip title="Offshore Checklist">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsOffshoreChecklistModelVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <AiOutlineFullscreen />
                  </div>
                </a>
              </Tooltip>
              <Tooltip title="Onshore Checklist">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsOnshoreChecklistModelVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <AiOutlineFullscreenExit />
                  </div>
                </a>
              </Tooltip>
              <Tooltip title="Course Details">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsCourseDetailsModelVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <AiOutlineEye />
                  </div>
                </a>
              </Tooltip> */}
            <Tooltip title="Course Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setCourseRecord(dataObj);
                    setIsCourseDetailsModelVisible(true);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>

            {dataObj.both && (
              <>
                <Tooltip title="Onshore Enroll">
                  <a>
                    <div
                      className="bordered"
                      onClick={() => {
                        setEnrollType("ONSHORE");
                        setCourseRecord(dataObj);
                        setIsAddVisasModalVisible(true);
                      }}
                    >
                      <AiOutlineSend />
                    </div>
                  </a>
                </Tooltip>
                <Tooltip title="Offshore Enroll">
                  <a>
                    <div
                      className="bordered"
                      onClick={() => {
                        setEnrollType("OFFSHORE");

                        setCourseRecord(dataObj);
                        setIsAddVisasModalVisible(true);
                      }}
                    >
                      <ImCompass />
                    </div>
                  </a>
                </Tooltip>
              </>
            )}
            {dataObj.onshore && (
              <>
                <Tooltip title="Onshore Enroll">
                  <a>
                    <div
                      className="bordered"
                      onClick={() => {
                        setEnrollType("ONSHORE");

                        setCourseRecord(dataObj);
                        setIsAddVisasModalVisible(true);
                      }}
                    >
                      <AiOutlineSend />
                    </div>
                  </a>
                </Tooltip>
              </>
            )}
            {dataObj.offshore && (
              <>
                <Tooltip title="Offshore Enroll">
                  <a>
                    <div
                      className="bordered"
                      onClick={() => {
                        setEnrollType("OFFSHORE");

                        setCourseRecord(dataObj);
                        setIsAddVisasModalVisible(true);
                      }}
                    >
                      <ImCompass />
                    </div>
                  </a>
                </Tooltip>
              </>
            )}
          </Space>
        ),
      };
    }
  );

  return (
    <Drawer
      title="Institute Details"
      open={isInstituteDetailsModelVisible}
      onClose={() => {
        console.log("click");
        setIsInstituteDetailsModelVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Card style={{ borderRadius: "0.5rem" }} className="drawerStyle">
        <div className="flexRowWithoutStyle" style={{ gap: "1rem" }}>
          {record?.image ? (
            <Image src={record?.image} className={"clientImage"} />
          ) : (
            <Image src={client} className={"clientImage"} />
          )}
          <div
            className="flexColumnWithoutStyle"
            style={{
              justifyContent: "flex-start",
              width: "100%",
              gap: "0.5rem",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: "bold" }}>
              {record?.instituteName ? record?.instituteName : "N/A"}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.4rem", color: "#aaa" }}
            >
              <span className="flexRowWithGap">
                <AiOutlinePhone
                  style={{
                    fontSize: "20",
                  }}
                />{" "}
                {record?.contact ? record?.contact : "N/A"}
              </span>
              <span className="flexRowWithGap">
                <GoLocation
                  style={{
                    fontSize: "16",
                  }}
                />{" "}
                {record?.location ? record?.location : "N/A"}
              </span>
              <span className="flexRowWithGap">
                <AiOutlineMail
                  style={{
                    fontSize: "16",
                  }}
                />{" "}
                {record?.email ? record?.email : "N/A"}
              </span>
            </div>

            <div
              className="flexRowWithoutStyle"
              style={{ width: "65%", gap: "1rem", padding: "1rem" }}
            >
              <h4>{record?.country?.name}</h4>
              <div>{record?.levelOfCollege?.name}</div>
              <div>{record?.crisco}</div>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{
                gap: "0.5rem",
                alignSelf: "flex-start",
                flexWrap: "wrap",
              }}
            >
              <button
                className="emailAndMessage"
                // onClick={() => {
                //   // setIsSendComposedEmailModalVisible(true);
                // }}
              >
                <BsChatLeftText style={{ fontSize: "10px" }} />
                <span>Message</span>
              </button>
              <button
                className="emailAndMessage"
                onClick={() => {
                  setIsSendComposedEmailModalVisible(true);
                }}
              >
                <AiOutlineMail />
                <span>Email</span>
              </button>
            </div>
          </div>
        </div>
        <Card
          bordered={false}
          tabList={clientDetailsTabList}
          activeTabKey={current}
          onTabChange={(key) => {
            setCurrent(key);
          }}
        >
          {current === "document" && (
            <>
              {record?.documents?.map((dataObj) => {
                return (
                  <div key={dataObj.id}>
                    <a
                      href={dataObj?.documentUrl}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "1px",
                        flexWrap: "wrap",
                      }}
                    >
                      <FcDocument style={{ fontSize: "30px" }} />
                      <span>{dataObj?.documentName}</span>
                    </a>
                    <Divider
                      orientationMargin="0"
                      style={{ margin: "0.5rem" }}
                    />
                  </div>
                );
              })}
            </>
          )}
          {current === "description" && <div>{record?.description}</div>}
          {current === "formToDownload" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1px",
                flexWrap: "wrap",
              }}
            >
              {record?.customer?.experiences?.map((qualification) => {
                return (
                  <>
                    <Card
                      title={qualification?.experience}
                      key={qualification.id}
                      style={{ width: 300 }}
                    ></Card>
                  </>
                );
              })}
            </div>
          )}
          {current === "courses" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1px",
              }}
            >
              <div style={{ marginTop: "0rem" }}>
                <Table bordered columns={columns} dataSource={data} />
              </div>
            </div>
          )}
        </Card>
      </Card>
      {isCourseDetailsModelVisible && (
        <CoursesDetail
          record={courseRecord}
          fromCounselling={fromCounselling}
          recordData={recordData}
          instituteRecordForCountry={record}
          isCourseDetailsModelVisible={isCourseDetailsModelVisible}
          setIsCourseDetailsModelVisible={setIsCourseDetailsModelVisible}
          setIsStartCounsellingModalVisible={setIsStartCounsellingModalVisible}
        />
      )}
      {isAddVisasModalVisible && (
        <AddVisaCases
          navigateCaseList={true}
          fromCounselling={fromCounselling}
          recordData={recordData}
          courseRecord={courseRecord}
          instituteRecordForCountry={record}
          enrollType={enrollType}
          isAddVisaCasesModalVisible={isAddVisasModalVisible}
          setIsAddVisaCasesModalVisible={setIsAddVisasModalVisible}
          setIsStartCounsellingModalVisible={setIsStartCounsellingModalVisible}
        />
      )}

      {isSendComposedEmailModalVisible && (
        <SendComposedMail
          composeMailRecord={{
            toUser: [store?.leadState?.leadById?.email],
          }}
          isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
          setIsSendComposedEmailModalVisible={
            setIsSendComposedEmailModalVisible
          }
        />
      )}
    </Drawer>
  );
};

export default CollegeFinderInstituteDescription;
