import React, { useEffect } from "react";
import { Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import logoImage from "./../../Assets/khrouch3.png";
import CarouselComponent from "../Login/CarouselComponent";

const ForgotPassword = () => {
  const forgotPasswordState = useSelector(
    (state) => state.PasswordResetReducer
  );
  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    dispatch({ type: "CLEAR_FORGOT_PASSWORD_DATA_REQUEST" });
  }, []);
  const forgotPasswordEmailHandler = (value) => {
    dispatch({
      type: "FORGOT_PASSWORD_CODE_BY_COMPANY_URL_REQUEST",
      payload: { value, companyDomainOrURL: params?.id },
    });
    // dispatch({ type: "FORGOT_PASSWORD_CODE_REQUEST", payload: value });
  };
  return (
    <>
      {forgotPasswordState.isOtpSend ? (
        <Navigate
          to={`/password_reset_otp_verification/${params?.id}`}
          replace={true}
        />
      ) : (
        <div style={{ display: "flex" }}>
          <div className="loginContainer">
            <div className="formContainer">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={logoImage} style={{ width: "15%" }} />
              </div>
              <h2 style={{ textAlign: "center" }}>Forgot Password!</h2>
              <p style={{ textAlign: "center", fontSize: "16px" }}>
                Please enter your email address or phone to request a password
                email.
              </p>
              <Form
                layout="vertical"
                className="loginForm"
                onFinish={forgotPasswordEmailHandler}
              >
                <Form.Item
                  label="Email Address"
                  name={"email"}
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Please enter email!",
                    },
                  ]}
                >
                  <Input
                    placeholder="e.g: email@domainname.com"
                    size="large"
                    style={{ borderRadius: "25px" }}
                  />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 9 }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ borderRadius: "2rem" }}
                  >
                    SEND CODE
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
          <div style={{ width: "30%" }}>
            <CarouselComponent />
          </div>
        </div>
      )}
    </>
  );
};

export default ForgotPassword;
