import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Space,
  TimePicker,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { date, dateAndTime, getTimeZone, YYYYMMDD } from "../../HelperFunction";
import AddLeadSource from "../Leads/LeadSource/AddLeadSource";
import AddCountry from "../Leads/Country/AddCountry";
import AddConsultant from "../Leads/Consultant/AddConsultant";
import moment from "moment";
import { AiOutlinePlus } from "react-icons/ai";
import AddContacts from "../Contacts/AddContacts";
import Purpose from "../Leads/Purpose/Purpose";
import AddSalesRep from "../Visas/AddSalesRep";
const { Option } = Select;
const { TextArea } = Input;

const UpdateAppointment = ({
  record,
  isUpdateAppointmentModelVisible,
  setIsUpdateAppointmentModelVisible,
}) => {
  const store = useSelector((state) => {
    return {
      timingDropdownState: state.AppointmentsReducer,
      addAppointmentState: state.LeadReducer,
    };
  });
  const [isContactType, setIsContactType] = useState(true);
  const [appointmentData, setAppointmentData] = useState({
    sendByEmail: false,
    note: record?.note,
    reminderTime: record?.reminderDateAndTime ?? "",
    reminderDate: record?.reminderDateAndTime ?? "",
    purposeName: record?.purpose?.purposeName,
    purposeId: record?.purpose?.id,
    leadSourceId: record?.leadResponse?.leadSourceId,
    leadSourceName: record?.leadResponse?.leadSourceName,
    sessionTime: record?.counsellingBooking?.sessionTime,
    countryOfResidence:
      record?.leadResponse?.customerResponse?.countryOfResidence,
    // counsellor: record?.assignedCounsellor?.fullName,
    counsellorId: record?.assignedCounsellor?.id,
    counsellorName: record?.assignedCounsellor?.fullName,
    appointmentDate: record?.appointmentDate,
    bookingStatus: record?.leadResponse?.assignCounsellor ?? false,
    country: record?.country?.id,
    email: record?.email,
    mobile: record?.mobile,
    lastName: record?.lastName,
    firstName: record?.firstName,
    customer: record?.leadResponse?.customerResponse?.id,
    ContactType: record?.ContactType,
    salesRepresentativeName:
      record !== undefined ? record?.salesRepresentative?.salesRepName : "",
    salesRepresentativeId:
      record !== undefined ? record?.salesRepresentative?.id : "",
    updateWeightage: record !== undefined ? record?.updateWeightage : "",
  });
  const updateAppointmentState = useSelector((state) => state.LeadReducer);
  const [isAddLeadSourceModalVisible, setIsAddLeadSourceModalVisible] =
    useState(false);
  const [isAddCountryModalVisible, setIsAddCountryModalVisible] =
    useState(false);
  const [isAddConsultantModalVisible, setIsAddConsultantModalVisible] =
    useState(false);
  const [isAddPurposeModalVisible, setIsAddPurposeModalVisible] =
    useState(false);
  const [isAddContactVisible, setIsAddContactVisible] = useState(false);
  const [isAddSalesRepModalVisible, setIsAddSalesRepModalVisible] =
    useState(false);
  const [isCouncelling, setIsCouncelling] = useState(false);

  const [counsellorId, setCounsellorId] = useState();
  const [appointmentDate, setAppointmentDate] = useState();
  const [sessionTime, setSessionTime] = useState();
  const [contactsData, setContactsData] = useState({});
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  useEffect(() => {
    if (isUpdateAppointmentModelVisible === true) {
      dispatch({ type: "FETCH_LEADS_DROPDOWN_REQUEST" });
    }
    setAppointmentData({
      sendByEmail: false,
      note: record?.note,
      reminderTime: record?.reminderDateAndTime ?? "",
      reminderDate: record?.reminderDateAndTime ?? "",
      leadSourceId: record?.leadResponse?.leadSourceId,
      leadSourceName: record?.leadResponse?.leadSourceName,
      purposeName: record?.purpose?.purposeName,
      purposeId: record?.purpose?.id,
      sessionTime: record?.counsellingBooking?.sessionTime,
      countryOfResidence:
        record?.leadResponse?.customerResponse?.countryOfResidence,

      // counsellor: record?.assignedCounsellor?.fullName,
      counsellorId: record?.assignedCounsellor?.id,
      counsellorName: record?.assignedCounsellor?.fullName,
      appointmentDate: record?.appointmentDate,
      bookingStatus: record?.leadResponse?.assignCounsellor ?? false,
      country: record?.country?.id,
      email: record?.email,
      mobile: record?.mobile,
      lastName: record?.lastName,
      firstName: record?.firstName,
      customer: record?.leadResponse?.customerResponse?.id,
      ContactType: record?.ContactType,
      salesRepresentativeName:
        record !== undefined ? record?.salesRepresentative?.salesRepName : "",
      salesRepresentativeId:
        record !== undefined ? record?.salesRepresentative?.id : "",
      updateWeightage: record !== undefined ? record?.updateWeightage : "",
    });
  }, [isUpdateAppointmentModelVisible]);
  useEffect(() => {
    if (
      appointmentData.counsellorId &&
      appointmentData.appointmentDate &&
      appointmentData.sessionTime &&
      appointmentData.bookingStatus
    ) {
      dispatch({
        type: "FETCH_TIMING_DROPDOWN_REQUEST",
        payload: {
          counsellorId: appointmentData.counsellorId,
          sessionTime: appointmentData.sessionTime,
          values: {
            appointmentDate: moment
              .utc(appointmentData.appointmentDate)
              .local()
              .format("YYYY-MM-DD"),
            timeOffset: getTimeZone(),
          },
        },
      });
    }
  }, [
    appointmentData.counsellorId,
    appointmentData.appointmentDate,
    appointmentData.sessionTime,
    appointmentData.bookingStatus,
  ]);
  const updateAppointmentSubmitHandler = (value) => {
    const reminderDateAndTime = dateAndTime(
      value.reminderDate,
      value.reminderTime
    );
    const bookingDate = value.appointmentDate
      ? date(value.appointmentDate)
      : null;
    const bookingDay = isCouncelling
      ? moment(value.appointmentDate).format("dddd").toUpperCase()
      : null;
    const appointmentDateTime = dateAndTime(
      value.appointmentDate,
      value.appointmentTime
    );
    const formData = {
      ...value,
      reminderDateAndTime,
      assignCounsellor: isCouncelling,
      appointmentDate: date(value.appointmentDate),
      consultantId: counsellorId,
      bookingDate,
      bookingDay: bookingDay,
      appointmentDateTime,
      salesRepresentativeId: record?.salesRepresentativeId
        ? record?.salesRepresentativeId
        : null,
      updateWeightage: record?.updateWeightage ? record?.updateWeightage : null,
    };
    dispatch({
      type: "UPDATE_APPOINTMENTS_REQUEST",
      payload: { id: record.id, values: formData },
    });

    dispatch({ type: "CLEAR_AVAILABLE_DATE_AND_TIME" });
    setContactsData(null);
    form.resetFields();
    setIsUpdateAppointmentModelVisible(false);
  };

  return (
    <Drawer
      title="Update Appointment"
      placement="right"
      open={isUpdateAppointmentModelVisible}
      onClose={() => {
        setIsUpdateAppointmentModelVisible(false);
      }}
      width={1163}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={updateAppointmentSubmitHandler}
        colon={true}
        form={form}
        fields={[
          {
            name: ["ContactType"],
            value: isContactType,
          },
          {
            name: ["customer"],
            value: appointmentData.customer,
          },
          {
            name: ["firstName"],
            value: contactsData?.firstName
              ? contactsData?.firstName
              : record?.firstName,
          },
          {
            name: ["lastName"],
            value: contactsData?.lastName
              ? contactsData?.lastName
              : record?.lastName,
          },
          {
            name: ["mobile"],
            value: appointmentData?.mobile,
          },
          {
            name: ["email"],
            value: appointmentData?.email,
          },
          {
            name: ["note"],
            value: appointmentData.note,
          },
          {
            name: ["sendByEmail"],
            value: appointmentData.sendByEmail,
          },
          {
            name: ["country"],
            value: appointmentData.country,
          },
          {
            name: ["countryOfResidence"],
            value: appointmentData.countryOfResidence,
          },
          {
            name: ["bookingStatus"],
            value: appointmentData.bookingStatus,
          },
          {
            name: ["appointmentDate"],
            value: appointmentData.appointmentDate
              ? moment.utc(appointmentData.appointmentDate).local()
              : "",
          },
          {
            name: ["counsellor"],
            value: appointmentData.counsellorId,
          },
          {
            name: ["sessionTime"],
            value: appointmentData.sessionTime,
          },
          {
            name: ["leadSourceId"],
            value: appointmentData.leadSourceId,
          },
          {
            name: ["purposeId"],
            value: appointmentData.purposeId,
          },
          {
            name: ["reminderDate"],
            value: appointmentData.reminderDate
              ? moment(appointmentData.reminderDate).local()
              : "",
          },
          {
            name: ["reminderTime"],
            value: appointmentData.reminderTime
              ? moment(appointmentData.reminderTime).local()
              : "",
          },
          {
            name: ["salesRepresentative"],
            value: appointmentData?.salesRepresentativeName,
          },
          {
            name: ["updateWeightage"],
            value: appointmentData?.updateWeightage,
          },
        ]}
        className="drawerStyle"
      >
        <Form.Item
          name={"ContactType"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please select client type",
            },
          ]}
        >
          <Radio.Group
            defaultValue={true}
            onChange={(e) => {
              setIsContactType(e.target.value);
            }}
          >
            <Radio value={true}>Existing Contact</Radio>
            <Radio value={false}>New Contact</Radio>
          </Radio.Group>
        </Form.Item>
        {isContactType ? (
          <div className="flexRowWithoutStyle" style={{ gap: "0.3rem" }}>
            <Form.Item
              label="Contact"
              name={"customer"}
              style={{ width: "100%" }}
            >
              <Select
                showSearch
                placeholder="Select a Contact"
                optionFilterProp="children"
                onSearch={(e) => {}}
                filterOption={(input, option) => {
                  return (option?.children?.props?.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {store.addAppointmentState?.leadsDropdown?.contacts?.map(
                  (dataObj) => {
                    return (
                      <Option key={dataObj.id} value={dataObj.id}>
                        <div
                          onClick={() => {
                            setContactsData(dataObj);
                            setAppointmentData((previouData) => {
                              return {
                                ...previouData,
                                customer: dataObj.id,
                                firstName: dataObj.firstName ?? "",
                                lastName: dataObj.lastName ?? "",
                                email: dataObj?.email,
                                mobile: dataObj?.phoneNumber,
                              };
                            });
                          }}
                          className={"selectOptionDiv"}
                        >
                          {`${dataObj.firstName ?? ""} ${
                            dataObj?.lastName ?? ""
                          } 
                            ${
                              dataObj.email !== null && dataObj.email !== ""
                                ? `(${dataObj.email})`
                                : dataObj.phoneNumber !== null &&
                                  dataObj.phoneNumber !== ""
                                ? `(${dataObj.phoneNumber ?? ""})`
                                : ""
                            }
                            `}
                        </div>
                      </Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddContactVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
        ) : null}

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="First Name"
            name={"firstName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter first name!",
              },
            ]}
          >
            <Input
              placeholder="First Name"
              onChange={(e) => {
                setAppointmentData((previouData) => {
                  return {
                    ...previouData,
                    firstName: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name={"lastName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter last name!",
              },
            ]}
          >
            <Input
              placeholder="Last Name"
              onChange={(e) => {
                setAppointmentData((previouData) => {
                  return {
                    ...previouData,
                    lastName: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Mobile Number"
            name={"mobile"}
            style={{ width: "100%" }}
            rules={[
              // {
              //   required: true,
              //   message: "Please enter mobile number",
              // },
              {
                pattern: /^[\d]{9,11}$/,
                message: "Mobile number should contain 9 to 11 numbers",
              },
            ]}
          >
            <Input
              type="number"
              placeholder="Mobile Number"
              onChange={(e) => {
                setAppointmentData((previouData) => {
                  return {
                    ...previouData,
                    mobile: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Email"
            name={"email"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     type: "email",
            //     message: "Please enter a valid email",
            //   },
            // ]}
          >
            <Input
              type="email"
              placeholder="e.g. name@domainname.com"
              onChange={(e) => {
                setAppointmentData((previouData) => {
                  return {
                    ...previouData,
                    email: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <div
            className="flexRowWithoutStyle"
            style={{ gap: "0.3rem", width: "100%" }}
          >
            <Form.Item
              label="Country to Study"
              name={"country"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter country!",
                },
              ]}
            >
              <Select>
                {store.addAppointmentState.leadsDropdown?.countries?.map(
                  (dataObj) => {
                    return (
                      <Option key={dataObj.id} value={dataObj.id}>
                        <div
                          onClick={(e) => {
                            setAppointmentData((previouData) => {
                              return {
                                ...previouData,
                                country: dataObj.id,
                              };
                            });
                          }}
                        >
                          {dataObj.countryName}
                        </div>
                      </Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddCountryModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{ gap: "0.3rem", width: "100%" }}
          >
            <Form.Item
              label="Country of Residence"
              name={"countryOfResidence"}
              style={{ width: "100%" }}
            >
              <Select>
                {store.addAppointmentState?.leadsDropdown?.countries?.map(
                  (dataObj) => {
                    return (
                      <Option key={dataObj.id} value={dataObj.countryName}>
                        <div
                          onClick={() => {
                            setAppointmentData((previouData) => {
                              return {
                                ...previouData,
                                countryOfResidence: dataObj.countryName,
                              };
                            });
                          }}
                          className={"selectOptionDiv"}
                        >
                          {dataObj.countryName}
                        </div>
                      </Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddCountryModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
        </div>
        {/* <Form.Item
          label="Want to confirm appointment now?"
          name={"bookingStatus"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please select a option!",
            },
          ]}
        >
          <Radio.Group
            onChange={(e) => {
              setIsCouncelling(e.target.value);
              setAppointmentData((previouData) => {
                return {
                  ...previouData,
                  bookingStatus: e.target.value,
                };
              });
            }}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item> */}
        {/* {appointmentData?.bookingStatus === false ? (
          <>
            <Form.Item
              label="Appointment Date"
              name={"appointmentDate"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter appointment date!",
                },
              ]}
            >
              <DatePicker
                onChange={(e) => {
                  setAppointmentData((previouData) => {
                    return {
                      ...previouData,
                      appointmentDate: e,
                    };
                  });
                }}
              />
            </Form.Item>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.3rem", width: "100%" }}
            >
              <Form.Item
                label="Counsellor"
                name={"counsellor"}
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please select counsellor!",
                //   },
                // ]}
              >
                <Select>
                  {store.addAppointmentState?.leadsDropdown?.consultantList?.map(
                    (dataObj) => {
                      return (
                        <Option key={dataObj.id} value={dataObj.id}>
                          <div
                            onClick={() => {
                              setCounsellorId(dataObj.id);
                              setAppointmentData((previouData) => {
                                return {
                                  ...previouData,
                                  counsellorId: dataObj.id,
                                  counsellorName: `${dataObj.firstName} ${dataObj.lastName}`,
                                };
                              });
                            }}
                          >
                            {dataObj.firstName} {dataObj.lastName}
                          </div>
                        </Option>
                      );
                    }
                  )}
                </Select>
              </Form.Item>
              <a
                className="plusButton"
                onClick={() => {
                  setIsAddConsultantModalVisible(true);
                }}
              >
                <AiOutlinePlus className="iconColor" />
              </a>
            </div>
          </>
        ) : (
          <>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.3rem", width: "100%" }}
              >
                <Form.Item
                  label="Counsellor"
                  name={"counsellor"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please select counsellor!",
                    },
                  ]}
                >
                  <Select>
                    {store.addAppointmentState?.leadsDropdown?.consultantList?.map(
                      (dataObj) => {
                        return (
                          <Option key={dataObj.id} value={dataObj.id}>
                            <div
                              onClick={() => {
                                setCounsellorId(dataObj.id);
                                setAppointmentData((previouData) => {
                                  return {
                                    ...previouData,
                                    counsellorId: dataObj.id,
                                    counsellorName: `${dataObj.firstName} ${dataObj.lastName}`,
                                  };
                                });
                              }}
                            >
                              {dataObj.firstName} {dataObj.lastName}
                            </div>
                          </Option>
                        );
                      }
                    )}
                  </Select>
                </Form.Item>
                <a
                  className="plusButton"
                  onClick={() => {
                    setIsAddConsultantModalVisible(true);
                  }}
                >
                  <AiOutlinePlus className="iconColor" />
                </a>
              </div>
              <Form.Item
                label="Booking Date"
                name={"appointmentDate"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please pick a booking date!",
                  },
                ]}
              >
                <DatePicker
                  format={"DD-MM-YYYY"}
                  disabledDate={(cd) => {
                    const d = new Date();
                    return cd < d;
                  }}
                  onChange={(e) => {
                    setAppointmentDate(YYYYMMDD(e));
                    setAppointmentData((previouData) => {
                      return {
                        ...previouData,
                        appointmentDate: e,
                      };
                    });
                  }}
                />
              </Form.Item>
            </div>
            <Form.Item
              label="Session Time"
              name={"sessionTime"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select a session time!",
                },
              ]}
            >
              <Select
                onChange={(e) => {
                  setSessionTime(e);
                  setAppointmentData((previouData) => {
                    return {
                      ...previouData,
                      sessionTime: e,
                    };
                  });
                }}
              >
                <Option value={"THIRTY_MINUTES"}>30 Minutes</Option>
                <Option value={"ONE_HOUR"}>1 Hour</Option>
                <Option value={"TWO_HOUR"}>2 Hour</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Available Time Slots"
              name={"startTime"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please pick a your time slot!",
                },
              ]}
            >
              <Radio.Group
                onChange={(e) => {
                }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {store.timingDropdownState &&
                  store.timingDropdownState.timingDropdown.map(
                    (dataObj, index) => {
                      return (
                        <div key={index}>
                          <Radio value={dataObj.startTime}>
                            {dataObj.startTime} - {dataObj.endTime}
                          </Radio>
                        </div>
                      );
                    }
                  )}
              </Radio.Group>
            </Form.Item>
          </>
        )} */}
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <div
            className="flexRowWithoutStyle"
            style={{ gap: "0.3rem", width: "100%" }}
          >
            <Form.Item
              label="Lead source"
              name={"leadSourceId"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select lead source!",
                },
              ]}
            >
              <Select>
                {store.addAppointmentState.leadsDropdown?.leadSource?.map(
                  (dataObj) => {
                    return (
                      <Option key={dataObj.id} value={dataObj.id}>
                        <div
                          onChange={(e) => {
                            setAppointmentData((previouData) => {
                              return {
                                ...previouData,
                                leadSourceId: dataObj.id,
                                leadSourceName: dataObj.leadSource,
                              };
                            });
                          }}
                        >
                          {dataObj.leadSource}
                        </div>
                      </Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddLeadSourceModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{ gap: "0.3rem", width: "100%" }}
          >
            <Form.Item
              label="Purpose"
              name={"purposeId"}
              style={{ width: "95%" }}
              rules={[
                {
                  required: true,
                  message: "Please select your purpose!",
                },
              ]}
            >
              <Select
              // defaultValue={
              //   store.leadState.leadsDropdown?.purposes?.[0].id
              // }
              >
                {store.addAppointmentState.leadsDropdown?.purposes?.map(
                  (dataObj) => {
                    return (
                      <Option key={dataObj.id} value={dataObj.id}>
                        <div
                          onClick={() => {
                            setAppointmentData((previouData) => {
                              return {
                                ...previouData,
                                purposeId: dataObj.id,
                                purposeName: dataObj.purposeName,
                              };
                            });
                          }}
                        >
                          {dataObj.purposeName}
                        </div>
                      </Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddPurposeModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
        </div>
        {/* <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Appointment Date"
            name={"appointmentDate"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please pick appointment date!",
            //   },
            // ]}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              disabledDate={(cd) => {
                const d = new Date();
                return cd < d;
              }}
            />
          </Form.Item>
          <Form.Item
            label="Appointment Time"
            name={"appointmentTime"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please pick a appointment time!",
            //   },
            // ]}
          >
            <TimePicker />
          </Form.Item>
        </div> */}
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <div
            className="flexRowWithoutStyle"
            style={{ gap: "0.3rem", width: "100%" }}
          >
            <Form.Item
              label="Sales Reps"
              name={"salesRepresentative"}
              style={{ width: "100%" }}
              // rules={[
              //   {
              //     required: true,
              //     message: "Please select Sales Reps",
              //   },
              // ]}
            >
              <Select>
                {store.addAppointmentState?.leadsDropdown?.salesRepresentativeResponse?.map(
                  (dataObj) => {
                    return (
                      <Option key={dataObj.id}>
                        <div
                          onClick={() => {
                            setAppointmentData((previouData) => {
                              return {
                                ...previouData,
                                salesRepresentativeId: dataObj.id,
                                salesRepresentativeName: dataObj.salesRepName,
                              };
                            });
                            // setSalesRepresentativeId(dataObj.id);
                            // setSalesRepresentativeName(dataObj.salesRepName);
                          }}
                        >
                          {dataObj.salesRepName}
                        </div>
                      </Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddSalesRepModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
          <Form.Item
            label="Weightage"
            name={"updateWeightage"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please select weightage",
            //   },
            // ]}
          >
            <Select>
              {store.addAppointmentState?.leadsDropdown?.weightage?.map(
                (dataObj, index) => {
                  return (
                    <Option key={dataObj}>
                      <div
                        onClick={(e) => {
                          setAppointmentData((previouData) => {
                            return {
                              ...previouData,
                              updateWeightage: dataObj,
                            };
                          });
                        }}
                      >
                        {dataObj}
                      </div>
                    </Option>
                  );
                }
              )}
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Reminder Date"
            name={"reminderDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick reminder date!",
              },
            ]}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              disabledDate={(cd) => {
                const d = new Date();
                return cd < d;
              }}
              onChange={(e) => {
                setAppointmentData((previouData) => {
                  return {
                    ...previouData,
                    reminderDate: e,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Reminder Time"
            name={"reminderTime"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a reminder time!",
              },
            ]}
          >
            <TimePicker
              onChange={(e) => {
                setAppointmentData((previouData) => {
                  return {
                    ...previouData,
                    reminderTime: e,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <Form.Item label="Note" name={"note"} style={{ width: "100%" }}>
          <TextArea
            placeholder="Write Something"
            onChange={(e) => {
              setAppointmentData((previouData) => {
                return {
                  ...previouData,
                  note: e.target.value,
                };
              });
            }}
          />
        </Form.Item>
        {appointmentData.email === null ||
          (appointmentData.email === "" && (
            <Form.Item name={"sendByEmail"} valuePropName="checked">
              <Checkbox
                onChange={(e) => {
                  setAppointmentData((previouData) => {
                    return {
                      ...previouData,
                      sendByEmail: e.target.checked,
                    };
                  });
                }}
              >
                Send by Email
              </Checkbox>
            </Form.Item>
          ))}
        <Form.Item
          name="appointmentSubmit"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  (getFieldValue("email") === undefined ||
                    getFieldValue("email") === "") &&
                  (getFieldValue("mobile") === undefined ||
                    getFieldValue("mobile") === "")
                ) {
                  return Promise.reject(
                    new Error("Please enter either email or mobile number!")
                  );
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
          hasFeedback
        >
          <Button
            name="appointmentSubmit"
            block
            type="primary"
            htmlType="submit"
          >
            Update Appointment
          </Button>
        </Form.Item>
      </Form>
      <AddLeadSource
        isAddLeadSourceModalVisible={isAddLeadSourceModalVisible}
        setIsAddLeadSourceModalVisible={setIsAddLeadSourceModalVisible}
      />
      <AddCountry
        isAddCountryModalVisible={isAddCountryModalVisible}
        setIsAddCountryModalVisible={setIsAddCountryModalVisible}
      />
      <AddConsultant
        isAddConsultantModalVisible={isAddConsultantModalVisible}
        setIsAddConsultantModalVisible={setIsAddConsultantModalVisible}
      />
      <AddContacts
        isAddContactVisible={isAddContactVisible}
        setIsAddContactVisible={setIsAddContactVisible}
      />
      <Purpose
        isAddPurposeModalVisible={isAddPurposeModalVisible}
        setIsAddPurposeModalVisible={setIsAddPurposeModalVisible}
      />
      {isAddSalesRepModalVisible && (
        <AddSalesRep
          isAddSalesRepModalVisible={isAddSalesRepModalVisible}
          setIsAddSalesRepModalVisible={setIsAddSalesRepModalVisible}
        />
      )}
    </Drawer>
  );
};

export default UpdateAppointment;
