import React from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import AddCountry from "../Leads/Country/AddCountry";
import AddressAutocomplete from "./AddressAutocomplete";
import { date00 } from "../../HelperFunction";
import AddState from "../Counsel/AddState";
const { Option } = Select;
const AddCompanies = ({
  isAddCompaniesModalVisible,
  setIsAddCompaniesModalVisible,
}) => {
  const store = useSelector((state) => {
    return {
      addCompanyState: state.LeadReducer,
    };
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isAddCountryModalVisible, setIsAddCountryModalVisible] =
    useState(false);
  const [isAddStateModalVisible, setIsAddStateModalVisible] = useState(false);

  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");

  useEffect(() => {
    if (isAddCompaniesModalVisible === true) {
      dispatch({ type: "FETCH_LEADS_DROPDOWN_REQUEST" });
    }
  }, []);
  const addCompaniesSubmitHandler = (values) => {
    console.log("addCompaniesSubmitHandler", values);
    const formData = {
      ...values,
      agreementStartDate: date00(values.agreementStartDate),
      agreementExpiryDate: date00(values.agreementExpiryDate),
    };
    dispatch({ type: "ADD_COMPANY_REQUEST", payload: formData });
    form.resetFields();
    setIsAddCompaniesModalVisible(false);
  };
  console.log("address", address);
  console.log("address2", address2);
  return (
    <Drawer
      title="Add Company"
      open={isAddCompaniesModalVisible}
      onClose={() => {
        setIsAddCompaniesModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Form
        layout="vertical"
        onFinish={addCompaniesSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
        fields={[
          {
            name: ["companyAddress"],
            value: address,
          },
          {
            name: ["address2"],
            value: address2,
          },
        ]}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Company Name"
            name={"companyName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter company name!",
              },
            ]}
          >
            <Input placeholder="Company name" />
          </Form.Item>
          <Form.Item
            label="Full Name"
            name={"name"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter fullName!",
              },
            ]}
          >
            <Input placeholder="Full Name" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Email"
            name={"email"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter email!",
              },
            ]}
          >
            <Input type="email" placeholder="e.g. company@domain.com" />
          </Form.Item>

          <Form.Item
            label="Phone Number"
            name={"phone"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter Phone Number!",
              },
              {
                pattern: /^[\d]{9,11}$/,
                message: "Phone number should contain 9 to 11 numbers",
              },
            ]}
          >
            <Input type="number" placeholder="Please enter Phone Number!" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Mobile Number"
            name={"mobile"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter mobile number!",
              },
              {
                pattern: /^[\d]{9,11}$/,
                message: "Mobile number should contain 9 to 11 numbers",
              },
            ]}
          >
            <Input type="number" placeholder="Mobile Number" />
          </Form.Item>
          <div
            className="flexRowWithoutStyle"
            style={{ gap: "0.3rem", width: "100%" }}
          >
            <Form.Item
              label="Country"
              name={"country"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter address!",
                },
              ]}
            >
              <Select>
                {store.addCompanyState.leadsDropdown?.countries?.map(
                  (dataObj) => {
                    return (
                      <Option key={dataObj.id}>{dataObj.countryName}</Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddCountryModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Staff"
            name={"staff"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter staff!",
              },
            ]}
          >
            <Input placeholder="Staff" />
          </Form.Item>
          <Form.Item
            label="ABN"
            name={"abn"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter abn!",
              },
            ]}
          >
            <Input type="number" placeholder="abn" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Agreement Start Date"
            name={"agreementStartDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select start date!",
              },
            ]}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              // disabledDate={(cd) => {
              //   const d = new Date();
              //   return cd > d;
              // }}
            />
          </Form.Item>
          <Form.Item
            label="Agreement End Date"
            name={"agreementExpiryDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select end date!",
              },
            ]}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              disabledDate={(cd) => {
                const d = new Date();
                return cd < d;
              }}
            />
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{
            justifyContent: "space-between",
            alignItems: "flex-start",
            gap: "1rem",
          }}
        >
          <Form.Item
            label="Address Line 1"
            name={"companyAddress"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter address line 1!",
              },
            ]}
          >
            <AddressAutocomplete setAddress={setAddress} address={address} />
          </Form.Item>
          <Form.Item
            label="Address Line 2"
            name={"address2"}
            style={{ width: "100%" }}
          >
            <AddressAutocomplete address={address2} setAddress={setAddress2} />
            {/* <Input placeholder="Address line 2" /> */}
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <div
            className="flexRowWithoutStyle"
            style={{ gap: "0.3rem", width: "100%" }}
          >
            <Form.Item label="State" name={"state"} style={{ width: "100%" }}>
              {/* <Select>
                {store.addCompanyState.leadsDropdown?.state?.map((dataObj) => {
                  return (
                    <Option key={dataObj.id} value={dataObj.id}>
                      {dataObj.stateName}
                    </Option>
                  );
                })}
              </Select> */}
              <Input placeholder="State" />
            </Form.Item>
            {/* <a
              className="plusButton"
              onClick={() => {
                setIsAddStateModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a> */}
          </div>
          <Form.Item label="Suburb" name={"suburb"} style={{ width: "100%" }}>
            <Input placeholder="Suburb" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Post Code"
            name={"postCode"}
            style={{ width: "100%" }}
          >
            <Input placeholder="Post Code" />
          </Form.Item>
          <Form.Item
            label="Website"
            name={"website"}
            style={{ width: "100%" }}
            rules={[
              {
                type: "url",
                message: "Please enter valid url!",
              },
            ]}
          >
            <Input placeholder="eg.www.companyname.com" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{
            justifyContent: "space-between",
            gap: "1rem",
            alignItems: "flex-end",
          }}
        >
          <div style={{ display: "flex", width: "100%", gap: "0.5rem" }}>
            <Form.Item
              label="Commission"
              name={"commission"}
              style={{ width: "100%" }}
            >
              <Input type="number" placeholder="Commission" />
            </Form.Item>
            <Form.Item
              label={"Commission Type"}
              name={"commissionType"}
              style={{ width: "100%" }}
            >
              <Radio.Group>
                <Radio value={"FLAT"}>is Flat</Radio>
                <Radio value={"PERCENTAGE"}>is Percentage</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <Form.Item
            name={"isDirectPartner"}
            valuePropName="checked"
            style={{ width: "100%" }}
          >
            <Checkbox
              onChange={(e) => {
                console.log("Is Direct Partner?", e);
              }}
            >
              Is Direct Partner?
            </Checkbox>
          </Form.Item>
        </div>
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Company
          </Button>
        </Form.Item>
      </Form>
      {isAddCountryModalVisible && (
        <AddCountry
          isAddCountryModalVisible={isAddCountryModalVisible}
          setIsAddCountryModalVisible={setIsAddCountryModalVisible}
        />
      )}
      {isAddStateModalVisible && (
        <AddState
          isAddStateModalVisible={isAddStateModalVisible}
          setIsAddStateModalVisible={setIsAddStateModalVisible}
        />
      )}
    </Drawer>
  );
};

export default AddCompanies;
