import { Button, Card, Form, Input } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import OrganizationalSettings from "../OrganizationalSettings";

const CaseSettings = () => {
  useEffect(() => {
    dispatch({
      type: "FETCH_REMINDER_EXIPRY_WORKING_HOUR_REQUEST",
      isSuccessFn: true,
      successFunction: successFunction,
    });
  }, []);
  const successFunction = (response) => {
    console.log("response from reminder expiry date", response);
    setCaseSettingsData((previousData) => {
      return {
        ...previousData,
        casePerfix: response?.data?.prefix?.casePrefix,
      };
    });
  };
  const [caseSettingsData, setCaseSettingsData] = useState({
    casePerfix: "",
  });
  const dispatch = useDispatch();
  return (
    <OrganizationalSettings>
      <div>
        <h3>Case Settings</h3>
        <Card>
          <Form labelCol={{ span: 3 }}>
            <Form.Item label="Case Prefix">
              <Input
                value={caseSettingsData.casePerfix}
                placeholder="Enter the prefix you want to use"
                onChange={(e) => {
                  setCaseSettingsData((previousData) => {
                    return {
                      ...previousData,
                      casePerfix: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
          </Form>
          <Form.Item
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                dispatch({
                  type: "UPDATE_PREFIX_REQUEST",
                  payload: {
                    casePrefix: caseSettingsData.casePerfix,
                  },
                });
              }}
            >
              Save Changes
            </Button>
          </Form.Item>
        </Card>
      </div>
    </OrganizationalSettings>
  );
};

export default CaseSettings;
