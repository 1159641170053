import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  employees: {},
  activeEmployees: {},
  activeConsultants: {},
  allConsultants: {},
};

const EmployeeReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_EMPLOYEE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_EMPLOYEE_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        employees: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_EMPLOYEE_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_ACTIVE_EMPLOYEE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_ACTIVE_EMPLOYEE_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        activeEmployees: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ACTIVE_EMPLOYEE_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_CONSULTANTS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_CONSULTANTS_REQUEST_SUCCESS": {
      return {
        ...state,
        allConsultants: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_CONSULTANTS_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load consultants.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_ACTIVE_CONSULTANTS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_ACTIVE_CONSULTANTS_REQUEST_SUCCESS": {
      return {
        ...state,
        activeConsultants: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ACTIVE_CONSULTANTS_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to .");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_EMPLOYEE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_EMPLOYEE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Employee added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_EMPLOYEE_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to add Employee.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_EMPLOYEE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_EMPLOYEE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Employee updated.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_EMPLOYEE_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to update Employee.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ASSIGN_DEPARTMENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ASSIGN_DEPARTMENT_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Department assigned.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ASSIGN_DEPARTMENT_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to assign department.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_EMPLOYEE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DELETE_EMPLOYEE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Employee deleted.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_EMPLOYEE_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to delete Employee.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_EMPLOYEE_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "TOGGLE_EMPLOYEE_STATUS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Employee status changed.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_EMPLOYEE_STATUS_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to change Employee status.");

      message.error("error");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "EMPLOYEE_DEACTIVATE_CONFIRMATION_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "EMPLOYEE_DEACTIVATE_CONFIRMATION_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success!  Employee deactivated.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "EMPLOYEE_DEACTIVATE_CONFIRMATION_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to deactivate employee.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "EMPLOYEE_ACTIVATE_CONFIRMATION_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "EMPLOYEE_ACTIVATE_CONFIRMATION_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success!  Employee activated.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "EMPLOYEE_ACTIVATE_CONFIRMATION_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to activate employee.");

      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};

export default EmployeeReducer;
