import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Input,
  Pagination,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import employee from "./../../Assets/account.png";
import activeEmployee from "./../../Assets/add-contact.png";
import {
  MdLink,
  MdOutlineAssignmentTurnedIn,
  MdOutlineDisabledVisible,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../Components/Layout/SiderDemo";
import AddEmployee from "./AddEmployee";
import UpdateEmployee from "./UpdateEmployee";
import EmployeeConfirmation from "./EmployeeConfirmation";
import { BiBlock } from "react-icons/bi";
import { AiOutlineEye, AiOutlinePoweroff } from "react-icons/ai";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
import ConsultantDetails from "../Consultants/ConultantDetails";
import AssignDepartment from "./AssignDepartment";
import ConsultantShortUrl from "./ConsultantShortUrl";
const { Search } = Input;

const columns = [
  {
    title: "Employee Name",
    dataIndex: "employeeName",
    sorter: (a, b) => {
      console.log(
        "form contact sorter a",
        a?.fullname?.props?.children?.props?.children?.props?.children
      );
      console.log("form contact sorter b", b);
      console.log("form contact sorter a", a);
      if (
        a?.employeeName?.props?.children?.props?.children?.props?.children <
        b?.employeeName?.props?.children?.props?.children?.props?.children
      ) {
        return -1;
      }
      if (
        a?.employeeName?.props?.children?.props?.children?.props?.children >
        b?.employeeName?.props?.children?.props?.children?.props?.children
      ) {
        return 1;
      }
      return 0;
    },
    align: "center",
  },
  {
    title: "Email",
    dataIndex: "emailId",
    sorter: (a, b) => {
      if (a.emailId < b.emailId) {
        return -1;
      }
      if (a.emailId > b.emailId) {
        return 1;
      }
      return 0;
    },
    align: "center",
  },
  {
    title: "Phone Number",
    dataIndex: "phoneNumber",
    sorter: (a, b) => {
      if (a.phoneNumber < b.phoneNumber) {
        return -1;
      }
      if (a.phoneNumber > b.phoneNumber) {
        return 1;
      }
      return 0;
    },
    align: "center",
  },
  {
    title: "User Name",
    dataIndex: "userName",
    sorter: (a, b) => {
      if (a.userName < b.userName) {
        return -1;
      }
      if (a.userName > b.userName) {
        return 1;
      }
      return 0;
    },
    align: "center",
  },
  {
    title: "Roles",
    dataIndex: "roles",
    width: 150,
    align: "center",
  },
  {
    title: "Is Consultant",
    dataIndex: "isConsultant",
    align: "center",
  },

  {
    title: "Status",
    dataIndex: "isActive",
    width: 150,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 150,
    align: "center",
  },
];

const EmployeeManager = () => {
  const employeeState = useSelector((state) => state.EmployeeReducer);
  const [searchValue, setSearchValue] = useState("");

  const [
    isAddEmployeeManagerModalVisible,
    setIsAddEmployeeManagerModalVisible,
  ] = useState(false);
  const [
    isUpdateEmployeeManagerModalVisible,
    setIsUpdateEmployeeManagerModalVisible,
  ] = useState(false);
  const [record, setRecord] = useState({});
  const [employeeType, setEmployeeManagerType] = useState("get-list");
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);
  const [isConsultantDetailsModelVisible, setIsConsultantDetailsModelVisible] =
    useState(false);
  const [isAssignDepartmentModalVisible, setIsAssignDepartmentModalVisible] =
    useState(false);
  const [isConsultantUrlLinkModalVisible, setIsConsultantUrlLinkModalVisible] =
    useState(false);

  const [employeRecord, setEmployeRecord] = useState({
    country: "",
    countryCode: "",
  });

  const dispatch = useDispatch();

  const onSearch = (e) => {
    console.log("This is e from search", e);
    if (employeeType === "get-active-list") {
      dispatch({
        type: "SEARCH_ACTIVE_EMPLOYEES_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          searchKey: { keyword: e },
        },
      });
    } else if (employeeType === "get-list") {
      dispatch({
        type: "SEARCH_EMPLOYEES_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          searchKey: { keyword: e },
        },
      });
    }
  };
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };
  useEffect(() => {
    if (employeeType === "get-active-list") {
      dispatch({
        type: "FETCH_ACTIVE_EMPLOYEE_REQUEST",
        payload: { page, pageSize },
      });
    } else {
      dispatch({
        type: "FETCH_EMPLOYEE_REQUEST",
        payload: { page, pageSize },
      });
    }
  }, [employeeType, page, pageSize]);

  useEffect(() => {
    if (
      isAddEmployeeManagerModalVisible === false ||
      isUpdateEmployeeManagerModalVisible === false
    ) {
      dispatch({
        type: "FETCH_EMPLOYEE_REQUEST",
      });
    }
  }, [isAddEmployeeManagerModalVisible, isUpdateEmployeeManagerModalVisible]);
  let data = [];

  useEffect(() => {
    dispatch({
      type: "FETCH_PROFILE_REQUEST",
      isSuccessFn: true,
      successFunction: profileSuccessFunction,
    });
  }, []);
  const profileSuccessFunction = (response) => {
    setEmployeRecord({
      country: response.data.country,
      countryCode: response.data.callingCode,
    });
  };

  const getAllListData = () => {
    let getAllData = employeeState.employees?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        employeeName: dataObj.firstName ? (
          <Tooltip title="Employee Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  setIsConsultantDetailsModelVisible(true);
                }}
              >
                {dataObj.firstName} {dataObj.lastName}
              </div>
            </a>
          </Tooltip>
        ) : (
          "N/A"
        ),
        emailId: dataObj.emailId,
        phoneNumber: dataObj.phoneNumber,
        userName: dataObj.userName,
        roles:
          dataObj.roleList?.length > 0
            ? dataObj.roleList?.map((dataobj) => {
                return (
                  <Tag
                    key={dataobj.id}
                    className="rolename"
                    style={{ margin: "2px" }}
                    color={"green"}
                  >
                    {dataobj.roleName}
                  </Tag>
                );
              })
            : "N/A",
        isConsultant: dataObj.isConsultant ? (
          <p className="greenTag">YES</p>
        ) : (
          <p className="redTag">NO</p>
        ),
        isActive: dataObj.isActive ? (
          <p className="greenTag">ACTIVE</p>
        ) : (
          <p className="redTag">INACTIVE</p>
        ),
        actions: (
          <Space size="middle">
            <Tooltip title="Update employee">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setRecord(dataObj);
                    setIsUpdateEmployeeManagerModalVisible(true);
                  }}
                >
                  <EditOutlined />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Assign Department">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setRecord(dataObj);
                    setIsAssignDepartmentModalVisible(true);
                  }}
                >
                  <MdOutlineAssignmentTurnedIn />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Employee Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setRecord(dataObj);
                    setIsConsultantDetailsModelVisible(true);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>

            {dataObj.isActive ? (
              <>
                <Tooltip title="Deactivate Employee">
                  <a style={{ color: "red" }}>
                    <div
                      className="bordered"
                      onClick={() => {
                        setRecord(dataObj);
                        setIsConfirmationModalVisible(true);
                      }}
                    >
                      <BiBlock />
                    </div>
                  </a>
                </Tooltip>
              </>
            ) : (
              <>
                {/* <Tooltip title="Delete employee">
                  <Popconfirm
                    title="Are you sure to delete this employee?"
                    onConfirm={() => {
                      dispatch({
                        type: "DELETE_EMPLOYEES_REQUEST",
                        payload: { id: dataObj.id },
                      });
                    }}
                    onCancel={() => {}}
                    okText="Yes"
                    cancelText="No"
                  >
                    <a>
                      <div className="bordered">
                        <DeleteOutlined />
                      </div>
                    </a>{" "}
                  </Popconfirm>
                </Tooltip> */}
                <Tooltip title="Activate">
                  <a>
                    <div
                      className="bordered"
                      onClick={() => {
                        setRecord(dataObj);
                        setIsConfirmationModalVisible(true);
                      }}
                    >
                      <AiOutlinePoweroff />
                    </div>
                  </a>
                </Tooltip>
              </>
            )}
            {dataObj.isConsultant && (
              <Tooltip title="Appointment Form URL Link">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsConsultantUrlLinkModalVisible(true);
                    }}
                  >
                    <MdLink />
                  </div>
                </a>
              </Tooltip>
            )}
          </Space>
        ),
      };
    });
    return getAllData;
  };

  const getActiveData = () => {
    let activeData = employeeState.activeEmployees?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        employeeName: dataObj.firstName ? (
          <Tooltip title="Employee Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  setIsConsultantDetailsModelVisible(true);
                }}
              >
                {dataObj.firstName} {dataObj.lastName}
              </div>
            </a>
          </Tooltip>
        ) : (
          "N/A"
        ),
        emailId: dataObj.emailId,
        phoneNumber: dataObj.phoneNumber,
        userName: dataObj.userName,
        roles:
          dataObj.roleList?.length > 0
            ? dataObj.roleList?.map((dataobj) => {
                return (
                  <Tag
                    key={dataobj.id}
                    className="rolename"
                    style={{ margin: "2px" }}
                    color={"green"}
                  >
                    {dataobj.roleName}
                  </Tag>
                );
              })
            : "N/A",
        isConsultant: dataObj.isConsultant ? (
          <p className="greenTag">YES</p>
        ) : (
          <p className="redTag">NO</p>
        ),
        isActive: dataObj.isActive ? (
          <p className="greenTag">ACTIVE</p>
        ) : (
          <p className="redTag">INACTIVE</p>
        ),
        actions: (
          <Space size="middle">
            <Tooltip title="Update employee">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsUpdateEmployeeManagerModalVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <EditOutlined />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Assign Department">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setRecord(dataObj);
                    setIsAssignDepartmentModalVisible(true);
                  }}
                >
                  <MdOutlineAssignmentTurnedIn />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Employee Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setRecord(dataObj);
                    setIsConsultantDetailsModelVisible(true);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>

            {dataObj.isActive ? (
              <>
                <Tooltip title="Deactivate Employee">
                  <a style={{ color: "red" }}>
                    <div
                      className="bordered"
                      onClick={() => {
                        setRecord(dataObj);
                        setIsConfirmationModalVisible(true);
                      }}
                    >
                      <BiBlock />
                    </div>
                  </a>
                </Tooltip>
              </>
            ) : (
              <>
                <Tooltip title="Delete employee">
                  <Popconfirm
                    title="Are you sure to delete this employee?"
                    onConfirm={() => {
                      dispatch({
                        type: "DELETE_EMPLOYEES_REQUEST",
                        payload: { id: dataObj.id },
                      });
                    }}
                    onCancel={() => {}}
                    okText="Yes"
                    cancelText="No"
                  >
                    <a>
                      <div className="bordered">
                        <DeleteOutlined />
                      </div>
                    </a>{" "}
                  </Popconfirm>
                </Tooltip>
                <Tooltip title="Activate">
                  <a>
                    <div
                      className="bordered"
                      onClick={() => {
                        setRecord(dataObj);
                        setIsConfirmationModalVisible(true);
                      }}
                    >
                      <AiOutlinePoweroff />
                    </div>
                  </a>
                </Tooltip>
              </>
            )}
            {dataObj.isConsultant && (
              <Tooltip title="Appointment Form URL Link">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsConsultantUrlLinkModalVisible(true);
                    }}
                  >
                    <MdLink />
                  </div>
                </a>
              </Tooltip>
            )}
          </Space>
        ),
      };
    });
    return activeData;
  };
  // if (employeeType === "get-list") {
  //   data = employeeState.activeEmployees?.data?.map((dataObj) => {
  //     return {
  //       key: dataObj.id,
  //       employeeName: dataObj.firstName + " " + dataObj.lastName,
  //       emailId: dataObj.emailId,
  //       phoneNumber: dataObj.phoneNumber,
  //       userName: dataObj.userName,
  //       isConsultant: dataObj.isConsultant ? (
  //         <p className="greenTag">YES</p>
  //       ) : (
  //         <p className="redTag">NO</p>
  //       ),

  //       actions: (
  //         <Space size="middle">
  //           <Tooltip title="Update employee">
  //             <a>
  //               <div
  //                 className="bordered"
  //                 onClick={() => {
  //                   setIsUpdateEmployeeManagerModalVisible(true);
  //                   setRecord(dataObj);
  //                 }}
  //               >
  //                 <EditOutlined />
  //               </div>
  //             </a>
  //           </Tooltip>
  //           <Tooltip title="Delete employee">
  //             <Popconfirm
  //               title="Are you sure to delete this employee?"
  //               onConfirm={() => {
  //                 dispatch({
  //                   type: "DELETE_EMPLOYEES_REQUEST",
  //                   payload: { id: dataObj.id },
  //                 });
  //               }}
  //               onCancel={() => {}}
  //               okText="Yes"
  //               cancelText="No"
  //             >
  //               <a>
  //                 <div className="bordered">
  //                   <DeleteOutlined />
  //                 </div>
  //               </a>{" "}
  //             </Popconfirm>
  //           </Tooltip>

  //         </Space>
  //       ),
  //     };
  //   });
  // } else {
  //   data = employeeState.activeEmployees?.data?.map((dataObj) => {
  //     return {
  //       key: dataObj.id,
  //       employeeName: dataObj.firstName + " " + dataObj.lastName,
  //       emailId: dataObj.emailId,
  //       phoneNumber: dataObj.phoneNumber,
  //       userName: dataObj.userName,
  //       isConsultant: dataObj.isConsultant ? (
  //         <p className="greenTag">YES</p>
  //       ) : (
  //         <p className="redTag">NO</p>
  //       ),
  //       actions: (
  //         <Space size="middle">
  //           <Tooltip title="Update employee">
  //             <a>
  //               <div
  //                 className="bordered"
  //                 onClick={() => {
  //                   setIsUpdateEmployeeManagerModalVisible(true);
  //                   setRecord(dataObj);
  //                 }}
  //               >
  //                 <EditOutlined />
  //               </div>
  //             </a>
  //           </Tooltip>
  //           <Tooltip title="Delete employee">
  //             <Popconfirm
  //               title="Are you sure to delete this employee?"
  //               onConfirm={() => {
  //                 dispatch({
  //                   type: "DELETE_EMPLOYEES_REQUEST",
  //                   payload: { id: dataObj.id },
  //                 });
  //               }}
  //               onCancel={() => {}}
  //               okText="Yes"
  //               cancelText="No"
  //             >
  //               <a>
  //                 <div className="bordered">
  //                   <DeleteOutlined />
  //                 </div>
  //               </a>{" "}
  //             </Popconfirm>
  //           </Tooltip>
  //           <Tooltip title="Toggle employee status">
  //             <a>
  //               <div
  //                 className="bordered"
  //                 onClick={() => {
  //                   const actionStatus =
  //                     dataObj.isConsultant === "INACTIVE"
  //                       ? "active"
  //                       : "inactive";
  //                   dispatch({
  //                     type: "TOGGLE_EMPLOYEES_STATUS_REQUEST",
  //                     payload: { id: dataObj.id, actionStatus },
  //                   });
  //                 }}
  //               >
  //                 <MdOutlineDisabledVisible />
  //               </div>
  //             </a>
  //           </Tooltip>
  //         </Space>
  //       ),
  //     };
  //   });
  // }
  console.log("from employee state", employeeState);
  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div className="leadFlexRow">
          <button
            className="button"
            onClick={() => {
              setIsAddEmployeeManagerModalVisible(true);
            }}
          >
            <span>Add Employee</span>
          </button>
          <Segmented
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <Avatar src={employee} size={20} shape="square" />
                    <div>All Employee</div>
                  </div>
                ),
                value: "get-list",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <Avatar src={activeEmployee} size={20} shape="square" />
                    <div>Active Employee</div>
                  </div>
                ),
                value: "get-active-list",
              },
            ]}
            onChange={(value) => {
              setPage(1);
              setPageSize(10);
              setEmployeeManagerType(value);
              console.log(value);
            }}
          />
          <Search
            className="search"
            style={{
              width: "30%",
              borderRadius: "10px ! important",
            }}
            placeholder="Search for employee"
            allowClear
            size="large"
            value={searchValue}
            onChange={(e) => {
              setPage(1);
              setPageSize(10);
              setSearchValue(e.target.value);
              onSearch(e.target.value);
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
          }}
        >
          {employeeState.isLoading ? (
            <TableSkeleton />
          ) : (
            <Table
              bordered
              columns={columns}
              dataSource={
                employeeType === "get-list" ? getAllListData() : getActiveData()
              }
              pagination={false}
            />
          )}
          {employeeType === "get-active-list" &&
            employeeState.activeEmployees?.totalData && (
              <Pagination
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  alignSelf: "flex-end",
                }}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                defaultCurrent={1}
                current={page}
                defaultPageSize={pageSize}
                onChange={onShowSizeChange}
                total={employeeState.activeEmployees?.totalPage * 10}
              />
            )}
          {employeeType === "get-list" &&
            employeeState.employees?.totalData && (
              <Pagination
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  alignSelf: "flex-end",
                }}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                defaultCurrent={1}
                current={page}
                defaultPageSize={pageSize}
                onChange={onShowSizeChange}
                total={employeeState.employees?.totalPage * 10}
              />
            )}
        </div>
      </div>
      {isAddEmployeeManagerModalVisible && (
        <AddEmployee
          employeRecord={employeRecord}
          AddEmployeeTitle={"Add Employee"}
          isAddEmployeeModalVisible={isAddEmployeeManagerModalVisible}
          setIsAddEmployeeModalVisible={setIsAddEmployeeManagerModalVisible}
        />
      )}
      {isUpdateEmployeeManagerModalVisible && (
        <UpdateEmployee
          record={record}
          updateEmployeeTitle={"Update Employee"}
          isUpdateEmployeeModalVisible={isUpdateEmployeeManagerModalVisible}
          setIsUpdateEmployeeModalVisible={
            setIsUpdateEmployeeManagerModalVisible
          }
        />
      )}
      {isConfirmationModalVisible && (
        <EmployeeConfirmation
          record={record}
          message={"Are you sure to change status?"}
          isConfirmationModalVisible={isConfirmationModalVisible}
          setIsConfirmationModalVisible={setIsConfirmationModalVisible}
        />
      )}
      {isConsultantDetailsModelVisible && (
        <ConsultantDetails
          record={record}
          consultantDetailsTitle={"Employee Details"}
          isConsultantDetailsModelVisible={isConsultantDetailsModelVisible}
          setIsConsultantDetailsModelVisible={
            setIsConsultantDetailsModelVisible
          }
        />
      )}
      {isAssignDepartmentModalVisible && (
        <AssignDepartment
          record={record}
          isAssignDepartmentModalVisible={isAssignDepartmentModalVisible}
          setIsAssignDepartmentModalVisible={setIsAssignDepartmentModalVisible}
        />
      )}
      {isConsultantUrlLinkModalVisible && (
        <ConsultantShortUrl
          record={record}
          isConsultantUrlLinkModalVisible={isConsultantUrlLinkModalVisible}
          setIsConsultantUrlLinkModalVisible={
            setIsConsultantUrlLinkModalVisible
          }
        />
      )}
    </SiderDemo>
  );
};

export default EmployeeManager;
