import React from "react";
import { Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useParams } from "react-router-dom";
import logoImage from "./../../Assets/khrouch3.png";
import CarouselComponent from "../Login/CarouselComponent";

const ForgotPasswordOTPverification = () => {
  const forgotPasswordOTPverificationState = useSelector(
    (state) => state.PasswordResetReducer
  );
  const dispatch = useDispatch();
  const params = useParams();

  const otpSubmitHandler = (value) => {
    const formData = {
      email: forgotPasswordOTPverificationState.userName,
      ...value,
    };
    dispatch({
      type: "FORGOT_PASSWORD_OTP_VERIFICATION_BY_COMPANY_URL_REQUEST",
      payload: { formData, companyDomainOrURL: params?.id },
    });
    // dispatch({
    //   type: "FORGOT_PASSWORD_OTP_VERIFICATION_REQUEST",
    //   payload: formData,
    // });
  };
  return (
    <>
      {forgotPasswordOTPverificationState.isOTPverified ? (
        <Navigate to={"/change_password"} replace={true} />
      ) : (
        <div style={{ display: "flex" }}>
          <div className="loginContainer">
            <div className="formContainer">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={logoImage} style={{ width: "15%" }} />
              </div>
              <h2 style={{ textAlign: "center" }}>OTP Verification!</h2>
              <p style={{ textAlign: "center", fontSize: "16px" }}>
                A text message with 6-digit verification code was just sent to{" "}
                {forgotPasswordOTPverificationState.userName}
              </p>
              <Form
                layout="vertical"
                className="loginForm"
                onFinish={otpSubmitHandler}
              >
                <Form.Item
                  name={"otp"}
                  rules={[
                    {
                      required: true,
                      message: "Please enter your OTP!",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="6-digit code"
                    size="large"
                    style={{ borderRadius: "25px" }}
                  />
                </Form.Item>

                {/* wrapperCol={{ offset: 9, span: 6 }} */}
                <Form.Item wrapperCol={{ offset: 9, span: 6 }}>
                  <div className="flexColumnwithoutStyle">
                    <Link
                      style={{
                        textAlign: "center",
                        marginBottom: "0.8rem",
                      }}
                      onClick={() => {
                        dispatch({
                          type: "FORGOT_PASSWORD_CODE_REQUEST",
                          payload: {
                            email: forgotPasswordOTPverificationState.userName,
                          },
                        });
                      }}
                    >
                      Resend Code
                    </Link>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ borderRadius: "2rem" }}
                    >
                      Verify
                    </Button>
                  </div>
                </Form.Item>
                <div style={{ textAlign: "center" }}>
                  Didn't receive the code?
                  <Link
                    to={`/password_reset/${params?.id}`}
                    onClick={() => {
                      dispatch({ type: "CHANGE_REGISTERED_STATUS" });
                    }}
                  >
                    {" "}
                    Try another email address
                  </Link>
                </div>
              </Form>
            </div>
          </div>
          <div style={{ width: "30%" }}>
            <CarouselComponent />
          </div>
        </div>
      )}
    </>
  );
};

export default ForgotPasswordOTPverification;
