import {
  Card,
  Divider,
  Drawer,
  Image,
  Modal,
  Input,
  Tooltip,
  Checkbox,
  Button,
  Tag,
  Form,
  Select,
} from "antd";
import React, { useEffect } from "react";
import { formattedDate, formattedDateTime } from "../../HelperFunction";
import client from "../../Assets/client.png";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { FcDocument } from "react-icons/fc";
import { GoLocation } from "react-icons/go";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CaseStatusHistory from "./CaseStatusHistory";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import UpdateCaseStatusCaseLog from "./UpdateCaseStatusCaseLog";
import CaseLog from "./CaseLog";
import CaseDetails from "./CaseDetails";
import { BsChatLeftText } from "react-icons/bs";
import SendComposedMail from "../Mailbox/SendComposedMail";
import { ExclamationCircleFilled } from "@ant-design/icons";
const { Meta } = Card;
const { Option } = Select;
const { confirm } = Modal;

const VisaCaseDetails = ({
  caseData,
  record,
  // visaRecord,
  isVisaCaseDetailsModalVisible,
  setIsVisaCaseDetailsModalVisible,
}) => {
  const visaState = useSelector((state) => state.VisasReducer);
  const [documentRecord, setDocumentRecord] = useState();
  const [form] = Form.useForm();
  const visaRecord = visaState?.caseById;
  const dispatch = useDispatch();
  const [isStatusHistoryModelVisible, setIsStatusHistoryModelVisible] =
    useState(false);
  const [isStatusUpdateModalVisible, setIsStatusUpdateModalVisible] =
    useState(false);
  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);
  useEffect(() => {
    if (isVisaCaseDetailsModalVisible === true) {
      dispatch({
        type: "FETCH_CASE_BY_ID_REQUEST",
        payload: {
          caseId: record?.id,
        },
      });
      dispatch({
        type: "FETCH_WORKFLOW_STATUSES_BY_WORKFLOW_ID_REQUEST",
        payload: { id: record?.workFlowDto?.id },
      });
      dispatch({
        type: "UPDATE_CURRENT_WORKFLOW_STATUS_REQUEST",
        payload: record?.currentWorkFlowStatus,
      });
      dispatch({
        type: "ADD_DOCUMENT_CHECKLIST",
        payload: {
          requiredWorkflowCheckList: record?.requiredWorkflowCheckList
            ? [...record?.requiredWorkflowCheckList]
            : [],
        },
      });
      dispatch({
        type: "ADD_COURSE_CHECKLIST",
        payload: {
          requiredCoursesCheckList: record?.requiredCoursesCheckList
            ? [...record?.requiredCoursesCheckList]
            : [],
        },
      });
    }
  }, [isVisaCaseDetailsModalVisible]);

  const [current, setCurrent] = useState("activityLog");
  const clientDetailsTabList = [
    {
      key: "activityLog",
      tab: "Activity Log",
    },
    {
      key: "caseDetails",
      tab: "Case Details",
    },
    {
      key: "documentChecklist",
      tab: "Documents Checklist",
    },
    {
      key: "formToDownload",
      tab: "Form to Download",
    },
  ];
  const successFn = (response) => {
    dispatch({
      type: "FETCH_CASE_BY_ID_REQUEST",
      payload: {
        caseId: record?.id,
      },
    });
  };
  console.log("visa details record", visaRecord);
  console.log("workflow Checklist", visaState?.requiredWorkflowCheckList);
  console.log("course Checklist", visaState?.requiredCoursesCheckList);
  console.log(
    "visaState?.currentWorkflowStatus",
    visaState?.currentWorkflowStatus
  );
  return (
    <Drawer
      title="Case Details"
      open={isVisaCaseDetailsModalVisible}
      onClose={() => {
        setIsVisaCaseDetailsModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Card style={{ borderRadius: "0.5rem" }} className="drawerStyle">
        <div className="flexColumnWithoutStyle" style={{}}>
          <div className="flexRowWithoutStyle" style={{ gap: "1rem" }}>
            {visaRecord?.customer?.photo ? (
              <Image
                src={visaRecord?.customer?.photo}
                className={"clientImage"}
              />
            ) : (
              <Image src={client} className={"clientImage"} />
            )}
            <div
              // className="flexColumnWithoutStyle"
              style={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "stretch",
                width: "100%",
                gap: "0.5rem",
                position: "relative",
              }}
            >
              <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                {visaRecord?.customer?.firstName +
                  " " +
                  visaRecord?.customer?.lastName}{" "}
                (Case ID: {caseData?.casePerfix ?? ""}
                {visaRecord?.id})
              </div>
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.4rem", color: "#aaa" }}
              >
                {visaRecord?.customer?.mobile && (
                  <span className="flexRowWithGap">
                    <AiOutlinePhone
                      style={{
                        fontSize: "20",
                      }}
                    />{" "}
                    {visaRecord?.customer?.mobile}
                  </span>
                )}
                {visaRecord?.customer?.address && (
                  <span className="flexRowWithGap">
                    <GoLocation
                      style={{
                        fontSize: "16",
                      }}
                    />{" "}
                    {visaRecord?.customer?.address}
                  </span>
                )}
                {visaRecord?.customer?.email && (
                  <span className="flexRowWithGap">
                    <AiOutlineMail
                      style={{
                        fontSize: "16",
                      }}
                    />{" "}
                    {visaRecord?.customer?.email}
                  </span>
                )}
              </div>
              <div
                className="flexRowWithoutStyle"
                style={{
                  alignItems: "flex-start",
                  gap: "0.3rem",
                }}
              >
                <div>
                  {visaRecord?.salesRepresentativeResponse?.salesRepName !==
                    undefined &&
                    visaRecord?.salesRepresentativeResponse?.salesRepName !==
                      null && (
                      <Tooltip
                        title={
                          visaRecord?.salesRepresentativeResponse?.salesRepEmail
                        }
                        showArrow={false}
                      >
                        <div>
                          <span className="profileTitle">Sales Rep:</span>
                          <span>
                            {
                              visaRecord?.salesRepresentativeResponse
                                ?.salesRepName
                            }
                          </span>
                        </div>
                      </Tooltip>
                    )}
                  {visaRecord?.leadType && (
                    <div>
                      <span className="profileTitle">Lead Type:</span>
                      <span>{visaRecord?.leadType}</span>
                    </div>
                  )}
                </div>
                {/* <Divider type="vertical" style={{ margin: 0, width: "2rem" }} /> */}
                <div
                  className="flexColumnWithoutStyle"
                  style={{ alignItems: "flex-start" }}
                >
                  <div>
                    <span className="profileTitle">Campaign:</span>
                    <span>{visaRecord?.customer?.campaign?.name ?? "N/A"}</span>
                  </div>
                </div>
              </div>

              <div
                className="flexRowWithoutStyle"
                style={{
                  gap: "1rem",
                  position: "absolute",
                  bottom: 0,
                }}
              >
                <Button
                  type="primary"
                  onClick={() => {
                    setIsStatusUpdateModalVisible(true);
                  }}
                >
                  Status Update
                </Button>
                <button
                  className="emailAndMessage"
                  onClick={() => {
                    setIsSendComposedEmailModalVisible(true);
                  }}
                >
                  <AiOutlineMail />
                  <span>Email</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <Card
          bordered={false}
          tabList={clientDetailsTabList}
          activeTabKey={current}
          onTabChange={(key) => {
            setCurrent(key);
          }}
        >
          {current === "caseDetails" && <CaseDetails visaRecord={visaRecord} />}
          {current === "documentChecklist" && (
            <div>
              <div style={{ width: "100%" }}>
                <h2>Document Attachment:</h2>
                <div style={{ marginTop: "1rem" }}>
                  {visaState?.caseById?.requiredWorkflowCheckList?.length >
                  0 ? (
                    visaState?.caseById?.requiredWorkflowCheckList.map(
                      (dataObj, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              border: "1px solid #ccc",
                              borderRadius: "20rem",
                              padding: "2px 4px",
                              margin: "0.5rem",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                gap: "1rem",
                                alignItems: "center",
                                padding: "0.5rem",
                              }}
                            >
                              <Checkbox
                                defaultChecked={dataObj?.isChecked}
                                onChange={(e) => {
                                  // if (e.target.checked === true) {
                                  //   confirm({
                                  //     title:
                                  //       "Do you want to verify this document?",
                                  //     icon: <ExclamationCircleFilled />,
                                  //     okText: "Verify",
                                  //     cancelText: "Cancel",
                                  //     onOk() {
                                  dispatch({
                                    type: "UPLOAD_URL_FROM_UPLOAD_RESPONSE_REQUEST",
                                    payload: {
                                      caseId: visaRecord.id,
                                      documentChecklistId:
                                        dataObj?.documentCheckListResponse?.id,
                                      courseCheckListId: null,
                                      uploadAttachment: null,
                                      isChecked: e.target.checked,
                                      isSuccessFn: true,
                                      successFn: successFn,
                                    },
                                  });
                                  //     },
                                  //     onCancel() {
                                  //       console.log("Cancel");
                                  //     },
                                  //   });
                                  // } else {
                                  //   confirm({
                                  //     title:
                                  //       "Do you want to unverify this document?",
                                  //     icon: <ExclamationCircleFilled />,
                                  //     okText: "Unverify",
                                  //     cancelText: "Cancel",
                                  //     onOk() {
                                  //       dispatch({
                                  //         type: "UPLOAD_URL_FROM_UPLOAD_RESPONSE_REQUEST",
                                  //         payload: {
                                  //           caseId: visaRecord.id,
                                  //           documentChecklistId:
                                  //             dataObj?.documentCheckListResponse
                                  //               ?.id,
                                  //           courseCheckListId: null,

                                  //           uploadAttachment: null,
                                  //           isChecked: e.target.checked,
                                  //           isSuccessFn: true,
                                  //           successFn: successFn,
                                  //         },
                                  //       });
                                  //     },
                                  //     onCancel() {
                                  //       console.log("Cancel");
                                  //     },
                                  //   });
                                  // }

                                  // console.log(
                                  //   "checked unchecked",
                                  //   e.target.checked
                                  // );
                                }}
                              >
                                {dataObj?.documentCheckListResponse?.item}
                              </Checkbox>
                              <div>
                                {
                                  dataObj?.documentCheckListResponse
                                    ?.documentSize
                                }{" "}
                              </div>
                              {/* <div>
                      {dataObj?.documentCheckListResponse?.documentType}
                    </div> */}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                gap: "0.5rem",
                                marginRight: "1.5rem",
                              }}
                            >
                              {dataObj.uploadedAttachment ? (
                                <Tooltip title="Download">
                                  <a href={dataObj.uploadedAttachment}>
                                    <div
                                      className="bordered"
                                      //   onClick={() => {
                                      //     setDocumentRecord(dataObj);
                                      //     setIsUpdateDocumentModalVisible(true);
                                      //   }}
                                    >
                                      <FcDocument
                                        style={{ fontSize: "30px" }}
                                      />
                                    </div>
                                  </a>
                                </Tooltip>
                              ) : null}

                              <Input
                                type="file"
                                onChange={(e) => {
                                  const uploadUrlFromUploadResponse = (
                                    response
                                  ) => {
                                    console.log(response, "response");
                                    dispatch({
                                      type: "UPLOAD_URL_FROM_UPLOAD_RESPONSE_REQUEST",
                                      payload: {
                                        caseId: visaRecord.id,
                                        documentChecklistId:
                                          dataObj?.documentCheckListResponse
                                            ?.id,
                                        courseCheckListId: null,
                                        uploadAttachment:
                                          response?.data?.imageUploadPath,
                                        isChecked: false,
                                        isSuccessFn: true,
                                        successFn: successFn,
                                      },
                                    });
                                    dispatch({
                                      type: "DOCUMENT_CHECKLIST_VISIBLE",
                                      payload: {
                                        index,
                                        documentChecklist: { ...response.data },
                                      },
                                    });
                                  };
                                  setDocumentRecord(dataObj);
                                  let formData = new FormData();
                                  formData.append(
                                    "uploadAttachment",
                                    e.target.files[0]
                                  );
                                  dispatch({
                                    type: "UPLOAD_CASE_DOCUMENTS_REQUEST",
                                    payload: formData,
                                    payload2: uploadUrlFromUploadResponse,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        );
                      }
                    )
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      No Workflow Document Checklist Available
                    </div>
                  )}
                </div>
                <h2>Course Attachment:</h2>
                <div style={{ marginTop: "1rem" }}>
                  {visaState?.caseById?.requiredCoursesCheckList?.length > 0 ? (
                    visaState?.caseById?.requiredCoursesCheckList.map(
                      (dataObj, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              border: "1px solid #ccc",
                              borderRadius: "20rem",
                              padding: "2px 4px",
                              margin: "0.5rem",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                gap: "1rem",
                                alignItems: "center",
                                padding: "0.5rem",
                              }}
                            >
                              <Checkbox
                                defaultChecked={dataObj?.isChecked}
                                onChange={(e) => {
                                  // if (e.target.checked === true) {
                                  //   confirm({
                                  //     title:
                                  //       "Do you want to verify this document?",
                                  //     icon: <ExclamationCircleFilled />,
                                  //     okText: "Verify",
                                  //     cancelText: "Cancel",
                                  //     onOk() {
                                  dispatch({
                                    type: "UPLOAD_URL_FROM_UPLOAD_RESPONSE_REQUEST",
                                    payload: {
                                      caseId: visaRecord.id,
                                      documentChecklistId: null,
                                      courseCheckListId:
                                        dataObj?.courseCheckListResponse?.id,
                                      uploadAttachment: null,
                                      isChecked: e.target.checked,
                                      isSuccessFn: true,
                                      successFn: successFn,
                                    },
                                  });
                                  //     },
                                  //     onCancel() {
                                  //       console.log("Cancel");
                                  //     },
                                  //   });
                                  // } else {
                                  //   confirm({
                                  //     title:
                                  //       "Do you want to unverify this document?",
                                  //     icon: <ExclamationCircleFilled />,
                                  //     okText: "Unverify",
                                  //     cancelText: "Cancel",
                                  //     onOk() {
                                  //       dispatch({
                                  //         type: "UPLOAD_URL_FROM_UPLOAD_RESPONSE_REQUEST",
                                  //         payload: {
                                  //           caseId: visaRecord.id,
                                  //           documentChecklistId: null,
                                  //           courseCheckListId:
                                  //             dataObj?.courseCheckListResponse
                                  //               ?.id,
                                  //           uploadAttachment: null,
                                  //           isChecked: e.target.checked,
                                  //           isSuccessFn: true,
                                  //           successFn: successFn,
                                  //         },
                                  //       });
                                  //     },
                                  //     onCancel() {
                                  //       console.log("Cancel");
                                  //     },
                                  //   });
                                  // }

                                  // console.log(
                                  //   "checked unchecked",
                                  //   e.target.checked
                                  // );
                                }}
                              >
                                {dataObj?.courseCheckListResponse?.item}
                              </Checkbox>

                              <div>
                                {dataObj?.courseCheckListResponse?.documentSize}
                              </div>
                              {/* <div>{dataObj?.courseCheckListResponse?.documentType}</div> */}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                gap: "0.5rem",
                                marginRight: "1.5rem",
                              }}
                            >
                              {dataObj.uploadedAttachment ? (
                                <Tooltip title="Download">
                                  <a href={dataObj.uploadedAttachment}>
                                    <div
                                      className="bordered"
                                      //   onClick={() => {
                                      //     setDocumentRecord(dataObj);
                                      //     setIsUpdateDocumentModalVisible(true);
                                      //   }}
                                    >
                                      <FcDocument
                                        style={{ fontSize: "30px" }}
                                      />
                                    </div>
                                  </a>
                                </Tooltip>
                              ) : null}
                              <Input
                                type="file"
                                onChange={(e) => {
                                  const uploadUrlFromUploadResponse = (
                                    response
                                  ) => {
                                    dispatch({
                                      type: "UPLOAD_URL_FROM_UPLOAD_RESPONSE_REQUEST",
                                      payload: {
                                        caseId: visaRecord.id,
                                        documentChecklistId: null,
                                        courseCheckListId:
                                          dataObj?.courseCheckListResponse?.id,
                                        uploadAttachment:
                                          response?.data?.imageUploadPath,
                                        isChecked: false,
                                        isSuccessFn: true,
                                        successFn: successFn,
                                      },
                                    });
                                    dispatch({
                                      type: "COURSE_CHECKLIST_VISIBLE",
                                      payload: {
                                        index,
                                        courseCheckList: { ...response.data },
                                      },
                                    });
                                  };

                                  let formData = new FormData();
                                  formData.append(
                                    "uploadAttachment",
                                    e.target.files[0]
                                  );
                                  dispatch({
                                    type: "UPLOAD_CASE_DOCUMENTS_REQUEST",
                                    payload: formData,
                                    payload2: uploadUrlFromUploadResponse,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        );
                      }
                    )
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      No Courses Document Checklist Available
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {current === "formToDownload" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1px",
                flexWrap: "wrap",
              }}
            ></div>
          )}
          {current === "activityLog" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "1px",
                flexWrap: "wrap",
              }}
            >
              <CaseLog record={record} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                }}
              >
                {visaState?.currentWorkflowStatus?.map((dataObj, index) => {
                  return (
                    <div>
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          border: "1px solid #ccc",
                          borderRadius: "20rem",
                          padding: "2px 4px",
                          margin: "0.5rem",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            gap: "1rem",
                            alignItems: "center",
                            padding: "0.5rem",
                          }}
                        >
                          {dataObj?.visitedStatus === "VISITED" ? (
                            <ImCheckboxChecked color="green" />
                          ) : dataObj?.visitedStatus === "CURRENT" ? (
                            <ImCheckboxUnchecked
                              color="green"
                              style={{ backgroundColor: "green" }}
                            />
                          ) : (
                            <ImCheckboxUnchecked color="gray" />
                          )}
                          {dataObj?.statusName}
                          <div>
                            {dataObj?.visitedStatus === "VISITED" ? "" : ""}
                          </div>
                        </div>
                      </div>
                      {dataObj?.visitedStatus === "CURRENT" &&
                      dataObj?.isLastState === true ? (
                        <div>
                          <Form layout="vertical" colon={true} form={form}>
                            <Form.Item
                              name={"Outcome"}
                              label={"Visa Outcome"}
                              style={{ width: "100%" }}
                            >
                              <Select
                                label="Visa Outcome"
                                onChange={(value) => {
                                  dispatch({
                                    type: "UPDATE_VISA_OUTCOME_REQUEST",
                                    payload: {
                                      id: visaRecord.id,
                                      data: { visaOutcome: value },
                                    },
                                  });
                                }}
                              >
                                <Option value="HOLD">Hold</Option>
                                <Option value="WITHDRAWN">Withdrawn</Option>
                                <Option value="GRANTED">Granted</Option>
                                <Option value="REFUSED">Refused</Option>
                                <Option value="NOT_ASSIGNED">
                                  Not Assigned
                                </Option>
                              </Select>
                            </Form.Item>
                          </Form>
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>

              {/* <Card style={{ width: 300 }}>
                <div className="flexRowSpaceBetween">
                  IELTS score: <span>{visaRecord?.customer?.ieltsScore}</span>
                </div>
                <div className="flexRowSpaceBetween">
                  SAT score: <span>{visaRecord?.customer?.satIbtScore}</span>
                </div>
                <div className="flexRowSpaceBetween">
                  PTE score: <span>{visaRecord?.customer?.pteScore}</span>
                </div>
                <div className="flexRowSpaceBetween">
                  GRE score: <span>{visaRecord?.customer?.greIbtScore}</span>
                </div>
                <div className="flexRowSpaceBetween">
                  Tofel score:{" "}
                  <span>{visaRecord?.customer?.yearsOfCommencement}</span>
                </div>
                <div className="flexRowSpaceBetween">
                  GMAT Score: <span>{visaRecord?.customer?.gmatIbtScore}</span>
                </div>
              </Card> */}
            </div>
          )}
        </Card>
      </Card>
      {isStatusHistoryModelVisible && (
        <CaseStatusHistory
          record={visaRecord}
          isUpdateCaseStatusModalVisible={isStatusHistoryModelVisible}
          setIsUpdateCaseStatusModalVisible={setIsStatusHistoryModelVisible}
        />
      )}
      {isStatusUpdateModalVisible && (
        <UpdateCaseStatusCaseLog
          record={visaRecord}
          isUpdateCaseStatusCaseLogModalVisible={isStatusUpdateModalVisible}
          setIsUpdateCaseStatusCaseLogModalVisible={
            setIsStatusUpdateModalVisible
          }
        />
      )}
      {isSendComposedEmailModalVisible && (
        <SendComposedMail
          composeMailRecord={{
            toUser: [visaRecord?.customer?.email],
          }}
          isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
          setIsSendComposedEmailModalVisible={
            setIsSendComposedEmailModalVisible
          }
        />
      )}
    </Drawer>
  );
};

export default VisaCaseDetails;

{
  /* <div className="flexRowSpaceBetween">
                  Date of Birth:<span>{visaRecord?.customer?.dob}</span>
                </div>
                <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

                <div className="flexRowSpaceBetween">
                  Phone Number:<span>{visaRecord?.customer?.phone}</span>
                </div>
                <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

                <div className="flexRowSpaceBetween">
                  Passport Number:
                  <span>{visaRecord?.customer?.passportNumber}</span>
                </div>
                <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

                <div className="flexRowSpaceBetween">
                  Marital Status:
                  <span>{visaRecord?.customer?.maritalStatus}</span>
                </div>
                <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

                <div className="flexRowSpaceBetween">
                  Sex Status:<span>{visaRecord?.customer?.sexStatus}</span>
                </div>
                <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

                <div className="flexRowSpaceBetween">
                  Referral:
                  <span>{visaRecord?.customer?.referral?.emailId}</span>
                </div>
                <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

                <div className="flexRowSpaceBetween">
                  About Us:<span>{visaRecord?.customer?.aboutUs?.aboutUs}</span>
                </div> */
}
