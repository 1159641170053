import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { MdOutlineDisabledVisible } from "react-icons/md";
import consultancy from "./../../Assets/Company.png";
import activeConsultancy from "./../../Assets/activeCompanies2.png";

import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../Components/Layout/SiderDemo";
import AddConsultancy from "./AddConsultancy";
import UpdateConsultancy from "./UpdateConsultancy";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    align: "center",
  },
  {
    title: "Email",
    dataIndex: "email",
    align: "center",
  },
  {
    title: "Phone",
    dataIndex: "phoneNumber",
    align: "center",
  },
  {
    title: "Company",
    dataIndex: "company",
    align: "center",
  },
  {
    title: "Competency",
    dataIndex: "competency",
    align: "center",
  },
  {
    title: "Transport",
    dataIndex: "transport",
    align: "center",
  },
  {
    title: "Position",
    dataIndex: "position",
    align: "center",
  },

  {
    title: "wage",
    dataIndex: "wage",
    align: "center",
  },
  {
    title: "Total Allowance",
    dataIndex: "totalAllowance",
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
  },
];

const Consultancies = () => {
  const consultancyState = useSelector((state) => state.ConsultancyReducer);
  const [isAddConsultanciesModalVisible, setIsAddConsultanciesModalVisible] =
    useState(false);
  const [
    isUpdateConsultanciesModalVisible,
    setIsUpdateConsultanciesModalVisible,
  ] = useState(false);
  const [record, setRecord] = useState({});
  const [consultancyType, setConsultanciesType] = useState("getAll");

  const dispatch = useDispatch();
  useEffect(() => {
    if (consultancyType === "active") {
      dispatch({
        type: "FETCH_ACTIVE_CONSULTANCY_REQUEST",
      });
    } else {
      dispatch({ type: "FETCH_CONSULTANCY_REQUEST" });
    }
  }, [consultancyType]);
  useEffect(() => {
    if (
      isAddConsultanciesModalVisible === false ||
      isUpdateConsultanciesModalVisible === false
    ) {
      dispatch({ type: "FETCH_CONSULTANCY_REQUEST" });
    }
  }, [isAddConsultanciesModalVisible, isUpdateConsultanciesModalVisible]);
  let data = [];
  if (consultancyType === "getAll") {
    data = consultancyState.consultancies?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        name: dataObj.firstName + " " + dataObj.lastName,
        email: dataObj.email,
        phoneNumber: dataObj.phoneNumber,
        company: dataObj.company,
        competency: dataObj.competency,
        transport: dataObj.transport,
        position: dataObj.position,
        wage: dataObj.wage,
        totalAllowance: dataObj.totalAllowance,
        status:
          dataObj.status === "ACTIVE" ? (
            <p className="greenTag">ACTIVE</p>
          ) : (
            <p className="redTag">INACTIVE</p>
          ),

        actions: (
          <Space size="middle">
            <Tooltip title="Update consultancy">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsUpdateConsultanciesModalVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <EditOutlined />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Delete consultancy">
              <Popconfirm
                title="Are you sure to delete this consultancy?"
                onConfirm={() => {
                  dispatch({
                    type: "DELETE_CONSULTANCY_REQUEST",
                    payload: { id: dataObj.id },
                  });
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <div className="bordered">
                    <DeleteOutlined />
                  </div>
                </a>{" "}
              </Popconfirm>
            </Tooltip>
            <Tooltip title="Toggle consultancy status">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    const actionStatus =
                      dataObj.status === "INACTIVE" ? "active" : "inactive";
                    dispatch({
                      type: "TOGGLE_CONSULTANCY_STATUS_REQUEST",
                      payload: { id: dataObj.id, actionStatus },
                    });
                  }}
                >
                  <MdOutlineDisabledVisible />
                </div>
              </a>
            </Tooltip>
          </Space>
        ),
      };
    });
  } else {
    data = consultancyState.activeConsultancies?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        name: dataObj.firstName + " " + dataObj.lastName,
        email: dataObj.email,
        phoneNumber: dataObj.phoneNumber,
        company: dataObj.company,
        competency: dataObj.competency,
        transport: dataObj.transport,
        position: dataObj.position,
        wage: dataObj.wage,
        totalAllowance: dataObj.totalAllowance,
        status:
          dataObj.status === "ACTIVE" ? (
            <p className="greenTag">ACTIVE</p>
          ) : (
            <p className="redTag">INACTIVE</p>
          ),
        actions: (
          <Space size="middle">
            <Tooltip title="Update consultancy">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsUpdateConsultanciesModalVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <EditOutlined />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Delete consultancy">
              <Popconfirm
                title="Are you sure to delete this consultancy?"
                onConfirm={() => {
                  dispatch({
                    type: "DELETE_CONSULTANCY_REQUEST",
                    payload: { id: dataObj.id },
                  });
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <div className="bordered">
                    <DeleteOutlined />
                  </div>
                </a>{" "}
              </Popconfirm>
            </Tooltip>
            <Tooltip title="Toggle consultancy status">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    const actionStatus =
                      dataObj.status === "INACTIVE" ? "active" : "inactive";
                    dispatch({
                      type: "TOGGLE_CONSULTANCY_STATUS_REQUEST",
                      payload: { id: dataObj.id, actionStatus },
                    });
                  }}
                >
                  <MdOutlineDisabledVisible />
                </div>
              </a>
            </Tooltip>
          </Space>
        ),
      };
    });
  }
  console.log("from consultancy state", consultancyState);
  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div className="flexRow">
          <button
            className="button"
            onClick={() => {
              setIsAddConsultanciesModalVisible(true);
            }}
          >
            <span>Add Consultancies</span>
          </button>
          <Segmented
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <Avatar src={consultancy} size={20} shape="square" />
                    <div>All Consultancies</div>
                  </div>
                ),
                value: "getAll",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <Avatar src={activeConsultancy} size={20} shape="square" />
                    <div>Active Consultancies</div>
                  </div>
                ),
                value: "active",
              },
            ]}
            onChange={(value) => {
              setConsultanciesType(value);
              console.log(value);
            }}
          />
        </div>
        <div style={{ marginTop: "4rem" }}>
          <Table bordered columns={columns} dataSource={data} />
        </div>
      </div>
      <AddConsultancy
        isAddConsultancyModalVisible={isAddConsultanciesModalVisible}
        setIsAddConsultancyModalVisible={setIsAddConsultanciesModalVisible}
      />
      <UpdateConsultancy
        record={record}
        isUpdateConsultanciesModalVisible={isUpdateConsultanciesModalVisible}
        setIsUpdateConsultanciesModalVisible={
          setIsUpdateConsultanciesModalVisible
        }
      />
    </SiderDemo>
  );
};

export default Consultancies;
