import { Menu, Layout, Divider, Card } from "antd";
import React, { useEffect, useState } from "react";
import { Children } from "react";
import { GoDashboard } from "react-icons/go";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import OrganizationalSettings from "../OrganizationalSettings";
import EmailTemplate from "./EmailTemplate/EmailTemplate";
const { Header, Sider, Content } = Layout;

const Templates = () => {
  const orgnizationalSettingsState = useSelector(
    (state) => state.OrganizationalSettingsReducer
  );

  const [record, setRecord] = useState({});

  const dispatch = useDispatch();
  const [current, setCurrent] = useState("email");

  const clientDetailsTabList = [
    {
      key: "email",
      tab: "Email",
    },
    {
      key: "sms",
      tab: "SMS",
    },
  ];

  return (
    <OrganizationalSettings>
      <div>
        <h3>Templates</h3>
        <Card
          bordered={false}
          tabList={clientDetailsTabList}
          activeTabKey={current}
          onTabChange={(key) => {
            setCurrent(key);
          }}
        >
          {current === "email" && (
            <>
              <EmailTemplate />
            </>
          )}
          {current === "sms" && <></>}
        </Card>
      </div>
    </OrganizationalSettings>
  );
};

export default Templates;
