import { Button, Card, Form, Input } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import OrganizationalSettings from "../OrganizationalSettings";

const CheckInSettings = () => {
  // const [appointmentPrefix, setAppointmentPrefix] = useState("");
  const [checkinPrefix, setCheckinPrefix] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "FETCH_REMINDER_EXIPRY_WORKING_HOUR_REQUEST",
      isSuccessFn: true,
      successFunction: successFunction,
    });
  }, []);

  const successFunction = (response) => {
    console.log("response from reminder expiry date", response);
    setCheckinPrefix((previousData) => {
      return {
        checkinPrefix: response?.data?.prefix?.checkInPrefix,
      };
    });
  };

  return (
    <OrganizationalSettings>
      <div>
        <h3>Check In Settings</h3>
        <Card>
          <Form.Item label="Prefixes">
            <Input
              value={checkinPrefix.checkinPrefix}
              placeholder="Enter the prefix you want to use"
              // onChange={(e) => {
              //   setAppointmentPrefix(e.target.value);
              // }}
              onChange={(e) => {
                setCheckinPrefix(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                dispatch({
                  type: "UPDATE_PREFIX_REQUEST",
                  payload: {
                    checkInPrefix: checkinPrefix,
                  },
                });
              }}
            >
              Save Changes
            </Button>
          </Form.Item>
        </Card>
      </div>
    </OrganizationalSettings>
  );
};

export default CheckInSettings;
