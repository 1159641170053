import { Button, Card, Form, Input, List, Tag, Tooltip } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FiSettings } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import OrganizationalSettings from "../OrganizationalSettings";

const LeadSetttings = () => {
  const store = useSelector((state) => {
    return {
      leadSettingsState: state.OrganizationSettingsReducer,
    };
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "FETCH_REMINDER_EXIPRY_WORKING_HOUR_REQUEST",
      isSuccessFn: true,
      successFunction: successFunction,
    });
    dispatch({
      type: "FETCH_ALL_LEAD_STATUSES_REQUEST",
      // isSuccessFn: true,
      // successFunction: successFunction,
    });
  }, []);

  const successFunction = (response) => {
    console.log("response from reminder expiry date", response);
    setLeadSettingsData((previousData) => {
      return {
        ...previousData,
        leadPrefix: response?.data?.prefix?.leadPrefix,
        leadReminder: response?.data?.expiryDates?.leadReminderDateAfter,
      };
    });
  };
  const [leadSettingsData, setLeadSettingsData] = useState({
    leadPrefix: "",
    leadReminder: "",
  });
  console.log("leadSettingsState", store.leadSettingsState);
  console.log("from profile state", leadSettingsData);
  console.log(
    "store.leadSettingsState?.leadStatuses",
    store.leadSettingsState?.leadStatuses
  );
  console.log(
    "from profile state",
    store.leadSettingsState?.reminderExpiryWorkingHour
  );
  return (
    <OrganizationalSettings>
      <div>
        <h3>Lead Settings</h3>
        <Card>
          <Form
            labelCol={{ span: 3 }}
            fields={[
              {
                name: ["leadPrefix"],
                value: leadSettingsData.leadPrefix,
              },
              {
                name: ["leadReminder"],
                value: leadSettingsData.leadReminder,
              },
            ]}
          >
            <Form.Item name={"leadPrefix"} label="Lead Prefix">
              <Input
                placeholder="Enter the prefix you want to use"
                value={leadSettingsData.leadPrefix}
                onChange={(e) => {
                  setLeadSettingsData((previousData) => {
                    return {
                      ...previousData,
                      leadPrefix: e.target.value,
                    };
                  });
                  // setLeadPrefix(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item name={"leadReminder"} label="Lead Reminder">
              <Input
                type="number"
                value={leadSettingsData.leadReminder}
                //   placeholder="reminder days after today"
                onChange={(e) => {
                  setLeadSettingsData((previousData) => {
                    return {
                      ...previousData,
                      leadReminder: e.target.value,
                    };
                  });
                }}
                style={{ width: "4rem" }}
              />{" "}
              days after.
            </Form.Item>
            {/* <Form.Item name={"replyEmail"} label="Reply Email">
              <Input
                value={leadSettingsData.leadReminder}
                onChange={(e) => {
                  setLeadSettingsData((previousData) => {
                    return {
                      ...previousData,
                      replyEmail: e.target.value,
                    };
                  });
                }}
                style={{ width: "4rem" }}
              />{" "}
            </Form.Item> */}
          </Form>
          <List
            header={<h3>Lead Statuses</h3>}
            bordered
            style={{ borderRadius: "0.4rem", margin: "0 0 1rem 0" }}
            dataSource={store.leadSettingsState?.leadStatuses?.data}
            renderItem={(item) => (
              <List.Item
                key={item.id}
                actions={[
                  <Tooltip title="Set as Default">
                    {!item.isDefault ? (
                      <a key="list-loadmore-edit">
                        <div
                          className="bordered"
                          onClick={() => {
                            dispatch({
                              type: "SET_LEAD_STATUS_AS_DEFAULT_REQUEST",
                              payload: {
                                leadStatusId: item.id,
                              },
                            });
                          }}
                        >
                          <FiSettings style={{ fontSize: "18px" }} />
                        </div>
                      </a>
                    ) : (
                      ""
                    )}
                  </Tooltip>,
                ]}
              >
                <List.Item.Meta
                  title={
                    <div>
                      {item.statusName}{" "}
                      {item.isDefault ? (
                        <span
                          // className="greenTag"
                          style={{
                            borderRadius: "20px",
                            fontSize: "12px",
                            color: "white",
                            background: "green",
                            padding: "2px 8px",
                            marginLeft: "1rem",
                          }}
                        >
                          Default
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  }
                />
                <Tag color={item?.statusColorCode ?? "blue"}>
                  {item.statusName}
                </Tag>
              </List.Item>
            )}
          />
          <Form.Item
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                dispatch({
                  type: "UPDATE_PREFIX_REQUEST",
                  payload: {
                    leadPrefix: leadSettingsData?.leadPrefix,
                  },
                });
                dispatch({
                  type: "UPDATE_EXPIRY_REMINDER_WORKING_HOUR_REQUEST",
                  payload: {
                    leadReminderDateAfter: leadSettingsData.leadReminder,
                  },
                });
              }}
            >
              Save Changes
            </Button>
          </Form.Item>
        </Card>
      </div>
    </OrganizationalSettings>
  );
};

export default LeadSetttings;
