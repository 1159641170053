import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Avatar,
  Form,
  Input,
  Pagination,
  Popconfirm,
  Segmented,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineHistory, AiOutlineMail } from "react-icons/ai";
import { CiMobile3 } from "react-icons/ci";
import { GrHistory } from "react-icons/gr";
import { MdOutlineDisabledVisible, MdPreview } from "react-icons/md";
import { VscDebugStart } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../Components/Layout/SiderDemo";
import {
  dateAndTime,
  formattedDate,
  formattedDateTime,
  fourTagResponses,
  threeTagResponses,
} from "../../HelperFunction";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
import StartCounselling from "../Counsel/StartCounselling";
import StatusHistory from "../Leads/StatusHistory";
import activeAppointment from "./../../Assets/activeAppointment.png";
import appointment from "./../../Assets/appointment.png";

import AddAppointment from "./AddAppointment";
import AppointmentDetails from "./AppointmentDetails";
import StatusHistoryConsellorAdd from "./StatusHistoryConsellorAdd";
import UpdateAppointment from "./UpdateAppointment";
const { Search } = Input;

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    align: "center",
  },
  {
    title: "Full Name",
    dataIndex: "firstName",
    sorter: (a, b) => {
      if (
        a?.firstName?.props?.children?.props?.children?.props?.children <
        b?.firstName?.props?.children?.props?.children?.props?.children
      ) {
        return -1;
      }
      if (
        a?.firstName?.props?.children?.props?.children?.props?.children >
        b?.firstName?.props?.children?.props?.children?.props?.children
      ) {
        return 1;
      }
      return 0;
    },
    align: "center",
  },
  {
    title: "Contact",
    dataIndex: "mobileNumber",

    align: "center",
  },
  {
    title: "Campaign",
    dataIndex: "campaign",
    align: "center",
  },
  {
    title: "Appointment Date/Time",
    dataIndex: "bookingDate",
    // sorter: (a, b) => {
    //   if (moment(a.bookingDate).isBefore(b.bookingDate)) {
    //     return -1;
    //   }
    //   if (moment(a.bookingDate).isAfter(b.bookingDate)) {
    //     return 1;
    //   }
    //   if (moment(a.bookingDate).isSame(b.bookingDate)) {
    //     return 0;
    //   }
    //   return 0;
    // },
    align: "center",
  },
  // {
  //   title: "Booking Day",
  //   dataIndex: "bookingDay",
  //   align: "center",
  // },
  // {
  //   title: "Session Time",
  //   dataIndex: "sessionTime",
  //   align: "center",
  // },
  // {
  //   title: "Session Duration",
  //   dataIndex: "sessionDuration",
  //   align: "center",
  // },

  // {
  //   title: "Consultant",
  //   dataIndex: "consultant",
  //   align: "center",
  // },

  {
    title: "Appointment Status",
    dataIndex: "appointmentStatus",
    align: "center",
  },
  {
    title: "Counsellor",
    dataIndex: "consultant",
    align: "center",
  },
  {
    title: "Lead ID",
    dataIndex: "leadId",
    align: "center",
  },
  {
    title: "Lead Status",
    dataIndex: "leadStatus",
    align: "center",
  },

  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
  },
];

const Appointments = ({ isHeadBranch, branchRecord }) => {
  const AppointmentState = useSelector((state) => state.AppointmentsReducer);
  const [searchValue, setSearchValue] = useState("");

  const [isAddAppointmentModelVisible, setIsAddAppointmentModelVisible] =
    useState(false);
  const [isUpdateAppointmentModelVisible, setIsUpdateAppointmentModelVisible] =
    useState(false);
  const [
    isAppointmentDetailsModalVisible,
    setIsAppointmentDetailsModalVisible,
  ] = useState();
  const [appointmentType, setAppointmentType] = useState("allAppointments");
  const [record, setRecord] = useState({});
  const dispatch = useDispatch();
  const [isStatusHistoryModelVisible, setIsStatusHistoryModelVisible] =
    useState(false);
  const [isStartCounsellingModelVisible, setIsStartCounsellingModelVisible] =
    useState(false);
  const [
    isStatusHistoryCounsellorAddModelVisible,
    setIsStatusHistoryCounsellorAddModelVisible,
  ] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };
  const onSearch = (e) => {
    if (appointmentType === "allAppointments") {
      dispatch({
        type: "SEARCH_APPOINTMENTS_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          searchKey: { keyword: e },
        },
      });
    } else if (appointmentType === "pendingAppointments") {
      dispatch({
        type: "SEARCH_PENDING_APPOINTMENTS_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          searchKey: { keyword: e },
        },
      });
    } else if (appointmentType === "confirmedAppointments") {
      dispatch({
        type: "SEARCH_CONFIRMED_APPOINTMENTS_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          searchKey: { keyword: e },
        },
      });
    } else if (appointmentType === "completedAppointments") {
      dispatch({
        type: "SEARCH_COMPLETED_APPOINTMENTS_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          searchKey: { keyword: e },
        },
      });
    }
  };

  const [appointmentData, setAppointmentData] = useState({
    appointmentPerfix: "",
    appointmentReminder: "",
  });
  const successFunction = (response) => {
    setAppointmentData((previousData) => {
      return {
        ...previousData,
        leadPrefix: response?.data?.prefix?.leadPrefix,
        appointmentPerfix: response?.data?.prefix?.appointmentPrefix,
        appointmentReminder:
          response?.data?.expiryDates?.appointmentReminderDateAfter,
      };
    });
  };
  useEffect(() => {
    dispatch({
      type: "FETCH_REMINDER_EXIPRY_WORKING_HOUR_REQUEST",
      isSuccessFn: true,
      successFunction: successFunction,
    });
    if (appointmentType === "allAppointments") {
      dispatch({
        type: "FETCH_APPOINTMENTS_REQUEST",
        payload: { page, pageSize, isHeadBranch, branchId: branchRecord.id },
      });
    } else if (appointmentType === "pendingAppointments") {
      dispatch({
        type: "FETCH_PENDING_APPOINTMENTS_REQUEST",
        payload: { page, pageSize, isHeadBranch, branchId: branchRecord.id },
      });
    } else if (appointmentType === "confirmedAppointments") {
      dispatch({
        type: "FETCH_CONFIRMED_APPOINTMENTS_REQUEST",
        payload: { page, pageSize, isHeadBranch, branchId: branchRecord.id },
      });
    } else if (appointmentType === "completedAppointments") {
      dispatch({
        type: "FETCH_COMPLETED_APPOINTMENTS_REQUEST",
        payload: { page, pageSize, isHeadBranch, branchId: branchRecord.id },
      });
    }
  }, [appointmentType, page, pageSize, branchRecord]);
  useEffect(() => {
    if (
      isAddAppointmentModelVisible === false ||
      isUpdateAppointmentModelVisible === false ||
      isStartCounsellingModelVisible === false
    ) {
      dispatch({
        type: "FETCH_APPOINTMENTS_REQUEST",
        payload: { page, pageSize, isHeadBranch, branchId: branchRecord.id },
      });
    }
  }, [
    isAddAppointmentModelVisible,
    isUpdateAppointmentModelVisible,
    isStartCounsellingModelVisible,
  ]);
  let data = [];
  if (appointmentType === "allAppointments") {
    data = AppointmentState.appointments?.data?.map((dataObj) => {
      // let appointmentStatus;
      // if (dataObj.appointmentStatus === "PENDING") {
      //   appointmentStatus = <p className="orangeTag">PENDING</p>;
      // } else if (dataObj.appointmentStatus === "COMPLETED") {
      //   appointmentStatus = <p className="blueTag">COMPLETED</p>;
      // } else {
      //   appointmentStatus = <p className="greenTag">CONFIRMED</p>;
      // }

      return {
        key: dataObj.id,
        id: `${appointmentData.appointmentPerfix ?? ""}${dataObj.id}`,
        leadId: `${appointmentData.leadPrefix ?? ""}${dataObj.leadId}`,

        firstName: (
          <Tooltip title="Appointment Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  setIsAppointmentDetailsModalVisible(true);
                }}
              >
                {dataObj.firstName ?? ""} {dataObj.lastName ?? ""}
              </div>
            </a>
          </Tooltip>
        ),
        appointmentStatus: dataObj?.appointmentStatus
          ? fourTagResponses(
              dataObj?.appointmentStatus,
              "PENDING",
              "COMPLETED",
              "CONFIRMED",
              "CANCELLED"
            )
          : "N/A",
        mobileNumber: (
          <div className="flexColumnWithoutStyle">
            {dataObj.email !== "" && dataObj.email !== null ? (
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "2px", color: "000", color: "000" }}
              >
                <AiOutlineMail />
                <a href={`mailto:${dataObj.email}`}>{dataObj.email ?? "N/A"}</a>
              </div>
            ) : null}
            {dataObj.mobile !== "" && dataObj.mobile !== null ? (
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "2px", color: "000" }}
              >
                <CiMobile3 />
                <a href={`tel:${dataObj.mobile}`}>{dataObj.mobile ?? "N/A"}</a>
              </div>
            ) : null}
          </div>
        ),
        // dataObj.mobile !== "" && dataObj.mobile !== null
        //   ? dataObj.mobile
        //   : "N/A",
        bookingDate:
          dataObj.counsellingBooking !== null &&
          dataObj.counsellingBooking?.bookingDate !== null
            ? `${dataObj.counsellingBooking?.bookingDate} ${
                dataObj.counsellingBooking?.startTime &&
                dataObj.counsellingBooking?.endTime
                  ? `${dataObj.counsellingBooking?.startTime}-${dataObj.counsellingBooking?.endTime}`
                  : ""
              }`
            : dataObj.appointmentDate
            ? formattedDate(dataObj.appointmentDate)
            : "N/A",
        bookingDay: dataObj.counsellingBooking?.bookingDay,
        sessionDuration: dataObj.counsellingBooking?.sessionTime,
        sessionTime:
          dataObj.counsellingBooking?.startTime +
          "-" +
          dataObj.counsellingBooking?.endTime,
        email: dataObj.email,
        campaign:
          dataObj?.leadResponse?.customerResponse?.campaign?.name ?? "N/A",
        consultant: dataObj?.counsellor ? (
          dataObj.counsellor?.fullName
        ) : dataObj.counsellingBooking &&
          dataObj.counsellingBooking?.consultant &&
          dataObj.counsellingBooking?.consultant?.fullName ? (
          dataObj.counsellingBooking?.consultant?.fullName
        ) : (
          <Tooltip title="Click to add counsellor">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  setIsStatusHistoryCounsellorAddModelVisible(true);
                }}
              >
                N/A
              </div>
            </a>
          </Tooltip>
        ),
        leadStatus: dataObj.leadStatus ? (
          <>
            {dataObj.leadStatus === "BOOK_AN_APPOINTMENT" ? (
              <Tag color={dataObj.leadStatusColorCode}>IN PROGRESS</Tag>
            ) : (
              <Tag color={dataObj.leadStatusColorCode}>
                {dataObj.leadStatus}
              </Tag>
            )}
          </>
        ) : (
          "N/A"
        ),

        actions: (
          <Space size="middle">
            {isHeadBranch && (
              <>
                {dataObj.appointmentStatus === "COMPLETED" ||
                dataObj.appointmentStatus === "CONFIRMED" ? (
                  <Tooltip
                    title={`${
                      dataObj.isSessionStarted
                        ? "View Counselling"
                        : "Start Counselling"
                    }`}
                  >
                    <a>
                      <div
                        className="bordered"
                        onClick={() => {
                          setIsStartCounsellingModelVisible(true);
                          setRecord(dataObj);
                          dispatch({
                            type: "START_COUNSELLING_REQUEST",
                            payload: {
                              id: dataObj.id,
                              formData: {
                                confirmationText: "",
                              },
                            },
                          });
                        }}
                      >
                        {dataObj.isSessionStarted ? (
                          <MdPreview />
                        ) : (
                          <VscDebugStart />
                        )}
                      </div>
                    </a>
                  </Tooltip>
                ) : null}
              </>
            )}

            {isHeadBranch && (
              <Tooltip title="Update Appointment">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsUpdateAppointmentModelVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
            )}
            {/* <Tooltip title="Appointment Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsAppointmentDetailsModalVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <EyeOutlined />
                </div>
              </a>
            </Tooltip> */}
            {isHeadBranch && (
              <Tooltip title="Delete Appointment">
                <Popconfirm
                  title="Are you sure to delete this appointment?"
                  onConfirm={() => {
                    dispatch({
                      type: "DELETE_APPOINTMENT_REQUEST",
                      payload: { id: dataObj.id },
                    });
                  }}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <a>
                    <div className="bordered">
                      <DeleteOutlined />
                    </div>
                  </a>{" "}
                </Popconfirm>
              </Tooltip>
            )}
            {/* {isHeadBranch && (
              <Tooltip title="View status History">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsStatusHistoryModelVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <AiOutlineHistory />
                  </div>
                </a>
              </Tooltip>
            )} */}
            {/* <Tooltip title="Change appointment status">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    const actionStatus = dataObj.isActive ? "inactive" : "active";
                    dispatch({
                      type: "CHANGE_APPOINTMENT_STATUS_REQUEST",
                      payload: { id: dataObj.id, actionStatus },
                    });
                  }}
                >
                  <MdOutlineDisabledVisible />
                </div>
              </a>
            </Tooltip> */}
          </Space>
        ),
      };
    });
  } else if (appointmentType === "pendingAppointments") {
    data = AppointmentState.pendingAppointments?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        id: `${appointmentData.appointmentPerfix ?? ""}${dataObj.id}`,
        leadId: `${appointmentData.leadPrefix ?? ""}${dataObj.leadId}`,

        firstName: (
          <Tooltip title="Appointment Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  setIsAppointmentDetailsModalVisible(true);
                }}
              >
                {dataObj.firstName ?? ""} {dataObj.lastName ?? ""}
              </div>
            </a>
          </Tooltip>
        ),
        appointmentStatus: dataObj?.appointmentStatus
          ? fourTagResponses(
              dataObj?.appointmentStatus,
              "PENDING",
              "COMPLETED",
              "CONFIRMED",
              "CANCELLED"
            )
          : "N/A",
        // dataObj.appointmentStatus

        mobileNumber: (
          <div className="flexColumnWithoutStyle">
            {dataObj.email !== "" && dataObj.email !== null ? (
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "2px", color: "000", color: "000" }}
              >
                <AiOutlineMail />
                <a href={`mailto:${dataObj.email}`}>{dataObj.email ?? "N/A"}</a>
              </div>
            ) : null}
            {dataObj.mobile !== "" && dataObj.mobile !== null ? (
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "2px", color: "000" }}
              >
                <CiMobile3 />
                <a href={`tel:${dataObj.mobile}`}>{dataObj.mobile ?? "N/A"}</a>
              </div>
            ) : null}
          </div>
        ),
        // dataObj.mobile !== "" && dataObj.mobile !== null
        //   ? dataObj.mobile
        //   : "N/A",
        bookingDate:
          dataObj.counsellingBooking !== null &&
          dataObj.counsellingBooking?.bookingDate !== null
            ? `${dataObj.counsellingBooking?.bookingDate} ${
                dataObj.counsellingBooking?.startTime &&
                dataObj.counsellingBooking?.endTime
                  ? `${dataObj.counsellingBooking?.startTime}-${dataObj.counsellingBooking?.endTime}`
                  : ""
              }`
            : dataObj.appointmentDate
            ? formattedDate(dataObj.appointmentDate)
            : "N/A",
        bookingDay: dataObj.counsellingBooking?.bookingDay,
        sessionDuration: dataObj.counsellingBooking?.sessionTime,
        sessionTime:
          dataObj.counsellingBooking?.startTime +
          "-" +
          dataObj.counsellingBooking?.endTime,
        email: dataObj.email,
        campaign:
          dataObj?.leadResponse?.customerResponse?.campaign?.name ?? "N/A",

        consultant: dataObj.counsellor ? (
          dataObj.counsellor?.fullName
        ) : dataObj.counsellingBooking &&
          dataObj.counsellingBooking?.consultant &&
          dataObj.counsellingBooking?.consultant?.fullName ? (
          dataObj.counsellingBooking?.consultant?.fullName
        ) : (
          <Tooltip title="Click to add counsellor">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsStatusHistoryCounsellorAddModelVisible(true);
                  setRecord(dataObj);
                }}
              >
                N/A
              </div>
            </a>
          </Tooltip>
        ),
        leadStatus: dataObj.leadStatus ? (
          <>
            {dataObj.leadStatus === "BOOK_AN_APPOINTMENT" ? (
              <Tag color={dataObj.leadStatusColorCode}>IN PROGRESS</Tag>
            ) : (
              <Tag color={dataObj.leadStatusColorCode}>
                {dataObj.leadStatus}
              </Tag>
            )}
          </>
        ) : (
          "N/A"
        ),
        actions: (
          <Space size="middle">
            {isHeadBranch && (
              <Tooltip title="Update Appointment">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsUpdateAppointmentModelVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
            )}
            {/* <Tooltip title="Appointment Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsAppointmentDetailsModalVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <EyeOutlined />
                </div>
              </a>
            </Tooltip> */}
            {isHeadBranch && (
              <Tooltip title="Delete Appointment">
                <Popconfirm
                  title="Are you sure to delete this appointment?"
                  onConfirm={() => {
                    dispatch({
                      type: "DELETE_APPOINTMENT_REQUEST",
                      payload: { id: dataObj.id },
                    });
                  }}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <a>
                    <div className="bordered">
                      <DeleteOutlined />
                    </div>
                  </a>{" "}
                </Popconfirm>
              </Tooltip>
            )}
            {/* {isHeadBranch && (
              <Tooltip title="View status History">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsStatusHistoryModelVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <AiOutlineHistory />
                  </div>
                </a>
              </Tooltip>
            )} */}
            {/* <Tooltip title="Change appointment status">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    const actionStatus = dataObj.isActive ? "inactive" : "active";
                    dispatch({
                      type: "CHANGE_APPOINTMENT_STATUS_REQUEST",
                      payload: { id: dataObj.id, actionStatus },
                    });
                  }}
                >
                  <MdOutlineDisabledVisible />
                </div>
              </a>
            </Tooltip> */}
          </Space>
        ),
      };
    });
  } else if (appointmentType === "confirmedAppointments") {
    data = AppointmentState.confirmedAppointments?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        id: `${appointmentData.appointmentPerfix ?? ""}${dataObj.id}`,
        leadId: `${appointmentData.leadPrefix ?? ""}${dataObj.leadId}`,

        firstName: (
          <Tooltip title="Appointment Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  setIsAppointmentDetailsModalVisible(true);
                }}
              >
                {dataObj.firstName ?? ""} {dataObj.lastName ?? ""}
              </div>
            </a>
          </Tooltip>
        ),
        appointmentStatus: dataObj?.appointmentStatus
          ? fourTagResponses(
              dataObj?.appointmentStatus,
              "PENDING",
              "COMPLETED",
              "CONFIRMED",
              "CANCELLED"
            )
          : "N/A",

        mobileNumber: (
          <div className="flexColumnWithoutStyle">
            {dataObj.email !== "" && dataObj.email !== null ? (
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "2px", color: "000", color: "000" }}
              >
                <AiOutlineMail />
                <a href={`mailto:${dataObj.email}`}>{dataObj.email ?? "N/A"}</a>
              </div>
            ) : null}
            {dataObj.mobile !== "" && dataObj.mobile !== null ? (
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "2px", color: "000" }}
              >
                <CiMobile3 />
                <a href={`tel:${dataObj.mobile}`}>{dataObj.mobile ?? "N/A"}</a>
              </div>
            ) : null}
          </div>
        ),
        // dataObj.mobile !== "" && dataObj.mobile !== null
        //   ? dataObj.mobile
        //   : "N/A",
        bookingDate:
          dataObj.counsellingBooking !== null &&
          dataObj.counsellingBooking?.bookingDate !== null
            ? `${dataObj.counsellingBooking?.bookingDate} ${
                dataObj.counsellingBooking?.startTime &&
                dataObj.counsellingBooking?.endTime
                  ? `${dataObj.counsellingBooking?.startTime}-${dataObj.counsellingBooking?.endTime}`
                  : ""
              }`
            : dataObj.appointmentDate
            ? formattedDate(dataObj.appointmentDate)
            : "N/A",
        bookingDay: dataObj.counsellingBooking?.bookingDay,
        sessionDuration: dataObj.counsellingBooking?.sessionTime,
        sessionTime:
          dataObj.counsellingBooking?.startTime +
          "-" +
          dataObj.counsellingBooking?.endTime,
        email: dataObj.email,
        campaign:
          dataObj?.leadResponse?.customerResponse?.campaign?.name ?? "N/A",

        consultant: dataObj.counsellor ? (
          dataObj.counsellor?.fullName
        ) : dataObj.counsellingBooking &&
          dataObj.counsellingBooking?.consultant &&
          dataObj.counsellingBooking?.consultant?.fullName ? (
          dataObj.counsellingBooking?.consultant?.fullName
        ) : (
          <Tooltip title="Click to add counsellor">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsStatusHistoryCounsellorAddModelVisible(true);
                  setRecord(dataObj);
                }}
              >
                N/A
              </div>
            </a>
          </Tooltip>
        ),
        leadStatus: dataObj.leadStatus ? (
          <>
            {dataObj.leadStatus === "BOOK_AN_APPOINTMENT" ? (
              <Tag color={dataObj.leadStatusColorCode}>IN PROGRESS</Tag>
            ) : (
              <Tag color={dataObj.leadStatusColorCode}>
                {dataObj.leadStatus}
              </Tag>
            )}
          </>
        ) : (
          "N/A"
        ),
        actions: (
          <Space size="middle">
            {isHeadBranch && (
              <Tooltip
                title={`${
                  dataObj.isSessionStarted
                    ? "View Counselling"
                    : "Start Counselling"
                }`}
              >
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsStartCounsellingModelVisible(true);
                      setRecord(dataObj);
                      dispatch({
                        type: "START_COUNSELLING_REQUEST",
                        payload: {
                          id: dataObj.id,
                          formData: {
                            confirmationText: "",
                          },
                        },
                      });
                    }}
                  >
                    {dataObj.isSessionStarted ? (
                      <MdPreview />
                    ) : (
                      <VscDebugStart />
                    )}
                  </div>
                </a>
              </Tooltip>
            )}
            {isHeadBranch && (
              <Tooltip title="Update Appointment">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsUpdateAppointmentModelVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
            )}
            {/* <Tooltip title="Appointment Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsAppointmentDetailsModalVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <EyeOutlined />
                </div>
              </a>
            </Tooltip> */}
            {isHeadBranch && (
              <Tooltip title="Delete Appointment">
                <Popconfirm
                  title="Are you sure to delete this appointment?"
                  onConfirm={() => {
                    dispatch({
                      type: "DELETE_APPOINTMENT_REQUEST",
                      payload: { id: dataObj.id },
                    });
                  }}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <a>
                    <div className="bordered">
                      <DeleteOutlined />
                    </div>
                  </a>{" "}
                </Popconfirm>
              </Tooltip>
            )}
            {/* {isHeadBranch && (
              <Tooltip title="View status History">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsStatusHistoryModelVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <AiOutlineHistory />
                  </div>
                </a>
              </Tooltip>
            )} */}
            {/* <Tooltip title="Change appointment status">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    const actionStatus = dataObj.isActive ? "inactive" : "active";
                    dispatch({
                      type: "CHANGE_APPOINTMENT_STATUS_REQUEST",
                      payload: { id: dataObj.id, actionStatus },
                    });
                  }}
                >
                  <MdOutlineDisabledVisible />
                </div>
              </a>
            </Tooltip> */}
          </Space>
        ),
      };
    });
  } else if (appointmentType === "completedAppointments") {
    data = AppointmentState.completedAppointments?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        id: `${appointmentData.appointmentPerfix ?? ""}${dataObj.id}`,
        leadId: `${appointmentData.leadPrefix ?? ""}${dataObj.leadId}`,

        firstName: (
          <Tooltip title="Appointment Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  setIsAppointmentDetailsModalVisible(true);
                }}
              >
                {dataObj.firstName ?? ""} {dataObj.lastName ?? ""}
              </div>
            </a>
          </Tooltip>
        ),
        appointmentStatus: dataObj?.appointmentStatus
          ? fourTagResponses(
              dataObj?.appointmentStatus,
              "PENDING",
              "COMPLETED",
              "CONFIRMED",
              "CANCELLED"
            )
          : "N/A",

        mobileNumber: (
          <div className="flexColumnWithoutStyle">
            {dataObj.email !== "" && dataObj.email !== null ? (
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "2px", color: "000", color: "000" }}
              >
                <AiOutlineMail />
                <a href={`mailto:${dataObj.email}`}>{dataObj.email ?? "N/A"}</a>
              </div>
            ) : null}
            {dataObj.mobile !== "" && dataObj.mobile !== null ? (
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "2px", color: "000" }}
              >
                <CiMobile3 />
                <a href={`tel:${dataObj.mobile}`}>{dataObj.mobile ?? "N/A"}</a>
              </div>
            ) : null}
          </div>
        ),
        // dataObj.mobile !== "" && dataObj.mobile !== null
        //   ? dataObj.mobile
        //   : "N/A",

        bookingDate:
          dataObj.counsellingBooking !== null &&
          dataObj.counsellingBooking?.bookingDate !== null
            ? `${dataObj.counsellingBooking?.bookingDate} ${
                dataObj.counsellingBooking?.startTime &&
                dataObj.counsellingBooking?.endTime
                  ? `${dataObj.counsellingBooking?.startTime}-${dataObj.counsellingBooking?.endTime}`
                  : ""
              }`
            : dataObj.appointmentDate
            ? formattedDate(dataObj.appointmentDate)
            : "N/A",
        bookingDay: dataObj.counsellingBooking?.bookingDay,
        sessionDuration: dataObj.counsellingBooking?.sessionTime,
        sessionTime:
          dataObj.counsellingBooking?.startTime +
          "-" +
          dataObj.counsellingBooking?.endTime,
        email: dataObj.email,
        campaign:
          dataObj?.leadResponse?.customerResponse?.campaign?.name ?? "N/A",

        consultant: dataObj.counsellor ? (
          dataObj.counsellor?.fullName
        ) : dataObj.counsellingBooking &&
          dataObj.counsellingBooking?.consultant &&
          dataObj.counsellingBooking?.consultant?.fullName ? (
          dataObj.counsellingBooking?.consultant?.fullName
        ) : (
          <Tooltip title="Click to add counsellor">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsStatusHistoryCounsellorAddModelVisible(true);
                  setRecord(dataObj);
                }}
              >
                N/A
              </div>
            </a>
          </Tooltip>
        ),
        leadStatus: dataObj.leadStatus ? (
          <>
            {dataObj.leadStatus === "BOOK_AN_APPOINTMENT" ? (
              <Tag color={dataObj.leadStatusColorCode}>IN PROGRESS</Tag>
            ) : (
              <Tag color={dataObj.leadStatusColorCode}>
                {dataObj.leadStatus}
              </Tag>
            )}
          </>
        ) : (
          "N/A"
        ),

        actions: (
          <Space size="middle">
            {isHeadBranch && (
              <Tooltip
                title={`${
                  dataObj.isSessionStarted
                    ? "View Counselling"
                    : "Start Counselling"
                }`}
              >
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsStartCounsellingModelVisible(true);
                      setRecord(dataObj);
                      dispatch({
                        type: "START_COUNSELLING_REQUEST",
                        payload: {
                          id: dataObj.id,
                          formData: {
                            confirmationText: "",
                          },
                        },
                      });
                    }}
                  >
                    {dataObj.isSessionStarted ? (
                      <MdPreview />
                    ) : (
                      <VscDebugStart />
                    )}
                  </div>
                </a>
              </Tooltip>
            )}
            {isHeadBranch && (
              <Tooltip title="Update Appointment">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsUpdateAppointmentModelVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
            )}
            {/* <Tooltip title="Appointment Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsAppointmentDetailsModalVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <EyeOutlined />
                </div>
              </a>
            </Tooltip> */}
            {isHeadBranch && (
              <Tooltip title="Delete Appointment">
                <Popconfirm
                  title="Are you sure to delete this appointment?"
                  onConfirm={() => {
                    dispatch({
                      type: "DELETE_APPOINTMENT_REQUEST",
                      payload: { id: dataObj.id },
                    });
                  }}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <a>
                    <div className="bordered">
                      <DeleteOutlined />
                    </div>
                  </a>{" "}
                </Popconfirm>
              </Tooltip>
            )}
            {/* {isHeadBranch && (
              <Tooltip title="View status History">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsStatusHistoryModelVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <AiOutlineHistory />
                  </div>
                </a>
              </Tooltip>
            )} */}
            {/* <Tooltip title="Change appointment status">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    const actionStatus = dataObj.isActive ? "inactive" : "active";
                    dispatch({
                      type: "CHANGE_APPOINTMENT_STATUS_REQUEST",
                      payload: { id: dataObj.id, actionStatus },
                    });
                  }}
                >
                  <MdOutlineDisabledVisible />
                </div>
              </a>
            </Tooltip> */}
          </Space>
        ),
      };
    });
  }

  return (
    <SiderDemo>
      <div className="flexColumn">
        <div className="leadFlexRow">
          <div style={{ width: "30%" }}>
            <button
              className="button"
              onClick={() => {
                setIsAddAppointmentModelVisible(true);
              }}
              disabled={!isHeadBranch}
            >
              <span>Add Appointment</span>
            </button>
          </div>

          <Segmented
            // style={{ width: "40%" }}
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>ALL</div>
                  </div>
                ),
                value: "allAppointments",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>PENDING</div>
                  </div>
                ),
                value: "pendingAppointments",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>CONFIRMED</div>
                  </div>
                ),
                value: "confirmedAppointments",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>COMPLETED</div>
                  </div>
                ),
                value: "completedAppointments",
              },
            ]}
            onChange={(value) => {
              setPage(1);
              setPageSize(10);
              setAppointmentType(value);
            }}
          />
          <Search
            className="search"
            style={{
              width: "30%",
              borderRadius: "10px ! important",
            }}
            placeholder="Search for appointments"
            allowClear
            size="large"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              onSearch(e.target.value);
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
          }}
        >
          {AppointmentState.isLoading ? (
            <TableSkeleton />
          ) : (
            <Table
              bordered
              columns={columns}
              dataSource={data}
              pagination={false}
            />
          )}

          {AppointmentState.appointments?.totalData && (
            <Pagination
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                alignSelf: "flex-end",
              }}
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              defaultCurrent={1}
              current={page}
              defaultPageSize={pageSize}
              onChange={onShowSizeChange}
              total={AppointmentState.appointments?.totalPage * 10}
            />
          )}
        </div>
      </div>
      {isAddAppointmentModelVisible && (
        <AddAppointment
          appointmentReminderData={appointmentData}
          isAddAppointmentModalVisible={isAddAppointmentModelVisible}
          setIsAddAppointmentModelVisible={setIsAddAppointmentModelVisible}
        />
      )}
      {isUpdateAppointmentModelVisible && (
        <UpdateAppointment
          record={record}
          isUpdateAppointmentModelVisible={isUpdateAppointmentModelVisible}
          setIsUpdateAppointmentModelVisible={
            setIsUpdateAppointmentModelVisible
          }
        />
      )}
      {isAppointmentDetailsModalVisible && (
        <AppointmentDetails
          appointmentData={appointmentData}
          appointmentObj={record}
          isAppointmentDetailsModalVisible={isAppointmentDetailsModalVisible}
          setIsAppointmentDetailsModalVisible={
            setIsAppointmentDetailsModalVisible
          }
        />
      )}
      {isStatusHistoryModelVisible && (
        <StatusHistory
          type={"APPOINTMENT"}
          appointmentData={appointmentData}
          appointmentRecord={record}
          record={record?.leadResponse}
          isStatusHistoryModalVisible={isStatusHistoryModelVisible}
          setIsStatusHistoryModalVisible={setIsStatusHistoryModelVisible}
        />
      )}
      {isStartCounsellingModelVisible && (
        <StartCounselling
          record={record}
          isStartCounsellingModalVisible={isStartCounsellingModelVisible}
          setIsStartCounsellingModalVisible={setIsStartCounsellingModelVisible}
        />
      )}
      {isStatusHistoryCounsellorAddModelVisible && (
        <StatusHistoryConsellorAdd
          type={"APPOINTMENT"}
          appointmentData={appointmentData}
          appointmentRecord={record}
          record={record?.leadResponse}
          isStatusHistoryModalVisible={isStatusHistoryCounsellorAddModelVisible}
          setIsStatusHistoryModalVisible={
            setIsStatusHistoryCounsellorAddModelVisible
          }
        />
      )}
    </SiderDemo>
  );
};

export default Appointments;
