import React from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import { useEffect } from "react";

import { useDispatch } from "react-redux";
import AddressAutocomplete from "./AddressAutocomplete";
import { useSelector } from "react-redux";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import AddCountry from "../Leads/Country/AddCountry";
import moment from "moment";
import { date00 } from "../../HelperFunction";
import AddState from "../Counsel/AddState";
const { Option } = Select;

const UpdateCompanies = ({
  record,
  isUpdateCompaniesModelVisible,
  setIsUpdateCompaniesModelVisible,
}) => {
  const store = useSelector((state) => {
    return {
      updateCompanyState: state.LeadReducer,
    };
  });
  const [updateCompanyData, setUpdateCompanyData] = useState({
    companyName: record?.companyName,
    name: record?.fullName,
    phone: record?.phoneNumber,
    mobile: record?.mobile,
    email: record?.email,
    staff: record?.staff,
    abn: record?.abn,
    countryName: record?.country?.name,
    countryId: record?.country?.id,
    agreementStartDate:
      record?.agreementStartDate !== null &&
      record?.agreementStartDate !== undefined &&
      record?.agreementStartDate !== ""
        ? record?.agreementStartDate
        : null,
    agreementExpiryDate:
      record?.agreementExpiryDate !== null &&
      record?.agreementExpiryDate !== undefined &&
      record?.agreementExpiryDate !== ""
        ? record?.agreementExpiryDate
        : null,
    state: record?.state,
    stateId: record?.state?.id,
    suburb: record?.suburb,
    postCode: record?.postCode,
    website: record?.website,
    commission: record?.commission,
    commissionType: record?.commissionType,
    isDirectPartner: record?.isDirectPartner,
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [address, setAddress] = useState(record?.address);
  const [address2, setAddress2] = useState(record?.address2);
  const [isAddCountryModalVisible, setIsAddCountryModalVisible] =
    useState(false);
  const [isAddStateModalVisible, setIsAddStateModalVisible] = useState(false);

  useEffect(() => {
    if (isUpdateCompaniesModelVisible === true) {
      dispatch({ type: "FETCH_LEADS_DROPDOWN_REQUEST" });
    }
  }, []);
  const updateCompanySubmitHandler = (values) => {
    console.log("updateCompanyData", updateCompanyData);
    const formData = {
      ...values,
      country: updateCompanyData.countryId,
      state: updateCompanyData.stateId,
      agreementStartDate: date00(updateCompanyData.agreementStartDate),
      agreementExpiryDate: date00(updateCompanyData.agreementExpiryDate),
    };
    dispatch({
      type: "UPDATE_COMPANY_REQUEST",
      payload: {
        id: record.id,
        values: formData,
      },
    });
    form.resetFields();
    setIsUpdateCompaniesModelVisible(false);
  };
  console.log("record form update ", record?.agreementStartDate);
  console.log("record form update ", record?.agreementExpiryDate);
  console.log("record form updateCompanyData ", updateCompanyData);
  return (
    <Drawer
      title="Update Company"
      open={isUpdateCompaniesModelVisible}
      onClose={() => {
        setIsUpdateCompaniesModelVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Form
        layout="vertical"
        onFinish={updateCompanySubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
        fields={[
          {
            name: ["companyName"],
            value: updateCompanyData?.companyName,
          },
          {
            name: ["name"],
            value: updateCompanyData?.name,
          },
          {
            name: ["phone"],
            value: updateCompanyData?.phone,
          },
          {
            name: ["mobile"],
            value: updateCompanyData?.mobile,
          },
          {
            name: ["email"],
            value: updateCompanyData?.email,
          },
          {
            name: ["companyAddress"],
            value: updateCompanyData?.address,
          },
          {
            name: ["staff"],
            value: updateCompanyData?.staff,
          },
          {
            name: ["abn"],
            value: updateCompanyData?.abn,
          },
          {
            name: ["country"],
            value: updateCompanyData?.countryName,
          },
          {
            name: ["agreementStartDate"],
            value:
              updateCompanyData?.agreementStartDate !== null &&
              updateCompanyData?.agreementStartDate !== undefined &&
              updateCompanyData?.agreementStartDate !== ""
                ? moment.utc(updateCompanyData?.agreementStartDate).local()
                : null,
          },
          {
            name: ["agreementExpiryDate"],
            value:
              updateCompanyData?.agreementExpiryDate !== null &&
              updateCompanyData?.agreementExpiryDate !== undefined &&
              updateCompanyData?.agreementExpiryDate !== ""
                ? moment.utc(updateCompanyData?.agreementExpiryDate).local()
                : null,
          },
          {
            name: ["state"],
            value: updateCompanyData?.state,
          },
          {
            name: ["suburb"],
            value: updateCompanyData?.suburb,
          },
          {
            name: ["postCode"],
            value: updateCompanyData?.postCode,
          },
          {
            name: ["website"],
            value: updateCompanyData?.website,
          },
          {
            name: ["commission"],
            value: updateCompanyData?.commission,
          },
          {
            name: ["commissionType"],
            value: updateCompanyData?.commissionType,
          },
          {
            name: ["isDirectPartner"],
            value: updateCompanyData?.isDirectPartner,
          },
        ]}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Company Name"
            name={"companyName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter company name!",
              },
            ]}
          >
            <Input
              placeholder="Company name"
              onChange={(e) => {
                setUpdateCompanyData((previousData) => {
                  return {
                    ...previousData,
                    companyName: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Full Name"
            name={"name"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter fullName!",
              },
            ]}
          >
            <Input
              placeholder="Full Name"
              onChange={(e) => {
                setUpdateCompanyData((previousData) => {
                  return {
                    ...previousData,
                    name: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Email"
            name={"email"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter email!",
              },
            ]}
          >
            <Input
              type="email"
              placeholder="e.g. company@domain.com"
              onChange={(e) => {
                setUpdateCompanyData((previousData) => {
                  return {
                    ...previousData,
                    email: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>

          <Form.Item
            label="Phone Number"
            name={"phone"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter Phone Number!",
              },
              {
                pattern: /^[\d]{9,11}$/,
                message: "Phone number should contain 9 to 11 numbers",
              },
            ]}
          >
            <Input
              type="number"
              placeholder="Please enter Phone Number!"
              onChange={(e) => {
                setUpdateCompanyData((previousData) => {
                  return {
                    ...previousData,
                    phone: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Mobile Number"
            name={"mobile"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter mobile number!",
              },
              {
                pattern: /^[\d]{9,11}$/,
                message: "Mobile number should contain 9 to 11 numbers",
              },
            ]}
          >
            <Input
              type="number"
              placeholder="Mobile Number"
              onChange={(e) => {
                setUpdateCompanyData((previousData) => {
                  return {
                    ...previousData,
                    mobile: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <div
            className="flexRowWithoutStyle"
            style={{ gap: "0.3rem", width: "100%" }}
          >
            <Form.Item
              label="Country"
              name={"country"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter address!",
                },
              ]}
            >
              <Select>
                {store.updateCompanyState.leadsDropdown?.countries?.map(
                  (dataObj) => {
                    return (
                      <Option key={dataObj.id}>
                        <div
                          onClick={() => {
                            setUpdateCompanyData((previousData) => {
                              return {
                                ...previousData,
                                countryName: dataObj.countryName,
                                countryId: dataObj.id,
                              };
                            });
                          }}
                        >
                          {dataObj.countryName}
                        </div>
                      </Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddCountryModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Staff"
            name={"staff"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter staff!",
              },
            ]}
          >
            <Input
              placeholder="Staff"
              onChange={(e) => {
                setUpdateCompanyData((previousData) => {
                  return {
                    ...previousData,
                    staff: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="ABN"
            name={"abn"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter abn!",
              },
            ]}
          >
            <Input
              type="number"
              placeholder="abn"
              onChange={(e) => {
                setUpdateCompanyData((previousData) => {
                  return {
                    ...previousData,
                    abn: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Agreement Start Date"
            name={"agreementStartDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select start date!",
              },
            ]}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              // disabledDate={(cd) => {
              //   const d = new Date();
              //   return cd > d;
              // }}
              onChange={(e) => {
                console.log("e from agreementStartDate", e);
                setUpdateCompanyData((previousData) => {
                  return {
                    ...previousData,
                    agreementStartDate: e,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Agreement End Date"
            name={"agreementExpiryDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select end date!",
              },
            ]}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              disabledDate={(cd) => {
                const d = new Date();
                return cd < d;
              }}
              onChange={(e) => {
                console.log("e from agreementExpiryDate", e);

                setUpdateCompanyData((previousData) => {
                  return {
                    ...previousData,
                    agreementExpiryDate: e,
                  };
                });
              }}
            />
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{
            justifyContent: "space-between",
            alignItems: "flex-start",
            gap: "1rem",
          }}
        >
          <Form.Item
            label="Address Line 1"
            name={"companyAddress"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter address line 1!",
            //   },
            // ]}
          >
            <AddressAutocomplete setAddress={setAddress} address={address} />
          </Form.Item>
          <Form.Item
            label="Address Line 2"
            name={"address2"}
            style={{ width: "100%" }}
          >
            <AddressAutocomplete address={address2} setAddress={setAddress2} />
            {/* <Input placeholder="Address line 2" /> */}
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <div
            className="flexRowWithoutStyle"
            style={{ gap: "0.3rem", width: "100%" }}
          >
            <Form.Item label="State" name={"state"} style={{ width: "100%" }}>
              {/* <Select>
                {store.updateCompanyState.leadsDropdown?.state?.map(
                  (dataObj) => {
                    return (
                      <Option key={dataObj.id}>
                        <div
                          onClick={() => {
                            setUpdateCompanyData((previousData) => {
                              return {
                                ...previousData,
                                state: dataObj.stateName,
                                stateId: dataObj.id,
                              };
                            });
                          }}
                        >
                          {dataObj.stateName}
                        </div>
                      </Option>
                    );
                  }
                )}
              </Select> */}
              <Input
                placeholder="State"
                onChange={(e) => {
                  setUpdateCompanyData((previousData) => {
                    return {
                      ...previousData,
                      state: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
            {/* <a
              className="plusButton"
              onClick={() => {
                setIsAddStateModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a> */}
          </div>
          <Form.Item label="Suburb" name={"suburb"} style={{ width: "100%" }}>
            <Input
              placeholder="Suburb"
              onChange={(e) => {
                setUpdateCompanyData((previousData) => {
                  return {
                    ...previousData,
                    suburb: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Post Code"
            name={"postCode"}
            style={{ width: "100%" }}
          >
            <Input
              placeholder="Post Code"
              onChange={(e) => {
                setUpdateCompanyData((previousData) => {
                  return {
                    ...previousData,
                    postCode: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Website"
            name={"website"}
            style={{ width: "100%" }}
            rules={[
              {
                type: "url",
                message: "Please enter valid url!",
              },
            ]}
          >
            <Input
              placeholder="eg.www.companyname.com"
              onChange={(e) => {
                setUpdateCompanyData((previousData) => {
                  return {
                    ...previousData,
                    website: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{
            justifyContent: "space-between",
            gap: "1rem",
            alignItems: "flex-end",
          }}
        >
          <div style={{ display: "flex", width: "100%", gap: "0.5rem" }}>
            <Form.Item
              label="Commission"
              name={"commission"}
              style={{ width: "100%" }}
            >
              <Input
                type="number"
                placeholder="Commission"
                onChange={(e) => {
                  setUpdateCompanyData((previousData) => {
                    return {
                      ...previousData,
                      commission: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label={"Commission Type"}
              name={"commissionType"}
              style={{ width: "100%" }}
            >
              <Radio.Group
                onChange={(e) => {
                  setUpdateCompanyData((previousData) => {
                    return {
                      ...previousData,
                      commissionType: e.target.value,
                    };
                  });
                }}
              >
                <Radio value={"FLAT"}>is Flat</Radio>
                <Radio value={"PERCENTAGE"}>is Percentage</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <Form.Item
            name={"isDirectPartner"}
            valuePropName="checked"
            style={{ width: "100%" }}
          >
            <Checkbox
              onChange={(e) => {
                setUpdateCompanyData((previousData) => {
                  return {
                    ...previousData,
                    isDirectPartner: e.target.checked,
                  };
                });
              }}
            >
              Is Direct Partner?
            </Checkbox>
          </Form.Item>
        </div>
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Update Company
          </Button>
        </Form.Item>
      </Form>
      {isAddCountryModalVisible && (
        <AddCountry
          isAddCountryModalVisible={isAddCountryModalVisible}
          setIsAddCountryModalVisible={setIsAddCountryModalVisible}
        />
      )}
      {isAddStateModalVisible && (
        <AddState
          isAddStateModalVisible={isAddStateModalVisible}
          setIsAddStateModalVisible={setIsAddStateModalVisible}
        />
      )}
    </Drawer>
  );
};

export default UpdateCompanies;
