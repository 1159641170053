import { Button, Card, Form, Input, TimePicker, Select } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment/moment";
import OrganizationalSettings from "../OrganizationalSettings";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
const { Option } = Select;

const EmployeeSettings = () => {
  const store = useSelector((state) => {
    return {
      generalState: state.OrganizationSettingsReducer,
      // profileState: state.ProfileReducer,
    };
  });
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch({ type: "FETCH_PROFILE_REQUEST" });
    dispatch({
      type: "FETCH_REMINDER_EXIPRY_WORKING_HOUR_REQUEST",
      isSuccessFn: true,
      successFunction: successFunction,
    });
  }, []);
  const successFunction = (response) => {
    console.log("response from reminder expiry date", response);

    setEmployeeSettingsData((previousData) => {
      return {
        ...previousData,
        casePerfix: response?.data?.prefix?.casePrefix,
      };
    });
    const makeSameScheduleDays =
      response?.data?.openingSchedule?.scheduleDays?.length > 0 &&
      response?.data?.openingSchedule?.scheduleDays?.map((dataObj) => {
        console.log("rendered from map", dataObj);
        return {
          days: dataObj?.scheduleDays,
          start: dataObj.startTime,
          end: dataObj?.endTime,
          breakStartTime: dataObj?.breakStartTime,
          breakEndTime: dataObj?.breakEndTime,
        };
      });

    // response?.data?.openingSchedule?.scheduleDays?.length > 0 &&
    //   setScheduleDay(makeSameScheduleDays);
    response?.data?.openingSchedule?.scheduleDays?.length > 0 &&
      setScheduleDay(
        makeSameScheduleDays.sort(
          (a, b) => dayToNumber[a.days] - dayToNumber[b.days]
        )
      );
  };
  const [employeeSettingsData, setEmployeeSettingsData] = useState({
    casePerfix: "",
  });
  const [form] = Form.useForm();

  const [scheduleDay, setScheduleDay] = useState([
    {
      days: "",
      start: "",
      end: "",
      breakStartTime: "",
      breakEndTime: "",
    },
  ]);

  const dayToNumber = {
    MONDAY: 1,
    TUSEDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
    SUNDAY: 7,
  };

  const removeScheduledDayInput = (index) => {
    const list = [...scheduleDay];
    list.splice(index, 1);
    setScheduleDay(list);
  };
  const addScheduledDayInput = () => {
    setScheduleDay([
      ...scheduleDay,
      {
        days: "",
        start: "",
        end: "",
        breakStartTime: "",
        breakEndTime: "",
      },
    ]);
  };
  const handleScheduleDayChange = (e, index) => {
    console.log(e, index);
    const list = [...scheduleDay];
    list[index].days = e;
    setScheduleDay(list);
  };
  const handleScheduleStartTimeChange = (e, index) => {
    console.log(e, index, "from handle shchedule start time");
    const list = [...scheduleDay];
    list[index].start = e;
    setScheduleDay(list);
  };
  const handleScheduleEndTimeChange = (e, index) => {
    console.log(e, index, "from handle shchedule start time");
    const list = [...scheduleDay];
    list[index].end = e;
    setScheduleDay(list);
  };
  const handleScheduleBreakStartTimeChange = (e, index) => {
    console.log(e, index, "from handle shchedule start time");
    const list = [...scheduleDay];
    list[index].breakStartTime = e;
    setScheduleDay(list);
  };
  const handleScheduleBreakEndTimeChange = (e, index) => {
    console.log(e, index, "from handle shchedule start time");
    const list = [...scheduleDay];
    list[index].breakEndTime = e;
    setScheduleDay(list);
  };

  console.log("record frm incoming schedule day update employee", scheduleDay);
  return (
    <OrganizationalSettings>
      <div>
        <h3>Employee Settings</h3>
        <Card>
          <Form
            layout="vertical"
            onFinish={(values) => {
              console.log("values", values);
            }}
            colon={true}
            form={form}
          >
            <div
              style={{
                backgroundColor: "#eeeeee55",
                borderRadius: "0.5rem",
                padding: "0.5rem",
                margin: "0.5rem",
              }}
            >
              <h3>Opening Schedule Days:</h3>
              {scheduleDay?.map((singleDay, index) => {
     
                return (
                  <div key={index}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          gap: "0.5rem",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Form.Item
                          label="Schedule Day"
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please select a day!",
                            },
                          ]}
                        >
                          <Select
                            name={"days"}
                            onChange={(e) => {
                              console.log("day of wweek", e);
                              handleScheduleDayChange(e, index);
                            }}
                            value={scheduleDay[index].days}
                            options={[
                              {
                                value: "SUNDAY",
                                label: "Sunday",
                              },
                              {
                                value: "MONDAY",
                                label: "Monday",
                              },
                              {
                                value: "TUESDAY",
                                label: "Tuesday",
                              },
                              {
                                value: "WEDNESDAY",
                                label: "Wednesday",
                              },
                              {
                                value: "THURSDAY",
                                label: "Thursday",
                              },
                              {
                                value: "FRIDAY",
                                label: "Friday",
                              },
                              {
                                value: "SATURDAY",
                                label: "Saturday",
                              },
                            ]}
                          />
                        </Form.Item>

                        <Form.Item
                          label="Start Time"
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please pick a start time in that day!",
                            },
                          ]}
                        >
                          <TimePicker
                            // onChange={(e) => {
                            //   console.log(
                            //     "from start time of schedule week",
                            //     e
                            //   );
                            //   handleScheduleStartTimeChange(e, index);
                            // }}
                            // value={moment(scheduleDay[index].start).format(
                            //   "HH:mm:ss"
                            // )}
                            // `Fri Jan 13 2023 ${scheduleDay[index].start} GMT+0545 (Nepal Time)`
                            name={"start"}
                            // defaultValue={dayjs("15:00:00", "HH:mm:ss")}
                            value={
                              scheduleDay[index].start
                                ? moment(
                                    `${scheduleDay[index].start}`,
                                    "HH:mm:00"
                                  ).local()
                                : null
                            }
                            onChange={(value) => {
                              handleScheduleStartTimeChange(
                                moment(value).format("HH:mm:00"),
                                index
                              );
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          label="End Time"
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please pick a end time in that day!",
                            },
                          ]}
                        >
                          {/* Fri Jan 13 2023 ${scheduleDay[index].end} GMT+0545 (Nepal Time) */}
                          <TimePicker
                            name={"end"}
                            value={
                              scheduleDay[index].end
                                ? moment(
                                    `${scheduleDay[index].end}`,
                                    "HH:mm:00"
                                  ).local()
                                : null
                            }
                            onChange={(e) => {
                              console.log("from end time of schedule week", e);
                              handleScheduleEndTimeChange(
                                moment(e).format("HH:mm:ss"),
                                index
                              );
                            }}
                            // value={moment(scheduleDay[index].end).format(
                            //   "HH:mm:ss"
                            // )}
                          />
                        </Form.Item>

                        <Form.Item
                          label="Break Start Time"
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message:
                                "Please pick a break start time in that day!",
                            },
                          ]}
                        >
                          <TimePicker
                            // onChange={(e) => {
                            //   console.log(
                            //     "from start time of schedule week",
                            //     e
                            //   );
                            //   handleScheduleStartTimeChange(e, index);
                            // }}
                            // value={moment(scheduleDay[index].start).format(
                            //   "HH:mm:ss"
                            // )}

                            name={"breakStartTime"}
                            // defaultValue={dayjs("15:00:00", "HH:mm:ss")}
                            value={
                              scheduleDay[index].breakStartTime
                                ? moment(
                                    `${scheduleDay[index].breakStartTime}`,
                                    "HH:mm:00"
                                  ).local()
                                : null
                            }
                            onChange={(value) => {
                              handleScheduleBreakStartTimeChange(
                                moment(value).format("HH:mm:ss"),
                                index
                              );
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Break End Time"
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message:
                                "Please pick a break end time in that day!",
                            },
                          ]}
                        >
                          <TimePicker
                            name={"breakEndTime"}
                            value={
                              scheduleDay[index].breakEndTime
                                ? moment(
                                    `${scheduleDay[index].breakEndTime}`,
                                    "HH:mm:00"
                                  ).local()
                                : null
                            }
                            onChange={(e) => {
                              console.log("from end time of schedule week", e);
                              handleScheduleBreakEndTimeChange(
                                moment(e).format("HH:mm:ss"),
                                index
                              );
                            }}
                            // value={moment(scheduleDay[index].end).format(
                            //   "HH:mm:ss"
                            // )}
                          />
                        </Form.Item>
                      </div>
                      {scheduleDay?.length > 1 && (
                        <Button
                          color="red"
                          onClick={() => removeScheduledDayInput(index)}
                          style={{
                            padding: "0",
                            borderRadius: "50%",
                            height: "1.5rem",
                            width: "1.5rem",
                          }}
                        >
                          <CloseOutlined />
                        </Button>
                      )}
                    </div>
                    {scheduleDay?.length - 1 === index && (
                      <Button
                        block
                        type="dashed"
                        onClick={addScheduledDayInput}
                      >
                        <PlusOutlined />
                        Add Day
                      </Button>
                    )}
                  </div>
                );
              })}
            </div>
            <Form.Item
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => {
                  dispatch({
                    type: "ADD_OPENING_SCHEDULE_REQUEST",
                    payload: {
                      formData: {
                        scheduleDays: [...scheduleDay],
                      },
                    },
                  });
                }}
              >
                Save Changes
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </OrganizationalSettings>
  );
};

export default EmployeeSettings;
