import { message } from "antd";
const INIT_STATE = {
  isLoading: false,
  isRegistered: false,
  isOTPverified: false,
  isSignedUp: false,
  otp: "",
  email: "",
};

const SignupReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "REGISTER_EMAIL_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "REGISTER_EMAIL_REQUEST_SUCCESS": {
      message.success(action.payload.message);
      return {
        ...state,
        otp: action.payload.otp,
        email: action.payload.userName,
        isRegistered: true,
        isLoading: false,
      };
    }
    case "REGISTER_EMAIL_REQUEST_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      } else {
        message.error("Error! Unable to register email.");
      }

      return {
        ...state,
        isLoading: false,
      };
    }
    case "OTP_VERIFICATION_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "OTP_VERIFICATION_REQUEST_SUCCESS": {
      message.success(action.payload.message);
      return {
        ...state,
        otp: action.payload.otp,
        email: action.payload.userName,
        isOTPverified: true,
        isLoading: false,
      };
    }
    case "OTP_VERIFICATION_REQUEST_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      } else {
        message.error("Error! Unable to verify OTP.");
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    case "RESEND_OTP_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "RESEND_OTP_REQUEST_SUCCESS": {
      message.success(action.payload.message);
      return {
        ...state,
        otp: action.payload.otp,
        email: action.payload.userName,
        isLoading: false,
      };
    }
    case "RESEND_OTP_REQUEST_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      } else {
        message.error("Error! Unable to resend OTP.");
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    case "SIGN_UP_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "SIGN_UP_REQUEST_SUCCESS": {
      message.success(action.payload.message);
      return {
        ...state,
        isSignedUp: true,
        isLoading: false,
      };
    }
    case "SIGN_UP_REQUEST_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      } else {
        message.error("Error! Unable to sign up.");
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    case "CHANGE_REGISTERED_STATUS": {
      return {
        ...state,
        isRegistered: false,

        isLoading: true,
      };
    }
    default:
      return state;
  }
};

export default SignupReducer;
