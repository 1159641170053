import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  clients: {},
  documentTypes: {},
  activeClients: {},
  customerLead: {},
  customerAppointments: {},
  customerCases: {},
  clientById: {},
  clientActivityLog: {},
  quotationById: {},
  clientCsv: {},
};

const ClientsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_CLIENT_BY_ID_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_CLIENT_BY_ID_REQUEST_SUCCESS": {
      return {
        ...state,
        clientById: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_CLIENT_BY_ID_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to fetch client by id.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_LEAD_CUSTOMER_BY_ID_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_LEAD_CUSTOMER_BY_ID_REQUEST_SUCCESS": {
      return {
        ...state,
        customerLead: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_LEAD_CUSTOMER_BY_ID_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load customer lead data.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_APPOINTMENT_CUSTOMER_BY_ID_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_APPOINTMENT_CUSTOMER_BY_ID_REQUEST_SUCCESS": {
      return {
        ...state,
        customerAppointments: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_APPOINTMENT_CUSTOMER_BY_ID_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load customer appointment data.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_CASE_CUSTOMER_BY_ID_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_CASE_CUSTOMER_BY_ID_REQUEST_SUCCESS": {
      return {
        ...state,
        customerCases: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_CASE_CUSTOMER_BY_ID_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load customer Case data.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_CLIENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_CLIENT_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        clients: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_CLIENT_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_ACTIVE_CLIENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_ACTIVE_CLIENT_REQUEST_SUCCESS": {
      return {
        ...state,
        activeClients: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ACTIVE_CLIENT_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to load clients.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_DOCUMENT_TYPES_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_DOCUMENT_TYPES_REQUEST_SUCCESS": {
      return {
        ...state,
        documentTypes: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_DOCUMENT_TYPES_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_DOCUMENT_TYPE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_DOCUMENT_TYPE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Document type added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_DOCUMENT_TYPE_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to add document type.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_CLIENT_DOCUMENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_CLIENT_DOCUMENT_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Document added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_CLIENT_DOCUMENT_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add document.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_CLIENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_CLIENT_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Clients added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_CLIENT_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add Clients.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_CLIENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_CLIENT_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Clients updated.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_CLIENT_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to update Clients.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_CLIENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DELETE_CLIENT_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Clients deleted.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_CLIENT_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to delete Clients.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_CLIENT_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "TOGGLE_CLIENT_STATUS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Clients status changed.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_CLIENT_STATUS_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to change Clients status.");

      message.error("error");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "CHANGE_CLIENT_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "CHANGE_CLIENT_STATUS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Clent status changed.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "CHANGE_CLIENT_STATUS_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to change client status.");

      return {
        ...state,
        isLoading: false,
      };
    }

    case "UPDATE_CUSTOMER_INFO_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_CUSTOMER_INFO_REQUEST_SUCCESS": {
      action.payload2.message
        ? message.success(action.payload2.message)
        : message.success("Success! client updated.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_CUSTOMER_INFO_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to update client.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_CUSTOMER_COURSE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_CUSTOMER_COURSE_REQUEST_SUCCESS": {
      action.payload2.message
        ? message.success(action.payload2.message)
        : message.success("Success! Client scores updated.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_CUSTOMER_COURSE_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to update client score.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_CUSTOMER_QUALIFICATIONS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_CUSTOMER_QUALIFICATIONS_REQUEST_SUCCESS": {
      action.payload2.message
        ? message.success(action.payload2.message)
        : message.success("Success! Client qualification added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_CUSTOMER_QUALIFICATIONS_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add client qualification.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_CUSTOMER_EXPERIENCE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_CUSTOMER_EXPERIENCE_REQUEST_SUCCESS": {
      action.payload2.message
        ? message.success(action.payload2.message)
        : message.success("Success! Customer experience added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_CUSTOMER_EXPERIENCE_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add customer experience.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_CUSTOMER_DOCUMENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_CUSTOMER_DOCUMENT_REQUEST_SUCCESS": {
      action.payload2.message
        ? message.success(action.payload2.message)
        : message.success("Success! Client document added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_CUSTOMER_DOCUMENT_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add document.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_EXPERIENCE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DELETE_EXPERIENCE_REQUEST_SUCCESS": {
      action.payload2.message
        ? message.success(action.payload2.message)
        : message.success("Success! Experience deleted.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_EXPERIENCE_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to delete experience.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_QUALIFICATION_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DELETE_QUALIFICATION_REQUEST_SUCCESS": {
      action.payload2.message
        ? message.success(action.payload2.message)
        : message.success("Success! Qualification deleted.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_QUALIFICATION_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to delete qualification.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_CUSTOMER_DOCUMENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DELETE_CUSTOMER_DOCUMENT_REQUEST_SUCCESS": {
      action.payload2.message
        ? message.success(action.payload2.message)
        : message.success("Success! Document deleted.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_CUSTOMER_DOCUMENT_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to delete document.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "EDIT_CUSTOMER_EXPERIENCE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "EDIT_CUSTOMER_EXPERIENCE_REQUEST_SUCCESS": {
      action.payload2.message
        ? message.success(action.payload2.message)
        : message.success("Success! Experience updated.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "EDIT_CUSTOMER_EXPERIENCE_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to update experience.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "EDIT_CUSTOMER_QUALIFICATIONS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "EDIT_CUSTOMER_QUALIFICATIONS_REQUEST_SUCCESS": {
      action.payload2.message
        ? message.success(action.payload2.message)
        : message.success("Success! Qualification updated.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "EDIT_CUSTOMER_QUALIFICATIONS_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to update qualification.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_CLIENT_ACTIVITY_LOG_BY_ID_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_CLIENT_ACTIVITY_LOG_BY_ID_REQUEST_SUCCESS": {
      return {
        ...state,
        clientActivityLog: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_CLIENT_ACTIVITY_LOG_BY_ID_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load client activity log.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DOWNLOAD_CSV_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DOWNLOAD_CSV_REQUEST_SUCCESS": {
      message.success("Success! CSV downloaded.");

      return {
        ...state,
        clientCsv: action.payload,
        isLoading: false,
      };
    }
    case "DOWNLOAD_CSV_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to download csv.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "IMPORT_CLIENT_CSV_FILE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "IMPORT_CLIENT_CSV_FILE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! CSV imported.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "IMPORT_CLIENT_CSV_FILE_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to import csv file.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_QUOTATION_CUSTOMER_BY_ID_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_QUOTATION_CUSTOMER_BY_ID_REQUEST_SUCCESS": {
      return {
        ...state,
        quotationById: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_QUOTATION_CUSTOMER_BY_ID_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load quotation.");

      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default ClientsReducer;
