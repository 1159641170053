import React, { useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Image,
  Input,
  Modal,
  Radio,
  Select,
  Tag,
  Timeline,
  TimePicker,
} from "antd";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import client from "./../../Assets/client.png";

import {
  date,
  dateAndTime,
  formattedDate,
  formattedDateTime,
  fourTagResponses,
  getTimeZone,
  time,
  YYYYMMDD,
} from "../../HelperFunction";
import { AiOutlineMail, AiOutlinePhone, AiOutlinePlus } from "react-icons/ai";
import AddSalesRep from "../Visas/AddSalesRep";
import AddEmployee from "../EmployeeManager/AddEmployee";
import { GoLocation } from "react-icons/go";
const { Option } = Select;
const { TextArea } = Input;

const StatusHistoryConsellorAdd = ({
  appointmentData,
  leadData,
  type,
  appointmentRecord,
  record,
  isStatusHistoryModalVisible,
  setIsStatusHistoryModalVisible,
}) => {
  const store = useSelector((state) => {
    return {
      timingDropdownState: state.AppointmentsReducer,
      addCounsellingState: state.LeadReducer,
      leadState: state.LeadReducer,
    };
  });
  const dispatch = useDispatch();
  const [isAddConsultantModalVisible, setIsAddConsultantModalVisible] =
    useState(false);
  const [leadStatus, setLeadStatus] = useState();
  useEffect(() => {
    if (isStatusHistoryModalVisible === true) {
      dispatch({ type: "FETCH_LEAD_BY_ID_REQUEST", payload: record?.leadId });
      dispatch({ type: "FETCH_LEADS_DROPDOWN_REQUEST" });
      setStatusUpdateData({
        salesRepresentativeId: record?.salesRepId,
        salesRepresentativeName: record?.salesRepName,
        updateWeightage: record?.weightage,
        reminderTime: record?.reminderDateAndTime,
        reminderDate: record?.reminderDateAndTime,
        nextAction: "",
        remarks: "",
        leadStatusId: record?.leadStatus?.id,
        leadStatusName: record?.leadStatus?.statusName,

        bookingStatus: record?.assignCounsellor ?? false,
        counsellorName: record?.assignedCounsellor?.fullName,
        counsellorId: record?.assignedCounsellor?.id,
        appointmentDate: record?.appointmentDate
          ? record?.appointmentDate
          : (type = "APPOINTMENT" ? appointmentRecord?.appointmentDate : null),
        sessionTime: record?.counsellingBooking?.sessionTime,
        todayUpdate: record?.todayDescription,
      });
    }
  }, [isStatusHistoryModalVisible]);
  const [isAddSalesRepModalVisible, setIsAddSalesRepModalVisible] =
    useState(false);

  const [statusUpdateData, setStatusUpdateData] = useState({
    salesRepresentativeId: record?.salesRepId,
    salesRepresentativeName: record?.salesRepName,
    updateWeightage: record?.weightage,
    reminderTime: record?.reminderDateAndTime,
    reminderDate: record?.reminderDateAndTime,
    remarks: "",
    nextAction: "",
    leadStatusId: record?.leadStatus?.id,
    leadStatusName: record?.leadStatus?.statusName,

    bookingStatus: record?.assignCounsellor ?? false,
    counsellorName: record?.assignedCounsellor?.fullName,
    counsellorId: record?.assignedCounsellor?.id,
    appointmentDate: record?.appointmentDate,
    sessionTime: record?.counsellingBooking?.sessionTime,
    todayUpdate: record?.todayDescription,
  });
  const [isCouncelling, setIsCouncelling] = useState(false);
  const [counsellorId, setCounsellorId] = useState();
  const [appointmentDate, setAppointmentDate] = useState();
  const [sessionTime, setSessionTime] = useState();
  useEffect(() => {
    if (
      statusUpdateData.counsellorId &&
      statusUpdateData.appointmentDate &&
      statusUpdateData.sessionTime &&
      statusUpdateData.bookingStatus
    ) {
      dispatch({
        type: "FETCH_TIMING_DROPDOWN_REQUEST",
        payload: {
          counsellorId: statusUpdateData.counsellorId,
          sessionTime: statusUpdateData.sessionTime,

          values: {
            appointmentDate: moment
              .utc(statusUpdateData.appointmentDate)
              .local()
              .format("YYYY-MM-DD"),
            timeOffset: getTimeZone(),
          },
          id: record.id,
        },
      });
    }
  }, [
    statusUpdateData.counsellorId,
    statusUpdateData.appointmentDate,
    statusUpdateData.sessionTime,
    statusUpdateData.bookingStatus,
  ]);

  const [form] = Form.useForm();
  const addStatusHistoryUpdateSubmitHandler = (value) => {
    const reminderDateAndTime = dateAndTime(
      statusUpdateData.reminderDate,
      statusUpdateData.reminderTime
    );
    const bookingDate = isCouncelling ? date(value.appointmentDate) : null;
    const bookingDay =
      statusUpdateData.leadStatusName === "BOOK_AN_APPOINTMENT"
        ? moment(statusUpdateData.appointmentDate).format("dddd").toUpperCase()
        : null;
    // const appointmentDateTime = dateAndTime(
    //   value.appointmentDate,
    //   value.appointmentTime
    // );
    const appointmentDate = date(value.appointmentDate);
    const isFromLead = type === "LEAD" ? true : false;
    const isFromAppointment = type === "APPOINTMENT" ? true : false;
    const {
      firstName,
      lastName,
      email,
      mobile,
      leadId,
      purposeId,
      purposeName,
      aboutUsId,
      contactId,
      countryId,
      countryName,
      leadSourceId,
      referral,
      sendByEmail,
      referredById,
      bookingStatus,
      wantCounselling,
      description,
      leadSourceName,
      leadBy,
      leadStatus,
      customerResponse,
      appointmentStatus,
      locationstatus,
      weightage,
    } = record;
    const formData = {
      firstName,
      lastName,
      contactId,
      email,
      mobile,
      purposeId: purposeId,
      aboutUsId: aboutUsId,
      countryId: countryId,
      leadSourceId: leadSourceId,
      referral: referral ? true : false,
      sendByEmail: sendByEmail,
      bookingStatus: bookingStatus ? true : false,
      referredById: referredById,
      weightage: weightage ?? "GOOD",
      leadId,
      isFromLead,
      // institute: { id: null, name: null },
      // state: { id: null, name: null },
      // country: { id: countryId, name: countryName },
      // purposeId: { id: purposeId, purposeName: purposeName },
      // addedBy: { id: null, emailId: null },
      // counsellingBooking: {
      //   bookingDate: null,
      //   bookingDay: null,
      //   startTime: null,
      //   endTime: null,
      //   consultant: null,
      // },
      // counsellor: counsellorId,
      // weightage: null,
      // counsellingTypeEnum: ["ONSHORE", "OFFSHORE"],
      // appointmentDateAndTime: appointmentDateTime,
      // reminderDateAndTime: reminderDateAndTime,
      // mobileNumber: mobile,
      // phone: null,
      // note: null,
      // isActive: null,
      // leadStatus: leadStatus,
      // leadStatusColorCode: "#0037B3",
      // leadSource: leadSourceName,
      // leadBy,
      // leadResponse: store.leadState.leadById,
      // query: "N/A",
      // requirement: "N/A",
      // isFromAppointment,
      // wantCounselling,
      // leadStatus: leadStatus,
      // customerResponse: customerResponse,
      // appointmentStatus,
      // locationstatus,
      // appointmentDateTime: appointmentDateTime,

      // aboutUsId,
      // countryId,
      // leadSourceId,
      // referral,
      // sendByEmail,
      // referredById,
      // bookingStatus,
      // description,
      ...value,
      consultantId:
        statusUpdateData.leadStatusName === "BOOK_AN_APPOINTMENT"
          ? statusUpdateData.counsellorId
          : null,
      salesRepresentativeId: statusUpdateData.salesRepresentativeId,
      leadStatusId: statusUpdateData.leadStatusId,
      reminderDateAndTime: reminderDateAndTime,
      bookingDate:
        statusUpdateData.bookingStatus &&
        statusUpdateData.leadStatusName === "BOOK_AN_APPOINTMENT"
          ? date(statusUpdateData.appointmentDate)
          : null,
      bookingDay: bookingDay,
      appointmentDate:
        statusUpdateData.appointmentDate &&
        statusUpdateData.leadStatusName === "BOOK_AN_APPOINTMENT"
          ? date(statusUpdateData.appointmentDate)
          : null,
      assignCounsellor:
        statusUpdateData.leadStatusName === "BOOK_AN_APPOINTMENT"
          ? statusUpdateData.bookingStatus
          : null,
    };
    if (type === "APPOINTMENT") {
      formData.id = appointmentRecord.id;
    }

    dispatch({
      type: "ADD_LEAD_STATUS_UPDATE_REQUEST",
      payload: {
        id: record.leadId,
        formData,
        isReload: true,
        reloadFn: reloadFn,
      },
    });
    dispatch({ type: "CLEAR_AVAILABLE_DATE_AND_TIME" });
    setLeadStatus(null);
    form.resetFields();
    setIsStatusHistoryModalVisible(false);
  };
  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };
  const [current, setCurrent] = useState("appointmentDetails");

  const clientDetailsTabList = [
    {
      key: "appointmentDetails",
      tab: "Appointment Details",
    },
  ];
  const reloadFn = (response) => {
    dispatch({
      type: "FETCH_APPOINTMENT_BY_LEAD_ID_REQUEST",
      payload: { leadId: record.leadId },
    });
    dispatch({
      type: "FETCH_APPOINTMENT_BY_ID_REQUEST",
      payload: { appointmentId: appointmentRecord?.id },
    });
  };

  return (
    <Modal
      title="Status History"
      open={isStatusHistoryModalVisible}
      onCancel={() => {
        setIsStatusHistoryModalVisible(false);
      }}
      footer={null}
    >
      <div>
        <Form
          layout="vertical"
          onFinish={addStatusHistoryUpdateSubmitHandler}
          colon={true}
          form={form}
          fields={[
            {
              name: ["salesRepresentative"],
              value: statusUpdateData.salesRepresentativeName,
            },
            {
              name: ["updateWeightage"],
              value: statusUpdateData.updateWeightage,
            },
            {
              name: ["reminderDate"],
              value: statusUpdateData.reminderDate
                ? moment.utc(statusUpdateData.reminderDate).local()
                : "",
            },
            {
              name: ["reminderTime"],
              value: statusUpdateData.reminderTime
                ? moment.utc(statusUpdateData.reminderTime).local()
                : "",
            },
            {
              name: ["remarks"],
              value: statusUpdateData.remarks,
            },
            {
              name: ["nextAction"],
              value: statusUpdateData.nextAction,
            },
            {
              name: ["leadStatus"],
              value: statusUpdateData.leadStatusName,
            },
            {
              name: ["bookingStatus"],
              value: statusUpdateData.bookingStatus,
            },

            {
              name: ["appointmentDate"],
              value: statusUpdateData.appointmentDate
                ? moment.utc(statusUpdateData.appointmentDate).local()
                : "",
            },
            {
              name: ["counsellor"],
              value: statusUpdateData.counsellorName,
            },
            {
              name: ["sessionTime"],
              value: statusUpdateData.sessionTime,
            },
            {
              name: ["todayUpdate"],
              value: statusUpdateData.todayUpdate,
            },
          ]}
        >
          {/* <Form.Item label="Status" name={"leadStatus"}>
            <Select>
              {store.leadState.leadsDropdown?.leadStatus?.map((dataObj) => {
                return (
                  <Option key={dataObj.id}>
                    <div
                      onClick={() => {
                        setLeadStatus(dataObj.statusName);
                        setStatusUpdateData((previouData) => {
                          return {
                            ...previouData,
                            leadStatusId: dataObj.id,
                            leadStatusName: dataObj.statusName,
                          };
                        });
                      }}
                    >
                      {dataObj.statusName}
                    </div>
                  </Option>
                );
              })}
            </Select>
          </Form.Item> */}

          {statusUpdateData.leadStatusName === "BOOK_AN_APPOINTMENT" ? (
            <>
              <Form.Item
                label="Want to confirm appointment now?"
                name={"bookingStatus"}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please select a option!",
                //   },
                // ]}
              >
                <Radio.Group
                  onChange={(e) => {
                    setIsCouncelling(e.target.value);
                    setStatusUpdateData((previouData) => {
                      return {
                        ...previouData,
                        bookingStatus: e.target.value,
                      };
                    });
                  }}
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
              {statusUpdateData.bookingStatus === false ? (
                <>
                  <Form.Item
                    label="Appointment Date"
                    name={"appointmentDate"}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter appointment date!",
                      },
                    ]}
                  >
                    <DatePicker
                      disabledDate={(cd) => {
                        const d = new Date();
                        return cd < d;
                      }}
                      onChange={(e) => {
                        setStatusUpdateData((previouData) => {
                          return {
                            ...previouData,
                            appointmentDate: e,
                          };
                        });
                      }}
                    />
                  </Form.Item>
                  <div
                    className="flexRowWithoutStyle"
                    style={{ gap: "0.3rem", width: "100%" }}
                  >
                    <Form.Item
                      label="Counsellor"
                      name={"counsellor"}
                      style={{ width: "100%" }}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please select counsellor!",
                      //   },
                      // ]}
                    >
                      <Select>
                        {store.addCounsellingState?.leadsDropdown?.consultantList?.map(
                          (dataObj) => {
                            return (
                              <Option key={dataObj.id}>
                                <div
                                  onClick={() => {
                                    setCounsellorId(dataObj.id);
                                    setStatusUpdateData((previouData) => {
                                      return {
                                        ...previouData,
                                        counsellorName: `${dataObj.firstName} ${dataObj.lastName}`,
                                        counsellorId: dataObj.id,
                                      };
                                    });
                                  }}
                                  className={"selectOptionDiv"}
                                >
                                  {dataObj.firstName} {dataObj.lastName}
                                </div>
                              </Option>
                            );
                          }
                        )}
                      </Select>
                    </Form.Item>
                    <a
                      className="plusButton"
                      onClick={() => {
                        setIsAddConsultantModalVisible(true);
                      }}
                    >
                      <AiOutlinePlus className="iconColor" />
                    </a>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="flexRowWithoutStyle"
                    style={{
                      justifyContent: "space-between",
                      gap: "1rem",
                    }}
                  >
                    <div
                      className="flexRowWithoutStyle"
                      style={{ gap: "0.3rem", width: "100%" }}
                    >
                      <Form.Item
                        label="Counsellor"
                        name={"counsellor"}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please select counsellor!",
                          },
                        ]}
                      >
                        <Select>
                          {store.addCounsellingState?.leadsDropdown?.consultantList?.map(
                            (dataObj) => {
                              return (
                                <Option key={dataObj.id}>
                                  <div
                                    onClick={() => {
                                      setCounsellorId(dataObj.id);
                                      setStatusUpdateData((previouData) => {
                                        return {
                                          ...previouData,
                                          counsellorName: `${dataObj.firstName} ${dataObj.lastName}`,
                                          counsellorId: dataObj.id,
                                        };
                                      });
                                    }}
                                    className={"selectOptionDiv"}
                                  >
                                    {dataObj.firstName} {dataObj.lastName}
                                  </div>
                                </Option>
                              );
                            }
                          )}
                        </Select>
                      </Form.Item>
                      <a
                        className="plusButton"
                        onClick={() => {
                          setIsAddConsultantModalVisible(true);
                        }}
                      >
                        <AiOutlinePlus className="iconColor" />
                      </a>
                    </div>
                    <Form.Item
                      label="Booking Date"
                      name={"appointmentDate"}
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Please pick a booking date!",
                        },
                      ]}
                    >
                      <DatePicker
                        format={"DD-MM-YYYY"}
                        disabledDate={(cd) => {
                          const d = new Date();
                          return cd < d;
                        }}
                        onChange={(e) => {
                          setAppointmentDate(YYYYMMDD(e));
                          setStatusUpdateData((previouData) => {
                            return {
                              ...previouData,
                              appointmentDate: e,
                            };
                          });
                        }}
                      />
                    </Form.Item>
                  </div>
                  <Form.Item
                    label="Session Time"
                    name={"sessionTime"}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please select a session time!",
                      },
                    ]}
                  >
                    <Select
                      onChange={(e) => {
                        setSessionTime(e);
                        setStatusUpdateData((previouData) => {
                          return {
                            ...previouData,
                            sessionTime: e,
                          };
                        });
                      }}
                    >
                      <Option value={"THIRTY_MINUTES"}>30 Minutes</Option>
                      <Option value={"ONE_HOUR"}>1 Hour</Option>
                      <Option value={"TWO_HOUR"}>2 Hour</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Available Time Slots"
                    name={"startTime"}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please pick a your time slot!",
                      },
                    ]}
                  >
                    <Radio.Group
                      onChange={(e) => {}}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {store.timingDropdownState &&
                        store.timingDropdownState.timingDropdown.map(
                          (dataObj, index) => {
                            return (
                              <div key={index}>
                                <Radio value={dataObj.startTime}>
                                  {dataObj.startTime} - {dataObj.endTime}
                                </Radio>
                              </div>
                            );
                          }
                        )}
                    </Radio.Group>
                  </Form.Item>
                </>
              )}
            </>
          ) : null}

          <Form.Item>
            <Button block type="primary" htmlType="submit">
              Add Update
            </Button>
          </Form.Item>
        </Form>
      </div>
      {isAddSalesRepModalVisible && (
        <AddSalesRep
          isAddSalesRepModalVisible={isAddSalesRepModalVisible}
          setIsAddSalesRepModalVisible={setIsAddSalesRepModalVisible}
        />
      )}

      {isAddConsultantModalVisible && (
        <AddEmployee
          isAddEmployeeModalVisible={isAddConsultantModalVisible}
          setIsAddEmployeeModalVisible={setIsAddConsultantModalVisible}
        />
      )}
    </Modal>
  );
};

export default StatusHistoryConsellorAdd;

{
  /* <Card key={index}>
  <div>
    <div className="leadLog">Update Status:</div>
    <Tag>{dataObj.updateStatus}</Tag>
  </div>
  <div>
    <div className="leadLog">Update Remarks:</div>
    <span>{dataObj.updateRemarks}</span>
  </div>
  <div>
    <div className="leadLog">Day Update:</div>
    <span>{dataObj.dayUpdate}</span>
  </div>
  {dataObj.nextAction ?? (
    <div>
      <div className="leadLog">Next Action:</div>
      <span>{dataObj.nextAction}</span>
    </div>
  )}

  <div>
    <div className="leadLog">Updated Date and Time:</div>
    <span>{formattedDateTime(dataObj.updatedDateTime)}</span>
  </div>
  <div>
    <div className="leadLog">Updated By Employee:</div>
    <span>{dataObj.updatedByEmployee}</span>
  </div>
</Card>; */
}
