import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import Courses from "../Courses/Courses";
const { Option } = Select;

const UpdateCustomerCourse = ({
  record,
  isUpdateCustomerCourseModalVisible,
  setIsUpdateCustomerCourseModalVisible,
  setIsClientDetailsModelVisible,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (isUpdateCustomerCourseModalVisible === true) {
      setIsCompletedIELTS(record?.completedIelts);
    }
  }, [isUpdateCustomerCourseModalVisible]);
  const [form] = Form.useForm();
  const [isCompletedIELTS, setIsCompletedIELTS] = useState(false);
  const [isCompletedTofel, setIsCompletedTofel] = useState(false);
  const [isCompletedPTE, setIsCompletedPTE] = useState(false);
  const [isCompletedSAT, setIsCompletedSAT] = useState(false);
  const [isCompletedGRE, setIsCompletedGRE] = useState(false);
  const [isCompletedGMAT, setIsCompletedGMAT] = useState(false);
  const [clientCourseData, setClientCourseData] = useState({
    completedIelts: record?.completedIelts,
    completedTofel: record?.completedTofel,
    completedGmat: record?.completedGmat,
    completedGre: record?.completedGre,
    completedSat: record?.completedSat,
    completedPte: record?.completedPte,
    satIbtScore: record?.satIbtScore,
    gmatIbtScore: record?.gmatIbtScore,
    greIbtScore: record?.greIbtScore,
    pteSpeaking: record?.pteSpeaking,
    pteWriting: record?.pteWriting,
    pteReading: record?.pteReading,
    pteListening: record?.pteListening,
    pteScore: record?.pteScore,
    tofelIbtScore: record?.tofelIbtScore,
    speaking: record?.speaking,
    writing: record?.writing,
    reading: record?.reading,
    listening: record?.listening,
    totalIeltsScore: record?.totalIeltsScore,
  });
  useEffect(() => {
    if (isUpdateCustomerCourseModalVisible === true) {
      console.log("this is rendered");
      setClientCourseData({
        completedIelts: record?.completedIelts,
        completedTofel: record?.completedTofel,
        completedGmat: record?.completedGmat,
        completedGre: record?.completedGre,
        completedSat: record?.completedSat,
        completedPte: record?.completedPte,
        satIbtScore: record?.satIbtScore,
        gmatIbtScore: record?.gmatIbtScore,
        greIbtScore: record?.greIbtScore,

        pteSpeaking: record?.pteSpeaking,
        pteWriting: record?.pteWriting,
        pteReading: record?.pteReading,
        pteListening: record?.pteListening,
        pteScore: record?.pteScore,
        tofelIbtScore: record?.tofelIbtScore,
        speaking: record?.speaking,
        writing: record?.writing,
        reading: record?.reading,
        listening: record?.listening,
        totalIeltsScore: record?.totalIeltsScore,
      });
    }
  }, [isUpdateCustomerCourseModalVisible]);
  const updateCustomerCourseSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      completedIelts: clientCourseData?.completedIelts,
      completedTofel: clientCourseData?.completedTofel,
      completedGmat: clientCourseData?.completedGmat,
      completedGre: clientCourseData?.completedGre,
      completedSat: clientCourseData?.completedSat,
      completedPte: clientCourseData?.completedPte,
      satIbtScore: clientCourseData?.completedSat
        ? clientCourseData?.satIbtScore
        : null,
      gmatIbtScore: clientCourseData?.completedGmat
        ? clientCourseData?.gmatIbtScore
        : null,
      greIbtScore: clientCourseData?.completedGre
        ? clientCourseData?.greIbtScore
        : null,
      pteSpeaking: clientCourseData?.completedPte
        ? clientCourseData?.pteSpeaking
        : null,
      pteWriting: clientCourseData?.completedPte
        ? clientCourseData?.pteWriting
        : null,
      pteReading: clientCourseData?.completedPte
        ? clientCourseData?.pteReading
        : null,
      pteListening: clientCourseData?.completedPte
        ? clientCourseData?.pteListening
        : null,
      pteScore: clientCourseData?.completedPte
        ? clientCourseData?.pteScore
        : null,
      tofelIbtScore: clientCourseData?.completedTofel
        ? clientCourseData?.tofelIbtScore
        : null,
      speaking: clientCourseData?.completedIelts
        ? clientCourseData?.speaking
        : null,
      writing: clientCourseData?.completedIelts
        ? clientCourseData?.writing
        : null,
      reading: clientCourseData?.completedIelts
        ? clientCourseData?.reading
        : null,
      listening: clientCourseData?.completedIelts
        ? clientCourseData?.listening
        : null,
      totalIeltsScore: clientCourseData?.completedIelts
        ? clientCourseData?.totalIeltsScore
        : null,
    };
    dispatch({
      type: "UPDATE_CUSTOMER_COURSE_REQUEST",
      payload: { id: record.id, formData },
    });
    // form.resetFields();
    setIsUpdateCustomerCourseModalVisible(false);
  };
  console.log("from courses scores edit", record);
  console.log("from clieent course  clent corse data edit", clientCourseData);
  return (
    <Drawer
      title="Update Client Scores"
      open={isUpdateCustomerCourseModalVisible}
      onClose={() => {
        setIsUpdateCustomerCourseModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Form
        layout="vertical"
        onFinish={updateCustomerCourseSubmitHandler}
        colon={true}
        form={form}
        fields={[
          {
            name: ["completedIelts"],
            value: clientCourseData?.completedIelts,
          },
          {
            name: ["completedTofel"],
            value: clientCourseData?.completedTofel,
          },
          {
            name: ["completedGmat"],
            value: clientCourseData?.completedGmat,
          },
          {
            name: ["completedGre"],
            value: clientCourseData?.completedGre,
          },
          {
            name: ["completedSat"],
            value: clientCourseData?.completedSat,
          },
          {
            name: ["greIbtScore"],
            value: clientCourseData?.greIbtScore,
          },
          {
            name: ["completedPte"],
            value: clientCourseData?.completedPte,
          },
          {
            name: ["totalIeltsScore"],
            value: clientCourseData?.totalIeltsScore,
          },
          {
            name: ["listening"],
            value: clientCourseData?.listening,
          },
          {
            name: ["reading"],
            value: clientCourseData?.reading,
          },
          {
            name: ["writing"],
            value: clientCourseData?.writing,
          },
          {
            name: ["speaking"],
            value: clientCourseData?.speaking,
          },
          {
            name: ["tofelIbtScore"],
            value: clientCourseData?.tofelIbtScore,
          },
          {
            name: ["pteScore"],
            value: clientCourseData?.pteScore,
          },
          {
            name: ["pteListening"],
            value: clientCourseData?.pteListening,
          },
          {
            name: ["pteReading"],
            value: clientCourseData?.pteReading,
          },
          {
            name: ["pteWriting"],
            value: clientCourseData?.pteWriting,
          },
          {
            name: ["pteSpeaking"],
            value: clientCourseData?.pteSpeaking,
          },
          {
            name: ["gmatIbtScore"],
            value: clientCourseData?.gmatIbtScore,
          },
          {
            name: ["satIbtScore"],
            value: clientCourseData?.satIbtScore,
          },
        ]}
        // initialValues={{
        //   completedIelts: record?.completedIelts,

        //   completedTofel: record?.completedTofel,
        //   completedGmat: record?.completedGmat,
        //   completedGre: record?.completedGre,
        //   completedSat: record?.completedSat,
        //   completedPte: record?.completedPte,
        //   satIbtScore: record?.satIbtScore,
        //   gmatIbtScore: record?.gmatIbtScore,
        //   pteSpeaking: record?.pteSpeaking,
        //   pteWriting: record?.pteWriting,
        //   pteReading: record?.pteReading,
        //   pteListening: record?.pteListening,
        //   pteScore: record?.pteScore,
        //   tofelIbtScore: record?.tofelIbtScore,
        //   speaking: record?.speaking,
        //   writing: record?.writing,
        //   reading: record?.reading,
        //   listening: record?.listening,
        //   totalIeltsScore: record?.totalIeltsScore,
        // }}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Completed IELTS?"
            name={"completedIelts"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter ielts score!",
              },
            ]}
          >
            <Radio.Group
              onChange={(e) => {
                console.log("from chang completed ielts", e);
                setClientCourseData((previousData) => {
                  return {
                    ...previousData,
                    completedIelts: e.target.value,
                  };
                });
              }}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          {clientCourseData.completedIelts ? (
            <>
              <Form.Item
                label="IELTS score"
                name={"totalIeltsScore"}
                style={{ width: "100%" }}
              >
                <Input
                  placeholder="IELTS Score"
                  onChange={(e) => {
                    setClientCourseData((previousData) => {
                      return {
                        ...previousData,
                        totalIeltsScore: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Listening"
                name={"listening"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter listening score!",
                  },
                ]}
              >
                <Input
                  placeholder="listening"
                  onChange={(e) => {
                    setClientCourseData((previousData) => {
                      return {
                        ...previousData,
                        listening: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Reading"
                name={"reading"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter reading score!",
                  },
                ]}
              >
                <Input
                  placeholder="writing score"
                  onChange={(e) => {
                    setClientCourseData((previousData) => {
                      return {
                        ...previousData,
                        reading: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Writing"
                name={"writing"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter writing score!",
                  },
                ]}
              >
                <Input
                  placeholder="writing score"
                  onChange={(e) => {
                    setClientCourseData((previousData) => {
                      return {
                        ...previousData,
                        writing: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Speaking"
                name={"speaking"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter speaking score!",
                  },
                ]}
              >
                <Input
                  placeholder="speaking score"
                  onChange={(e) => {
                    setClientCourseData((previousData) => {
                      return {
                        ...previousData,
                        speaking: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
            </>
          ) : (
            ""
          )}
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Completed TOFEL?"
            name={"completedTofel"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter completedTofel!",
              },
            ]}
          >
            <Radio.Group
              onChange={(e) => {
                setClientCourseData((previousData) => {
                  return {
                    ...previousData,
                    completedTofel: e.target.value,
                  };
                });
              }}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          {clientCourseData.completedTofel ? (
            <>
              <Form.Item
                label="TOFEL IBT Score?"
                name={"tofelIbtScore"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter tofelIbtScore!",
                  },
                ]}
              >
                <Input
                  placeholder="tofelIbtScore"
                  onChange={(e) => {
                    setClientCourseData((previousData) => {
                      return {
                        ...previousData,
                        tofelIbtScore: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
            </>
          ) : (
            ""
          )}
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Completed PTE?"
            name={"completedPte"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter completedPte!",
              },
            ]}
          >
            <Radio.Group
              onChange={(e) => {
                setClientCourseData((previousData) => {
                  return {
                    ...previousData,
                    completedPte: e.target.value,
                  };
                });
              }}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          {clientCourseData.completedPte ? (
            <>
              <Form.Item
                label="PTE Score"
                name={"pteScore"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter pteScore!",
                  },
                ]}
              >
                <Input
                  placeholder="pteScore"
                  onChange={(e) => {
                    setClientCourseData((previousData) => {
                      return {
                        ...previousData,
                        pteScore: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="PTE Listening"
                name={"pteListening"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter pte listening score!",
                  },
                ]}
              >
                <Input
                  placeholder="pte listening score"
                  onChange={(e) => {
                    setClientCourseData((previousData) => {
                      return {
                        ...previousData,
                        pteListening: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="PTE Reading"
                name={"pteReading"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter pte reading score!",
                  },
                ]}
              >
                <Input
                  placeholder="pte reading score"
                  onChange={(e) => {
                    setClientCourseData((previousData) => {
                      return {
                        ...previousData,
                        pteReading: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="PTE Writing"
                name={"pteWriting"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter PTE Writing score!",
                  },
                ]}
              >
                <Input
                  placeholder="pte Writing score"
                  onChange={(e) => {
                    setClientCourseData((previousData) => {
                      return {
                        ...previousData,
                        pteWriting: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="PTE Speaking"
                name={"pteSpeaking"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter pteSpeaking!",
                  },
                ]}
              >
                <Input
                  placeholder="pte Speaking score"
                  onChange={(e) => {
                    setClientCourseData((previousData) => {
                      return {
                        ...previousData,
                        pteSpeaking: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
            </>
          ) : (
            ""
          )}
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Completed SAT?"
            name={"completedSat"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter completedSat!",
              },
            ]}
          >
            <Radio.Group
              onChange={(e) => {
                setClientCourseData((previousData) => {
                  return {
                    ...previousData,
                    completedSat: e.target.value,
                  };
                });
              }}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          {clientCourseData.completedSat ? (
            <>
              <Form.Item
                label="SAT IBT Score"
                name={"satIbtScore"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter satIbtScore!",
                  },
                ]}
              >
                <Input
                  placeholder="satIbtScore"
                  onChange={(e) => {
                    setClientCourseData((previousData) => {
                      return {
                        ...previousData,
                        satIbtScore: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
            </>
          ) : (
            ""
          )}
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Completed GRE?"
            name={"completedGre"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter completedGre!",
              },
            ]}
          >
            <Radio.Group
              onChange={(e) => {
                setClientCourseData((previousData) => {
                  return {
                    ...previousData,
                    completedGre: e.target.value,
                  };
                });
              }}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          {clientCourseData.completedGre ? (
            <>
              <Form.Item
                label="GRE IBT Score"
                name={"greIbtScore"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter greIbtScore!",
                  },
                ]}
              >
                <Input
                  placeholder="greIbtScore"
                  onChange={(e) => {
                    setClientCourseData((previousData) => {
                      return {
                        ...previousData,
                        greIbtScore: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
            </>
          ) : (
            ""
          )}
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Completed Gmat?"
            name={"completedGmat"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter completedGmat!",
              },
            ]}
          >
            <Radio.Group
              onChange={(e) => {
                setClientCourseData((previousData) => {
                  return {
                    ...previousData,
                    completedGmat: e.target.value,
                  };
                });
              }}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          {clientCourseData.completedGmat ? (
            <>
              <Form.Item
                label="GMAT IBT Score"
                name={"gmatIbtScore"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter gmatIbtScore!",
                  },
                ]}
              >
                <Input
                  placeholder="gmatIbtScore"
                  onChange={(e) => {
                    setClientCourseData((previousData) => {
                      return {
                        ...previousData,
                        gmatIbtScore: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
            </>
          ) : (
            ""
          )}
        </div>
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Update Client Scores
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default UpdateCustomerCourse;
