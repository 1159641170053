import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  counselling: {},
  activeCounselling: {},
};

const CounsellingReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "CHANGE_START_COUNSELLING_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "CHANGE_START_COUNSELLING_STATUS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Status changed.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "CHANGE_START_COUNSELLING_STATUS_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to change status.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_COUNSELLING_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_COUNSELLING_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Contact added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_COUNSELLING_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to add contact.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_COUNSELLING_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_COUNSELLING_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        counselling: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_COUNSELLING_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_ACTIVE_COUNSELLING_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_ACTIVE_COUNSELLING_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        activeCounselling: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ACTIVE_COUNSELLING_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    // case "FETCH_COUNSELLING_REQUEST": {
    //   return {
    //     ...state,
    //     isLoading: true,
    //   };
    // }

    // case "FETCH_COUNSELLING_REQUEST_SUCCESS": {
    //   console.log("from reducer", action.payload);
    //   return {
    //     ...state,
    //     counselling: action.payload,
    //     isLoading: false,
    //   };
    // }
    // case "FETCH_COUNSELLING_REQUEST_FAILURE": {
    //   return {
    //     ...state,
    //     isLoading: false,
    //   };
    // }

    case "UPDATE_COUNSELLING_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_COUNSELLING_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Contact updated.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_COUNSELLING_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to update contact.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_STATE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_STATE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! State added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_STATE_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to add state .");

      return {
        ...state,
        isLoading: false,
      };
    }
    // case "DELETE_CONTACTS_REQUEST": {
    //   return {
    //     ...state,
    //     isLoading: true,
    //   };
    // }

    // case "DELETE_CONTACTS_REQUEST_SUCCESS": {
    //   action.payload.message
    //     ? message.success(action.payload.message)
    //     : message.success("Success! Contact deleted.");

    //   return {
    //     ...state,
    //     isLoading: false,
    //   };
    // }
    // case "DELETE_CONTACTS_REQUEST_FAILURE": {
    //   action.payload.message
    //     ? message.error(action.payload.message)
    //     : message.error("Error! Unable to delete contact.");

    //   message.error("error");
    //   return {
    //     ...state,
    //     isLoading: false,
    //   };
    // }
    default:
      return state;
  }
};

export default CounsellingReducer;
