import { Button, Card, Form, Input } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import OrganizationalSettings from "../OrganizationalSettings";

const AppointmentSettings = () => {
  useEffect(() => {
    dispatch({
      type: "FETCH_REMINDER_EXIPRY_WORKING_HOUR_REQUEST",
      isSuccessFn: true,
      successFunction: successFunction,
    });
  }, []);
  const successFunction = (response) => {
    console.log("response from reminder expiry date", response);
    setAppointmentSettingsData((previousData) => {
      return {
        ...previousData,
        appointmentPerfix: response?.data?.prefix?.appointmentPrefix,
        appointmentReminder:
          response?.data?.expiryDates?.appointmentReminderDateAfter,
      };
    });
  };
  const [appointmentPrefix, setAppointmentPrefix] = useState("");
  const [appointmentSettingsData, setAppointmentSettingsData] = useState({
    appointmentPerfix: "",
    appointmentReminder: "",
  });
  const dispatch = useDispatch();
  return (
    <OrganizationalSettings>
      <div>
        <h3>Appointment Settings</h3>
        <Card>
          <Form
            labelCol={{ span: 5 }}
            fields={[
              {
                name: ["appointmentPrefix"],
                value: appointmentSettingsData.appointmentPerfix,
              },
              {
                name: ["appointmentReminder"],
                value: appointmentSettingsData.appointmentReminder,
              },
            ]}
          >
            <Form.Item label="Appointment Prefix" name={"appointmentPrefix"}>
              <Input
                placeholder="Enter the prefix you want to use"
                onChange={(e) => {
                  setAppointmentSettingsData((previousData) => {
                    return {
                      ...previousData,
                      appointmentPerfix: e.target.value,
                    };
                  });
                  // setAppointmentPrefix(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item
              name={"appointmentReminder"}
              label="Appointment Reminder"
            >
              <Input
                type="number"
                value={appointmentSettingsData.appointmentReminder}
                //   placeholder="reminder days after today"
                onChange={(e) => {
                  setAppointmentSettingsData((previousData) => {
                    return {
                      ...previousData,
                      appointmentReminder: e.target.value,
                    };
                  });
                }}
                style={{ width: "4rem" }}
              />{" "}
              days after.
            </Form.Item>
          </Form>

          <Form.Item
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                dispatch({
                  type: "UPDATE_PREFIX_REQUEST",
                  payload: {
                    appointmentPrefix:
                      appointmentSettingsData.appointmentPerfix,
                  },
                });
                dispatch({
                  type: "UPDATE_EXPIRY_REMINDER_WORKING_HOUR_REQUEST",
                  payload: {
                    appointmentReminderDateAfter:
                      appointmentSettingsData.appointmentReminder,
                  },
                });
              }}
            >
              Save Changes
            </Button>
          </Form.Item>
        </Card>
      </div>
    </OrganizationalSettings>
  );
};

export default AppointmentSettings;
