import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
  Tooltip,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { FcDocument } from "react-icons/fc";
import { BsUpload } from "react-icons/bs";
import { AiFillFilePdf } from "react-icons/ai";
import { useEffect } from "react";
import { useSelector } from "react-redux";
const { Option } = Select;

const DocumentChecklist = ({
  record,
  isDocumentChecklistModalVisible,
  setIsDocumentChecklistModalVisible,
}) => {
  const { requiredWorkflowCheckList, requiredCoursesCheckList } = record;
  const dispatch = useDispatch();
  const visaState = useSelector((state) => state.VisasReducer);
  const [form] = Form.useForm();
  useEffect(() => {
    if (isDocumentChecklistModalVisible === true) {
      dispatch({
        type: "UPDATE_DOCUMENT_CHECKLIST_REQUEST",
        payload: { requiredWorkflowCheckList, requiredCoursesCheckList },
      });
    }
  }, [isDocumentChecklistModalVisible]);
  const uploadUrlFromUploadResponse = (response) => {
    dispatch({
      type: "UPLOAD_URL_FROM_UPLOAD_RESPONSE_REQUEST",
      payload: {
        caseId: record.id,
        documentChecklistId: documentRecord?.documentCheckListResponse?.id,
        uploadAttachment: response?.data?.imageUploadPath,
        isChecked: null,
      },
    });
  };
  const [documentRecord, setDocumentRecord] = useState();
  console.log("From document checklist case", record);
  console.log("workflow Checklist", visaState?.requiredWorkflowCheckList);
  console.log("course Checklist", visaState?.requiredCoursesCheckList);
  return (
    <Drawer
      title="Document Checklist"
      open={isDocumentChecklistModalVisible}
      onClose={() => {
        setIsDocumentChecklistModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <div className="drawerStyle">
        <h2>Document Attachment:</h2>
        <div style={{ marginTop: "1rem" }}>
          {visaState?.requiredWorkflowCheckList?.length > 0 ? (
            visaState.requiredWorkflowCheckList.map((dataObj, index) => {
              console.log("is document checked", dataObj?.isChecked);
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: "1px solid #ccc",
                    borderRadius: "20rem",
                    padding: "2px 4px",
                    margin: "0.5rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "1rem",
                      alignItems: "center",
                      padding: "0.5rem",
                    }}
                  >
                    <Checkbox
                      defaultChecked={dataObj?.isChecked}
                      onChange={(e) => {
                        dispatch({
                          type: "UPLOAD_URL_FROM_UPLOAD_RESPONSE_REQUEST",
                          payload: {
                            caseId: record.id,
                            documentChecklistId:
                              dataObj?.documentCheckListResponse?.id,
                            courseCheckListId: null,

                            uploadAttachment: null,
                            isChecked: e.target.checked,
                          },
                        });
                        console.log("checked unchecked", e.target.checked);
                      }}
                    >
                      {dataObj?.documentCheckListResponse?.item}
                    </Checkbox>
                    <div>
                      {dataObj?.documentCheckListResponse?.documentSize}{" "}
                    </div>
                    {/* <div>
                      {dataObj?.documentCheckListResponse?.documentType}
                    </div> */}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "0.5rem",
                      marginRight: "1.5rem",
                    }}
                  >
                    {dataObj.uploadedAttachment ? (
                      <Tooltip title="Download">
                        <a href={dataObj.uploadedAttachment}>
                          <div
                            className="bordered"
                            //   onClick={() => {
                            //     setDocumentRecord(dataObj);
                            //     setIsUpdateDocumentModalVisible(true);
                            //   }}
                          >
                            <FcDocument style={{ fontSize: "30px" }} />
                          </div>
                        </a>
                      </Tooltip>
                    ) : null}

                    <Input
                      type="file"
                      onChange={(e) => {
                        const uploadUrlFromUploadResponse = (response) => {
                          console.log(response, "response");
                          dispatch({
                            type: "UPLOAD_URL_FROM_UPLOAD_RESPONSE_REQUEST",
                            payload: {
                              caseId: record.id,
                              documentChecklistId:
                                dataObj?.documentCheckListResponse?.id,
                              courseCheckListId: null,
                              uploadAttachment: response?.data?.imageUploadPath,
                              isChecked: null,
                            },
                          });
                          dispatch({
                            type: "DOCUMENT_CHECKLIST_VISIBLE",
                            payload: {
                              index,
                              documentChecklist: { ...response.data },
                            },
                          });
                        };
                        setDocumentRecord(dataObj);
                        let formData = new FormData();
                        formData.append("uploadAttachment", e.target.files[0]);
                        dispatch({
                          type: "UPLOAD_CASE_DOCUMENTS_REQUEST",
                          payload: formData,
                          payload2: uploadUrlFromUploadResponse,
                        });
                      }}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <div style={{ textAlign: "center" }}>
              No Workflow Document Checklist Available
            </div>
          )}
        </div>
        <h2>Course Attachment:</h2>
        <div style={{ marginTop: "1rem" }}>
          {visaState?.requiredCoursesCheckList?.length > 0 ? (
            visaState.requiredCoursesCheckList.map((dataObj, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: "1px solid #ccc",
                    borderRadius: "20rem",
                    padding: "2px 4px",
                    margin: "0.5rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "1rem",
                      alignItems: "center",
                      padding: "0.5rem",
                    }}
                  >
                    <Checkbox
                      defaultChecked={dataObj?.isChecked}
                      onChange={(e) => {
                        dispatch({
                          type: "UPLOAD_URL_FROM_UPLOAD_RESPONSE_REQUEST",
                          payload: {
                            caseId: record.id,
                            documentChecklistId: null,
                            courseCheckListId:
                              dataObj?.courseCheckListResponse?.id,
                            uploadAttachment: null,
                            isChecked: e.target.checked,
                          },
                        });
                        console.log("checked unchecked", e.target.checked);
                      }}
                    >
                      {dataObj?.courseCheckListResponse?.item}
                    </Checkbox>

                    <div>{dataObj?.courseCheckListResponse?.documentSize}</div>
                    {/* <div>{dataObj?.courseCheckListResponse?.documentType}</div> */}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "0.5rem",
                      marginRight: "1.5rem",
                    }}
                  >
                    {dataObj.uploadedAttachment ? (
                      <Tooltip title="Download">
                        <a href={dataObj.uploadedAttachment}>
                          <div
                            className="bordered"
                            //   onClick={() => {
                            //     setDocumentRecord(dataObj);
                            //     setIsUpdateDocumentModalVisible(true);
                            //   }}
                          >
                            <FcDocument style={{ fontSize: "30px" }} />
                          </div>
                        </a>
                      </Tooltip>
                    ) : null}
                    <Input
                      type="file"
                      onChange={(e) => {
                        const uploadUrlFromUploadResponse = (response) => {
                          dispatch({
                            type: "UPLOAD_URL_FROM_UPLOAD_RESPONSE_REQUEST",
                            payload: {
                              caseId: record.id,
                              documentChecklistId: null,
                              courseCheckListId:
                                dataObj?.courseCheckListResponse?.id,
                              uploadAttachment: response?.data?.imageUploadPath,
                              isChecked: null,
                            },
                          });
                          dispatch({
                            type: "COURSE_CHECKLIST_VISIBLE",
                            payload: {
                              index,
                              courseCheckList: { ...response.data },
                            },
                          });
                        };

                        let formData = new FormData();
                        formData.append("uploadAttachment", e.target.files[0]);
                        dispatch({
                          type: "UPLOAD_CASE_DOCUMENTS_REQUEST",
                          payload: formData,
                          payload2: uploadUrlFromUploadResponse,
                        });
                      }}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <div style={{ textAlign: "center" }}>
              No Courses Document Checklist Available
            </div>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default DocumentChecklist;
