import axios from "axios";
import { Navigate } from "react-router-dom";
import { getCookie } from "./FrontendHelper";

const HeaderData = async (update = false) => {
  const token = getCookie("accessToken");

  if (token === null) {
    return {
      "Content-Type": "application/json",
    };
  } else if (update) {
    return {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    };
  } else {
    return {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
  }
};
//local
// axios.defaults.baseURL = "http://192.168.10.73:8080/khrouch/";

//test
axios.defaults.baseURL = process.env.REACT_APP_TEST_URL;
// axios.defaults.baseURL = "http://13.127.139.254:8080/khrouch/";

//live
axios.defaults.baseURL = "https://secureapi.ausnepit.me:8444/khrouch";

// FOR ADMIN LOGIN
// export const API = axios.create({
//   // baseURL: "http://13.127.139.254:8080/payroller/v1/api",

//   baseURL: "http://13.127.139.254:8080/khrouch/v1/api/",
//   // baseURL: "http://192.168.10.67:8080/khrouch/v1/api/",

//   headers: {
//     Authorization: `Bearer ${getCookie("v1/apiaccessToken")}`,
//   },
// });

// export const API1 = axios.create({
//   // baseURL: "http://13.127.139.254:8080/payroller/v1/api",
//   baseURL: "https://classibazaar.com.au/api",

//   headers: {
//     Authorization: `Bearer ${getCookie("v1/apitoken")}`,
//     "Content-Type": "multipart/form-data",
//   },
// });
// Remember to change base url
// auth invitation employee
// http://13.127.139.254:8080/payroller => https://api.activaccount.com.au:8443/payroller

export default class Api {
  async signUpApi(payload) {
    try {
      const res = await axios.post("v1/api/customer/add-information", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async signUpApi(payload) {
    try {
      const res = await axios.post("v1/api/customer/add-information", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async registerEmail(payload) {
    try {
      const res = await axios.post("v1/api/customer/register-email", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async otpVerification(payload) {
    try {
      const res = await axios.post("v1/api/customer/validate-otp", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async resendOtp(payload) {
    try {
      const res = await axios.post("v1/api/customer/resend-otp", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async forgotPasswordOtp(payload) {
    try {
      const res = await axios.post("v1/api/customer/password/forgot", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async forgotPasswordUsingCompanyDomainOrURL(payload) {
    try {
      const res = await axios.post(
        `/v1/api/customer/company-password/forgot?loginCompanyUrl=${payload.companyDomainOrURL}
        `,
        payload.value,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async signUpApi(payload) {
    try {
      const res = await axios.post("v1/api/customer/add-information", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async forgotPasswordOtpverification(payload) {
    try {
      const res = await axios.post(
        "/v1/api/customer/validate-otp-for-forget-password",
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async forgotPasswordOtpVerificationByCompanyUrl(payload) {
    try {
      const res = await axios.post(
        `/v1/api/customer/validate-otp-for-company-forget-password?companyUrl=${payload.companyDomainOrURL}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async changePassword(payload) {
    try {
      const res = await axios.post("v1/api/customer/password/reset", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async authenticateUser(payload) {
    try {
      const res = await axios.post("v1/api/customer/login", payload?.values, {
        headers: {
          "Content-Type": "application/json",
          recaptcha: payload?.recaptcha,
          // payload?.recaptcha
        },
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async companyAuthenticateUser(payload) {
    try {
      const res = await axios.get(
        `/v1/api/customer/validate-company-url?loginCompanyUrl=${payload.values?.domain}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async companyEmailValidate(payload) {
    try {
      const res = await axios.get(
        `/v1/api/customer/validate-company-email?email=${payload.values?.companyEmail}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async companyEmailAuthenticateUser(payload) {
    try {
      const res = await axios.post(
        `/v1/api/customer/company-login-email?companyEmail=${payload?.companyEmail}`,
        payload?.values,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async companyDomainAuthenticateUser(payload) {
    try {
      const res = await axios.post(
        `/v1/api/customer/company-login?loginCompanyUrl=${payload.domain}`,
        payload?.values,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }

  async addContact(payload) {
    try {
      let res;
      // if (payload.isHeadBranch === false) {
      //   res = await axios.post(
      //     "/v2/api/client/branch/contact/add",
      //     payload.formData,
      //     {
      //       headers: await HeaderData(),
      //     }
      //   );
      // } else {
      res = await axios.post("v1/api/contact/addContact", payload.formData, {
        headers: await HeaderData(),
      });
      // }

      return res;
    } catch (err) {
      throw err;
    }
  }
  async uploadContactImage(payload) {
    try {
      let res;
      // if (payload.isHeadBranch === false) {
      //   res = await axios.post(
      //     `/v2/api/client/branch/contact/upload-image/${payload.id}`,
      //     payload.contactFormData,
      //     {
      //       headers: await HeaderData(),
      //     }
      //   );
      // } else {
      res = await axios.post(
        `v1/api/contact/upload-image/${payload.id}`,
        payload.contactFormData,
        {
          headers: await HeaderData(),
        }
      );
      // }

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchContact(payload) {
    try {
      let res;
      if (payload?.isHeadBranch === false) {
        res = await axios.get(
          `/v2/api/client/branch/contact/get-all/${payload?.branchId}?page=${
            payload.page ?? 1
          }&pageSize=${payload?.pageSize ?? 10}`,
          {
            headers: await HeaderData(),
          }
        );
      } else {
        res = await axios.get(
          `v1/api/contact/list-all?page=${payload?.page ?? 1}&pageSize=${
            payload?.pageSize ?? 10
          }`,
          {
            headers: await HeaderData(),
          }
        );
      }

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchActiveContact(payload) {
    try {
      let res;
      if (payload?.isHeadBranch === false) {
        res = await axios.get(
          `/v2/api/client/branch/contact/get-all-active/${
            payload?.branchId
          }?page=${payload?.page ?? 1}&pageSize=${payload?.pageSize ?? 10}`,
          {
            headers: await HeaderData(),
          }
        );
      } else {
        res = await axios.get(
          `v1/api/contact/get-active-contact?page=${
            payload?.page ?? 1
          }&pageSize=${payload?.pageSize ?? 10}`,
          {
            headers: await HeaderData(),
          }
        );
      }

      return res;
    } catch (err) {
      throw err;
    }
  }

  async updateContact(payload) {
    try {
      let res;
      // if (payload.isHeadBranch === false) {
      //   res = await axios.put(
      //     `/v2/api/client/branch/contact/update/${payload.id}`,
      //     payload.values,
      //     {
      //       headers: await HeaderData(),
      //     }
      //   );
      // } else {
      res = await axios.put(
        `v1/api/contact/updateContact/${payload.id}`,
        payload.values,
        {
          headers: await HeaderData(),
        }
      );
      // }

      return res;
    } catch (err) {
      throw err;
    }
  }
  async changeContactStatus(payload) {
    try {
      let res;
      // if (payload.isHeadBranch === false) {
      //   res = await axios.patch(
      //     `/v2/api/client/branch/contact/change-status/${payload.actionStatus}/${payload.id}`,
      //     payload,
      //     {
      //       headers: await HeaderData(),
      //     }
      //   );
      // } else {
      res = await axios.patch(
        `v1/api/contact/change-contact-status/${payload.actionStatus}/${payload.id}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );
      // }

      return res;
    } catch (err) {
      throw err;
    }
  }
  async deleteContact(payload) {
    try {
      const res = await axios.delete(
        `v1/api/contact/deleteContact/${payload.id}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async AddCampaign(payload) {
    try {
      let res;
      // if (payload.isHeadBranch === false) {
      //   res = await axios.post(
      //     `/v2/api/client/branch/campaign/add`,
      //     payload.formData,
      //     {
      //       headers: await HeaderData(),
      //     }
      //   );
      // } else {
      res = await axios.post(`v1/api/contact/add-campaign`, payload.formData, {
        headers: await HeaderData(),
      });
      // }

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchLeads(payload) {
    try {
      let res;
      if (payload?.isHeadBranch === false) {
        res = await axios.get(
          `/v2/api/client/branch/leads/get-all/${payload?.branchId}?page=${
            payload?.page ?? 1
          }&pageSize=${payload.pageSize ?? 10}`,
          {
            headers: await HeaderData(),
          }
        );
      } else {
        res = await axios.get(
          `v1/api/leads/getAllLeads?page=${payload?.page ?? 1}&pageSize=${
            payload?.pageSize ?? 10
          }`,
          {
            headers: await HeaderData(),
          }
        );
      }

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchNotAssignedLeads(payload) {
    try {
      let res;
      if (payload?.isHeadBranch === false) {
        res = await axios.get(
          `/v2/api/client/branch/leads/filter/NOT_ASSIGNED/${
            payload?.branchId
          }?page=${payload?.page ?? 1}&pageSize=${payload?.pageSize ?? 10}`,
          {
            headers: await HeaderData(),
          }
        );
      } else {
        res = await axios.get(
          `/v1/api/leads/get-lead-by-progress/NOT_ASSIGNED?page=${
            payload?.page ?? 1
          }&pageSize=${payload?.pageSize ?? 10}`,
          {
            headers: await HeaderData(),
          }
        );
      }

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchInProgressLeads(payload) {
    try {
      let res;
      if (payload?.isHeadBranch === false) {
        res = await axios.get(
          `/v2/api/client/branch/leads/filter/IN_PROGRESS/${
            payload.branchId
          }?page=${payload?.page ?? 1}&pageSize=${payload?.pageSize ?? 10}`,
          {
            headers: await HeaderData(),
          }
        );
      } else {
        res = await axios.get(
          `/v1/api/leads/get-lead-by-progress/IN_PROGRESS?page=${
            payload?.page ?? 1
          }&pageSize=${payload?.pageSize ?? 10}`,
          {
            headers: await HeaderData(),
          }
        );
      }

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchCompletedLeads(payload) {
    try {
      let res;
      if (payload?.isHeadBranch === false) {
        res = await axios.get(
          `/v2/api/client/branch/leads/filter/COMPLETED/${
            payload?.branchId
          }?page=${payload?.page ?? 1}&pageSize=${payload.pageSize ?? 10}`,
          {
            headers: await HeaderData(),
          }
        );
      } else {
        res = await axios.get(
          `/v1/api/leads/get-lead-by-progress/COMPLETED?page=${
            payload?.page ?? 1
          }&pageSize=${payload?.pageSize ?? 10}`,
          {
            headers: await HeaderData(),
          }
        );
      }

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchLeadById(payload) {
    try {
      const res = await axios.get(`v1/api/leads/get-lead-by-id/${payload}`, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }

  async fetchLeadsDropdown(payload) {
    try {
      let res;
      // if (payload.isHeadBranch === false) {
      //   res = await axios.get(
      //     `/v2/api/client/branch/leads/get-all-dropdown/${payload.branchId}`,
      //     {
      //       headers: await HeaderData(),
      //     }
      //   );
      // } else {
      res = await axios.get("v1/api/leads/get-lead-dropdowns", {
        headers: await HeaderData(),
      });
      // }

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addLeads(payload) {
    try {
      const res = await axios.post("v1/api/leads/addLeads", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateLeadStatus(payload) {
    try {
      const res = await axios.post(
        `v1/api/leads/push-case-log/${payload.id}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateLead(payload) {
    try {
      const res = await axios.put(
        `v1/api/leads/updateLeads/${payload.id}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async deleteLead(payload) {
    try {
      const res = await axios.delete(`v1/api/leads/deleteLeads/${payload.id}`, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addAboutUs(payload) {
    try {
      const res = await axios.post("v1/api/leads/add-about-us", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addLeadSource(payload) {
    try {
      let res;
      // if (payload.isHeadBranch === false) {
      //   res = await axios.post(
      //     "/v2/api/client/branch/lead-source/add",
      //     payload.formData,
      //     {
      //       headers: await HeaderData(),
      //     }
      //   );
      // } else {
      res = await axios.post("v1/api/leads/add-lead-source", payload.formData, {
        headers: await HeaderData(),
      });
      // }

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addCountry(payload) {
    try {
      const res = await axios.post("v1/api/country/addCountry", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async uploadCountryImage(payload) {
    try {
      const res = await axios.post(
        `v1/api/country/upload-image?countryId=${payload.id}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async changeCountryStatus(payload) {
    try {
      const res = await axios.patch(
        `v1/api/country/change/status/${payload.id}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addLeadStatus(payload) {
    try {
      const res = await axios.post("v1/api/leads/add-lead-status", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addPurpose(payload) {
    try {
      const res = await axios.post("v1/api/leads/add-purpose", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addConsultant(payload) {
    try {
      const res = await axios.post(
        "/v1/api/consultancy/employee/add",
        payload.values,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addSchedule(payload) {
    try {
      const res = await axios.post(
        `v1/api/consultant/add-consultant-time-schedule/${payload.id}`,
        payload.values,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addReferral(payload) {
    try {
      const res = await axios.post("v1/api/leads/add-reference", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchCompanies(payload) {
    try {
      const res = await axios.get(
        `v1/api/company/companygetAll?page=${payload?.page ?? 1}&pageSize=${
          payload?.pageSize ?? 10
        }`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchActiveCompanies(payload) {
    try {
      const res = await axios.get(
        `/v1/api/company/get-all-active?page=${payload?.page ?? 1}&pageSize=${
          payload?.pageSize ?? 10
        }`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateCompanies(payload) {
    try {
      const res = await axios.put(
        `v1/api/company/updateCompany/${payload.id}`,
        payload.values,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async deleteCompanies(payload) {
    try {
      const res = await axios.delete(
        `v1/api/company/deleteCompany/${payload.id}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async changeCompanyStatus(payload) {
    try {
      const res = await axios.post(
        `v1/api/company/company/change-status/${payload.id}/${payload.actionStatus}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addCompany(payload) {
    try {
      const res = await axios.post("v1/api/company/addCompany", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  // ?page=${payload.page}&pageSize=${payload.pageSize}
  async fetchInstitute(payload) {
    try {
      const res = await axios.get(
        `v1/api/institute/getAllInstitute??page=${
          payload?.page ?? 1
        }&pageSize=${payload?.pageSize ?? 10}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchActiveInstitute(payload) {
    try {
      const res = await axios.get(
        `v1/api/institute/get/active?page=${payload?.page ?? 1}&pageSize=${
          payload?.pageSize ?? 10
        }`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async AddInstitute(payload) {
    try {
      const res = await axios.post("v1/api/institute/addInstitute", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async uploadInstituteImage(payload) {
    try {
      const res = await axios.post(
        `v1/api/institute/upload-image?instituteId=${payload.id}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async uploadInstituteDocument(payload) {
    try {
      const res = await axios.post(
        `v1/api/institute/upload-document/${payload.id}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async uploadCourseDocument(payload) {
    try {
      const res = await axios.post(
        `v1/api/institute/coursesadd-document/${payload.id}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async deleteInstituteDocuments(payload) {
    try {
      const res = await axios.delete(
        `v1/api/institute/delete-document/${payload.id}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addLevelOfCollege(payload) {
    try {
      const res = await axios.post(
        `v1/api/institute/add-level-of-college/${payload.id}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async UpdateInstitute(payload) {
    try {
      const res = await axios.put(
        `v1/api/institute/updateInstitute/${payload.id}`,
        payload.values,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async toggleCompanyStatus(payload) {
    try {
      const res = await axios.patch(
        `v1/api/institute/changeStatus/${payload.id}/${payload.actionStatus}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async deleteInstitue(payload) {
    try {
      const res = await axios.delete(
        `v1/api/institutedelete-institute/${payload.id}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchCourses(payload) {
    try {
      const res = await axios.get(
        `v1/api/institute/get-all-courses?page=${payload?.page ?? 1}&pageSize=${
          payload?.pageSize ?? 10
        }`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchActiveCourses(payload) {
    try {
      const res = await axios.get(
        `v1/api/institute/get-active-courses?page=${
          payload?.page ?? 1
        }&pageSize=${payload?.pageSize ?? 10}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async AddCourse(payload) {
    try {
      const res = await axios.post(
        `v1/api/institute/add-courses/${payload.id}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchCourcesDocumentChecklist(payload) {
    try {
      const res = await axios.get(
        `v1/api/institute/courses/get-document-checklist/${payload.id}/${payload.type}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addCoursesDocumentChecklist(payload) {
    try {
      const res = await axios.post(
        `v1/api/institute/courses/add-document-checklist/${payload.id}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateCoursesDocumentChecklist(payload) {
    try {
      const res = await axios.post(
        `v1/api/institute/courses/update-document-checklist/${payload.id}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async toggleCourseStatus(payload) {
    try {
      const res = await axios.patch(
        `v1/api/institute/change-course-status/${payload.actionStatus}/${payload.id}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateCourse(payload) {
    try {
      const res = await axios.post(
        `v1/api/institute/update-course/${payload.id}`,
        payload.values,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async FetchProfile() {
    try {
      const res = await axios.get("v1/api/profile/get-information", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateProfile(payload) {
    try {
      const res = await axios.post(
        "/v1/api/profile/update-information",
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchCounselling() {
    try {
      const res = await axios.get("v1/api/counselling/get-all-counselling", {
        headers: await HeaderData(),
      });
      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchActiveCounselling(payload) {
    try {
      const res = await axios.get(
        "/v1/api/counselling/get-all-active-counselling",
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addCounselling(payload) {
    try {
      const res = await axios.post(
        "/v1/api/counselling/add-counselling",
        payload.values,
        {
          headers: await HeaderData(),
        }
      );
      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateCounselling(payload) {
    try {
      const res = await axios.post(
        `v1/api/counselling/update-counselling/${payload.id}`,
        payload.values,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async deleteCounselling(payload) {
    try {
      const res = await axios.delete(
        `v1/api/counselling/delete-counselling/${payload.id}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async changeCounsellingStatus(payload) {
    try {
      const res = await axios.patch(
        `v1/api/counselling/change-counselling-status/${payload.actionStatus}/${payload.id}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addState(payload) {
    try {
      const res = await axios.post(
        `v1/api/country/add-state/${payload.id}`,
        payload.values,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addAppointment(payload) {
    try {
      const res = await axios.post(
        "v1/api/appointment/add-appointment",
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchAppointments(payload) {
    try {
      let res;
      if (payload?.isHeadBranch === false) {
        res = await axios.get(
          `v2/api/client/branch/appointment/get-individual-appointment/${
            payload.branchId
          }?page=${payload?.page ?? 1}&pageSize=${payload?.pageSize ?? 10}`,
          {
            headers: await HeaderData(),
          }
        );
      } else {
        res = await axios.get(
          `v1/api/appointment/get-individual-appointment?page=${
            payload?.page ?? 1
          }&pageSize=${payload?.pageSize ?? 10}`,
          {
            headers: await HeaderData(),
          }
        );
      }

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchAppointmentById(payload) {
    try {
      const res = await axios.get(
        `/v1/api/appointment/get-by-id/${payload.appointmentId}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchPendingAppointments(payload) {
    try {
      let res;
      if (payload?.isHeadBranch === false) {
        res = await axios.get(
          `/v2/api/client/branch/appointment/get-all/PENDING/${
            payload.branchId
          }?page=${payload?.page ?? 1}&pageSize=${payload?.pageSize ?? 10}`,
          {
            headers: await HeaderData(),
          }
        );
      } else {
        res = await axios.get(
          `/v1/api/appointment/get-all-appointment-data/PENDING?page=${
            payload?.page ?? 1
          }&pageSize=${payload?.pageSize ?? 10}`,
          {
            headers: await HeaderData(),
          }
        );
      }

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchConfirmedAppointments(payload) {
    try {
      let res;
      if (payload?.isHeadBranch === false) {
        res = await axios.get(
          `/v2/api/client/branch/appointment/get-all/CONFIRMED/${
            payload?.branchId
          }?page=${payload?.page ?? 1}&pageSize=${payload?.pageSize ?? 10}`,
          {
            headers: await HeaderData(),
          }
        );
      } else {
        res = await axios.get(
          `/v1/api/appointment/get-all-appointment-data/CONFIRMED?page=${
            payload?.page ?? 1
          }&pageSize=${payload?.pageSize ?? 10}`,
          {
            headers: await HeaderData(),
          }
        );
      }

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchCompletedAppointments(payload) {
    try {
      let res;
      if (payload?.isHeadBranch === false) {
        res = await axios.get(
          `/v2/api/client/branch/appointment/get-all/COMPLETED/${
            payload.branchId
          }?page=${payload?.page ?? 1}&pageSize=${payload?.pageSize ?? 10}`,
          {
            headers: await HeaderData(),
          }
        );
      } else {
        res = await axios.get(
          `/v1/api/appointment/get-all-appointment-data/COMPLETED?page=${
            payload?.page ?? 1
          }&pageSize=${payload?.pageSize ?? 10}`,
          {
            headers: await HeaderData(),
          }
        );
      }

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchActiveAppointment(payload) {
    try {
      const res = await axios.get("v1/api/appointment/get-active-appointment", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateAppointment(payload) {
    try {
      const res = await axios.post(
        `v1/api/appointment/update-appointment/${payload.id}`,
        payload.values,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async changeAppointmentStatus(payload) {
    try {
      const res = await axios.patch(
        `v1/api/appointment/change-appointment-status/${payload.actionStatus}/${payload.id}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async deleteAppointment(payload) {
    try {
      const res = await axios.delete(
        `v1/api/appointment/delete-appointment/${payload.id}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async timingScheduleDropdown(payload) {
    try {
      const res = await axios.post(
        `v1/api/consultant/get-timing-dropdown/${payload.sessionTime}/${payload.counsellorId}`,
        payload.values,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async headAgent(payload) {
    try {
      const res = await axios.get("v1/api/agent/get-all-head-agent", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchActiveHeadAgent() {
    try {
      const res = await axios.get("v1/api/agent/get-all-active-head-agent", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addHeadAgent(payload) {
    try {
      const res = await axios.post("v1/api/agent/add-head-agent", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateHeadAgent(payload) {
    try {
      const res = await axios.post(
        `v1/api/agent/update-head-agent/${payload.id}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async deleteHeadAgent(payload) {
    try {
      const res = await axios.delete(
        `v1/api/agent/delete-head-agent/${payload.id}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async toggleHeadAgentDisabled(payload) {
    try {
      const res = await axios.patch(
        `v1/api/agent/change-head-agent-status/${payload.actionStatus}/${payload.id}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchSubAgent(payload) {
    try {
      const res = await axios.get("v1/api/agent/get-all-sub-agent", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchActiveSubAgent() {
    try {
      const res = await axios.get("v1/api/agent/get-all-active-sub-agent", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addSubAgent(payload) {
    try {
      const res = await axios.post("v1/api/agent/add-sub-agent", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateSubAgent(payload) {
    try {
      const res = await axios.post(
        `v1/api/agent/update-sub-agent/${payload.id}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async toggleSubAgentStatus(payload) {
    try {
      const res = await axios.patch(
        `v1/api/agent/change-sub-agent-status/${payload.actionStatus}/${payload.id}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async deleteSubagent(payload) {
    try {
      const res = await axios.delete(
        `/v1/api/agent/delete-sub-agent/${payload.id}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchActiveSupportCategory(payload) {
    try {
      const res = await axios.get(`v1/api/support/category/get-all-active`, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchSupport() {
    try {
      const res = await axios.get("v1/api/support/get-all-ticket", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addSupportCategory(payload) {
    try {
      const res = await axios.post("v1/api/support/category/add", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addSupport(payload) {
    try {
      const res = await axios.post("v1/api/support/add-support", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async replyToSupportTicket(payload) {
    try {
      const res = await axios.post(
        `v1/api/support/reply-support-ticket/${payload.id}`,
        payload.values,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchTicketById(payload) {
    try {
      const res = await axios.get(
        `v1/api/support/get-ticket-thread-by-id/${payload.id}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchAllRoles(payload) {
    try {
      const res = await axios.get("v1/api/client/role/list-all", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchActiveRoles(payload) {
    try {
      const res = await axios.get(
        "/v1/api/client/role/get-active-dropdown-list",
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addRole(payload) {
    try {
      const res = await axios.post("v1/api/client/role/add-role", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateRole(payload) {
    try {
      const res = await axios.put(
        `v1/api/client/role/update-role/${payload.id}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchPermissions() {
    try {
      const res = await axios.get("v1/api/client/permission/get-all", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updatePermission(payload) {
    try {
      const res = await axios.put(
        `v1/api/client/roles-permission/map-permissions-to-role/${payload.roleid}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchDepartments(payload) {
    try {
      const res = await axios.get(
        "/v1/api/client/department/roles-of-department/list-all",
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchActiveDepartments(payload) {
    try {
      const res = await axios.get(
        "/v1/api/client/department/roles-of-department/list-active",
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addDepartment(payload) {
    try {
      const res = await axios.post(
        "/v1/api/client/department/add-department",
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateDepartment(payload) {
    try {
      const res = await axios.put(
        `v1/api/client/department/update-department/${payload.id}`,
        payload.values,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  // async deleteDepartment(payload) {
  //   try {
  //     const res = await axios.delete("v1/api", payload, {
  //       headers: await HeaderData(),
  //     });

  //     return res;
  //   } catch (err) {
  //     throw err;
  //   }
  // }

  async activateDepartment(payload) {
    try {
      const res = await axios.put(
        `v1/api/client/department/activate-department/${payload.id}`,
        payload.values,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async deactivateDepartment(payload) {
    try {
      const res = await axios.put(
        `v1/api/client/department/deactivate-department/${payload.id}`,
        payload.values,
        {
          headers: await HeaderData(),
        }
      );
      return res;
    } catch (err) {
      throw err;
    }
  }
  async assignRolesToDepartment(payload) {
    try {
      const res = await axios.post(
        `v1/api/client/department/roles-of-department/map-roles/${payload.id}`,
        payload.values,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async changeAppointmentStatus(payload) {
    try {
      const res = await axios.patch(
        `v1/api/appointment/change-appointment-status/${payload.actionStatus}/${payload.id}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }

  async fetchEmployees(payload) {
    try {
      const res = await axios.get(
        `v1/api/consultancy/employee/get-list?page=${
          payload.page ?? 1
        }&pageSize=${payload?.pageSize ?? 10}`,
        {
          headers: await HeaderData(),
        }
      );
      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchActiveEmployee(payload) {
    try {
      const res = await axios.get(
        `/v1/api/consultancy/employee/get-active-list?page=${
          payload.page ?? 1
        }&pageSize=${payload?.pageSize ?? 10}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateEmployee(payload) {
    try {
      const res = await axios.put(
        `v1/api/consultancy/employee/update/${payload.id}`,
        payload.values,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async employeeActivateConfirmation(payload) {
    try {
      const res = await axios.put(
        `v1/api/consultancy/employee/activate/${payload.id}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async employeeDeactivateConfirmation(payload) {
    try {
      const res = await axios.put(
        `v1/api/consultancy/employee/deactivate/${payload.id}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async assignDepartment(payload) {
    try {
      const res = await axios.post(
        `v1/api/consultancy/employee/assign-department/${payload.id}`,
        payload.values,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchConsultancy(payload) {
    try {
      const res = await axios.get("v1/api/consultancy/consultancy/getAll", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchActiveConsultancy(payload) {
    try {
      const res = await axios.get(
        "/v1/api/consultancy/consultancy/getAll/active",
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addConsultancy(payload) {
    try {
      const res = await axios.post(
        "/v1/api/consultancy/consultancy/add",
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchAssignedByTask(payload) {
    try {
      const res = await axios.get("v1/api/consultancy/get-assigned-by", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchAssignedToTask(payload) {
    try {
      const res = await axios.get("v1/api/consultancy/get-assigned-to", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addtask(payload) {
    try {
      const res = await axios.post("v1/api/task/add-task", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateTask(payload) {
    try {
      const res = await axios.post(
        `v1/api/task/update-task/${payload.id}`,
        payload.values,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchPublicDrive(payload) {
    try {
      const res = await axios.get(
        "/v1/api/customer/drive-manager/fetch-data/PUBLIC",
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchPrivateDrive(payload) {
    try {
      const res = await axios.get(
        "/v1/api/customer/drive-manager/fetch-data/PRIVATE",
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchResourceById(payload) {
    try {
      const res = await axios.get(
        `v1/api/customer/drive-manager/fetch-data-by-folder-id/${payload}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchPrivateResourceById(payload) {
    try {
      const res = await axios.get(
        `v1/api/customer/drive-manager/fetch-data-by-folder-id/${payload}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async AddNewFolder(payload) {
    try {
      const res = await axios.post(
        "/v1/api/customer/drive-manager/create-folder",
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async renameFolder(payload) {
    try {
      const res = await axios.put(
        `v1/api/customer/drive-manager/rename-folder-by-id/${payload.id}?newName=${payload.formData.newName}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async deleteFolder(payload) {
    try {
      const res = await axios.delete(
        `v1/api/customer/drive-manager/delete-data/${payload}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fileUpload(payload) {
    // console.info(payload.getAll("v1/apifileContent"), "line numbr 1360 allapi");
    try {
      const res = await axios.post(
        "/v1/api/customer/drive-manager/upload-file",
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchClients(payload) {
    try {
      const res = await axios.get(
        `v1/api/customer/get-all-customer?page=${payload?.page ?? 1}&pageSize=${
          payload?.pageSize ?? 10
        }`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addClient(payload) {
    try {
      const res = await axios.post(
        "/v1/api/customer/add-customer",
        payload.clientData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addCustomerPhoto(payload) {
    try {
      const res = await axios.post(
        `v1/api/customer/image?customerId=${payload.clientId}`,
        payload.photoFormData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }

  async fetchDocumentTypes(payload) {
    try {
      const res = await axios.get("v1/api/customer/document-type/get-all", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addDocumentType(payload) {
    try {
      const res = await axios.post(
        "/v1/api/customer/document-type/add",
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }

  async addClientDocument(payload) {
    try {
      const res = await axios.post(
        `v1/api/customer/add-document?customerId=${payload.id}`,
        payload.documentsFormData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addClientQualification(payload) {
    try {
      const res = await axios.post(
        `v1/api/customer/add-qualifications/${payload.customerId}`,
        payload.qualificationData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async deleteClient(payload) {
    try {
      const res = await axios.delete(
        `v1/api/customer/delete-customer/${payload.id}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchActiveClients(payload) {
    try {
      const res = await axios.get(
        `v1/api/customer/get-all-active-customer?page=${
          payload?.page ?? 1
        }&pageSize=${payload?.pageSize ?? 10}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchVisa(payload) {
    try {
      let res;
      if (payload?.isHeadBranch === false) {
        res = await axios.get(
          `/v2/api/client/branch/cases/get-all/${payload?.branchId}?page=${
            payload?.page ?? 1
          }&pageSize=${payload?.pageSize ?? 10}`,
          {
            headers: await HeaderData(),
          }
        );
      } else {
        res = await axios.get(
          `v1/api/cases/get-all?page=${payload?.page ?? 1}&pageSize=${
            payload?.pageSize ?? 10
          }`,
          {
            headers: await HeaderData(),
          }
        );
      }

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchInProgressFilter(payload) {
    try {
      let res;
      if (payload?.isHeadBranch === false) {
        res = await axios.get(
          `/v2/api/client/branch/cases/filter-list/IN_PROGRESS/${
            payload?.branchId
          }?page=${payload?.page ?? 1}&pageSize=${payload?.pageSize ?? 10}`,
          {
            headers: await HeaderData(),
          }
        );
      } else {
        res = await axios.get(
          `v1/api/cases/filter-list/IN_PROGRESS?page=${
            payload?.page ?? 1
          }&pageSize=${payload?.pageSize ?? 10}`,
          {
            headers: await HeaderData(),
          }
        );
      }

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchNotAssignedFilterVisa(payload) {
    try {
      let res;
      if (payload?.isHeadBranch === false) {
        res = await axios.get(
          `/v2/api/client/branch/cases/filter-list/NOT_ASSIGNED/${
            payload?.branchId
          }?page=${payload?.page ?? 1}&pageSize=${payload?.pageSize ?? 10}`,
          {
            headers: await HeaderData(),
          }
        );
      } else {
        res = await axios.get(
          `v1/api/cases/filter-list/NOT_ASSIGNED?page=${
            payload?.page ?? 1
          }&pageSize=${payload?.pageSize ?? 10}`,
          {
            headers: await HeaderData(),
          }
        );
      }

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchCompletedFilterVisa(payload) {
    try {
      let res;
      if (payload?.isHeadBranch === false) {
        res = await axios.get(
          `/v2/api/client/branch/cases/filter-list/COMPLETED/${
            payload?.branchId
          }?page=${payload?.page ?? 1}&pageSize=${payload?.pageSize ?? 10}`,
          {
            headers: await HeaderData(),
          }
        );
      } else {
        res = await axios.get(
          `v1/api/cases/filter-list/COMPLETED?page=${
            payload?.page ?? 1
          }&pageSize=${payload?.pageSize ?? 10}`,
          {
            headers: await HeaderData(),
          }
        );
      }

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchFilterVisa(payload) {
    try {
      const res = await axios.get(`v1/api/cases/filter-list/${payload}`, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchCasesDropdown(payload) {
    try {
      const res = await axios.get("v1/api/cases/dropdown", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchCaseLogByWorkflowDto(payload) {
    try {
      const res = await axios.get(
        `v1/api/cases/get-workflow-status-updates/${payload.id}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchWorkflowStatusesByWorkflowId(payload) {
    try {
      const res = await axios.get(
        `v1/api/work-flow/get-all-work-flow-status/${payload.id}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateCaseStatusByWorkflowId(payload) {
    try {
      const res = await axios.put(
        `v1/api/cases/workflow-status/update/${payload.id}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateVisaOutcomeStatus(payload) {
    try {
      const res = await axios.put(
        `v1/api/cases/update-visa-outcome/${payload.id}`,
        payload.data,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async uploadCaseDocuments(payload) {
    try {
      const res = await axios.post(
        "/v1/api/cases/upload-document-for-case",
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async uploadURLfromUploadResponse(payload) {
    try {
      const res = await axios.post(
        `v1/api/cases/mark-unmark-check-list/${payload.caseId}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchCountryWorkflowById(payload) {
    try {
      const res = await axios.get(
        `v1/api/work-flow/get-all-work-flow/${payload.id}/${payload.visaCategory}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchWorkflowById(payload) {
    try {
      const res = await axios.get(
        `v1/api/work-flow/get-all-work-flow-status/${payload.id}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addWorkflowStatus(payload) {
    try {
      const res = await axios.post(
        `v1/api/work-flow/add-work-flow-status/${payload.id}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateWorkflowStatus(payload) {
    try {
      const res = await axios.post(
        `v1/api/work-flow/update-work-flow-status/${payload.id}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async changeWorkflowStatusAsFinal(payload) {
    try {
      const res = await axios.put(
        `v1/api/work-flow/assign-workflow-status-as-final/${payload.id}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async changeWorkflowStatusAsCancelled(payload) {
    try {
      const res = await axios.put(
        `v1/api/work-flow/assign-workflow-status-as-cancelled/${payload.id}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async changeWorkflowStatusAsFirst(payload) {
    try {
      const res = await axios.put(
        `v1/api/work-flow/assign-workflow-status-as-first/${payload.id}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchWorkflowStatusById(payload) {
    try {
      const res = await axios.get(
        `v1/api/work-flow/get-all-work-flow-status/${payload.id}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addVisaSubCategory(payload) {
    try {
      const res = await axios.post("v1/api/visa/add-visa-sub-class", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addSalesRepName(payload) {
    try {
      const res = await axios.post(
        "/v1/api/customer/add-sales-representative",
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async AddDegree(payload) {
    try {
      const res = await axios.post("v1/api/institute/add-degree", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchActiveCasePackage(payload) {
    try {
      const res = await axios.get("v1/api/case-packages/get-active", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addCasePackage(payload) {
    try {
      const res = await axios.post(
        "/v1/api/case-packages/create-cases",
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchCoursesByInstituteAndDegree(payload) {
    try {
      const res = await axios.post(`v1/api/institute/course-filter?`, payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateCaseStatus(payload) {
    try {
      const res = await axios.put(
        `v1/api/case-status/update-case-status/${payload.id}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async AddIntake(payload) {
    try {
      const res = await axios.post(
        `v1/api/institute/courses-intake/create-for/${payload.courseId}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addOrderStatus(payload) {
    try {
      const res = await axios.post("v1/api/apiNotCreated", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addVisaCase(payload) {
    try {
      const res = await axios.post("v1/api/cases/create-case", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchCountry(payload) {
    try {
      const res = await axios.get("v1/api/country/getAllCountry", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchActiveCountries(payload) {
    try {
      const res = await axios.get("v1/api/country/get/active", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async markCountryAsDefault(payload) {
    try {
      const res = await axios.patch(
        `/v1/api/country/set-as-default/${payload}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addMigrationWorkflow(payload) {
    try {
      const res = await axios.post(
        `v1/api/work-flow/add-work-flow/${payload.id}/migration`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addEducationWorkflow(payload) {
    try {
      const res = await axios.post(
        `v1/api/work-flow/add-work-flow/${payload.id}/education`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addOthersWorkflow(payload) {
    try {
      const res = await axios.post(
        `v1/api/work-flow/add-work-flow/${payload.id}/others`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchMigrationWorkflow(payload) {
    try {
      const res = await axios.get(
        `v1/api/work-flow/get-all-work-flow/${payload.id}/migration`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchEducationWorkflow(payload) {
    try {
      const res = await axios.get(
        `v1/api/work-flow/get-all-work-flow/${payload.id}/education`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchOthersWorkflow(payload) {
    try {
      const res = await axios.get(
        `v1/api/work-flow/get-all-work-flow/${payload.id}/others`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async deleteMigrationWorkflow(payload) {
    try {
      const res = await axios.delete(
        `v1/api/work-flow/delete-work-flow/${payload.id}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateMigrationWorkflow(payload) {
    try {
      const res = await axios.post(
        `v1/api/work-flow/update-work-flow/${payload.id}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async setWorkflowAsDefault(payload) {
    try {
      const res = await axios.patch(
        `v1/api/work-flow/change-work-flow-into-default/${payload.countryId}/migration/${payload.workflowId}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async setEducationWorkflowAsDefault(payload) {
    try {
      const res = await axios.patch(
        `v1/api/work-flow/change-work-flow-into-default/${payload.countryId}/education/${payload.workflowId}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async setOthersWorkflowAsDefault(payload) {
    try {
      const res = await axios.patch(
        `v1/api/work-flow/change-work-flow-into-default/${payload.countryId}/others/${payload.workflowId}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addDocument(payload) {
    try {
      const res = await axios.post(
        `v1/api/work-flow/add-document-check-list/${payload.workflowId}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchDocuments(payload) {
    try {
      const res = await axios.get(
        `v1/api/work-flow/get-all-document-check-list/${payload.id}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateDocument(payload) {
    try {
      const res = await axios.post(
        `v1/api/work-flow/update-document-check-list/${payload.id}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async deleteDocument(payload) {
    try {
      const res = await axios.delete(
        `v1/api/work-flow/delete-document-check-list/${payload.id}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchPackage(payload) {
    try {
      const res = await axios.get("v1/api/client/get-active-packages", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchDashboardData(payload) {
    try {
      const res = await axios.get(
        "/v1/api/dashboard/get-all-dashboard-dropdowns",
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      if (err.response.data.error === "Unauthorized") {
        window.location.replace("v1/api/");
      }
      throw err;
    }
  }
  async fetchDashboardDataList(payload) {
    try {
      const res = await axios.get(
        "/v1/api/dashboard/get-all-dashboard-dropdown-list",
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      if (err.response.data.error === "Unauthorized") {
        window.location.replace("v1/api/");
      }
      throw err;
    }
  }

  async purchaseSubscription(payload) {
    try {
      const res = await axios.post(
        `v1/api/client/purchase-subscription`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateWorkflowStatusPriority(payload) {
    try {
      const res = await axios.post(
        `v1/api/work-flow/update-work-flow-status-priority`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }

  async changeStartCounselling(payload) {
    try {
      const res = await axios.patch(
        `v1/api/counselling/change-counselling-status/${payload.status}/${payload.id}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchCustomerLeadById(payload) {
    try {
      const res = await axios.get(
        `v1/api/leads/get-leads-for-customer/${payload}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchCustomerAppointmentById(payload) {
    try {
      const res = await axios.get(
        `/v1/api/appointment/get-appointment-for-customer/${payload}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchCustomerCasesById(payload) {
    try {
      const res = await axios.get(
        `v1/api/cases/get-cases-by-customer-id/${payload}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchAllCountry(payload) {
    try {
      const res = await axios.get(
        "/v1/api/college-finder/get-available-country",
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchAllDegree(payload) {
    try {
      const res = await axios.get(
        `v1/api/college-finder/get-filter/${payload}/DEGREE`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchAllCourse(payload) {
    try {
      const res = await axios.get(
        `v1/api/college-finder/get-filter/${payload}/COURSE`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchAllIntake(payload) {
    try {
      const res = await axios.get(
        `v1/api/college-finder/get-filter/${payload}/INTAKE`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchAllCollege(payload) {
    try {
      const res = await axios.get(
        `v1/api/college-finder?${
          payload.selectedCountry !== "Select"
            ? `countryName=${payload.selectedCountry}`
            : null
        }&${
          payload.selectedDegree !== "Select"
            ? `degreeName=${payload.selectedDegree}`
            : null
        }&${
          payload.selectedCourse !== "Select"
            ? `courseName=${payload.selectedCourse}`
            : null
        }&${
          payload.selectedIntake !== "Select"
            ? `intakeName=${payload.selectedIntake}`
            : null
        }`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async changeClientStatus(payload) {
    try {
      const res = await axios.patch(
        `v1/api/customer/change-status/${payload.actionStatus}/${payload.id}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateCustomerInformatiion(payload) {
    try {
      const res = await axios.post(
        `v1/api/customer/update-customer/${payload.id}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateCustomerCourse(payload) {
    try {
      const res = await axios.post(
        `v1/api/customer/update-customer/${payload.id}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateCustomerQualification(payload) {
    try {
      const res = await axios.post(
        `v1/api/customer/add-qualifications/${payload.id}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addCustomerQualification(payload) {
    try {
      const res = await axios.post(
        `v1/api/customer/add-experience/${payload.id}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addCustomerDocument(payload) {
    try {
      const res = await axios.post(
        `v1/api/customer/add-document?customerId=${payload.id}`,
        payload.documentFormData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async deleteCustomerQualification(payload) {
    try {
      const res = await axios.delete(
        `v1/api/customer/delete-qualifications/${payload.id}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async deleteCustomerExperience(payload) {
    try {
      const res = await axios.delete(
        `v1/api/customer/delete-experience/${payload.id}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async deleteCustomerDocument(payload) {
    try {
      const res = await axios.delete(
        `v1/api/customer/delete-document/${payload.id}`,

        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async editCustomerExperience(payload) {
    try {
      const res = await axios.post(
        `v1/api/customer/update-experience/${payload.id}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async editCustomerQualifications(payload) {
    try {
      const res = await axios.post(
        `v1/api/customer/update-qualifications/${payload.id}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchEductionVisas(payload) {
    try {
      const res = await axios.get(
        `v1/api/visa/get-visa-sub-class-by-category/education`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchMigrationVisas(payload) {
    try {
      const res = await axios.get(
        "/v1/api/visa/get-visa-sub-class-by-category/migration",
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addEducationVisa(payload) {
    try {
      const res = await axios.post(`v1/api/visa/add-visa-sub-class`, payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addMigrationVisa(payload) {
    try {
      const res = await axios.post("v1/api/visa/add-visa-sub-class", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateEducationVisas(payload) {
    try {
      const res = await axios.post(
        `v1/api/visa/update-visa-sub-class/${payload.id}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async deleteMigrationVisa(payload) {
    try {
      const res = await axios.delete(
        `v1/api/visa/delete-visa-sub-class/${payload.id}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async assignAgents(payload) {
    try {
      const res = await axios.put(
        `v1/api/cases/assign-agent-or-consultant-for-case/${payload.id}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchClientById(payload) {
    try {
      const res = await axios.get(`v1/api/customer/get-by-id/${payload}`, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchClientActivityLogById(payload) {
    try {
      const res = await axios.get(
        `/v1/api/customer/get-all-log/${payload}
        `,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchMigrationVisaCases(payload) {
    try {
      const res = await axios.get(
        "/v1/api/visa/get-visa-sub-class-by-category/migration",
        {
          headers: await HeaderData(),
        }
      );
      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchEducationVisaCases(payload) {
    try {
      const res = await axios.get(
        "/v1/api/visa/get-visa-sub-class-by-category/education",
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async startCounselling(payload) {
    try {
      const res = await axios.put(
        `v1/api/counselling/start-counselling/${payload.id}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchInstituteBasedOnLocationType(payload) {
    try {
      const res = await axios.get(
        `/v1/api/institute/search-filter-by-shore?shore=${payload.location}&countryId=${payload.countryId}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchDegreeByInstituteFilter(payload) {
    try {
      const res = await axios.get(
        `v1/api/institute/get-active-degree/${payload.institute}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addTodo(payload) {
    try {
      const res = await axios.post(
        `v1/api/customer/todo-list/add-to-my-todo-list`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchTodo(payload) {
    try {
      const res = await axios.get(
        "v1/api/customer/todo-list/get-my-todo-list",
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async changeTodoStatus(payload) {
    try {
      const res = await axios.put(
        `v1/api/customer/todo-list/mark-unmark-my-todo-list/${payload.id}`,
        payload.value,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchActiveConsultants(payload) {
    try {
      const res = await axios.get(
        `v1/api/customer/get-active-consultant-list?page=${
          payload.page ?? 1
        }&pageSize=${payload?.pageSize ?? 10}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }

  async fetchAttendance(payload) {
    try {
      const res = await axios.post("/v1/api/users/get-attendance", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async checkIn(payload) {
    try {
      const res = await axios.post("/v1/api/users/checkIn", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async checkOut(payload) {
    try {
      const res = await axios.post(
        `/v1/api/users/checkOut/${payload.id}`,
        payload.CheckOutFormData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchReport(payload) {
    try {
      const res = await axios.post(
        "/v1/api/users/get-monthly-report",
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchAttandanceOfAllEmployee(payload) {
    try {
      const res = await axios.post(
        `/v1/api/users/get-all-employee-attendance`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchTodayAssignedTask(payload) {
    try {
      const res = await axios.post(
        `/v1/api/consultancy/get-task-by-session-date`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchMyCounselling(payload) {
    try {
      const res = await axios.get(
        `v1/api/appointment/get-appointment-for-consultant`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchMyCasesInProgress(payload) {
    try {
      const res = await axios.get(
        `v1/api/cases/get-cases-for-consultant-by-progress-status/IN_PROGRESS`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchMyCasesCompleted(payload) {
    try {
      const res = await axios.get(
        `v1/api/cases/get-cases-for-consultant-by-progress-status/COMPLETED`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async changeProfileImage(payload) {
    try {
      const res = await axios.post("v1/api/profile/upload-image", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async changeCompanyLogo(payload) {
    try {
      const res = await axios.post(
        "/v1/api/customer/add-company-logo",
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchBranch(payload) {
    try {
      const res = await axios.get(
        `v2/api/client/branch/get-my-branch?page=${
          payload?.page ?? 1
        }&pageSize=${payload?.pageSize ?? 10}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addBranch(payload) {
    try {
      const res = await axios.post(
        `/v2/api/client/branch/create-new-branch`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateBranch(payload) {
    try {
      const res = await axios.put(
        `/v1/api/customer/branch/update-branch/${payload.id}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchPrivacyPolicy(payload) {
    try {
      const res = await axios.get(`/v1/api/privacy-terms`, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async searchContacts(payload) {
    try {
      const res = await axios.get(
        `/v1/api/contact/search-all?keyword=${
          payload?.searchKey?.keyword
        }&page=${payload?.page ?? 1}&pageSize=${payload?.pageSize ?? 10}`,

        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async searchActiveContacts(payload) {
    try {
      const res = await axios.get(
        `/v1/api/contact/search-all-active?keyword=${
          payload?.searchKey?.keyword
        }&page=${payload?.page ?? 1}&pageSize=${payload?.pageSize ?? 10}`,

        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async searchLead(payload) {
    try {
      const res = await axios.post(
        `/v1/api/leads/search?page=${payload?.page ?? 1}&pageSize=${
          payload?.pageSize ?? 10
        }`,
        payload?.searchKey,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async searchNotAssignedLead(payload) {
    try {
      const res = await axios.get(
        `/v1/api/leads/search/NOT_ASSIGNED?keyword=${
          payload?.searchKey?.keyword
        }&page=${payload?.page ?? 1}&pageSize=${payload?.pageSize ?? 10}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async searchInProgressLead(payload) {
    try {
      const res = await axios.get(
        `/v1/api/leads/search/IN_PROGRESS?keyword=${
          payload?.searchKey?.keyword
        }&page=${payload?.page ?? 1}&pageSize=${payload?.pageSize ?? 10}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async searchCompletedLead(payload) {
    try {
      const res = await axios.get(
        `/v1/api/leads/search/COMPLETED?keyword=${
          payload?.searchKey?.keyword
        }&page=${payload?.page ?? 1}&pageSize=${payload?.pageSize ?? 10}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async searchAppointment(payload) {
    try {
      const res = await axios.post(
        `/v1/api/appointment/search?page=${payload?.page ?? 1}&pageSize=${
          payload?.pageSize ?? 10
        }`,
        payload.searchKey,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async searchPendingAppointment(payload) {
    try {
      const res = await axios.post(
        `/v1/api/appointment/search/PENDING?page=${
          payload?.page ?? 1
        }&pageSize=${payload?.pageSize ?? 10}`,
        payload.searchKey,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async searchConfirmedAppointment(payload) {
    try {
      const res = await axios.post(
        `/v1/api/appointment/search/CONFIRMED?page=${
          payload?.page ?? 1
        }&pageSize=${payload?.pageSize ?? 10}`,
        payload.searchKey,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async searchCompletedAppointment(payload) {
    try {
      const res = await axios.post(
        `/v1/api/appointment/search/COMPLETED?page=${
          payload?.page ?? 1
        }&pageSize=${payload?.pageSize ?? 10}`,
        payload.searchKey,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async searchCases(payload) {
    try {
      const res = await axios.get(
        `/v1/api/cases/search-case?searchWord=${payload.searchKey.keyword}`,

        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async searchInProgressCases(payload) {
    try {
      const res = await axios.get(
        `/v1/api/cases/search-case?searchWord=${payload.searchKey.keyword}&filterBy=IN_PROGRESS&page=${payload?.page}&pageSize=${payload.pageSize}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async searchNotAssignedCases(payload) {
    try {
      const res = await axios.get(
        `/v1/api/cases/search-case?searchWord=${payload.searchKey.keyword}&filterBy=NOT_ASSIGNED&page=${payload?.page}&pageSize=${payload.pageSize}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async searchCompletedCases(payload) {
    try {
      const res = await axios.get(
        `/v1/api/cases/search-case?searchWord=${payload.searchKey.keyword}&filterBy=COMPLETED&page=${payload?.page}&pageSize=${payload.pageSize}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async searchClients(payload) {
    try {
      const res = await axios.get(
        `/v1/api/customer/search?keyword=${payload?.searchKey?.keyword}&page=${
          payload?.page ?? 1
        }&pageSize=${payload?.pageSize ?? 10}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async searchActiveClients(payload) {
    try {
      const res = await axios.get(
        `/v1/api/customer/search-active?keyword=${
          payload?.searchKey?.keyword
        }&page=${payload?.page ?? 1}&pageSize=${payload?.pageSize ?? 10}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }

  async searchEmployees(payload) {
    try {
      const res = await axios.get(
        `/v1/api/consultancy/employee/search-all?keyword=${
          payload?.searchKey?.keyword
        }&page=${payload?.page ?? 1}&pageSize=${payload?.pageSize ?? 10}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async searchActiveEmployees(payload) {
    try {
      const res = await axios.get(
        `/v1/api/consultancy/employee/search-all-active?keyword=${
          payload?.searchKey?.keyword
        }&page=${payload?.page ?? 1}&pageSize=${payload?.pageSize ?? 10}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async searchActiveConsultants(payload) {
    try {
      const res = await axios.get(
        `/v1/api/consultancy/employee/consultant/search-all-active?keyword=${
          payload?.searchKey?.keyword
        }&page=${payload?.page ?? 1}&pageSize=${payload?.pageSize ?? 10}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async searchInstitues(payload) {
    try {
      const res = await axios.get(
        `/v1/api/institute/search?keyword=${payload?.searchKey?.keyword}&page=${
          payload?.page ?? 1
        }&pageSize=${payload?.pageSize ?? 10}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async searchActiveInstitutes(payload) {
    try {
      const res = await axios.get(
        `/v1/api/institute/search-active?keyword=${
          payload?.searchKey?.keyword
        }&page=${payload?.page ?? 1}&pageSize=${payload?.pageSize ?? 10}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }

  async searchCourses(payload) {
    try {
      const res = await axios.get(
        `/v1/api/institute/courses/search-all?keyword=${
          payload?.searchKey?.keyword
        }&page=${payload?.page ?? 1}&pageSize=${payload?.pageSize ?? 10}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async searchActiveCourses(payload) {
    try {
      const res = await axios.get(
        `/v1/api/institute/courses/search-active?keyword=${
          payload?.searchKey?.keyword
        }&page=${payload?.page ?? 1}&pageSize=${payload?.pageSize ?? 10}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async searchHeadAgent(payload) {
    try {
      const res = await axios.get(
        `/v1/api/agent/search/ALL?keyword=${payload?.searchKey?.keyword}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async searchActiveHeadAgent(payload) {
    try {
      const res = await axios.get(
        `/v1/api/agent/search/ACTIVE?keyword=${payload?.searchKey?.keyword}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }

  async searchActiveSubAgent(payload) {
    try {
      const res = await axios.get(
        `/v1/api/agent/sub/search/ACTIVE?keyword=${payload?.searchKey?.keyword}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async searchSubAgent(payload) {
    try {
      const res = await axios.get(
        `/v1/api/agent/sub/search/ALL?keyword=${payload?.searchKey?.keyword}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async searchCompanies(payload) {
    try {
      const res = await axios.get(
        `/v1/api/company/search/ALL?keyword=${payload?.searchKey?.keyword}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async searchActiveCompanies(payload) {
    try {
      const res = await axios.get(
        `/v1/api/company/search/ACTIVE?keyword=${payload?.searchKey?.keyword}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async searchTask(payload) {
    try {
      const res = await axios.get(
        `/v1/api/task/search?keyword=${payload?.searchKey?.keyword}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchLeadEmailTemplate(payload) {
    try {
      const res = await axios.get(`/v1/api/template/get-email-template/LEAD`, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchCaseEmailTemplate(payload) {
    try {
      const res = await axios.get(`/v1/api/template/get-email-template/CASE`, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchAppointmentEmailTemplate(payload) {
    try {
      const res = await axios.get(
        `/v1/api/template/get-email-template/APPOINTMENT`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchClientEmailTemplate(payload) {
    try {
      const res = await axios.get(
        `/v1/api/template/get-email-template/CLIENT`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }

  async addEmailTemplate(payload) {
    try {
      const res = await axios.put(
        `/v1/api/template/update-email-template/${payload.templateType}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateDashboardSettings(payload) {
    try {
      const res = await axios.put(
        "/v1/api/dashboard/update-settings",
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async importDropdownDataFromHeadOffice(payload) {
    try {
      const res = await axios.get(`/v2/api/client/branch/import-all`, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updatePrefix(payload) {
    try {
      const res = await axios.put("/v1/api/customer/add-prefix", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }

  async fetchReminderExpiryWorkingHour(payload) {
    try {
      const res = await axios.get("/v1/api/customer/get-setting-response", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateReminderExpiryWorkingHour(payload) {
    try {
      const res = await axios.put(
        "/v1/api/customer/add-expiry-dates",
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }

  async fetchMailboxInbox(payload) {
    try {
      const res = await axios.get(
        `/v2/api/mailbox/get-inbox-mail-box?page=${
          payload.page ?? 1
        }&pageSize=${payload?.pageSize ?? 10}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchMailboxSent(payload) {
    try {
      const res = await axios.get(
        `/v2/api/mailbox/get-sent-mail-box?page=${payload.page ?? 1}&pageSize=${
          payload?.pageSize ?? 10
        }`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async sendComposedEmail(payload) {
    try {
      const res = await axios.post(
        "/v2/api/mailbox/send-mail",
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async replyToEmail(payload) {
    try {
      const res = await axios.post(
        `/v2/api/mailbox/reply-mail`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchMailboxDraft(payload) {
    try {
      const res = await axios.get(
        `/v2/api/mailbox/get-my-draft?page=${payload.page ?? 1}&pageSize=${
          payload?.pageSize ?? 10
        }`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addMailToDraft(payload) {
    try {
      const res = await axios.post(
        `/v2/api/mailbox/add-to-draft?draftId=${payload.draftId}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );
      return res;
    } catch (err) {
      throw err;
    }
  }
  async deleteDraft(payload) {
    try {
      const res = await axios.put(
        `/v2/api/mailbox/remove-from-draft?draftId=${payload.draftId}`,
        payload.draftId,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async markMailAsSeen(payload) {
    try {
      const res = await axios.get(
        `/v2/api/mailbox/open-by-id/${payload.mailId}`,
        {
          headers: await HeaderData(),
        }
      );
      return res;
    } catch (err) {
      throw err;
    }
  }
  async searchInstituteForCourses(payload) {
    try {
      const res = await axios.get(
        `/v1/api/institute/courses/search-by-shore-and-country?shore=${payload.shore}&countryId=${payload.countryId}&keyword=${payload.keyword}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchAvailableEmail(payload) {
    try {
      const res = await axios.get(
        `/v2/api/mailbox/get-available-emails?searchWord=${payload.keyword}`,
        {
          headers: await HeaderData(),
        }
      );
      return res;
    } catch (err) {
      throw err;
    }
  }

  async downloadCSV(payload) {
    try {
      const res = await axios.post(
        "/v1/api/customer/download/client.csv",
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }

  async downloadTaskCSV(payload) {
    try {
      const res = await axios.post("/v1/api/task/download/task.csv", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }

  async downleadLeadCSV(payload) {
    try {
      const res = await axios.post("/v1/api/leads/download/lead.csv", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }

  async setDefaultCurrency(payload) {
    try {
      const res = await axios.put(
        `/v1/api/customer/set-default-currency?defaultSelectedCurrency=${
          payload?.selectCurrency
        }&country=${payload?.country ? payload.country : null}&timezone=${
          payload.timezone ? payload.timezone : null
        }`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }

  async fetchDegree(payload) {
    try {
      const res = await axios.get(
        `/v1/api/institute/get-all-degree?page=${payload?.page ?? 1}&pageSize=${
          payload?.pageSize ?? 10
        }`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchActiveDegree(payload) {
    try {
      const res = await axios.get(
        `/v1/api/institute/get-all-active-degree?page=${
          payload?.page ?? 1
        }&pageSize=${payload?.pageSize ?? 10}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async changeDegreeStatus(payload) {
    try {
      const res = await axios.patch(
        `/v1/api/institute/change-degree-status/${payload.status}/${payload.degreeId}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }

  async updateDegree(payload) {
    try {
      const res = await axios.post(
        `/v1/api/institute/update-degree/${payload.degreeId}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async deleteDegree(payload) {
    try {
      const res = await axios.delete(
        `/v1/api/institute/delete-degree/${payload.degreeId}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }

  async fetchCampaign(payload) {
    try {
      const res = await axios.get(
        `/v1/api/contact/campaign/get-all?page=${payload?.page ?? 1}&pageSize=${
          payload?.pageSize ?? 10
        }`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchActiveCampaign(payload) {
    try {
      const res = await axios.get(
        `/v1/api/contact/campaign/get-active?page=${
          payload?.page ?? 1
        }&pageSize=${payload?.pageSize ?? 10}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async changeCampaignStatus(payload) {
    try {
      const res = await axios.patch(
        `/v1/api/contact/change-campaign-status/${payload.status}/${payload.campaignId}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateCampaign(payload) {
    try {
      const res = await axios.post(
        `/v1/api/contact/update-campaign/${payload.campaignId}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }

  async fetchFormDetailsOfCampaign(payload) {
    try {
      const res = await axios.get(
        `/v1/api/contact/get-form-details-campaign?campaignId=${payload.campaignId}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchQRcodeOfCampaign(payload) {
    try {
      const res = await axios.post(
        `/v1/api/contact/get-qr-of-campaign/${payload.campaignId}`,
        { payload },
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addLeadFromCampaign(payload) {
    try {
      const res = await axios.put(
        `/v1/api/leads/add-from-campaign?campaignId=${payload.campaignId}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchCampainFormDropdown(payload) {
    try {
      const res = await axios.get(
        `/v1/api/contact/campaign/get-dropdown?clientId=${payload?.companyId}${
          payload?.consultantId !== undefined && payload?.consultantId !== null
            ? `&consultantId=${payload.consultantId}`
            : ""
        }`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateAutoreminderSettings(payload) {
    try {
      const res = await axios.post(
        "/v1/api/auto-reminder/set-config/bulk",
        payload,
        {
          headers: await HeaderData(),
        }
      );
      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchAutoReminderData(payload) {
    try {
      // const res = await axios.get(`/v2/api/auto-reminder/get-all-config`, {
      const res = await axios.get(
        `v2/api/client/branch/auto-reminder/get-all-config/${payload}`,
        {
          headers: await HeaderData(),
        }
      );
      http: return res;
    } catch (err) {
      throw err;
    }
  }
  async verifyPurchase(payload) {
    try {
      const res = await axios.post("/v1/api/client/verify-purchase", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async changeTimezone(payload) {
    try {
      const res = await axios.put(
        "/v1/api/customer/add-timezone-setting",
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }

  async fetchTodayReminders(payload) {
    try {
      const res = await axios.post(
        `/v1/api/customer/reminder/get-all-of-a-certain-day`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchNotification(payload) {
    try {
      const res = await axios.get("v1/api/notification/get-my-notification", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }

  async globalSearchFromDashboard(payload) {
    try {
      const res = await axios.get(
        `/v1/api/customer/get-all-global-search?keyword=${
          payload.keyword
        }&pageSize=${payload?.pageSize ?? 5}&page=${payload.page ?? 1}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchLeadActivityLogById(payload) {
    try {
      const res = await axios.get(
        `/v1/api/leads/logs/get-by-lead/${payload.leadId}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async importCLientCsvFile(payload) {
    try {
      const res = await axios.post("/v1/api/customer/upload-csv", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async importLeadCsvFile(payload) {
    try {
      const res = await axios.post("/v1/api/lead/upload-csv", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async importTaskCsvFile(payload) {
    try {
      const res = await axios.post("/v1/api/task/upload-csv", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addAdditionalEmailsFrmGS(payload) {
    try {
      const res = await axios.post(
        "/v1/api/customer/add-additional-emails",
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async searchInstitutesFrmAdmin(payload) {
    try {
      const res = await axios.post(
        `/v1/api/institute/global-institute-to-client/${payload.keyword}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async importInstituteFromAdmin(payload) {
    try {
      const res = await axios.post(
        `/v1/api/institute/global-institute-to-client/${payload.adminInstituteId}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addOpeningSchedule(payload) {
    try {
      const res = await axios.post(
        `/v1/api/profile/add-opening-schedule`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async importInstitutesFromAdmin(payload) {
    try {
      const res = await axios.get(
        "/v1/api/institute/global-institute-to-client",

        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchAppointmentsByLeadId(payload) {
    try {
      const res = await axios.get(
        `/v1/api/appointment/get-by-lead/${payload.leadId}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addCourseWishlistForLeadId(payload) {
    try {
      const res = await axios.post(
        `/v1/api/leads/wishList/add-course/${payload.leadId}`,
        payload?.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchCourseWishlistByLeadId(payload) {
    try {
      const res = await axios.get(
        `/v1/api/leads/wishList/get/${payload.leadId}?page=${
          payload.page ?? 1
        }&pageSize=${payload?.pageSize ?? 10}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async deleteCourseFromWishlist(payload) {
    try {
      const res = await axios.delete(
        `/v1/api/leads/wishList/delete-course/${payload.wishListId}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async checkForExistingEmail(payload) {
    try {
      const res = await axios.get(
        `/v1/api/customer/validate-email?email=${payload.keyword}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async checkForExistingMobile(payload) {
    try {
      const res = await axios.get(
        `/v1/api/customer/validate-mobile?mobile=${payload.keyword}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async statusHistoryStatusUpdate(payload) {
    try {
      const res = await axios.post(
        `/v1/api/leads/updates/add?updates=${payload.update}&leadId=${payload.leadId}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async deleteIntakeFromCourse(payload) {
    try {
      const res = await axios.delete(
        `/v1/api/institute/courses-intake/delete-intake/${payload.intakeId}`,

        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchCourseForAnInstitute(payload) {
    try {
      const res = await axios.get(
        `/v1/api/institute/courses/get-by-institute/${payload.instituteId}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchCaseById(payload) {
    try {
      const res = await axios.get(
        `/v1/api/cases/get-by-id/${payload?.caseId}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async deleteBonusFromInstitute(payload) {
    try {
      const res = await axios.delete(
        `/v1/api/institute/bonus/delete/${payload?.bonusId}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchConsultants(payload) {
    try {
      const res = await axios.get(
        `/v1/api/customer/get-all-consultant-list?page=${
          payload.page ?? 1
        }&pageSize=${payload?.pageSize ?? 10}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addAppointmentFormFieldsForCampaign(payload) {
    try {
      const res = await axios.put(
        `/v1/api/contact/campaign/appointment-form/${payload.campaignId}`,
        payload?.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchQRcodeForAppointmentFormOfCampaign(payload) {
    try {
      const res = await axios.get(
        `/v1/api/contact/get-qr-of-appointment/${payload.campaignId}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchQRcodeForConsultantOfAppointmentForm(payload) {
    try {
      const res = await axios.get(
        `/v1/api/contact/get-qr-of-appointment/${payload.campaignId}/${payload.consultantId}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }

  async fetchAppointmentFormDetailsOfCampaign(payload) {
    try {
      const res = await axios.get(
        `/v1/api/contact/campaign/appointment-form-get?campaignId=${payload.campaignId}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchTimingDropdownForCampaign(payload) {
    try {
      const res = await axios.post(
        `/v1/api/consultant/campaign/get-timing-dropdown?sessionTime=${payload?.sessionTime}&consultantId=${payload?.counsellorId}`,
        payload?.values,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addAppointmentFromCampaign(payload) {
    try {
      const res = await axios.post(
        `/v1/api/appointment/add-from-campaign?campaignId=${payload?.campaignId}`,
        payload.formData,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchCompanyLogoAndFooterDetails(payload) {
    try {
      const res = await axios.get(
        `/v1/api/contact/campaign/get-footer?clientId=${payload.companyId}`,
        {
          headers: await HeaderData(),
        }
      );
      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchAllLeadStatuses(payload) {
    try {
      const res = await axios.get(`/v1/api/leads/get-all-lead-status`, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async setLeadStatusAsDefault(payload) {
    try {
      const res = await axios.patch(
        `/v1/api/leads/set-default-lead-status/${payload.leadStatusId}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchActiveBranch(payload) {
    try {
      const res = await axios.get(
        `/v1/api/customer/branch/get-my-active-branch?page=${
          payload?.page ?? 1
        }&pageSize=${payload?.pageSize ?? 10}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async changeBranchStatus(payload) {
    try {
      const res = await axios.patch(
        `/v1/api/customer/branch/change-status/${payload.status}/${payload.branchId}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchCounsellorForGivenDate(payload) {
    try {
      const res = await axios.get(
        `/v1/api/consultant/campaign/get-consultant-using-session?appointmentDate=${payload.appointmentDate}&campaignId=${payload.campaignId}`,
        {
          headers: await HeaderData(),
        }
      );
      return res;
    } catch (err) {
      throw err;
    }
  }
  async addCompanyDomainUrl(payload) {
    try {
      const res = await axios.get(
        `/v1/api/customer/add-login-url/${payload.companyDomainUrl}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchCompanyDetaisls(payload) {
    try {
      const res = await axios.get(
        `/v1/api/customer/get-information-login?${
          payload?.isEmail
            ? `companyEmail=${payload?.url}`
            : `loginCompanyUrl=${payload?.url}`
        }`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchQuotation(payload) {
    try {
      const res = await axios.get(
        `/v1/api/counselling/get-all-quotations?page=${
          payload.page ?? 1
        }&pageSize=${payload?.pageSize ?? 10}`,
        {
          headers: await HeaderData(),
        }
      );
      return res;
    } catch (err) {
      throw err;
    }
  }
  async addQuotation(payload) {
    try {
      const res = await axios.post(
        `/v1/api/counselling/create-quotation`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addTimeline(payload) {
    try {
      const res = await axios.post(
        "/v1/api/associated-timelines/add-timeline",
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchTimeline(payload) {
    try {
      const res = await axios.get(`/v1/api/associated-timelines/fetch-active`, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async approveQuotation(payload) {
    try {
      const res = await axios.patch(
        `/v1/api/counselling/approve-quotes/${payload.quoteId}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchQuotationById(payload) {
    try {
      const res = await axios.get(
        `/v1/api/counselling/get-quotations-by-id/${payload.quoteId}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async downloadQuotation(payload) {
    try {
      const res = await axios.get(
        `/v1/api/counselling/get-pdf/${payload.quotationId}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addQuotationSource(payload) {
    try {
      const res = await axios.post("/v1/api/quotation-source/add", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchQuotationSource(payload) {
    try {
      const res = await axios.get("/v1/api/quotation-source/get-active", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async fetchQuotationByCustomerId(payload) {
    try {
      const res = await axios.get(
        `/v1/api/counselling/get-quotations-customer/${payload}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
}
