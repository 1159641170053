import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  privacyPolicy: {},
};

const TermsAndPrivacyReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_PRIVACY_POLICY_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_PRIVACY_POLICY_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        privacyPolicy: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_PRIVACY_POLICY_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_PRIVACY_POLICY_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_PRIVACY_POLICY_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! TermsAndPrivacy added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_PRIVACY_POLICY_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to add TermsAndPrivacy.");

      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};

export default TermsAndPrivacyReducer;
