import React, { useEffect, useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
const { Option } = Select;

const AddPermission = ({
  record,
  role,
  setRole,
  parmissionAdded,
  permission,
  setPermission,
  isAddPermissionModalVisible,
  setIsAddPermissionModalVisible,
}) => {
  const addPermissionState = useSelector(
    (state) => state.RoleAndPermissionReducer
  );
  const [permissions, setPermissions] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isAddPermissionModalVisible === true) {
      dispatch({ type: "FETCH_PERMISSIONS_REQUEST" });
    }
  }, [isAddPermissionModalVisible]);
  const [form] = Form.useForm();
  const addPermissionSubmitHandler = (value) => {
    console.log("from checklist", value);
    console.log(record);
    const formData = {
      ...value,
    };
    dispatch({
      type: "UPDATE_PERMISSION_REQUEST",
      payload: { roleid: record.id, formData: { permissionList: permissions } },
    });
    // setRole(record.roleName);
    // setPermission(addPermissionState.roles?.data?.[0].associatedPermissions);
    // form.resetFields();
    setIsAddPermissionModalVisible(false);
  };
  console.log("From permission role", record);
  return (
    <Modal
      title="Add New Permission"
      open={isAddPermissionModalVisible}
      onCancel={() => {
        setIsAddPermissionModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addPermissionSubmitHandler}
        colon={true}
        form={form}
        initialValues=""
      >
        <Checkbox.Group
          options={addPermissionState.permissions}
          defaultValue={["Apple"]}
          onChange={(values) => {
            setPermissions(values);
            console.log("from checklist", values);
          }}
        />

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add New Permission
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddPermission;
