import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import "./Responsiveness.css";
import PrivateRoute from "./Helpers/PrivateRoute";
import Contacts from "./Pages/Contacts/Contacts";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Login from "./Pages/Login/Login";
import Leads from "./Pages/Leads/Leads";
import "./scss/my-app.css";
import Companies from "./Pages/Companies/Companies";
import Institute from "./Pages/Education/Institute/Institute";
import RegisterEmail from "./Pages/Signup/RegisterEmail";
import OTPverification from "./Pages/Signup/OTPverification";
import Signup from "./Pages/Signup/Signup";
import ForgotPassword from "./Pages/PasswordReset/ForgotPassword";
import ForgotPasswordOTPverification from "./Pages/PasswordReset/ForgotPasswordOTPverification";
import ChangePassword from "./Pages/PasswordReset/ChangePassword";
import Courses from "./Pages/Courses/Courses";
import Profile from "./Pages/Profile/Profile";
import Appointments from "./Pages/Appointments/Appointments";
import Counsel from "./Pages/Counsel/Counsel";
import HeadAgent from "./Pages/HeadAgent/HeadAgent";
import SubAgent from "./Pages/SubAgent/SubAgent";
import Support from "./Pages/Support/Support";
import RolesAndPermission from "./Pages/RolesAndPermission/RolesAndPermission";
import EmployeeManager from "./Pages/EmployeeManager/EmployeeManager";
import Departments from "./Pages/EmployeeManager/Department/Departments";
import Visas from "./Pages/Visas/Visas";
import Settings from "./Pages/Settings/Settings";
import Consultancies from "./Pages/Consultancies/Consultancies";
import Task from "./Pages/Tasks/Task";
import Resources from "./Pages/Resources/Resources";
import Clients from "./Pages/Clients/Clients";
import Country from "./Pages/Settings/Country";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { useEffect } from "react";
import firebase from "./firebase";
import app from "./firebase";
import CollegeFinder from "./Pages/CollegeFinder/CollegeFinder";
import EducationVisa from "./Pages/VisaClass/Education/EducationVisa";
import MigrationVisas from "./Pages/VisaClass/Migration/MigrationVisas";
import VisaTypeCases from "./Pages/VisaClass/VisaTypeCases/VisaTypeCases";
import Todo from "./Pages/Todo/Todo";
import Consultants from "./Pages/Consultants/Consultants";
import OrganizationalSettings from "./Pages/OrganizationalSettings/OrganizationalSettings";
import Templates from "./Pages/OrganizationalSettings/Templates/Templates";
import Email from "./Pages/OrganizationalSettings/Email/Email";
import Attendance from "./Pages/Attendance/Attendance";
import MyCounselling from "./Pages/MyCounselling/MyCounselling";
import MyCases from "./Pages/MyCases/MyCases";
import Branches from "./Pages/Branch/Branches";
import Branch from "./Pages/Branch/Branch/Branch";
import { useSelector } from "react-redux";
import TableSkeleton from "./Helpers/TableSkeleton/tableSkelaton";
import PrivacyPolicy from "./Pages/PrivacyAndTerms/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "./Pages/PrivacyAndTerms/TermsConditions/TermsConditions";
import PaymentSuccess from "./Pages/PaymentSuccess/PaymentSuccess";
import SubscriptionSuccess from "./Pages/PaymentSuccess/SubscriptionSuccess";
import SubscriptionFailure from "./Pages/PaymentSuccess/SubscriptionFailure";
import SubscriptionList from "./Pages/Profile/SubscriptionPlan/SubscriptionList";
import DashboardSettings from "./Pages/OrganizationalSettings/DashboardSettings/DashboardSettings";
import LeadSetttings from "./Pages/OrganizationalSettings/LeadSettings/LeadSetttings";
import AppointmentSettings from "./Pages/OrganizationalSettings/AppointmentSettings/AppointmentSettings";
import CaseSettings from "./Pages/OrganizationalSettings/CaseSettings/CaseSettings";
import GeneralSettings from "./Pages/OrganizationalSettings/GeneralSettings/GeneralSettings";
import MonthlyReport from "./Pages/Attendance/MonthlyReport";
import CheckInSettings from "./Pages/OrganizationalSettings/CheckInSettings/CheckInSettings";
import Mailbox from "./Pages/Mailbox/Mailbox";
import AutoreminderSettings from "./Pages/OrganizationalSettings/AutoreminderSettings/AutoreminderSettings";
import Degree from "./Pages/Degree/Degree";
import Campaign from "./Pages/CampaignManager/Campaign";
import CampaignForm from "./Pages/CampaignManager/CampaignForm";
import SuccessPage from "./Pages/CampaignManager/SuccessPage";
import ErrorPage from "./Pages/CampaignManager/ErrorPage";
import EmployeeSettings from "./Pages/OrganizationalSettings/EmployeeSettings/EmployeeSettings";
import Import from "./Pages/OrganizationalSettings/Import/Import";
import AppointmentForm from "./Pages/CampaignManager/AppointmentForm";
import CompanyLogi from "./Pages/Login/CompanyLogin";
import CompanyLogin from "./Pages/Login/CompanyLogin";
import CompanyLoginWithEmail from "./Pages/Login/CompanyLoginWithEmail";
import Quotation from "./Pages/Quotation/Quotation";
import QuotationSettings from "./Pages/OrganizationalSettings/QuotationSettings/QuotationSettings";
const App = () => {
  const store = useSelector((state) => {
    return {
      branchState: state.BranchReducer,
    };
  });
  // useEffect(() => {
  //   const messaging = firebase.messaging();
  //   messaging
  //     .requestPermission()
  //     .then(() => {
  //       messaging.getToken();
  //     })
  //     .then((token) => console.log(token))
  //     .catch((error) => console.log(error));
  // }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:id" element={<Login />} />
        <Route path="/" element={<CompanyLogin />} />
        <Route path="/email-sign-in" element={<CompanyLoginWithEmail />} />
        <Route path="/register" element={<RegisterEmail />} />
        <Route path="/otpverification" element={<OTPverification />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/password_reset/:id" element={<ForgotPassword />} />
        <Route
          path="/password_reset_otp_verification/:id"
          element={<ForgotPasswordOTPverification />}
        />
        <Route path="/change_password" element={<ChangePassword />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsConditions />} />

        <Route
          path="/my-profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />

        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              {/* <Suspense fallback={<TableSkeleton />}> */}
              <Dashboard />
              {/* </Suspense> */}
            </PrivateRoute>
          }
        />

        <Route
          path="/contacts"
          element={
            <PrivateRoute>
              <Contacts
                isHeadBranch={store.branchState.isHeadBranch}
                branchRecord={store.branchState.branchRecord}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/leads"
          element={
            <PrivateRoute>
              <Leads
                isHeadBranch={store.branchState.isHeadBranch}
                branchRecord={store.branchState.branchRecord}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/appointments"
          element={
            <PrivateRoute>
              <Appointments
                isHeadBranch={store.branchState.isHeadBranch}
                branchRecord={store.branchState.branchRecord}
              />
            </PrivateRoute>
          }
        />
        {/* <Route
          path="/counselling"
          element={
            <PrivateRoute>
              <Counsel />
            </PrivateRoute>
          }
        /> */}
        <Route
          path="/companies"
          element={
            <PrivateRoute>
              <Companies />
            </PrivateRoute>
          }
        />
        <Route
          path="/institute"
          element={
            <PrivateRoute>
              <Institute />
            </PrivateRoute>
          }
        />
        <Route
          path="/courses"
          element={
            <PrivateRoute>
              <Courses />
            </PrivateRoute>
          }
        />
        <Route
          path="/degree"
          element={
            <PrivateRoute>
              <Degree />
            </PrivateRoute>
          }
        />
        <Route
          path="/head-agent"
          element={
            <PrivateRoute>
              <HeadAgent />
            </PrivateRoute>
          }
        />
        <Route
          path="/sub-agent"
          element={
            <PrivateRoute>
              <SubAgent />
            </PrivateRoute>
          }
        />
        <Route
          path="/support"
          element={
            <PrivateRoute>
              <Support />
            </PrivateRoute>
          }
        />
        <Route
          path="/roles-and-permission"
          element={
            <PrivateRoute>
              <RolesAndPermission />
            </PrivateRoute>
          }
        />
        <Route
          path="/manage-employees"
          element={
            <PrivateRoute>
              <EmployeeManager />
            </PrivateRoute>
          }
        />
        <Route
          path="/manage-department"
          element={
            <PrivateRoute>
              <Departments />
            </PrivateRoute>
          }
        />
        <Route
          path="/cases"
          element={
            <PrivateRoute>
              <Visas
                isHeadBranch={store.branchState.isHeadBranch}
                branchRecord={store.branchState.branchRecord}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          }
        />
        <Route
          path="/manage-consultancies"
          element={
            <PrivateRoute>
              <Consultancies />
            </PrivateRoute>
          }
        />
        <Route
          path="/tasks"
          element={
            <PrivateRoute>
              <Task />
            </PrivateRoute>
          }
        />
        <Route
          path="/resources"
          element={
            <PrivateRoute>
              <Resources />
            </PrivateRoute>
          }
        />
        <Route
          path="/clients"
          element={
            <PrivateRoute>
              <Clients />
            </PrivateRoute>
          }
        />
        <Route
          path="/organizationalSettings/country"
          element={
            <PrivateRoute>
              <Country />
            </PrivateRoute>
          }
        />
        <Route
          path="/collegeFinder"
          element={
            <PrivateRoute>
              <CollegeFinder />
            </PrivateRoute>
          }
        />
        <Route
          path="/education"
          element={
            <PrivateRoute>
              <EducationVisa />
            </PrivateRoute>
          }
        />
        <Route
          path="/migration"
          element={
            <PrivateRoute>
              <MigrationVisas />
            </PrivateRoute>
          }
        />
        <Route
          path="/visaTypeCases"
          element={
            <PrivateRoute>
              <VisaTypeCases />
            </PrivateRoute>
          }
        />
        <Route
          path="/todo"
          element={
            <PrivateRoute>
              <Todo />
            </PrivateRoute>
          }
        />
        <Route
          path="/manage-consultants"
          element={
            <PrivateRoute>
              <Consultants />
            </PrivateRoute>
          }
        />
        <Route
          path="/organizationalSettings"
          element={
            <PrivateRoute>
              <OrganizationalSettings />
            </PrivateRoute>
          }
        />
        <Route
          path="/organizationalSettings/templates"
          element={
            <PrivateRoute>
              <Templates />
            </PrivateRoute>
          }
        />
        <Route
          path="/organizationalSettings/import"
          element={
            <PrivateRoute>
              <Import />
            </PrivateRoute>
          }
        />
        <Route
          path="/attendance"
          element={
            <PrivateRoute>
              <Attendance />
            </PrivateRoute>
          }
        />
        <Route
          path="myCounselling"
          element={
            <PrivateRoute>
              <MyCounselling />
            </PrivateRoute>
          }
        />
        <Route
          path="/myCases"
          element={
            <PrivateRoute>
              <MyCases />
            </PrivateRoute>
          }
        />

        <Route
          path="/branch-cases"
          element={
            <PrivateRoute>
              <Branch />
            </PrivateRoute>
          }
        />
        <Route
          path="branch"
          element={
            <PrivateRoute>
              <Branch />
            </PrivateRoute>
          }
        />
        <Route
          path="branch-lead"
          element={
            <PrivateRoute>
              <Branches />
            </PrivateRoute>
          }
        />
        <Route
          path="/branch-appointments"
          element={
            <PrivateRoute>
              <Branches />
            </PrivateRoute>
          }
        />
        <Route
          path="/branch-Clients"
          element={
            <PrivateRoute>
              <Branches />
            </PrivateRoute>
          }
        />
        <Route
          path="/branch-head-agent"
          element={
            <PrivateRoute>
              <Branches />
            </PrivateRoute>
          }
        />
        <Route
          path="/branch-sub-agent"
          element={
            <PrivateRoute>
              <Branches />
            </PrivateRoute>
          }
        />
        <Route
          path="/subscription"
          element={
            <PrivateRoute>
              <SubscriptionList />
            </PrivateRoute>
          }
        />
        <Route
          path="/organizationalSettings/dashboard"
          element={
            <PrivateRoute>
              <DashboardSettings />
            </PrivateRoute>
          }
        />
        <Route
          path="/organizationalSettings/lead-settings"
          element={
            <PrivateRoute>
              <LeadSetttings />
            </PrivateRoute>
          }
        />
        <Route
          path="/organizationalSettings/appointment-settings"
          element={
            <PrivateRoute>
              <AppointmentSettings />
            </PrivateRoute>
          }
        />
        <Route
          path="/organizationalSettings/case-settings"
          element={
            <PrivateRoute>
              <CaseSettings />
            </PrivateRoute>
          }
        />
        <Route
          path="/organizationalSettings/general-settings"
          element={
            <PrivateRoute>
              <GeneralSettings />
            </PrivateRoute>
          }
        />
        <Route
          path="/report"
          element={
            <PrivateRoute>
              <MonthlyReport />
            </PrivateRoute>
          }
        />
        <Route
          path="/organizationalSettings/check-in"
          element={
            <PrivateRoute>
              <CheckInSettings />
            </PrivateRoute>
          }
        />
        <Route
          path="/organizationalSettings/quotation-settings"
          element={
            <PrivateRoute>
              <QuotationSettings />
            </PrivateRoute>
          }
        />
        <Route
          path="/mailbox"
          element={
            <PrivateRoute>
              <Mailbox />
            </PrivateRoute>
          }
        />
        <Route
          path="/manage-campaign"
          element={
            <PrivateRoute>
              <Campaign />
            </PrivateRoute>
          }
        />
        <Route
          path="/organizationalSettings/autoreminder"
          element={
            <PrivateRoute>
              <AutoreminderSettings />
            </PrivateRoute>
          }
        />
        <Route
          path="/organizationalSettings/employee-settings"
          element={<EmployeeSettings />}
        />
        <Route
          path="/khrouch/campaign-qr-generator"
          element={<CampaignForm />}
        />
        <Route
          path="/khrouch/campaign-appointment"
          element={<AppointmentForm />}
        />
        <Route path="/submissionSuccess" element={<SuccessPage />} />
        <Route path="/submissionFailed" element={<ErrorPage />} />
        <Route
          path="/khrouch/stripe-payment-success"
          element={
            <PrivateRoute>
              <SubscriptionSuccess />
            </PrivateRoute>
          }
        />
        <Route
          path="/khrouch/stripe-payment-cancel"
          element={
            <PrivateRoute>
              <SubscriptionFailure />
            </PrivateRoute>
          }
        />
        <Route
          path="/quotation"
          element={
            <PrivateRoute>
              <Quotation />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
