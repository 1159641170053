import React, { useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Image,
  Input,
  Modal,
  Select,
  Tag,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import client from "./../../Assets/client.png";
import { FcDocument } from "react-icons/fc";
import { GoLocation } from "react-icons/go";
import {
  formattedDate,
  formattedDateTime,
  fourTagResponses,
  threeTagResponses,
} from "../../HelperFunction";
const { Option } = Select;

const ContactDetails = ({
  record,
  isContactsDetailsModelVisible,
  setIsContactsDetailsModelVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [current, setCurrent] = useState("contactDetails");

  const clientDetailsTabList = [
    {
      key: "contactDetails",
      tab: "Contact Details",
    },
  ];
  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };
  console.log("record from appointment details", record);

  return (
    <Drawer
      title="Contact Details"
      open={isContactsDetailsModelVisible}
      onClose={() => {
        setIsContactsDetailsModelVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Card style={{ borderRadius: "0.5rem" }} className="drawerStyle">
        <div
          className="flexRowWithoutStyle"
          style={{ gap: "1rem", alignItems: "flex-start" }}
        >
          {record.contactImage ? (
            <Image src={record.contactImage} className={"clientImage"} />
          ) : (
            <Image src={client} className={"clientImage"} />
          )}
          <div
            className="flexColumnWithoutStyle"
            style={{
              justifyContent: "flex-start",
              // alignItems: "flex-start",
              width: "100%",
              gap: "0.5rem",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: "bold" }}>
              {`${record?.fullName ?? ""}`}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.4rem", color: "#aaa" }}
            >
              {record.phoneNumber && (
                <span className="flexRowWithGap">
                  <AiOutlinePhone
                    style={{
                      fontSize: "20",
                    }}
                  />{" "}
                  {record.phoneNumber ? record.phoneNumber : "N/A"}
                </span>
              )}
              {record?.address && (
                <span className="flexRowWithGap">
                  <GoLocation
                    style={{
                      fontSize: "16",
                    }}
                  />{" "}
                  {record?.address ? record?.address : "N/A"}
                </span>
              )}
              {record.email && (
                <span className="flexRowWithGap">
                  <AiOutlineMail
                    style={{
                      fontSize: "16",
                    }}
                  />{" "}
                  {record.email ? record.email : "N/A"}
                </span>
              )}
            </div>
            {/* <div>
              <div>
                <span className="profileTitle">Campaign:</span>
                <span>
                  {record?.leadResponse?.customerResponse?.campaign?.name ??
                    "N/A"}
                </span>
              </div>
            </div> */}

            {/* <div className="flexColumnWithoutStyle">
              <h4>{record?.country?.name}</h4>
              <div>{record?.levelOfCollege?.name}</div>
              <div>{record?.crisco}</div>
            </div> */}
          </div>
        </div>
        <Card
          bordered={false}
          tabList={clientDetailsTabList}
          activeTabKey={current}
          onTabChange={(key) => {
            setCurrent(key);
          }}
        >
          {current === "contactDetails" && (
            <>
              <div
                style={{
                  borderRadius: "1.5rem",
                  padding: "0.8rem",
                  display: "flex",
                  gap: "0.5rem",
                  flexDirection: "column",
                }}
              >
                {detailFun(
                  "Campaign",
                  `${record.campaign?.name ? record.campaign?.name : "N/A"}`
                )}
                {detailFun(
                  "Source",
                  `${record.source?.name ? record.source?.name : "N/A"}`
                )}
                {detailFun(
                  "Country of  Residence",
                  `${
                    record.countryOfResidence
                      ? record.countryOfResidence
                      : "N/A"
                  }`
                )}
              </div>
            </>
          )}
        </Card>
      </Card>
    </Drawer>
  );
};

export default ContactDetails;

// const ContactDetails = ({
// record,
// isContactsDetailsModelVisible,
// setIsContactsDetailsModelVisible,
// }) => {
//   const detailFun = (title, value) => {
//     return (
//       <>
//         <div className="flexRowSpaceBetween">
//           {title}:<span>{value}</span>
//         </div>
//         <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
//       </>
//     );
//   };
//   return (
//     <Modal
//       title="Contact Details"
//       open={isContactsDetailsModelVisible}
//       onCancel={() => {
//         setIsContactsDetailsModelVisible(false);
//       }}
//       footer={null}
//     >
//       <div className="flexColumnWithoutStyle" style={{ width: "100%" }}>
//         {detailFun(
//           "Image",
//           record.contactImage ? (
//             <Image
//               src={record.contactImage}
//               style={{
//                 height: "50px",
//                 width: "50px",
//                 border: "1px solid #ccc",
//               }}
//             />
//           ) : (
//             "N/A"
//           )
//         )}
//         {detailFun(
//           "Contact Name",
//           `${record.firstName ?? ""} ${record.lastName ?? ""}`
//         )}
//         {detailFun(
//           "Mobile Number",
//           `${record.phoneNumber ? record.phoneNumber : "N/A"}`
//         )}
//         {detailFun("Email", `${record.email ? record.email : "N/A"}`)}
//         {detailFun(
//           "Source",
//           `${record.source?.name ? record.source?.name : "N/A"}`
//         )}
//         {detailFun(
//           "Campaign",
//           `${record.campaign?.name ? record.campaign?.name : "N/A"}`
//         )}
//         {detailFun(
//           "Country of  Residence",
//           `${record.countryOfResidence ? record.countryOfResidence : "N/A"}`
//         )}
//       </div>
//     </Modal>
//   );
// };

// export default ContactDetails;
