import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
const { Option } = Select;

const AddOrderStatus = ({
  isAddOrderStatusModalVisible,
  setIsAddOrderStatusModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const addOrderStatusSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "ADD_ORDER_STATUS_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddOrderStatusModalVisible(false);
  };
  return (
    <Modal
      title="Add Order Status"
      open={isAddOrderStatusModalVisible}
      onCancel={() => {
        setIsAddOrderStatusModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addOrderStatusSubmitHandler}
        colon={true}
        form={form}
      >
        <Form.Item
          label="Name"
          name={"name"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter order status!",
            },
          ]}
        >
          <Input placeholder="name" />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Order Status
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddOrderStatus;
