import React, { useEffect } from "react";
import { Button, DatePicker, Divider, Form, Input, Radio, Select } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AiOutlinePlus } from "react-icons/ai";
import moment from "moment";
import axios from "axios";
import QuoteSummary from "./QuoteSummary";
import AddCountry from "../Leads/Country/AddCountry";
import { getCookie } from "../../Helpers/FrontendHelper";
import AddReferral from "../Leads/Referral/AddReferral";
const { Option } = Select;
const { TextArea } = Input;

const QuotationUserInfo = ({
  quotationData,
  setQuotationData,
  current,
  setCurrent,
  recordData,
}) => {
  const store = useSelector((state) => {
    return {
      leadState: state.LeadReducer,
      caseState: state.VisasReducer,
    };
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch({
      type: "FETCH_LEADS_DROPDOWN_REQUEST",
      payload: {
        fromInstituteVisaCase: true,
        visaSuccessFunction: visaSuccessFunction,
      },
    });
    dispatch({ type: "FETCH_CASES_DROPDOWN_REQUEST" });
  }, []);
  const [error, setError] = useState("");
  const [isAddCountryModalVisible, setIsAddCountryModalVisible] =
    useState(false);
  const [isAddReferralModalVisible, setIsAddReferralModalVisible] =
    useState(false);
  const addQuotationUserInfoSubmitHandler = () => {
    setCurrent(1);
  };
  const visaSuccessFunction = (response) => {
    console.log("defaultSelectedCurrency", response.data);
    setQuotationData((previousData) => {
      return {
        ...previousData,
        currency: response?.data?.defaultSelectedCurrency,
        countryOfResidence: response?.data?.defaultCountry?.name,
      };
    });
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexFlow: "wrap",
          gap: "1rem",
          alignItems: "stretch",
        }}
      >
        <div style={{ width: "100%", display: "flex", flex: 1 }}>
          <Form
            layout="vertical"
            onFinish={addQuotationUserInfoSubmitHandler}
            colon={true}
            form={form}
            className="drawerStyle"
            fields={[
              {
                name: ["clientType"],
                value: quotationData.clientType,
              },
              {
                name: ["customerId"],
                value: quotationData.customer,
              },
              {
                name: ["firstName"],
                value: quotationData.firstName,
              },

              {
                name: ["lastName"],
                value: quotationData.lastName,
              },
              {
                name: ["mobile"],
                value: quotationData.mobile,
              },
              {
                name: ["email"],
                value: quotationData.email,
              },
              {
                name: ["dob"],
                value:
                  quotationData.dob !== null &&
                  quotationData.dob !== "" &&
                  quotationData.dob !== undefined
                    ? moment.utc(quotationData.dob).local()
                    : null,
              },
              {
                name: ["phone"],
                value: quotationData.phone,
              },
              {
                name: ["address"],
                value: quotationData.address,
              },
              {
                name: ["passportNumber"],
                value: quotationData.passportNumber,
              },
              {
                name: ["maritalStatus"],
                value: quotationData.maritalStatus,
              },
              {
                name: ["sexStatus"],
                value: quotationData.sexStatus,
              },
              {
                name: ["countryOfResidence"],
                value: quotationData.countryOfResidence,
              },
              {
                name: ["referral"],
                value: quotationData.referralName,
              },
            ]}
          >
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Client Type"
                name={"clientType"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please select client type",
                  },
                ]}
              >
                <Radio.Group
                  onChange={(e) => {
                    console.log("from chang client type", e);

                    setQuotationData((previousData) => {
                      return {
                        ...previousData,
                        clientType: e.target.value,
                        customerId: null,
                        customer: null,
                        firstName: null,
                        lastName: null,
                        mobile: null,
                        email: null,
                        dob: null,
                        phone: null,
                        address: null,
                        passportNumber: null,
                        maritalStatus: null,
                        sexStatus: null,
                        countryOfResidence: null,
                      };
                    });

                    // setIsClientType(e.target.value);
                  }}
                >
                  <Radio value={"EXISTING"}>Existing Client</Radio>
                  <Radio value={"NEW"}>New Client</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            {quotationData.clientType === "EXISTING" ? (
              <>
                <Form.Item
                  label="Client"
                  name={"customerId"}
                  style={{ style: "50%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please select client!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select a Client"
                    optionFilterProp="children"
                    onSearch={(e) => {
                      console.log("On search", e);
                    }}
                    filterOption={(input, option) => {
                      console.log("input and option", input, option);
                      return (option?.value ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {store.caseState.caseDropdown?.client?.map((dataObj) => {
                      return (
                        <Option
                          key={dataObj.id}
                          value={`${dataObj?.firstName ?? ""} ${
                            dataObj?.lastName ?? ""
                          } ${
                            dataObj.email !== null && dataObj.email !== ""
                              ? `(${dataObj.email})`
                              : dataObj.mobile !== null && dataObj.mobile !== ""
                              ? `(${dataObj.mobile})`
                              : ""
                          }`}
                        >
                          <div
                            onClick={() => {
                              //   setExistingClientDetails(dataObj);
                              setQuotationData((previousData) => {
                                return {
                                  ...previousData,
                                  customerId: dataObj.id,
                                  customer: `${dataObj?.firstName ?? ""} ${
                                    dataObj?.lastName ?? ""
                                  } ${
                                    dataObj.email !== null &&
                                    dataObj.email !== ""
                                      ? `(${dataObj.email})`
                                      : dataObj.mobile !== null &&
                                        dataObj.mobile !== ""
                                      ? `(${dataObj.mobile})`
                                      : ""
                                  }`,
                                  firstName: dataObj?.firstName,
                                  lastName: dataObj?.lastName,
                                  mobile: dataObj?.mobile,
                                  email: dataObj?.email,
                                  dob: dataObj.dob,
                                  phone: dataObj.phone,
                                  address: dataObj.address,
                                  passportNumber: dataObj.passportNumber,
                                  maritalStatus: dataObj.maritalStatus,
                                  sexStatus: dataObj.sexStatus,
                                  countryOfResidence:
                                    dataObj.countryOfResidence ??
                                    quotationData.countryOfResidence,
                                };
                              });
                            }}
                            className={"selectOptionDiv"}
                          >
                            {`${dataObj?.firstName ?? ""} ${
                              dataObj?.lastName ?? ""
                            } ${
                              dataObj.email !== null && dataObj.email !== ""
                                ? `(${dataObj.email})`
                                : dataObj.mobile !== null &&
                                  dataObj.mobile !== ""
                                ? `(${dataObj.mobile})`
                                : ""
                            }`}
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </>
            ) : null}
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="First Name"
                name={"firstName"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter first name!",
                  },
                ]}
              >
                <Input
                  placeholder="First Name"
                  onChange={(e) => {
                    setQuotationData((previousData) => {
                      return {
                        ...previousData,
                        firstName: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name={"lastName"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter last name!",
                  },
                ]}
              >
                <Input
                  placeholder="Last Name"
                  onChange={(e) => {
                    setQuotationData((previousData) => {
                      return {
                        ...previousData,
                        lastName: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Mobile Number"
                name={"mobile"}
                style={{ width: "100%" }}
                rules={[
                  {
                    pattern: /^[\d]{9,11}$/,
                    message: "Mobile number should contain 9 to 11 numbers",
                  },
                  {
                    validator: async (_, value) => {
                      const token = getCookie("accessToken");
                      const response = await axios.get(
                        `${process.env.REACT_APP_TEST_URL}v1/api/customer/validate-mobile?mobile=${value}`,
                        {
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + token,
                          },
                        }
                      );
                      if (
                        response?.data === false ||
                        value === "" ||
                        value === null ||
                        value === undefined
                      ) {
                        return Promise.resolve();
                      } else if (
                        response?.data === true &&
                        quotationData.clientType === "NEW"
                      ) {
                        return Promise.reject(
                          new Error("Entered mobile number already exists")
                        );
                      }
                    },
                  },
                ]}
                validateTrigger={["onBlur"]}
              >
                <Input
                  type="number"
                  placeholder="Mobile Number"
                  onChange={(e) => {
                    setQuotationData((previousData) => {
                      return {
                        ...previousData,
                        mobile: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Email"
                name={"email"}
                style={{ width: "100%" }}
                rules={[
                  {
                    type: "email",
                    message: "Please enter valid email",
                  },
                  {
                    validator: async (_, value) => {
                      const token = getCookie("accessToken");
                      const response = await axios.get(
                        `${process.env.REACT_APP_TEST_URL}v1/api/customer/validate-email?email=${value}`,
                        {
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + token,
                          },
                        }
                      );
                      console.log("response frm email", response);
                      if (
                        response?.data === false ||
                        value === "" ||
                        value === null ||
                        value === undefined
                      ) {
                        return Promise.resolve();
                      } else if (
                        response?.data === true &&
                        quotationData.clientType === "NEW"
                      ) {
                        return Promise.reject(
                          new Error("Entered email already exists")
                        );
                      }
                    },
                  },
                ]}
                validateTrigger={["onBlur"]}
              >
                <Input
                  type="email"
                  placeholder="E.g. name@domainname.com"
                  onChange={(e) => {
                    setQuotationData((previousData) => {
                      return {
                        ...previousData,
                        email: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Date of Birth"
                name={"dob"}
                style={{ width: "100%" }}
                validateStatus={error ? "error" : undefined}
                help={error}
              >
                <DatePicker
                  format={"DD-MM-YYYY"}
                  disabledDate={(currentDate) => {
                    const date = new Date();
                    return currentDate > date;
                  }}
                  onChange={(e) => {
                    // setDob(e);
                    setQuotationData((previousData) => {
                      return {
                        ...previousData,
                        dob: e,
                      };
                    });
                    if (e) {
                      const age = moment().diff(e, "years");
                      console.log("this is running", e);
                      if (age < 18) {
                        console.log("age is below 18 years old", age);
                        setError("Employee must be above 18 years old.");
                        form.setFields([
                          {
                            name: "dateOfBirth",
                            errors: ["Employee must be at least 18 years old."],
                          },
                        ]);
                      } else {
                        setError("");
                        form.setFields([
                          {
                            name: "dateOfBirth",
                            errors: [],
                          },
                        ]);
                      }
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Phone"
                name={"phone"}
                style={{ width: "100%" }}
                rules={[
                  {
                    pattern: /^[\d]{9,11}$/,
                    message: "Mobile number should contain 9 to 11 numbers",
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder="Phone number"
                  onChange={(e) => {
                    // setPhone(e.target.value);
                    setQuotationData((previousData) => {
                      return {
                        ...previousData,
                        phone: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Address"
                name={"address"}
                style={{ width: "100%" }}
              >
                <Input
                  placeholder="Address"
                  onChange={(e) => {
                    // setAddress(e.target.value);
                    setQuotationData((previousData) => {
                      return {
                        ...previousData,
                        address: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Passport Number"
                name={"passportNumber"}
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please enter passport number!",
                //   },
                // ]}
              >
                <Input
                  type="number"
                  placeholder="passport number"
                  onChange={(e) => {
                    // setPassportNumber(e.target.value);
                    setQuotationData((previousData) => {
                      return {
                        ...previousData,
                        passportNumber: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Marital Status"
                name={"maritalStatus"}
                style={{ width: "100%" }}
              >
                <Select
                  onChange={(e) => {
                    setQuotationData((previousData) => {
                      return {
                        ...previousData,
                        maritalStatus: e,
                      };
                    });
                    // setMaritalStatus(e);
                  }}
                >
                  <Option value="SINGLE">Single</Option>
                  <Option value="MARRIED">Married</Option>
                  <Option value="DIVORCED">Divorced</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Gender"
                name={"sexStatus"}
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please enter gender!",
                //   },
                // ]}
              >
                <Select
                  onChange={(e) => {
                    console.log(e, "male female");
                    // setSexStatus(e);
                    setQuotationData((previousData) => {
                      return {
                        ...previousData,
                        sexStatus: e,
                      };
                    });
                  }}
                >
                  <Option value={"MALE"}>Male</Option>
                  <Option value={"FEMALE"}>Female</Option>
                  <Option value={"OTHERS"}>Others</Option>
                </Select>
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.3rem", width: "100%" }}
              >
                <Form.Item
                  label="Country of Residence"
                  name={"countryOfResidence"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter country of residence!",
                    },
                  ]}
                >
                  <Select>
                    {store.leadState?.leadsDropdown?.countries?.map(
                      (dataObj) => {
                        return (
                          <Option key={dataObj.id} value={dataObj.countryName}>
                            <div
                              onClick={() => {
                                setQuotationData((previousData) => {
                                  return {
                                    ...previousData,
                                    countryOfResidence: dataObj.countryName,
                                  };
                                });
                              }}
                              className={"selectOptionDiv"}
                            >
                              {dataObj.countryName}
                            </div>
                          </Option>
                        );
                      }
                    )}
                  </Select>
                </Form.Item>
                <a
                  className="plusButton"
                  onClick={() => {
                    setIsAddCountryModalVisible(true);
                  }}
                >
                  <AiOutlinePlus className="iconColor" />
                </a>
              </div>
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.3rem", width: "100%" }}
              >
                <Form.Item
                  label="Referral"
                  name={"referral"}
                  style={{ width: "100%" }}
                  //   rules={[
                  //     {
                  //       required: true,
                  //       message: "Please select a referral !",
                  //     },
                  //   ]}
                >
                  <Select>
                    {store.leadState?.leadsDropdown?.references?.map(
                      (dataObj) => {
                        return (
                          <Option key={dataObj.id}>
                            <div
                              onClick={(e) => {
                                setQuotationData((previousData) => {
                                  return {
                                    ...previousData,
                                    referral: dataObj.id,
                                    referralName: `${dataObj.firstName} ${dataObj.lastName}`,
                                  };
                                });
                              }}
                            >
                              {dataObj.firstName} {dataObj.lastName}
                            </div>
                          </Option>
                        );
                      }
                    )}
                  </Select>
                </Form.Item>
                <a
                  className="plusButton"
                  onClick={() => {
                    setIsAddReferralModalVisible(true);
                  }}
                >
                  <AiOutlinePlus className="iconColor" />
                </a>
              </div>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "flex-end", gap: "1rem" }}
            >
              <Form.Item
                name="clientInfoNext"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      console.log("value frm head agent", value);
                      console.log(
                        "is percentage frm head agent",
                        getFieldValue("mobile"),
                        getFieldValue("email")
                      );
                      if (
                        (getFieldValue("email") === undefined ||
                          getFieldValue("email") === "") &&
                        (getFieldValue("mobile") === undefined ||
                          getFieldValue("mobile") === "")
                      ) {
                        return Promise.reject(
                          new Error(
                            "Please enter either email or mobile number!"
                          )
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
                hasFeedback
              >
                <Button name="clientInfoNext" type="primary" htmlType="submit">
                  Next
                </Button>
              </Form.Item>
            </div>
          </Form>

          {isAddCountryModalVisible && (
            <AddCountry
              isAddCountryModalVisible={isAddCountryModalVisible}
              setIsAddCountryModalVisible={setIsAddCountryModalVisible}
            />
          )}
          {isAddReferralModalVisible && (
            <AddReferral
              isAddReferralModalVisible={isAddReferralModalVisible}
              setIsAddReferralModalVisible={setIsAddReferralModalVisible}
            />
          )}
        </div>
        <div
          style={{
            width: "30%",
          }}
        >
          <QuoteSummary current={current} quotationData={quotationData} />
        </div>
      </div>
    </>
  );
};

export default QuotationUserInfo;
