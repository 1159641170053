import { message } from "antd";
import FileSaver, { saveAs } from "file-saver";

const INIT_STATE = {
  isLoading: false,
  timelines: {},
  quotations: {},
  quotationById: {},
  quotationPDFfile: "",
  quotationSources: {},
};

const QuotationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_QUOTATION_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_QUOTATION_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        quotations: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_QUOTATION_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load quotation.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_QUOTATION_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_QUOTATION_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Quotation added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_QUOTATION_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add quotation.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_QUOTATION_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_QUOTATION_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Quotation updated.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_QUOTATION_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to update Quotation.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_QUOTATION_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DELETE_QUOTATION_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Quotation deleted.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_QUOTATION_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to delete Quotation.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_QUOTATION_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "TOGGLE_QUOTATION_STATUS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Quotation status changed.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_QUOTATION_STATUS_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to change Quotation status.");

      message.error("error");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_TIMELINE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_TIMELINE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Timeline added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_TIMELINE_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add timeline.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_TIMELINE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_TIMELINE_REQUEST_SUCCESS": {
      return {
        ...state,
        timelines: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_TIMELINE_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load timelines.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "APPROVE_QUOTATION_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "APPROVE_QUOTATION_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Quotation approved.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "APPROVE_QUOTATION_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to approve quotation.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_QUOTAtION_BY_ID_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_QUOTAtION_BY_ID_REQUEST_SUCCESS": {
      return {
        ...state,
        quotationById: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_QUOTAtION_BY_ID_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to fetch quotation.");

      return {
        ...state,

        isLoading: false,
      };
    }
    case "DOWNLOAD_QUOTATION_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DOWNLOAD_QUOTATION_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Quotation downloaded.");
      return {
        ...state,
        quotationPDFfile: action.payload,
        isLoading: false,
      };
    }
    case "DOWNLOAD_QUOTATION_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to download quotation.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_QUOTATION_SOURCE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_QUOTATION_SOURCE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Quotation source added..");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_QUOTATION_SOURCE_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add quotation source.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_QUOTATION_SOURCE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_QUOTATION_SOURCE_REQUEST_SUCCESS": {
      return {
        ...state,
        quotationSources: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_QUOTATION_SOURCE_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load quotation source.");

      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default QuotationReducer;
