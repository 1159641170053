import { Divider, Tag } from "antd";
import moment from "moment";
import React from "react";

const CaseSummary = ({ visaCaseData, current }) => {
  const caseDetails = (title, value) => {
    return (
      <div>
        <div className="profileTitle">{title}:</div>
        <div>{value}</div>
      </div>
    );
  };
  return (
    <div
      style={{
        borderLeft: "1px solid #f0f2f5",
        display: "flex",
        flexDirection: "column",
        padding: "0.5rem",
      }}
    >
      {current === 0 && (
        <>
          <div>
            <h4>CLIENT INFORMATION:</h4>
          </div>
          <Divider orientationMargin="0" style={{ margin: "0" }} />
          <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
            <div style={{ width: "100%" }}>
              {caseDetails(
                "Start Date",
                visaCaseData.startDate
                  ? moment(visaCaseData.startDate)
                      .utc()
                      .local()
                      .format("DD-MM-YYYY")
                  : moment().utc().local().format("DD-MM-YYYY")
              )}
            </div>
            <div style={{ width: "100%" }}>
              {visaCaseData.salesRepresentativeName !== "" &&
                visaCaseData.salesRepresentativeName !== null &&
                visaCaseData.salesRepresentativeName !== undefined &&
                caseDetails(
                  "Sales Representative",
                  visaCaseData.salesRepresentativeName
                )}
            </div>
          </div>
          <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
            <div style={{ width: "100%" }}>
              {visaCaseData.leadType !== "" &&
                visaCaseData.leadType !== null &&
                visaCaseData.leadType !== undefined &&
                caseDetails("Lead Type", visaCaseData.leadType)}
            </div>
            <div style={{ width: "100%" }}>
              {visaCaseData.commenceDate !== "" &&
                visaCaseData.commenceDate !== null &&
                visaCaseData.commenceDate !== undefined &&
                caseDetails(
                  "Commence Date",
                  moment(visaCaseData.commenceDate)
                    .utc()
                    .local()
                    .format("DD-MM-YYYY")
                )}
            </div>
          </div>
          <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
            <div style={{ width: "100%" }}>
              {visaCaseData.firstName !== "" &&
                visaCaseData.firstName !== null &&
                visaCaseData.firstName !== undefined &&
                visaCaseData.lastName !== "" &&
                visaCaseData.lastName !== null &&
                visaCaseData.lastName !== undefined &&
                caseDetails(
                  "Name",
                  `${visaCaseData.firstName} ${visaCaseData.lastName} ${
                    visaCaseData.clientType === "EXISTING"
                      ? "(Existing)"
                      : visaCaseData.clientType === "NEW"
                      ? "(New)"
                      : null
                  } `
                )}
            </div>
            <div style={{ width: "100%" }}>
              {visaCaseData.mobile !== "" &&
                visaCaseData.mobile !== null &&
                visaCaseData.mobile !== undefined &&
                caseDetails("Mobile Number", visaCaseData.mobile)}
            </div>
          </div>
          {visaCaseData.email !== "" &&
            visaCaseData.email !== null &&
            visaCaseData.email !== undefined &&
            caseDetails("Email", visaCaseData.email)}
          {visaCaseData.dob !== "" &&
            visaCaseData.dob !== null &&
            visaCaseData.dob !== undefined &&
            caseDetails(
              "Date of Birth",
              moment
                .utc(moment.utc(visaCaseData.dob).local())
                .utc()
                .local()
                .format("DD-MM-YYYY")
            )}
          {visaCaseData.phone !== "" &&
            visaCaseData.phone !== null &&
            visaCaseData.phone !== undefined &&
            caseDetails("Phone Number", visaCaseData.phone)}
          {visaCaseData.address !== "" &&
            visaCaseData.address !== null &&
            visaCaseData.address !== undefined &&
            caseDetails("Address", visaCaseData.address)}
          {visaCaseData.passportNumber !== "" &&
            visaCaseData.passportNumber !== null &&
            visaCaseData.passportNumber !== undefined &&
            caseDetails("Passport Number", visaCaseData.passportNumber)}
          {visaCaseData.maritalStatus !== "" &&
            visaCaseData.maritalStatus !== null &&
            visaCaseData.maritalStatus !== undefined &&
            caseDetails("Marital Status", visaCaseData.maritalStatus)}
          {visaCaseData.sexStatus !== "" &&
            visaCaseData.sexStatus !== null &&
            visaCaseData.sexStatus !== undefined &&
            caseDetails("Sex Status", visaCaseData.sexStatus)}
          {visaCaseData.countryOfResidence !== "" &&
            visaCaseData.countryOfResidence !== null &&
            visaCaseData.countryOfResidence !== undefined &&
            caseDetails(
              "Country of Residence",
              visaCaseData.countryOfResidence
            )}
        </>
      )}

      {current === 1 && (
        <>
          <div>
            <h4>VISA INFORMATION:</h4>
          </div>
          <Divider orientationMargin="0" style={{ margin: "0" }} />
          <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
            <div style={{ width: "100%" }}>
              {visaCaseData.visaType !== "" &&
                visaCaseData.visaType !== null &&
                visaCaseData.visaType !== undefined &&
                caseDetails("Visa Type", visaCaseData.visaType)}
            </div>
            <div style={{ width: "100%" }}>
              {visaCaseData.visaSubClass !== "" &&
                visaCaseData.visaSubClass !== null &&
                visaCaseData.visaSubClass !== undefined &&
                caseDetails("Visa Subclass", visaCaseData.visaSubClass)}
            </div>
          </div>
          <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
            <div style={{ width: "100%" }}>
              {visaCaseData.currentStatus !== "" &&
                visaCaseData.currentStatus !== null &&
                visaCaseData.currentStatus !== undefined &&
                caseDetails("Current Status", visaCaseData.currentStatus)}
            </div>
            <div style={{ width: "100%" }}>
              {visaCaseData.country !== "" &&
                visaCaseData.country !== null &&
                visaCaseData.country !== undefined &&
                caseDetails("Country", visaCaseData.country)}
            </div>
          </div>
          <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
            <div style={{ width: "100%" }}>
              {visaCaseData.workFlow !== "" &&
                visaCaseData.workFlow !== null &&
                visaCaseData.workFlow !== undefined &&
                caseDetails("Workflow", visaCaseData.workFlow)}
            </div>
            <div style={{ width: "100%" }}>
              {visaCaseData.workFlowStatus !== "" &&
                visaCaseData.workFlowStatus !== null &&
                visaCaseData.workFlowStatus !== undefined &&
                caseDetails("Workflow Status", visaCaseData.workFlowStatus)}
            </div>
          </div>
          <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
            <div style={{ width: "100%" }}>
              {visaCaseData.instituteName !== "" &&
                visaCaseData.instituteName !== null &&
                visaCaseData.instituteName !== undefined &&
                caseDetails("Institute", visaCaseData.instituteName)}
            </div>
            <div style={{ width: "100%" }}>
              {visaCaseData.degreeName !== "" &&
                visaCaseData.degreeName !== null &&
                visaCaseData.degreeName !== undefined &&
                caseDetails("Degree", visaCaseData.degreeName)}
            </div>
          </div>
          {visaCaseData.courseName !== "" &&
            visaCaseData.courseName !== null &&
            visaCaseData.courseName !== undefined &&
            caseDetails("Course", visaCaseData.courseName)}
          {visaCaseData?.course?.courseDuration !== "" &&
            visaCaseData?.course?.courseDuration !== null &&
            visaCaseData?.course?.courseDuration !== undefined &&
            visaCaseData?.course?.courseAccordingTo !== "" &&
            visaCaseData?.course?.courseAccordingTo !== null &&
            visaCaseData?.course?.courseAccordingTo !== undefined &&
            caseDetails(
              "Course Duration",
              `${visaCaseData?.course?.courseDuration} ${
                visaCaseData?.course?.courseAccordingTo ?? ""
              }`
            )}
          {visaCaseData.Intake !== "" &&
            visaCaseData.Intake !== null &&
            visaCaseData.Intake !== undefined &&
            caseDetails("Intake", visaCaseData.Intake)}
          {visaCaseData.wantToPayFor !== "" &&
            visaCaseData.wantToPayFor !== null &&
            visaCaseData.wantToPayFor !== undefined &&
            caseDetails("Want to pay for", visaCaseData.wantToPayFor)}
          {visaCaseData.wantToPayFee !== "" &&
            visaCaseData.wantToPayFee !== null &&
            visaCaseData.wantToPayFee !== undefined &&
            caseDetails("Want to pay fee", visaCaseData.wantToPayFee)}

          {visaCaseData.currentStatus === "ONSHORE" && (
            <>
              {visaCaseData.course?.onShoreFee !== "" &&
                visaCaseData.course?.onShoreFee !== null &&
                visaCaseData.course?.onShoreFee !== undefined &&
                caseDetails("Onshore Fee", visaCaseData.course?.onShoreFee)}
              {visaCaseData.course?.onshoreApplicationFee !== "" &&
                visaCaseData.course?.onshoreApplicationFee !== null &&
                visaCaseData.course?.onshoreApplicationFee !== undefined &&
                caseDetails(
                  "Onshore Application Fee",
                  visaCaseData.course?.onshoreApplicationFee
                )}
              {visaCaseData.course?.onshoreMaterialFee !== "" &&
                visaCaseData.course?.onshoreMaterialFee !== null &&
                visaCaseData.course?.onshoreMaterialFee !== undefined &&
                caseDetails(
                  "Onshore Material Fee",
                  visaCaseData.course?.onshoreMaterialFee
                )}
              {visaCaseData.course?.onshoreAdditionalFee !== "" &&
                visaCaseData.course?.onshoreAdditionalFee !== null &&
                visaCaseData.course?.onshoreAdditionalFee !== undefined &&
                caseDetails(
                  "Onshore Additional Fee",
                  visaCaseData.course?.onshoreAdditionalFee
                )}
              {visaCaseData.course?.onshoreIsDiscount === true && (
                <>
                  {visaCaseData.course?.onshoreDiscountAmount !== "" &&
                    visaCaseData.course?.onshoreDiscountAmount !== null &&
                    visaCaseData.course?.onshoreDiscountAmount !== undefined &&
                    visaCaseData.course?.onshoreDiscountType !== "" &&
                    visaCaseData.course?.onshoreDiscountType !== null &&
                    visaCaseData.course?.onshoreDiscountType !== undefined &&
                    caseDetails(
                      "Onshore Discount",
                      `${visaCaseData.course?.onshoreDiscountAmount} ${
                        visaCaseData.course?.onshoreDiscountType === "FLAT"
                          ? "FLAT"
                          : visaCaseData.course?.onshoreDiscountType ===
                            "PERCENTAGE"
                          ? "%"
                          : null
                      }`
                    )}
                </>
              )}
              {visaCaseData.course?.onshoreIsScholarship === true && (
                <>
                  {visaCaseData.course?.onshoreScholarshipAmount !== "" &&
                    visaCaseData.course?.onshoreScholarshipAmount !== null &&
                    visaCaseData.course?.onshoreScholarshipAmount !==
                      undefined &&
                    visaCaseData.course?.onshoreScholarshipType !== "" &&
                    visaCaseData.course?.onshoreScholarshipType !== null &&
                    visaCaseData.course?.onshoreScholarshipType !== undefined &&
                    caseDetails(
                      "Onshore Scholarship",
                      `${visaCaseData.course?.onshoreScholarshipAmount} ${
                        visaCaseData.course?.onshoreScholarshipType === "FLAT"
                          ? "FLAT"
                          : visaCaseData.course?.onshoreScholarshipType ===
                            "PERCENTAGE"
                          ? "%"
                          : null
                      }`
                    )}
                </>
              )}
            </>
          )}

          {visaCaseData.currentStatus === "OFFSHORE" && (
            <>
              {visaCaseData.course?.offShoreFee !== "" &&
                visaCaseData.course?.offShoreFee !== null &&
                visaCaseData.course?.offShoreFee !== undefined &&
                caseDetails("Offshore Fee", visaCaseData.course?.offShoreFee)}
              {visaCaseData.course?.offshoreApplicationFee !== "" &&
                visaCaseData.course?.offshoreApplicationFee !== null &&
                visaCaseData.course?.offshoreApplicationFee !== undefined &&
                caseDetails(
                  "Offshore Application Fee",
                  visaCaseData.course?.offshoreApplicationFee
                )}
              {visaCaseData.course?.offshoreMaterialFee !== "" &&
                visaCaseData.course?.offshoreMaterialFee !== null &&
                visaCaseData.course?.offshoreMaterialFee !== undefined &&
                caseDetails(
                  "Offshore Material Fee",
                  visaCaseData.course?.offshoreMaterialFee
                )}
              {visaCaseData.course?.offshoreAdditionalFee !== "" &&
                visaCaseData.course?.offshoreAdditionalFee !== null &&
                visaCaseData.course?.offshoreAdditionalFee !== undefined &&
                caseDetails(
                  "Offshore Additional Fee",
                  visaCaseData.course?.offshoreAdditionalFee
                )}
              {visaCaseData.course?.offshoreIsDiscount === true && (
                <>
                  {visaCaseData.course?.offshoreDiscountAmount !== "" &&
                    visaCaseData.course?.offshoreDiscountAmount !== null &&
                    visaCaseData.course?.offshoreDiscountAmount !== undefined &&
                    visaCaseData.course?.offshoreDiscountType !== "" &&
                    visaCaseData.course?.offshoreDiscountType !== null &&
                    visaCaseData.course?.offshoreDiscountType !== undefined &&
                    caseDetails(
                      "Offshore Discount",
                      `${visaCaseData.course?.offshoreDiscountAmount} ${
                        visaCaseData.course?.offshoreDiscountType === "FLAT"
                          ? "FLAT"
                          : visaCaseData.course?.offshoreDiscountType ===
                            "PERCENTAGE"
                          ? "%"
                          : null
                      }`
                    )}
                </>
              )}
              {visaCaseData.course?.offshoreIsScholarship === true && (
                <>
                  {visaCaseData.course?.offshoreScholarshipAmount !== "" &&
                    visaCaseData.course?.offshoreScholarshipAmount !== null &&
                    visaCaseData.course?.offshoreScholarshipAmount !==
                      undefined &&
                    visaCaseData.course?.offshoreScholarshipType !== "" &&
                    visaCaseData.course?.offshoreScholarshipType !== null &&
                    visaCaseData.course?.offshoreScholarshipType !==
                      undefined &&
                    caseDetails(
                      "Offshore Discount",
                      `${visaCaseData.course?.onshoreScholarshipAmount} ${
                        visaCaseData.course?.offshoreScholarshipType === "FLAT"
                          ? "FLAT"
                          : visaCaseData.course?.offshoreScholarshipType ===
                            "PERCENTAGE"
                          ? "%"
                          : null
                      }`
                    )}
                </>
              )}
            </>
          )}

          {visaCaseData.fee !== "" &&
            visaCaseData.fee !== null &&
            visaCaseData.fee !== undefined &&
            !isNaN(visaCaseData.fee) &&
            caseDetails("Grand Total", visaCaseData.fee ?? 0)}
        </>
      )}
      {current === 2 && (
        <>
          <div>
            <h4>PACKAGE INFORMATION:</h4>
          </div>
          <Divider orientationMargin="0" style={{ margin: "0" }} />
          {visaCaseData.fee !== "" &&
            visaCaseData.fee !== null &&
            visaCaseData.fee !== undefined &&
            caseDetails(
              "Total Fee",
              `${visaCaseData.fee} ${
                visaCaseData.currency !== null &&
                visaCaseData.currency !== undefined &&
                visaCaseData.currency !== ""
                  ? visaCaseData.currency
                  : ""
              } `
            )}
          {visaCaseData.packageTotal !== "" &&
            visaCaseData.packageTotal !== null &&
            visaCaseData.packageTotal !== undefined &&
            caseDetails(
              "Package Total",
              `${visaCaseData.packageTotal} ${
                visaCaseData.currency !== null &&
                visaCaseData.currency !== undefined &&
                visaCaseData.currency !== ""
                  ? visaCaseData.currency
                  : ""
              } `
            )}
          {visaCaseData.packageTotal !== "" &&
            visaCaseData.packageTotal !== null &&
            visaCaseData.packageTotal !== undefined &&
            visaCaseData.fee !== "" &&
            visaCaseData.fee !== null &&
            visaCaseData.fee !== undefined &&
            caseDetails(
              "Grand Total",
              `${visaCaseData.fee + visaCaseData.packageTotal} ${
                visaCaseData.currency !== null &&
                visaCaseData.currency !== undefined &&
                visaCaseData.currency !== ""
                  ? visaCaseData.currency
                  : ""
              } `
            )}
          {visaCaseData.description !== "" &&
            visaCaseData.description !== null &&
            visaCaseData.description !== undefined &&
            caseDetails("Description", visaCaseData.description)}
          {visaCaseData.priorNote !== "" &&
            visaCaseData.priorNote !== null &&
            visaCaseData.priorNote !== undefined &&
            caseDetails("Prior Note", visaCaseData.priorNote)}
          {visaCaseData.deadlineDate !== "" &&
            visaCaseData.deadlineDate !== null &&
            visaCaseData.deadlineDate !== undefined &&
            caseDetails(
              "Deadline",
              moment(visaCaseData.deadlineDate)
                .utc()
                .local()
                .format("DD-MM-YYYY")
            )}
          {visaCaseData?.documentCheckList?.length > 0 &&
            caseDetails(
              "Workflow document checklist",
              visaCaseData?.workflowDocumentCheckList?.map((document) => {
                if (visaCaseData?.documentCheckList?.includes(document.id)) {
                  return (
                    <Tag key={document.id} color="blue">
                      {document?.item}
                    </Tag>
                  );
                }
              })
            )}
          {visaCaseData?.coursesChecklist?.length > 0 &&
          visaCaseData?.currentStatus === "ONSHORE"
            ? caseDetails(
                "Course document checklist",
                visaCaseData?.course?.onShoreCheckList?.map((document) => {
                  if (visaCaseData?.coursesChecklist?.includes(document.id)) {
                    return (
                      <Tag key={document.id} color={"blue"}>
                        {document?.item}
                      </Tag>
                    );
                  }
                })
              )
            : visaCaseData?.coursesChecklist?.length > 0 &&
              visaCaseData?.currentStatus === "OFFSHORE"
            ? caseDetails(
                "Course document checklist",
                visaCaseData?.course?.offShoreCheckList?.map((document) => {
                  if (visaCaseData?.coursesChecklist?.includes(document.id)) {
                    return (
                      <Tag key={document.id} color={"blue"}>
                        {document?.item}
                      </Tag>
                    );
                  }
                })
              )
            : null}
        </>
      )}
    </div>
  );
};

export default CaseSummary;
