import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
const { Option } = Select;

const UpdateDepartment = ({
  record,
  isUpdateDepartmentModalVisible,
  setIsUpdateDepartmentModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const updateDepartmentSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({
      type: "UPDATE_DEPARTMENT_REQUEST",
      payload: { id: record.id, values: formData },
    });
    form.resetFields();
    setIsUpdateDepartmentModalVisible(false);
  };
  console.log("From deparment update module", record);
  return (
    <Modal
      title="Update Department"
      open={isUpdateDepartmentModalVisible}
      onCancel={() => {
        setIsUpdateDepartmentModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={updateDepartmentSubmitHandler}
        colon={true}
        form={form}
        fields={[
          {
            name: ["departmentName"],
            value: record.departmentName,
          },
        ]}
      >
        <Form.Item
          label="Department Name"
          name={"departmentName"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter department name!",
            },
          ]}
        >
          <Input placeholder="Department" />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Update Department
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateDepartment;
