import React, { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Space,
  TimePicker,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  date,
  dateAndTime,
  getTimeZone,
  YYYYMMDD,
} from "./../../HelperFunction";
import { AiOutlinePlus } from "react-icons/ai";
import Purpose from "../Leads/Purpose/Purpose";
import AddCountry from "../Leads/Country/AddCountry";
import AddConsultant from "../Leads/Consultant/AddConsultant";
import AddState from "./AddState";
import moment from "moment";
import AddEmployee from "../EmployeeManager/AddEmployee";
import AddContacts from "../Contacts/AddContacts";
const { Option } = Select;
const AddCounselling = ({
  isAddCounsellingModelVisible,
  setIsAddCounsellingModelVisible,
}) => {
  const store = useSelector((state) => {
    return {
      timingDropdownState: state.AppointmentsReducer,
      addCounsellingState: state.LeadReducer,
    };
  });
  const [isContactType, setIsContactType] = useState(true);

  const [isAddPurposeModalVisible, setIsAddPurposeModalVisible] =
    useState(false);
  const [isAddCountryModalVisible, setIsAddCountryModalVisible] =
    useState(false);
  const [isAddConsultantModalVisible, setIsAddConsultantModalVisible] =
    useState(false);
  const [isAddStateModalVisible, setIsAddStateModalVisible] = useState(false);
  const [isAddContactVisible, setIsAddContactVisible] = useState(false);

  const [record, setRecord] = useState({});
  const [counsellorId, setCounsellorId] = useState();
  const [appointmentDate, setAppointmentDate] = useState();
  const [sessionTime, setSessionTime] = useState();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [type, setType] = useState("");
  useEffect(() => {
    if (isAddCounsellingModelVisible === true) {
      dispatch({ type: "FETCH_LEADS_DROPDOWN_REQUEST" });
    }
  }, [isAddCounsellingModelVisible]);
  useEffect(() => {
    if (counsellorId && appointmentDate && sessionTime) {
      dispatch({
        type: "FETCH_TIMING_DROPDOWN_REQUEST",
        payload: {
          counsellorId,
          sessionTime,
          values: {
            appointmentDate: appointmentDate,
            timeOffset: getTimeZone(),
          },
        },
      });
    }
  }, [counsellorId, appointmentDate, sessionTime]);
  const addCounsellingSubmitHandler = (values) => {
    console.log(values);
    const bookingDate = date(values.bookingDate);
    const bookingDay = moment(values.bookingDate).format("dddd");
    const formData = {
      ...values,
      bookingDate,
      bookingDay: bookingDay.toUpperCase(),
    };
    dispatch({
      type: "ADD_COUNSELLING_REQUEST",
      payload: { values: formData },
    });
    dispatch({ type: "CLEAR_AVAILABLE_DATE_AND_TIME" });

    form.resetFields();
    setIsAddCounsellingModelVisible(false);
  };
  return (
    <Drawer
      title="Add Counselling"
      open={isAddCounsellingModelVisible}
      onClose={() => {
        setIsAddCounsellingModelVisible(false);
      }}
      width={1163}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addCounsellingSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
        fields={[
          {
            name: ["ContactType"],
            value: isContactType,
          },
          {
            name: ["firstName"],
            value: record.firstName,
          },
          {
            name: ["lastName"],
            value: record.lastName,
          },
          {
            name: ["email"],
            value: record.email,
          },
          {
            name: ["mobile"],
            value: record.phoneNumber,
          },
          {
            name: ["startTime"],
            value: "",
          },
        ]}
      >
        <Form.Item
          name={"ContactType"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please select client type",
            },
          ]}
        >
          <Radio.Group
            defaultValue={true}
            onChange={(e) => {
              console.log("from chang contact type", e);
              setIsContactType(e.target.value);
            }}
          >
            <Radio value={true}>Existing Contact</Radio>
            <Radio value={false}>New Contact</Radio>
          </Radio.Group>
        </Form.Item>
        {isContactType ? (
          <div className="flexRowWithoutStyle" style={{ gap: "0.3rem" }}>
            <Form.Item
              label="Contact"
              name={"customer"}
              style={{ width: "100%" }}
            >
              <Select>
                {store.addCounsellingState?.leadsDropdown?.contacts?.map(
                  (dataObj) => {
                    console.log(dataObj);
                    return (
                      <Option key={dataObj.id}>
                        <div
                          onClick={() => {
                            setRecord(dataObj);
                          }}
                        >
                          {dataObj.firstName} {dataObj.lastName}
                        </div>
                      </Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddContactVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
        ) : null}

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="First Name"
            name={"firstName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter first name!",
              },
            ]}
          >
            <Input placeholder="Please enter first name!" />
          </Form.Item>

          <Form.Item
            label="Last Name"
            name={"lastName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter last name!",
              },
            ]}
          >
            <Input placeholder="Please enter last name!" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Email"
            name={"email"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter email!",
              },
            ]}
          >
            <Input type="email" placeholder="Please enter Email!" />
          </Form.Item>
          <Form.Item
            label="Mobile Number"
            name={"mobile"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter mobile number!",
              },
              {
                pattern: /^[\d]{9,11}$/,
                message: "Mobile number should contain 9 to 11 numbers",
              },
            ]}
          >
            <Input placeholder="Mobile Number!" />
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <div
            className="flexRowWithoutStyle"
            style={{ gap: "0.3rem", width: "100%" }}
          >
            <Form.Item
              label="Purpose"
              name={"purpose"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select your purpose!",
                },
              ]}
            >
              <Select>
                {store.addCounsellingState.leadsDropdown?.purposes?.map(
                  (dataObj) => {
                    return (
                      <Option key={dataObj.id}>{dataObj.purposeName}</Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddPurposeModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>

          <Form.Item
            label="Counsellor Type"
            name={"counsellingType"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter source!",
              },
            ]}
          >
            <Radio.Group
              onChange={(e) => {
                setType(e.target.value);
              }}
            >
              <Radio value={"ONSHORE"}>Onshore</Radio>
              <Radio value={"OFFSHORE"}>Offshore</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          {type === "ONSHORE" ? (
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.3rem", width: "100%" }}
            >
              <Form.Item
                label="Where are you located at the moment?"
                name={"country"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter country!",
                  },
                ]}
              >
                <Select>
                  {store.addCounsellingState.leadsDropdown?.countries?.map(
                    (dataObj) => {
                      return (
                        <Option key={dataObj.id}>{dataObj.countryName}</Option>
                      );
                    }
                  )}
                </Select>
              </Form.Item>
              <a
                className="plusButton"
                onClick={() => {
                  setIsAddCountryModalVisible(true);
                }}
              >
                <AiOutlinePlus className="iconColor" />
              </a>
            </div>
          ) : (
            <>
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.3rem", width: "100%" }}
              >
                <Form.Item
                  label="Select your interested country"
                  name={"country"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter country!",
                    },
                  ]}
                >
                  <Select>
                    {store.addCounsellingState.leadsDropdown?.countries?.map(
                      (dataObj) => {
                        return (
                          <Option key={dataObj.id}>
                            {dataObj.countryName}
                          </Option>
                        );
                      }
                    )}
                  </Select>
                </Form.Item>
                <a
                  className="plusButton"
                  onClick={() => {
                    setIsAddCountryModalVisible(true);
                  }}
                >
                  <AiOutlinePlus className="iconColor" />
                </a>
              </div>
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.3rem", width: "100%" }}
              >
                <Form.Item
                  label="State"
                  name={"state"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter first name!",
                    },
                  ]}
                >
                  <Select>
                    {store.addCounsellingState.leadsDropdown?.state?.map(
                      (dataObj) => {
                        return (
                          <Option key={dataObj.id}>{dataObj.stateName}</Option>
                        );
                      }
                    )}
                  </Select>
                </Form.Item>
                <a
                  className="plusButton"
                  onClick={() => {
                    setIsAddStateModalVisible(true);
                  }}
                >
                  <AiOutlinePlus className="iconColor" />
                </a>
              </div>
            </>
          )}
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <div
            className="flexRowWithoutStyle"
            style={{ gap: "0.3rem", width: "100%" }}
          >
            <Form.Item
              label="Counsellor"
              name={"counsellor"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select counsellor!",
                },
              ]}
            >
              <Select
                onChange={(e) => {
                  setCounsellorId(e);
                }}
              >
                {store.addCounsellingState?.leadsDropdown?.consultantList?.map(
                  (dataObj) => {
                    return (
                      <Option key={dataObj.id}>
                        {dataObj.firstName} {dataObj.lastName}
                      </Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddConsultantModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
          <Form.Item
            label="Booking Date"
            name={"bookingDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a booking date!",
              },
            ]}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              disabledDate={(cd) => {
                const d = new Date();
                return cd < d;
              }}
              onChange={(e) => {
                setAppointmentDate(YYYYMMDD(e));
              }}
            />
          </Form.Item>
        </div>
        <Form.Item
          label="Session Time"
          name={"sessionTime"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please select a session time!",
            },
          ]}
        >
          <Select
            onChange={(e) => {
              console.log(e);
              setSessionTime(e);
            }}
          >
            <Option value={"THIRTY_MINUTES"}>30 Minutes</Option>
            <Option value={"ONE_HOUR"}>1 Hour</Option>
            <Option value={"TWO_HOUR"}>2 Hour</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Available  Time Slots"
          name={"startTime"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please pick a your time slot!",
            },
          ]}
        >
          <Radio.Group
            onChange={(e) => {
              console.log("selected start time: ", e.target.value);
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {store.timingDropdownState &&
              store.timingDropdownState.timingDropdown.map((dataObj, index) => {
                return (
                  <div key={index}>
                    <Radio value={dataObj.startTime}>
                      {dataObj.startTime} - {dataObj.endTime}
                    </Radio>
                  </div>
                );
              })}
          </Radio.Group>
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Counselling
          </Button>
        </Form.Item>
      </Form>
      <Purpose
        isAddPurposeModalVisible={isAddPurposeModalVisible}
        setIsAddPurposeModalVisible={setIsAddPurposeModalVisible}
      />
      <AddCountry
        isAddCountryModalVisible={isAddCountryModalVisible}
        setIsAddCountryModalVisible={setIsAddCountryModalVisible}
      />
      <AddEmployee
        isAddEmployeeModalVisible={isAddConsultantModalVisible}
        setIsAddEmployeeModalVisible={setIsAddConsultantModalVisible}
      />
      <AddState
        isAddStateModalVisible={isAddStateModalVisible}
        setIsAddStateModalVisible={setIsAddStateModalVisible}
      />
      <AddContacts
        isAddContactVisible={isAddContactVisible}
        setIsAddContactVisible={setIsAddContactVisible}
      />
    </Drawer>
  );
};

export default AddCounselling;
