import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
  Timeline,
} from "antd";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { formattedDateTime } from "../../HelperFunction";
import { ClockCircleOutlined } from "@ant-design/icons";
const { Option } = Select;
const { Meta } = Card;

const CaseLog = ({
  record,
  isCaseLogModalVisible,
  setIsCaseLogModalVisible,
}) => {
  const caselogState = useSelector((state) => state.VisasReducer);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  useEffect(() => {
    dispatch({
      type: "FETCH_CASE_LOG_BY_WORKFLOWDTO_ID_REQUEST",
      payload: { id: record?.id },
    });
  }, [isCaseLogModalVisible]);
  console.log("record from caselog", record);
  const timelineItems = caselogState.caseDetailsByWorflowDtoId?.data?.map(
    (dataObj) => {
      return {
        key: dataObj.id,

        title: dataObj?.currentWorkFlowStatus,
        name: dataObj?.addedBy?.firstName + " " + dataObj?.addedBy?.lastName,
        date: dataObj?.logDateTime
          ? moment
              .utc(dataObj?.logDateTime)
              .local()
              .format("D MMMM YYYY, h:mm A")
          : null,
        adminNote: dataObj?.adminNote,
        customerNote: dataObj?.customerNote,
      };
    }
  );
  return (
    // <Drawer
    //   title="Case Log"
    //   open={isCaseLogModalVisible}
    //   onClose={() => {
    //     setIsCaseLogModalVisible(false);
    //   }}
    //   footer={null}
    //   width={1163}
    // >
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "1rem",
        justifyContent: "flex-start",
        flexWrap: "wrap",
      }}
    >
      <Timeline>
        {timelineItems?.map((item) => (
          <Timeline.Item key={item.key}>
            <div className="flexColumnwithoutStyle">
              <div style={{ fontWeight: "200" }}>{item.date}</div>
              <div style={{ fontWeight: "bold" }}>{item.title}</div>
              {item.adminNote !== null &&
                item.adminNote !== "" &&
                item.adminNote !== undefined && (
                  <div>Admin Note: {item.adminNote}</div>
                )}
              {item.customerNote !== null &&
                item.customerNote !== "" &&
                item.customerNote !== undefined && (
                  <div>Customer Note: {item.customerNote}</div>
                )}
            </div>
          </Timeline.Item>
        ))}
      </Timeline>
    </div>
    // </Drawer>
  );
};

export default CaseLog;

{
  /* {caselogState.caseDetailsByWorflowDtoId?.data?.map((dataObj) => {
        return (
          
          
        );
      })} */
}
{
  /* <Card
            key={dataObj.id}
            style={{ width: "30%" }}
            title={dataObj.currentWorkFlowStatus}
            extra={
              <>
                <div style={{ fontWeight: "600" }}>
                  {dataObj?.addedBy?.firstName +
                    " " +
                    dataObj?.addedBy?.lastName}
                </div>
                <div style={{ color: "#aaa" }}>
                  {formattedDateTime(dataObj?.logDateTime)}{" "}
                </div>
              </>
            }
          >
            <div>
              <h3>Admin Note:</h3>
              <div>{dataObj.adminNote}</div>
            </div>
            <div>
              <h3>Customer Note:</h3>
              <div>{dataObj.customerNote}</div>
            </div>
          </Card> */
}
