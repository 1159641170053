import { Button, Card, Checkbox, Form } from "antd";
import React from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import OrganizationalSettings from "../OrganizationalSettings";
const CheckboxGroup = Checkbox.Group;

const AutoreminderSettings = () => {
  const store = useSelector((state) => {
    return {
      dashboardState: state.DashboardReducer,
      autoreminderState: state.OrganizationSettingsReducer,
    };
  });
  const beforeArr = [
    "ONE_DAY",
    "TWO_DAY",
    "THREE_DAY",
    "SEVEN_DAY",
    "FOURTEEN_DAY",
    "ONE_MONTH",
    "TWO_MONTH",
    "THREE_MONTH",
  ];
  const afterArr = [
    "ONE_DAY",
    "TWO_DAY",
    "THREE_DAY",
    "SEVEN_DAY",
    "FOURTEEN_DAY",
    "ONE_MONTH",
    "TWO_MONTH",
    "EVERY_DAY",
  ];
  const [autoReminderData, setAutoReminderData] = useState({
    VISA_EXPIRY_BEFORE: {
      checkedDurationList: [],
      unCheckedDurationList: [],
    },
    VISA_EXPIRY_AFTER: {
      checkedDurationList: [],
      unCheckedDurationList: [],
    },
    PASSPORT_EXPIRY_BEFORE: {
      checkedDurationList: [],
      unCheckedDurationList: [],
    },
    PASSPORT_EXPIRY_AFTER: {
      checkedDurationList: [],
      unCheckedDurationList: [],
    },
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    // dispatch({ type: "FETCH_DASHBOARD_DATA_REQUEST" });
    const clientId = localStorage.getItem("clientId");
    console.log("clientId", clientId);
    dispatch({
      type: "FETCH_AUTOREMINDER_DATA_REQUEST",
      isSuccessFn: true,
      payload: clientId,
      successFunction: successFunction,
    });
  }, []);

  const successFunction = (response) => {
    response?.data?.map((dataObj) => {
      if (dataObj.identifyingParam === "VISA_EXPIRY_BEFORE") {
        setAutoReminderData((previousData) => {
          return {
            ...previousData,
            VISA_EXPIRY_BEFORE: {
              checkedDurationList: [...dataObj.checkedDurationList],
            },
          };
        });
      } else if (dataObj.identifyingParam === "PASSPORT_EXPIRY_BEFORE") {
        setAutoReminderData((previousData) => {
          return {
            ...previousData,
            PASSPORT_EXPIRY_BEFORE: {
              checkedDurationList: [...dataObj.checkedDurationList],
            },
          };
        });
      } else if (dataObj.identifyingParam === "VISA_EXPIRY_AFTER") {
        setAutoReminderData((previousData) => {
          return {
            ...previousData,
            VISA_EXPIRY_AFTER: {
              checkedDurationList: [...dataObj.checkedDurationList],
            },
          };
        });
      } else if (dataObj.identifyingParam === "PASSPORT_EXPIRY_AFTER") {
        setAutoReminderData((previousData) => {
          return {
            ...previousData,
            PASSPORT_EXPIRY_AFTER: {
              checkedDurationList: [...dataObj.checkedDurationList],
            },
          };
        });
      }
    });
  };

  const handleDashboardSettings = () => {
    const formData = [
      {
        identifyingParam: "VISA_EXPIRY_BEFORE",
        checkedDurationList: [
          ...autoReminderData?.VISA_EXPIRY_BEFORE?.checkedDurationList,
        ],
        unCheckedDurationList: beforeArr.filter(
          (data) =>
            !autoReminderData?.VISA_EXPIRY_BEFORE?.checkedDurationList.includes(
              data
            )
        ),
      },
      {
        identifyingParam: "VISA_EXPIRY_AFTER",
        checkedDurationList: [
          ...autoReminderData?.VISA_EXPIRY_AFTER?.checkedDurationList,
        ],
        unCheckedDurationList: afterArr.filter(
          (data) =>
            !autoReminderData?.VISA_EXPIRY_AFTER?.checkedDurationList.includes(
              data
            )
        ),
      },
      {
        identifyingParam: "PASSPORT_EXPIRY_BEFORE",
        checkedDurationList: [
          ...autoReminderData?.PASSPORT_EXPIRY_BEFORE?.checkedDurationList,
        ],
        unCheckedDurationList: beforeArr.filter(
          (data) =>
            !autoReminderData?.PASSPORT_EXPIRY_BEFORE?.checkedDurationList.includes(
              data
            )
        ),
      },
      {
        identifyingParam: "PASSPORT_EXPIRY_AFTER",
        checkedDurationList: [
          ...autoReminderData?.PASSPORT_EXPIRY_AFTER?.checkedDurationList,
        ],
        unCheckedDurationList: afterArr.filter(
          (data) =>
            !autoReminderData?.PASSPORT_EXPIRY_AFTER?.checkedDurationList.includes(
              data
            )
        ),
      },
    ];

    dispatch({
      type: "UPDATE_AUTOREMINDER_SETTINGS_REQUEST",
      payload: { reminderBulkRequest: formData },
    });
  };

  return (
    <OrganizationalSettings>
      <div>
        <h3>Autoreminder Settings</h3>

        <Card
          bordered={false}
          //   tabList={clientDetailsTabList}
          //   activeTabKey={current}
          //   onTabChange={(key) => {
          //     setCurrent(key);
          //   }}
          className={"flexColumnwithoutStyle"}
          style={{ gap: "0.5rem" }}
        >
          <div style={{ marginBottom: "1rem" }}>
            <h3>Passport expiry:</h3>
            <div>Before</div>

            <Form
              form={form}
              fields={[
                {
                  name: ["PASSPORT_EXPIRY_BEFORE"],
                  value:
                    autoReminderData.PASSPORT_EXPIRY_BEFORE.checkedDurationList,
                },
              ]}
            >
              <div className="flexRowWithoutStyle">
                <Checkbox.Group
                  name="PASSPORT_EXPIRY_BEFORE"
                  value={
                    autoReminderData.PASSPORT_EXPIRY_BEFORE.checkedDurationList
                  }
                  onChange={(e) => {
                    console.log("target", e);
                    setAutoReminderData((previousData) => {
                      return {
                        ...previousData,
                        PASSPORT_EXPIRY_BEFORE: {
                          checkedDurationList: [...e],
                        },
                      };
                    });
                  }}
                >
                  <Checkbox value={"ONE_DAY"}>1 Day</Checkbox>

                  <Checkbox value={"TWO_DAY"}>2 Days</Checkbox>

                  <Checkbox value={"THREE_DAY"}>3 Days</Checkbox>

                  <Checkbox value={"SEVEN_DAY"}>7 Days</Checkbox>

                  <Checkbox value={"FOURTEEN_DAY"}>14 days</Checkbox>
                  <Checkbox value={"ONE_MONTH"}>1 Month</Checkbox>

                  <Checkbox value={"TWO_MONTH"}>2 Months</Checkbox>

                  <Checkbox value={"THREE_MONTH"}>3 Months</Checkbox>
                </Checkbox.Group>
              </div>
            </Form>
            <div>After</div>

            <Form
              form={form}
              fields={[
                {
                  name: ["PASSPORT_EXPIRY_AFTER"],
                  value:
                    autoReminderData.PASSPORT_EXPIRY_AFTER.checkedDurationList,
                },
              ]}
            >
              <div className="flexRowWithoutStyle">
                <Checkbox.Group
                  name="PASSPORT_EXPIRY_AFTER"
                  value={
                    autoReminderData.PASSPORT_EXPIRY_AFTER.checkedDurationList
                  }
                  onChange={(e) => {
                    console.log("target", e);
                    setAutoReminderData((previousData) => {
                      return {
                        ...previousData,
                        PASSPORT_EXPIRY_AFTER: {
                          checkedDurationList: [...e],
                        },
                      };
                    });
                  }}
                >
                  <Checkbox value={"ONE_DAY"}>1 Day</Checkbox>

                  <Checkbox value={"TWO_DAY"}>2 Days</Checkbox>

                  <Checkbox value={"THREE_DAY"}>3 Days</Checkbox>

                  <Checkbox value={"SEVEN_DAY"}>7 Days</Checkbox>

                  <Checkbox value={"FOURTEEN_DAY"}>14 days</Checkbox>
                  <Checkbox value={"ONE_MONTH"}>1 Month</Checkbox>

                  <Checkbox value={"TWO_MONTH"}>2 Months</Checkbox>

                  <Checkbox value={"EVERY_DAY"}>Everyday</Checkbox>
                </Checkbox.Group>
              </div>
            </Form>
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <h3>Visa expiry:</h3>
            <div>Before</div>

            <Form
              form={form}
              fields={[
                {
                  name: ["PASSPORT_EXPIRY_BEFORE"],
                  value:
                    autoReminderData.PASSPORT_EXPIRY_BEFORE.checkedDurationList,
                },
              ]}
            >
              <div className="flexRowWithoutStyle">
                <Checkbox.Group
                  name="VISA_EXPIRY_BEFORE"
                  value={
                    autoReminderData.VISA_EXPIRY_BEFORE.checkedDurationList
                  }
                  onChange={(e) => {
                    console.log("target", e);
                    setAutoReminderData((previousData) => {
                      return {
                        ...previousData,
                        VISA_EXPIRY_BEFORE: {
                          checkedDurationList: [...e],
                        },
                      };
                    });
                  }}
                >
                  <Checkbox value={"ONE_DAY"}>1 Day</Checkbox>

                  <Checkbox value={"TWO_DAY"}>2 Days</Checkbox>

                  <Checkbox value={"THREE_DAY"}>3 Days</Checkbox>

                  <Checkbox value={"SEVEN_DAY"}>7 Days</Checkbox>

                  <Checkbox value={"FOURTEEN_DAY"}>14 days</Checkbox>
                  <Checkbox value={"ONE_MONTH"}>1 Month</Checkbox>

                  <Checkbox value={"TWO_MONTH"}>2 Months</Checkbox>

                  <Checkbox value={"THREE_MONTH"}>3 Months</Checkbox>
                </Checkbox.Group>
              </div>
            </Form>
            <div>After</div>

            <Form
              form={form}
              fields={[
                {
                  name: ["VISA_EXPIRY_AFTER"],
                  value: autoReminderData.VISA_EXPIRY_AFTER.checkedDurationList,
                },
              ]}
            >
              <div className="flexRowWithoutStyle">
                <Checkbox.Group
                  value={autoReminderData.VISA_EXPIRY_AFTER.checkedDurationList}
                  name="VISA_EXPIRY_AFTER"
                  onChange={(e) => {
                    console.log("target", e);
                    setAutoReminderData((previousData) => {
                      return {
                        ...previousData,
                        VISA_EXPIRY_AFTER: {
                          checkedDurationList: [...e],
                        },
                      };
                    });
                  }}
                >
                  <Checkbox value={"ONE_DAY"}>1 Day</Checkbox>

                  <Checkbox value={"TWO_DAY"}>2 Days</Checkbox>

                  <Checkbox value={"THREE_DAY"}>3 Days</Checkbox>

                  <Checkbox value={"SEVEN_DAY"}>7 Days</Checkbox>

                  <Checkbox value={"FOURTEEN_DAY"}>14 days</Checkbox>
                  <Checkbox value={"ONE_MONTH"}>1 Month</Checkbox>

                  <Checkbox value={"TWO_MONTH"}>2 Months</Checkbox>

                  <Checkbox value={"EVERY_DAY"}>Everyday</Checkbox>
                </Checkbox.Group>
              </div>
            </Form>
          </div>

          {/* <div>
            <h3>Latest:</h3>
            <CheckboxGroup
              options={latestOptions}
              value={latestOptionsList}
              onChange={(list) => {
                setLatestOptionsList(list);
                // if (latestOptionsList?.length <= 4) {
                //   setLatestOptionsList(list);
                // } else {
                //   setLatestOptionsList(latestOptionsList);
                // }
                console.log("Checked dashboard Latest components", list);
              }}
              style={{ margin: "1rem" }}
            />
          </div> */}
          <Form.Item
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              onClick={handleDashboardSettings}
            >
              Save Changes
            </Button>
          </Form.Item>
        </Card>
      </div>
    </OrganizationalSettings>
  );
};

export default AutoreminderSettings;
