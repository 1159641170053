import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { date, date00Time, dateAndTime } from "../../HelperFunction";
const { Option } = Select;
const { TextArea } = Input;

const AddTask = ({ isAddTaskModalVisible, setIsAddTaskModalVisible }) => {
  const AddTaskState = useSelector((state) => state.VisasReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isAddTaskModalVisible === true) {
      dispatch({ type: "FETCH_CASES_DROPDOWN_REQUEST" });
    }
  }, [isAddTaskModalVisible]);
  const [form] = Form.useForm();
  const [taskData, setTaskData] = useState({
    forCase: false,
    caseId: null,
  });
  const addTaskSubmitHandler = (value) => {
    console.log("from ", value);
    const startDate = date00Time(value.startDate);
    const dueDate = date00Time(value.dueDate);
    const formData = {
      startDate,
      dueDate,
      ...value,
    };
    dispatch({ type: "ADD_TASK_REQUEST", payload: formData });
    form.resetFields();
    setIsAddTaskModalVisible(false);
  };
  return (
    <Drawer
      title="Add Task"
      open={isAddTaskModalVisible}
      onClose={() => {
        setIsAddTaskModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Form
        layout="vertical"
        onFinish={addTaskSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
        initialValues={{ startDate: moment() }}
      >
        {/* <Form.Item
          label={"For Case"}
          name={"ContactType"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please select client type",
            },
          ]}
        >
          <Radio.Group
            value={taskData.forCase}
            onChange={(e) => {
              console.log("from chang contact type", e);
              setTaskData((previouData) => {
                return {
                  ...previouData,
                  forCase: e.target.value,
                };
              });
              if (e.target.value === false) {
              }
            }}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        {taskData.forCase && (
          <Form.Item
            label="Select Contact"
            name={"contact"}
            style={{ width: "95%" }}
            rules={[
              {
                required: true,
                message: "Please enter contact!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a Case"
              optionFilterProp="children"
              onSearch={(e) => {
                console.log("On search case", e);
              }}
              filterOption={(input, option) => {
                console.log("input and option", input, option);
                return (option?.children?.props?.children ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              value={taskData.caseId}
            >
              {apiXaina?.map((dataObj) => {
                return (
                  <Option key={dataObj.id} value={dataObj.id}>
                    <div
                      onClick={() => {
                        setTaskData((previouData) => {
                          return {
                            ...previouData,
                            caseId: dataObj.id,
                          };
                        });
                      }}
                      className={"selectOptionDiv"}
                    >
                      {dataObj.id}
                    </div>
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        )} */}

        <Form.Item
          label="Task Title"
          name={"taskTitle"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter task!",
            },
          ]}
        >
          <Input placeholder="Task Title" />
        </Form.Item>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Start Date"
            name={"startDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick start date!",
              },
            ]}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              disabledDate={(cd) => {
                const d = new Date();
                return cd < d;
              }}
            />
          </Form.Item>
          <Form.Item
            label="Due Date"
            name={"dueDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a due date",
              },
            ]}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              disabledDate={(cd) => {
                const d = new Date();
                return cd < d;
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          {/* <Form.Item
            label="Assign Task By"
            name={"assignedBy"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter",
              },
            ]}
          >
            <Select>
              {AddTaskState.leadsDropdown?.consultantList?.map((dataObj) => {
                return <Option key={dataObj.id}>{dataObj.emailId}</Option>;
              })}
            </Select>
          </Form.Item> */}
          <Form.Item
            label="Assign Task To"
            name={"assignedTo"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter assignedTo!",
              },
            ]}
          >
            <Select>
              {AddTaskState.caseDropdown?.consultancyEmployee?.map(
                (dataObj) => {
                  return <Option key={dataObj.id}>{dataObj.emailId}</Option>;
                }
              )}
            </Select>
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Task Status"
            name={"taskStatus"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a taskStatus!",
              },
            ]}
          >
            <Select>
              <Option value={"PENDING"}>Pending</Option>
              <Option value={"ASSIGNED"}>Assigned</Option>
              <Option value={"COMPLETED"}>Completed</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Priority"
            name={"priority"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter priority!",
              },
            ]}
          >
            <Select>
              <Option value={"NORMAL"}>Normal</Option>
              <Option value={"HIGH"}>High</Option>
              <Option value={"URGENT"}>Urgent</Option>
            </Select>
          </Form.Item>
        </div>

        <Form.Item
          label="Task Description"
          name={"taskDescription"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter !",
            },
          ]}
        >
          <TextArea placeholder="Write about task" />
        </Form.Item>
        <Form.Item
          label="Update Note"
          name={"updateNote"}
          style={{ width: "100%" }}
          // rules={[
          //   {
          //     required: true,
          //     message: "Please enter updateNote!",
          //   },
          // ]}
        >
          <TextArea placeholder="Write recent update" />
        </Form.Item>
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Task
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddTask;
