import React, { useEffect, useState } from "react";
import {
  Avatar,
  Form,
  Input,
  Pagination,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tooltip,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../Components/Layout/SiderDemo";
import company from "./../../Assets/Company.png";
import activeCompany from "./../../Assets/activeCompanies2.png";
import AddCompanies from "./AddCompanies";
import UpdateCompanies from "./UpdateCompanies";
import { MdOutlineDisabledVisible } from "react-icons/md";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
import { AiOutlineEye, AiOutlinePlus, AiOutlinePoweroff } from "react-icons/ai";
import { BiBlock } from "react-icons/bi";
import AddCasePackage from "../Visas/AddCasePackage";
import { BsCalendar2Plus, BsCalendar2Pluss } from "react-icons/bs";
import CompanyDetails from "./CompanyDetails";
const { Search } = Input;

const columns = [
  {
    title: "Company Name",
    dataIndex: "companyName",
    sorter: (a, b) => {
      console.log(
        "form contact sorter a",
        a?.fullname?.props?.children?.props?.children?.props?.children
      );
      console.log("form contact sorter b", b);
      if (
        a?.fullname?.props?.children?.props?.children?.props?.children <
        b?.fullname?.props?.children?.props?.children?.props?.children
      ) {
        return -1;
      }
      if (
        a?.fullname?.props?.children?.props?.children?.props?.children >
        b?.fullname?.props?.children?.props?.children?.props?.children
      ) {
        return 1;
      }
      return 0;
    },
    width: 250,
    align: "center",
  },
  {
    title: "Full Name",
    dataIndex: "fullName",
    width: 250,
    align: "center",
  },
  {
    title: "Email",
    dataIndex: "email",
    width: 100,
    align: "center",
  },
  {
    title: "Phone Number",
    dataIndex: "phoneNumber",
    width: 200,
    align: "center",
  },
  {
    title: "Address",
    dataIndex: "address",
    width: 450,
    align: "center",
  },
  {
    title: "Staff",
    dataIndex: "staff",
    width: 100,
    align: "center",
  },
  {
    title: "ABN",
    dataIndex: "abn",
    width: 100,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 100,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 350,
    align: "center",
  },
];

const Companies = () => {
  const companiesState = useSelector((state) => state.CompaniesReducer);
  const [searchValue, setSearchValue] = useState("");

  const [isAddCompaniesModalVisible, setIsAddCompaniesModalVisible] =
    useState(false);
  const [companiesType, setCompaniesType] = useState("companygetAll");
  const [isUpdateCompaniesModelVisible, setIsUpdateCompaniesModelVisible] =
    useState(false);
  const [isAddPackageModalVisible, setIsAddPackageModalVisible] =
    useState(false);
  const [isCompanyDetalisModalVisible, setIsCompanyDetalisModalVisible] =
    useState(false);
  const [record, setRecord] = useState({});
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };
  const onSearch = (e) => {
    console.log("This is e from search", e);
    if (companiesType === "active") {
      dispatch({
        type: "SEARCH_ACTIVE_COMPANIES_REQUEST",
        payload: {
          page: page,
          pageSize: pageSize,
          searchKey: { keyword: e },
        },
      });
    } else if (companiesType === "companygetAll") {
      dispatch({
        type: "SEARCH_COMPANIES_REQUEST",
        payload: {
          page: page,
          pageSize: pageSize,
          searchKey: { keyword: e },
        },
      });
    }
  };
  useEffect(() => {
    dispatch({ type: "FETCH_COMPANIES_REQUEST" });
  }, []);
  useEffect(() => {
    if (companiesType === "active") {
      dispatch({
        type: "FETCH_ACTIVE_COMPANIES_REQUEST",
        payload: { page, pageSize },
      });
    } else {
      dispatch({
        type: "FETCH_COMPANIES_REQUEST",
        payload: { page, pageSize },
      });
    }
  }, [companiesType, page, pageSize]);
  let data = [];
  if (companiesType === "companygetAll") {
    console.log("from companies all data", companiesState);
    data = companiesState.companies?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        companyName: dataObj.companyName ? (
          <Tooltip title="Company Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  setIsCompanyDetalisModalVisible(true);
                }}
              >
                {dataObj.companyName}
              </div>
            </a>
          </Tooltip>
        ) : (
          "N/A"
        ),
        fullName: dataObj.fullName,
        email: dataObj.email,
        phoneNumber: dataObj.phoneNumber,
        address: dataObj.address,
        staff: dataObj.staff,
        abn: dataObj.abn,
        status:
          dataObj.status === "ACTIVE" ? (
            <p className="greenTag">{dataObj.status}</p>
          ) : (
            <p className="redTag">{dataObj.status}</p>
          ),
        actions: (
          <Space size="middle">
            {dataObj.status === "ACTIVE" && (
              <Tooltip title="Add Package">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsAddPackageModalVisible(true);
                    }}
                  >
                    <BsCalendar2Plus />
                  </div>
                </a>
              </Tooltip>
            )}
            <Tooltip title="Update Company">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsUpdateCompaniesModelVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <EditOutlined />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Delete Contacts">
              <Popconfirm
                title="Are you sure to delete this company?"
                onConfirm={() => {
                  dispatch({
                    type: "DELETE_COMPANY_REQUEST",
                    payload: { id: dataObj.id },
                  });
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <div className="bordered">
                    <DeleteOutlined />
                  </div>
                </a>{" "}
              </Popconfirm>
            </Tooltip>
            <Tooltip title="Company Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setRecord(dataObj);
                    setIsCompanyDetalisModalVisible(true);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
            {dataObj.status === "INACTIVE" && (
              <Tooltip title="Activate Company">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus =
                        dataObj.status === "INACTIVE" ? "active" : "inactive";
                      dispatch({
                        type: "TOGGLE_COMPANY_STATUS_REQUEST",
                        payload: { id: dataObj.id, actionStatus },
                      });
                    }}
                  >
                    <AiOutlinePoweroff />
                  </div>
                </a>
              </Tooltip>
            )}
            {dataObj.status === "ACTIVE" && (
              <Tooltip title="Deactivate Company">
                <a style={{ color: "red" }}>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus =
                        dataObj.status === "INACTIVE" ? "active" : "inactive";
                      dispatch({
                        type: "TOGGLE_COMPANY_STATUS_REQUEST",
                        payload: { id: dataObj.id, actionStatus },
                      });
                    }}
                  >
                    <BiBlock />
                  </div>
                </a>
              </Tooltip>
            )}
          </Space>
        ),
      };
    });
  } else if (companiesType === "active") {
    console.log("active", companiesState.activeContacts);
    data = companiesState.activeCompanies?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        companyName: dataObj.companyName ? (
          <Tooltip title="Company Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  setIsCompanyDetalisModalVisible(true);
                }}
              >
                {dataObj.companyName}
              </div>
            </a>
          </Tooltip>
        ) : (
          "N/A"
        ),
        fullName: dataObj.fullName,
        email: dataObj.email,
        phoneNumber: dataObj.phoneNumber,
        address: dataObj.address,
        staff: dataObj.staff,
        abn: dataObj.abn,
        status:
          dataObj.status === "ACTIVE" ? (
            <p className="greenTag">{dataObj.status}</p>
          ) : (
            <p className="redTag">{dataObj.status}</p>
          ),
        actions: (
          <Space size="middle">
            {dataObj.status === "ACTIVE" && (
              <Tooltip title="Add Package">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsAddPackageModalVisible(true);
                    }}
                  >
                    <BsCalendar2Plus />
                  </div>
                </a>
              </Tooltip>
            )}
            <Tooltip title="Update Company">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setRecord(dataObj);
                    setIsUpdateCompaniesModelVisible(true);
                  }}
                >
                  <EditOutlined />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Delete Contacts">
              <Popconfirm
                title="Are you sure to delete this company?"
                onConfirm={() => {
                  dispatch({
                    type: "DELETE_COMPANY_REQUEST",
                    payload: { id: dataObj.id },
                  });
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <div className="bordered">
                    <DeleteOutlined />
                  </div>
                </a>{" "}
              </Popconfirm>
            </Tooltip>
            <Tooltip title="Company Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setRecord(dataObj);
                    setIsCompanyDetalisModalVisible(true);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
            {dataObj.status === "INACTIVE" && (
              <Tooltip title="Activate Company">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus =
                        dataObj.status === "INACTIVE" ? "active" : "inactive";
                      dispatch({
                        type: "TOGGLE_COMPANY_STATUS_REQUEST",
                        payload: { id: dataObj.id, actionStatus },
                      });
                    }}
                  >
                    <AiOutlinePoweroff />
                  </div>
                </a>
              </Tooltip>
            )}
            {dataObj.status === "ACTIVE" && (
              <Tooltip title="Deactivate Company">
                <a style={{ color: "red" }}>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus =
                        dataObj.status === "INACTIVE" ? "active" : "inactive";
                      dispatch({
                        type: "TOGGLE_COMPANY_STATUS_REQUEST",
                        payload: { id: dataObj.id, actionStatus },
                      });
                    }}
                  >
                    <BiBlock />
                  </div>
                </a>
              </Tooltip>
            )}
          </Space>
        ),
      };
    });
  }
  console.log("companyState", companiesState);
  return (
    <SiderDemo>
      <div className="flexColumn">
        <div className="leadFlexRow">
          <button
            className="button"
            onClick={() => {
              setIsAddCompaniesModalVisible(true);
            }}
          >
            <span>Add Company</span>
          </button>

          <Segmented
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <Avatar src={company} size={20} shape="square" />
                    <div>All Companies</div>
                  </div>
                ),
                value: "companygetAll",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <Avatar src={activeCompany} size={20} shape="square" />
                    <div>Active Companies</div>
                  </div>
                ),
                value: "active",
              },
            ]}
            onChange={(value) => {
              setPage(1);
              setPageSize(10);
              setCompaniesType(value);
              console.log(value);
            }}
          />
          <Search
            className="search"
            style={{
              width: "30%",
              borderRadius: "10px ! important",
            }}
            placeholder="Search for companies"
            allowClear
            size="large"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              onSearch(e.target.value);
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
          }}
        >
          {companiesState.isLoading ? (
            <TableSkeleton />
          ) : (
            <>
              <Table
                bordered
                columns={columns}
                dataSource={data}
                pagination={false}
              />

              {companiesType === "companygetAll" ? (
                <>
                  {companiesState.companies?.totalData && (
                    <Pagination
                      style={{
                        marginTop: "1rem",
                        marginBottom: "1rem",
                        alignSelf: "flex-end",
                      }}
                      showSizeChanger
                      onShowSizeChange={onShowSizeChange}
                      defaultCurrent={1}
                      current={page}
                      defaultPageSize={pageSize}
                      onChange={onShowSizeChange}
                      total={companiesState.companies?.totalPage * 10}
                    />
                  )}
                </>
              ) : companiesType === "active" ? (
                <>
                  {companiesState.activeCompanies?.totalData && (
                    <Pagination
                      style={{
                        marginTop: "1rem",
                        marginBottom: "1rem",
                        alignSelf: "flex-end",
                      }}
                      showSizeChanger
                      onShowSizeChange={onShowSizeChange}
                      defaultCurrent={1}
                      current={page}
                      defaultPageSize={pageSize}
                      onChange={onShowSizeChange}
                      total={companiesState.activeCompanies?.totalPage * 10}
                    />
                  )}
                </>
              ) : null}
            </>
          )}
        </div>
      </div>
      {/* ADD COMPANY */}
      {isAddCompaniesModalVisible && (
        <AddCompanies
          isAddCompaniesModalVisible={isAddCompaniesModalVisible}
          setIsAddCompaniesModalVisible={setIsAddCompaniesModalVisible}
        />
      )}
      {/* UPDATE COMPANY */}
      {isUpdateCompaniesModelVisible && (
        <UpdateCompanies
          record={record}
          isUpdateCompaniesModelVisible={isUpdateCompaniesModelVisible}
          setIsUpdateCompaniesModelVisible={setIsUpdateCompaniesModelVisible}
        />
      )}
      {isAddPackageModalVisible && (
        <AddCasePackage
          from={"COMPANY"}
          companyRecord={record}
          isAddPackageModalVisible={isAddPackageModalVisible}
          setIsAddPackageModalVisible={setIsAddPackageModalVisible}
        />
      )}
      {isCompanyDetalisModalVisible && (
        <CompanyDetails
          record={record}
          isCompanyDetalisModalVisible={isCompanyDetalisModalVisible}
          setIsCompanyDetalisModalVisible={setIsCompanyDetalisModalVisible}
        />
      )}
    </SiderDemo>
  );
};

export default Companies;
