import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
const { Option } = Select;

const AddTimeline = ({
  isAddTimelineModalVisible,
  setIsAddTimelineModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const addTimelineSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "ADD_TIMELINE_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddTimelineModalVisible(false);
  };
  return (
    <Modal
      title="Add Timeline"
      open={isAddTimelineModalVisible}
      onCancel={() => {
        setIsAddTimelineModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addTimelineSubmitHandler}
        colon={true}
        form={form}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Timeline"
            name={"timelineName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter !",
              },
            ]}
          >
            <Input placeholder="Timeline" />
          </Form.Item>
        </div>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Timeline
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddTimeline;
