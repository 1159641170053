import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Image,
  Input,
  Modal,
  Select,
  Tag,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import client from "./../../Assets/client.png";
import { FcDocument } from "react-icons/fc";
import { GoLocation } from "react-icons/go";
import {
  formattedDate,
  formattedDateTime,
  fourTagResponses,
  threeTagResponses,
  twoTagResponses,
} from "../../HelperFunction";
import { useSelector } from "react-redux";
const { Option } = Select;

const QuotationDetails = ({
  record,
  isQuotationDetailsModelVisible,
  setIsQuotationDetailsModelVisible,
}) => {
  const store = useSelector((state) => {
    return {
      quoteState: state.QuotationReducer,
    };
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "FETCH_QUOTAtION_BY_ID_REQUEST",
      payload: {
        quoteId: record?.id,
      },
    });
  }, []);
  const [form] = Form.useForm();
  const [current, setCurrent] = useState("quotationDetails");

  const clientDetailsTabList = [
    {
      key: "quotationDetails",
      tab: "Quotation Details",
    },
    {
      key: "packages",
      tab: "Packages",
    },
  ];
  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };
  console.log("record from appointment details", record);

  return (
    <Drawer
      title="Contact Details"
      open={isQuotationDetailsModelVisible}
      onClose={() => {
        setIsQuotationDetailsModelVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Card
        loading={store?.quoteState?.isLoading}
        style={{ borderRadius: "0.5rem" }}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ gap: "1rem", alignItems: "flex-start" }}
        >
          {store?.quoteState?.quotationById?.customerId?.photo ? (
            <Image
              src={store?.quoteState?.quotationById?.customerId?.photo}
              className={"clientImage"}
            />
          ) : (
            <Image src={client} className={"clientImage"} />
          )}
          <div
            className="flexColumnWithoutStyle"
            style={{
              justifyContent: "flex-start",
              // alignItems: "flex-start",
              position: "relative",

              width: "100%",
              gap: "0.5rem",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: "bold" }}>
              {`${
                store?.quoteState?.quotationById?.customerId?.fullName ?? ""
              }`}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.4rem", color: "#aaa" }}
            >
              {store?.quoteState?.quotationById?.customerId?.phoneNumber && (
                <span className="flexRowWithGap">
                  <AiOutlinePhone
                    style={{
                      fontSize: "20",
                    }}
                  />
                  {store?.quoteState?.quotationById?.customerId?.phoneNumber
                    ? store?.quoteState?.quotationById?.customerId?.phoneNumber
                    : "N/A"}
                </span>
              )}
              {store?.quoteState?.quotationById?.customerId?.address && (
                <span className="flexRowWithGap">
                  <GoLocation
                    style={{
                      fontSize: "16",
                    }}
                  />{" "}
                  {store?.quoteState?.quotationById?.customerId?.address
                    ? store?.quoteState?.quotationById?.customerId?.address
                    : "N/A"}
                </span>
              )}
              {store?.quoteState?.quotationById?.customerId?.email && (
                <span className="flexRowWithGap">
                  <AiOutlineMail
                    style={{
                      fontSize: "16",
                    }}
                  />{" "}
                  {store?.quoteState?.quotationById?.customerId?.email
                    ? store?.quoteState?.quotationById?.customerId?.email
                    : "N/A"}
                </span>
              )}
            </div>
            <div
              style={{
                position: "absolute",
                right: 0,
                top: 0,
              }}
            >
              {store?.quoteState?.quotationById?.quoteStatus ===
              "QUOTE_DRAFT" ? (
                <p className="orangeTag">QUOTE_DRAFT</p>
              ) : store?.quoteState?.quotationById?.quoteStatus === "WIN" ? (
                <p className="greenTag">WIN</p>
              ) : store?.quoteState?.quotationById?.quoteStatus === "LOSE" ? (
                <p className="redTag">LOSE</p>
              ) : store?.quoteState?.quotationById?.quoteStatus ===
                "QUOTE_SENT" ? (
                <p className="blueTag">QUOTE_SENT</p>
              ) : (
                "N/A"
              )}
            </div>
            {/* <div>
              <div>
                <span className="profileTitle">Campaign:</span>
                <span>
                  {store?.quoteState?.quotationById?.customerId??.leadResponse?.customerResponse?.campaign?.name ??
                    "N/A"}
                </span>
              </div>
            </div> */}

            {/* <div className="flexColumnWithoutStyle">
              <h4>{store?.quoteState?.quotationById?.customerId??.country?.name}</h4>
              <div>{store?.quoteState?.quotationById?.customerId??.levelOfCollege?.name}</div>
              <div>{store?.quoteState?.quotationById?.customerId??.crisco}</div>
            </div> */}
          </div>
        </div>
        <Card
          bordered={false}
          tabList={clientDetailsTabList}
          activeTabKey={current}
          onTabChange={(key) => {
            setCurrent(key);
          }}
        >
          {current === "quotationDetails" && (
            <>
              <div
                style={{
                  borderRadius: "1.5rem",
                  padding: "0.8rem",
                  display: "flex",
                  gap: "0.5rem",
                  flexDirection: "column",
                }}
              >
                {detailFun(
                  "Campaign",
                  `${
                    store?.quoteState?.quotationById?.customerId?.campaign?.name
                      ? store?.quoteState?.quotationById?.customerId?.campaign
                          ?.name
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "Source",
                  `${
                    store?.quoteState?.quotationById?.customerId?.source?.name
                      ? store?.quoteState?.quotationById?.customerId?.source
                          ?.name
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "Country of  Residence",
                  `${
                    store?.quoteState?.quotationById?.customerId
                      ?.countryOfResidence
                      ? store?.quoteState?.quotationById?.customerId
                          ?.countryOfResidence
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "Nature Of Project",
                  `${
                    store?.quoteState?.quotationById?.natureOfProject
                      ? store?.quoteState?.quotationById?.natureOfProject
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "Gross Price",
                  `${
                    store?.quoteState?.quotationById?.grossPrice
                      ? store?.quoteState?.quotationById?.grossPrice
                      : "N/A"
                  }`
                )}

                {store?.quoteState?.quotationById?.discount !== "" &&
                  store?.quoteState?.quotationById?.discount !== null &&
                  store?.quoteState?.quotationById?.discount !== undefined &&
                  store?.quoteState?.quotationById?.percentage !== "" &&
                  store?.quoteState?.quotationById?.percentage !== null &&
                  store?.quoteState?.quotationById?.percentage !==
                    undefined && (
                    <>
                      {detailFun(
                        "Discount",
                        `${store?.quoteState?.quotationById?.discount} ${
                          store?.quoteState?.quotationById?.percentage === false
                            ? "FLAT"
                            : store?.quoteState?.quotationById?.percentage ===
                              true
                            ? "%"
                            : null
                        }
                      `
                      )}
                    </>
                  )}
                {detailFun(
                  "Is GST applicable",
                  twoTagResponses(
                    store?.quoteState?.quotationById?.gstApplicable,
                    true,
                    false
                  )
                )}
                {store?.quoteState?.quotationById?.gstApplicable &&
                  detailFun(
                    "GST amount",
                    `${
                      store?.quoteState?.quotationById?.gstAmount
                        ? store?.quoteState?.quotationById?.gstAmount
                        : "N/A"
                    }`
                  )}
                {detailFun(
                  "Total amount",
                  `${
                    store?.quoteState?.quotationById?.totalAmount
                      ? store?.quoteState?.quotationById?.totalAmount
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "Payment terms (in months)",
                  `${
                    store?.quoteState?.quotationById?.paymentTermsInMonths
                      ? store?.quoteState?.quotationById?.paymentTermsInMonths
                      : "N/A"
                  }`
                )}

                {detailFun(
                  "Timeline",
                  `${
                    store?.quoteState?.quotationById?.quoteSource
                      ? store?.quoteState?.quotationById?.associatedTimelineId
                          ?.name
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "quoteSource",
                  `${
                    store?.quoteState?.quotationById?.quoteSource
                      ? store?.quoteState?.quotationById?.quoteSource
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "About us",
                  `${
                    store?.quoteState?.quotationById?.aboutUsId?.name
                      ? store?.quoteState?.quotationById?.aboutUsId?.name
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "Quote date",
                  `${
                    store?.quoteState?.quotationById?.addedDateTime
                      ? formattedDate(
                          store?.quoteState?.quotationById?.addedDateTime
                        )
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "Quote expiry date",
                  `${
                    store?.quoteState?.quotationById?.quoteExpiryDate
                      ? formattedDate(
                          store?.quoteState?.quotationById?.quoteExpiryDate
                        )
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "Mode of contact",
                  `${
                    store?.quoteState?.quotationById?.modeOfContact
                      ? store?.quoteState?.quotationById?.modeOfContact
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "Finance options?",
                  twoTagResponses(
                    store?.quoteState?.quotationById?.financeOptions,
                    true,
                    false
                  )
                )}
                {detailFun(
                  "Description",
                  `${
                    store?.quoteState?.quotationById?.description
                      ? store?.quoteState?.quotationById?.description
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "Special note",
                  `${
                    store?.quoteState?.quotationById?.specialNote
                      ? store?.quoteState?.quotationById?.specialNote
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "Quote Update",
                  `${
                    store?.quoteState?.quotationById?.quotesUpdate
                      ? store?.quoteState?.quotationById?.quotesUpdate
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "Display quote update in quote confirmation?",
                  twoTagResponses(
                    store?.quoteState?.quotationById
                      ?.displayQuotesUpdateInQuoteConfirmation,
                    true,
                    false
                  )
                )}
                {detailFun(
                  "Payment terms information",
                  `${
                    store?.quoteState?.quotationById?.paymentTermsInfo
                      ? store?.quoteState?.quotationById?.paymentTermsInfo
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "Display payment terms info in quote confirmation?",
                  twoTagResponses(
                    store?.quoteState?.quotationById
                      ?.displayPaymentTermsInfoInQuoteConfirmation,
                    true,
                    false
                  )
                )}
              </div>
            </>
          )}
        </Card>
      </Card>
    </Drawer>
  );
};

export default QuotationDetails;
