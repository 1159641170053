import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  List,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { MdOutlineDisabledVisible } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../Components/Layout/SiderDemo";
import publicDrive from "./../../Assets/publicDrive.png";
import privateDrive from "./../../Assets/privateDrive.png";
import { BsFolder, BsUpload } from "react-icons/bs";
import { GrActions } from "react-icons/gr";
import { BiArrowBack } from "react-icons/bi";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineFile,
  AiOutlinePlus,
} from "react-icons/ai";
import AddNewFolder from "./AddNewFolder";
import AddPrivateNewFolder from "./AddPrivateNewFolder";
import RenameFolder from "./RenameFolder";
import FileUpload from "./FiileUpload";
import { FiUpload } from "react-icons/fi";
import PublicFileUpload from "./PublicFileUpload";

const columns = [
  {
    title: "",
    dataIndex: "",
    align: "center",
  },
  {
    title: "",
    dataIndex: "",
    align: "center",
  },
  {
    title: "",
    dataIndex: "",
    align: "center",
  },
  {
    title: "",
    dataIndex: "",
    align: "center",
  },
  {
    title: "",
    dataIndex: "",
    align: "center",
  },
  {
    title: "",
    dataIndex: "",
    align: "center",
  },
  {
    title: "",
    dataIndex: "",
    align: "center",
  },
  {
    title: "",
    dataIndex: "",
    align: "center",
  },
  {
    title: "",
    dataIndex: "",
    align: "center",
  },
  {
    title: "",
    dataIndex: "",
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
  },
];

const Resources = () => {
  const driveState = useSelector((state) => state.ResourcesReducer);
  const [isAddResourcesModalVisible, setIsAddResourcesModalVisible] =
    useState(false);
  const [isUpdateResourcesModalVisible, setIsUpdateResourcesModalVisible] =
    useState(false);
  const [record, setRecord] = useState({});
  const [driveType, setResourcesType] = useState("PUBLIC");
  const [item, setItem] = useState();
  const [isAddNewFolderModalVisible, setIsAddNewFolderModalVisible] =
    useState(false);
  const [
    isAddPrivateNewFolderModalVisible,
    setIsAddPrivateNewFolderModalVisible,
  ] = useState(false);
  const [isRenameFolderModalVisible, setIsRenameFolderModalVisible] =
    useState(false);
  const [isUploadFileModalVisible, setIsUploadFileModalVisible] =
    useState(false);
  const [isUploadPublicFileModalVisible, setIsUploadPublicFileModalVisible] =
    useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (driveType === "PRIVATE") {
      dispatch({
        type: "FETCH_PRIVATE_DRIVE_REQUEST",
      });
    } else {
      dispatch({ type: "FETCH_PUBLIC_DRIVE_REQUEST" });
    }
  }, [driveType]);
  useEffect(() => {
    if (
      isAddResourcesModalVisible === false ||
      isUpdateResourcesModalVisible === false
    ) {
      dispatch({ type: "FETCH_PUBLIC_DRIVE_REQUEST" });
    }
  }, [isAddResourcesModalVisible, isUpdateResourcesModalVisible]);
  console.log("item from  drive", item);
  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div className="flexRow" style={{ justifyContent: "center" }}>
          <Segmented
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <Avatar src={publicDrive} size={20} shape="square" />
                    <div>Public Drive</div>
                  </div>
                ),
                value: "PUBLIC",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <Avatar src={privateDrive} size={20} shape="square" />
                    <div>Private Drive</div>
                  </div>
                ),
                value: "PRIVATE",
              },
            ]}
            onChange={(value) => {
              setResourcesType(value);
              console.log(value);
            }}
          />
        </div>
        {driveType === "PUBLIC" ? (
          <div style={{ marginTop: "4rem" }}>
            {driveState.publicDrive.totalData === null && (
              <div
                onClick={() => {
                  console.warn(driveState, "driveState");
                  if (driveState.publicDrive.parentId === null) {
                    dispatch({
                      type: "FETCH_PUBLIC_DRIVE_REQUEST",
                    });
                    return;
                  }

                  dispatch({
                    type: "FETCH_PUBLICBY_ID_DRIVE_REQUEST",
                    payload: driveState.publicDrive.parentId,
                  });
                }}
              >
                <Tooltip title="Back">
                  <BiArrowBack className="iconStyle" />
                </Tooltip>
              </div>
            )}
            <List
              header={
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  Public Drive{" "}
                  <div
                    className="flexRowWithoutStyle"
                    style={{ gap: "0.5rem" }}
                  >
                    <Tooltip title="Upload File">
                      <FiUpload
                        className="iconStyle"
                        onClick={() => {
                          setItem(item);
                          setIsUploadPublicFileModalVisible(true);
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Add Folder">
                      <AiOutlinePlus
                        className="iconStyle"
                        onClick={() => {
                          setIsAddNewFolderModalVisible(true);
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              }
              bordered
              dataSource={driveState.publicDrive?.data}
              renderItem={(item) => {
                console.log("inside render", item);
                return (
                  <List.Item
                    className="flexRowWithoutStyle"
                    style={{
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div
                      className="flexRowWithoutStyle"
                      style={{
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      {item.idType === "FOLDER" ? (
                        <div
                          className="folderFlex"
                          onClick={() => {
                            dispatch({
                              type: "FETCH_PUBLICBY_ID_DRIVE_REQUEST",
                              payload: item.id,
                            });
                          }}
                        >
                          <BsFolder size={30} color={"#f8d172"} />
                          {item?.contentName}
                        </div>
                      ) : (
                        <a href={item.fileContent}>
                          <div className="folderFlex">
                            <AiOutlineFile size={30} color={"#f8d172"} />
                            {item?.contentName}
                          </div>{" "}
                        </a>
                      )}

                      <div
                        style={{
                          display: "flex",

                          gap: "0.5rem",
                          justifyContent: "space-between",
                          width: "7%",
                        }}
                      >
                        <Tooltip title="Rename">
                          <AiOutlineEdit
                            className="iconStyle"
                            onClick={() => {
                              setItem(item);
                              setIsRenameFolderModalVisible(true);
                            }}
                          />
                        </Tooltip>
                        <Popconfirm
                          title="Are you sure to delete this file/folder?"
                          onConfirm={() => {
                            dispatch({
                              type: "DELETE_FOLDER_REQUEST",
                              payload: item.id,
                            });
                          }}
                          onCancel={() => {}}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Tooltip title="Delete">
                            <AiOutlineDelete className="iconStyle" />
                          </Tooltip>
                        </Popconfirm>
                      </div>
                    </div>
                  </List.Item>
                );
              }}
            />
          </div>
        ) : (
          <div style={{ marginTop: "4rem" }}>
            {driveState.privateDrive.totalData === null && (
              <div
                onClick={() => {
                  console.warn(driveState, "driveState");
                  if (driveState.privateDrive.parentId === null) {
                    dispatch({
                      type: "FETCH_PRIVATE_DRIVE_REQUEST",
                    });
                    return;
                  }

                  dispatch({
                    type: "FETCH_PRIVATEBY_ID_DRIVE_REQUEST",
                    payload: driveState.privateDrive.parentId,
                  });
                }}
              >
                <Tooltip title="Back">
                  <BiArrowBack className="iconStyle" />
                </Tooltip>
              </div>
            )}
            <List
              header={
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  Private Drive{" "}
                  <div
                    className="flexRowWithoutStyle"
                    style={{ gap: "0.5rem" }}
                  >
                    <Tooltip title="Upload File">
                      <FiUpload
                        className="iconStyle"
                        onClick={() => {
                          setItem(item);
                          setIsUploadFileModalVisible(true);
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Add Folder">
                      <AiOutlinePlus
                        className="iconStyle"
                        onClick={() => {
                          setIsAddPrivateNewFolderModalVisible(true);
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              }
              //   footer={<div>Footer</div>}
              bordered
              dataSource={driveState.privateDrive?.data}
              renderItem={(item) => {
                console.log("inside render", item);
                return (
                  <List.Item>
                    <div
                      className="flexRowWithoutStyle"
                      style={{
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      {item.idType === "FOLDER" ? (
                        <div
                          className="folderFlex"
                          onClick={() => {
                            dispatch({
                              type: "FETCH_PRIVATEBY_ID_DRIVE_REQUEST",
                              payload: item.id,
                            });
                          }}
                        >
                          <BsFolder size={30} color={"#f8d172"} />
                          {item?.contentName}
                        </div>
                      ) : (
                        <a href={item.fileContent}>
                          <div className="folderFlex">
                            <AiOutlineFile size={30} color={"#f8d172"} />
                            {item?.contentName}
                          </div>{" "}
                        </a>
                      )}

                      <div
                        style={{
                          display: "flex",

                          gap: "0.5rem",
                          justifyContent: "space-between",
                          width: "7%",
                        }}
                      >
                        <Tooltip title="Rename">
                          <AiOutlineEdit
                            className="iconStyle"
                            onClick={() => {
                              setItem(item);
                              setIsRenameFolderModalVisible(true);
                            }}
                          />
                        </Tooltip>
                        <Popconfirm
                          title="Are you sure to delete this file/folder?"
                          onConfirm={() => {
                            dispatch({
                              type: "DELETE_FOLDER_REQUEST",
                              payload: item.id,
                            });
                          }}
                          onCancel={() => {}}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Tooltip title="Delete">
                            <AiOutlineDelete className="iconStyle" />
                          </Tooltip>
                        </Popconfirm>
                      </div>
                    </div>
                  </List.Item>
                );
              }}
            />
          </div>
        )}
      </div>
      <AddNewFolder
        driveType={driveType}
        parentId={driveState.publicDrive.id}
        isAddNewFolderModalVisible={isAddNewFolderModalVisible}
        setIsAddNewFolderModalVisible={setIsAddNewFolderModalVisible}
      />
      <AddPrivateNewFolder
        driveType={driveType}
        parentId={driveState.privateDrive.id}
        isAddNewFolderModalVisible={isAddPrivateNewFolderModalVisible}
        setIsAddNewFolderModalVisible={setIsAddPrivateNewFolderModalVisible}
      />
      <RenameFolder
        item={item}
        isRenameFolderModalVisible={isRenameFolderModalVisible}
        setIsRenameFolderModalVisible={setIsRenameFolderModalVisible}
      />
      <FileUpload
        item={driveState.privateDrive}
        driveType={driveType}
        parentId={driveState.privateDrive.id}
        isFileUploadModalVisible={isUploadFileModalVisible}
        setIsFileUploadModalVisible={setIsUploadFileModalVisible}
      />
      <PublicFileUpload
        item={driveState.publicDrive}
        driveType={driveType}
        parentId={driveState.publicDrive.id}
        isFileUploadModalVisible={isUploadPublicFileModalVisible}
        setIsFileUploadModalVisible={setIsUploadPublicFileModalVisible}
      />
      {/* <UpdateResources
        record={record}
        isUpdateResourcesModalVisible={isUpdateResourcesModalVisible}
        setIsUpdateResourcesModalVisible={setIsUpdateResourcesModalVisible}
      /> */}
    </SiderDemo>
  );
};

export default Resources;
