import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
const { Option } = Select;

const AddQuotationSource = ({
  isAddQuotationSourceModalVisible,
  setIsAddQuotationSourceModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const addQuotationSourceSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "ADD_QUOTATION_SOURCE_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddQuotationSourceModalVisible(false);
  };
  return (
    <Modal
      title="Add Quotation Source"
      open={isAddQuotationSourceModalVisible}
      onCancel={() => {
        setIsAddQuotationSourceModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addQuotationSourceSubmitHandler}
        colon={true}
        form={form}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Quotation Source"
            name={"sourceName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter source name!",
              },
            ]}
          >
            <Input placeholder="Source name" />
          </Form.Item>
        </div>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add quotation source
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddQuotationSource;
