import { Modal, Typography } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useDispatch } from "react-redux";
const { Paragraph } = Typography;

const AppointmentFormUrlLink = ({
  record,
  isUrlLinkModalVisible,
  setIsUrlLinkModalVisible,
}) => {
  const store = useSelector((state) => {
    return {
      profileState: state.LoginReducer,
      loginState: state.ProfileReducer,
      campaignState: state.CampaignReducer,
    };
  });
  const [shortenedUrl, setShortenedUrl] = useState("");
  const dispatch = useDispatch();
  const successFunction = async (response) => {
    try {
      const response1 = await axios.get(
        `https://api.shrtco.de/v2/shorten?url=${encodeURIComponent(
          response?.data
        )}`
      );
      setShortenedUrl(response1.data.result.full_short_link);
      return;
    } catch (e) {}
  };
  useEffect(() => {
    dispatch({
      type: "FETCH_QR_CODE_OF_APPOINTMENT_FORM_OF_CAMPAIGN_REQUEST",
      payload: { campaignId: record.id },
      isSuccessFn: true,
      successFunction,
    });
  }, []);
  return (
    <Modal
      title="URL Link"
      open={isUrlLinkModalVisible}
      onCancel={() => {
        setIsUrlLinkModalVisible(false);
      }}
      footer={null}
    >
      {shortenedUrl !== "" && (
        <Paragraph
          copyable={shortenedUrl}
          style={{
            borderColor: "#57a8e9",
            borderRadius: "0.4rem",
            padding: "0.4rem 0.5rem",
            outline: 0,
            boxShadow: "0 0 0 2px rgba(87,168,233, .2)",
          }}
        >
          {shortenedUrl}
        </Paragraph>
      )}
    </Modal>
  );
};

export default AppointmentFormUrlLink;
