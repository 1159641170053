import React from "react";
import CountUp from "react-countup";

const DashboardComponentAnalysis = ({
  title,
  graph,
  subtitle1,
  subtitle1Count,
  subtitle2,
  subtitle2Count,
  subtitle3,
  subtitle3Count,
  subtitle4,
  subtitle4Count,
}) => {
  return (
    <div
      style={{
        border: "1px solid #cccccc",
        padding: "1rem",
        backgroundColor: "white",
        // width: "260px",
        minWidth: "14rem",
        // width: "98%",
        display: "flex",
        alignItem: "center",
        flexDirection: "column",
        borderRadius: "5px",
        // height: "280px",
      }}
    >
      <div
        style={{
          fontSize: "12px",
          fontWeight: "700",
          color: "#888888",
          marginBottom: "0.4rem",
        }}
      >
        {title}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              fontSize: "12px",
              fontWeight: "700",
              color: "black",
            }}
          >
            <CountUp end={subtitle1Count} />
          </div>
          <div
            style={{
              fontSize: "13px",
              fontWeight: "500",
              color: "#888888",
            }}
          >
            {subtitle1}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              fontSize: "12px",
              fontWeight: "700",
              color: "black",
            }}
          >
            <CountUp end={subtitle2Count} />
          </div>
          <div
            style={{
              fontSize: "13px",
              fontWeight: "500",
              color: "#888888",
            }}
          >
            {subtitle2}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // width: "14rem",
          height: "12rem",
        }}
      >
        {graph}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {subtitle3 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                fontSize: "12px",
                fontWeight: "700",
                color: "black",
              }}
            >
              <CountUp end={subtitle3Count} />
            </div>
            <div
              style={{
                fontSize: "13px",
                fontWeight: "500",
                color: "#888888",
              }}
            >
              {subtitle3}
            </div>
          </div>
        )}
        {subtitle4 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                fontSize: "12px",
                fontWeight: "700",
                color: "black",
              }}
            >
              <CountUp end={subtitle4Count} />
            </div>
            <div
              style={{
                fontSize: "13px",
                fontWeight: "500",
                color: "#888888",
              }}
            >
              {subtitle4}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardComponentAnalysis;
