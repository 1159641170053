import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  contacts: {},
  activeContacts: {},
};

const ContactsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "ADD_CONTACT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_CONTACT_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Contact added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_CONTACT_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add contact.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPLOAD_CONTACT_IMAGE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPLOAD_CONTACT_IMAGE_REQUEST_SUCCESS": {
      action.payload?.response?.data?.message
        ? message.success(action.payload?.response?.data?.message)
        : message.success("Success! Image uploaded.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPLOAD_CONTACT_IMAGE_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to Image upload.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_CONTACTS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_CONTACTS_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        contacts: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_CONTACTS_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_ACTIVE_CONTACTS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_ACTIVE_CONTACTS_REQUEST_SUCCESS": {
      console.log("from active reducer", action.payload);
      return {
        ...state,
        activeContacts: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ACTIVE_CONTACTS_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_CONTACT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_CONTACT_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Contact updated.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_CONTACT_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to update contact.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_CONTACTS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DELETE_CONTACTS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Contact deleted.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_CONTACTS_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to delete contact.");

      message.error("error");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "CHANGE_CONTACTS_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "CHANGE_CONTACTS_STATUS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success!  Contact status changed.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "CHANGE_CONTACTS_STATUS_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to Contact status.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_CAMPAIGN_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_CAMPAIGN_REQUEST_SUCCESS": {
      action.payload2.message
        ? message.success(action.payload2.message)
        : message.success("Success! Campaign added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_CAMPAIGN_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to  .");

      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default ContactsReducer;
