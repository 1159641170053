import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  visas: {},
  filterVisas: {},
  filterInstitutes: [],
  filterDegrees: [],
  caseDropdown: {},
  countryWorkflow: {},
  workflowStatuses: {},
  activeCasePackage: {},
  coursesByInstituteAndDegree: {},
  caseDetailsByWorflowDtoId: {},
  workflowStatusesByWorkflowId: {},
  currentWorkflowStatus: [],
  requiredWorkflowCheckList: [],
  requiredCoursesCheckList: [],
  searchedInstitutes: [],
  caseById: {},
};

const VisasReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "DOCUMENT_CHECKLIST_VISIBLE":
      console.log(action.payload, "action paylaod");
      let newUpdatedWorkFlowCheckList = state.requiredWorkflowCheckList;
      console.log(newUpdatedWorkFlowCheckList, "workflowlist");
      newUpdatedWorkFlowCheckList[action.payload.index].uploadedAttachment =
        action.payload.documentChecklist.imageFullPath;
      console.log(newUpdatedWorkFlowCheckList, "workflowlist");
      return {
        ...state,
        requiredWorkflowCheckList: [...newUpdatedWorkFlowCheckList],
      };
    case "ADD_DOCUMENT_CHECKLIST":
      return {
        ...state,
        requiredWorkflowCheckList: [
          ...action.payload.requiredWorkflowCheckList,
        ],
      };
    case "COURSE_CHECKLIST_VISIBLE":
      console.log(action.payload, "action.payload");
      let newUpdatedCourseCheckList = [...state.requiredCoursesCheckList];
      newUpdatedCourseCheckList[action.payload.index].uploadedAttachment =
        action.payload.courseCheckList.imageFullPath;
      console.log(newUpdatedCourseCheckList, "coursechecklist");
      return {
        ...state,
        requiredCoursesCheckList: [...newUpdatedCourseCheckList],
      };
    case "ADD_COURSE_CHECKLIST":
      return {
        ...state,
        requiredCoursesCheckList: [...action.payload.requiredCoursesCheckList],
      };
    case "UPDATE_DOCUMENT_CHECKLIST_REQUEST": {
      return {
        ...state,
        requiredWorkflowCheckList: action.payload.requiredWorkflowCheckList,
        requiredCoursesCheckList: action.payload.requiredCoursesCheckList,
      };
    }
    case "UPDATE_CURRENT_WORKFLOW_STATUS_REQUEST": {
      return {
        ...state,
        currentWorkflowStatus: action.payload,
      };
    }
    case "FETCH_IN_PROGRESS_FILTER_VISAS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_IN_PROGRESS_FILTER_VISAS_REQUEST_SUCCESS": {
      return {
        ...state,
        filterVisas: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_IN_PROGRESS_FILTER_VISAS_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load cases.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_NOT_ASSIGNED_FILTER_VISAS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_NOT_ASSIGNED_FILTER_VISAS_REQUEST_SUCCESS": {
      return {
        ...state,
        filterVisas: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_NOT_ASSIGNED_FILTER_VISAS_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load cases.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_COMPLETED_FILTER_VISAS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_COMPLETED_FILTER_VISAS_REQUEST_SUCCESS": {
      return {
        ...state,
        filterVisas: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_COMPLETED_FILTER_VISAS_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load cases.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_VISA_CASES_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_VISA_CASES_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        visas: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_VISA_CASES_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_WORKFLOW_STATUS_BY_ID_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_WORKFLOW_STATUS_BY_ID_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        workflowStatuses: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_WORKFLOW_STATUS_BY_ID_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_CASES_DROPDOWN_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_CASES_DROPDOWN_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        caseDropdown: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_CASES_DROPDOWN_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_ACTIVE_CASE_PACKAGE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_ACTIVE_CASE_PACKAGE_REQUEST_SUCCESS": {
      return {
        ...state,
        activeCasePackage: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ACTIVE_CASE_PACKAGE_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to load case packages .");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_COURSES_BY_INSTITUTE_AND_DEGREE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_COURSES_BY_INSTITUTE_AND_DEGREE_REQUEST_SUCCESS": {
      return {
        ...state,
        coursesByInstituteAndDegree: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_COURSES_BY_INSTITUTE_AND_DEGREE_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error(
            "Error! Unable to find courses of the institute and degree."
          );

      return {
        ...state,
        isLoading: false,
      };
    }
    case "CLEAR_COURSE_REQUEST": {
      return {
        ...state,
        coursesByInstituteAndDegree: {},
      };
    }
    case "FETCH_CASE_LOG_BY_WORKFLOWDTO_ID_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_CASE_LOG_BY_WORKFLOWDTO_ID_REQUEST_SUCCESS": {
      return {
        ...state,
        caseDetailsByWorflowDtoId: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_CASE_LOG_BY_WORKFLOWDTO_ID_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to load case details by workflow id.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_WORKFLOW_STATUSES_BY_WORKFLOW_ID_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_WORKFLOW_STATUSES_BY_WORKFLOW_ID_REQUEST_SUCCESS": {
      return {
        ...state,
        workflowStatusesByWorkflowId: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_WORKFLOW_STATUSES_BY_WORKFLOW_ID_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error(
            "Error! Unable to load workflow status by workflow id."
          );

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_CASE_STATUS_CASE_LOG_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_CASE_STATUS_CASE_LOG_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Case status updated.");
      console.log("FRM update case status case log success", action.payload);
      return {
        ...state,
        currentWorkflowStatus: action.payload?.currentWorkFlowStatus,
        isLoading: false,
      };
    }
    case "UPDATE_CASE_STATUS_CASE_LOG_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to update case status.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_VISA_OUTCOME_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_VISA_OUTCOME_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Status updated.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_VISA_OUTCOME_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to update status.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPLOAD_CASE_DOCUMENTS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPLOAD_CASE_DOCUMENTS_REQUEST_SUCCESS": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPLOAD_CASE_DOCUMENTS_REQUEST_FAILURE": {
      action.payload?.response.data.message
        ? message.error(action.payload?.response.data.message)
        : message.error("Error! Unable to add document.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPLOAD_URL_FROM_UPLOAD_RESPONSE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPLOAD_URL_FROM_UPLOAD_RESPONSE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Document added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPLOAD_URL_FROM_UPLOAD_RESPONSE_REQUEST_FAILURE": {
      action.payload?.response.data.message
        ? message.error(action.payload?.response.data.message)
        : message.error("Error! Unable to add document.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_VISA_CASE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_VISA_CASE_REQUEST_SUCCESS": {
      action.payload?.response?.data?.message
        ? message.success(action.payload?.response?.data?.message)
        : message.success("Success! Visa case added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_VISA_CASE_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add Visa case.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_COUNTRY_WORKFLOW_BY_ID_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_COUNTRY_WORKFLOW_BY_ID_REQUEST_SUCCESS": {
      return {
        ...state,
        countryWorkflow: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_COUNTRY_WORKFLOW_BY_ID_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to load country workflow.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_VISAS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_VISAS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Visas updated.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_VISAS_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to update Visas.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_VISAS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DELETE_VISAS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Visas deleted.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_VISAS_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to delete Visas.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_VISAS_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "TOGGLE_VISAS_STATUS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Visas status changed.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_VISAS_STATUS_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to change Visas status.");

      message.error("error");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_VISA_SUBCATEGORY_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_VISA_SUBCATEGORY_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success!  Subcategory added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_VISA_SUBCATEGORY_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to add subcategory.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_SALES_REP_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_SALES_REP_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success!  Sales Rep added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_SALES_REP_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to add sales rep.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_CASE_PACKAGE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_CASE_PACKAGE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Case package added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_CASE_PACKAGE_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to add case package.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_DEGREE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_DEGREE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Degree added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_DEGREE_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to add degree.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_INTAKE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_INTAKE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Intake added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_INTAKE_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to add intake.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_LEAD_TYPE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_LEAD_TYPE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Lead type added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_LEAD_TYPE_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to add Lead type.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_ORDER_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_ORDER_STATUS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Status added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_ORDER_STATUS_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to add status.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ASSIGN_AGENTS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ASSIGN_AGENTS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Agents assigned.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ASSIGN_AGENTS_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to assign agents.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_INSTITUTE_BASED_ON_LOCATION_AND_COUNTRY_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_INSTITUTE_BASED_ON_LOCATION_AND_COUNTRY_REQUEST_SUCCESS": {
      return {
        ...state,
        filterInstitutes: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_INSTITUTE_BASED_ON_LOCATION_AND_COUNTRY_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error(
            "Error! Unable to fetch institute for current location type."
          );

      return {
        ...state,
        isLoading: false,
      };
    }
    case "CLEAR_INSTITUTE_SEARCH_DROPDOWN": {
      return {
        ...state,
        searchedInstitutes: [],
      };
    }
    case "FETCH_DEGREE_BY_INSTITUTE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_DEGREE_BY_INSTITUTE_REQUEST_SUCCESS": {
      return {
        ...state,
        filterDegrees: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_DEGREE_BY_INSTITUTE_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to .");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "SEARCH_INSTITUTE_FOR_COURSES_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "SEARCH_INSTITUTE_FOR_COURSES_REQUEST_SUCCESS": {
      return {
        ...state,
        searchedInstitutes: action.payload,
        isLoading: false,
      };
    }
    case "SEARCH_INSTITUTE_FOR_COURSES_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to search institutes.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_CASE_BY_ID_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_CASE_BY_ID_REQUEST_SUCCESS": {
      return {
        ...state,
        caseById: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_CASE_BY_ID_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load case details.");

      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default VisasReducer;
