import {
  Card,
  Divider,
  Drawer,
  Image,
  Modal,
  Input,
  Tooltip,
  Checkbox,
  Button,
  Form,
} from "antd";
import React, { useEffect } from "react";
import {
  formattedDate,
  formattedDateTime,
  twoTagResponses,
} from "../../HelperFunction";
import client from "../../Assets/client.png";
import { AiOutlineHeart, AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { FcDocument } from "react-icons/fc";
import { GoLocation } from "react-icons/go";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ImCheckboxChecked } from "react-icons/im";
import AddVisaCases from "../Visas/AddVisas";
import { ExclamationCircleFilled } from "@ant-design/icons";
import StatusHistoryOnlyUpdate from "../Appointments/StatusHistoryOnlyUpdate";
const { Meta } = Card;
const { confirm } = Modal;
const { TextArea } = Input;

const CoursesDetail = ({
  fromWishlist,
  record,
  fromCounselling,
  recordData,
  instituteRecordForCountry,
  setIsStartCounsellingModalVisible,
  isCourseDetailsModelVisible,
  setIsCourseDetailsModelVisible,
}) => {
  const visaState = useSelector((state) => state.VisasReducer);

  const dispatch = useDispatch();

  const [current, setCurrent] = useState("description");
  const [isAddVisasModalVisible, setIsAddVisasModalVisible] = useState(false);
  const [
    isStatusHistoryOnlyUpdateModalVisible,
    setIsStatusHistoryOnlyUpdateModalVisible,
  ] = useState(false);
  const [enrollType, setEnrollType] = useState("ONSHORE");
  const clientDetailsTabList = [
    {
      key: "description",
      tab: "Description",
    },
    {
      key: "courseDetail",
      tab: "Course Detail",
    },
    {
      key: "document",
      tab: "Document Checklist",
    },
  ];
  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };
  const reloadFn = (response) => {
    console.log("response of wishlist", response);
    dispatch({
      type: "FETCH_COURSE_WISHLIST_FOR_LEAD_ID_REQUEST",
      payload: {
        leadId: recordData?.leadResponse?.leadId,
        page: 1,
        pageSize: 10,
      },
    });
  };

  console.log("course details record", record);
  console.log("course details recordData", recordData);
  console.log("course details fromCounselling", fromCounselling);

  return (
    <Drawer
      title="Course Details"
      open={isCourseDetailsModelVisible}
      onClose={() => {
        console.log("click");
        setIsCourseDetailsModelVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Card style={{ borderRadius: "0.5rem" }} className="drawerStyle">
        <div className="flexRowWithoutStyle" style={{ gap: "1rem" }}>
          <div
            className="flexColumnWithoutStyle"
            style={{
              justifyContent: "flex-start",
              width: "100%",
              gap: "0.5rem",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: "bold" }}>
              {record?.name}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.4rem", color: "#aaa" }}
            >
              {record?.courseCode}
            </div>
            <div>{record?.degree?.name}</div>

            <div
              className="flexRowWithoutStyle"
              style={{ gap: "1rem", padding: "1rem" }}
            >
              <span>Time Period: </span>
              <span>
                {record?.courseDuration} {record?.courseAccordingTo}
              </span>
            </div>
            <div className="flexRowWithoutStyle" style={{ gap: "0.3rem" }}>
              {fromCounselling && fromWishlist !== true && (
                <Button
                  type="primary"
                  onClick={() => {
                    dispatch({
                      type: "ADD_TO_COURSE_WISHLIST_REQUEST",
                      payload: {
                        formData: { course: [{ id: record?.id }] },
                        leadId: recordData?.leadResponse?.leadId,
                        isReload: true,
                        reloadFn: reloadFn,
                      },
                    });
                  }}
                >
                  Add to wishlist
                </Button>
              )}
              {fromCounselling && (
                <Button
                  type="primary"
                  onClick={() => {
                    setIsStatusHistoryOnlyUpdateModalVisible(true);
                  }}
                >
                  Status Update
                </Button>
              )}
            </div>
          </div>
        </div>
        <Card
          bordered={false}
          tabList={clientDetailsTabList}
          activeTabKey={current}
          onTabChange={(key) => {
            setCurrent(key);
          }}
        >
          {current === "courseDetail" && (
            <div
              className="flexRowWithoutStyle"
              style={{
                gap: "0.5rem",
                width: "100%",
              }}
            >
              {record?.onshore ? (
                <Card className="clientCard" style={{ width: "100%" }}>
                  {detailFun("Onshore", `${record?.onshore ? "Yes" : "No"}`)}
                  {detailFun(
                    "Onshore Fee",
                    `${record?.onShoreFee ? record?.onShoreFee : "N/A"}`
                  )}
                  {detailFun(
                    "Is Onshore Discount?",

                    record?.onshoreIsDiscount
                      ? twoTagResponses(record?.onshoreIsDiscount, true, false)
                      : "N/A"
                  )}
                  {detailFun(
                    "Onshore Discount",
                    `${
                      record?.onshoreDiscountAmount
                        ? record?.onshoreDiscountAmount
                        : "N/A"
                    }`
                  )}
                  {detailFun(
                    "Onshore Discount Type",
                    `${
                      record?.onshoreDiscountType
                        ? record?.onshoreDiscountType
                        : "N/A"
                    }`
                  )}
                  {detailFun(
                    "Is Onshore Scholarship?",

                    record?.onshoreIsScholarship
                      ? twoTagResponses(
                          record?.onshoreIsScholarship,
                          true,
                          false
                        )
                      : "N/A"
                  )}
                  {detailFun(
                    "Onshore Scholarship",
                    `${
                      record?.onshoreScholarshipAmount
                        ? record?.onshoreScholarshipAmount
                        : "N/A"
                    }`
                  )}
                  {detailFun(
                    "Onshore Scholarship Type",
                    `${
                      record?.onshoreScholarshipType
                        ? record?.onshoreScholarshipType
                        : "N/A"
                    }`
                  )}
                  {detailFun(
                    "Onshore Material Fee",
                    `${
                      record?.onshoreMaterialFee
                        ? record?.onshoreMaterialFee
                        : "N/A"
                    }`
                  )}
                  {detailFun(
                    "Onshore Application Fee",
                    `${
                      record?.onshoreApplicationFee
                        ? record?.onshoreApplicationFee
                        : "N/A"
                    }`
                  )}
                  {detailFun(
                    "Onshore Additional Fee",
                    `${
                      record?.onshoreAdditionalFee
                        ? record?.onshoreAdditionalFee
                        : "N/A"
                    }`
                  )}
                  <div className="flexCenter">
                    <Button
                      type="primary"
                      style={{ borderRadius: "1rem" }}
                      onClick={() => {
                        setEnrollType("ONSHORE");
                        setIsAddVisasModalVisible(true);
                      }}
                    >
                      Onshore Enroll
                    </Button>
                  </div>
                </Card>
              ) : null}
              {record?.offshore ? (
                <Card className="clientCard" style={{ width: "100%" }}>
                  {detailFun("Offshore", `${record?.offshore ? "Yes" : "No"}`)}
                  {detailFun(
                    "Offshore Fee",
                    `${record?.offShoreFee ? record?.offShoreFee : "N/A"}`
                  )}
                  {detailFun(
                    "Is Offshore Discount?",

                    record?.offshoreIsDiscount
                      ? twoTagResponses(record?.offshoreIsDiscount, true, false)
                      : "N/A"
                  )}
                  {detailFun(
                    "Offshore Discount",
                    `${
                      record?.offshoreDiscountAmount
                        ? record?.offshoreDiscountAmount
                        : "N/A"
                    }`
                  )}
                  {detailFun(
                    "Offshore Discount Type",
                    `${
                      record?.offshoreDiscountType
                        ? record?.offshoreDiscountType
                        : "N/A"
                    }`
                  )}
                  {detailFun(
                    "Is Offshore Scholarship?",

                    twoTagResponses(record?.offshoreIsScholarship, true, false)
                  )}
                  {detailFun(
                    "Offshore Scholarship",
                    `${
                      record?.offshoreScholarshipAmount
                        ? record?.offshoreScholarshipAmount
                        : "N/A"
                    }`
                  )}
                  {detailFun(
                    "Offshore Scholarship Type",
                    `${
                      record?.offshoreScholarshipType
                        ? record?.offshoreScholarshipType
                        : "N/A"
                    }`
                  )}
                  {detailFun(
                    "Offshore Material Fee",
                    `${
                      record?.offshoreMaterialFee
                        ? record?.offshoreMaterialFee
                        : "N/A"
                    }`
                  )}
                  {detailFun(
                    "Offshore Application Fee",
                    `${
                      record?.offshoreApplicationFee
                        ? record?.offshoreApplicationFee
                        : "N/A"
                    }`
                  )}
                  {detailFun(
                    "Offshore Additional Fee",
                    `${
                      record?.offshoreAdditionalFee
                        ? record?.offshoreAdditionalFee
                        : "N/A"
                    }`
                  )}
                  <div className="flexCenter">
                    <Button
                      type="primary"
                      style={{ borderRadius: "1rem" }}
                      onClick={() => {
                        setEnrollType("OFFSHORE");
                        setIsAddVisasModalVisible(true);
                      }}
                    >
                      Offshore Enroll
                    </Button>
                  </div>
                </Card>
              ) : null}
              {record?.both ? (
                <>
                  <Card className="clientCard" style={{ width: "100%" }}>
                    {/* {detailFun(
                      "Both (Onshore/Offshore)",
                      `${record?.both ? "Yes" : "No"}`
                    )} */}
                    {detailFun(
                      "Onshore Fee",
                      `${record?.onShoreFee ? record?.onShoreFee : "N/A"}`
                    )}
                    {detailFun(
                      "Is Onshore Discount?",

                      record?.onshoreIsDiscount
                        ? twoTagResponses(
                            record?.onshoreIsDiscount,
                            true,
                            false
                          )
                        : "N/A"
                    )}
                    {detailFun(
                      "Onshore Discount",
                      `${
                        record?.onshoreDiscountAmount
                          ? record?.onshoreDiscountAmount
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Onshore Discount Type",
                      `${
                        record?.onshoreDiscountType
                          ? record?.onshoreDiscountType
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Is Onshore Scholarship?",
                      record?.onshoreIsScholarship
                        ? twoTagResponses(
                            record?.onshoreIsScholarship,
                            true,
                            false
                          )
                        : "N/A"
                    )}
                    {detailFun(
                      "Onshore Scholarship",
                      `${
                        record?.onshoreScholarshipAmount
                          ? record?.onshoreScholarshipAmount
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Onshore Scholarship Type",
                      `${
                        record?.onshoreScholarshipType
                          ? record?.onshoreScholarshipType
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Onshore Material Fee",
                      `${
                        record?.onshoreMaterialFee
                          ? record?.onshoreMaterialFee
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Onshore Application Fee",
                      `${
                        record?.onshoreApplicationFee
                          ? record?.onshoreApplicationFee
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Onshore Additional Fee",
                      `${
                        record?.onshoreAdditionalFee
                          ? record?.onshoreAdditionalFee
                          : "N/A"
                      }`
                    )}

                    <div className="flexCenter">
                      <Button
                        type="primary"
                        style={{ borderRadius: "1rem" }}
                        onClick={() => {
                          setEnrollType("ONSHORE");
                          setIsAddVisasModalVisible(true);
                        }}
                      >
                        Onshore Enroll
                      </Button>
                    </div>
                  </Card>
                  <Card className="clientCard" style={{ width: "100%" }}>
                    {detailFun(
                      "Offshore Fee",
                      `${record?.offShoreFee ? record?.offShoreFee : "N/A"}`
                    )}
                    {detailFun(
                      "Is Offshore Discount?",

                      record?.offshoreIsDiscount
                        ? twoTagResponses(
                            record?.offshoreIsDiscount,
                            true,
                            false
                          )
                        : "N/A"
                    )}
                    {detailFun(
                      "Offshore Discount",
                      `${
                        record?.offshoreDiscountAmount
                          ? record?.offshoreDiscountAmount
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Offshore Discount Type",
                      `${
                        record?.offshoreDiscountType
                          ? record?.offshoreDiscountType
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Is Offshore Scholarship?",

                      record?.offshoreIsScholarship
                        ? twoTagResponses(
                            record?.offshoreIsScholarship,
                            true,
                            false
                          )
                        : "N/A"
                    )}
                    {detailFun(
                      "Offshore Scholarship",
                      `${
                        record?.offshoreScholarshipAmount
                          ? record?.offshoreScholarshipAmount
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Offshore Scholarship Type",
                      `${
                        record?.offshoreScholarshipType
                          ? record?.offshoreScholarshipType
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Offshore Material Fee",
                      `${
                        record?.offshoreMaterialFee
                          ? record?.offshoreMaterialFee
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Offshore Application Fee",
                      `${
                        record?.offshoreApplicationFee
                          ? record?.offshoreApplicationFee
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Offshore Additional Fee",
                      `${
                        record?.offshoreAdditionalFee
                          ? record?.offshoreAdditionalFee
                          : "N/A"
                      }`
                    )}
                    <div className="flexCenter">
                      <Button
                        type="primary"
                        style={{ borderRadius: "1rem" }}
                        onClick={() => {
                          setEnrollType("OFFSHORE");
                          setIsAddVisasModalVisible(true);
                        }}
                      >
                        Offshore Enroll
                      </Button>
                    </div>
                  </Card>
                </>
              ) : null}
            </div>
          )}
          {current === "document" && (
            <>
              {record?.documents?.map((dataObj) => {
                return (
                  <div key={dataObj.id}>
                    <a
                      href={dataObj?.documentUrl}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "1px",
                        flexWrap: "wrap",
                      }}
                    >
                      <FcDocument style={{ fontSize: "30px" }} />
                      <span>{dataObj?.documentName}</span>
                    </a>
                    <Divider
                      orientationMargin="0"
                      style={{ margin: "0.5rem" }}
                    />
                  </div>
                );
              })}
              <div>
                <h3>Offshore Document Checklist</h3>
                {record?.offShoreCheckList?.length > 0 ? (
                  record?.offShoreCheckList?.map((dataObj) => {
                    return (
                      <div
                        key={dataObj.id}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: "0.5rem",
                          flexWrap: "wrap",
                        }}
                      >
                        <ImCheckboxChecked style={{ color: "blue" }} />
                        {dataObj?.item}
                      </div>
                    );
                  })
                ) : (
                  <div style={{ textAlign: "center" }}>
                    No offshore document checklist Available.
                  </div>
                )}
              </div>
              <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
              <div>
                <h3>Onshore Document Checklist</h3>
                {record?.onShoreCheckList?.length > 0 ? (
                  record?.onShoreCheckList?.map((dataObj) => {
                    return (
                      <div
                        key={dataObj.id}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: "0.5rem",
                          flexWrap: "wrap",
                        }}
                      >
                        <ImCheckboxChecked style={{ color: "blue" }} />
                        {dataObj?.item}
                      </div>
                    );
                  })
                ) : (
                  <div style={{ textAlign: "center" }}>
                    No onshore document checklist Available.
                  </div>
                )}
              </div>
            </>
          )}
          {current === "description" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1px",
                flexWrap: "wrap",
              }}
            >
              {record?.description}
            </div>
          )}
        </Card>
      </Card>
      {isAddVisasModalVisible && (
        <AddVisaCases
          navigateCaseList={true}
          fromCounselling={fromCounselling}
          recordData={recordData}
          courseRecord={record}
          instituteRecordForCountry={instituteRecordForCountry}
          enrollType={enrollType}
          isAddVisaCasesModalVisible={isAddVisasModalVisible}
          setIsAddVisaCasesModalVisible={setIsAddVisasModalVisible}
          setIsStartCounsellingModalVisible={setIsStartCounsellingModalVisible}
        />
      )}
      {isStatusHistoryOnlyUpdateModalVisible && (
        <StatusHistoryOnlyUpdate
          recordData={recordData}
          isStatusHistoryOnlyUpdateModalVisible={
            isStatusHistoryOnlyUpdateModalVisible
          }
          setIsStatusHistoryOnlyUpdateModalVisible={
            setIsStatusHistoryOnlyUpdateModalVisible
          }
        />
      )}
    </Drawer>
  );
};

export default CoursesDetail;
