import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
const { Option } = Select;

const AddOthersWorkflow = ({
  countryRecord,
  isAddOthersWorkflowModalVisible,
  setIsAddOthersWorkflowModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const reloadFn = () => {
    dispatch({
      type: "FETCH_OTHERS_WORKFLOW_REQUEST",
      payload: { id: countryRecord.id },
    });
  };
  const addEducationWorkflowSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };

    dispatch({
      type: "ADD_OTHERS_WORKFLOW_REQUEST",
      payload: {
        id: countryRecord.id,
        formData,
        isReloadFn: true,
        reloadFn: reloadFn,
      },
    });
    form.resetFields();
    setIsAddOthersWorkflowModalVisible(false);
  };
  return (
    <Modal
      title="Add Workflow"
      open={isAddOthersWorkflowModalVisible}
      onCancel={() => {
        setIsAddOthersWorkflowModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addEducationWorkflowSubmitHandler}
        colon={true}
        form={form}
      >
        <Form.Item
          label="Name"
          name={"name"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter name!",
            },
          ]}
        >
          <Input placeholder="Name" />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Workflow
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddOthersWorkflow;
