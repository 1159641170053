import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Tag,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { formattedDateTime } from "../../HelperFunction";
const { Option } = Select;

const CounsellingDetails = ({
  record,
  isCounsellingDetailsModalVisible,
  setIsCounsellingDetailsModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  console.log("record", record);
  return (
    <Modal
      title="Appointment Details"
      open={isCounsellingDetailsModalVisible}
      onCancel={() => {
        setIsCounsellingDetailsModalVisible(false);
      }}
      footer={null}
    >
      <div
        style={{
          backgroundColor: "#ddd",
          borderRadius: "1.5rem",
          padding: "0.8rem",
          display: "flex",
          gap: "0.5rem",
          flexDirection: "column",
        }}
      >
        <div className="roundCards">
          <span color="#ccc">Client Name: </span>{" "}
          <span color="#ccc">{record.firstName + " " + record.lastName}</span>
        </div>
        <div className="roundCards">
          <span color="#ccc">Mobile Number: </span>{" "}
          <span color="#ccc">{record.mobile}</span>
        </div>
        <div className="roundCards">
          <span color="#ccc">Email: </span>{" "}
          <span color="#ccc">{record.email}</span>
        </div>
        <div className="roundCards">
          <span color="#ccc">Country: </span>{" "}
          <span color="#ccc">{record.country?.name}</span>
        </div>
        <div className="roundCards">
          <span color="#ccc">Purpose: </span>{" "}
          <span color="#ccc">
            {record.purpose?.purposeName ??
              record.leadResponse?.purposeName ??
              "N/A"}
          </span>
        </div>
        <div className="roundCards">
          <span color="#ccc">Counsellor: </span>{" "}
          <span color="#ccc">
            {record?.leadResponse?.counsellingBooking?.consultant?.emailId ??
              "N/A"}
          </span>
        </div>
        {/* <div className="roundCards">
          <span color="#ccc">counsellor By: </span>{" "}
          <span color="#ccc">{record.counsellor?.emailId}</span>
        </div> */}
        <div className="roundCards">
          <span color="#ccc">Appointment Date/Time: </span>{" "}
          <span color="#ccc">
            {formattedDateTime(record.appointmentDateAndTime)}
          </span>
        </div>
        <div className="roundCards">
          <span color="#ccc">Reminder: </span>{" "}
          <span color="#ccc">
            {formattedDateTime(record.reminderDateAndTime)}
          </span>
        </div>

        <div className="roundCards">
          <span color="#ccc">Added By: </span>{" "}
          <span color="#ccc">{record.addedBy?.emailId}</span>
        </div>
        <div className="roundCards">
          <span color="#ccc">Lead Status: </span>{" "}
          <Tag color={record.leadStatusColorCode}>{record.leadStatus}</Tag>
        </div>
        <div className="roundCards">
          <span color="#ccc">leadBy: </span>{" "}
          <span color="#ccc">{record.leadBy}</span>
        </div>
      </div>
    </Modal>
  );
};

export default CounsellingDetails;

{
  /* <div className="roundCards">
              <span color="#ccc">Referral: </span>{" "}
              {record.referral ? (
                <span
                  color="#ccc"
                  style={{
                    padding: "0.1rem 0.5rem",
                    backgroundColor: "rgba(0,255, 40, 0.9",
                    borderRadius: "12px",
                  }}
                >
                  Yes
                </span>
              ) : (
                <span
                  color="#ccc"
                  style={{
                    padding: "0.1rem 0.5rem",
                    backgroundColor: "rgba(255,0, 40, 0.9",
                    borderRadius: "12px",
                  }}
                >
                  No
                </span>
              )}
            </div> */
}
