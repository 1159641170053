import React from "react";
import { Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import CarouselComponent from "../Login/CarouselComponent";
import logoImage from "./../../Assets/khrouch3.png";

const OTPverification = () => {
  const otpVerificationState = useSelector((state) => state.SignupReducer);
  const dispatch = useDispatch();
  const otpSubmitHandler = (value) => {
    const formData = {
      email: otpVerificationState.email,
      ...value,
    };
    dispatch({ type: "OTP_VERIFICATION_REQUEST", payload: formData });
  };
  return (
    <>
      {otpVerificationState.isOTPverified ? (
        <Navigate to={"/signup"} replace={true} />
      ) : (
        <div style={{ display: "flex" }}>
          <div className="loginContainer">
            <div className="formContainer">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={logoImage} style={{ width: "15%" }} />
              </div>
              <h2 style={{ textAlign: "center" }}>OTP Verification!</h2>
              <p style={{ textAlign: "center", fontSize: "16px" }}>
                A text message with 6-digit verification code was just sent to{" "}
                {otpVerificationState.email}
              </p>
              <Form
                layout="vertical"
                className="loginForm"
                onFinish={otpSubmitHandler}
              >
                <Form.Item
                  name={"otp"}
                  rules={[
                    {
                      required: true,
                      message: "Please enter your OTP!",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="6-digit code"
                    size="large"
                    style={{ borderRadius: "25px" }}
                  />
                </Form.Item>
                {/* wrapperCol={{ offset: 9, span: 6 }} */}
                <Form.Item>
                  <div
                    className="flexColumnwithoutStyle"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Link
                      style={{
                        textAlign: "center",
                        marginBottom: "0.8rem",
                        width: "40%",
                      }}
                      onClick={() => {
                        dispatch({
                          type: "RESEND_OTP_REQUEST",
                          payload: { userName: otpVerificationState.email },
                        });
                      }}
                    >
                      Resend Code
                    </Link>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "40%", borderRadius: "2rem" }}
                    >
                      Verify
                    </Button>
                  </div>
                </Form.Item>
                <div style={{ textAlign: "center" }}>
                  Didn't receive the code?
                  <Link
                    to={"/register"}
                    onClick={() => {
                      dispatch({ type: "CHANGE_REGISTERED_STATUS" });
                    }}
                  >
                    {" "}
                    Try another email address
                  </Link>
                </div>
              </Form>
            </div>
          </div>
          <div style={{ width: "30%" }}>
            <CarouselComponent />
          </div>
        </div>
      )}
    </>
  );
};

export default OTPverification;
