import { EditOutlined } from "@ant-design/icons";
import { Button, Card, Select, Tooltip } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import AddEmailTemplate from "./AddEmailTemplate";
const { Option } = Select;

const EmailTemplate = () => {
  const store = useSelector((state) => {
    return {
      emailTemplateState: state.OrganizationSettingsReducer,
    };
  });
  const dispatch = useDispatch();
  const [isAddEmailTemplateVisible, setIsAddEmailTemplateVisible] =
    useState(false);
  const [templateType, setTemplateType] = useState("");
  const [templateText, setTemplateText] = useState("");
  const successPayloadFn = (data) => {
    console.log("data from compose mail", data?.data?.templateText);
    // setQuillValue(data?.data?.templateText);
  };
  console.log("from email template reducer", store.emailTemplateState);
  useEffect(() => {
    dispatch({
      type: "FETCH_LEAD_EMAIL_TEMPLATE_REQUEST",
      payload: "LEAD",
      isSuccessFn: true,
      successPayload: successPayloadFn,
    });
    dispatch({
      type: "FETCH_CASE_EMAIL_TEMPLATE_REQUEST",
      payload: "CASE",
      successPayload: successPayloadFn,
    });
    dispatch({
      type: "FETCH_APPOINTMENT_EMAIL_TEMPLATE_REQUEST",
      payload: "APPOINTMENT",
      isSuccessFn: true,

      successPayload: successPayloadFn,
    });
    dispatch({
      type: "FETCH_CLIENT_EMAIL_TEMPLATE_REQUEST",
      payload: "CLIENT",
      isSuccessFn: true,

      successPayload: successPayloadFn,
    });
  }, []);
  return (
    <Card bordered={false} loading={store.emailTemplateState?.isLoading}>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1rem",
        }}
      >
        <Card
          style={{
            width: "30%",
            borderRadius: "0.5rem",
            marginTop: 16,
          }}
          title={
            store.emailTemplateState?.leadEmailTemplates?.templateType ?? "LEAD"
          }
          extra={
            <Tooltip title="Edit" placement="bottom">
              <a>
                <EditOutlined
                  key="edit"
                  onClick={() => {
                    setTemplateType("LEAD");
                    setTemplateText(
                      store.emailTemplateState?.leadEmailTemplates?.templateText
                    );
                    setIsAddEmailTemplateVisible(true);
                  }}
                />
              </a>
            </Tooltip>
          }
        >
          <div style={{ fontSize: "12px" }}>
            {store.emailTemplateState?.leadEmailTemplates?.templateText?.replace(
              /(<([^>]+)>)/gi,
              ""
            )}
          </div>
        </Card>
        <Card
          style={{
            width: "30%",
            borderRadius: "0.5rem",
            marginTop: 16,
          }}
          title={
            store.emailTemplateState?.caseEmailTemplates?.templateType ?? "CASE"
          }
          extra={
            <Tooltip title="Edit" placement="bottom">
              <a>
                <EditOutlined
                  key="edit"
                  onClick={() => {
                    setTemplateType("CASE");
                    setTemplateText(
                      store.emailTemplateState?.caseEmailTemplates?.templateText
                    );
                    setIsAddEmailTemplateVisible(true);
                  }}
                />
              </a>
            </Tooltip>
          }
        >
          <div style={{ fontSize: "12px" }}>
            {store.emailTemplateState?.caseEmailTemplates?.templateText?.replace(
              /(<([^>]+)>)/gi,
              ""
            )}
          </div>
        </Card>
        <Card
          style={{
            width: "30%",
            borderRadius: "0.5rem",
            marginTop: 16,
          }}
          title={
            store.emailTemplateState?.appointmentEmailTemplates?.templateType ??
            "APPOINTMENT"
          }
          extra={
            <Tooltip title="Edit" placement="bottom">
              <a>
                <EditOutlined
                  key="edit"
                  onClick={() => {
                    setTemplateType("APPOINTMENT");
                    setTemplateText(
                      store.emailTemplateState?.appointmentEmailTemplates
                        ?.templateText
                    );
                    setIsAddEmailTemplateVisible(true);
                  }}
                />
              </a>
            </Tooltip>
          }
        >
          <div style={{ fontSize: "12px" }}>
            {store.emailTemplateState?.appointmentEmailTemplates?.templateText?.replace(
              /(<([^>]+)>)/gi,
              ""
            )}
          </div>
        </Card>
        <Card
          style={{
            width: "30%",
            borderRadius: "0.5rem",
            marginTop: 16,
          }}
          title={
            store.emailTemplateState?.clientEmailTemplates?.templateType ??
            "CLIENT"
          }
          extra={
            <Tooltip title="Edit" placement="bottom">
              <a>
                <EditOutlined
                  key="edit"
                  onClick={() => {
                    setTemplateType("CLIENT");
                    setTemplateText(
                      store.emailTemplateState?.clientEmailTemplates
                        ?.templateText
                    );
                    setIsAddEmailTemplateVisible(true);
                  }}
                />
              </a>
            </Tooltip>
          }
        >
          <div style={{ fontSize: "12px" }}>
            {store.emailTemplateState?.clientEmailTemplates?.templateText?.replace(
              /(<([^>]+)>)/gi,
              ""
            )}
          </div>
        </Card>
      </div>
      {isAddEmailTemplateVisible && (
        <AddEmailTemplate
          emailTemplateType={templateType}
          templateText={templateText}
          isAddEmailTemplateModalVisible={isAddEmailTemplateVisible}
          setIsAddEmailTemplateModalVisible={setIsAddEmailTemplateVisible}
        />
      )}
    </Card>
  );
};

export default EmailTemplate;

{
  /* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          border: "1px solid red",
        }}
      >
        <Select
          style={{ width: "10rem" }}
          onChange={(e) => {
            setTemplateType(e);
          }}
        >
          <Option value="LEAD">LEAD</Option>
          <Option value="CASE">CASE</Option>
          <Option value="APPOINTMENT">APPOINTMENT</Option>
        </Select>
        <Button
          type="primary"
          onClick={() => {
            console.log("clicked");
            setIsAddEmailTemplateVisible(true);
          }}
        >
          Add
        </Button>
      </div> */
}
