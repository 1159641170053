import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Input,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { MdOutlineDisabledVisible } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import subAgent from "./../../Assets/account.png";
import activeSubAgent from "./../../Assets/add-contact.png";
import SiderDemo from "../../Components/Layout/SiderDemo";
import AddSubAgent from "./AddSubAgent";
import UpdateSubAgent from "./UpdateSubAgent";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
import { BiBlock } from "react-icons/bi";
import { AiOutlinePoweroff } from "react-icons/ai";
const { Search } = Input;

const columns = [
  {
    title: "Sub Agent",
    dataIndex: "name",
    align: "center",
  },
  {
    title: "Email",
    dataIndex: "email",
    align: "center",
  },
  {
    title: "Phone Number",
    dataIndex: "phoneNumber",
    align: "center",
  },
  {
    title: "Mobile Number",
    dataIndex: "mobileNumber",
    align: "center",
  },
  {
    title: "Commission Share",
    dataIndex: "commissionShare",
    align: "center",
  },
  {
    title: "Is Percentage",
    dataIndex: "isPercentage",
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    align: "center",
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
  },
];

const SubAgent = () => {
  const subAgentState = useSelector((state) => state.SubAgentReducer);
  const [searchValue, setSearchValue] = useState("");

  const [isAddSubAgentModalVisible, setIsAddSubAgentModalVisible] =
    useState(false);
  const [isUpdateSubAgentModalVisible, setIsUpdateSubAgentModalVisible] =
    useState(false);
  const [record, setRecord] = useState({});
  const [subAgentType, setSubAgentType] = useState("get-all-sub-agent");

  const dispatch = useDispatch();
  const onSearch = (e) => {
    console.log("This is e from search", e);
    if (subAgentType === "get-all-active-sub-agent") {
      dispatch({
        type: "SEARCH_ACTIVE_SUB_AGENT_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          searchKey: { keyword: e },
        },
      });
    } else if (subAgentType === "get-all-sub-agent") {
      dispatch({
        type: "SEARCH_SUB_AGENT_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          searchKey: { keyword: e },
        },
      });
    }
  };
  useEffect(() => {
    if (subAgentType === "get-all-active-sub-agent") {
      dispatch({
        type: "FETCH_ACTIVE_SUBAGENT_REQUEST",
      });
    } else {
      dispatch({ type: "FETCH_SUBAGENT_REQUEST" });
    }
  }, [subAgentType]);
  useEffect(() => {
    if (
      isAddSubAgentModalVisible === false ||
      isUpdateSubAgentModalVisible === false
    ) {
      dispatch({ type: "FETCH_SUBAGENT_REQUEST" });
    }
  }, [isAddSubAgentModalVisible, isUpdateSubAgentModalVisible]);
  let data;
  if (subAgentType === "get-all-sub-agent") {
    data = subAgentState.subAgents?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        name: dataObj.name,
        email: dataObj.email,
        phoneNumber: dataObj.phoneNumber,
        mobileNumber: dataObj.mobileNumber,
        commissionShare: dataObj.commissionShare,
        isPercentage: dataObj.isPercentage ? (
          <Tag color="#00ff00aa">YES</Tag>
        ) : (
          <Tag color="#ff0000aa">NO</Tag>
        ),
        status:
          dataObj.status === "ACTIVE" ? (
            <p className="greenTag">{dataObj.status}</p>
          ) : (
            <p className="redTag">{dataObj.status}</p>
          ),
        createdBy: dataObj.createdBy?.emailId,
        actions: (
          <Space size="middle">
            <Tooltip title="Update subAgent">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsUpdateSubAgentModalVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <EditOutlined />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Delete subAgent">
              <Popconfirm
                title="Are you sure to delete this subAgent?"
                onConfirm={() => {
                  dispatch({
                    type: "DELETE_SUBAGENT_REQUEST",
                    payload: { id: dataObj.id },
                  });
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <div className="bordered">
                    <DeleteOutlined />
                  </div>
                </a>{" "}
              </Popconfirm>
            </Tooltip>
            {dataObj.status === "INACTIVE" && (
              <Tooltip title="Activate Sub Agent">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus =
                        dataObj.status === "INACTIVE" ? "active" : "inactive";
                      dispatch({
                        type: "TOGGLE_SUBAGENT_STATUS_REQUEST",
                        payload: { id: dataObj.id, actionStatus },
                      });
                    }}
                  >
                    <AiOutlinePoweroff />
                  </div>
                </a>
              </Tooltip>
            )}
            {dataObj.status === "ACTIVE" && (
              <Tooltip title="Deactivate Sub Agent">
                <a style={{ color: "red" }}>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus =
                        dataObj.status === "INACTIVE" ? "active" : "inactive";
                      dispatch({
                        type: "TOGGLE_SUBAGENT_STATUS_REQUEST",
                        payload: { id: dataObj.id, actionStatus },
                      });
                    }}
                  >
                    <BiBlock />
                  </div>
                </a>
              </Tooltip>
            )}
          </Space>
        ),
      };
    });
  } else {
    data = subAgentState.activeSubAgents?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        name: dataObj.name,
        email: dataObj.email,
        phoneNumber: dataObj.phoneNumber,
        mobileNumber: dataObj.mobileNumber,
        commissionShare: dataObj.commissionShare,
        isPercentage: dataObj.isPercentage ? (
          <Tag color="#00ff00aa">YES</Tag>
        ) : (
          <Tag color="#ff0000aa">NO</Tag>
        ),
        status:
          dataObj.status === "ACTIVE" ? (
            <p className="greenTag">{dataObj.status}</p>
          ) : (
            <p className="redTag">{dataObj.status}</p>
          ),
        createdBy: dataObj.createdBy,
        actions: (
          <Space size="middle">
            <Tooltip title="Update subAgent">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsUpdateSubAgentModalVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <EditOutlined />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Delete subAgent">
              <Popconfirm
                title="Are you sure to delete this subAgent?"
                onConfirm={() => {
                  dispatch({
                    type: "DELETE_SUBAGENT_REQUEST",
                    payload: { id: dataObj.id },
                  });
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <div className="bordered">
                    <DeleteOutlined />
                  </div>
                </a>{" "}
              </Popconfirm>
            </Tooltip>
            {dataObj.status === "INACTIVE" && (
              <Tooltip title="Activate Sub Agent">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus =
                        dataObj.status === "INACTIVE" ? "active" : "inactive";
                      dispatch({
                        type: "TOGGLE_SUBAGENT_STATUS_REQUEST",
                        payload: { id: dataObj.id, actionStatus },
                      });
                    }}
                  >
                    <AiOutlinePoweroff />
                  </div>
                </a>
              </Tooltip>
            )}
            {dataObj.status === "ACTIVE" && (
              <Tooltip title="Deactivate Sub Agent">
                <a style={{ color: "red" }}>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus =
                        dataObj.status === "INACTIVE" ? "active" : "inactive";
                      dispatch({
                        type: "TOGGLE_SUBAGENT_STATUS_REQUEST",
                        payload: { id: dataObj.id, actionStatus },
                      });
                    }}
                  >
                    <BiBlock />
                  </div>
                </a>
              </Tooltip>
            )}
          </Space>
        ),
      };
    });
  }
  console.log("from subAgent state", subAgentState);
  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div className="leadFlexRow">
          <button
            className="button"
            onClick={() => {
              setIsAddSubAgentModalVisible(true);
            }}
          >
            <span>Add Sub Agent</span>
          </button>
          <Segmented
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <Avatar src={subAgent} size={20} shape="square" />
                    <div>All Sub Agent</div>
                  </div>
                ),
                value: "get-all-sub-agent",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <Avatar src={activeSubAgent} size={20} shape="square" />
                    <div>Active Sub Agent</div>
                  </div>
                ),
                value: "get-all-active-sub-agent",
              },
            ]}
            onChange={(value) => {
              setSubAgentType(value);
              console.log(value);
            }}
          />
          <Search
            className="search"
            style={{
              width: "30%",
              borderRadius: "10px ! important",
            }}
            placeholder="Search for sub agent"
            allowClear
            size="large"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              onSearch(e.target.value);
            }}
          />
        </div>
        <div style={{ marginTop: "4rem" }}>
          {subAgentState.isLoading ? (
            <TableSkeleton />
          ) : (
            <Table bordered columns={columns} dataSource={data} />
          )}
        </div>
      </div>
      <AddSubAgent
        isAddSubAgentModalVisible={isAddSubAgentModalVisible}
        setIsAddSubAgentModalVisible={setIsAddSubAgentModalVisible}
      />
      <UpdateSubAgent
        record={record}
        isUpdateSubAgentModalVisible={isUpdateSubAgentModalVisible}
        setIsUpdateSubAgentModalVisible={setIsUpdateSubAgentModalVisible}
      />
    </SiderDemo>
  );
};

export default SubAgent;
