import React, { useEffect } from "react";
import { Button, DatePicker, Divider, Form, Input, Radio, Select } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AiOutlinePlus } from "react-icons/ai";
import moment from "moment";
import axios from "axios";
import QuoteSummary from "./QuoteSummary";
import AddCountry from "../Leads/Country/AddCountry";
import { getCookie } from "../../Helpers/FrontendHelper";
import AddAboutUs from "../Leads/AboutUs/AddAboutUs";
import { date } from "../../HelperFunction";
import AddQuotation from "./AddQuotation";
import AddQuotationSource from "./QuotationSource/AddQuotationSource";
const { Option } = Select;
const { TextArea } = Input;

const QuotationQuotationInfo = ({
  quotationData,
  setQuotationData,
  current,
  setCurrent,
  addQuotationSubmitHandler,
  setIsAddQuotationModalVisible,
}) => {
  const store = useSelector((state) => {
    return {
      leadState: state.LeadReducer,
      caseState: state.VisasReducer,
      quoteState: state.QuotationReducer,
    };
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isAddAboutUsModalVisible, setIsAddAboutUsModalVisible] =
    useState(false);
  const [
    isAddQuotationSourceModalVisible,
    setIsAddQuotationSourceModalVisible,
  ] = useState(false);

  useEffect(() => {
    dispatch({ type: "FETCH_QUOTATION_SOURCE_REQUEST" });
  }, []);

  const visaSuccessFunction = (response) => {
    console.log("defaultSelectedCurrency", response.data);
    setQuotationData((previousData) => {
      return {
        ...previousData,
        currency: response?.data?.defaultSelectedCurrency,
        countryOfResidence: response?.data?.defaultCountry?.name,
      };
    });
  };
  const addQuotationQuotationInfoSubmitHandler = () => {
    addQuotationSubmitHandler();
    // setIsAddQuotationModalVisible(false);
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexFlow: "wrap",
          gap: "1rem",
          alignItems: "stretch",
        }}
      >
        <div style={{ width: "100%", display: "flex", flex: 1 }}>
          <Form
            layout="vertical"
            onFinish={addQuotationQuotationInfoSubmitHandler}
            colon={true}
            form={form}
            className="drawerStyle"
            fields={[
              {
                name: ["quoteStatus"],
                value: quotationData.quoteStatus,
              },
              {
                name: ["quoteSource"],
                value: quotationData.quoteSourceName,
              },
              {
                name: ["aboutUs"],
                value: quotationData.aboutUsName,
              },
              {
                name: ["quoteExpiryDate"],
                value: quotationData.quoteExpiryDate
                  ? moment.utc(quotationData.quoteExpiryDate).local()
                  : null,
              },
              {
                name: ["modeOfContact"],
                value: quotationData.modeOfContact,
              },
            ]}
          >
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Quote Status"
                name={"quoteStatus"}
                style={{ width: "100%" }}
              >
                <Select
                  onChange={(e) => {
                    setQuotationData((previousData) => {
                      return {
                        ...previousData,
                        quoteStatus: e,
                      };
                    });
                  }}
                >
                  <Option value="QUOTE_DRAFT">Quote Draft</Option>
                  <Option value="QUOTE_SENT">Quote Sent</Option>
                  <Option value="WIN">Win</Option>
                  <Option value="LOSE">Lose</Option>
                </Select>
              </Form.Item>
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.3rem", width: "100%" }}
              >
                <Form.Item
                  label="Quote Source"
                  name={"quoteSource"}
                  style={{ width: "100%" }}
                >
                  <Select>
                    {store.quoteState?.quotationSources?.data?.map(
                      (dataObj) => {
                        return (
                          <Option key={dataObj.id}>
                            <div
                              onClick={(e) => {
                                setQuotationData((previousData) => {
                                  return {
                                    ...previousData,
                                    quoteSource: dataObj.id,
                                    quoteSourceName: dataObj.sourceName,
                                  };
                                });
                              }}
                              className={"selectOptionDiv"}
                            >
                              {dataObj.sourceName}
                            </div>
                          </Option>
                        );
                      }
                    )}
                  </Select>
                </Form.Item>
                <a
                  className="plusButton"
                  onClick={() => {
                    setIsAddQuotationSourceModalVisible(true);
                  }}
                >
                  <AiOutlinePlus className="iconColor" />
                </a>
              </div>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.3rem", width: "100%" }}
              >
                <Form.Item
                  label="About Us"
                  name={"aboutUs"}
                  style={{ width: "100%" }}
                >
                  <Select>
                    {store.leadState?.leadsDropdown?.aboutUs?.map((dataObj) => {
                      return (
                        <Option key={dataObj.id}>
                          <div
                            onClick={(e) => {
                              setQuotationData((previousData) => {
                                return {
                                  ...previousData,
                                  aboutUs: dataObj.id,
                                  aboutUsName: dataObj.aboutUs,
                                };
                              });
                            }}
                            className={"selectOptionDiv"}
                          >
                            {dataObj.aboutUs}
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <a
                  className="plusButton"
                  onClick={() => {
                    setIsAddAboutUsModalVisible(true);
                  }}
                >
                  <AiOutlinePlus className="iconColor" />
                </a>
              </div>
              <Form.Item
                label="Quote Expiry Date"
                name={"quoteExpiryDate"}
                style={{ width: "100%" }}
              >
                <DatePicker
                  format={"DD-MM-YYYY"}
                  onChange={(e) => {
                    setQuotationData((previousData) => {
                      return {
                        ...previousData,
                        quoteExpiryDate: e,
                      };
                    });
                  }}
                />
              </Form.Item>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Mode of Contact"
                name={"modeOfContact"}
                style={{ width: "100%" }}
              >
                <Select
                  onChange={(e) => {
                    setQuotationData((previousData) => {
                      return {
                        ...previousData,
                        modeOfContact: e,
                      };
                    });
                    // setMaritalStatus(e);
                  }}
                >
                  <Option value="EMAIL">Email</Option>
                  <Option value="PHONE">Phone</Option>
                </Select>
              </Form.Item>
            </div>

            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "flex-end", gap: "1rem" }}
            >
              <div
                className="flexRowWithoutStyle"
                style={{ justifyContent: "space-between", gap: "1rem" }}
              >
                {current === 2 && (
                  <Form.Item>
                    <Button
                      onClick={() => {
                        setCurrent(1);
                      }}
                    >
                      Back
                    </Button>
                  </Form.Item>
                )}
                <Form.Item name="caseSubmit">
                  <Button
                    name="caseSubmit"
                    block
                    type="primary"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>

          {isAddAboutUsModalVisible && (
            <AddAboutUs
              isAddAboutUsModalVisible={isAddAboutUsModalVisible}
              setIsAddAboutUsModalVisible={setIsAddAboutUsModalVisible}
            />
          )}
          {isAddQuotationSourceModalVisible && (
            <AddQuotationSource
              isAddQuotationSourceModalVisible={
                isAddQuotationSourceModalVisible
              }
              setIsAddQuotationSourceModalVisible={
                setIsAddQuotationSourceModalVisible
              }
            />
          )}
        </div>
        <div
          style={{
            width: "30%",
          }}
        >
          <QuoteSummary current={current} quotationData={quotationData} />
        </div>
      </div>
    </>
  );
};

export default QuotationQuotationInfo;
