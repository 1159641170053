import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
const { Option } = Select;

const AddWorkFlowStatus = ({
  workflowRecord,
  statusList,
  setStatusList,
  isAddWorkFlowStatusModalVisible,
  setIsAddWorkFlowStatusModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const successFn = (response) => {
    console.log("data from fetch workflow by id response", response);
    setStatusList(response?.data?.data);
  };
  const addWorkFlowStatusSubmitHandler = (value) => {
    console.log("from workflow status input", value);
    const formData = {
      ...value,
    };
    dispatch({
      type: "ADD_WORKFLOW_STATUS_REQUEST",
      payload: { id: workflowRecord.id, formData },
      payload2: successFn,
    });
    form.resetFields();
    setIsAddWorkFlowStatusModalVisible(false);
  };
  return (
    <Modal
      title="Add Workflow Status"
      open={isAddWorkFlowStatusModalVisible}
      onCancel={() => {
        setIsAddWorkFlowStatusModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addWorkFlowStatusSubmitHandler}
        colon={true}
        form={form}
      >
        <Form.Item
          label="Work Flow Status"
          name={"statusName"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter a workflow name!",
            },
          ]}
        >
          <Input placeholder="Enter Work Flow Name" />
        </Form.Item>
        <Form.Item
          label="Task"
          name={"tasks"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter a task!",
            },
          ]}
        >
          <Input placeholder="Enter a Task" />
        </Form.Item>
        <Form.Item
          label="Color Code"
          name={"colorCode"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter color code for the status!",
            },
          ]}
        >
          <Input
            type="color"
            placeholder="Hex value for color code"
            onChange={(e) => {
              console.log("From workflow status color code", e);
            }}
          />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Workflow Status
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddWorkFlowStatus;
