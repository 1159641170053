import React, { useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Image,
  Input,
  Modal,
  Radio,
  Select,
  Tag,
  Timeline,
  TimePicker,
} from "antd";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import client from "./../../Assets/client.png";

import {
  date,
  dateAndTime,
  formattedDate,
  formattedDateTime,
  fourTagResponses,
  getTimeZone,
  time,
  YYYYMMDD,
} from "../../HelperFunction";
import { AiOutlineMail, AiOutlinePhone, AiOutlinePlus } from "react-icons/ai";
import AddSalesRep from "../Visas/AddSalesRep";
import AddEmployee from "../EmployeeManager/AddEmployee";
import { GoLocation } from "react-icons/go";
const { Option } = Select;
const { TextArea } = Input;

const StatusHistory = ({
  appointmentData,
  leadData,
  type,
  appointmentRecord,
  record,
  isStatusHistoryModalVisible,
  setIsStatusHistoryModalVisible,
}) => {
  const store = useSelector((state) => {
    return {
      timingDropdownState: state.AppointmentsReducer,
      addCounsellingState: state.LeadReducer,
      leadState: state.LeadReducer,
    };
  });
  const dispatch = useDispatch();
  const [isAddConsultantModalVisible, setIsAddConsultantModalVisible] =
    useState(false);
  const [leadStatus, setLeadStatus] = useState();
  useEffect(() => {
    if (isStatusHistoryModalVisible === true) {
      dispatch({ type: "FETCH_LEAD_BY_ID_REQUEST", payload: record?.leadId });
      dispatch({ type: "FETCH_LEADS_DROPDOWN_REQUEST" });
      setStatusUpdateData({
        salesRepresentativeId: record?.salesRepId,
        salesRepresentativeName: record?.salesRepName,
        updateWeightage: record?.weightage,
        reminderTime: record?.reminderDateAndTime,
        reminderDate: record?.reminderDateAndTime,
        nextAction: "",
        remarks: "",
        leadStatusId: record?.leadStatus?.id,
        leadStatusName: record?.leadStatus?.statusName,

        bookingStatus:
          appointmentRecord?.appointmentStatus === "CONFIRMED" ||
          appointmentRecord?.appointmentStatus === "COMPLETED"
            ? true
            : false,
        counsellorName: appointmentRecord?.assignedCounsellor?.fullName,
        counsellorId: appointmentRecord?.assignedCounsellor?.id,
        appointmentDate: appointmentRecord?.appointmentDate
          ? appointmentRecord?.appointmentDate
          : null,
        sessionTime: appointmentRecord?.counsellingBooking?.sessionTime,
        todayUpdate: null,
      });
    }
  }, [isStatusHistoryModalVisible]);
  const [isAddSalesRepModalVisible, setIsAddSalesRepModalVisible] =
    useState(false);

  const [statusUpdateData, setStatusUpdateData] = useState({
    salesRepresentativeId: record?.salesRepId,
    salesRepresentativeName: record?.salesRepName,
    updateWeightage: record?.weightage,
    reminderTime: record?.reminderDateAndTime,
    reminderDate: record?.reminderDateAndTime,
    remarks: "",
    nextAction: "",
    leadStatusId: record?.leadStatus?.id,
    leadStatusName: record?.leadStatus?.statusName,

    bookingStatus:
      appointmentRecord?.appointmentStatus === "CONFIRMED" ||
      appointmentRecord?.appointmentStatus === "COMPLETED"
        ? true
        : false,
    counsellorName: appointmentRecord?.assignedCounsellor?.fullName,
    counsellorId: appointmentRecord?.assignedCounsellor?.id,
    appointmentDate: appointmentRecord?.appointmentDate,
    sessionTime: appointmentRecord?.counsellingBooking?.sessionTime,
    todayUpdate: null,
  });
  const [isCouncelling, setIsCouncelling] = useState(false);
  const [counsellorId, setCounsellorId] = useState();
  const [appointmentDate, setAppointmentDate] = useState();
  const [sessionTime, setSessionTime] = useState();
  useEffect(() => {
    if (
      statusUpdateData.counsellorId &&
      statusUpdateData.appointmentDate &&
      statusUpdateData.sessionTime &&
      statusUpdateData.bookingStatus
    ) {
      dispatch({
        type: "FETCH_TIMING_DROPDOWN_REQUEST",
        payload: {
          counsellorId: statusUpdateData.counsellorId,
          sessionTime: statusUpdateData.sessionTime,

          values: {
            appointmentDate: moment
              .utc(statusUpdateData.appointmentDate)
              .local()
              .format("YYYY-MM-DD"),
            timeOffset: getTimeZone(),
          },
          id: record.id,
        },
      });
    }
  }, [
    statusUpdateData.counsellorId,
    statusUpdateData.appointmentDate,
    statusUpdateData.sessionTime,
    statusUpdateData.bookingStatus,
  ]);

  const [form] = Form.useForm();
  const addStatusHistoryUpdateSubmitHandler = (value) => {
    console.log("from ", value);
    const reminderDateAndTime = dateAndTime(
      statusUpdateData.reminderDate,
      statusUpdateData.reminderTime
    );
    const bookingDate = isCouncelling ? date(value.appointmentDate) : null;
    const bookingDay =
      statusUpdateData.leadStatusName === "BOOK_AN_APPOINTMENT"
        ? moment(statusUpdateData.appointmentDate).format("dddd").toUpperCase()
        : null;
    // const appointmentDateTime = dateAndTime(
    //   value.appointmentDate,
    //   value.appointmentTime
    // );
    const appointmentDate = date(value.appointmentDate);
    const isFromLead = type === "LEAD" ? true : false;
    const isFromAppointment = type === "APPOINTMENT" ? true : false;
    const {
      firstName,
      lastName,
      email,
      mobile,
      leadId,
      purposeId,
      purposeName,
      aboutUsId,
      contactId,
      countryId,
      countryName,
      leadSourceId,
      referral,
      sendByEmail,
      referredById,
      bookingStatus,
      wantCounselling,
      description,
      leadSourceName,
      leadBy,
      leadStatus,
      customerResponse,
      appointmentStatus,
      locationstatus,
      weightage,
    } = record;
    const formData = {
      firstName,
      lastName,
      contactId,
      email,
      mobile,
      purposeId: purposeId,
      aboutUsId: aboutUsId,
      countryId: countryId,
      leadSourceId: leadSourceId,
      referral: referral ? true : false,
      sendByEmail: sendByEmail,
      bookingStatus: bookingStatus ? true : false,
      referredById: referredById,
      weightage: weightage ?? "GOOD",
      leadId,
      isFromLead,
      // institute: { id: null, name: null },
      // state: { id: null, name: null },
      // country: { id: countryId, name: countryName },
      // purposeId: { id: purposeId, purposeName: purposeName },
      // addedBy: { id: null, emailId: null },
      // counsellingBooking: {
      //   bookingDate: null,
      //   bookingDay: null,
      //   startTime: null,
      //   endTime: null,
      //   consultant: null,
      // },
      // counsellor: counsellorId,
      // weightage: null,
      // counsellingTypeEnum: ["ONSHORE", "OFFSHORE"],
      // appointmentDateAndTime: appointmentDateTime,
      // reminderDateAndTime: reminderDateAndTime,
      // mobileNumber: mobile,
      // phone: null,
      // note: null,
      // isActive: null,
      // leadStatus: leadStatus,
      // leadStatusColorCode: "#0037B3",
      // leadSource: leadSourceName,
      // leadBy,
      // leadResponse: store.leadState.leadById,
      // query: "N/A",
      // requirement: "N/A",
      // isFromAppointment,
      // wantCounselling,
      // leadStatus: leadStatus,
      // customerResponse: customerResponse,
      // appointmentStatus,
      // locationstatus,
      // appointmentDateTime: appointmentDateTime,

      // aboutUsId,
      // countryId,
      // leadSourceId,
      // referral,
      // sendByEmail,
      // referredById,
      // bookingStatus,
      // description,
      ...value,
      consultantId:
        statusUpdateData.leadStatusName === "BOOK_AN_APPOINTMENT"
          ? statusUpdateData.counsellorId
          : null,
      salesRepresentativeId: statusUpdateData.salesRepresentativeId,
      leadStatusId: statusUpdateData.leadStatusId,
      reminderDateAndTime: reminderDateAndTime,
      bookingDate:
        statusUpdateData.bookingStatus &&
        statusUpdateData.leadStatusName === "BOOK_AN_APPOINTMENT"
          ? date(statusUpdateData.appointmentDate)
          : null,
      bookingDay: bookingDay,
      appointmentDate:
        statusUpdateData.appointmentDate &&
        statusUpdateData.leadStatusName === "BOOK_AN_APPOINTMENT"
          ? date(statusUpdateData.appointmentDate)
          : null,
      assignCounsellor:
        statusUpdateData.leadStatusName === "BOOK_AN_APPOINTMENT"
          ? statusUpdateData.bookingStatus
          : null,
    };
    if (type === "APPOINTMENT") {
      formData.id = appointmentRecord.id;
    }

    dispatch({
      type: "ADD_LEAD_STATUS_UPDATE_REQUEST",
      payload: {
        id: record.leadId,
        formData,
        isReload: true,
        reloadFn: reloadFn,
      },
    });
    dispatch({ type: "CLEAR_AVAILABLE_DATE_AND_TIME" });
    setLeadStatus(null);
    form.resetFields();
    setIsStatusHistoryModalVisible(false);
  };
  const reloadFn = (response) => {
    dispatch({ type: "FETCH_LEAD_BY_ID_REQUEST", payload: record?.leadId });

    dispatch({
      type: "FETCH_APPOINTMENT_BY_ID_REQUEST",
      payload: { appointmentId: appointmentRecord?.id },
    });

    if (type !== "LEAD") {
      dispatch({
        type: "FETCH_APPOINTMENT_BY_LEAD_ID_REQUEST",
        payload: { leadId: record?.id },
      });
    }
  };
  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };
  const [current, setCurrent] = useState("appointmentDetails");

  const clientDetailsTabList = [
    {
      key: "appointmentDetails",
      tab: "Appointment Details",
    },
  ];
  console.log("record for status history", record);
  console.log("statusUpdateData for status history", statusUpdateData);
  console.log("appointmentRecord for status history", appointmentRecord);
  console.log("record form counsellor", counsellorId);
  console.log("statusData state form store.leadState", store.leadState);

  return (
    <Drawer
      title="Status History"
      open={isStatusHistoryModalVisible}
      onClose={() => {
        setIsStatusHistoryModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <div className="drawerStyle" style={{ display: "flex", gap: "0.3rem" }}>
        {/* {type === "APPOINTMENT" && (
          <Card style={{ borderRadius: "0.5rem", width: "45%" }}>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "1rem", alignItems: "flex-start" }}
            >
              {appointmentRecord?.leadResponse?.customerResponse?.photo ? (
                <Image
                  src={appointmentRecord?.leadResponse?.customerResponse?.photo}
                  className={"clientImage"}
                />
              ) : (
                <Image src={client} className={"clientImage"} />
              )}
              <div
                className="flexColumnWithoutStyle"
                style={{
                  justifyContent: "flex-start",
                  // alignItems: "flex-start",
                  width: "100%",
                  gap: "0.5rem",
                }}
              >
                <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                  {`${appointmentRecord?.firstName ?? ""} ${
                    appointmentRecord?.lastName ?? ""
                  }`}
                </div>
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "0.4rem", color: "#aaa", flexWrap: "wrap" }}
                >
                  {appointmentRecord?.mobile && (
                    <span className="flexRowWithGap">
                      <AiOutlinePhone
                        style={{
                          fontSize: "20",
                        }}
                      />{" "}
                      {appointmentRecord?.mobile
                        ? appointmentRecord?.mobile
                        : "N/A"}
                    </span>
                  )}
                  {appointmentRecord?.leadResponse?.customerResponse
                    ?.address && (
                    <span className="flexRowWithGap">
                      <GoLocation
                        style={{
                          fontSize: "16",
                        }}
                      />{" "}
                      {appointmentRecord?.leadResponse?.customerResponse
                        ?.address
                        ? appointmentRecord?.leadResponse?.customerResponse
                            ?.address
                        : "N/A"}
                    </span>
                  )}
                  {appointmentRecord?.email && (
                    <span className="flexRowWithGap">
                      <AiOutlineMail
                        style={{
                          fontSize: "16",
                        }}
                      />{" "}
                      {appointmentRecord?.email
                        ? appointmentRecord?.email
                        : "N/A"}
                    </span>
                  )}
                </div>
                <div>
                  <div>
                    <span className="profileTitle">Campaign:</span>
                    <span>
                      {appointmentRecord?.leadResponse?.customerResponse
                        ?.campaign?.name ?? "N/A"}
                    </span>
                  </div>
                </div>

                <div className="flexColumnWithoutStyle">
                  <h4>{appointmentRecord?.country?.name}</h4>
                  <div>{appointmentRecord?.levelOfCollege?.name}</div>
                  <div>{appointmentRecord?.crisco}</div>
                </div>
              </div>
            </div>
            <Card
              bordered={false}
              tabList={clientDetailsTabList}
              activeTabKey={current}
              onTabChange={(key) => {
                setCurrent(key);
              }}
            >
              {current === "appointmentDetails" && (
                <>
                  <div
                    style={{
                      borderRadius: "1.5rem",
                      padding: "0.8rem",
                      display: "flex",
                      gap: "0.5rem",
                      flexDirection: "column",
                    }}
                  >
                    {detailFun(
                      "Appointment ID",
                      `${appointmentData?.appointmentPerfix ?? ""}${
                        type === "LEAD"
                          ? appointmentRecord.id
                          : type === "APPOINTMENT"
                          ? appointmentRecord?.id
                          : null
                      }`
                    )}
                   
                    {appointmentRecord?.appointmentStatus === "CONFIRMED" ||
                    (appointmentRecord?.appointmentStatus === "COMPLETED" &&
                      appointmentRecord?.counsellingBooking !== null) ? (
                      <>
                        {detailFun(
                          "Booking Date",
                          `${
                            appointmentRecord?.counsellingBooking?.bookingDate
                              ? formattedDate(
                                  appointmentRecord?.counsellingBooking
                                    ?.bookingDate
                                )
                              : "N/A"
                          }`
                        )}
                        {detailFun(
                          "Booking Day",
                          `${
                            appointmentRecord?.counsellingBooking?.bookingDay
                              ? appointmentRecord?.counsellingBooking
                                  ?.bookingDay
                              : "N/A"
                          }`
                        )}
                        {detailFun(
                          "Start Time",
                          `${
                            appointmentRecord?.counsellingBooking?.startTime
                              ? appointmentRecord?.counsellingBooking?.startTime
                              : "N/A"
                          }`
                        )}
                        {detailFun(
                          "End Time",
                          `${
                            appointmentRecord?.counsellingBooking?.endTime
                              ? appointmentRecord?.counsellingBooking?.endTime
                              : "N/A"
                          }`
                        )}
                        {detailFun(
                          "Counselling By",
                          `${
                            appointmentRecord?.counsellingBooking?.consultant
                              ?.fullName
                              ? appointmentRecord?.counsellingBooking
                                  ?.consultant?.fullName
                              : appointmentRecord?.assignedCounsellor?.fullName
                              ? appointmentRecord?.assignedCounsellor?.fullName
                              : "N/A"
                          }`
                        )}
                      </>
                    ) : null}

                    {detailFun(
                      "Appointment Status",
                      appointmentRecord?.appointmentStatus
                        ? fourTagResponses(
                            appointmentRecord?.appointmentStatus,
                            "PENDING",
                            "COMPLETED",
                            "CONFIRMED",
                            "CANCELLED"
                          )
                        : "N/A"
                    )}
                    {detailFun(
                      "Purpose",
                      `${
                        appointmentRecord?.purposeName
                          ? appointmentRecord?.purposeName
                          : appointmentRecord?.purpose?.purposeName
                          ? appointmentRecord?.purpose?.purposeName
                          : appointmentRecord?.leadResponse?.purposeName
                          ? appointmentRecord?.leadResponse?.purposeName
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Lead By",
                      `${
                        appointmentRecord?.leadBy
                          ? appointmentRecord?.leadBy
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Counsellor",
                      `${
                        appointmentRecord?.assignedCounsellor?.fullName
                          ? appointmentRecord?.assignedCounsellor?.fullName
                          : appointmentRecord?.counsellor
                          ? appointmentRecord?.counsellor
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Sales Rep",
                      `${
                        appointmentRecord?.salesRepName
                          ? appointmentRecord?.salesRepName
                          : appointmentRecord?.leadResponse?.salesRepName
                          ? appointmentRecord?.leadResponse.salesRepName
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Appointment Date",
                      `${
                        appointmentRecord?.appointmentDate
                          ? formattedDate(appointmentRecord?.appointmentDate)
                          : "N/A"
                      }`
                    )}
                  </div>
                </>
              )}
            </Card>
          </Card>
        )} */}
        <div style={{ width: "100%" }}>
          <Card>
            <div className="flexRowWithoutStyle">
              <div style={{ width: "10%" }}>Lead ID:</div>
              {store.leadState.leadById?.leadId}
            </div>
            <div className="flexRowWithoutStyle">
              <div style={{ width: "10%" }}>Lead Name:</div>
              {store.leadState.leadById?.firstName +
                " " +
                store.leadState.leadById?.lastName}
            </div>

            <div className="flexRowWithoutStyle">
              <div style={{ width: "10%" }}>Lead Status:</div>
              {store.leadState.leadById?.leadStatus?.statusName ===
              "BOOK_AN_APPOINTMENT" ? (
                <Tag
                  color={store.leadState.leadById?.leadStatus?.statusColorCode}
                >
                  IN PROGRESS
                </Tag>
              ) : (
                <Tag
                  color={store.leadState.leadById?.leadStatus?.statusColorCode}
                >
                  {store.leadState.leadById?.leadStatus?.statusName}
                </Tag>
              )}
            </div>
          </Card>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexFlow: "wrap",
              gap: "1rem",
              alignItems: "stretch",
              marginTop: "0.5rem",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <h2>Add Update</h2>
              <Form
                layout="vertical"
                onFinish={addStatusHistoryUpdateSubmitHandler}
                colon={true}
                form={form}
                fields={[
                  {
                    name: ["salesRepresentative"],
                    value: statusUpdateData.salesRepresentativeName,
                  },
                  {
                    name: ["updateWeightage"],
                    value: statusUpdateData.updateWeightage,
                  },
                  {
                    name: ["reminderDate"],
                    value: statusUpdateData.reminderDate
                      ? moment.utc(statusUpdateData.reminderDate).local()
                      : "",
                  },
                  {
                    name: ["reminderTime"],
                    value: statusUpdateData.reminderTime
                      ? moment.utc(statusUpdateData.reminderTime).local()
                      : "",
                  },
                  {
                    name: ["remarks"],
                    value: statusUpdateData.remarks,
                  },
                  {
                    name: ["nextAction"],
                    value: statusUpdateData.nextAction,
                  },
                  {
                    name: ["leadStatus"],
                    value: statusUpdateData.leadStatusName,
                  },
                  {
                    name: ["bookingStatus"],
                    value: statusUpdateData.bookingStatus,
                  },

                  {
                    name: ["appointmentDate"],
                    value: statusUpdateData.appointmentDate
                      ? moment.utc(statusUpdateData.appointmentDate).local()
                      : "",
                  },
                  {
                    name: ["counsellor"],
                    value: statusUpdateData.counsellorName,
                  },
                  {
                    name: ["sessionTime"],
                    value: statusUpdateData.sessionTime,
                  },
                  {
                    name: ["todayUpdate"],
                    value: statusUpdateData.todayUpdate,
                  },
                ]}
              >
                {/* <div
                className="flexRowWithoutStyle"
                style={{ justifyContent: "space-between", gap: "1rem" }}
              >
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "0.3rem", width: "100%" }}
                >
                  <Form.Item
                    label="Sales Reps"
                    name={"salesRepresentative"}
                    style={{ width: "100%" }}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please select Sales Reps",
                    //   },
                    // ]}
                  >
                    <Select>
                      {store.leadState?.leadsDropdown?.salesRepresentativeResponse?.map(
                        (dataObj) => {
                          return (
                            <Option key={dataObj.id}>
                              <div
                                onClick={() => {
                                  setStatusUpdateData((previouData) => {
                                    return {
                                      ...previouData,
                                      salesRepresentativeId: dataObj.id,
                                      salesRepresentativeName:
                                        dataObj.salesRepName,
                                    };
                                  });
                                  // setSalesRepresentativeId(dataObj.id);
                                  // setSalesRepresentativeName(dataObj.salesRepName);
                                }}
                              >
                                {dataObj.salesRepName}
                              </div>
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                  <a
                    className="plusButton"
                    onClick={() => {
                      setIsAddSalesRepModalVisible(true);
                    }}
                  >
                    <AiOutlinePlus className="iconColor" />
                  </a>
                </div>
                <Form.Item
                  label="Weightage"
                  name={"updateWeightage"}
                  style={{ width: "100%" }}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please select weightage",
                  //   },
                  // ]}
                >
                  <Select>
                    {store.leadState?.leadsDropdown?.weightage?.map(
                      (dataObj, index) => {
                        return (
                          <Option key={dataObj}>
                            <div
                              onClick={(e) => {
                                setStatusUpdateData((previouData) => {
                                  return {
                                    ...previouData,
                                    updateWeightage: dataObj,
                                  };
                                });
                              }}
                            >
                              {dataObj}
                            </div>
                          </Option>
                        );
                      }
                    )}
                  </Select>
                </Form.Item>
              </div> */}
                <Form.Item
                  label="Today's Update"
                  name={"todayUpdate"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please write today's update!",
                    },
                  ]}
                >
                  <TextArea
                    placeholder="Write something.."
                    onChange={(e) => {
                      setStatusUpdateData((previouData) => {
                        return {
                          ...previouData,
                          todayUpdate: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>
                <div
                  className="flexRowWithoutStyle"
                  style={{ justifyContent: "space-between", gap: "1rem" }}
                >
                  <Form.Item
                    label="Reminder Date"
                    name={"reminderDate"}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter reminder date!",
                      },
                    ]}
                  >
                    <DatePicker
                      format={"DD-MM-YYYY"}
                      disabledDate={(currentDate) => {
                        const date = new Date();
                        return currentDate < date;
                      }}
                      onChange={(e) => {
                        setStatusUpdateData((previouData) => {
                          return {
                            ...previouData,
                            reminderDate: e,
                          };
                        });
                        // setReminderDate(date(e));
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Reminder Time"
                    name={"reminderTime"}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter reminder time!",
                      },
                    ]}
                  >
                    <TimePicker
                      onChange={(e) => {
                        setStatusUpdateData((previouData) => {
                          return {
                            ...previouData,
                            reminderTime: e,
                          };
                        });
                        // setReminderTime(time(e));
                      }}
                    />
                  </Form.Item>
                </div>
                <div
                  className="flexRowWithoutStyle"
                  style={{ justifyContent: "space-between", gap: "1rem" }}
                >
                  <Form.Item
                    label="Remark"
                    name={"remarks"}
                    style={{ width: "100%" }}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please enter remark!",
                    //   },
                    // ]}
                  >
                    <Input
                      placeholder="Remarks"
                      onChange={(e) => {
                        setStatusUpdateData((previouData) => {
                          return {
                            ...previouData,
                            remarks: e.target.value,
                          };
                        });
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Next Action"
                    name={"nextAction"}
                    style={{ width: "100%" }}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please enter next action!",
                    //   },
                    // ]}
                  >
                    <Input
                      placeholder="Next Action"
                      onChange={(e) => {
                        setStatusUpdateData((previouData) => {
                          return {
                            ...previouData,
                            nextAction: e.target.value,
                          };
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  label="Status"
                  name={"leadStatus"}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please select lead status!",
                  //   },
                  // ]}
                >
                  <Select>
                    {store.leadState.leadsDropdown?.leadStatus?.map(
                      (dataObj) => {
                        return (
                          <Option key={dataObj.id}>
                            <div
                              onClick={() => {
                                console.log("frm here", dataObj.statusName);
                                setLeadStatus(dataObj.statusName);
                                setStatusUpdateData((previouData) => {
                                  return {
                                    ...previouData,
                                    leadStatusId: dataObj.id,
                                    leadStatusName: dataObj.statusName,
                                  };
                                });
                              }}
                            >
                              {dataObj.statusName}
                            </div>
                          </Option>
                        );
                      }
                    )}
                  </Select>
                </Form.Item>

                {statusUpdateData.leadStatusName === "BOOK_AN_APPOINTMENT" ? (
                  <>
                    <Form.Item
                      label="Want to confirm appointment now?"
                      name={"bookingStatus"}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please select a option!",
                      //   },
                      // ]}
                    >
                      <Radio.Group
                        onChange={(e) => {
                          setIsCouncelling(e.target.value);
                          setStatusUpdateData((previouData) => {
                            return {
                              ...previouData,
                              bookingStatus: e.target.value,
                            };
                          });
                        }}
                      >
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                    {statusUpdateData.bookingStatus === false ? (
                      <>
                        <Form.Item
                          label="Appointment Date"
                          name={"appointmentDate"}
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please enter appointment date!",
                            },
                          ]}
                        >
                          <DatePicker
                            disabledDate={(cd) => {
                              const d = new Date();
                              return cd < d;
                            }}
                            onChange={(e) => {
                              setStatusUpdateData((previouData) => {
                                return {
                                  ...previouData,
                                  appointmentDate: e,
                                };
                              });
                            }}
                          />
                        </Form.Item>
                        <div
                          className="flexRowWithoutStyle"
                          style={{ gap: "0.3rem", width: "100%" }}
                        >
                          <Form.Item
                            label="Counsellor"
                            name={"counsellor"}
                            style={{ width: "100%" }}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please select counsellor!",
                            //   },
                            // ]}
                          >
                            <Select>
                              {store.addCounsellingState?.leadsDropdown?.consultantList?.map(
                                (dataObj) => {
                                  return (
                                    <Option key={dataObj.id}>
                                      <div
                                        onClick={() => {
                                          setCounsellorId(dataObj.id);
                                          setStatusUpdateData((previouData) => {
                                            return {
                                              ...previouData,
                                              counsellorName: `${dataObj.firstName} ${dataObj.lastName}`,
                                              counsellorId: dataObj.id,
                                            };
                                          });
                                        }}
                                        className={"selectOptionDiv"}
                                      >
                                        {dataObj.firstName} {dataObj.lastName}
                                      </div>
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                          <a
                            className="plusButton"
                            onClick={() => {
                              setIsAddConsultantModalVisible(true);
                            }}
                          >
                            <AiOutlinePlus className="iconColor" />
                          </a>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="flexRowWithoutStyle"
                          style={{
                            justifyContent: "space-between",
                            gap: "1rem",
                          }}
                        >
                          <div
                            className="flexRowWithoutStyle"
                            style={{ gap: "0.3rem", width: "100%" }}
                          >
                            <Form.Item
                              label="Counsellor"
                              name={"counsellor"}
                              style={{ width: "100%" }}
                              rules={[
                                {
                                  required: true,
                                  message: "Please select counsellor!",
                                },
                              ]}
                            >
                              <Select>
                                {store.addCounsellingState?.leadsDropdown?.consultantList?.map(
                                  (dataObj) => {
                                    return (
                                      <Option key={dataObj.id}>
                                        <div
                                          onClick={() => {
                                            setCounsellorId(dataObj.id);
                                            setStatusUpdateData(
                                              (previouData) => {
                                                return {
                                                  ...previouData,
                                                  counsellorName: `${dataObj.firstName} ${dataObj.lastName}`,
                                                  counsellorId: dataObj.id,
                                                };
                                              }
                                            );
                                          }}
                                          className={"selectOptionDiv"}
                                        >
                                          {dataObj.firstName} {dataObj.lastName}
                                        </div>
                                      </Option>
                                    );
                                  }
                                )}
                              </Select>
                            </Form.Item>
                            <a
                              className="plusButton"
                              onClick={() => {
                                setIsAddConsultantModalVisible(true);
                              }}
                            >
                              <AiOutlinePlus className="iconColor" />
                            </a>
                          </div>
                          <Form.Item
                            label="Booking Date"
                            name={"appointmentDate"}
                            style={{ width: "100%" }}
                            rules={[
                              {
                                required: true,
                                message: "Please pick a booking date!",
                              },
                            ]}
                          >
                            <DatePicker
                              format={"DD-MM-YYYY"}
                              disabledDate={(cd) => {
                                const d = new Date();
                                return cd < d;
                              }}
                              onChange={(e) => {
                                setAppointmentDate(YYYYMMDD(e));
                                setStatusUpdateData((previouData) => {
                                  return {
                                    ...previouData,
                                    appointmentDate: e,
                                  };
                                });
                              }}
                            />
                          </Form.Item>
                        </div>
                        <Form.Item
                          label="Session Time"
                          name={"sessionTime"}
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please select a session time!",
                            },
                          ]}
                        >
                          <Select
                            onChange={(e) => {
                              console.log(e);
                              // setSessionTime(e);
                              setStatusUpdateData((previouData) => {
                                return {
                                  ...previouData,
                                  sessionTime: e,
                                };
                              });
                            }}
                          >
                            <Option value={"THIRTY_MINUTES"}>30 Minutes</Option>
                            <Option value={"ONE_HOUR"}>1 Hour</Option>
                            <Option value={"TWO_HOUR"}>2 Hour</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label="Available Time Slots"
                          name={"startTime"}
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please pick a your time slot!",
                            },
                          ]}
                        >
                          <Radio.Group
                            onChange={(e) => {
                              console.log(
                                "selected start time: ",
                                e.target.value
                              );
                            }}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                            }}
                          >
                            {store.timingDropdownState &&
                              store.timingDropdownState.timingDropdown.map(
                                (dataObj, index) => {
                                  return (
                                    <div key={index}>
                                      <Radio value={dataObj.startTime}>
                                        {dataObj.startTime} - {dataObj.endTime}
                                      </Radio>
                                    </div>
                                  );
                                }
                              )}
                          </Radio.Group>
                        </Form.Item>
                      </>
                    )}
                  </>
                ) : null}

                <Form.Item>
                  <Button block type="primary" htmlType="submit">
                    Add Update
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <div
              className="statusUpdateTimeline"
              style={{
                width: "30%",
                padding: "0.3rem",
                height: "130vh",
              }}
            >
              {store.leadState.leadById?.caseLog?.map((dataObj, index) => {
                return (
                  <>
                    <Timeline.Item key={dataObj.key}>
                      <div className="flexColumnwithoutStyle">
                        <div style={{ fontWeight: "200" }}>
                          {formattedDateTime(dataObj.updatedDateTime)}
                        </div>
                        <span>
                          {dataObj.updateStatus === "BOOK_AN_APPOINTMENT" ? (
                            <Tag
                              color={dataObj?.updateStatusColorCode ?? "blue"}
                            >
                              IN PROGRESS
                            </Tag>
                          ) : (
                            <Tag
                              color={dataObj?.updateStatusColorCode ?? "blue"}
                            >
                              {dataObj.updateStatus}
                            </Tag>
                          )}
                        </span>
                        {dataObj?.updateRemarks !== null &&
                          dataObj?.updateRemarks !== "" &&
                          dataObj?.updateRemarks !== undefined && (
                            <div>Update Remarks: {dataObj?.updateRemarks}</div>
                          )}
                        {dataObj.nextAction !== null &&
                          dataObj.nextAction !== "" &&
                          dataObj.nextAction !== undefined && (
                            <div>Next Action: {dataObj.nextAction}</div>
                          )}
                        {dataObj.dayUpdate !== null &&
                          dataObj.dayUpdate !== "" &&
                          dataObj.dayUpdate !== undefined && (
                            <div>Description: {dataObj.dayUpdate}</div>
                          )}
                        {dataObj?.updatedByEmployee !== null &&
                          dataObj?.updatedByEmployee !== "" &&
                          dataObj?.updatedByEmployee !== undefined && (
                            <div>Updated By: {dataObj?.updatedByEmployee}</div>
                          )}
                      </div>
                    </Timeline.Item>
                  </>
                );
              })}
            </div>
          </div>
          {isAddSalesRepModalVisible && (
            <AddSalesRep
              isAddSalesRepModalVisible={isAddSalesRepModalVisible}
              setIsAddSalesRepModalVisible={setIsAddSalesRepModalVisible}
            />
          )}
        </div>
      </div>

      {isAddConsultantModalVisible && (
        <AddEmployee
          isAddEmployeeModalVisible={isAddConsultantModalVisible}
          setIsAddEmployeeModalVisible={setIsAddConsultantModalVisible}
        />
      )}
    </Drawer>
  );
};

export default StatusHistory;

{
  /* <Card key={index}>
  <div>
    <div className="leadLog">Update Status:</div>
    <Tag>{dataObj.updateStatus}</Tag>
  </div>
  <div>
    <div className="leadLog">Update Remarks:</div>
    <span>{dataObj.updateRemarks}</span>
  </div>
  <div>
    <div className="leadLog">Day Update:</div>
    <span>{dataObj.dayUpdate}</span>
  </div>
  {dataObj.nextAction ?? (
    <div>
      <div className="leadLog">Next Action:</div>
      <span>{dataObj.nextAction}</span>
    </div>
  )}

  <div>
    <div className="leadLog">Updated Date and Time:</div>
    <span>{formattedDateTime(dataObj.updatedDateTime)}</span>
  </div>
  <div>
    <div className="leadLog">Updated By Employee:</div>
    <span>{dataObj.updatedByEmployee}</span>
  </div>
</Card>; */
}
