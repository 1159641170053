import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
const { Option } = Select;

const SubscrptionAddons = ({
  subscriptionRecord,
  noOfUsers,
  isSubscriptionAddonsModalVisible,
  setIsSubscriptionAddonsModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const subscriptionAddonsSubmitHandler = (value) => {
    const formData = {
      ...value,
    };
    dispatch({
      type: "PURCHASE_SUBSCRIPTION_REQUEST",
      payload: {
        subscriptionId: subscriptionRecord?.id,
        purchaseUserCount: noOfUsers,
        confirmationText: "",
      },
    });
    // form.resetFields();
    setIsSubscriptionAddonsModalVisible(false);
  };
  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };
  const detailFunFlexStart = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowFlexStart" style={{ gap: "2rem" }}>
          <span style={{ width: "20%" }}>{title}:</span>
          <span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };
  console.log("from subscriptionRecord ", subscriptionRecord);
  console.log("from noOfUsers ", noOfUsers);
  return (
    <Modal
      title="Proceed to Payment"
      open={isSubscriptionAddonsModalVisible}
      onCancel={() => {
        setIsSubscriptionAddonsModalVisible(false);
      }}
      footer={null}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <h3>Package Includes:</h3>
        {detailFunFlexStart(
          "Users",
          `${
            +noOfUsers > +subscriptionRecord.userCount
              ? `${subscriptionRecord.userCount} + ${
                  +noOfUsers - +subscriptionRecord.userCount
                } (package included + extra user)`
              : // +subscriptionRecord?.packagePrice
                +subscriptionRecord.userCount
          }`
        )}
        {detailFunFlexStart(
          "Basic package price",
          `$ ${subscriptionRecord?.packagePrice}`
        )}
        {detailFunFlexStart(
          "Extra price per user",
          `$ ${subscriptionRecord?.pricePerUser ?? 0}`
        )}

        {/* {detailFunFlexStart("Device Count", subscriptionRecord?.deviceCount)} */}
        {/* {detailFunFlexStart("Lead Forms", subscriptionRecord?.leadForms)}
        {detailFunFlexStart("Storage", subscriptionRecord?.storage)} */}
        <div style={{ width: "100%" }}>
          <div className="flexRowSpaceBetween">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            ></div>

            <span>
              ${subscriptionRecord?.packagePrice} +
              {+noOfUsers > +subscriptionRecord.userCount
                ? +noOfUsers - +subscriptionRecord?.userCount ?? 1
                : 0}{" "}
              * ${subscriptionRecord?.pricePerUser ?? 0} = $
              {+noOfUsers > +subscriptionRecord.userCount
                ? +subscriptionRecord?.packagePrice +
                  (+noOfUsers - +subscriptionRecord.userCount) *
                    +subscriptionRecord?.pricePerUser
                : +subscriptionRecord?.packagePrice}
            </span>
          </div>
          <Divider
            orientationMargin="0"
            style={{ margin: "0.5rem", color: "black" }}
          />
        </div>
        <div style={{ width: "100%", margin: "0 0 1rem 0" }}>
          <div className="flexRowFlexEnd" style={{ gap: "1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                fontWeight: "500",
              }}
            >
              Total amount:
            </div>

            <span style={{ fontWeight: "500" }}>
              {/* $ {+noOfUsers * +subscriptionRecord?.packagePrice} */}${" "}
              {+noOfUsers > +subscriptionRecord.userCount
                ? +subscriptionRecord?.packagePrice +
                  (+noOfUsers - +subscriptionRecord.userCount) *
                    subscriptionRecord?.pricePerUser
                : +subscriptionRecord?.packagePrice}
            </span>
          </div>
        </div>
      </div>
      <Form
        layout="vertical"
        onFinish={subscriptionAddonsSubmitHandler}
        colon={true}
        form={form}
      >
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Proceed to Checkout
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SubscrptionAddons;
