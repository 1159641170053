import {
  DownOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SmileOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import khrouchLogo from "./../../Assets/khrouch.png";
import khrouchLogo2 from "./../../Assets/khrouch3.png";
import profile from "./../../Assets/profile.png";
import {
  Alert,
  Badge,
  Divider,
  Dropdown,
  Layout,
  Menu,
  Select,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { IoIosArrowDown, IoIosNotificationsOutline } from "react-icons/io";
import { GoDashboard } from "react-icons/go";
import {
  AiOutlineClockCircle,
  AiOutlineContacts,
  AiOutlineDatabase,
  AiOutlineKey,
  AiOutlineLogin,
  AiOutlineMail,
  AiOutlinePlus,
  AiOutlineSearch,
} from "react-icons/ai";
import { TiFlowChildren } from "react-icons/ti";
import {
  BsBell,
  BsBookmark,
  BsClipboard,
  BsFlag,
  BsPlayCircle,
} from "react-icons/bs";
import { FaQuora, FaRegBuilding, FaSearchLocation } from "react-icons/fa";
import { RiTodoLine, RiVisaLine } from "react-icons/ri";
import { SlGraduation } from "react-icons/sl";
import { TbCertificate } from "react-icons/tb";
import { TfiBookmarkAlt } from "react-icons/tfi";
import { BsBuilding } from "react-icons/bs";
import {
  HiOutlineBookOpen,
  HiOutlinePaperAirplane,
  HiOutlineUsers,
} from "react-icons/hi";
import { SiGoogleclassroom } from "react-icons/si";
import { FiSettings } from "react-icons/fi";
import React, { useEffect, useState } from "react";
import classes from "./SiderDemo.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BiBuildingHouse, BiLayer, BiSupport, BiUser } from "react-icons/bi";
import { GrNotification, GrResources, GrUserAdmin } from "react-icons/gr";
import { VscDebugStart, VscTypeHierarchySuper } from "react-icons/vsc";
import AddLead from "../../Pages/Leads/AddLead";
import AddAppointment from "../../Pages/Appointments/AddAppointment";
import AddClient from "../../Pages/Clients/AddClient";
import AddContacts from "../../Pages/Contacts/AddContacts";
import { MdCalendarViewMonth, MdCampaign } from "react-icons/md";
import AddVisaCases from "../../Pages/Visas/AddVisas";
import axios from "axios";
import moment from "moment";
import ClientDetails from "../../Pages/Clients/ClientDetails";
import ContactDetails from "../../Pages/Contacts/ContactDetails";
import LeadDetails from "../../Pages/Leads/LeadDetails";
import AppointmentDetails from "../../Pages/Appointments/AppointmentDetails";
import VisaCaseDetails from "../../Pages/Visas/VisaCaseDetails";
import ConsultantDetails from "../../Pages/Consultants/ConultantDetails";
import InstituteDetais from "../../Pages/Education/Institute/InstituteDetails";
import CoursesDetail from "../../Pages/Courses/CoursesDetail";
import CompanyDetails from "../../Pages/Companies/CompanyDetails";
import Cookies from "js-cookie";
const { Header, Sider, Content } = Layout;
const { Option } = Select;
const { Paragraph } = Typography;

const SiderDemo = ({ children, organizationalSettings, dashboard }) => {
  const store = useSelector((state) => {
    return {
      profileState: state.ProfileReducer,
      loginState: state.LoginReducer,
      branchState: state.BranchReducer,
      // mailboxState: state.MailboxReducer,
    };
  });
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [isAddContactVisible, setIsAddContactVisible] = useState(false);
  const [isAddLeadModelVisible, setIsAddLeadModelVisible] = useState(false);
  const [isAddAppointmentModelVisible, setIsAddAppointmentModelVisible] =
    useState(false);
  const [isAddVisasModalVisible, setIsAddVisasModalVisible] = useState(false);

  const [isAddClientModalVisible, setIsAddClientModalVisible] = useState(false);
  const [isClientDetailsModelVisible, setIsClientDetailsModelVisible] =
    useState(false);
  const [isContactsDetailsModelVisible, setIsContactsDetailsModelVisible] =
    useState(false);
  const [isLeadsDetailsModelVisible, setIsLeadsDetailsModelVisible] =
    useState(false);
  const [
    isAppointmentDetailsModalVisible,
    setIsAppointmentDetailsModalVisible,
  ] = useState();
  const [isVisaCaseDetailsModalVisible, setIsVisaCaseDetailsModalVisible] =
    useState(false);
  const [isConsultantDetailsModelVisible, setIsConsultantDetailsModelVisible] =
    useState(false);
  const [isInstituteDetailsModelVisible, setIsInstituteDetailsModelVisible] =
    useState(false);
  const [isCourseDetailsModelVisible, setIsCourseDetailsModelVisible] =
    useState(false);
  const [isCompanyDetalisModalVisible, setIsCompanyDetalisModalVisible] =
    useState(false);
  const [record, setRecord] = useState(null);
  const [keyword, setKeyword] = useState(null);
  let keyId = 4;

  const [current, setCurrent] = useState(
    location.pathname === "/" || location.pathname === ""
      ? "/dashboard"
      : location.pathname
  );
  const [stateChange, setStateChange] = useState({
    hell: "vutvut",
  });
  console.table(stateChange, "changing the state");
  // const debounce = (func, delay) => {
  //   let timeOutId;
  //   return (...args) => {
  //     clearTimeout(timeOutId);
  //     timeOutId = setTimeout(() => {
  //       func(...args);
  //     }, delay);
  //   };
  // };
  // const handleSearch = debounce(() => {
  //   if (keyword !== "") {
  //     dispatch({
  //       type: "GLOBAL_SEARCH_FROM_DASHBOARD_REQUEST",
  //       payload: { keyword: keyword, page: 1, pageSize: 3 },
  //     });
  //   }
  // }, 3000);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (keyword !== "" && keyword !== null) {
        dispatch({
          type: "GLOBAL_SEARCH_FROM_DASHBOARD_REQUEST",
          payload: { keyword: keyword, page: 1, pageSize: 3 },
        });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [keyword]);

  const mailSuccessFunction = (response) => {};
  useEffect(() => {
    dispatch({ type: "FETCH_PROFILE_REQUEST" });
    dispatch({ type: "FETCH_BRANCH_REQUEST" });
    dispatch({
      type: "BRANCH_SELECTED_REQUEST",
      payload: {
        branchRecord: {
          branchName: "Head Branch",
        },
        isHeadBranch: true,
      },
    });
  }, []);
  // useEffect(() => {
  //   dispatch({
  //     type: "GLOBAL_SEARCH_FROM_DASHBOARD_REQUEST",
  //     payload: { keyword: keyword, page: 1, pageSize: 1 },
  //   });
  // }, [keyword]);
  // setTimeout(() => {
  //   dispatch({
  //     type: "FETCH_MAILBOX_INBOX_REQUEST",
  //     payload: {
  //       page: 1,
  //       pageSize: 10,
  //       isFromSiderDemo: true,
  //       mailSuccessFunction: mailSuccessFunction,
  //     },
  //   });
  // }, 120000);
  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
  }, [location, current]);
  const [collapsed, setCollapsed] = useState(false);
  const handleClick = (e) => {
    setCurrent(e.key);
  };
  const reminderItems =
    store.profileState?.todayReminders?.data?.length > 0
      ? store.profileState?.todayReminders?.data?.map((dataObj) => {
          return {
            key: dataObj.id,
            label: dataObj.confirmationText,
          };
        })
      : [{ key: 1, label: "No reminders found!" }];
  const notificatonsItems =
    store.profileState?.notifications?.data?.length > 0
      ? store.profileState?.notifications?.data?.map((dataObj) => {
          return {
            key: dataObj.id,
            label: dataObj?.notificationDescription,
          };
        })
      : [{ key: 1, label: "No reminders found!" }];

  //  [
  //   {
  //     key: "1",
  //     label: (
  //       <a
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         href="https://www.antgroup.com"
  //       >
  //         1st menu item
  //       </a>
  //     ),
  //   },
  //   {
  //     key: "2",
  //     label: (
  //       <a
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         href="https://www.aliyun.com"
  //       >
  //         2nd menu item (disabled)
  //       </a>
  //     ),
  //     icon: <SmileOutlined />,
  //     disabled: true,
  //   },
  //   {
  //     key: "3",
  //     label: (
  //       <a
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         href="https://www.luohanacademy.com"
  //       >
  //         3rd menu item (disabled)
  //       </a>
  //     ),
  //     disabled: true,
  //   },
  //   {
  //     key: "4",
  //     danger: true,
  //     label: "a danger item",
  //   },
  // ];
  const logoutHandler = () => {
    const tempToken = Cookies.get("accessToken");
    axios.get("v1/api/customer/logout", {
      headers: {
        Authorization: `Bearer ${tempToken}`,
      },
    });
    dispatch({
      type: "LOGOUT_CONSULTANCY_REQUEST",
    });
  };
  const menu = (
    <div
      style={{
        background: "white",
        padding: "5px 15px",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",

        color: "black",
      }}
    >
      <Link to={"/my-profile"}>
        <p style={{ cursor: "pointer" }}>
          <UserOutlined /> Profile
        </p>
      </Link>
      {/* <Link to={"/change-password"}>
        {" "}
        <p style={{ cursor: "pointer" }}>
          <RiLockPasswordFill /> Change Password
        </p>
      </Link>
      <Link to={"/register-admin"}>
        {" "}
        <p style={{ cursor: "pointer" }}>
          <UserAddOutlined /> Register Admin
        </p>
      </Link>
      <Link to={"/admin-list"}>
        {" "}
        <p style={{ cursor: "pointer" }}>
          <RiAdminFill /> Admin List
        </p>
      </Link> */}
      {/* <Link to={"/roles-and-permission"}>
        <p style={{ cursor: "pointer" }}>
          <AiOutlineKey /> Roles & Permissions
        </p>
      </Link> */}
      {/* <Link to={"/settings"}>
        <p style={{ cursor: "pointer" }}>
          <FiSettings /> Settings
        </p>
      </Link> */}
      <a>
        <p onClick={logoutHandler} style={{ cursor: "pointer" }}>
          <LogoutOutlined /> Log out
        </p>
      </a>
    </div>
  );

  const items = [
    {
      key: "1",
      label: (
        <a
          onClick={() => {
            setIsAddContactVisible(true);
          }}
        >
          Contact
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          onClick={() => {
            setIsAddLeadModelVisible(true);
          }}
        >
          Lead
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a
          onClick={() => {
            setIsAddAppointmentModelVisible(true);
          }}
        >
          Appointment
        </a>
      ),
    },
    {
      key: "5",
      label: (
        <a
          onClick={() => {
            setIsAddVisasModalVisible(true);
          }}
        >
          Case
        </a>
      ),
    },
    {
      key: "4",
      label: (
        <a
          onClick={() => {
            setIsAddClientModalVisible(true);
          }}
        >
          Client
        </a>
      ),
    },
    {
      key: "6",
      label: (
        <a
          onClick={() => {
            navigate("/attendance");
          }}
        >
          Check In
        </a>
      ),
    },
  ];

  return (
    <Layout hasSider={true}>
      <Sider
        className="sider"
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          background: "white",
          height: "100vh",

          position: "sticky",
          overflow: "scroll",
          backgroundColor: "#1D1C40",
          overflow: "hidden",
          top: 0,
          left: 0,
        }}
      >
        <div
          className="logo"
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            fontSize: "1.2rem",
            height: "64px",
          }}
        >
          {collapsed ? (
            <img src={khrouchLogo2} style={{ width: "100%" }} />
          ) : (
            <img src={khrouchLogo} style={{ width: "100%" }} />
          )}
        </div>
        <Divider
          style={{
            margin: "0",
            height: "15px",
            borderTop: "1.5px solid rgb(221, 218, 218)",
          }}
        />
        <Menu
          theme="light"
          mode="inline"
          onClick={handleClick}
          selectedKeys={[current]}
          items={[
            {
              key: "1",
              icon: <GoDashboard />,
              label: <Link to={"/dashboard"}>Dashboard</Link>,
            },
            {
              key: "4",
              icon: <VscDebugStart />,
              label: <Link to={"/leads"}>Leads</Link>,
            },

            {
              key: "28",
              icon: <BiUser />,
              label: "Client Manager",
              children: [
                {
                  key: "27",
                  icon: <HiOutlineUsers />,
                  label: <Link to={"/clients"}>Clients</Link>,
                },
                {
                  key: "3",
                  icon: <AiOutlineContacts />,
                  label: <Link to={"/contacts"}>Contacts</Link>,
                },
                ,
              ],
            },
            {
              key: "5",
              icon: <AiOutlineClockCircle />,
              label: <Link to={"/appointments"}>Appointments</Link>,
            },
            {
              key: "21",
              icon: <BsClipboard />,
              label: <Link to={"/cases"}>Cases</Link>,
            },
            {
              key: "54",
              icon: <FaQuora />,
              label: <Link to={"/quotation"}>Quotation</Link>,
            },
            {
              key: "46",
              icon: <AiOutlineLogin />,
              label: "Check In",
              // onClick: { handleButtonClick },
              children: [
                {
                  key: "47",
                  icon: <AiOutlineLogin />,
                  label: <Link to={"/attendance"}>Manage Check In</Link>,
                },
                {
                  key: "48",
                  icon: <MdCalendarViewMonth />,
                  label: <Link to={"/report"}>Report</Link>,
                },
              ],
            },

            !store.loginState.loginData?.isBranch && {
              key: "37",
              icon: <VscTypeHierarchySuper />,
              label: <Link to={"/branch"}>Branch</Link>,
            },
            {
              key: "17",
              icon: <BiUser />,
              label: "Employee Manager",
              children: [
                {
                  key: "18",
                  icon: <HiOutlineUsers />,
                  label: <Link to={"/manage-employees"}>Manage Employee</Link>,
                },
                {
                  key: "19",
                  icon: <SiGoogleclassroom />,
                  label: (
                    <Link to={"/manage-department"}>Manage Department</Link>
                  ),
                },
                {
                  key: "52",
                  icon: <AiOutlineKey />,
                  label: (
                    <Link to={"/roles-and-permission"}>
                      Roles and Permissions
                    </Link>
                  ),
                },
                {
                  key: "23",
                  icon: <HiOutlineUsers />,
                  label: (
                    <Link to={"/manage-consultants"}>Manage Consultants</Link>
                  ),
                },
              ],
            },
            {
              key: "50",
              icon: <MdCampaign />,
              label: "Campaign Manager",
              children: [
                {
                  key: "51",
                  icon: <MdCampaign />,
                  label: <Link to={"/manage-campaign"}>Manage Campaign</Link>,
                },
              ],
            },
            {
              key: "26",
              icon: <GrResources />,
              label: <Link to={"/resources"}>Resources</Link>,
            },

            {
              key: "2",
              icon: <BsPlayCircle />,
              label: "Agents",
              children: [
                {
                  key: "7",
                  icon: <BiBuildingHouse />,
                  label: <Link to={"/companies"}>Companies</Link>,
                },
                {
                  key: "14",
                  icon: <UserOutlined />,
                  label: <Link to={"/head-agent"}>Head Agent</Link>,
                },
                {
                  key: "15",
                  icon: <UserOutlined />,
                  label: <Link to={"/sub-agent"}>Sub Agent</Link>,
                },
              ],
            },

            // {
            //   key: "8",
            //   icon: <FaRegBuilding />,
            //   label: "Branch",
            // },
            {
              key: "25",
              icon: <AiOutlineDatabase />,
              label: <Link to={"/tasks"}>Tasks</Link>,
            },
            {
              key: "32",
              icon: <RiVisaLine />,
              label: "Visas",
              children: [
                {
                  key: "33",
                  icon: <HiOutlineBookOpen />,
                  label: <Link to={"/education"}>Education</Link>,
                },
                {
                  key: "34",
                  icon: <HiOutlinePaperAirplane />,
                  label: <Link to={"/migration"}>Migration</Link>,
                },
                // {
                //   key: "35",
                //   icon: <VscTypeHierarchySuper />,
                //   label: <Link to={"/visaTypeCases"}>Cases</Link>,
                // },
              ],
            },

            {
              key: "10",
              icon: <SlGraduation />,
              label: "Education",
              children: [
                {
                  key: "11",
                  icon: <BsBuilding />,
                  label: <Link to={"/institute"}>Institute/College</Link>,
                },
                {
                  key: "12",
                  icon: <TfiBookmarkAlt />,
                  label: <Link to={"/courses"}>Courses</Link>,
                },
                {
                  key: "49",
                  icon: <BsBookmark />,
                  label: <Link to={"/degree"}>Degree</Link>,
                },
              ],
            },
            {
              key: "16",
              icon: <BiSupport />,
              label: <Link to={"/support"}>Support</Link>,
            },
            // {
            //   key: "29",
            //   icon: <FiSettings />,
            //   label: "Settings",
            //   children: [
            //     {
            //       key: "30",
            //       icon: <TiFlowChildren />,
            //       label: (
            //         <Link
            //           to="/country"
            //           onClick={() =>
            //             setStateChange({ ...stateChange, hell: "tutut" })
            //           }
            //         >
            //           Workflow
            //         </Link>
            //       ),
            //     },
            //     // {
            //     //   key: "40",
            //     //   icon: <TiFlowChildren />,
            //     //   label: <Link to={"/Currency"}>Currency</Link>,
            //     // },
            //   ],
            // },
            {
              key: "31",
              icon: <FaSearchLocation />,
              label: <Link to={"/collegeFinder"}>College Finder</Link>,
            },
            {
              key: "36",
              icon: <RiTodoLine />,
              label: <Link to={"/todo"}>Todo</Link>,
            },

            // {
            //   key: "45",
            //   icon: <RiTodoLine />,
            //   label: <Link to={"/subscription"}>Subscription</Link>,
            // },
            // {
            //   key: "44",
            //   icon: <RiTodoLine />,
            //   label: (
            //     <Link to={"/stripe-payment-success"}>Payment Success</Link>
            //   ),
            // },
            // {
            //   key: "45",
            //   icon: <RiTodoLine />,
            //   label: <Link to={"/stripe-payment-cancel"}>Payment Failure</Link>,
            // },
          ]}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: "0 20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "1rem",
            }}
          >
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                style: {
                  fontSize: "20px",
                },
                onClick: () => setCollapsed(!collapsed),
              }
            )}
            <Dropdown
              menu={{
                items,
              }}
              placement="bottom"
            >
              <AiOutlinePlus style={{ fontSize: "20px" }} />
            </Dropdown>
            <Select
              style={{ width: 300 }}
              placeholder="Search here for anything"
              className={"dashboardSearch"}
              suffixIcon={<AiOutlineSearch />}
              prefixIcon={<AiOutlineSearch />}
              value={null}
              showSearch
              loading={store.profileState?.isLoading}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              notFoundContent={null}
              onSearch={(e) => {
                // if (e !== "") {
                //   dispatch({
                //     type: "GLOBAL_SEARCH_FROM_DASHBOARD_REQUEST",
                //     payload: { keyword: e, page: 1, pageSize: 3 },
                //   });
                // }
                // handleSearch();
                setKeyword(e);
              }}
              onChange={() => {}}
            >
              {store.profileState?.globalSearchResult?.customer?.data?.map(
                (dataObj, keyId) => {
                  keyId = keyId + 1;
                  return (
                    <Option key={keyId}>
                      <div
                        onClick={() => {
                          setRecord(dataObj);
                          setIsClientDetailsModelVisible(true);
                        }}
                        className={"selectOptionDiv flexRowSpaceBetween"}
                      >
                        <div
                          className="ellipsisStyle"
                          style={{
                            width: "78%",
                          }}
                        >
                          {`${dataObj?.firstName ?? ""} ${
                            dataObj?.lastName ?? ""
                          } ${
                            dataObj.email !== null && dataObj.email !== ""
                              ? `(${dataObj.email})`
                              : dataObj.mobile !== null && dataObj.mobile !== ""
                              ? `(${dataObj.mobile})`
                              : ""
                          }`}
                        </div>
                        <Tag
                          color="#2db7f5"
                          style={{
                            borderRadius: "10px",
                            margin: "3px",
                          }}
                        >
                          CLIENT
                        </Tag>
                      </div>
                    </Option>
                  );
                }
              )}
              {store.profileState?.globalSearchResult?.contact?.data?.map(
                (dataObj) => {
                  keyId = keyId + 1;

                  return (
                    <Option key={keyId}>
                      <div
                        onClick={() => {
                          setRecord(dataObj);
                          setIsContactsDetailsModelVisible(true);
                        }}
                        className={"selectOptionDiv flexRowSpaceBetween"}
                      >
                        <div
                          className="ellipsisStyle"
                          style={{
                            width: "78%",
                          }}
                        >
                          {`${dataObj?.firstName ?? ""} ${
                            dataObj?.lastName ?? ""
                          } ${
                            dataObj.email !== null && dataObj.email !== ""
                              ? `(${dataObj.email})`
                              : dataObj.mobile !== null && dataObj.mobile !== ""
                              ? `(${dataObj.mobile})`
                              : ""
                          }`}
                        </div>
                        <Tag
                          color="#2db7f5"
                          style={{
                            borderRadius: "10px",
                            margin: "3px",
                          }}
                        >
                          CONTACT
                        </Tag>
                      </div>
                    </Option>
                  );
                }
              )}
              {store.profileState?.globalSearchResult?.leads?.data?.map(
                (dataObj) => {
                  keyId = keyId + 1;

                  return (
                    <Option key={keyId}>
                      <div
                        onClick={() => {
                          setRecord(dataObj);
                          setIsLeadsDetailsModelVisible(true);
                        }}
                        className={"selectOptionDiv flexRowSpaceBetween"}
                      >
                        <div
                          className="ellipsisStyle"
                          style={{
                            width: "83%",
                          }}
                        >
                          {`${dataObj?.firstName ?? ""} ${
                            dataObj?.lastName ?? ""
                          } ${
                            dataObj.email !== null && dataObj.email !== ""
                              ? `(${dataObj.email})`
                              : dataObj.mobile !== null && dataObj.mobile !== ""
                              ? `(${dataObj.mobile})`
                              : ""
                          }`}
                        </div>
                        <Tag
                          color="#2db7f5"
                          style={{
                            borderRadius: "10px",
                            margin: "3px",
                          }}
                        >
                          LEAD
                        </Tag>
                      </div>
                    </Option>
                  );
                }
              )}
              {store.profileState?.globalSearchResult?.appointments?.data?.map(
                (dataObj) => {
                  keyId = keyId + 1;

                  return (
                    <Option key={keyId}>
                      <div
                        onClick={() => {
                          setRecord(dataObj);
                          setIsAppointmentDetailsModalVisible(true);
                        }}
                        className={"selectOptionDiv flexRowSpaceBetween"}
                      >
                        <div
                          className="ellipsisStyle"
                          style={{
                            width: "78%",
                          }}
                        >
                          {`${dataObj?.firstName ?? ""} ${
                            dataObj?.lastName ?? ""
                          } ${
                            dataObj.email !== null && dataObj.email !== ""
                              ? `(${dataObj.email})`
                              : dataObj.mobile !== null && dataObj.mobile !== ""
                              ? `(${dataObj.mobile})`
                              : ""
                          }`}
                        </div>
                        <Tag
                          color="#2db7f5"
                          style={{
                            borderRadius: "10px",
                            margin: "3px",
                          }}
                        >
                          APPOINTMENT
                        </Tag>
                      </div>
                    </Option>
                  );
                }
              )}
              {store.profileState?.globalSearchResult?.cases?.data?.map(
                (dataObj, index) => {
                  keyId = keyId + 1;

                  return (
                    <Option key={keyId}>
                      <div
                        onClick={() => {
                          setRecord(dataObj);
                          setIsVisaCaseDetailsModalVisible(true);
                        }}
                        className={"selectOptionDiv flexRowSpaceBetween"}
                      >
                        <div
                          className="ellipsisStyle"
                          style={{
                            width: "83%",
                          }}
                        >
                          {`${dataObj?.customer?.firstName ?? ""} ${
                            dataObj?.customer?.lastName ?? ""
                          } ${
                            dataObj?.customer?.email !== null &&
                            dataObj?.customer?.email !== ""
                              ? `(${dataObj?.customer?.email})`
                              : dataObj?.customer?.mobile !== null &&
                                dataObj?.customer?.mobile !== ""
                              ? `(${dataObj?.customer?.mobile})`
                              : ""
                          }`}
                        </div>
                        <Tag
                          color="#2db7f5"
                          style={{
                            borderRadius: "10px",
                            margin: "3px",
                          }}
                        >
                          CASE
                        </Tag>
                      </div>
                    </Option>
                  );
                }
              )}
              {store.profileState?.globalSearchResult?.institute?.data?.map(
                (dataObj) => {
                  keyId = keyId + 1;

                  return (
                    <Option key={keyId}>
                      <div
                        onClick={() => {
                          setRecord(dataObj);
                          setIsInstituteDetailsModelVisible(true);
                        }}
                        className={"selectOptionDiv flexRowSpaceBetween"}
                      >
                        <div
                          className="ellipsisStyle"
                          style={{
                            width: "78%",
                          }}
                        >
                          {dataObj.instituteName}
                        </div>
                        <Tag
                          color="#2db7f5"
                          style={{
                            borderRadius: "10px",
                            margin: "3px",
                          }}
                        >
                          INSTITUTE
                        </Tag>
                      </div>
                    </Option>
                  );
                }
              )}
              {store.profileState?.globalSearchResult?.course?.data?.map(
                (dataObj) => {
                  keyId = keyId + 1;

                  return (
                    <Option key={keyId}>
                      <div
                        onClick={() => {
                          setRecord(dataObj);
                          setIsCourseDetailsModelVisible(true);
                        }}
                        className={"selectOptionDiv flexRowSpaceBetween"}
                      >
                        <div
                          className="ellipsisStyle"
                          style={{
                            width: "78%",
                          }}
                        >
                          {dataObj.name ?? ""}
                          {dataObj.courseCode ? `(${dataObj.courseCode})` : ""}
                        </div>
                        <Tag
                          color="#2db7f5"
                          style={{
                            borderRadius: "10px",
                            margin: "3px",
                          }}
                        >
                          COURSE
                        </Tag>
                      </div>
                    </Option>
                  );
                }
              )}
              {store.profileState?.globalSearchResult?.company?.data?.map(
                (dataObj) => {
                  keyId = keyId + 1;

                  return (
                    <Option key={keyId}>
                      <div
                        onClick={() => {
                          setRecord(dataObj);
                          setIsCompanyDetalisModalVisible(true);
                        }}
                        className={"selectOptionDiv flexRowSpaceBetween"}
                      >
                        <div
                          className="ellipsisStyle"
                          style={{
                            width: "78%",
                          }}
                        >
                          {dataObj.companyName ?? ""}{" "}
                          {dataObj.email ? `(${dataObj.email})` : ""}
                        </div>
                        <Tag
                          color="#2db7f5"
                          style={{
                            borderRadius: "10px",
                            margin: "3px",
                          }}
                        >
                          COMPANY
                        </Tag>
                      </div>
                    </Option>
                  );
                }
              )}
              {store.profileState?.globalSearchResult?.headAgent?.data?.map(
                (dataObj) => {
                  keyId = keyId + 1;

                  return (
                    <Option key={keyId}>
                      <div
                        onClick={() => {
                          // setRecord(dataObj);
                          // setIsLeadsDetailsModelVisible(true);
                        }}
                        className={"selectOptionDiv flexRowSpaceBetween"}
                      >
                        <div
                          className="ellipsisStyle"
                          style={{
                            width: "78%",
                          }}
                        >
                          {`${dataObj?.name ?? ""} ${
                            dataObj.email !== null && dataObj.email !== ""
                              ? `(${dataObj.email})`
                              : dataObj.mobileNumber !== null &&
                                dataObj.mobileNumber !== ""
                              ? `(${dataObj.mobileNumber})`
                              : ""
                          }`}
                        </div>
                        <Tag
                          color="#2db7f5"
                          style={{
                            borderRadius: "10px",
                            margin: "3px",
                          }}
                        >
                          HEADAGENT
                        </Tag>
                      </div>
                    </Option>
                  );
                }
              )}
              {store.profileState?.globalSearchResult?.subAgent?.data?.map(
                (dataObj) => {
                  keyId = keyId + 1;

                  return (
                    <Option key={keyId}>
                      <div
                        onClick={() => {
                          // setRecord(dataObj);
                          // setIsLeadsDetailsModelVisible(true);
                        }}
                        className={"selectOptionDiv flexRowSpaceBetween"}
                      >
                        <div
                          className="ellipsisStyle"
                          style={{
                            width: "78%",
                          }}
                        >
                          {`${dataObj?.name ?? ""}  ${
                            dataObj.email !== null && dataObj.email !== ""
                              ? `(${dataObj.email})`
                              : dataObj.mobileNumber !== null &&
                                dataObj.mobileNumber !== ""
                              ? `(${dataObj.mobileNumber})`
                              : ""
                          }`}
                        </div>
                        <Tag
                          color="#2db7f5"
                          style={{
                            borderRadius: "10px",
                            margin: "3px",
                          }}
                        >
                          SUBAGENT
                        </Tag>
                      </div>
                    </Option>
                  );
                }
              )}
              {store.profileState?.globalSearchResult?.consultancyEmployees?.data?.map(
                (dataObj) => {
                  keyId = keyId + 1;
                  return (
                    <Option key={keyId}>
                      <div
                        onClick={() => {
                          setRecord(dataObj);
                          setIsConsultantDetailsModelVisible(true);
                        }}
                        className={"selectOptionDiv flexRowSpaceBetween"}
                      >
                        <div
                          className="ellipsisStyle"
                          style={{
                            width: "78%",
                          }}
                        >
                          {`${dataObj?.fullName ?? ""} ${
                            dataObj.emailId !== null && dataObj.emailId !== ""
                              ? `(${dataObj.emailId})`
                              : dataObj.phoneNumber !== null &&
                                dataObj.phoneNumber !== ""
                              ? `(${dataObj.phoneNumber})`
                              : ""
                          }`}
                        </div>
                        <Tag
                          color="#2db7f5"
                          style={{
                            borderRadius: "10px",
                            margin: "3px",
                          }}
                        >
                          EMPLOYEE
                        </Tag>
                      </div>
                    </Option>
                  );
                }
              )}
            </Select>
          </div>

          {!store.loginState.loginData?.isBranch && (
            <div>
              <Select
                style={{ width: "10rem" }}
                value={store.branchState.branchRecord.branchName}
              >
                <Option>
                  <div
                    onClick={() => {
                      dispatch({
                        type: "BRANCH_SELECTED_REQUEST",
                        payload: {
                          branchRecord: {
                            branchName: "Head Branch",
                          },
                          isHeadBranch: true,
                        },
                      });
                    }}
                  >
                    Head Branch
                  </div>
                </Option>
                {store.branchState.branches?.data?.map((dataObj) => {
                  return (
                    <Option key={dataObj.id}>
                      <div
                        onClick={() => {
                          dispatch({
                            type: "BRANCH_SELECTED_REQUEST",
                            payload: {
                              branchRecord: dataObj,
                              isHeadBranch: false,
                            },
                          });
                        }}
                      >
                        {dataObj.branchName}
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </div>
          )}
          <div style={{ display: "flex", alignItems: "center", gap: "1.5rem" }}>
            <div style={{ display: "flex", gap: "0.7rem" }}>
              {/* <Tooltip title="Notifications" placement="right">
                <div
                  onClick={() => {
                    dispatch({
                      type: "FETCH_NOTIFICATION_REQUEST",
                    });
                  }}
                >
                  <Dropdown
                    trigger={["click"]}
                    placement={"bottomRight"}
                    menu={{
                      items: notificatonsItems,
                    }}
                  >
                    <Badge
                      count={store.profileState?.notifications?.data?.length}
                    >
                      <IoIosNotificationsOutline className="headerIcons" />
                    </Badge>
                  </Dropdown>
                </div>
              </Tooltip> */}
              {/* <Tooltip title="Today Reminders" placement="right">
                <div
                  onClick={() => {
                    dispatch({
                      type: "FETCH_TODAY_REMINDER_REQUEST",
                      payload: { date: moment().local().format("YYYY-MM-DD") },
                    });
                  }}
                >
                  <Dropdown
                    trigger={["click"]}
                    placement={"bottomRight"}
                    menu={{
                      items: reminderItems,
                    }}
                  >
                    <Badge
                      count={store.profileState?.todayReminders?.data?.length}
                    >
                      <AiOutlineClockCircle className="headerIcons" />
                    </Badge>
                  </Dropdown>
                </div>
              </Tooltip> */}
              <Tooltip title="Mailbox">
                <Link to={"/mailbox"}>
                  <Badge
                    count={store.profileState?.profile?.unseenMailInboxCount}
                  >
                    <AiOutlineMail
                      className="headerIcons"
                      onClick={() => {
                        dispatch({ type: "MAILBOX_ONCLICK_ZERO_REQUSEST" });
                      }}
                    />
                  </Badge>
                </Link>
              </Tooltip>
              <Tooltip title="Organization Settings">
                <Link to={"/organizationalSettings/general-settings"}>
                  <FiSettings className="headerIcons" />
                </Link>
              </Tooltip>
            </div>
            <Dropdown overlay={menu}>
              <div
                className=""
                style={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <img
                  src={
                    store.profileState.profile?.profileImage
                      ? store.profileState.profile?.profileImage
                      : profile
                  }
                  alt=""
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    border: "2px solid #999",
                  }}
                />
                <div style={{ color: "black" }}>
                  <span>
                    {store.profileState.profile.firstName ?? ""}{" "}
                    {store.profileState.profile.lastName ?? ""}
                  </span>
                </div>
                <IoIosArrowDown style={{ color: "black" }} />
              </div>
            </Dropdown>
          </div>
        </Header>
        {organizationalSettings === true ? (
          <Content
            className="site-layout-background"
            style={{
              backgroundColor: "#f0f2f5",
            }}
          >
            {children}
          </Content>
        ) : dashboard === true ? (
          <Content
            className="site-layout-background"
            style={{
              margin: "24px 16px 24px 16px",
              padding: 0,
              minHeight: 280,
              backgroundColor: "#f0f2f5",
              // width: "100%",
            }}
          >
            {children}
          </Content>
        ) : (
          <Content
            className="site-layout-background"
            style={{
              margin: "24px 16px 24px 16px",
              padding: "1rem",
              borderRadius: "0.25rem",
              minHeight: 280,
              // backgroundColor: "#f0f2f5",
              // width: "100%",
            }}
          >
            {children}
          </Content>
        )}
      </Layout>
      {isAddContactVisible && (
        <AddContacts
          fromSiderDemo={true}
          isAddContactVisible={isAddContactVisible}
          setIsAddContactVisible={setIsAddContactVisible}
        />
      )}
      {isAddLeadModelVisible && (
        <AddLead
          fromSiderDemo={true}
          fromContacts={true}
          isAddLeadVisible={isAddLeadModelVisible}
          setIsAddLeadVisible={setIsAddLeadModelVisible}
        />
      )}
      {isAddAppointmentModelVisible && (
        <AddAppointment
          fromSiderDemo={true}
          isAddAppointmentModalVisible={isAddAppointmentModelVisible}
          setIsAddAppointmentModelVisible={setIsAddAppointmentModelVisible}
        />
      )}
      {isAddVisasModalVisible && (
        <AddVisaCases
          navigateCaseList={true}
          isAddVisaCasesModalVisible={isAddVisasModalVisible}
          setIsAddVisaCasesModalVisible={setIsAddVisasModalVisible}
        />
      )}
      {isAddClientModalVisible && (
        <AddClient
          fromSiderDemo={true}
          isAddClientModalVisible={isAddClientModalVisible}
          setIsAddClientModalVisible={setIsAddClientModalVisible}
        />
      )}
      {isClientDetailsModelVisible && (
        <ClientDetails
          Record={record}
          isClientDetailsModelVisible={isClientDetailsModelVisible}
          setIsClientDetailsModelVisible={setIsClientDetailsModelVisible}
        />
      )}

      {isContactsDetailsModelVisible && (
        <ContactDetails
          record={record}
          isContactsDetailsModelVisible={isContactsDetailsModelVisible}
          setIsContactsDetailsModelVisible={setIsContactsDetailsModelVisible}
        />
      )}
      {isLeadsDetailsModelVisible && (
        <LeadDetails
          // leadData={leadData}
          record={record}
          isLeadsDetailsModelVisible={isLeadsDetailsModelVisible}
          setIsLeadsDetailsModelVisible={setIsLeadsDetailsModelVisible}
        />
      )}
      {isAppointmentDetailsModalVisible && (
        <AppointmentDetails
          // appointmentData={appointmentData}
          appointmentObj={record}
          isAppointmentDetailsModalVisible={isAppointmentDetailsModalVisible}
          setIsAppointmentDetailsModalVisible={
            setIsAppointmentDetailsModalVisible
          }
        />
      )}
      {isVisaCaseDetailsModalVisible && (
        <VisaCaseDetails
          // caseData={caseData}
          visaRecord={record}
          record={record}
          isVisaCaseDetailsModalVisible={isVisaCaseDetailsModalVisible}
          setIsVisaCaseDetailsModalVisible={setIsVisaCaseDetailsModalVisible}
        />
      )}
      {isConsultantDetailsModelVisible && (
        <ConsultantDetails
          record={record}
          consultantDetailsTitle={"Employee Details"}
          isConsultantDetailsModelVisible={isConsultantDetailsModelVisible}
          setIsConsultantDetailsModelVisible={
            setIsConsultantDetailsModelVisible
          }
        />
      )}
      {isInstituteDetailsModelVisible && (
        <InstituteDetais
          record={record}
          isInstituteDetailsModelVisible={isInstituteDetailsModelVisible}
          setIsInstituteDetailsModelVisible={setIsInstituteDetailsModelVisible}
        />
      )}
      {isCourseDetailsModelVisible && (
        <CoursesDetail
          record={record}
          isCourseDetailsModelVisible={isCourseDetailsModelVisible}
          setIsCourseDetailsModelVisible={setIsCourseDetailsModelVisible}
        />
      )}
      {isCompanyDetalisModalVisible && (
        <CompanyDetails
          record={record}
          isCompanyDetalisModalVisible={isCompanyDetalisModalVisible}
          setIsCompanyDetalisModalVisible={setIsCompanyDetalisModalVisible}
        />
      )}
    </Layout>
  );
};
export default SiderDemo;
