import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logoImage from "./../../../Assets/khrouch.png";

const PrivacyPolicy = () => {
  const TermsAndPrivacyState = useSelector(
    (state) => state.TermsAndPrivacyReducer
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "FETCH_PRIVACY_POLICY_REQUEST" });
  }, []);
  console.log("Privacy policy", TermsAndPrivacyState);
  return (
    <div>
      <div className="privacy--container">
        <div className="privacy--container__navbar">
          <div className="logo--container">
            <img src={logoImage} alt="logo" />
          </div>
          <div className="navigation--container">
            <Link to="/terms-of-service" style={{ textDecoration: "none" }}>
              <p>Terms of Service</p>
            </Link>
            {/* <Link to="/" style={{ textDecoration: "none" }}>
              <p>Sign In</p>
            </Link>
            <Link to="/signup" style={{ textDecoration: "none" }}>
              <p>Sign Up</p>
            </Link> */}
          </div>
        </div>
        <div className="privacy--container__body">
          <div className="body--header">
            <p>Privacy Policy</p>
          </div>
          <div className="body--text">
            <div
              dangerouslySetInnerHTML={{
                __html: TermsAndPrivacyState?.privacyPolicy?.privacyPolicy,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
