import React, { useEffect, useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
const { Option } = Select;

const AssignDepartment = ({
  record,
  isAssignDepartmentModalVisible,
  setIsAssignDepartmentModalVisible,
}) => {
  const assignDepartmentState = useSelector((state) => state.DepartmentReducer);
  useEffect(() => {
    if (isAssignDepartmentModalVisible) {
      dispatch({ type: "FETCH_ACTIVE_DEPARTMENT_REQUEST" });
    }
  }, [isAssignDepartmentModalVisible]);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [roles, setRoles] = useState();
  const assignDepartmentSubmitHandler = (value) => {
    console.log("from assign roles", value);
    const formData = {
      departmentId: value.departmentId,
    };
    dispatch({
      type: "ASSIGN_DEPARTMENT_REQUEST",
      payload: { id: record.id, values: formData },
    });
    form.resetFields();
    setIsAssignDepartmentModalVisible(false);
  };
  const onAssignRoleChange = (e) => {
    setRoles(e);
    console.log("assign role change", e);
  };
  return (
    <Modal
      title="Assign Department"
      open={isAssignDepartmentModalVisible}
      onCancel={() => {
        setIsAssignDepartmentModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={assignDepartmentSubmitHandler}
        colon={true}
        form={form}
        fields={[
          {
            name: ["departmentId"],
            value: record?.department?.id,
          },
        ]}
      >
        <Form.Item
          label="Department"
          name={"departmentId"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please select a department!",
            },
          ]}
        >
          <Select>
            {assignDepartmentState.activeDepartments?.data?.map((dataObj) => {
              return (
                <Option
                  key={dataObj?.departmentResponse?.id}
                  value={dataObj?.departmentResponse?.id}
                >
                  {dataObj?.departmentResponse?.departmentName}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Assign Department
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AssignDepartment;
