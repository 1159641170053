import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
const { Option } = Select;

const TodoConfirmation = ({
  onSubmit = () => {
    console.log();
  },
  labelText,
  isconfirmationModalVisible,
  setIsconfirmationModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  return (
    <Modal
      title={labelText}
      open={isconfirmationModalVisible}
      onCancel={() => {
        setIsconfirmationModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={(value) => {
          onSubmit(value);
          form.resetFields();
        }}
        colon={true}
        form={form}
      >
        <Form.Item
          label="Confirmation Note"
          name={"confirmationText"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please confirm with a note!",
            },
          ]}
        >
          <Input placeholder="Confirmation text" />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Confirm
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TodoConfirmation;
