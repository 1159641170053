import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Image,
  Input,
  Modal,
  Select,
  Tag,
  Tooltip,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import client from "./../../Assets/client.png";
import { FcDocument } from "react-icons/fc";
import { GoLocation } from "react-icons/go";
import {
  formattedDate,
  formattedDateTime,
  fourDivTagResponses,
  fourTagResponses,
  threeTagResponses,
} from "../../HelperFunction";
import { Link, useNavigate } from "react-router-dom";
import { BsChatLeftText } from "react-icons/bs";
import StatusHistoryConsellorAdd from "./StatusHistoryConsellorAdd";
import SendComposedMail from "../Mailbox/SendComposedMail";
import StatusHistory from "../Leads/StatusHistory";
import AddVisaCases from "../Visas/AddVisas";
import Alert from "../Components/Alert";
import { ExclamationCircleFilled } from "@ant-design/icons";
import StatusHistoryUpdateAdd from "./StatusHistoryUpdateAdd";
import { useSelector } from "react-redux";
import CollegeFinderDrawer from "../CollegeFinder/CollegeFinderDrawer";
import CourseWishlist from "../Courses/CourseWishList";
const { Option } = Select;
const { confirm } = Modal;

const AppointmentDetails = ({
  appointmentData,
  appointmentObj,
  isAppointmentDetailsModalVisible,
  setIsAppointmentDetailsModalVisible,
}) => {
  const store = useSelector((state) => {
    return {
      appointmentState: state.AppointmentsReducer,
    };
  });
  useEffect(() => {
    dispatch({
      type: "FETCH_APPOINTMENT_BY_ID_REQUEST",
      payload: { appointmentId: appointmentObj?.id },
    });
  }, []);
  const record = store.appointmentState?.appointmentById;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [appointmentRecord, setAppointmentRecord] = useState({});
  const [
    isStatusHistoryCounsellorAddModelVisible,
    setIsStatusHistoryCounsellorAddModelVisible,
  ] = useState(false);
  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);
  const [isAddVisasModalVisible, setIsAddVisasModalVisible] = useState(false);
  const [
    isAppointmentStatusHistoryModelVisible,
    setIsAppointmentStatusHistoryModelVisible,
  ] = useState(false);
  const [
    isStatusHistoryUpdateAddModelVisible,
    setIsStatusHistoryUpdateAddModelVisible,
  ] = useState(false);
  const [isCollegeFinderModalVisible, setIsCollegeFinderModalVisible] =
    useState(false);
  const [isconfirmationModalVisible, setIsconfirmationModalVisible] =
    useState(false);
  const [isCourseWishlistModalVisible, setIsCourseWishlistModalVisible] =
    useState(false);
  const [current, setCurrent] = useState("appointmentDetails");

  const clientDetailsTabList = [
    {
      key: "appointmentDetails",
      tab: "Appointment Details",
    },
    {
      key: "courseWishlist",
      tab: "Course Wishlist",
    },
  ];
  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };
  const reloadFn = (response) => {
    dispatch({
      type: "FETCH_APPOINTMENT_BY_ID_REQUEST",
      payload: { appointmentId: appointmentObj?.id },
    });
    dispatch({
      type: "FETCH_APPOINTMENT_BY_LEAD_ID_REQUEST",
      payload: { leadId: appointmentObj?.leadId },
    });
  };

  return (
    <Drawer
      title="Appointment Details"
      open={isAppointmentDetailsModalVisible}
      onClose={() => {
        setIsAppointmentDetailsModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Card
        // loading={store.appointmentState?.isLoading}
        className="drawerStyle"
        style={{ borderRadius: "0.5rem" }}
      >
        <div
          className="flexRowWithoutStyle"
          style={{
            gap: "1rem",
            alignItems: "stretch",
          }}
        >
          {record?.leadResponse?.customerResponse?.photo ? (
            <Image
              src={record?.leadResponse?.customerResponse?.photo}
              className={"clientImage"}
            />
          ) : (
            <Image src={client} className={"clientImage"} />
          )}
          <div
            className="flexColumnWithoutStyle"
            style={{
              justifyContent: "flex-start",
              position: "relative",
              width: "100%",
              gap: "0.5rem",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: "bold" }}>
              {`${record?.firstName ?? ""} ${
                record?.lastName ?? ""
              } (Appointment ID: ${appointmentData?.appointmentPerfix ?? ""}${
                record?.id
              })`}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.4rem", color: "#aaa" }}
            >
              {record?.mobile && (
                <span className="flexRowWithGap">
                  <AiOutlinePhone
                    style={{
                      fontSize: "20",
                    }}
                  />{" "}
                  {record?.mobile ? record?.mobile : "N/A"}
                </span>
              )}
              {record?.leadResponse?.customerResponse?.address && (
                <span className="flexRowWithGap">
                  <GoLocation
                    style={{
                      fontSize: "16",
                    }}
                  />{" "}
                  {record?.leadResponse?.customerResponse?.address
                    ? record?.leadResponse?.customerResponse?.address
                    : "N/A"}
                </span>
              )}
              {record?.email && (
                <span className="flexRowWithGap">
                  <AiOutlineMail
                    style={{
                      fontSize: "16",
                    }}
                  />{" "}
                  {record?.email ? record?.email : "N/A"}
                </span>
              )}
            </div>
            <div>
              <div>
                <span className="profileTitle">Campaign:</span>
                <span>
                  {record?.leadResponse?.customerResponse?.campaign?.name ??
                    "N/A"}
                </span>
              </div>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{
                gap: "0.2rem",
                position: "absolute",
                flexWrap: "wrap",
                alignSelf: "flex-start",
                bottom: 0,
              }}
            >
              <button
                className="emailAndMessage"
                onClick={() => {
                  // setIsSendComposedEmailModalVisible(true);
                }}
              >
                <BsChatLeftText style={{ fontSize: "10px" }} />
                <span>Message</span>
              </button>
              <button
                className="emailAndMessage"
                onClick={() => {
                  setIsSendComposedEmailModalVisible(true);
                }}
              >
                <AiOutlineMail />
                <span>Email</span>
              </button>

              {record?.leadStatus !== "WIN" && (
                <Button
                  type="primary"
                  onClick={() => {
                    setIsAddVisasModalVisible(true);
                  }}
                >
                  Add Case
                </Button>
              )}
              <Button
                type="primary"
                onClick={() => {
                  setIsAppointmentStatusHistoryModelVisible(true);
                }}
              >
                Status Update
              </Button>

              <Button
                type="primary"
                onClick={() => {
                  setIsCollegeFinderModalVisible(true);
                }}
              >
                Go To College Finder
              </Button>
              {/* <Button
                type="primary"
                onClick={() => {
                  setIsCourseWishlistModalVisible(true);
                }}
              >
                Course Wishlist
              </Button> */}
            </div>
            <div
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                display: "flex",

                flexDirection: "column",
              }}
            >
              <div style={{ textAlign: "center" }}>
                {record?.appointmentStatus
                  ? fourDivTagResponses(
                      record?.appointmentStatus,
                      "PENDING",
                      "COMPLETED",
                      "CONFIRMED",
                      "CANCELLED"
                    )
                  : ""}
              </div>
              {record?.assignedCounsellor?.fullName ? (
                <div style={{ textAlign: "center" }}>
                  {record?.assignedCounsellor?.fullName}
                </div>
              ) : record?.counsellor ? (
                <div style={{ textAlign: "center" }}>{record?.counsellor}</div>
              ) : (
                <Tooltip title="Click to add counsellor">
                  <a>
                    <div
                      className="bordered"
                      onClick={() => {
                        setAppointmentRecord(record);
                        setIsStatusHistoryCounsellorAddModelVisible(true);
                      }}
                    >
                      N/A
                    </div>
                  </a>
                </Tooltip>
              )}
              {(record?.appointmentStatus === "COMPLETED" ||
                record?.appointmentStatus === "CONFIRMED") &&
              record?.isSessionStarted === false ? (
                <Button
                  className="bordered"
                  type="danger"
                  onClick={() => {
                    setIsconfirmationModalVisible(true);
                    // setRecord(record);
                    confirm({
                      title: "Do you want to start counselling now?",
                      icon: <ExclamationCircleFilled />,
                      // content: "Some descriptions",
                      okText: "Start",
                      cancelText: "No",
                      onOk() {
                        dispatch({
                          type: "START_COUNSELLING_REQUEST",
                          payload: {
                            id: record.id,
                            formData: {
                              confirmationText: "",
                            },
                            reloadAppointmentByLeadId: true,
                            reloadAppointmentByLeadFn: reloadFn,
                          },
                        });
                      },
                      onCancel() {},
                    });
                  }}
                >
                  Start Counselling
                </Button>
              ) : record?.appointmentStatus === "CONFIRMED" &&
                record?.isSessionStarted === true ? (
                <div className="flexRowWithoutStyle" style={{ gap: "0.3rem" }}>
                  <Button
                    className="bordered"
                    style={{
                      backgroundColor: "green",
                      color: "white",
                    }}
                    onClick={() => {
                      setIsStatusHistoryUpdateAddModelVisible(true);
                    }}
                  >
                    Complete
                  </Button>
                  <Button
                    className="bordered"
                    type="danger"
                    onClick={() => {
                      confirm({
                        title: "Do you want to cancel an appointment?",
                        icon: <ExclamationCircleFilled />,
                        // content: "Some descriptions",
                        okText: "Yes",
                        cancelText: "No",
                        onOk() {
                          dispatch({
                            type: "CHANGE_START_COUNSELLING_STATUS_REQUEST",
                            payload: {
                              id: record?.id,
                              status: "CANCELLED",
                            },
                            isReloadFn: true,
                            reloadFn: reloadFn,
                          });
                        },
                        onCancel() {},
                      });
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <Card
          bordered={false}
          tabList={clientDetailsTabList}
          activeTabKey={current}
          onTabChange={(key) => {
            setCurrent(key);
          }}
        >
          {current === "appointmentDetails" && (
            <>
              <div
                style={{
                  borderRadius: "1.5rem",
                  padding: "0.8rem",
                  display: "flex",
                  gap: "0.5rem",
                  flexDirection: "column",
                }}
              >
                {detailFun(
                  "Appointment ID",
                  `${appointmentData?.appointmentPerfix ?? ""}${record?.id}`
                )}
                {detailFun("Country", record?.country?.name ?? "N/A")}
                {detailFun(
                  "Country of Residence",
                  record?.leadResponse?.customerResponse?.countryOfResidence ??
                    "N/A"
                )}
                {/* {detailFun(
                  "Mobile Number",
                  `${
                    record?.mobileNumber
                      ? record?.mobileNumber
                      : record?.mobile
                      ? record?.mobile
                      : "N/A"
                  }`
                )}
                {detailFun("Email", `${record?.email ? record?.email : "N/A"}`)}
                {detailFun(
                  "Country",
                  `${
                    record?.country?.name
                      ? record?.country?.name
                      : record?.countryName
                      ? record?.countryName
                      : "N/A"
                  }`
                )} */}
                {record?.appointmentStatus === "CONFIRMED" ||
                (record?.appointmentStatus === "COMPLETED" &&
                  record?.counsellingBooking !== null) ? (
                  <>
                    {detailFun(
                      "Booking Date",
                      `${
                        record?.counsellingBooking?.bookingDate
                          ? formattedDate(
                              record?.counsellingBooking?.bookingDate
                            )
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Booking Day",
                      `${
                        record?.counsellingBooking?.bookingDay
                          ? record?.counsellingBooking?.bookingDay
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Start Time",
                      `${
                        record?.counsellingBooking?.startTime
                          ? record?.counsellingBooking?.startTime
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "End Time",
                      `${
                        record?.counsellingBooking?.endTime
                          ? record?.counsellingBooking?.endTime
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Counselling By",
                      `${
                        record?.counsellingBooking?.consultant?.fullName
                          ? record?.counsellingBooking?.consultant?.fullName
                          : record?.assignedCounsellor?.fullName
                          ? record?.assignedCounsellor?.fullName
                          : "N/A"
                      }`
                    )}
                  </>
                ) : null}

                {detailFun(
                  "Appointment Status",
                  record?.appointmentStatus
                    ? fourTagResponses(
                        record?.appointmentStatus,
                        "PENDING",
                        "COMPLETED",
                        "CONFIRMED",
                        "CANCELLED"
                      )
                    : "N/A"
                )}
                {detailFun(
                  "Purpose",
                  `${
                    record?.purposeName
                      ? record?.purposeName
                      : record?.purpose?.purposeName
                      ? record?.purpose?.purposeName
                      : record?.leadResponse?.purposeName
                      ? record?.leadResponse?.purposeName
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "Lead By",
                  `${record?.leadBy ? record?.leadBy : "N/A"}`
                )}
                {detailFun(
                  "Counsellor",
                  `${
                    record?.assignedCounsellor?.fullName
                      ? record?.assignedCounsellor?.fullName
                      : record?.counsellor
                      ? record?.counsellor
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "Sales Rep",
                  `${
                    record?.salesRepresentative
                      ? record?.salesRepresentative?.salesRepName
                      : record?.salesRepName
                      ? record?.salesRepName
                      : record?.leadResponse?.salesRepName
                      ? record?.leadResponse.salesRepName
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "Weightage",
                  `${record?.updateWeightage ? record?.updateWeightage : "N/A"}`
                )}

                {detailFun(
                  "Reminder Time",
                  `${
                    record?.reminderDateAndTime
                      ? formattedDateTime(record?.reminderDateAndTime)
                      : "N/A"
                  }`
                )}
                {detailFun(
                  "Appointment Date",
                  `${
                    record?.appointmentDate
                      ? formattedDate(record?.appointmentDate)
                      : "N/A"
                  }`
                )}
                {detailFun("Note", `${record?.note ? record?.note : "N/A"}`)}
              </div>
            </>
          )}
          {current === "courseWishlist" && (
            <CourseWishlist appointmentRecord={record} />
          )}
          {isStatusHistoryCounsellorAddModelVisible && (
            <StatusHistoryConsellorAdd
              type={"APPOINTMENT"}
              appointmentData={appointmentData}
              appointmentRecord={record}
              record={record?.leadResponse}
              isStatusHistoryModalVisible={
                isStatusHistoryCounsellorAddModelVisible
              }
              setIsStatusHistoryModalVisible={
                setIsStatusHistoryCounsellorAddModelVisible
              }
            />
          )}
          {isSendComposedEmailModalVisible && (
            <SendComposedMail
              composeMailRecord={{
                toUser: [record?.email],
              }}
              isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
              setIsSendComposedEmailModalVisible={
                setIsSendComposedEmailModalVisible
              }
            />
          )}
          {isAddVisasModalVisible && (
            <AddVisaCases
              navigateCaseList={true}
              fromCounselling={true}
              recordData={record}
              isAddVisaCasesModalVisible={isAddVisasModalVisible}
              setIsAddVisaCasesModalVisible={setIsAddVisasModalVisible}
            />
          )}
          {isAppointmentStatusHistoryModelVisible && (
            <StatusHistory
              type={"APPOINTMENT"}
              appointmentData={appointmentData}
              appointmentRecord={record}
              record={record?.leadResponse}
              isStatusHistoryModalVisible={
                isAppointmentStatusHistoryModelVisible
              }
              setIsStatusHistoryModalVisible={
                setIsAppointmentStatusHistoryModelVisible
              }
            />
          )}
          {isStatusHistoryUpdateAddModelVisible && (
            <StatusHistoryUpdateAdd
              type={"APPOINTMENT"}
              isCounsellingCompleted={true}
              title={"Do you want to complete counselling now?"}
              appointmentData={appointmentData}
              appointmentRecord={record}
              record={record?.leadResponse}
              isStatusHistoryModalVisible={isStatusHistoryUpdateAddModelVisible}
              setIsStatusHistoryModalVisible={
                setIsStatusHistoryUpdateAddModelVisible
              }
            />
          )}
          {isCollegeFinderModalVisible && (
            <CollegeFinderDrawer
              record={record}
              isCollegeFinderModalVisible={isCollegeFinderModalVisible}
              setIsCollegeFinderModalVisible={setIsCollegeFinderModalVisible}
            />
          )}
          {/* {isCourseWishlistModalVisible && (
            <CourseWishlist
              appointmentRecord={record}
              isCourseWishlistModalVisible={isCourseWishlistModalVisible}
              setIsCourseWishlistModalVisible={setIsCourseWishlistModalVisible}
            />
          )} */}
        </Card>
      </Card>
    </Drawer>
  );
};

export default AppointmentDetails;
