import { Button, Checkbox, Form, Input, Carousel } from "antd";
import { VscLock } from "react-icons/vsc";
import React, { Fragment } from "react";
import classes from "./Login.module.css";
import logoImage from "./../../Assets/khrouch3.png";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import CarouselComponent from "./CarouselComponent";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { useState } from "react";
import { useEffect } from "react";

const CompanyLogin = () => {
  const loginState = useSelector((state) => state.LoginReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [companyDomain, setCompanyDomain] = useState(null);

  useEffect(() => {
    dispatch({ type: "CLEAR_AUTHENTICATION_REQUEST" });
  }, []);

  const loginSubmitHandler = (values) => {
    // navigate(`/${encodeURIComponent(values?.domain)}`);

    dispatch({
      type: "COMPANY_AUTHENTICATION_REQUEST",
      payload: { values },
    });
  };

  console.log("Is authenticated", loginState.isAuthenticated);
  return (
    <Fragment>
      {loginState.isCompanyAuthenticated ? (
        <Navigate to={`/${companyDomain}`} replace={true} />
      ) : (
        <div style={{ display: "flex" }}>
          <div className={classes.companyLoginContainer}>
            <div className={classes.formContainer}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={logoImage} style={{ width: "15%" }} />
              </div>
              <h3 style={{ textAlign: "center", fontSize: "16px" }}>
                Welcome To Khrouch
              </h3>
              <p style={{ textAlign: "center", fontSize: "16px" }}>
                Please enter your company's sub-domain to continue with Khrouch.
              </p>
              <Form
                layout="vertical"
                className={classes.loginForm}
                onFinish={loginSubmitHandler}
              >
                <div className={classes.emailAndPassword}>
                  <Form.Item
                    name={"domain"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your domain URL to continue!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="company name"
                      // addonBefore="clients.khrouch.com/"

                      addonAfter=".khrouch.com"
                      size="large"
                      style={{ borderRadius: "25px" }}
                      onChange={(e) => {
                        setCompanyDomain(e.target.value);
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div className="flexColumnwithoutStyle">
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ borderRadius: "2rem" }}
                      >
                        Continue
                      </Button>
                      {/* <Link
                        to={"/password_reset"}
                        style={{
                          textAlign: "center",
                          marginTop: "6px",
                          fontSize: "16px",
                        }}
                      >
                        Forgot Password?
                      </Link> */}
                    </div>
                  </Form.Item>
                </div>
              </Form>

              <div style={{ textAlign: "center", fontSize: "16px" }}>
                <Link to={"/email-sign-in"} style={{ fontSize: "16px" }}>
                  Try with company email address
                </Link>
              </div>
              <div
                style={{
                  marginTop: "0.5rem",
                  textAlign: "center",
                  fontSize: "16px",
                }}
              >
                Don't have an account?{" "}
                <Link to={"/register"} style={{ fontSize: "16px" }}>
                  Register here
                </Link>
              </div>
            </div>
          </div>
          <div style={{ width: "30%" }}>
            <CarouselComponent />
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default CompanyLogin;
