import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  Radio,
  Select,
  Space,
  TimePicker,
} from "antd";
import { AiOutlineMail, AiOutlinePhone, AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import logo3 from "./../../Assets/khrouch3.png";
import {
  date,
  dateAndTime,
  dateAndTimeUTC,
  getTimeZone,
  YYYYMMDD,
} from "../../HelperFunction";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "antd/lib/form/Form";
import { GoLocation } from "react-icons/go";
import { IoLocationSharp } from "react-icons/io5";
const { Option } = Select;
const { TextArea } = Input;
const AppointmentForm = () => {
  const store = useSelector((state) => {
    return {
      campaignState: state.CampaignReducer,
    };
  });

  const campaignId = new URLSearchParams(window.location.search).get(
    "campaignId"
  );
  const companyId = new URLSearchParams(window.location.search).get(
    "companyId"
  );
  const consultantId = new URLSearchParams(window.location.search).get(
    "consultantId"
  );

  const dispatch = useDispatch();
  const successfn = (response) => {
    setCampaignData((previousdata) => {
      return {
        ...previousdata,
        isCouncelling: response?.data?.sessionTime ? true : false,
      };
    });
  };
  const successFunction = (response) => {
    setCampaignData((previousData) => {
      return {
        ...previousData,
        counsellorId: response?.data?.selectedConsultant?.id,
        counsellorName: response?.data?.selectedConsultant?.fullName,
      };
    });
  };
  useEffect(() => {
    dispatch({
      type: "FETCH_APPOINTMENT_FORM_DETAILS_OF_CAMPAIGN_REQUEST",
      payload: {
        campaignId: campaignId,
        isSuccessFn: true,
        successfn: successfn,
      },
    });

    dispatch({
      type: "FETCH_CAMPAIGN_FORM_DROPDOWN_REQUEST",
      payload: {
        companyId: companyId,
        consultantId: consultantId,
        isSucessFn: true,
        successFunction: successFunction,
      },
    });
    dispatch({
      type: "FETCH_COMPANY_DETAILS_FOR_LOGO_AND_FOOTER_REQUEST",
      payload: {
        companyId: companyId,
      },
    });
  }, []);
  const [campaignData, setCampaignData] = useState({
    firstName: null,
    lastname: null,
    email: null,
    mobileNumber: null,
    leadSourceId: null,
    countryId: null,
    countryName: null,
    countryOfResidence: null,
    isCouncelling: false,
    counsellorId: null,
    counsellorName: null,
    sessionTime: null,
    appointmentDate: null,
    remainderDate: moment(),
    remainderTime: moment(),
    note: null,
    sendByEmail: null,
  });
  useEffect(() => {
    if (
      campaignData?.counsellorId &&
      campaignData?.appointmentDate &&
      campaignData?.sessionTime &&
      campaignData?.isCouncelling
    ) {
      if (campaignData?.counsellorId !== "ANY") {
        dispatch({
          type: "FETCH_TIMING_DROPDOWN_FOR_CAMPAIGN_REQUEST",
          payload: {
            counsellorId: campaignData?.counsellorId,
            sessionTime: campaignData?.sessionTime,
            values: {
              appointmentDate: campaignData?.appointmentDate
                ? YYYYMMDD(campaignData?.appointmentDate)
                : null,
              timeOffset: getTimeZone(),
            },
          },
        });
      }
    }
  }, [
    campaignData?.counsellorId,
    campaignData?.appointmentDate,
    campaignData?.sessionTime,
    campaignData?.isCouncelling,
  ]);

  const [form] = Form.useForm();
  const params = useParams();
  const navigate = useNavigate();

  const addLeadSubmitHandler = (value) => {
    const bookingDate =
      value.appointmentDate && campaignData?.isCouncelling
        ? date(value.appointmentDate)
        : null;
    const reminderDateAndTime = dateAndTime(
      value.reminderDate,
      value.reminderTime
    );
    const bookingDay = campaignData?.isCouncelling
      ? moment(value.appointmentDate).format("dddd").toUpperCase()
      : null;
    const appointmentDateTime = dateAndTime(
      value.appointmentDate,
      value.appointmentTime
    );
    const formData = {
      ...value,
      firstName: campaignData.firstName,
      lastName: campaignData.lastname,
      email: campaignData.email,
      mobile: campaignData.mobileNumber,
      countryId: campaignData.countryId,
      countryOfResidence: campaignData?.countryOfResidence,
      assignCounsellor: campaignData?.isCouncelling,
      consultantId:
        campaignData?.counsellorId === "ANY"
          ? null
          : campaignData?.counsellorId,
      counsellor:
        campaignData?.counsellorId === "ANY"
          ? null
          : campaignData?.counsellorId,
      bookingDate,
      bookingDay: bookingDay,
      appointmentDateTime,
      sessionTime: campaignData.sessionTime,
      reminderDateAndTime: reminderDateAndTime,
      note: campaignData.note,
      startTime:
        campaignData?.counsellorId === "ANY"
          ? moment(value.startTime).utc().local().format("HH:mm:ss")
          : value.startTime,
      sendByEmail: campaignData.sendByEmail,
    };
    dispatch({
      type: "ADD_APPOINTMENT_FROM_CAMPAIGN_REQUEST",
      payload: {
        formData: formData,
        campaignId: campaignId,
        responseSuccessFunction: responseSuccessFunction,
        responseErrorFunction: responseErrorFunction,
      },
    });
    dispatch({ type: "CLEAR_CAMPAIGN_AVAILABLE_DATE_AND_TIME" });
  };

  const responseSuccessFunction = (response) => {
    navigate("/submissionSuccess");
  };
  const responseErrorFunction = (response) => {
    navigate("/submissionFailed");
  };
  const successFun = (response) => {
    setCampaignData((previousData) => {
      return {
        ...previousData,
        counsellorId: null,
        counsellorName: null,
      };
    });
  };

  return (
    <div className="campaignForm">
      <div className="campaignActualForm">
        <div style={{ padding: "2rem" }}>
          <Alert
            description="Please fill the following details to make an appointment!"
            type="info"
            style={{ padding: "0.5rem 0.5rem", marginBottom: "0.3rem" }}
          />
          <div
            className="flexColumnWithoutStyle"
            style={{
              alignItems: "center",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            {store.campaignState?.companyLogoAndFooterDetails?.companyLogo && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  // src={logo3}
                  src={
                    store.campaignState?.companyLogoAndFooterDetails
                      ?.companyLogo
                  }
                  style={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "0.5rem",
                  }}
                />
              </div>
            )}
            <div
              style={{
                padding: "0 0 0 1rem",
                fontSize: "36px",
                fontWeight: "500",
                marginTop: "0",
                marginBottom: "30px",
                color: "#008080",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <strong style={{ textTransform: "uppercase" }}>
                {store.campaignState?.companyLogoAndFooterDetails?.companyName}
              </strong>
            </div>
          </div>
          <h2 style={{ textAlign: "center" }}>MAKE YOUR APPOINTMENT:</h2>
          <Divider />
          <Form
            layout="vertical"
            onFinish={addLeadSubmitHandler}
            colon={true}
            form={form}
            fields={[
              {
                name: ["firstName"],
                value: campaignData?.firstName,
              },
              {
                name: ["lastName"],
                value: campaignData?.lastname,
              },
              {
                name: ["email"],
                value: campaignData?.email,
              },
              {
                name: ["mobile"],
                value: campaignData?.mobileNumber,
              },
              {
                name: ["counsellor"],
                value: campaignData?.counsellorName,
              },
              {
                name: ["bookingStatus"],
                value: campaignData?.isCouncelling,
              },
              {
                name: ["countryOfResidence"],
                value: campaignData?.countryOfResidence,
              },
            ]}
            initialValues={{
              remainderDate: moment(),
              remainderTime: moment(),
              referral: false,
            }}
          >
            <div
              className="flexRowWithoutStyle"
              style={{
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              {store.campaignState?.appointmentFormDetails?.firstName && (
                <Form.Item
                  label="First Name"
                  name={"firstName"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter first name!",
                    },
                  ]}
                >
                  <Input
                    placeholder="First Name"
                    onChange={(e) => {
                      setCampaignData((previousData) => {
                        return {
                          ...previousData,
                          firstName: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>
              )}
              {store.campaignState?.appointmentFormDetails?.lastName && (
                <Form.Item
                  label="Last Name"
                  name={"lastName"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter last name!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Last Name"
                    onChange={(e) => {
                      setCampaignData((previousData) => {
                        return {
                          ...previousData,
                          lastname: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>
              )}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              {store.campaignState?.appointmentFormDetails?.email && (
                <Form.Item
                  label="Email"
                  name={"email"}
                  style={{ width: "100%" }}
                >
                  <Input
                    type="email"
                    placeholder="user@domain.com"
                    onChange={(e) => {
                      setCampaignData((previousData) => {
                        return {
                          ...previousData,
                          email: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>
              )}
              {store.campaignState?.appointmentFormDetails?.mobile && (
                <Form.Item
                  label="Mobile Number"
                  name={"mobile"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      pattern: /^[\d]{9,11}$/,
                      message: "Mobile number should contain 9 to 11 numbers",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Mobile Number"
                    onChange={(e) => {
                      setCampaignData((previousData) => {
                        return {
                          ...previousData,
                          mobileNumber: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>
              )}
            </div>

            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.3rem", width: "100%" }}
              >
                {store.campaignState?.appointmentFormDetails?.country && (
                  <Form.Item
                    label="Country to Study"
                    name={"countryId"}
                    style={{ width: "100%" }}
                  >
                    <Select>
                      {store.campaignState.leadsDropdown?.country?.map(
                        (dataObj) => {
                          return (
                            <Option key={dataObj.id}>
                              <div
                                onClick={() => {
                                  setCampaignData((previousData) => {
                                    return {
                                      ...previousData,
                                      countryId: dataObj.id,
                                      countryName: dataObj.countryName,
                                    };
                                  });
                                }}
                                className={"selectOptionDiv"}
                              >
                                {dataObj.countryName}
                              </div>
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                )}
                {store.campaignState?.appointmentFormDetails
                  ?.countryOfResidence && (
                  <Form.Item
                    label="Country of Residence"
                    name={"countryOfResidence"}
                    style={{ width: "100%" }}
                  >
                    <Select>
                      {store.campaignState.leadsDropdown?.country?.map(
                        (dataObj) => {
                          return (
                            <Option key={dataObj.id}>
                              <div
                                onClick={() => {
                                  setCampaignData((previousData) => {
                                    return {
                                      ...previousData,
                                      countryOfResidence: dataObj.countryName,
                                    };
                                  });
                                }}
                                className={"selectOptionDiv"}
                              >
                                {dataObj.countryName}
                              </div>
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                )}
              </div>
            </div>
            {store.campaignState?.appointmentFormDetails?.appointmentDate && (
              <>
                <Form.Item
                  label="Want to confirm appointment now?"
                  name={"bookingStatus"}
                  style={{ width: "100%" }}
                >
                  <Radio.Group
                    onChange={(e) => {
                      setCampaignData((previousdata) => {
                        return {
                          ...previousdata,
                          isCouncelling: e.target.value,
                        };
                      });
                    }}
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
                {campaignData?.isCouncelling === false ? (
                  <>
                    <Form.Item
                      label="Appointment Date"
                      name={"appointmentDate"}
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter appointment date!",
                        },
                      ]}
                    >
                      <DatePicker
                        disabledDate={(cd) => {
                          const d = new Date();
                          return cd < d;
                        }}
                        onChange={(e) => {
                          setCampaignData((previousData) => {
                            return {
                              ...previousData,
                              appointmentDate: e,
                            };
                          });
                          if (!consultantId) {
                            dispatch({
                              type: "FETCH_COUNSELLOR_FOR_APPOINTMENT_DATE_REQUEST",
                              payload: {
                                appointmentDate: YYYYMMDD(e),
                                campaignId: campaignId,
                                isSuccessFun: true,
                                successFun: successFun,
                              },
                            });
                          }
                        }}
                      />
                    </Form.Item>
                    {store.campaignState?.appointmentFormDetails
                      ?.consultant && (
                      <>
                        {consultantId ? (
                          <div
                            className="flexRowWithoutStyle"
                            style={{ gap: "0.3rem", width: "100%" }}
                          >
                            <Form.Item
                              label="Counsellor"
                              name={"counsellor"}
                              style={{ width: "100%" }}
                            >
                              <Select>
                                <Option id={campaignData.counsellorId}>
                                  <div
                                    onClick={() => {
                                      setCampaignData((previousData) => {
                                        return {
                                          ...previousData,
                                          counsellorId:
                                            campaignData.counsellorId,
                                          counsellorName:
                                            campaignData.counsellorName,
                                        };
                                      });
                                    }}
                                    className={"selectOptionDiv"}
                                  >
                                    {campaignData.counsellorName}
                                  </div>
                                </Option>
                              </Select>
                            </Form.Item>
                          </div>
                        ) : (
                          <div
                            className="flexRowWithoutStyle"
                            style={{ gap: "0.3rem", width: "100%" }}
                          >
                            <Form.Item
                              label="Counsellor"
                              name={"counsellor"}
                              style={{ width: "100%" }}
                            >
                              <Select>
                                {store.campaignState?.counsellorForAdate?.map(
                                  (dataObj) => {
                                    return (
                                      <Option key={dataObj.id}>
                                        <div
                                          onClick={() => {
                                            setCampaignData((previousData) => {
                                              return {
                                                ...previousData,
                                                counsellorId: dataObj.id,
                                                counsellorName:
                                                  dataObj.fullName,
                                              };
                                            });
                                          }}
                                          className={"selectOptionDiv"}
                                        >
                                          {dataObj.fullName}
                                        </div>
                                      </Option>
                                    );
                                  }
                                )}
                              </Select>
                            </Form.Item>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div
                      className="flexRowWithoutStyle"
                      style={{ justifyContent: "space-between", gap: "1rem" }}
                    >
                      <Form.Item
                        label="Booking Date"
                        name={"appointmentDate"}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please pick a booking date!",
                          },
                        ]}
                      >
                        <DatePicker
                          format={"DD-MM-YYYY"}
                          disabledDate={(cd) => {
                            const d = new Date();
                            return cd < d;
                          }}
                          onChange={(e) => {
                            setCampaignData((previousData) => {
                              return {
                                ...previousData,
                                appointmentDate: e,
                              };
                            });
                            if (!consultantId) {
                              dispatch({
                                type: "FETCH_COUNSELLOR_FOR_APPOINTMENT_DATE_REQUEST",
                                payload: {
                                  appointmentDate: YYYYMMDD(e),
                                  campaignId: campaignId,
                                  isSuccessFun: true,
                                  successFun: successFun,
                                },
                              });
                            }
                          }}
                        />
                      </Form.Item>
                      <div
                        className="flexRowWithoutStyle"
                        style={{ gap: "0.3rem", width: "100%" }}
                      >
                        {consultantId ? (
                          <div
                            className="flexRowWithoutStyle"
                            style={{ gap: "0.3rem", width: "100%" }}
                          >
                            <Form.Item
                              label="Counsellor"
                              name={"counsellor"}
                              style={{ width: "100%" }}
                            >
                              <Select>
                                <Option id={campaignData.counsellorId}>
                                  <div
                                    onClick={() => {
                                      setCampaignData((previousData) => {
                                        return {
                                          ...previousData,
                                          counsellorId:
                                            campaignData.counsellorId,
                                          counsellorName:
                                            campaignData.counsellorName,
                                        };
                                      });
                                    }}
                                    className={"selectOptionDiv"}
                                  >
                                    {campaignData.counsellorName}
                                  </div>
                                </Option>
                              </Select>
                            </Form.Item>
                          </div>
                        ) : (
                          <Form.Item
                            label="Counsellor"
                            name={"counsellor"}
                            style={{ width: "100%" }}
                            rules={[
                              {
                                required: true,
                                message: "Please select counsellor!",
                              },
                            ]}
                          >
                            <Select>
                              <Option key={"ANY"} value={"ANY"}>
                                <div
                                  onClick={() => {
                                    setCampaignData((previousData) => {
                                      return {
                                        ...previousData,
                                        counsellorId: "ANY",
                                        counsellorName: "ANY",
                                      };
                                    });
                                  }}
                                  className={"selectOptionDiv"}
                                >
                                  ANY
                                </div>
                              </Option>
                              {store.campaignState.counsellorForAdate?.map(
                                (dataObj) => {
                                  return (
                                    <Option key={dataObj.id}>
                                      <div
                                        onClick={() => {
                                          setCampaignData((previousData) => {
                                            return {
                                              ...previousData,
                                              counsellorId: dataObj.id,
                                              counsellorName: dataObj.fullName,
                                            };
                                          });
                                        }}
                                        className={"selectOptionDiv"}
                                      >
                                        {dataObj.fullName}
                                      </div>
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        )}
                      </div>
                    </div>

                    <Form.Item
                      label="Session Time"
                      name={"sessionTime"}
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Please select a session time!",
                        },
                      ]}
                    >
                      <Select
                        onChange={(e) => {
                          setCampaignData((previousData) => {
                            return {
                              ...previousData,
                              sessionTime: e,
                            };
                          });
                        }}
                      >
                        <Option value={"THIRTY_MINUTES"}>30 Minutes</Option>
                        <Option value={"ONE_HOUR"}>1 Hour</Option>
                        <Option value={"TWO_HOUR"}>2 Hour</Option>
                      </Select>
                    </Form.Item>
                    {campaignData.counsellorName === "ANY" ? (
                      <Form.Item
                        label="Pick start time"
                        name={"startTime"}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please pick a your time slot!",
                          },
                        ]}
                      >
                        <TimePicker />
                      </Form.Item>
                    ) : (
                      <Form.Item
                        label="Available Time Slots"
                        name={"startTime"}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please pick a your time slot!",
                          },
                        ]}
                      >
                        <Radio.Group
                          onChange={(e) => {}}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          {store?.campaignState &&
                            store?.campaignState?.timingDropdown?.map(
                              (dataObj, index) => {
                                return (
                                  <div key={index}>
                                    <Radio value={dataObj.startTime}>
                                      {dataObj.startTime} - {dataObj.endTime}
                                    </Radio>
                                  </div>
                                );
                              }
                            )}
                        </Radio.Group>
                      </Form.Item>
                    )}
                  </>
                )}
              </>
            )}
            {store.campaignState?.appointmentFormDetails
              ?.reminderDateAndTime && (
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.3rem", width: "100%" }}
              >
                <Form.Item
                  label="Reminder Date"
                  name={"remainderDate"}
                  style={{ width: "100%" }}
                >
                  <DatePicker
                    format={"DD-MM-YYYY"}
                    disabledDate={(currentDate) => {
                      const date = new Date();
                      return currentDate < date;
                    }}
                    onChange={(e) => {
                      setCampaignData((previouData) => {
                        return {
                          ...previouData,
                          remainderDate: e,
                        };
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Reminder Time"
                  name={"remainderTime"}
                  style={{ width: "100%" }}
                >
                  <TimePicker
                    onChange={(e) => {
                      setCampaignData((previouData) => {
                        return {
                          ...previouData,
                          remainderTime: e,
                        };
                      });
                    }}
                  />
                </Form.Item>
              </div>
            )}
            {store.campaignState?.appointmentFormDetails?.note && (
              <Form.Item label="Note" name={"note"} style={{ width: "100%" }}>
                <TextArea
                  placeholder="Write something here"
                  onChange={(e) => {
                    setCampaignData((previouData) => {
                      return {
                        ...previouData,
                        note: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
            )}
            {store.campaignState?.appointmentFormDetails?.email && (
              <Form.Item name={"sendByEmail"} valuePropName="checked">
                <Checkbox
                  onChange={(e) => {
                    setCampaignData((previouData) => {
                      return {
                        ...previouData,
                        sendByEmail: e.target.checked,
                      };
                    });
                  }}
                >
                  Send emails
                </Checkbox>
              </Form.Item>
            )}
            <Form.Item
              name="leadSubmit"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      (getFieldValue("email") === undefined ||
                        getFieldValue("email") === "" ||
                        getFieldValue("email") === null) &&
                      (getFieldValue("mobile") === undefined ||
                        getFieldValue("mobile") === null ||
                        getFieldValue("mobile") === "")
                    ) {
                      return Promise.reject(
                        new Error("Please enter either email or phone number!")
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
              hasFeedback
            >
              <Button
                name={"leadSubmit"}
                block
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{
            justifyContent: "space-evenly",
            backgroundColor: "#253340",
            borderRadius: "0 0 20px 20px",
            padding: "2rem 2rem 3rem 2rem",
            gap: "0.3rem",
          }}
        >
          <div style={{ color: "white", fontSize: "22px", fontWeight: "400" }}>
            Contact Us
          </div>
          {store?.campaignState?.companyLogoAndFooterDetails?.emailId && (
            <div
              className="flexRowWithoutStyle"
              style={{ color: "white", gap: "0.3rem" }}
            >
              <div className="footerIcon">
                <AiOutlineMail style={{ fontSize: "20px" }} />
              </div>
              <span>
                <a
                  href={`mailto:${store?.campaignState?.companyLogoAndFooterDetails?.emailId}`}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {store?.campaignState?.companyLogoAndFooterDetails?.emailId}
                </a>
              </span>
            </div>
          )}
          {store?.campaignState?.companyLogoAndFooterDetails
            ?.companyAddress && (
            <div
              className="flexRowWithoutStyle"
              style={{ color: "white", gap: "0.3rem" }}
            >
              <div className="footerIcon">
                <IoLocationSharp style={{ fontSize: "20px" }} />
              </div>
              <span>
                {
                  store?.campaignState?.companyLogoAndFooterDetails
                    ?.companyAddress
                }
              </span>
            </div>
          )}
          {store?.campaignState?.companyLogoAndFooterDetails?.phoneNumber && (
            <div
              className="flexRowWithoutStyle"
              style={{ color: "white", gap: "0.3rem" }}
            >
              <div className="footerIcon">
                <AiOutlinePhone style={{ fontSize: "20px" }} />
              </div>
              <span>
                <a
                  href={`tel:${store?.campaignState?.companyLogoAndFooterDetails?.callingCode} ${store?.campaignState?.companyLogoAndFooterDetails?.phoneNumber}`}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {store?.campaignState?.companyLogoAndFooterDetails
                    ?.callingCode ?? ""}
                  {
                    store?.campaignState?.companyLogoAndFooterDetails
                      ?.phoneNumber
                  }
                </a>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppointmentForm;
