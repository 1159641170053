import React, { useEffect, useState } from "react";
import {
  Avatar,
  Form,
  Image,
  Input,
  Pagination,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import AddInstitute from "./AddInstitute";
import UpdateInstitute from "./UpdateInstitute";
import SiderDemo from "../../../Components/Layout/SiderDemo";
import institute from "../../../Assets/Company.png";
import activeInstitute from "../../../Assets/activeCompanies.png";
import { MdOutlineDisabledVisible } from "react-icons/md";
import { AiOutlineEye, AiOutlinePlus, AiOutlinePoweroff } from "react-icons/ai";
import { HiOutlineDocumentPlus } from "react-icons/hi2";
import AddCourseFrmInstitute from "./AddCourseFrmInstitute";
import TableSkeleton from "../../../Helpers/TableSkeleton/tableSkelaton";
import ManageInstituteDocuments from "./ManageInstituteDocuments";
import InstituteDetais from "./InstituteDetails";
import { BiBlock } from "react-icons/bi";
import AddCasePackage from "../../Visas/AddCasePackage";
import { BsCalendar2Plus } from "react-icons/bs";
const { Search } = Input;

const columns = [
  {
    title: "Institute Name",
    dataIndex: "instituteName",
    sorter: (a, b) => {
      console.log(
        "form contact sorter a",
        a?.fullname?.props?.children?.props?.children?.props?.children
      );
      console.log("form contact sorter b", b);
      if (
        a?.instituteName?.props?.children?.props?.children?.props?.children <
        b?.instituteName?.props?.children?.props?.children?.props?.children
      ) {
        return -1;
      }
      if (
        a?.instituteName?.props?.children?.props?.children?.props?.children >
        b?.instituteName?.props?.children?.props?.children?.props?.children
      ) {
        return 1;
      }
      return 0;
    },
    width: 250,
    align: "center",
  },
  {
    title: "Image",
    dataIndex: "image",
    width: 250,
    align: "center",
  },
  {
    title: "Location",
    dataIndex: "location",
    width: 250,
    align: "center",
  },
  {
    title: "Email",
    dataIndex: "email",
    width: 250,
    align: "center",
  },

  {
    title: "University URL",
    dataIndex: "universityUrl",
    width: 250,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 100,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const Institute = () => {
  const instituteState = useSelector((state) => state.InstituteReducer);
  const [searchValue, setSearchValue] = useState("");

  const [isAddInstituteModalVisible, setIsAddInstituteModalVisible] =
    useState(false);
  const [isUpdateInstituteModelVisible, setIsUpdateInstituteModelVisible] =
    useState(false);
  const [
    isAddCourseFrmInstituteModelVisible,
    setIsAddCourseFrmInstituteModelVisible,
  ] = useState(false);
  const [
    isAddInstituteDocumentsModelVisible,
    setIsAddInstituteDocumentsModelVisible,
  ] = useState(false);
  const [isInstituteDetailsModelVisible, setIsInstituteDetailsModelVisible] =
    useState(false);
  const [isAddPackageModalVisible, setIsAddPackageModalVisible] =
    useState(false);
  const [instituteType, setInstituteType] = useState("getAllInstitute");
  const [record, setRecord] = useState({});
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const onSearch = (e) => {
    console.log("This is e from search", e);
    if (instituteType === "active") {
      dispatch({
        type: "SEARCH_ACTIVE_INSTITUTES_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          searchKey: { keyword: e },
        },
      });
    } else if (instituteType === "getAllInstitute") {
      dispatch({
        type: "SEARCH_INSTITUTES_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          searchKey: { keyword: e },
        },
      });
    }
  };
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };
  const instituteApiCall = () => {
    if (instituteType === "active") {
      dispatch({
        type: "FETCH_ACTIVE_INSTITUTES_REQUEST",
        payload: { page, pageSize },
      });
    } else {
      dispatch({
        type: "FETCH_INSTITUTES_REQUEST",
        payload: { page, pageSize },
        // payload: { page: current, pageSize },
      });
    }
  };
  useEffect(() => {
    instituteApiCall();
  }, [instituteType, isAddInstituteDocumentsModelVisible, page, pageSize]);
  let data = [];
  if (instituteType === "getAllInstitute") {
    console.log("from institute all data", instituteState);
    data = instituteState.institutes?.data?.map((dataObj) => {
      return {
        key: dataObj.id,

        instituteName: (
          <Tooltip title="Institute Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  setIsInstituteDetailsModelVisible(true);
                }}
              >
                {dataObj.instituteName ?? "N/A"}
              </div>
            </a>
          </Tooltip>
        ),
        image: dataObj.image ? (
          <Image
            src={dataObj.image}
            style={{ height: "50px", width: "50px", border: "1px solid #ccc" }}
          />
        ) : (
          "N/A"
        ),
        location: dataObj.location,
        email: dataObj.email,
        commission: dataObj.percentage,
        universityUrl: dataObj.universityUrl,
        status:
          dataObj.status === "ACTIVE" ? (
            <p className="greenTag">ACTIVE</p>
          ) : (
            <p className="redTag">INACTIVE</p>
          ),
        actions: (
          <Space size="middle">
            {dataObj.status === "ACTIVE" && (
              <>
                <Tooltip title="Add Course">
                  <a>
                    <div
                      className="bordered"
                      onClick={() => {
                        setRecord(dataObj);
                        setIsAddCourseFrmInstituteModelVisible(true);
                      }}
                    >
                      <AiOutlinePlus />
                    </div>
                  </a>
                </Tooltip>
                <Tooltip title="Add Package">
                  <a>
                    <div
                      className="bordered"
                      onClick={() => {
                        setRecord(dataObj);
                        setIsAddPackageModalVisible(true);
                      }}
                    >
                      <BsCalendar2Plus />
                    </div>
                  </a>
                </Tooltip>
              </>
            )}

            <Tooltip title="Update Institute">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsUpdateInstituteModelVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <EditOutlined />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Manage Institute Documents">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsAddInstituteDocumentsModelVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <HiOutlineDocumentPlus />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Delete Institute">
              <Popconfirm
                title="Are you sure to delete this Institute?"
                onConfirm={() => {
                  dispatch({
                    type: "DELETE_INSTITUTE_REQUEST",
                    payload: { id: dataObj.id },
                  });
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <div className="bordered">
                    <DeleteOutlined />
                  </div>
                </a>
              </Popconfirm>
            </Tooltip>
            <Tooltip title="Institute Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setRecord(dataObj);
                    setIsInstituteDetailsModelVisible(true);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
            {dataObj.status === "INACTIVE" && (
              <Tooltip title="Activate Institute">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus =
                        dataObj.status === "INACTIVE" ? "active" : "inactive";
                      dispatch({
                        type: "TOGGLE_INSTITUTE_STATUS_REQUEST",
                        payload: { id: dataObj.id, actionStatus },
                      });
                    }}
                  >
                    <AiOutlinePoweroff />
                  </div>
                </a>
              </Tooltip>
            )}
            {dataObj.status === "ACTIVE" && (
              <Tooltip title="Deactivate Institute">
                <a style={{ color: "red" }}>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus =
                        dataObj.status === "INACTIVE" ? "active" : "inactive";
                      dispatch({
                        type: "TOGGLE_INSTITUTE_STATUS_REQUEST",
                        payload: { id: dataObj.id, actionStatus },
                      });
                    }}
                  >
                    <BiBlock />
                  </div>
                </a>
              </Tooltip>
            )}
          </Space>
        ),
      };
    });
  } else if (instituteType === "active") {
    console.log("form institute active", instituteState.activeInstitutes);
    data = instituteState.activeInstitutes?.map((dataObj) => {
      return {
        key: dataObj.id,
        instituteName: (
          <Tooltip title="Institute Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  setIsInstituteDetailsModelVisible(true);
                }}
              >
                {dataObj.instituteName ?? "N/A"}
              </div>
            </a>
          </Tooltip>
        ),
        image: dataObj.image ? (
          <Image
            src={dataObj.image}
            style={{ height: "50px", width: "50px", border: "1px solid #ccc" }}
          />
        ) : (
          "N/A"
        ),
        location: dataObj.location,
        email: dataObj.email,
        commission: dataObj.percentage,
        universityUrl: dataObj.universityUrl,
        status: dataObj.status ? (
          <p className="greenTag">ACTIVE</p>
        ) : (
          <p className="redTag">INACTIVE</p>
        ),
        actions: (
          <Space size="middle">
            {dataObj.status === "ACTIVE" && (
              <>
                <Tooltip title="Add Course">
                  <a>
                    <div
                      className="bordered"
                      onClick={() => {
                        setRecord(dataObj);
                        setIsAddCourseFrmInstituteModelVisible(true);
                      }}
                    >
                      <AiOutlinePlus />
                    </div>
                  </a>
                </Tooltip>
                <Tooltip title="Add Package">
                  <a>
                    <div
                      className="bordered"
                      onClick={() => {
                        setRecord(dataObj);
                        setIsAddPackageModalVisible(true);
                      }}
                    >
                      <BsCalendar2Plus />
                    </div>
                  </a>
                </Tooltip>
              </>
            )}
            <Tooltip title="Update Institute">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsUpdateInstituteModelVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <EditOutlined />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Delete Institute">
              <Popconfirm
                title="Are you sure to delete this Institute?"
                onConfirm={() => {
                  dispatch({
                    type: "DELETE_INSTITUTE_REQUEST",
                    payload: { id: dataObj.id },
                  });
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <div className="bordered">
                    <DeleteOutlined />
                  </div>
                </a>{" "}
              </Popconfirm>
            </Tooltip>
            <Tooltip title="Institute Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsInstituteDetailsModelVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
            {dataObj.status === "INACTIVE" && (
              <Tooltip title="Activate Institute">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus =
                        dataObj.status === "INACTIVE" ? "active" : "inactive";
                      dispatch({
                        type: "TOGGLE_INSTITUTE_STATUS_REQUEST",
                        payload: { id: dataObj.id, actionStatus },
                      });
                    }}
                  >
                    <AiOutlinePoweroff />
                  </div>
                </a>
              </Tooltip>
            )}
            {dataObj.status === "ACTIVE" && (
              <Tooltip title="Deactivate Institute">
                <a style={{ color: "red" }}>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus =
                        dataObj.status === "INACTIVE" ? "active" : "inactive";
                      dispatch({
                        type: "TOGGLE_INSTITUTE_STATUS_REQUEST",
                        payload: { id: dataObj.id, actionStatus },
                      });
                    }}
                  >
                    <BiBlock />
                  </div>
                </a>
              </Tooltip>
            )}
          </Space>
        ),
      };
    });
  }

  return (
    <SiderDemo>
      <div className="flexColumn">
        <div className="leadFlexRow">
          <div style={{ width: "10rem" }}>
            <button
              className="button"
              onClick={() => {
                setIsAddInstituteModalVisible(true);
              }}
            >
              <span>Add Institute</span>
            </button>
          </div>
          <Segmented
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <Avatar src={institute} size={20} shape="square" />
                    <div>All Institutes</div>
                  </div>
                ),
                value: "getAllInstitute",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <Avatar src={activeInstitute} size={20} shape="square" />
                    <div>Active Institutes</div>
                  </div>
                ),
                value: "active",
              },
            ]}
            onChange={(value) => {
              setPage(1);
              setPageSize(10);
              setInstituteType(value);
              console.log(value);
            }}
          />
          <Search
            className="search"
            style={{
              width: "30%",
              borderRadius: "10px ! important",
            }}
            placeholder="Search for institute"
            allowClear
            size="large"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              onSearch(e.target.value);
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
          }}
        >
          {instituteState.isLoading ? (
            <TableSkeleton />
          ) : (
            <Table
              bordered
              columns={columns}
              dataSource={data}
              pagination={false}
            />
          )}

          {instituteState.institutes?.totalData && (
            <Pagination
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                alignSelf: "flex-end",
              }}
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              defaultCurrent={1}
              current={page}
              defaultPageSize={pageSize}
              onChange={onShowSizeChange}
              total={instituteState.institutes?.totalPage * 10}
            />
          )}

          {/* {instituteState.institutes?.totalData && (
          <Pagination
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            defaultCurrent={1}
            onChange={onShowSizeChange}
            total={instituteState.institutes?.totalPage * 10}
          />
        )} */}
        </div>
      </div>
      {/* ADD COMPANY */}
      {isAddInstituteModalVisible && (
        <AddInstitute
          isAddInstituteModalVisible={isAddInstituteModalVisible}
          setIsAddInstituteModalVisible={setIsAddInstituteModalVisible}
        />
      )}
      {/* UPDATE COMPANY */}
      {isUpdateInstituteModelVisible && (
        <UpdateInstitute
          record={record}
          isUpdateInstituteModelVisible={isUpdateInstituteModelVisible}
          setIsUpdateInstituteModelVisible={setIsUpdateInstituteModelVisible}
        />
      )}
      {isAddCourseFrmInstituteModelVisible && (
        <AddCourseFrmInstitute
          record={record}
          isAddCourseModalVisible={isAddCourseFrmInstituteModelVisible}
          setIsAddCourseModalVisible={setIsAddCourseFrmInstituteModelVisible}
        />
      )}
      {isAddInstituteDocumentsModelVisible && (
        <ManageInstituteDocuments
          record={record}
          isAddInstituteDocumentsModelVisible={
            isAddInstituteDocumentsModelVisible
          }
          setIsAddInstituteDocumentsModelVisible={
            setIsAddInstituteDocumentsModelVisible
          }
        />
      )}
      {isInstituteDetailsModelVisible && (
        <InstituteDetais
          record={record}
          isInstituteDetailsModelVisible={isInstituteDetailsModelVisible}
          setIsInstituteDetailsModelVisible={setIsInstituteDetailsModelVisible}
        />
      )}
      {isAddPackageModalVisible && (
        <AddCasePackage
          from={"INSTITUTE"}
          instituteRecord={record}
          isAddPackageModalVisible={isAddPackageModalVisible}
          setIsAddPackageModalVisible={setIsAddPackageModalVisible}
        />
      )}
    </SiderDemo>
  );
};

export default Institute;
