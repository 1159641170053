import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import TextArea from "antd/lib/input/TextArea";
const { Option } = Select;

const AddIntake = ({ isAddIntakeModalVisible, setIsAddIntakeModalVisible }) => {
  const store = useSelector((state) => {
    return {
      addIntakeState: state.VisasReducer,
    };
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const addIntakeSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "ADD_INTAKE_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddIntakeModalVisible(false);
  };
  return (
    <Modal
      title="Add Intake"
      open={isAddIntakeModalVisible}
      onCancel={() => {
        setIsAddIntakeModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addIntakeSubmitHandler}
        colon={true}
        form={form}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ gap: "0.3rem", width: "100%" }}
        >
          <Form.Item
            label="Course"
            name={"courseId"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter name!",
              },
            ]}
          >
            <Select>
              {store.addIntakeState.caseDropdown?.course?.map((dataObj) => {
                return <Option key={dataObj.id}>{dataObj.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Name"
            name={"intakeSessionName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter name!",
              },
            ]}
          >
            <Input placeholder="Name" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ gap: "0.3rem", width: "100%" }}
        >
          <Form.Item
            label="Description"
            name={"description"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please write description!",
              },
            ]}
          >
            <TextArea placeholder="Write something..." />
          </Form.Item>
          <Form.Item
            label="Form Submit Start Date"
            name={"formSubmitStartDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick form submit date",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ gap: "0.3rem", width: "100%" }}
        >
          <Form.Item
            label="Form Submit End Date"
            name={"formSubmitEndDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter form submit end date",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
        </div>
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Intake
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddIntake;
