import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
const { Option } = Select;

const UpdateEducationVisa = ({
  record,
  isUpdateEducationVisaModalVisible,
  setIsUpdateEducationVisaModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const updateEducationVisaSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      subClass: value.subClass,
      visaCategory: "EDUCATION",
    };
    dispatch({
      type: "UPDATE_EDUCATION_VISAS_REQUEST",
      payload: { id: record.id, formData },
    });
    form.resetFields();
    setIsUpdateEducationVisaModalVisible(false);
  };
  return (
    <Modal
      title="Update Education Visa Class"
      open={isUpdateEducationVisaModalVisible}
      onCancel={() => {
        setIsUpdateEducationVisaModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={updateEducationVisaSubmitHandler}
        colon={true}
        form={form}
        fields={[
          {
            name: ["subClass"],
            value: record?.subClass,
          },
        ]}
      >
        <Form.Item
          label="Visa class"
          name={"subClass"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter visa class name!",
            },
          ]}
        >
          <Input placeholder="Visa class name" />
        </Form.Item>
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Update Education Visa Class
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateEducationVisa;
