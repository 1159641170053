import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  countryFilter: [],
  degreeFilter: [],
  courseFilter: [],
  intakeFilter: [],
  allCollege: {},
};

const CollegeFinderReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_ALL_COUNTRY_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_ALL_COUNTRY_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        countryFilter: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ALL_COUNTRY_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_ALL_DEGREE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_ALL_DEGREE_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        degreeFilter: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ALL_DEGREE_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_ALL_COURSE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_ALL_COURSE_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        courseFilter: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ALL_COURSE_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_ALL_INTAKE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_ALL_INTAKE_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        intakeFilter: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ALL_INTAKE_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_ALL_COLLEGE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_ALL_COLLEGE_REQUEST_SUCCESS": {
      return {
        ...state,
        allCollege: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ALL_COLLEGE_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to find courses.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_ALL_COUNTRY_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_ALL_COUNTRY_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! CollegeFinder added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_ALL_COUNTRY_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to add CollegeFinder.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_ALL_COUNTRY_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_ALL_COUNTRY_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! CollegeFinder updated.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_ALL_COUNTRY_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to update CollegeFinder.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_ALL_COUNTRY_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DELETE_ALL_COUNTRY_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! CollegeFinder deleted.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_ALL_COUNTRY_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to delete CollegeFinder.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_ALL_COUNTRY_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "TOGGLE_ALL_COUNTRY_STATUS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! CollegeFinder status changed.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_ALL_COUNTRY_STATUS_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to change CollegeFinder status.");

      message.error("error");
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default CollegeFinderReducer;
