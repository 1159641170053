import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  degree: {},
  activeDegree: {},
};

const DegreeReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_DEGREE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_DEGREE_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        degree: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_DEGREE_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load active degree.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_ACTIVE_DEGREE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_ACTIVE_DEGREE_REQUEST_SUCCESS": {
      return {
        ...state,
        activeDegree: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ACTIVE_DEGREE_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load active degree.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_DEGREE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_DEGREE_REQUEST_SUCCESS": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.success("Success! Degree added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_DEGREE_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add Degree.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_DEGREE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_DEGREE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Degree updated.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_DEGREE_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to update Degree.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_DEGREE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DELETE_DEGREE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Degree deleted.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_DEGREE_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to delete Degree.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_DEGREE_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "TOGGLE_DEGREE_STATUS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Degree status changed.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_DEGREE_STATUS_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to change Degree status.");

      message.error("error");
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default DegreeReducer;
