import { Button, Checkbox, Form, Input, Carousel } from "antd";
import { VscLock } from "react-icons/vsc";
import React, { Fragment } from "react";
import classes from "./Login.module.css";
import logoImage from "./../../Assets/khrouch3.png";
import carousel1 from "./../../Assets/carousel/carousel1.png";
import carousel2 from "./../../Assets/carousel/carousel2.png";
import carousel3 from "./../../Assets/carousel/carousel3.png";
import carousel4 from "./../../Assets/carousel/carousel4.png";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useLocation, useParams } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import CarouselComponent from "./CarouselComponent";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { useState } from "react";
import { useEffect } from "react";

const Login = () => {
  const loginState = useSelector((state) => state.LoginReducer);
  const dispatch = useDispatch();
  const params = useParams();

  const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const [isRecaptchaDone, setIsRecaptchaDone] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const regexExp =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  useEffect(() => {
    dispatch({
      type: "COMPANY_DETAILS_REQUEST",
      payload: {
        isEmail: regexExp.test(params?.id),
        url: params?.id,
      },
    });
  }, []);

  const loginSubmitHandler = (values) => {
    if (regexExp.test(params?.id)) {
      dispatch({
        type: "COMPANY_EMAIL_AUTHENTICATION_REQUEST",
        payload: {
          companyEmail: params?.id,
          values,
        },
      });
    } else {
      dispatch({
        type: "COMPANY_DOMAIN_AUTHENTICATION_REQUEST",
        payload: {
          domain: params?.id,
          values,
        },
      });
    }
    // dispatch({
    //   type: "USER_AUTHENTICATION_REQUEST",
    //   payload: { values, recaptcha: recaptchaToken },
    // });
  };
  // console.log("Is authenticated", loginState.isAuthenticated);
  // console.log("params", params);
  return (
    <Fragment>
      {loginState.isAuthenticated ? (
        loginState?.loginData?.isFirstLogin ? (
          <Navigate
            to="/organizationalSettings/general-settings"
            replace={true}
          />
        ) : (
          <Navigate to="/dashboard" replace={true} />
        )
      ) : (
        <div style={{ display: "flex" }}>
          <div className={classes.loginContainer}>
            <div className={classes.formContainer}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {loginState?.companyLogoDetails?.companyLogo ? (
                  <img
                    src={loginState?.companyLogoDetails?.companyLogo}
                    style={{ width: "25%", borderRadius: "0.5rem" }}
                  />
                ) : (
                  <h1>{loginState?.companyLogoDetails?.companyName}</h1>
                )}
              </div>
              <p style={{ textAlign: "center", fontSize: "16px" }}>
                Login to your account!
              </p>
              <Form
                layout="vertical"
                className={classes.loginForm}
                onFinish={loginSubmitHandler}
              >
                <div className={classes.emailAndPassword}>
                  <Form.Item
                    name={"userName"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter Email!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Email"
                      size="large"
                      prefix={<UserOutlined />}
                      style={{ borderRadius: "25px" }}
                    />
                  </Form.Item>
                  <Form.Item
                    name={"password"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter Password!",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Password"
                      size="large"
                      prefix={<VscLock />}
                      style={{ borderRadius: "25px" }}
                    />
                  </Form.Item>

                  <Form.Item
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div className="flexColumnwithoutStyle">
                      <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
                        <GoogleReCaptcha
                          onVerify={(e) => {
                            // console.log("recaptcha changed");
                            // console.log("recaptcha changed", e);
                            setRecaptchaToken(e);
                          }}
                        />
                      </GoogleReCaptchaProvider>
                      {/* <GoogleReCaptcha
                        onVerify={(e) => {
                          console.log("recaptcha changed");
                          console.log("recaptcha changed", e);
                          // setToken(token);
                        }}
                      /> */}
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ borderRadius: "2rem" }}
                      >
                        SIGN IN
                      </Button>
                      {!regexExp.test(params?.id) && (
                        <Link
                          to={`/password_reset/${encodeURIComponent(
                            params?.id
                          )}`}
                          style={{
                            textAlign: "center",
                            marginTop: "6px",
                            fontSize: "16px",
                          }}
                        >
                          Forgot Password?
                        </Link>
                      )}
                    </div>
                  </Form.Item>
                </div>
              </Form>
              <div style={{ textAlign: "center", fontSize: "16px" }}>
                Don't have an account?{" "}
                <Link to={"/register"} style={{ fontSize: "16px" }}>
                  Register here
                </Link>
              </div>
            </div>
          </div>
          <div style={{ width: "30%" }}>
            <CarouselComponent />
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Login;
