import { Button, Checkbox, Form, Input, Carousel } from "antd";
import React, { Fragment, useState } from "react";
import classes from "./Login.module.css";
import logoImage from "./../../Assets/khrouch3.png";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import CarouselComponent from "./CarouselComponent";
import { AiOutlineMail } from "react-icons/ai";
import { useEffect } from "react";

const CompanyLoginWithEmail = () => {
  const loginState = useSelector((state) => state.LoginReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [companyEmail, setCompanyEmail] = useState(null);

  useEffect(() => {
    dispatch({ type: "CLEAR_AUTHENTICATION_REQUEST" });
  }, []);

  const loginSubmitHandler = (values) => {
    // navigate(`/${encodeURIComponent(values?.companyEmail)}`);
    dispatch({
      type: "COMPANY_EMAIL_VALIDATION_REQUEST",
      payload: { values },
    });
  };

  console.log("Is authenticated", loginState.isAuthenticated);
  return (
    <Fragment>
      {loginState.isCompanyEmailValidated ? (
        <Navigate to={`/${encodeURIComponent(companyEmail)}`} replace={true} />
      ) : (
        <div style={{ display: "flex" }}>
          <div className={classes.companyLoginContainer}>
            <div className={classes.formContainer}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={logoImage} style={{ width: "15%" }} />
              </div>
              <h3 style={{ textAlign: "center", fontSize: "16px" }}>
                Welcome To Khrouch
              </h3>
              <p style={{ textAlign: "center", fontSize: "16px" }}>
                Please enter your company's email to continue with Khrouch.
              </p>
              <Form
                layout="vertical"
                className={classes.loginForm}
                onFinish={loginSubmitHandler}
              >
                <div className={classes.emailAndPassword}>
                  <Form.Item
                    name={"companyEmail"}
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message:
                          "Please enter valid company email to continue!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="companyname@domainname.com"
                      prefix={
                        <AiOutlineMail
                          style={{ fontSize: "18px", color: "#555555" }}
                        />
                      }
                      size="large"
                      style={{ borderRadius: "25px" }}
                      onChange={(e) => {
                        setCompanyEmail(e.target.value);
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div className="flexColumnwithoutStyle">
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ borderRadius: "2rem" }}
                      >
                        Continue
                      </Button>
                    </div>
                  </Form.Item>
                </div>
              </Form>

              <div style={{ textAlign: "center", fontSize: "16px" }}>
                <Link to={"/"} style={{ fontSize: "16px" }}>
                  Try with company domain
                </Link>
              </div>
              <div
                style={{
                  marginTop: "0.5rem",
                  textAlign: "center",
                  fontSize: "16px",
                }}
              >
                Don't have an account?{" "}
                <Link to={"/register"} style={{ fontSize: "16px" }}>
                  Register here
                </Link>
              </div>
            </div>
          </div>
          <div style={{ width: "30%" }}>
            <CarouselComponent />
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default CompanyLoginWithEmail;
