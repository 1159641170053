import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  Radio,
  Select,
  Space,
  TimePicker,
} from "antd";
import { AiOutlineMail, AiOutlinePhone, AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  date,
  dateAndTime,
  dateAndTimeUTC,
  getTimeZone,
  YYYYMMDD,
} from "../../HelperFunction";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "antd/lib/form/Form";
import { IoLocationSharp } from "react-icons/io5";
const { Option } = Select;
const { TextArea } = Input;
const CampaignForm = () => {
  const store = useSelector((state) => {
    return {
      campaignState: state.CampaignReducer,
    };
  });

  const campaignId = new URLSearchParams(window.location.search).get(
    "campaignId"
  );
  const companyId = new URLSearchParams(window.location.search).get(
    "companyId"
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "FETCH_FORM_DETAILS_OF_CAMPAIGN_REQUEST",
      payload: {
        campaignId: campaignId,
      },
    });
    dispatch({
      type: "FETCH_CAMPAIGN_FORM_DROPDOWN_REQUEST",
      payload: {
        companyId: companyId,
      },
    });
    dispatch({
      type: "FETCH_COMPANY_DETAILS_FOR_LOGO_AND_FOOTER_REQUEST",
      payload: {
        companyId: companyId,
      },
    });
  }, []);

  const [form] = Form.useForm();
  const params = useParams();
  const navigate = useNavigate();
  const [isAddPurposeModalVisible, setIsAddPurposeModalVisible] =
    useState(false);
  const [isAddAboutUsModalVisible, setIsAddAboutUsModalVisible] =
    useState(false);
  const [isAddLeadSourceModalVisible, setIsAddLeadSourceModalVisible] =
    useState(false);
  const [isAddCountryModalVisible, setIsAddCountryModalVisible] =
    useState(false);
  const [isAddLeadStatusModalVisible, setIsAddLeadStatusModalVisible] =
    useState(false);
  const [isAddConsultantModalVisible, setIsAddConsultantModalVisible] =
    useState(false);
  const [isAddReferralModalVisible, setIsAddReferralModalVisible] =
    useState(false);
  const [isAddContactVisible, setIsAddContactVisible] = useState(false);
  const [isAddSalesRepModalVisible, setIsAddSalesRepModalVisible] =
    useState(false);
  const [campaignData, setCampaignData] = useState({
    firstName: null,
    lastname: null,
    email: null,
    mobileNumber: null,
    leadSourceId: null,
    countryOfResidence: null,
    purposeId: null,
    purposeName: null,
    countryId: null,
    countryName: null,
    leadSourceId: null,
    leadSourceName: null,
    leadType: "SELF ASSIGNED",
    referral: store.campaignState?.formDetails?.referral ?? false,
    referredById: null,
    referredByName: null,
    remainderDate: moment(),
    remainderTime: moment(),
    description: null,
    todayDescription: null,
    sendByEmail: null,
  });

  const addLeadSubmitHandler = (values) => {
    const formData = {
      firstName: campaignData.firstName,
      lastName: campaignData.lastname,
      email: campaignData.email,
      mobile: campaignData.mobileNumber,
      purposeId: campaignData.purposeId,
      aboutUsId: campaignData.aboutUsId,
      countryId: campaignData.countryId,
      description: campaignData.description,
      leadSourceId: campaignData.leadSourceId,
      referral: campaignData.referral,
      reminderDateAndTime: dateAndTime(
        campaignData.remainderDate,
        campaignData.remainderTime
      ),
      sendByEmail: campaignData.sendByEmail,
      todayDescription: campaignData.todayDescription,
      referredById: campaignData.referredById,
    };
    dispatch({
      type: "ADD_LEAD_FROM_CAMPAIGN_REQUEST",
      payload: {
        formData: formData,
        campaignId: campaignId,
        responseSuccessFunction: responseSuccessFunction,
        responseErrorFunction: responseErrorFunction,
      },
    });
  };

  const responseSuccessFunction = (response) => {
    navigate("/submissionSuccess");
  };
  const responseErrorFunction = (response) => {
    navigate("/submissionFailed");
  };

  return (
    <div className="campaignForm">
      <div className="campaignActualForm">
        <div style={{ padding: "2rem" }}>
          <Alert
            description="Please fill the following details for enquiry!"
            type="info"
            style={{ padding: "0.5rem 0.5rem", marginBottom: "0.3rem" }}

            //   closable
          />
          <div
            className="flexColumnWithoutStyle"
            style={{
              alignItems: "flex-start",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            {store.campaignState?.companyLogoAndFooterDetails?.companyLogo && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  // src={logo3}
                  src={
                    store.campaignState?.companyLogoAndFooterDetails
                      ?.companyLogo
                  }
                  style={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "0.5rem",
                  }}
                />
              </div>
            )}
            <div
              style={{
                padding: "0 0 0 1rem",
                fontSize: "36px",
                fontWeight: "500",
                marginTop: "0",
                marginBottom: "30px",
                color: "#008080",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <strong style={{ textTransform: "uppercase" }}>
                {store.campaignState?.companyLogoAndFooterDetails?.companyName}
              </strong>
            </div>
          </div>
          <h2 style={{ textAlign: "center" }}>REGISTER & ENQUIRY WITH US:</h2>
          <Divider />
          <Form
            layout="vertical"
            onFinish={addLeadSubmitHandler}
            colon={true}
            form={form}
            // record
            //       ? `${record?.firstName} ${record?.lastName}`
            //       : contactRecord
            //       ? `${contactRecord?.firstName} ${contactRecord?.lastName}`
            //       : null,
            fields={[
              {
                name: ["contact"],
                value: campaignData?.contactId,
              },
              {
                name: ["firstName"],
                value: campaignData?.firstName,
              },
              {
                name: ["lastName"],
                value: campaignData?.lastname,
              },
              {
                name: ["email"],
                value: campaignData?.email,
              },
              {
                name: ["mobile"],
                value: campaignData?.mobileNumber,
              },
              {
                name: ["leadSourceId"],
                value: campaignData?.leadSourceId,
              },
              {
                name: ["countryOfResidence"],
                value: campaignData?.countryOfResidence,
              },
            ]}
            initialValues={{
              remainderDate: moment(),
              remainderTime: moment(),
              referral: false,
            }}
          >
            <div
              className="flexRowWithoutStyle"
              style={{
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              {store.campaignState?.formDetails?.firstName && (
                <Form.Item
                  label="First Name"
                  name={"firstName"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter first name!",
                    },
                  ]}
                >
                  <Input
                    placeholder="First Name"
                    onChange={(e) => {
                      setCampaignData((previousData) => {
                        return {
                          ...previousData,
                          firstName: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>
              )}
              {store.campaignState?.formDetails?.lastName && (
                <Form.Item
                  label="Last Name"
                  name={"lastName"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter last name!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Last Name"
                    onChange={(e) => {
                      setCampaignData((previousData) => {
                        return {
                          ...previousData,
                          lastname: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>
              )}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              {store.campaignState?.formDetails?.email && (
                <Form.Item
                  label="Email"
                  name={"email"}
                  style={{ width: "100%" }}
                >
                  <Input
                    type="email"
                    placeholder="user@domain.com"
                    onChange={(e) => {
                      setCampaignData((previousData) => {
                        return {
                          ...previousData,
                          email: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>
              )}
              {store.campaignState?.formDetails?.mobile && (
                <Form.Item
                  label="Mobile Number"
                  name={"mobile"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      pattern: /^[\d]{9,11}$/,
                      message: "Mobile number should contain 9 to 11 numbers",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Mobile Number"
                    onChange={(e) => {
                      setCampaignData((previousData) => {
                        return {
                          ...previousData,
                          mobileNumber: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>
              )}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              {store.campaignState?.formDetails?.purposeId && (
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "0.3rem", width: "100%" }}
                >
                  <Form.Item
                    label="Purpose"
                    name={"purposeId"}
                    style={{ width: "95%" }}
                    //   rules={[
                    //     {
                    //       required: true,
                    //       message: "Please select your purpose!",
                    //     },
                    //   ]}
                  >
                    <Select>
                      {store.campaignState.leadsDropdown?.purpose?.map(
                        (dataObj) => {
                          return (
                            <Option key={dataObj.id} value={dataObj.id}>
                              <div
                                onClick={() => {
                                  setCampaignData((previousData) => {
                                    return {
                                      ...previousData,
                                      purposeId: dataObj.id,
                                      purposeName: dataObj.purposeName,
                                    };
                                  });
                                }}
                              >
                                {dataObj.purposeName}
                              </div>
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                  {/* <a
                  className="plusButton"
                  onClick={() => {
                    setIsAddPurposeModalVisible(true);
                  }}
                >
                  <AiOutlinePlus className="iconColor" />
                </a> */}
                </div>
              )}
              {store.campaignState?.formDetails?.aboutUsId && (
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "0.3rem", width: "100%" }}
                >
                  <Form.Item
                    label="How do you know about us?"
                    name={"aboutUsId"}
                    style={{ width: "95%" }}
                    //   rules={[
                    //     {
                    //       required: true,
                    //       message: "Please select a option!",
                    //     },
                    //   ]}
                  >
                    <Select
                    // defaultValue={
                    //   store.leadState.leadsDropdown?.aboutUs?.[0].id
                    // }
                    >
                      {store.campaignState.leadsDropdown?.aboutUs?.map(
                        (dataObj) => {
                          return (
                            <Option key={dataObj.id} value={dataObj.id}>
                              <div
                                onClick={(e) => {
                                  setCampaignData((previousData) => {
                                    return {
                                      ...previousData,
                                      aboutUsId: dataObj.id,
                                      aboutUsName: dataObj.aboutUs,
                                    };
                                  });
                                }}
                                className={"selectOptionDiv"}
                              >
                                {dataObj.aboutUs}
                              </div>
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                  {/* <a
                  className="plusButton"
                  onClick={() => {
                    setIsAddAboutUsModalVisible(true);
                  }}
                >
                  <AiOutlinePlus className="iconColor" />
                </a> */}
                </div>
              )}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              {store.campaignState?.formDetails?.countryId && (
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "0.3rem", width: "100%" }}
                >
                  <Form.Item
                    label="Country to Study"
                    name={"countryId"}
                    style={{ width: "100%" }}
                    //   rules={[
                    //     {
                    //       required: true,
                    //       message: "Please select a country!",
                    //     },
                    //   ]}
                  >
                    <Select>
                      {store.campaignState.leadsDropdown?.country?.map(
                        (dataObj) => {
                          return (
                            <Option key={dataObj.id}>
                              <div
                                onClick={() => {
                                  setCampaignData((previousData) => {
                                    return {
                                      ...previousData,
                                      countryId: dataObj.id,
                                      countryName: dataObj.countryName,
                                    };
                                  });
                                }}
                                className={"selectOptionDiv"}
                              >
                                {dataObj.countryName}
                              </div>
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                  {/* <a
                  className="plusButton"
                  onClick={() => {
                    setIsAddCountryModalVisible(true);
                  }}
                >
                  <AiOutlinePlus className="iconColor" />
                </a> */}
                </div>
              )}
              {store.campaignState?.formDetails?.leadSourceId && (
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "0.3rem", width: "100%" }}
                >
                  <Form.Item
                    label="Lead Source"
                    name={"leadSourceId"}
                    style={{ width: "95%" }}
                    //   rules={[
                    //     {
                    //       required: true,
                    //       message: "Please select a lead source!",
                    //     },
                    //   ]}
                  >
                    <Select>
                      {store.campaignState.leadsDropdown?.leadSource?.map(
                        (dataObj) => {
                          return (
                            <Option key={dataObj.id} value={dataObj.id}>
                              <div
                                onClick={() => {
                                  setCampaignData((previousData) => {
                                    return {
                                      ...previousData,
                                      leadSourceId: dataObj.id,
                                      leadSourceName: dataObj.leadSource,
                                    };
                                  });
                                }}
                                className={"selectOptionDiv"}
                              >
                                {dataObj.leadSource}
                              </div>
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                  {/* <a
                  className="plusButton"
                  onClick={() => {
                    setIsAddLeadSourceModalVisible(true);
                  }}
                >
                  <AiOutlinePlus className="iconColor" />
                </a> */}
                </div>
              )}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.3rem", width: "100%" }}
            >
              {store.campaignState?.formDetails?.leadType && (
                <Form.Item
                  label="Lead Type"
                  name={"leadType"}
                  style={{ width: "100%" }}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please enter lead type!",
                  //   },
                  // ]}
                >
                  <Select
                    onChange={(e) => {
                      setCampaignData((previousData) => {
                        return {
                          ...previousData,
                          leadType: e,
                        };
                      });
                    }}
                  >
                    <Option value={"SELF ASSIGNED"}>Self Assigned</Option>
                    <Option value={"COMPANY ASSIGNED"}>Company Assigned</Option>
                  </Select>
                </Form.Item>
              )}
              {store.campaignState?.formDetails?.countryOfResidence && (
                <Form.Item
                  label="Country of Residence"
                  name={"countryOfResidence"}
                  style={{ width: "100%" }}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please enter photo!",
                  //   },
                  // ]}
                >
                  <Input
                    placeholder="Please enter country of residence!"
                    onChange={(e) => {
                      setCampaignData((previouData) => {
                        return {
                          ...previouData,
                          countryOfResidence: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>
              )}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              {store.campaignState?.formDetails?.referral && (
                <Form.Item
                  label="Referral"
                  name={"referral"}
                  style={{ width: "100%" }}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please select a option!",
                  //   },
                  // ]}
                >
                  <Radio.Group
                    onChange={(e) => {
                      setCampaignData((previouData) => {
                        return {
                          ...previouData,
                          referral: e.target.value,
                        };
                      });
                    }}
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              )}
              {store.campaignState?.formDetails?.referredById &&
                store.campaignState?.formDetails?.referral && (
                  <>
                    {campaignData.referral === true && (
                      <div
                        className="flexRowWithoutStyle"
                        style={{ gap: "0.3rem", width: "100%" }}
                      >
                        <Form.Item
                          label="Select Referral"
                          name={"referredById"}
                          style={{ width: "100%" }}
                          //   rules={[
                          //     {
                          //       required: true,
                          //       message: "Please select a referral!",
                          //     },
                          //   ]}
                        >
                          <Select>
                            {store.campaignState.leadsDropdown?.referral?.map(
                              (dataObj) => {
                                return (
                                  <Option key={dataObj.id}>
                                    <div
                                      onClick={() => {
                                        setCampaignData((previousData) => {
                                          return {
                                            ...previousData,
                                            referredById: dataObj.id,
                                            referredByName: `${dataObj.firstName} ${dataObj.lastName}`,
                                          };
                                        });
                                      }}
                                      className={"selectOptionDiv"}
                                    >
                                      {dataObj.firstName} {dataObj.lastName}
                                    </div>
                                  </Option>
                                );
                              }
                            )}
                          </Select>
                        </Form.Item>
                        {/* <a
                      className="plusButton"
                      onClick={() => {
                        setIsAddReferralModalVisible(true);
                      }}
                    >
                      <AiOutlinePlus className="iconColor" />
                    </a> */}
                      </div>
                    )}
                  </>
                )}
            </div>
            {/* <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.3rem", width: "100%" }}
            >
              <Form.Item
                label="Sales Reps"
                name={"salesRepresentative"}
                style={{ width: "100%" }}
              >
                <Select>
                  {store.leadState?.leadsDropdown?.salesRepresentativeResponse?.map(
                    (dataObj) => {
                      return (
                        <Option key={dataObj.id}>
                          <div
                            onClick={() => {
                              setCampaignData((previouData) => {
                                return {
                                  ...previouData,
                                  salesRepresentativeId: dataObj.id,
                                  salesRepresentativeName: dataObj.salesRepName,
                                };
                              });
                            }}
                            className={"selectOptionDiv"}
                          >
                            {dataObj.salesRepName}
                          </div>
                        </Option>
                      );
                    }
                  )}
                </Select>
              </Form.Item>
              <a
                className="plusButton"
                onClick={() => {
                  setIsAddSalesRepModalVisible(true);
                }}
              >
                <AiOutlinePlus className="iconColor" />
              </a>
            </div>
            <Form.Item
              label="Weightage"
              name={"updateWeightage"}
              style={{ width: "100%" }}
              // rules={[
              //   {
              //     required: true,
              //     message: "Please select weightage",
              //   },
              // ]}
            >
              <Select>
                {store.leadState?.leadsDropdown?.weightage?.map(
                  (dataObj, index) => {
                    return (
                      <Option key={dataObj}>
                        <div
                          onClick={(e) => {
                            setCampaignData((previouData) => {
                              return {
                                ...previouData,
                                updateWeightage: dataObj,
                              };
                            });
                          }}
                        >
                          {dataObj}
                        </div>
                      </Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
          </div> */}
            {store.campaignState?.formDetails?.reminderDateAndTime && (
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.3rem", width: "100%" }}
              >
                <Form.Item
                  label="Reminder Date"
                  name={"remainderDate"}
                  style={{ width: "100%" }}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please pick a remainder date!",
                  //   },
                  // ]}
                >
                  <DatePicker
                    format={"DD-MM-YYYY"}
                    disabledDate={(currentDate) => {
                      const date = new Date();
                      return currentDate < date;
                    }}
                    onChange={(e) => {
                      setCampaignData((previouData) => {
                        return {
                          ...previouData,
                          remainderDate: e,
                        };
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Reminder Time"
                  name={"remainderTime"}
                  style={{ width: "100%" }}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please pick a reminder time!",
                  //   },
                  // ]}
                >
                  <TimePicker
                    onChange={(e) => {
                      setCampaignData((previouData) => {
                        return {
                          ...previouData,
                          remainderTime: e,
                        };
                      });
                    }}
                  />
                </Form.Item>
              </div>
            )}
            {store.campaignState?.formDetails?.description && (
              <Form.Item
                label="Description"
                name={"description"}
                //   rules={[
                //     {
                //       required: true,
                //       message: "Please select a file!",
                //     },
                //   ]}
              >
                <TextArea
                  placeholder="Write something here"
                  onChange={(e) => {
                    setCampaignData((previouData) => {
                      return {
                        ...previouData,
                        description: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
            )}
            {store.campaignState?.formDetails?.todayDescription && (
              <Form.Item
                label="Today's Description"
                name={"todayDescription"}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please select a file!",
                //   },
                // ]}
              >
                <TextArea
                  placeholder="Type here"
                  onChange={(e) => {
                    setCampaignData((previouData) => {
                      return {
                        ...previouData,
                        todayDescription: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
            )}

            {store.campaignState?.formDetails?.sendByEmail && (
              <Form.Item name={"sendByEmail"} valuePropName="checked">
                <Checkbox
                  onChange={(e) => {
                    setCampaignData((previouData) => {
                      return {
                        ...previouData,
                        sendByEmail: e.target.checked,
                      };
                    });
                  }}
                >
                  Send emails
                </Checkbox>
              </Form.Item>
            )}
            <Form.Item
              name="leadSubmit"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      (getFieldValue("email") === undefined ||
                        getFieldValue("email") === "" ||
                        getFieldValue("email") === null) &&
                      (getFieldValue("mobile") === undefined ||
                        getFieldValue("mobile") === null ||
                        getFieldValue("mobile") === "")
                    ) {
                      return Promise.reject(
                        new Error("Please enter either email or phone number!")
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
              hasFeedback
            >
              <Button
                name={"leadSubmit"}
                block
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{
            justifyContent: "space-evenly",
            backgroundColor: "#253340",
            borderRadius: "0 0 20px 20px",
            padding: "2rem 2rem 3rem 2rem",
            gap: "0.3rem",
          }}
        >
          <div style={{ color: "white", fontSize: "22px", fontWeight: "400" }}>
            Contact Us
          </div>
          {store?.campaignState?.companyLogoAndFooterDetails?.emailId && (
            <div
              className="flexRowWithoutStyle"
              style={{ color: "white", gap: "0.3rem" }}
            >
              <div className="footerIcon">
                <AiOutlineMail style={{ fontSize: "20px" }} />
              </div>
              <span>
                <a
                  href={`mailto:${store?.campaignState?.companyLogoAndFooterDetails?.emailId}`}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {store?.campaignState?.companyLogoAndFooterDetails?.emailId}
                </a>
              </span>
            </div>
          )}
          {store?.campaignState?.companyLogoAndFooterDetails
            ?.companyAddress && (
            <div
              className="flexRowWithoutStyle"
              style={{ color: "white", gap: "0.3rem" }}
            >
              <div className="footerIcon">
                <IoLocationSharp style={{ fontSize: "20px" }} />
              </div>
              <span>
                {
                  store?.campaignState?.companyLogoAndFooterDetails
                    ?.companyAddress
                }
              </span>
            </div>
          )}
          {store?.campaignState?.companyLogoAndFooterDetails?.phoneNumber && (
            <div
              className="flexRowWithoutStyle"
              style={{ color: "white", gap: "0.3rem" }}
            >
              <div className="footerIcon">
                <AiOutlinePhone style={{ fontSize: "20px" }} />
              </div>
              <span>
                <a
                  href={`tel:${store?.campaignState?.companyLogoAndFooterDetails?.callingCode} ${store?.campaignState?.companyLogoAndFooterDetails?.phoneNumber}`}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {store?.campaignState?.companyLogoAndFooterDetails
                    ?.callingCode ?? ""}
                  {
                    store?.campaignState?.companyLogoAndFooterDetails
                      ?.phoneNumber
                  }
                </a>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CampaignForm;
