import { Button, Card, Form, Input, List, Tag, Tooltip } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FiSettings } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import OrganizationalSettings from "../OrganizationalSettings";

const QuotationSettings = () => {
  const store = useSelector((state) => {
    return {
      quotationSettingsState: state.OrganizationSettingsReducer,
    };
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "FETCH_REMINDER_EXIPRY_WORKING_HOUR_REQUEST",
      isSuccessFn: true,
      successFunction: successFunction,
    });
  }, []);
  const successFunction = (response) => {
    console.log("response from reminder expiry date", response);
    setQuotationSettingsData((previousData) => {
      return {
        // ...previousData,
        quotationPrefix: response?.data?.prefix?.quotationPrefix,
        quoteExpiryDateAfter: response?.data?.expiryDates?.quoteExpiryDateAfter,
      };
    });
  };
  const [quotationSettingsData, setQuotationSettingsData] = useState({
    quotationPrefix: "",
    quoteExpiryDateAfter: "",
  });
  console.log("leadSettingsState", store.leadSettingsState);
  console.log("from profile state", quotationSettingsData);
  console.log(
    "store.leadSettingsState?.leadStatuses",
    store.leadSettingsState?.leadStatuses
  );
  console.log(
    "from profile state",
    store.leadSettingsState?.reminderExpiryWorkingHour
  );
  return (
    <OrganizationalSettings>
      <div>
        <h3>Quotation Settings</h3>
        <Card>
          <Form
            labelCol={{ span: 4 }}
            fields={[
              {
                name: ["quotePrefix"],
                value: quotationSettingsData.quotationPrefix,
              },
              {
                name: ["quoteExpiryDateAfter"],
                value: quotationSettingsData.quoteExpiryDateAfter,
              },
            ]}
          >
            <Form.Item name={"quotePrefix"} label="Quotation Prefix">
              <Input
                placeholder="Enter the prefix you want to use"
                value={quotationSettingsData.quotationPrefix}
                onChange={(e) => {
                  setQuotationSettingsData((previousData) => {
                    return {
                      ...previousData,
                      quotationPrefix: e.target.value,
                    };
                  });
                  // setLeadPrefix(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item name={"quoteExpiryDateAfter"} label="Quote expiry date">
              <Input
                type="number"
                value={quotationSettingsData.quoteExpiryDateAfter}
                //   placeholder="reminder days after today"
                onChange={(e) => {
                  setQuotationSettingsData((previousData) => {
                    return {
                      ...previousData,
                      quoteExpiryDateAfter: e.target.value,
                    };
                  });
                }}
                style={{ width: "4rem" }}
              />{" "}
              days after.
            </Form.Item>
            {/* <Form.Item name={"replyEmail"} label="Reply Email">
              <Input
                value={leadSettingsData.leadReminder}
                onChange={(e) => {
                  setLeadSettingsData((previousData) => {
                    return {
                      ...previousData,
                      replyEmail: e.target.value,
                    };
                  });
                }}
                style={{ width: "4rem" }}
              />{" "}
            </Form.Item> */}
          </Form>

          <Form.Item
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                dispatch({
                  type: "UPDATE_PREFIX_REQUEST",
                  payload: {
                    quotationPrefix: quotationSettingsData?.quotationPrefix,
                  },
                });
                dispatch({
                  type: "UPDATE_EXPIRY_REMINDER_WORKING_HOUR_REQUEST",
                  payload: {
                    quoteExpiryDateAfter:
                      quotationSettingsData.quoteExpiryDateAfter,
                  },
                });
              }}
            >
              Save Changes
            </Button>
          </Form.Item>
        </Card>
      </div>
    </OrganizationalSettings>
  );
};

export default QuotationSettings;
