import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import { useDispatch } from "react-redux";
const { Option } = Select;

const UpdateHeadAgent = ({
  record,
  isUpdateHeadAgentModalVisible,
  setIsUpdateHeadAgentModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const updateHeadAgentSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({
      type: "UPDATE_HEADAGENT_REQUEST",
      payload: { id: record.id, formData },
    });
    form.resetFields();
    setIsUpdateHeadAgentModalVisible(false);
  };
  return (
    <Modal
      title="Update Head Agent"
      open={isUpdateHeadAgentModalVisible}
      onCancel={() => {
        setIsUpdateHeadAgentModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={updateHeadAgentSubmitHandler}
        colon={true}
        form={form}
        fields={[
          {
            name: ["name"],
            value: record.name,
          },
          {
            name: ["email"],
            value: record.email,
          },
          ,
          {
            name: ["phoneNumber"],
            value: record.phoneNumber,
          },
          {
            name: ["mobileNumber"],
            value: record.mobileNumber,
          },

          {
            name: ["commissionShare"],
            value: record.commissionShare,
          },
          ,
          {
            name: ["status"],
            value: record.status,
          },
          ,
          {
            name: ["isPercentage"],
            value: record.isPercentage,
          },
        ]}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Full Name"
            name={"name"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter name!",
              },
            ]}
          >
            <Input placeholder="Full Name" />
          </Form.Item>
          <Form.Item
            label="Email"
            name={"email"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter email!",
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Phone Number"
            name={"phoneNumber"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter phone number",
              },
              {
                pattern: /^[\d]{9,11}$/,
                message: "Phone number should contain 9 to 11 numbers",
              },
            ]}
          >
            <Input type="number" placeholder="Phone Number" />
          </Form.Item>
          <Form.Item
            label="Mobile Number"
            name={"mobileNumber"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter mobile number",
              },
              {
                pattern: /^[\d]{9,11}$/,
                message: "Mobile number should contain 9 to 11 numbers",
              },
            ]}
          >
            <Input type="number" placeholder="mobile number" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Commission Share"
            name={"commissionShare"}
            dependencies={["isPercentage"]}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter commission share!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  console.log("value frm head agent", value);
                  console.log(
                    "is percentage frm head agent",
                    getFieldValue("isPercentage")
                  );
                  if (value <= 100 && getFieldValue("isPercentage")) {
                    return Promise.resolve();
                  } else if (!getFieldValue("isPercentage")) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Percentage must be less than 100!")
                  );
                },
              }),
            ]}
            hasFeedback
          >
            <Input type="number" placeholder="commission share" />
          </Form.Item>
          <Form.Item
            label="Action"
            name={"status"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select status !",
              },
            ]}
          >
            <Select>
              <Option value="ACTIVE">Active</Option>
              <Option value="INACTIVE">Inactive</Option>
            </Select>
          </Form.Item>
        </div>
        <Form.Item name={"isPercentage"} valuePropName="checked">
          <Checkbox>Is Percentage</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Update Head Agent
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateHeadAgent;
