import React, { useEffect } from "react";
import { CgHello } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import SiderDemo from "../../Components/Layout/SiderDemo";
import test from "./../../Assets/course.png";
import { Pie } from "@ant-design/plots";
import { Avatar, Badge, Divider, Progress, Tooltip } from "antd";
import { AntDesignOutlined, UserOutlined } from "@ant-design/icons";
import { MdDateRange } from "react-icons/md";
import DashboardHeader from "./DashboardHeader";
import { BiCube } from "react-icons/bi";
import { SlGraduation, SlLayers } from "react-icons/sl";
import { IoLayersOutline } from "react-icons/io5";
import { BsBagCheck, BsBuilding, BsTags } from "react-icons/bs";
import { Calendar, momentLocalizer } from "react-big-calendar";
import DashboardComponentAnalysis from "./DashboardComponentAnalysis";
import DashboardComponentList from "./DashboardComponentList";
import moment from "moment";
import { HiOutlineBuildingLibrary } from "react-icons/hi2";
import { GrDocumentVerified } from "react-icons/gr";
import { FaUserTie } from "react-icons/fa";
import DashboardSkeleton from "../../Helpers/TableSkeleton/DashboardSkeleton";

const localizer = momentLocalizer(moment);

const Dashboard = () => {
  const store = useSelector((state) => {
    return {
      dashboardState: state.DashboardReducer,
      MyCounsellingState: state.MyCounsellingAndCasesReducer,
      VisasState: state.MyCounsellingAndCasesReducer,
    };
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "FETCH_DASHBOARD_DATA_REQUEST" });
    dispatch({ type: "FETCH_DASHBOARD_DATA_LIST_REQUEST" });
    dispatch({ type: "FETCH_MY_COUNSELLING_REQUEST" });

    // dispatch({
    //   type: "FETCH_MY_CASES_IN_PROGRESS_REQUEST",
    //   payload: "IN_PROGRESS",
    // });
  }, []);

  let data;
  data = [
    {
      // Appointment
      type: "Appointment",
      value: store.dashboardState.dashboardData?.appointmentLeads,
      color: "white",
    },

    {
      // Win
      type: "Win",
      value: store.dashboardState.dashboardData?.winLeads,
    },
    {
      // Lose
      type: "Lose",
      value: store.dashboardState.dashboardData?.loseLeads,
    },
    {
      // Pending
      type: "Pending",
      value: store.dashboardState.dashboardData?.pendingLeads,
    },
  ];

  const config = {
    legend: false,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    innerRadius: 0.6,
    label: {
      type: "inner",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 16,
        color: "white",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: "",
      },
    },
  };

  data = [
    {
      type: "Not Assigned",
      // value: 10,
      value: store.dashboardState.dashboardData?.totalCaseNotAssigned
        ? store.dashboardState.dashboardData?.totalCaseNotAssigned
        : 0,
    },
    {
      type: "In Progress",
      // value: 5,
      value: store.dashboardState.dashboardData?.totalCaseInProgress
        ? store.dashboardState.dashboardData?.totalCaseInProgress
        : 0,
    },
    {
      type: "Completed",
      // value: 5,
      value: store.dashboardState.dashboardData?.totalCaseCompleted
        ? store.dashboardState.dashboardData?.totalCaseCompleted
        : 0,
    },
  ];
  const config2 = {
    legend: false,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    innerRadius: 0.6,
    label: {
      type: "inner",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 16,
        color: "white",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: "",
      },
    },
  };

  data = [
    {
      type: "Active",
      // value: 10,
      value: store.dashboardState.dashboardData?.totalActiveContact
        ? store.dashboardState.dashboardData?.totalActiveContact
        : 0,
    },
    {
      type: "Inactive",
      // value: 5,
      value:
        store.dashboardState.dashboardData?.totalAllContact !== null &&
        store.dashboardState.dashboardData?.totalActiveContact !== null
          ? store.dashboardState.dashboardData?.totalAllContact -
            store.dashboardState.dashboardData?.totalActiveContact
          : 0,
    },
  ];

  const config3 = {
    legend: false,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    innerRadius: 0.6,
    label: {
      type: "inner",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 16,
        color: "white",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: "",
      },
    },
  };
  data = [
    {
      type: "Active",
      // value: 10,
      value: store.dashboardState.dashboardData?.activeClients
        ? store.dashboardState.dashboardData?.activeClients
        : 0,
    },
    {
      type: "Inactive",
      // value: 5,
      value:
        store.dashboardState.dashboardData?.totalClients !== null &&
        store.dashboardState.dashboardData?.activeClients !== null
          ? store.dashboardState.dashboardData?.totalClients -
            store.dashboardState.dashboardData?.activeClients
          : 0,
    },
  ];

  const config4 = {
    legend: false,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    innerRadius: 0.6,
    label: {
      type: "inner",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 16,
        color: "white",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: "",
      },
    },
  };
  data = [
    {
      type: "Pending",
      // value: 10,
      value: store.dashboardState.dashboardData?.pendingAppointment
        ? store.dashboardState.dashboardData?.pendingAppointment
        : 0,
    },
    {
      type: "Confirmed",
      // value: 5,
      value: store.dashboardState.dashboardData?.confirmedAppointment
        ? store.dashboardState.dashboardData?.confirmedAppointment
        : 0,
    },
    {
      type: "Completed",
      // value: 5,
      value: store.dashboardState.dashboardData?.completedAppointment
        ? store.dashboardState.dashboardData?.completedAppointment
        : 0,
    },
  ];
  const config5 = {
    legend: false,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    innerRadius: 0.6,
    label: {
      type: "inner",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 16,
        color: "white",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: "",
      },
    },
  };

  data = [
    {
      type: "Active",
      // value: 10,
      value: store.dashboardState.dashboardData?.activeCompany
        ? store.dashboardState.dashboardData?.activeCompany
        : 0,
    },
    {
      type: "Inactive",
      // value: 5,
      value:
        store.dashboardState.dashboardData?.allCompany !== null &&
        store.dashboardState.dashboardData?.activeCompany !== null
          ? store.dashboardState.dashboardData?.allCompany -
            store.dashboardState.dashboardData?.activeCompany
          : 0,
    },
  ];

  const config6 = {
    legend: false,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    innerRadius: 0.6,
    label: {
      type: "inner",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 16,
        color: "white",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: "",
      },
    },
  };
  data = [
    {
      type: "Active",
      // value: 10,
      value: store.dashboardState.dashboardData?.activeCourses
        ? store.dashboardState.dashboardData?.activeCourses
        : 0,
    },
    {
      type: "Inactive",
      // value: 5,
      value:
        store.dashboardState.dashboardData?.allCourses !== null &&
        store.dashboardState.dashboardData?.activeCourses !== null
          ? store.dashboardState.dashboardData?.allCourses -
            store.dashboardState.dashboardData?.activeCourses
          : 0,
    },
  ];

  const config7 = {
    legend: false,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    innerRadius: 0.6,
    label: {
      type: "inner",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 16,
        color: "white",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: "",
      },
    },
  };
  data = [
    {
      type: "Active",
      // value: 10,
      value: store.dashboardState.dashboardData?.activeInstitute
        ? store.dashboardState.dashboardData?.activeInstitute
        : 0,
    },
    {
      type: "Inactive",
      // value: 5,
      value:
        store.dashboardState.dashboardData?.activeInstitute !== null &&
        store.dashboardState.dashboardData?.activeInstitute !== null
          ? store.dashboardState.dashboardData?.activeInstitute -
            store.dashboardState.dashboardData?.activeInstitute
          : 0,
    },
  ];

  const config8 = {
    legend: false,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    innerRadius: 0.6,
    label: {
      type: "inner",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 16,
        color: "white",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: "",
      },
    },
  };
  data = [
    {
      type: "Active",
      // value: 10,
      value: store.dashboardState.dashboardData?.activeConsultancyEmployee
        ? store.dashboardState.dashboardData?.activeConsultancyEmployee
        : 0,
    },
    {
      type: "Inactive",
      // value: 5,
      value:
        store.dashboardState.dashboardData?.totalConsultancyEmployee !== null &&
        store.dashboardState.dashboardData?.activeConsultancyEmployee !== null
          ? store.dashboardState.dashboardData?.totalConsultancyEmployee -
            store.dashboardState.dashboardData?.activeConsultancyEmployee
          : 0,
    },
  ];

  const config9 = {
    legend: false,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    innerRadius: 0.6,
    label: {
      type: "inner",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 16,
        color: "white",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: "",
      },
    },
  };

  console.log("dashboard state", store.dashboardState);
  console.log("from dashboard profile state", store.profileState);
  console.log("from dashboard login state", store.loginState);
  console.log(
    "from dashboard my cases",
    store.VisasState.myInProgressCases.totalData
  );
  console.log(
    "from dashboard dashboard state",
    store.dashboardState?.dashboardData.dashboardSettingResponse
  );
  console.log(
    "from dashboard mycounselling state",
    store.MyCounsellingState?.myCounselling?.totalData
  );
  const dashboardVisibleOptions =
    store.dashboardState?.dashboardDataList?.dashboardSettingResponse;

  return (
    <SiderDemo dashboard={true}>
      {store.dashboardState?.isLoading ? (
        <DashboardSkeleton />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.8rem",
          }}
        >
          <div className="new-dashboardcard-container">
            {dashboardVisibleOptions?.counterLeads === true && (
              <Link to={"/leads"}>
                <DashboardHeader
                  title={"LEADS"}
                  end={store.dashboardState.dashboardData?.allLeads}
                  dashboardHeaderIcon={
                    <BiCube className="dashboardIconStyle" />
                  }
                />
              </Link>
            )}
            {dashboardVisibleOptions?.counterCases === true && (
              <Link to={"/cases"}>
                <DashboardHeader
                  title={"CASES"}
                  end={store.dashboardState.dashboardData?.totalCases}
                  dashboardHeaderIcon={
                    <IoLayersOutline className="dashboardIconStyle" />
                  }
                />
              </Link>
            )}

            {dashboardVisibleOptions?.counterContact === true && (
              <Link to={"/contacts"}>
                <DashboardHeader
                  title={"CONTACTS"}
                  end={store.dashboardState.dashboardData?.totalAllContact}
                  dashboardHeaderIcon={
                    <BsTags className="dashboardIconStyle" />
                  }
                />
              </Link>
            )}
            {dashboardVisibleOptions?.counterClient === true && (
              <Link to={"/clients"}>
                <DashboardHeader
                  title={"CLIENTS"}
                  end={store.dashboardState.dashboardData?.totalClients ?? 0}
                  dashboardHeaderIcon={
                    <BsBagCheck className="dashboardIconStyle" />
                  }
                />
              </Link>
            )}
            {dashboardVisibleOptions?.counterCompanies === true && (
              <Link to={"/companies"}>
                <DashboardHeader
                  title={"COMPANIES"}
                  end={store.dashboardState.dashboardData?.allCompany ?? 0}
                  dashboardHeaderIcon={
                    <HiOutlineBuildingLibrary className="dashboardIconStyle" />
                  }
                />
              </Link>
            )}
            {dashboardVisibleOptions?.counterAppointment === true && (
              <Link to={"/appointments"}>
                <DashboardHeader
                  title={"APPOINTMENTS"}
                  end={store.dashboardState.dashboardData?.allAppointments ?? 0}
                  dashboardHeaderIcon={
                    <GrDocumentVerified className="dashboardIconStyle" />
                  }
                />
              </Link>
            )}
            {dashboardVisibleOptions?.counterCourses === true && (
              <Link to={"/courses"}>
                <DashboardHeader
                  title={"COURSES"}
                  end={store.dashboardState.dashboardData?.allCourses ?? 0}
                  dashboardHeaderIcon={
                    <SlGraduation className="dashboardIconStyle" />
                  }
                />
              </Link>
            )}
            {dashboardVisibleOptions?.counterInstitute === true && (
              <Link to={"/institute"}>
                <DashboardHeader
                  title={"INSTITUTES"}
                  end={store.dashboardState.dashboardData?.allInstitute ?? 0}
                  dashboardHeaderIcon={
                    <BsBuilding className="dashboardIconStyle" />
                  }
                />
              </Link>
            )}
            {dashboardVisibleOptions?.counterEmployees === true && (
              <Link to={"/manage-employees"}>
                <DashboardHeader
                  title={"EMPLOYEES"}
                  end={
                    store.dashboardState.dashboardData
                      ?.totalConsultancyEmployee ?? 0
                  }
                  dashboardHeaderIcon={
                    <FaUserTie className="dashboardIconStyle" />
                  }
                />
              </Link>
            )}
          </div>
          <div className="new-dashboardcard-container">
            {dashboardVisibleOptions?.analysisLeads === true && (
              <DashboardComponentAnalysis
                title={"LEAD ANALYSIS"}
                subtitle1Count={
                  store.dashboardState.dashboardData?.appointmentLeads
                }
                subtitle2Count={store.dashboardState.dashboardData?.winLeads}
                subtitle3Count={store.dashboardState.dashboardData?.loseLeads}
                subtitle4Count={
                  store.dashboardState.dashboardData?.pendingLeads
                }
                subtitle1={"APPOINTMENTS"}
                subtitle2={"WINS"}
                subtitle3={"LOSE"}
                subtitle4={"PENDINGS"}
                graph={<Pie {...config} />}
              />
            )}
            {dashboardVisibleOptions?.analysisCases === true && (
              <DashboardComponentAnalysis
                title={"CASE ANALYSIS"}
                subtitle1Count={
                  store.dashboardState.dashboardData?.totalCaseNotAssigned
                }
                subtitle2Count={
                  store.dashboardState.dashboardData?.totalCaseInProgress
                }
                subtitle3Count={
                  store.dashboardState.dashboardData?.totalCaseCompleted
                }
                subtitle1={"NOT ASSIGNED"}
                subtitle2={"IN PROGRESS"}
                subtitle3={"COMPLETED"}
                graph={<Pie {...config2} />}
              />
            )}
            {dashboardVisibleOptions?.analysisContact === true && (
              <DashboardComponentAnalysis
                title={"CONTACT ANALYSIS"}
                subtitle1Count={
                  store.dashboardState.dashboardData?.totalActiveContact
                }
                subtitle2Count={
                  store.dashboardState.dashboardData?.totalAllContact -
                  store.dashboardState.dashboardData?.totalActiveContact
                }
                subtitle1={"ACTIVE"}
                subtitle2={"INACTIVE"}
                graph={<Pie {...config3} />}
              />
            )}
            {dashboardVisibleOptions?.analysisClient === true && (
              <DashboardComponentAnalysis
                title={"CLIENT ANALYSIS"}
                subtitle1Count={
                  store.dashboardState.dashboardData?.activeClients
                }
                subtitle2Count={
                  store.dashboardState.dashboardData?.inactiveClients
                }
                subtitle1={"ACTIVE"}
                subtitle2={"INACTIVE"}
                graph={<Pie {...config4} />}
              />
            )}
            {dashboardVisibleOptions?.analysisAppointment === true && (
              <DashboardComponentAnalysis
                title={"APPOINTMENT ANALYSIS"}
                subtitle1Count={
                  store.dashboardState.dashboardData?.pendingAppointment
                }
                subtitle2Count={
                  store.dashboardState.dashboardData?.confirmedAppointment
                }
                subtitle3Count={
                  store.dashboardState.dashboardData?.completedAppointment
                }
                subtitle1={"PENDING"}
                subtitle2={"CONFIRMED"}
                subtitle3={"CONFIRMED"}
                graph={<Pie {...config5} />}
              />
            )}
            {dashboardVisibleOptions?.analysisCompanies === true && (
              <DashboardComponentAnalysis
                title={"COMPANY ANALYSIS"}
                subtitle1Count={store.dashboardState.dashboardData?.allCompany}
                subtitle2Count={
                  store.dashboardState.dashboardData?.allCompany -
                  store.dashboardState.dashboardData?.activeCompany
                }
                subtitle1={"ACTIVE"}
                subtitle2={"INACTIVE"}
                graph={<Pie {...config6} />}
              />
            )}
            {dashboardVisibleOptions?.analysisCourses === true && (
              <DashboardComponentAnalysis
                title={"COURSES ANALYSIS"}
                subtitle1Count={
                  store.dashboardState.dashboardData?.activeCourses
                }
                subtitle2Count={
                  store.dashboardState.dashboardData?.allCourses -
                  store.dashboardState.dashboardData?.activeCourses
                }
                subtitle1={"ACTIVE"}
                subtitle2={"INACTIVE"}
                graph={<Pie {...config7} />}
              />
            )}
            {dashboardVisibleOptions?.analysisInstitute === true && (
              <DashboardComponentAnalysis
                title={"INSTITUTE ANALYSIS"}
                subtitle1Count={
                  store.dashboardState.dashboardData?.activeInstitute
                }
                subtitle2Count={
                  store.dashboardState.dashboardData?.allInstitute -
                  store.dashboardState.dashboardData?.activeInstitute
                }
                subtitle1={"ACTIVE"}
                subtitle2={"INACTIVE"}
                graph={<Pie {...config8} />}
              />
            )}
            {dashboardVisibleOptions?.analysisEmployees === true && (
              <DashboardComponentAnalysis
                title={"EMPLOYEE ANALYSIS"}
                subtitle1Count={
                  store.dashboardState.dashboardData?.activeConsultancyEmployee
                }
                subtitle2Count={
                  store.dashboardState.dashboardData?.totalConsultancyEmployee -
                  store.dashboardState.dashboardData?.activeConsultancyEmployee
                }
                subtitle1={"ACTIVE"}
                subtitle2={"INACTIVE"}
                graph={<Pie {...config9} />}
              />
            )}
          </div>
          <div className="new-dashboardcard-datacontainer">
            {dashboardVisibleOptions?.latestAppointment && (
              <DashboardComponentList
                title={"LATEST APPOINTMENTS"}
                isAppointment={true}
                appointmentList={
                  store.dashboardState?.dashboardDataList?.recentAppointments
                    ?.data
                }
                myAppointmentList={
                  store.MyCounsellingState?.myCounselling?.data
                }
                totalMyCounselling={
                  store.MyCounsellingState?.myCounselling?.totalData
                }
              />
            )}
            {dashboardVisibleOptions?.latestContact && (
              <DashboardComponentList
                title={"LATEST CONTACTS"}
                contactList={
                  store.dashboardState?.dashboardDataList?.recentContacts?.data
                }
              />
            )}
            {dashboardVisibleOptions?.latestCases && (
              <DashboardComponentList
                title={"LATEST CASES"}
                caseInProgressList={
                  store.dashboardState?.dashboardDataList?.inProgressCases?.data
                }
                caseNotAssignedList={
                  store.dashboardState?.dashboardDataList?.notAssignedCases
                    ?.data
                }
                caseCompletedList={
                  store.dashboardState?.dashboardDataList?.completedCases?.data
                }
                isCase={true}
              />
            )}
            {dashboardVisibleOptions?.latestClient && (
              <DashboardComponentList
                title={"LATEST CLIENTS"}
                clientList={
                  store.dashboardState?.dashboardDataList?.latestCustomer?.data
                }
              />
            )}
            {dashboardVisibleOptions?.latestLeads && (
              <DashboardComponentList
                title={"LATEST LEADS"}
                leadList={
                  store.dashboardState?.dashboardDataList?.latestLead?.data
                }
              />
            )}
            <DashboardComponentList
              title={"ANNOUNCEMENTS"}
              announcements={
                store.dashboardState?.dashboardDataList?.announcements
              }
            />
            <DashboardComponentList
              title={"ASSIGNED TASKS"}
              taskAssigned={
                store.dashboardState?.dashboardDataList?.allAssignedToTasks
                  ?.data
              }
            />
            <DashboardComponentList
              title={"REMINDERS"}
              // taskAssigned={
              //   store.dashboardState?.dashboardDataList?.allAssignedToTasks?.data
              // }
            />
          </div>
          {/* <div style={{ backgroundColor: "white" }}>
          <Calendar
            view="week"
            localizer={localizer}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500 }}
          />
        </div> */}
        </div>
      )}
    </SiderDemo>
  );
};

export default Dashboard;

// let months = [
//   "Jan",
//   "Feb",
//   "Mar",
//   "Apr",
//   "May",
//   "Jun",
//   "Jul",
//   "Aug",
//   "Sep",
//   "Oct",
//   "Nov",
//   "Dec",
// ];
// // const TotalLead = store.leadState?.leads?.data?.length;
// // const appointmentLeadFilter = () => {
// //   let appointmentFilter = [];
// //   appointmentFilter = store.leadState.leads?.data?.filter((dataobj) => {
// //     return dataobj?.leadStatus?.statusName === "APPOINTMENT";
// //   });
// //   console.log("From appointment filete", store.leadState.leads?.data);
// //   return parseInt((appointmentFilter?.length / TotalLead) * 100);
// // };
// // const counsellingLeadFilter = () => {
// //   let appointmentFilter = [];
// //   appointmentFilter = store.leadState.leads?.data?.filter((dataobj) => {
// //     return dataobj?.leadStatus?.statusName === "COUNSELLING";
// //   });
// //   return parseInt((appointmentFilter?.length / TotalLead) * 100);
// // };
// // const WinLeadFilter = () => {
// //   const appointmentFilter = store.leadState.leads?.data?.filter((dataobj) => {
// //     return dataobj?.leadStatus?.statusName === "WIN";
// //   });
// //   return parseInt((appointmentFilter.length / TotalLead) * 100);
// // };
// // const LoseLeadFilter = () => {
// //   const appointmentFilter = store.leadState.leads?.data?.filter((dataobj) => {
// //     return dataobj?.leadStatus?.statusName === "LOSE";
// //   });
// //   return parseInt((appointmentFilter.length / TotalLead) * 100);
// // };
// // const pendingLeadFilter = () => {
// //   const appointmentFilter = store.leadState.leads?.data?.filter((dataobj) => {
// //     return dataobj?.leadStatus?.statusName === "PENDING";
// //   });
// //   return parseInt((appointmentFilter.length / TotalLead) * 100);
// // };
// let data;
// data = [
//   {
//     type: "Appointment",
//     value: store.dashboardState.dashboardData?.appointmentLeads,
//   },
//   // {
//   //   type: "Counselling",
//   //   value: store.dashboardState.dashboardData?.counsellingLeads,
//   // },
//   {
//     type: "Win",
//     value: store.dashboardState.dashboardData?.winLeads,
//   },
//   {
//     type: "Lose",
//     value: store.dashboardState.dashboardData?.loseLeads,
//   },
//   {
//     type: "Pending",
//     value: store.dashboardState.dashboardData?.pendingLeads,
//   },
// ];
// const config = {
//   data,
//   angleField: "value",
//   colorField: "type",
//   radius: 0.8,
//   innerRadius: 0.4,
//   label: {
//     type: "inner",
//     content: "{value}",
//     style: {
//       textAlign: "center",
//       fontSize: 16,
//       color: "white",
//     },
//   },
//   interactions: [
//     {
//       type: "element-selected",
//     },
//     {
//       type: "element-active",
//     },
//   ],
//   statistic: {
//     title: false,
//     content: {
//       style: {
//         whiteSpace: "pre-wrap",
//         overflow: "hidden",
//         textOverflow: "ellipsis",
//       },
//       content: "",
//     },
//   },
// };
// data = [
//   {
//     type: "Not Assigned",
//     // value: 10,
//     value: store.dashboardState.dashboardData?.totalCaseNotAssigned
//       ? store.dashboardState.dashboardData?.totalCaseNotAssigned
//       : 0,
//   },
//   {
//     type: "In Progress",
//     // value: 5,
//     value: store.dashboardState.dashboardData?.totalCaseInProgress
//       ? store.dashboardState.dashboardData?.totalCaseInProgress
//       : 0,
//   },
//   {
//     type: "Completed",
//     // value: 5,
//     value: store.dashboardState.dashboardData?.totalCaseCompleted
//       ? store.dashboardState.dashboardData?.totalCaseCompleted
//       : 0,
//   },
// ];
// const config2 = {
//   appendPadding: 10,
//   data,
//   angleField: "value",
//   colorField: "type",
//   radius: 0.9,
//   label: {
//     type: "inner",
//     offset: "-30%",
//     content: `{value}`,
//     style: {
//       fontSize: 14,
//       textAlign: "center",
//     },
//   },
//   interactions: [
//     {
//       type: "element-active",
//     },
//   ],
// };

{
  /* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingL: "0rem 2rem",
        }}
      >
        <h2 style={{ display: "flex", gap: "1rem" }}>
          <CgHello style={{ fontSize: "30px" }} /> Welcome,{" "}
          {store.profileState.profile?.userName}
        </h2>
        <h2
          style={{
            fontWeight: "bold",
            marginBottom: "15px",
            display: "flex",
            gap: "5px",

            alignItems: "center",
          }}
        >
          <MdDateRange style={{ fontSize: "26px" }} />
          <span> {new Date().getDate()}</span>
          <span>{months[new Date().getMonth()]}</span>
          <span>{new Date().getFullYear()}</span>
        </h2>
      </div>

      <div
        style={{
          display: "inline-flex",
          justifyContent: "",

          gap: "2rem",
          flexWrap: "wrap",
        }}
      >
        <div className="cardDiv" style={{ backgroundColor: "" }}>
          <div className="imageAndParas">
            <div className="imageCenter">
              {/* <img src={test} alt="" height="70px" /> */
}
//     </div>

//     <div className="textAndNumberDiv">
//       <div className="numberPara">
//         <CountUp end={store.dashboardState.dashboardData?.allLeads} />
//       </div>
//       <div className="textPara">Leads</div>
//     </div>
//   </div>
//   <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
//   <div style={{ height: "120px" }}>
//     <Pie {...config} />
//   </div>
// </div>
{
  /* <div className="cardDiv">
          <div className="imageAndParas">
            <div className="textAndNumberDiv">
              <div className="numberPara">
                <CountUp
                  end={store.dashboardState.dashboardData?.allAppointments}
                />
              </div>
              <div className="textPara">Appointments</div>
            </div>
          </div>
          <Divider orientationMargin="0" />
          <div className="flexRowWithoutStyle" style={{ gap: "1rem" }}>
            <div className="textPara">Today Appointments</div>
            <div></div>
            <div className="numberPara">
              <CountUp
                end={store.dashboardState.dashboardData?.todayAppointments}
              />
            </div>
          </div>
          <div>
            <Progress
              percent={30}
              strokeColor={{ "0%": "#108ee9", "100%": "#87d068" }}
            />
          </div>
        </div> */
}
// <div className="cardDiv">
//   <div
//     className="imageAndParas"
//     style={{ justifyContent: "space-between" }}
//   >
//     <div className="textAndNumberDiv">
//       <div className="numberPara">
//         <CountUp end={store.dashboardState.dashboardData?.totalCases} />
//       </div>
//       <div className="textPara">Cases</div>
//     </div>
//     {store.loginState.loginData?.isConsultant && (
//       <Link to={"/myCases"}>
//         <button
//           className="myCounselling"
//           style={{
//             border: "none",
//             backgroundColor: "#ddddddbb",
//             borderRadius: "0.2rem",
//             padding: "0.3rem 0.6rem",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             gap: "0.2rem",
//             cursor: "pointer",
//             height: "2rem",
//             color: "black",
//           }}
//         >
//           <Badge count={store.VisasState?.myInProgressCases?.totalData}>
//             <span>My Cases</span>
//           </Badge>
//         </button>
//       </Link>
//     )}
//   </div>
//   <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
//   <div>
//     <div style={{ height: "120px" }}>
//       <Pie {...config2} />
//     </div>
//   </div>
// </div>

// <div className="cardDiv">
//   <div className="imageAndParas">
//     <div className="imageCenter">
//       {/* <img src={test} alt="" height="70px" /> */}
//     </div>

//     <div className="textAndNumberDiv">
//       <div className="numberPara">
//         <CountUp end={store.contactState.contacts?.data?.length} />
//       </div>
//       <div className="textPara">Contacts</div>
//     </div>
//   </div>
//   <Divider style={{ margin: "0.5rem" }} orientationMargin="0" />
//   <div>
//     <div className="flexRowWithoutStyle" style={{ gap: "1rem" }}>
//       <div className="textPara">Active Contacts</div>
//       <div></div>
//       <div className="numberPara">
//         <CountUp end={store.contactState?.activeContacts?.length} />
//       </div>
//     </div>
//     <Avatar.Group
//       maxCount={10}
//       maxPopoverTrigger="click"
//       size="large"
//       maxStyle={{
//         color: "#f56a00",
//         backgroundColor: "#fde3cf",
//         cursor: "pointer",
//       }}
//     >
//       {store.contactState?.activeContacts?.map((dataObj) => {
//         return (
//           <div key={dataObj.id}>
//             <Tooltip
//               title={dataObj.firstName + " " + dataObj.lastName}
//               placement="top"
//             >
//               <Avatar
//                 src={dataObj.contactImage}
//                 style={{
//                   backgroundColor: "blue",
//                   verticalAlign: "middle",
//                 }}
//                 size="large"
//               >
//                 {dataObj.firstName.charAt(0).toUpperCase()}
//               </Avatar>
//             </Tooltip>
//           </div>
//         );
//       })}
//     </Avatar.Group>
//   </div>
// </div>
{
  /* here is companies */
}
// <div className="cardDiv">
//   <div className="imageAndParas">
//     <div className="imageCenter">
//       {/* <img src={test} alt="" height="70px" /> */}
//     </div>

//     <div className="textAndNumberDiv">
//       <div className="numberPara">
//         <CountUp end={store.dashboardState.dashboardData?.allCompany} />
//       </div>
//       <div className="textPara">Companies</div>
//     </div>
//   </div>
//   <Divider style={{ margin: "0.5rem" }} orientationMargin="0" />
//   <div>
//     <div className="flexRowWithoutStyle" style={{ gap: "1rem" }}>
//       <div className="textPara">Active Companies</div>
//       <div></div>
//       <div className="numberPara">
//         <CountUp
//           end={store.dashboardState.dashboardData?.activeCompany}
//         />
//       </div>
//     </div>
//     <Avatar.Group
//       maxCount={10}
//       maxPopoverTrigger="click"
//       size="large"
//       maxStyle={{
//         color: "#f56a00",
//         backgroundColor: "#fde3cf",
//         cursor: "pointer",
//       }}
//     >
//       {store.companiesState?.activeCompanies?.map((dataObj) => {
//         return (
//           <>
//             <Tooltip title={dataObj.companyName} placement="top">
//               <Avatar
//                 src={dataObj.contactImage}
//                 style={{
//                   backgroundColor: "blue",
//                   verticalAlign: "middle",
//                 }}
//                 size="large"
//               >
//                 {dataObj.companyName.charAt(0).toUpperCase()}
//               </Avatar>
//             </Tooltip>
//           </>
//         );
//       })}
//     </Avatar.Group>
//   </div>
// </div>
// adsfjk */}

// <div className="cardDiv">
//   <div
//     className="imageAndParas"
//     style={{ justifyContent: "space-between" }}
//   >
//     <div className="textAndNumberDiv">
//       <div className="numberPara">
//         <CountUp
//           end={store.dashboardState.dashboardData?.allAppointments}
//         />
//       </div>
//       <div className="textPara">Appointments</div>
//     </div>

//     {store.loginState.loginData?.isConsultant && (
//       <Link to={"/myCounselling"}>
//         <button
//           className="myCounselling"
//           style={{
//             border: "none",
//             backgroundColor: "#ddddddbb",
//             borderRadius: "0.2rem",
//             padding: "0.3rem 0.6rem",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             gap: "0.2rem",
//             cursor: "pointer",
//             height: "2rem",
//             color: "black",
//           }}
//         >
//           <Badge
//             count={store.MyCounsellingState.myCounselling?.totalData}
//           >
//             <span>My Counselling</span>
//           </Badge>
//         </button>
//       </Link>
//     )}
//   </div>
//   <Divider style={{ margin: "0.5rem" }} orientationMargin="0" />
//   <div>
//     <div className="flexRowWithoutStyle" style={{ gap: "1rem" }}>
//       <div className="textPara">Today Appointments</div>
//       <div></div>
//       <div className="numberPara">
//         <CountUp
//           end={store.dashboardState.dashboardData?.todayAppointments}
//         />
//       </div>
//     </div>
//   </div>
// </div>

// <div className="cardDiv">
//   <div className="imageAndParas">
//     <div className="imageCenter">
//       {/* <img src={test} alt="" height="70px" /> */}
//     </div>

//     <div className="textAndNumberDiv">
//       <div className="numberPara">
//         <CountUp
//           end={store.dashboardState.dashboardData?.allInstitute}
//         />
//       </div>
//       <div className="textPara">Institutes</div>
//     </div>
//   </div>
//   <Divider style={{ margin: "0.5rem" }} orientationMargin="0" />
//   <div>
//     <div className="flexRowWithoutStyle" style={{ gap: "1rem" }}>
//       <div className="textPara">Active Institutes</div>
//       <div></div>
//       <div className="numberPara">
//         <CountUp
//           end={store.dashboardState.dashboardData?.activeInstitute}
//         />
//       </div>
//     </div>
{
  /* <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>Pending Conselling</span>
                <span>0</span>
              </div>
              <Progress
                percent={30}
                strokeColor={{ "0%": "#108ee9", "100%": "#87d068" }}
                showInfo={false}
              />
            </div> */
}
//   </div>
// </div>
// <div className="cardDiv">
//   <div className="imageAndParas">
//     <div className="imageCenter">
//       {/* <img src={test} alt="" height="70px" /> */}
//     </div>

//     <div className="textAndNumberDiv">
//       <div className="numberPara">
//         <CountUp end={store.dashboardState.dashboardData?.allCourses} />
//       </div>
//       <div className="textPara">Courses</div>
//     </div>
//   </div>
//   <Divider style={{ margin: "0.5rem" }} orientationMargin="0" />
//   <div>
//     <div className="flexRowWithoutStyle" style={{ gap: "1rem" }}>
//       <div className="textPara">Active Courses</div>
//       <div></div>
//       <div className="numberPara">
//         <CountUp
//           end={store.dashboardState.dashboardData?.activeCourses}
//         />
//       </div>
//     </div>
{
  /* <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>Pending Conselling</span>
                <span>0</span>
              </div>
              <Progress
                percent={30}
                strokeColor={{ "0%": "#108ee9", "100%": "#87d068" }}
                showInfo={false}
              />
            </div> */
}
//     </div>
//   </div>
// </div>
