import { Avatar, Badge, List, Segmented, Skeleton, Tag } from "antd";
import Item from "antd/lib/list/Item";
import moment from "moment";
import React, { useState } from "react";
import CountUp from "react-countup";
import {
  formattedDate,
  formattedDateTime,
  threeTagResponses,
  twoTagResponses,
  twoTagResponsesWithStatuses,
} from "../../HelperFunction";
const DashboardComponentList = ({
  title,
  contactList,
  isAppointment,
  appointmentList,
  myAppointmentList,
  totalMyCounselling,
  caseInProgressList,
  caseNotAssignedList,
  caseCompletedList,
  isCase,
  announcements,
  taskAssigned,
  clientList,
  leadList,
}) => {
  //   const list = [
  //     {
  //       title: "Hello",
  //       description:
  //         "Ant Design, a design language for background applications, is refined by Ant UED Team",
  //     },
  //   ];
  console.log("different contactlist", contactList);
  console.log("different clientList", clientList);
  console.log("different leadList", leadList);
  console.log("different appointmentlist", appointmentList);
  console.log("different inprogress caselist", caseInProgressList);
  console.log("different completed caselist", caseCompletedList);
  console.log("different not assigned caselist", caseNotAssignedList);
  console.log("different casetype", isCase);
  const [casetype, setCaseType] = useState("IN_PROGRESS");
  const [appointmenttype, setAppointmenttype] = useState("LATEST");
  const Contactlist =
    contactList &&
    contactList?.slice(0, 6).map((dataObj) => {
      return {
        item1: dataObj.id,
        item2: dataObj.contactImage,
        item3: dataObj.fullName,
        item4: dataObj.email,
        item5: dataObj.phoneNumber,
        item6: dataObj.activeStatus ? (
          <p className="greenTag">ACTIVE</p>
        ) : (
          <p className="redTag">INACTIVE</p>
        ),
      };
    });
  const Clientlist =
    clientList &&
    clientList?.slice(0, 6).map((dataObj) => {
      return {
        item1: dataObj.id,
        item2: dataObj.photo,
        item3: dataObj.firstName + " " + dataObj.lastName,
        item4: dataObj.email,
        item5: dataObj.mobile,
        item6: dataObj.activeStatus ? (
          <p className="greenTag">ACTIVE</p>
        ) : (
          <p className="redTag">INACTIVE</p>
        ),
      };
    });
  const LeadList =
    leadList &&
    leadList?.slice(0, 6).map((dataObj) => {
      return {
        item1: dataObj.id,
        item2: dataObj.customerResponse?.photo,
        item3: `${dataObj?.firstName ?? ""}  ${dataObj?.lastName ?? ""}`,
        item4: dataObj?.email,
        item7: dataObj?.mobile,
        item5: dataObj.leadStatus?.statusName ? (
          <Tag
            color={
              dataObj.leadStatus?.statusColorCode
                ? dataObj.leadStatus?.statusColorCode
                : "green"
            }
          >
            {dataObj.leadStatus?.statusName}
          </Tag>
        ) : null,
        item6: dataObj.visaOutcome ? (
          <Tag
            color={
              dataObj.visaOutcomeColorCode
                ? dataObj.visaOutcomeColorCode
                : "White"
            }
          >
            {dataObj.visaOutcome}
          </Tag>
        ) : (
          "N/A"
        ),
      };
    });
  let APpointmentList =
    appointmenttype === "LATEST"
      ? appointmentList
      : appointmenttype === "MY_APPOINTMENTS"
      ? myAppointmentList
      : [];
  const Appointmentlist =
    APpointmentList &&
    APpointmentList?.slice(0, 6).map((dataObj) => {
      return {
        item1: dataObj.id,
        item2: dataObj?.leadResponse?.customerResponse?.photo,
        item3: dataObj.firstName + " " + dataObj.lastName,
        item4: dataObj.email,
        item5: formattedDate(dataObj.appointmentDate),

        item6: threeTagResponses(
          dataObj.appointmentStatus,
          "PENDING",
          "COMPLETED",
          "CONFIRMED"
        ),
      };
    });
  let caseList =
    casetype === "IN_PROGRESS"
      ? caseInProgressList
      : casetype === "NOT_ASSIGNED"
      ? caseNotAssignedList
      : casetype === "COMPLETED"
      ? caseCompletedList
      : [];
  const CaseList =
    caseList &&
    caseList?.slice(0, 6).map((dataObj) => {
      return {
        item1: dataObj.id,
        item2: dataObj.customer?.photo,
        item3: dataObj.customer?.fullName,
        item4: dataObj.customer?.email,
        item7: dataObj.customer?.mobile,
        item5: dataObj.workFlowStatusDto?.statusName ? (
          <Tag
            color={
              dataObj.workFlowStatusDto?.colorCode
                ? dataObj.workFlowStatusDto?.colorCode
                : "green"
            }
          >
            {dataObj.workFlowStatusDto?.statusName}
          </Tag>
        ) : null,
        item6: dataObj.visaOutcome ? (
          <Tag
            color={
              dataObj.visaOutcomeColorCode
                ? dataObj.visaOutcomeColorCode
                : "White"
            }
          >
            {dataObj.visaOutcome}
          </Tag>
        ) : (
          "N/A"
        ),
      };
    });
  const Announcements =
    announcements &&
    announcements?.slice(0, 6).map((dataObj, index) => {
      return {
        item1: index,
        message: dataObj?.message,
      };
    });

  const Tasks =
    taskAssigned &&
    taskAssigned?.slice(0, 6).map((dataObj, index) => {
      return {
        id: dataObj.id,
        index: index,
        title: dataObj.taskTitle,
        description: dataObj.taskDescription,
        dueDate: formattedDateTime(dataObj.dueDate),
        priority: threeTagResponses(
          dataObj.priority,
          "NORMAL",
          "HIGH",
          "URGENT"
        ),
      };
    });

  return (
    <div
      style={{
        border: "1px solid #cccccc",
        padding: "1rem",
        backgroundColor: "white",
        // width: "49%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        borderRadius: "5px",
      }}
    >
      <div
        style={{
          fontSize: "12px",
          fontWeight: "700",
          color: "#888888",
          marginBottom: "0.4rem",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <div>{title}</div>
        {isCase ? (
          <Segmented
            size="small"
            options={[
              {
                label: (
                  <div style={{ padding: 0 }}>
                    <div style={{ fontSize: "12px" }}>IN PROGRESS</div>
                  </div>
                ),
                value: "IN_PROGRESS",
              },
              {
                label: (
                  <div style={{ padding: 0 }}>
                    <div style={{ fontSize: "12px" }}>NOT ASSIGNED</div>
                  </div>
                ),
                value: "NOT_ASSIGNED",
              },
              {
                label: (
                  <div style={{ padding: 0 }}>
                    <div style={{ fontSize: "12px" }}>COMPLETED</div>
                  </div>
                ),
                value: "COMPLETED",
              },
            ]}
            onChange={(value) => {
              setCaseType(value);
              console.log(value);
            }}
          />
        ) : null}
        {isAppointment ? (
          <Segmented
            size="small"
            options={[
              {
                label: (
                  <div style={{ padding: 0 }}>
                    <div style={{ fontSize: "12px" }}>Latest</div>
                  </div>
                ),
                value: "LATEST",
              },
              {
                label: (
                  <div style={{ padding: 0 }}>
                    <Badge size="small" count={totalMyCounselling}>
                      <div style={{ fontSize: "12px" }}>My Appointments</div>
                    </Badge>
                  </div>
                ),
                value: "MY_APPOINTMENTS",
              },
            ]}
            onChange={(value) => {
              setAppointmenttype(value);
              console.log(myAppointmentList);
            }}
          />
        ) : null}
      </div>
      {contactList !== undefined ? (
        <List
          className="demo-loadmore-list"
          loading={false}
          itemLayout="horizontal"
          //   loadMore={loadMore}
          dataSource={Contactlist}
          renderItem={(item) => (
            <List.Item
              key={item.item1}
              actions={[
                <div style={{ color: "black" }}>{item.item5}</div>,
                <div style={{ width: "6rem" }}>{item.item6}</div>,
              ]}
            >
              <Skeleton avatar loading={item.loading} active>
                <List.Item.Meta
                  avatar={
                    <Avatar src={item.item2}>
                      {item.item2 === null &&
                        item.item3.charAt(0).toUpperCase()}
                    </Avatar>
                  }
                  title={item.item3}
                  description={
                    <div className="ellipsisStyle">{item.item4}</div>
                  }
                />
              </Skeleton>
            </List.Item>
          )}
        />
      ) : appointmentList !== undefined ? (
        <List
          className="demo-loadmore-list"
          loading={false}
          itemLayout="horizontal"
          //   loadMore={loadMore}
          dataSource={Appointmentlist}
          renderItem={(item) => (
            <List.Item
              key={item.item1}
              actions={[
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ color: "black" }}>{item.item5}</div>{" "}
                  <div>{"Appointment Date"}</div>
                </div>,
                <div style={{ width: "6rem" }}>{item.item6}</div>,
              ]}
            >
              <Skeleton avatar loading={item.loading} active>
                <List.Item.Meta
                  avatar={
                    <Avatar src={item.item2}>
                      {item.item2 === null &&
                        item.item3.charAt(0).toUpperCase()}
                    </Avatar>
                  }
                  title={item.item3}
                  description={
                    <div className="ellipsisStyle">{item.item4}</div>
                  }
                />
              </Skeleton>
            </List.Item>
          )}
        />
      ) : caseList !== undefined ? (
        <List
          className="demo-loadmore-list"
          loading={false}
          itemLayout="horizontal"
          //   loadMore={loadMore}
          dataSource={CaseList}
          renderItem={(item) => (
            <List.Item
              key={item.item1}
              actions={[
                <div>{item.item7}</div>,
                <div style={{ width: "10rem" }}>{item.item5}</div>,
              ]}
            >
              <Skeleton avatar loading={item.loading} active>
                <List.Item.Meta
                  avatar={
                    <Avatar src={item.item2}>
                      {item.item2 === null &&
                        item.item3.charAt(0).toUpperCase()}
                    </Avatar>
                  }
                  title={item.item3}
                  description={
                    <div className="ellipsisStyle">{item.item4}</div>
                  }
                />
              </Skeleton>
            </List.Item>
          )}
        />
      ) : announcements !== undefined ? (
        <List
          className="demo-loadmore-list"
          loading={false}
          itemLayout="horizontal"
          //   loadMore={loadMore}
          dataSource={Announcements}
          renderItem={(item) => (
            <List.Item key={item.item1}>
              <Skeleton avatar loading={item.loading} active>
                <List.Item.Meta
                  avatar={
                    <Avatar style={{ backgroundColor: "green" }}>
                      {item.item1 + 1}
                    </Avatar>
                  }
                  description={
                    <div className="ellipsisStyle"> {item.message}</div>
                  }
                />
              </Skeleton>
            </List.Item>
          )}
        />
      ) : taskAssigned !== undefined ? (
        <List
          className="demo-loadmore-list"
          loading={false}
          itemLayout="horizontal"
          //   loadMore={loadMore}
          dataSource={Tasks}
          renderItem={(item) => (
            <List.Item
              key={item.id}
              actions={[
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ color: "black" }}>{item.dueDate}</div>
                  <div>{"Due Date"}</div>
                </div>,
                <div style={{ width: "6rem" }}>{item.priority}</div>,
              ]}
            >
              <Skeleton avatar loading={item.loading} active>
                <List.Item.Meta
                  avatar={
                    <Avatar style={{ backgroundColor: "blue" }}>
                      {item.index + 1}
                    </Avatar>
                  }
                  title={item.title}
                  description={
                    <div className="ellipsisStyle">{item.description}</div>
                  }
                />
              </Skeleton>
            </List.Item>
          )}
        />
      ) : Clientlist !== undefined ? (
        <List
          className="demo-loadmore-list"
          loading={false}
          itemLayout="horizontal"
          //   loadMore={loadMore}
          dataSource={Clientlist}
          renderItem={(item) => (
            <List.Item
              key={item.item1}
              actions={[
                <div style={{ color: "black" }}>{item.item5}</div>,
                <div style={{ width: "6rem" }}>{item.item6}</div>,
              ]}
            >
              <Skeleton avatar loading={item.loading} active>
                <List.Item.Meta
                  avatar={
                    <Avatar src={item.item2}>
                      {item.item2 === null &&
                        item.item3.charAt(0).toUpperCase()}
                    </Avatar>
                  }
                  title={item.item3}
                  description={
                    <div className="ellipsisStyle">{item.item4}</div>
                  }
                />
              </Skeleton>
            </List.Item>
          )}
        />
      ) : LeadList !== undefined ? (
        <List
          className="demo-loadmore-list"
          loading={false}
          itemLayout="horizontal"
          //   loadMore={loadMore}
          dataSource={LeadList}
          renderItem={(item) => (
            <List.Item
              key={item.item1}
              actions={[
                <div>{item.item7}</div>,
                <div style={{ width: "10rem" }}>{item.item5}</div>,
              ]}
            >
              <Skeleton avatar loading={item.loading} active>
                <List.Item.Meta
                  avatar={<Avatar>{item.item3.charAt(0).toUpperCase()}</Avatar>}
                  title={item.item3}
                  description={
                    <div className="ellipsisStyle">{item.item4}</div>
                  }
                />
              </Skeleton>
            </List.Item>
          )}
        />
      ) : null}
    </div>
  );
};

export default DashboardComponentList;
