import { Card, Divider, Drawer } from "antd";
import React, { useEffect } from "react";
import { formattedDate, twoTagResponses } from "../../HelperFunction";
import { FcDocument } from "react-icons/fc";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ImCheckboxChecked } from "react-icons/im";

const CompanyDetails = ({
  record,
  isCompanyDetalisModalVisible,
  setIsCompanyDetalisModalVisible,
}) => {
  const visaState = useSelector((state) => state.VisasReducer);

  const dispatch = useDispatch();

  const [current, setCurrent] = useState("companyDetail");
  const companyDetailsTabList = [
    {
      key: "companyDetail",
      tab: "Company Detail",
    },
    {
      key: "packages",
      tab: "Packages",
    },
  ];
  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };
  console.log("company details record", record);

  return (
    <Drawer
      title="Company Details"
      open={isCompanyDetalisModalVisible}
      onClose={() => {
        setIsCompanyDetalisModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Card style={{ borderRadius: "0.5rem" }} className="drawerStyle">
        <div className="flexRowWithoutStyle" style={{ gap: "1rem" }}>
          <div
            className="flexColumnWithoutStyle"
            style={{
              justifyContent: "flex-start",
              width: "100%",
              gap: "0.5rem",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: "bold" }}>
              {record?.companyName}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.4rem", color: "#aaa" }}
            >
              {record?.fullName}
            </div>
            <div>{record?.degree?.name}</div>

            {/* <div
              className="flexRowWithoutStyle"
              style={{ gap: "1rem", padding: "1rem" }}
            >
              <span>Time Period: </span>
              <span>
                {record?.courseDuration} {record?.courseAccordingTo}
              </span>
            </div> */}
          </div>
        </div>
        <Card
          bordered={false}
          tabList={companyDetailsTabList}
          activeTabKey={current}
          onTabChange={(key) => {
            setCurrent(key);
          }}
        >
          {current === "companyDetail" && (
            <>
              {detailFun("Company Id", `${record?.id ? record?.id : "N/A"}`)}
              {detailFun("email", `${record?.email ? record?.email : "N/A"}`)}
              {detailFun(
                "Mobile Number",
                `${record?.mobile ? record?.mobile : "N/A"}`
              )}
              {detailFun(
                "Phone Number",
                `${record?.phoneNumber ? record?.phoneNumber : "N/A"}`
              )}
              {detailFun(
                "Adress Line 1",
                `${record?.address ? record?.address : "N/A"}`
              )}
              {detailFun(
                "Adress Line 2",
                `${record?.address2 ? record?.address2 : "N/A"}`
              )}
              {detailFun(
                "Agreement Start Date",
                `${
                  record?.agreementStartDate
                    ? formattedDate(record?.agreementStartDate)
                    : "N/A"
                }`
              )}
              {detailFun(
                "Agreement Expiry Date",
                `${
                  record?.agreementExpiryDate
                    ? formattedDate(record?.agreementExpiryDate)
                    : "N/A"
                }`
              )}

              {detailFun(
                "Commission",
                `${record?.commission ? record?.commission : "N/A"}`
              )}
              {detailFun(
                "Is Direct Partner",
                `${record?.isDirectPartner ? "Yes" : "No"}`
              )}
              {detailFun(
                "Country",
                `${record?.country?.name ? record?.country?.name : "N/A"}`
              )}

              {detailFun(
                "PostCode",
                `${record?.postCode ? record?.postCode : "N/A"}`
              )}
              {detailFun("Staff", `${record?.staff ? record?.staff : "N/A"}`)}
              {detailFun("State", `${record?.state ? record?.state : "N/A"}`)}
              {detailFun(
                "suburb",
                `${record?.suburb ? record?.suburb : "N/A"}`
              )}
            </>
          )}
          {current === "packages" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1px",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "1rem",
                  width: "100%",
                }}
              >
                {record?.casePackages?.length > 0 ? (
                  record?.casePackages?.map((dataObj) => {
                    return (
                      <div
                        key={dataObj?.id}
                        style={{
                          backgroundColor: "#eeeeee55",
                          width: "48%",
                          borderRadius: "0.5rem",
                          padding: "0.5rem",
                          marginBottom: "0.5rem",
                        }}
                      >
                        {dataObj?.company?.name !== undefined && (
                          <>
                            {detailFun(
                              "Company",
                              `${
                                dataObj?.company?.name
                                  ? dataObj?.company?.name
                                  : "N/A"
                              }`
                            )}
                          </>
                        )}
                        {dataObj?.institute?.name !== undefined && (
                          <>
                            {detailFun(
                              "Company",
                              `${
                                dataObj?.institute?.name
                                  ? dataObj?.institute?.name
                                  : "N/A"
                              }`
                            )}
                          </>
                        )}
                        {detailFun(
                          "Package Name",
                          `${
                            dataObj?.packageName ? dataObj?.packageName : "N/A"
                          }`
                        )}

                        {detailFun(
                          "Unit Price",
                          `${
                            dataObj?.unitPrice
                              ? `${dataObj?.unitPrice} ${
                                  dataObj?.currency ? dataObj?.currency : ""
                                }`
                              : "N/A"
                          }`
                        )}
                        {dataObj?.gst !== "" &&
                          dataObj?.gst !== null &&
                          dataObj?.gst !== undefined && (
                            <>
                              {detailFun(
                                "GST/VAT",
                                `${
                                  dataObj?.gst
                                    ? `${dataObj?.gst} ${
                                        dataObj?.isGstPercentage === true
                                          ? `% `
                                          : dataObj?.isGstFlat
                                          ? `${
                                              dataObj?.currency
                                                ? dataObj?.currency
                                                : ""
                                            } FLAT`
                                          : ""
                                      }`
                                    : "N/A"
                                }`
                              )}
                            </>
                          )}
                        {dataObj?.commission !== "" &&
                          dataObj?.commission !== null &&
                          dataObj?.commission !== undefined && (
                            <>
                              {detailFun(
                                "Commission",
                                `${
                                  dataObj?.commission
                                    ? `${dataObj?.commission} ${
                                        dataObj?.isCommissionPercentage === true
                                          ? `% `
                                          : dataObj?.isCommissionFlat
                                          ? `${
                                              dataObj?.currency
                                                ? dataObj?.currency
                                                : ""
                                            } FLAT`
                                          : ""
                                      }`
                                    : "N/A"
                                }`
                              )}
                            </>
                          )}
                      </div>
                    );
                  })
                ) : (
                  <div style={{ textAlign: "center" }}>
                    No Company Packages Available.
                  </div>
                )}
              </div>
            </div>
          )}
        </Card>
      </Card>
    </Drawer>
  );
};

export default CompanyDetails;
