import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
  Input,
  Select,
  Form,
  Button,
  Image,
} from "antd";
import { MdOutlineDisabledVisible } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../Components/Layout/SiderDemo";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
import { AiOutlineEye } from "react-icons/ai";
import InstituteDetais from "../Education/Institute/InstituteDetails";
import CollegeFinderInstituteDescription from "./CollegeFinderInstituteDescription";
import { useLocation } from "react-router-dom";
const { Option } = Select;

const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "Image",
    dataIndex: "image",
    align: "center",
  },
  {
    title: "Institute Name",
    dataIndex: "instituteName",
    align: "center",
  },
  {
    title: "Level of College",
    dataIndex: "levelOfCollege",
    align: "center",
  },
  {
    title: "Location",
    dataIndex: "location",
    align: "center",
  },

  {
    title: "Institute URL",
    dataIndex: "universityUrl",
    align: "center",
  },

  {
    title: "contact Person Name",
    dataIndex: "contactPersonName",
    align: "center",
  },
  {
    title: "Contact",
    dataIndex: "contact",
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
  },
];

const CollegeFinder = () => {
  const collegeFinderState = useSelector((state) => state.CollegeFinderReducer);
  const [record, setRecord] = useState({});
  const [selectedCountry, setSelectedCountry] = useState("Select");
  const [selectedDegree, setSelectedDegree] = useState("Select");
  const [selectedCourse, setSelectedCourse] = useState("Select");
  const [selectedIntake, setSelectedIntake] = useState("Select");
  const [isInstituteDetailsModelVisible, setIsInstituteDetailsModelVisible] =
    useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "FETCH_ALL_COUNTRY_REQUEST" });
  }, []);
  const [form] = Form.useForm();
  const data = collegeFinderState.allCollege?.data?.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj.id,
      image: dataObj.image ? (
        <Image
          src={dataObj.image}
          style={{ height: "50px", width: "50px", border: "1px solid #ccc" }}
        />
      ) : (
        "N/A"
      ),
      instituteName: dataObj.instituteName ?? "N/A",
      levelOfCollege: dataObj.levelOfCollege?.name ?? "N/A",
      location: dataObj.location ?? "N/A",
      universityUrl: dataObj.universityUrl ?? "N/A",
      contactPersonName: dataObj.contactPersonName ?? "N/A",
      contact: dataObj.contact ?? "N/A",
      actions: (
        <Space>
          <Tooltip title="Institute Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsInstituteDetailsModelVisible(true);
                  setRecord(dataObj);
                }}
              >
                <AiOutlineEye />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    };
  });
  console.log("from college state", collegeFinderState);
  console.log("country from degree finder", selectedDegree);
  console.log("country from course finder", selectedCourse);
  console.log("country from intake finder", selectedIntake);
  return (
    <SiderDemo>
      <div className="DrawerStyle">
        <Form layout="vertical" onFinish={() => {}} colon={true} form={form}>
          <div className="flexRowWithoutStyle" style={{ gap: "1rem" }}>
            <Form.Item
              label="Country"
              name={"country"}
              style={{ width: "100%" }}
              labelAlign="left"
            >
              <Select>
                <Option value="Select">
                  <div
                    onClick={() => {
                      setSelectedCountry("Select");
                    }}
                  >
                    Select
                  </div>
                </Option>
                {collegeFinderState.countryFilter?.map((dataObj, index) => {
                  return (
                    <Option key={index} value={dataObj.filterName}>
                      <div
                        onClick={() => {
                          setSelectedCountry(dataObj.filterName);
                          dispatch({
                            type: "FETCH_ALL_DEGREE_REQUEST",
                            payload: dataObj.filterName,
                          });
                        }}
                      >
                        {dataObj.filterName}
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label="Degree" name={"degree"} style={{ width: "100%" }}>
              <Select>
                <Option value="Select">
                  <div
                    onClick={() => {
                      setSelectedDegree("Select");
                    }}
                  >
                    Select
                  </div>
                </Option>
                {collegeFinderState.degreeFilter?.map((dataObj, index) => {
                  return (
                    <Option key={index} value={dataObj.filterName}>
                      <div
                        onClick={() => {
                          setSelectedDegree(dataObj.filterName);
                          dispatch({
                            type: "FETCH_ALL_COURSE_REQUEST",
                            payload: dataObj.filterName,
                          });
                        }}
                      >
                        {dataObj.filterName}
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label="Course" name={"course"} style={{ width: "100%" }}>
              <Select>
                <Option value="Select">
                  <div
                    onClick={() => {
                      setSelectedCourse("Select");
                    }}
                  >
                    Select
                  </div>
                </Option>
                {collegeFinderState.courseFilter?.map((dataObj, index) => {
                  return (
                    <Option key={index} value={dataObj.filterName}>
                      <div
                        onClick={() => {
                          setSelectedCourse(dataObj.filterName);
                          dispatch({
                            type: "FETCH_ALL_INTAKE_REQUEST",
                            payload: dataObj.filterName,
                          });
                        }}
                      >
                        {dataObj.filterName}
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label="Intake" name={"intake"} style={{ width: "100%" }}>
              <Select>
                <Option value="Select">
                  <div
                    onClick={() => {
                      setSelectedIntake("Select");
                    }}
                  >
                    Select
                  </div>
                </Option>
                {collegeFinderState.intakeFilter?.map((dataObj, index) => {
                  return (
                    <Option key={index} value={dataObj.filterName}>
                      <div
                        onClick={() => {
                          setSelectedIntake(dataObj.filterName);
                          dispatch({
                            type: "FETCH_ALL_COLLEGE_REQUEST",
                            payload: {
                              selectedCountry,
                              selectedDegree,
                              selectedCourse,
                              selectedIntake: dataObj.filterName,
                            },
                          });
                        }}
                      >
                        {dataObj.filterName}
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
          <div>
            <Button
              type="primary"
              onClick={() => {
                dispatch({
                  type: "FETCH_ALL_COLLEGE_REQUEST",
                  payload: {
                    selectedCountry,
                    selectedDegree,
                    selectedCourse,
                    selectedIntake,
                  },
                });
              }}
            >
              FIND OPTIONS
            </Button>
          </div>
        </Form>
        <div style={{ marginTop: "4rem" }}>
          {collegeFinderState.isLoading ? (
            <TableSkeleton />
          ) : (
            <Table bordered columns={columns} dataSource={data} />
          )}
        </div>
      </div>
      {isInstituteDetailsModelVisible && (
        <CollegeFinderInstituteDescription
          record={record}
          isInstituteDetailsModelVisible={isInstituteDetailsModelVisible}
          setIsInstituteDetailsModelVisible={setIsInstituteDetailsModelVisible}
        />
      )}
    </SiderDemo>
  );
};

export default CollegeFinder;
