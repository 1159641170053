import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
  TimePicker,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { date, dateAndTime, dateUTC } from "../../HelperFunction";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import axios from "axios";
const { Option } = Select;

const UpdateEmployee = ({
  record,
  updateEmployeeTitle,
  isUpdateEmployeeModalVisible,
  setIsUpdateEmployeeModalVisible,
}) => {
  const [isConsultant, setIsConsultant] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [incomingScheduleDays, setIncomingScheduleDays] = useState(
    record?.availableDays?.scheduleDays
  );
  const [wantPasswordChanged, setWantPasswordChanged] = useState(false);

  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [employeeData, setEmployeeData] = useState({
    isConsultant: record?.isConsultant,
    password: "",
    confirmPassword: "",
    callingCode: record?.callingCode,
    phoneNumber: record?.phoneNumber,
    email: record?.emailId,
    dob: record?.dob,
    gender: record?.gender,
    lastName: record?.lastName,
    firstName: record?.firstName,
    zipCode: record?.zipCode,
    suburb: record?.suburb,
    state: record?.state,
    country: record?.country,
    address2: record?.address2,
    address: record?.address,
  });
  const [error, setError] = useState("");

  useEffect(() => {
    if (isUpdateEmployeeModalVisible === true) {
      setEmployeeData({
        isConsultant: record?.isConsultant,
        password: "",
        confirmPassword: "",
        callingCode: record?.callingCode,
        phoneNumber: record?.phoneNumber,
        email: record?.emailId,
        dob: record?.dob,
        gender: record?.gender,
        lastName: record?.lastName,
        firstName: record?.firstName,
        zipCode: record?.zipCode,
        suburb: record?.suburb,
        state: record?.state,
        country: record?.country,
        address2: record?.address2,
        address: record?.address,
      });

      const makeSameScheduleDays =
        record?.availableDays?.scheduledDaysDtoResponse?.length > 0 &&
        record?.availableDays?.scheduledDaysDtoResponse?.map((dataObj) => {
          console.log("rendered from map", dataObj);
          return {
            days: dataObj?.days,
            start: dataObj.start,
            end: dataObj?.end,
            breakStartTime: dataObj?.breakStartTime,
            breakEndTime: dataObj?.breakEndTime,
          };
        });

      record?.availableDays?.scheduleDays?.length > 0 &&
        setScheduleDay(makeSameScheduleDays);
      console.log("converted schedule days", makeSameScheduleDays);
    }
  }, [isUpdateEmployeeModalVisible]);
  console.log("schedule incomig days state", incomingScheduleDays);

  const updateEmployeeSubmitHandler = (value) => {
    console.log("from ", value);
    const dob = value.dob ? dateUTC(value.dob) : null;
    const scheduleDays = employeeData.isConsultant && scheduleDay;

    const formData = {
      ...value,
      dob,
      profileImage: "adklf",
    };
    dispatch({
      type: "UPDATE_EMPLOYEE_REQUEST",
      payload: {
        id: record.id,
        values: formData,
        payload2: { scheduleDays: scheduleDays },
      },
    });
    form.resetFields();

    setIsUpdateEmployeeModalVisible(false);
  };
  // incomingScheduleDays?.length !== 0
  //     ? incomingScheduleDays
  //     :
  const [scheduleDay, setScheduleDay] = useState([
    {
      days: "",
      start: "",
      end: "",
      breakStartTime: "",
      breakEndTime: "",
    },
  ]);
  const removeScheduledDayInput = (index) => {
    const list = [...scheduleDay];
    list.splice(index, 1);
    setScheduleDay(list);
  };
  const addScheduledDayInput = () => {
    setScheduleDay([
      ...scheduleDay,
      {
        days: "",
        start: "",
        end: "",
        breakStartTime: "",
        breakEndTime: "",
      },
    ]);
  };
  const handleScheduleDayChange = (e, index) => {
    console.log(e, index);
    const list = [...scheduleDay];
    list[index].days = e;
    setScheduleDay(list);
  };
  const handleScheduleStartTimeChange = (e, index) => {
    console.log(e, index, "from handle shchedule start time");
    const list = [...scheduleDay];
    list[index].start = e;
    setScheduleDay(list);
  };
  const handleScheduleEndTimeChange = (e, index) => {
    console.log(e, index, "from handle shchedule start time");
    const list = [...scheduleDay];
    list[index].end = e;
    setScheduleDay(list);
  };
  const handleScheduleBreakStartTimeChange = (e, index) => {
    console.log(e, index, "from handle shchedule start time");
    const list = [...scheduleDay];
    list[index].breakStartTime = e;
    setScheduleDay(list);
  };
  const handleScheduleBreakEndTimeChange = (e, index) => {
    console.log(e, index, "from handle shchedule start time");
    const list = [...scheduleDay];
    list[index].breakEndTime = e;
    setScheduleDay(list);
  };
  console.log("record frm update employee", record);
  console.log("record frm schedule day update employee", scheduleDay);
  console.log(
    "record frm incoming schedule day update employee",
    incomingScheduleDays
  );

  useEffect(() => {
    fetchCountry();
  }, []);

  const fetchCountry = () => {
    const tempToken = Cookies.get("accessToken");
    axios
      .get(`v1/api/country/getAllCountry`, {
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      })
      .then((res) => {
        setCountryList(res.data.data);
      })
      .catch((err) => {
        alert(err);
      });
  };

  return (
    <Drawer
      title={updateEmployeeTitle}
      open={isUpdateEmployeeModalVisible}
      onClose={() => {
        setIsUpdateEmployeeModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Form
        layout="vertical"
        onFinish={updateEmployeeSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
        fields={[
          {
            name: ["firstName"],
            value: employeeData.firstName,
          },
          {
            name: ["lastName"],
            value: employeeData?.lastName,
          },
          {
            name: ["password"],
            value: employeeData?.password,
          },
          {
            name: ["confirmPassword"],
            value: employeeData?.confirmPassword,
          },
          {
            name: ["gender"],
            value: employeeData?.gender,
          },
          {
            name: ["dob"],
            value: employeeData?.dob
              ? moment.utc(employeeData?.dob).local()
              : "",
          },
          {
            name: ["email"],
            value: employeeData?.email,
          },
          {
            name: ["phoneNumber"],
            value: employeeData?.phoneNumber,
          },
          {
            name: ["callingCode"],
            value: employeeData?.callingCode,
          },
          {
            name: ["address"],
            value: employeeData?.address,
          },
          {
            name: ["address2"],
            value: employeeData?.address2,
          },
          {
            name: ["country"],
            value: employeeData?.country,
          },
          {
            name: ["state"],
            value: employeeData?.state,
          },
          {
            name: ["suburb"],
            value: employeeData?.suburb,
          },
          {
            name: ["zipCode"],
            value: employeeData?.zipCode,
          },

          {
            name: ["isConsultant"],
            value: employeeData?.isConsultant,
          },
        ]}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="First Name"
            name={"firstName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter first name!",
              },
            ]}
          >
            <Input
              placeholder="Please enter first name!"
              onChange={(e) => {
                setEmployeeData((previousData) => {
                  return {
                    ...previousData,
                    firstName: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name={"lastName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter last name!",
              },
            ]}
          >
            <Input
              placeholder="Please enter last name!"
              onChange={(e) => {
                setEmployeeData((previousData) => {
                  return {
                    ...previousData,
                    lastName: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Gender"
            name={"gender"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter gender!",
              },
            ]}
          >
            <Select
              onChange={(e) => {
                setEmployeeData((previousData) => {
                  return {
                    ...previousData,
                    gender: e,
                  };
                });
              }}
            >
              <Option value="MALE">Male</Option>
              <Option value="FEMALE">Female</Option>
              <Option value="OTHERS">Others</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Date of Birth"
            name={"dob"}
            style={{ width: "100%" }}
            validateStatus={error ? "error" : undefined}
            help={error}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please pick birth date!",
            //   },
            // ]}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              disabledDate={(currentDate) => {
                const date = new Date();
                return currentDate > date;
              }}
              onChange={(e) => {
                setEmployeeData((previousData) => {
                  return {
                    ...previousData,
                    dob: e,
                  };
                });
                if (e) {
                  const age = moment().diff(e, "years");
                  console.log("this is running", e);
                  if (age < 18) {
                    console.log("age is below 18 years old", age);
                    setError("Employee must be above 18 years old.");
                    form.setFields([
                      {
                        name: "dateOfBirth",
                        errors: ["You must be at least 18 years old."],
                      },
                    ]);
                  } else {
                    setError("");
                    form.setFields([
                      {
                        name: "dateOfBirth",
                        errors: [],
                      },
                    ]);
                  }
                }
              }}
            />
          </Form.Item>
        </div>
        {/* <Form.Item
          label="Email"
          name={"email"}
          rules={[
            {
              required: true,
              type: "email",
              message: "Please enter valid email!",
            },
          ]}
        >
          <Input
            type="email"
            placeholder="e.g, name@domainname.com"
            onChange={(e) => {
              setEmployeeData((previousData) => {
                return {
                  ...previousData,
                  email: e.target.value,
                };
              });
            }}
          />
        </Form.Item> */}

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Address Line 1"
            name={"address"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter address line 1!",
              },
            ]}
          >
            <Input
              placeholder="Address Line 1"
              onChange={(e) => {
                setEmployeeData((previousData) => {
                  return {
                    ...previousData,
                    address: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Address Line 2"
            name={"address2"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter address line 2!",
            //   },
            // ]}
          >
            <Input
              placeholder="Address Line 2"
              onChange={(e) => {
                setEmployeeData((previousData) => {
                  return {
                    ...previousData,
                    address2: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          {/* <Form.Item
            label="Country"
            name={"country"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter a country!",
              },
            ]}
          >
            <Input
              placeholder="Country"
              onChange={(e) => {
                setEmployeeData((previousData) => {
                  return {
                    ...previousData,
                    country: e.target.value,
                  };
                });
              }}
            />
          </Form.Item> */}
          <Form.Item style={{ width: "100%" }} label="Country" name="country">
            <Select
              placeholder="Select Country"
              onChange={(data, option) => {
                setEmployeeData((previousData) => {
                  return {
                    ...previousData,
                    country: data,
                    callingCode: option?.key,
                  };
                });
                form.setFieldsValue({ callingCode: option?.key });
              }}
            >
              {countryList?.map((country) => {
                return (
                  <Option key={country.callingCode} value={country.countryName}>
                    {country.countryName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          {employeeData.country?.toLowerCase() === "australia" ? (
            <Form.Item label="State" name={"state"} style={{ width: "100%" }}>
              <Select
                onChange={(e) => {
                  setEmployeeData((previousData) => {
                    return {
                      ...previousData,
                      state: e,
                    };
                  });
                }}
              >
                <Option value="NSW|New South Wales">New South Wales</Option>
                <Option value="QLD|Queensland">Queensland</Option>
                <Option value="SA|South Australia">South Australia</Option>
                <Option value="TAS|Tasmania">Tasmania</Option>
                <Option value="VIC|Victoria">Victoria</Option>
                <Option value="WA|Western Australia">Western Australia</Option>
                <Option value="ACT|Australian Capital Territory">
                  Australian Capital Territory
                </Option>
                <Option value="NT|Northern Territory">
                  Northern Territory
                </Option>
              </Select>
            </Form.Item>
          ) : (
            <Form.Item label="State" name={"state"} style={{ width: "100%" }}>
              <Input
                placeholder="State"
                onChange={(e) => {
                  setEmployeeData((previousData) => {
                    return {
                      ...previousData,
                      state: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
          )}
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Country Code"
            name={"callingCode"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter calling code!",
              },
            ]}
          >
            <Input
              readOnly
              placeholder="Calling Code"
              onChange={(e) => {
                setEmployeeData((previousData) => {
                  return {
                    ...previousData,
                    callingCode: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name={"phoneNumber"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter phone number!",
              },
              {
                pattern: /^[\d]{9,11}$/,
                message: "Mobile number should contain 9 to 11 numbers",
              },
            ]}
          >
            <Input
              type="number"
              placeholder="Please enter first name!"
              onChange={(e) => {
                setEmployeeData((previousData) => {
                  return {
                    ...previousData,
                    phoneNumber: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item label="Suburb" name={"suburb"} style={{ width: "100%" }}>
            <Input
              placeholder="Suburb"
              onChange={(e) => {
                setEmployeeData((previousData) => {
                  return {
                    ...previousData,
                    suburb: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>

          <Form.Item
            label="Post Code/Zip Code"
            name={"zipCode"}
            style={{ width: "100%" }}
          >
            <Input
              type="number"
              placeholder="Zip Code"
              onChange={(e) => {
                setEmployeeData((previousData) => {
                  return {
                    ...previousData,
                    zipCode: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <Form.Item name={"wantPasswordChanged"} valuePropName="checked">
          <Checkbox
            onChange={(e) => {
              setWantPasswordChanged(e.target.checked);
              console.log("From employee is consultant", e.target.checked);
            }}
          >
            Want to change your password?
          </Checkbox>
        </Form.Item>
        {wantPasswordChanged ? (
          <>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <Form.Item
                label="Password"
                name={"password"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter password!",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Password!"
                  onChange={(e) => {
                    setEmployeeData((previousData) => {
                      return {
                        ...previousData,
                        password: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name={"confirmPassword"}
                dependencies={["password"]}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please confirm the password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Password and confirm password do not match!")
                      );
                    },
                  }),
                ]}
                hasFeedback
              >
                <Input.Password
                  placeholder="Confirm Password!"
                  onChange={(e) => {
                    setEmployeeData((previousData) => {
                      return {
                        ...previousData,
                        confirmPassword: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
            </div>
          </>
        ) : null}

        <Form.Item name={"isConsultant"} valuePropName="checked">
          <Checkbox
            onChange={(e) => {
              setIsConsultant(e.target.checked);
              setEmployeeData((previousData) => {
                return {
                  ...previousData,
                  isConsultant: e.target.checked,
                };
              });
              console.log("From employee is consultant", e.target.checked);
            }}
          >
            Is Consultant
          </Checkbox>
        </Form.Item>
        {employeeData.isConsultant && (
          <>
            <div
              style={{
                backgroundColor: "#eeeeee55",
                borderRadius: "0.5rem",
                padding: "0.5rem",
              }}
            >
              <h3>Schedule Days:</h3>
              {scheduleDay?.map((singleDay, index) => {
                console.log(
                  scheduleDay[index].start,
                  "start schedule days from map"
                );
                return (
                  <div key={index}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: "0.5rem",
                          width: "100%",
                        }}
                      >
                        <Form.Item
                          label="Schedule Day"
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please select a day!",
                            },
                          ]}
                        >
                          <Select
                            name={"days"}
                            onChange={(e) => {
                              console.log("day of wweek", e);
                              handleScheduleDayChange(e, index);
                            }}
                            value={scheduleDay[index].days}
                            options={[
                              {
                                value: "SUNDAY",
                                label: "Sunday",
                              },
                              {
                                value: "MONDAY",
                                label: "Monday",
                              },
                              {
                                value: "TUESDAY",
                                label: "Tuesday",
                              },
                              {
                                value: "WEDNESDAY",
                                label: "Wednesday",
                              },
                              {
                                value: "THURSDAY",
                                label: "Thursday",
                              },
                              {
                                value: "FRIDAY",
                                label: "Friday",
                              },
                              {
                                value: "SATURDAY",
                                label: "Saturday",
                              },
                            ]}
                          />
                        </Form.Item>

                        <Form.Item
                          label="Start Time"
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please pick a start time in that day!",
                            },
                          ]}
                        >
                          <TimePicker
                            // onChange={(e) => {
                            //   console.log(
                            //     "from start time of schedule week",
                            //     e
                            //   );
                            //   handleScheduleStartTimeChange(e, index);
                            // }}
                            // value={moment(scheduleDay[index].start).format(
                            //   "HH:mm:ss"
                            // )}
                            // `Fri Jan 13 2023 ${scheduleDay[index].start} GMT+0545 (Nepal Time)`
                            name={"start"}
                            // defaultValue={dayjs("15:00:00", "HH:mm:ss")}
                            value={
                              scheduleDay[index].start
                                ? moment(
                                    `${scheduleDay[index].start}`,
                                    "HH:mm:00"
                                  ).local()
                                : null
                            }
                            onChange={(value) => {
                              handleScheduleStartTimeChange(
                                moment(value).format("HH:mm:00"),
                                index
                              );
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          label="End Time"
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please pick a end time in that day!",
                            },
                          ]}
                        >
                          {/* Fri Jan 13 2023 ${scheduleDay[index].end} GMT+0545 (Nepal Time) */}
                          <TimePicker
                            name={"end"}
                            value={
                              scheduleDay[index].end
                                ? moment(
                                    `${scheduleDay[index].end}`,
                                    "HH:mm:00"
                                  ).local()
                                : null
                            }
                            onChange={(e) => {
                              console.log("from end time of schedule week", e);
                              handleScheduleEndTimeChange(
                                moment(e).format("HH:mm:ss"),
                                index
                              );
                            }}
                            // value={moment(scheduleDay[index].end).format(
                            //   "HH:mm:ss"
                            // )}
                          />
                        </Form.Item>

                        <Form.Item
                          label="Break Start Time"
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message:
                                "Please pick a break start time in that day!",
                            },
                          ]}
                        >
                          <TimePicker
                            // onChange={(e) => {
                            //   console.log(
                            //     "from start time of schedule week",
                            //     e
                            //   );
                            //   handleScheduleStartTimeChange(e, index);
                            // }}
                            // value={moment(scheduleDay[index].start).format(
                            //   "HH:mm:ss"
                            // )}

                            name={"breakStartTime"}
                            // defaultValue={dayjs("15:00:00", "HH:mm:ss")}
                            value={
                              scheduleDay[index].breakStartTime
                                ? moment(
                                    `${scheduleDay[index].breakStartTime}`,
                                    "HH:mm:00"
                                  ).local()
                                : null
                            }
                            onChange={(value) => {
                              handleScheduleBreakStartTimeChange(
                                moment(value).format("HH:mm:ss"),
                                index
                              );
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Break End Time"
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message:
                                "Please pick a break end time in that day!",
                            },
                          ]}
                        >
                          <TimePicker
                            name={"breakEndTime"}
                            value={
                              scheduleDay[index].breakEndTime
                                ? moment(
                                    `${scheduleDay[index].breakEndTime}`,
                                    "HH:mm:00"
                                  ).local()
                                : null
                            }
                            onChange={(e) => {
                              console.log("from end time of schedule week", e);
                              handleScheduleBreakEndTimeChange(
                                moment(e).format("HH:mm:ss"),
                                index
                              );
                            }}
                            // value={moment(scheduleDay[index].end).format(
                            //   "HH:mm:ss"
                            // )}
                          />
                        </Form.Item>
                      </div>
                      {scheduleDay?.length > 1 && (
                        <Button
                          color="red"
                          onClick={() => removeScheduledDayInput(index)}
                          style={{
                            padding: "0",
                            borderRadius: "50%",
                            height: "1.5rem",
                            width: "1.5rem",
                          }}
                        >
                          <CloseOutlined />
                        </Button>
                      )}
                    </div>
                    {scheduleDay?.length - 1 === index && (
                      <Button
                        block
                        type="dashed"
                        onClick={addScheduledDayInput}
                      >
                        <PlusOutlined />
                        Add Day
                      </Button>
                    )}
                  </div>
                );
              })}
            </div>
          </>
        )}
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Update Employee
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default UpdateEmployee;
