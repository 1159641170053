import { Button, Form, Input, Modal } from "antd";
import React from "react";
import { useDispatch } from "react-redux";

const Purpose = ({ isAddPurposeModalVisible, setIsAddPurposeModalVisible }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const addPurposeSubmitHandler = (value) => {
    dispatch({ type: "ADD_PURPOSE_REQUEST", payload: value });
    form.resetFields();
    setIsAddPurposeModalVisible(false);
    console.log(value, "from purpose");
  };
  return (
    <Modal
      title="Add Purpose"
      open={isAddPurposeModalVisible}
      onCancel={() => {
        setIsAddPurposeModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addPurposeSubmitHandler}
        colon={true}
        form={form}
      >
        <Form.Item
          label="Purpose"
          name={"name"}
          rules={[
            {
              required: true,
              message: "Please enter purpose!",
            },
          ]}
        >
          <Input placeholder="Purpose" />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Purpose
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Purpose;
