import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import {
  MdOutlineAssignmentTurnedIn,
  MdOutlineDisabledVisible,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import department1 from "./../../../Assets/department2.png";
import activeDepartment from "./../../../Assets/department.png";

import AddDepartment from "./AddDepartment";
import SiderDemo from "../../../Components/Layout/SiderDemo";
import { formattedDateTime } from "../../../HelperFunction";
import UpdateDepartment from "./UpdateDepartment";
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlinePoweroff,
} from "react-icons/ai";
import { BsCircle } from "react-icons/bs";
import Confirmation from "./Confirmation";
import AssignRoles from "./AssignRoles";
import { BiBlock } from "react-icons/bi";
import TableSkeleton from "../../../Helpers/TableSkeleton/tableSkelaton";
const columns = [
  {
    title: "Department Name",
    dataIndex: "departmentName",
    sorter: (a, b) => {
      if (a.departmentName < b.departmentName) {
        return -1;
      }
      if (a.departmentName > b.departmentName) {
        return 1;
      }
      return 0;
    },
    align: "center",
  },

  {
    title: "Created On",
    dataIndex: "createdOn",
    align: "center",
  },

  {
    title: "Roles",
    dataIndex: "roles",
    align: "center",
  },
  {
    title: "Permissions",
    dataIndex: "permissions",
  },
  {
    title: "Status",
    dataIndex: "isActive",
    align: "center",
  },

  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
  },
];

const Departments = () => {
  const departmentState = useSelector((state) => state.DepartmentReducer);
  const [isAddDepartmentModalVisible, setIsAddDepartmentModalVisible] =
    useState(false);
  const [isUpdateDepartmentModalVisible, setIsUpdateDepartmentModalVisible] =
    useState(false);
  const [record, setRecord] = useState({});
  const [isconfirmationModalVisible, setIsconfirmationModalVisible] =
    useState(false);
  const [departmentType, setDepartmentType] = useState("list-all");
  const [isAssignRolesModalVisible, setIsAssignRolesModalVisible] =
    useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (departmentType === "list-active") {
      dispatch({ type: "FETCH_ACTIVE_DEPARTMENT_REQUEST" });
    } else {
      dispatch({ type: "FETCH_DEPARTMENT_REQUEST" });
    }
  }, [departmentType]);
  useEffect(() => {
    if (
      isAddDepartmentModalVisible === false ||
      isUpdateDepartmentModalVisible === false
    ) {
      dispatch({ type: "FETCH_DEPARTMENT_REQUEST" });
    }
  }, [isAddDepartmentModalVisible, isUpdateDepartmentModalVisible]);
  let data = [];
  if (departmentType === "list-all") {
    data = departmentState.departments?.data?.map((dataObj) => {
      return {
        key: dataObj.departmentResponse?.id,
        departmentName: dataObj.departmentResponse?.departmentName,
        modifierDetails: dataObj.departmentResponse?.modifierDetails?.userName,
        creatorDetails: dataObj.departmentResponse?.creatorDetails?.userName,
        deactivationDateTime: formattedDateTime(
          dataObj.departmentResponse?.deactivationDateTime
        ),
        activationDateTime: formattedDateTime(
          dataObj.departmentResponse?.activationDateTime
        ),
        modifiedOn: formattedDateTime(dataObj.departmentResponse?.modifiedOn),
        createdOn: formattedDateTime(dataObj.departmentResponse?.createdOn),
        permissions: dataObj.associatedPermissionList?.map((dataobj, index) => {
          return (
            <Tag key={index} style={{ margin: "2px" }} color={"blue"}>
              {dataobj}
            </Tag>
          );
        }),
        roles: dataObj.roleList?.map((dataobj) => {
          return (
            <Tag
              key={dataobj.id}
              className="rolename"
              style={{ margin: "2px" }}
              color={"green"}
            >
              {dataobj.roleName}
            </Tag>
          );
        }),
        isActive: dataObj.departmentResponse.isActive ? (
          <p className="greenTag">ACTIVE</p>
        ) : (
          <p className="redTag">INACTIVE</p>
        ),

        actions: (
          <Space size="middle">
            <Tooltip title="Update department">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setRecord(dataObj.departmentResponse);
                    setIsUpdateDepartmentModalVisible(true);
                  }}
                >
                  <EditOutlined />
                </div>
              </a>
            </Tooltip>
            {/* <Tooltip title="Delete department">
                <Popconfirm
                  title="Are you sure to delete this department?"
                  onConfirm={() => {
                    dispatch({
                      type: "DELETE_DEPARTMENT_REQUEST",
                      payload: { id: dataObj.id },
                    });
                  }}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <a>
                    <div className="bordered">
                      <DeleteOutlined />
                    </div>
                  </a>{" "}
                </Popconfirm>
              </Tooltip> */}
            <Tooltip title="Assign Roles">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setRecord(dataObj.departmentResponse);
                    setIsAssignRolesModalVisible(true);
                  }}
                >
                  <MdOutlineAssignmentTurnedIn />
                </div>
              </a>
            </Tooltip>
            {dataObj.departmentResponse?.isActive ? (
              <Tooltip title="Deactivate Department">
                <a style={{ color: "red" }}>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj.departmentResponse);
                      setIsconfirmationModalVisible(true);
                    }}
                  >
                    <BiBlock />
                  </div>
                </a>
              </Tooltip>
            ) : (
              <Tooltip title="Activate Department">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj.departmentResponse);
                      setIsconfirmationModalVisible(true);
                    }}
                  >
                    <AiOutlinePoweroff />
                  </div>
                </a>
              </Tooltip>
            )}
          </Space>
        ),
      };
    });
  } else {
    data = departmentState.activeDepartments?.data?.map((dataObj) => {
      return {
        key: dataObj?.departmentResponse?.id,
        departmentName: dataObj?.departmentResponse?.departmentName,
        modifierDetails: dataObj?.departmentResponse?.modifierDetails?.userName,
        creatorDetails: dataObj?.departmentResponse?.creatorDetails?.userName,
        deactivationDateTime: formattedDateTime(
          dataObj?.departmentResponse?.deactivationDateTime
        ),
        activationDateTime: formattedDateTime(
          dataObj?.departmentResponse?.activationDateTime
        ),
        modifiedOn: formattedDateTime(dataObj?.departmentResponse?.modifiedOn),
        createdOn: formattedDateTime(dataObj?.departmentResponse?.createdOn),
        permissions: dataObj.associatedPermissionList?.map((dataobj, index) => {
          return (
            <Tag key={index} style={{ margin: "2px" }} color={"blue"}>
              {dataobj}
            </Tag>
          );
        }),
        roles: dataObj.roleList?.map((dataobj) => {
          return (
            <Tag
              key={dataobj.id}
              className="rolename"
              style={{ margin: "2px" }}
              color={"green"}
            >
              {dataobj.roleName}
            </Tag>
          );
        }),
        isActive: dataObj?.departmentResponse?.isActive ? (
          <p className="greenTag">ACTIVE</p>
        ) : (
          <p className="redTag">INACTIVE</p>
        ),
        actions: (
          <Space size="middle">
            <Tooltip title="Update department">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsUpdateDepartmentModalVisible(true);
                    setRecord(dataObj?.departmentResponse);
                  }}
                >
                  <EditOutlined />
                </div>
              </a>
            </Tooltip>
            {/* <Tooltip title="Delete department">
                <Popconfirm
                  title="Are you sure to delete this department?"
                  onConfirm={() => {
                    dispatch({
                      type: "DELETE_DEPARTMENT_REQUEST",
                      payload: { id: dataObj.id },
                    });
                  }}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <a>
                    <div className="bordered">
                      <DeleteOutlined />
                    </div>
                  </a>{" "}
                </Popconfirm>
              </Tooltip> */}
            {dataObj?.departmentResponse?.isActive ? (
              <Tooltip title="Deactivate Department">
                <a style={{ color: "red" }}>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj?.departmentResponse);
                      setIsconfirmationModalVisible(true);
                    }}
                  >
                    <BiBlock />
                  </div>
                </a>
              </Tooltip>
            ) : (
              <Tooltip title="Activate Department">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj?.departmentResponse);
                      setIsconfirmationModalVisible(true);
                    }}
                  >
                    <AiOutlinePoweroff />
                  </div>
                </a>
              </Tooltip>
            )}
          </Space>
        ),
      };
    });
  }

  console.log("from department state", departmentState);
  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div className="flexRow">
          <button
            className="button"
            onClick={() => {
              setIsAddDepartmentModalVisible(true);
            }}
          >
            <span>Add Department</span>
          </button>
          <Segmented
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <Avatar src={department1} size={20} shape="square" />
                    <div>All Departments</div>
                  </div>
                ),
                value: "list-all",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <Avatar src={activeDepartment} size={20} shape="square" />
                    <div>Active Departments</div>
                  </div>
                ),
                value: "list-active",
              },
            ]}
            onChange={(value) => {
              setDepartmentType(value);
              console.log(value);
            }}
          />
        </div>
        <div style={{ marginTop: "4rem" }}>
          {departmentState.isLoading ? (
            <TableSkeleton />
          ) : (
            <Table bordered columns={columns} dataSource={data} />
          )}
        </div>
      </div>
      {isAssignRolesModalVisible && (
        <AssignRoles
          record={record}
          isAssignRolesModalVisible={isAssignRolesModalVisible}
          setIsAssignRolesModalVisible={setIsAssignRolesModalVisible}
        />
      )}
      <Confirmation
        onSubmit={(value) => {
          console.warn(value, "value from teh confirmation box");
          setIsconfirmationModalVisible(false);
          if (record.isActive) {
            dispatch({
              type: "DEACTIVATE_DEPARTMENT_REQUEST",
              payload: { id: record.id, values: value },
            });

            return;
          }
          dispatch({
            type: "ACTIVATE_DEPARTMENT_REQUEST",
            payload: { id: record.id, values: value },
          });
        }}
        isconfirmationModalVisible={isconfirmationModalVisible}
        setIsconfirmationModalVisible={setIsconfirmationModalVisible}
      />
      {isAddDepartmentModalVisible && (
        <AddDepartment
          isAddDepartmentModalVisible={isAddDepartmentModalVisible}
          setIsAddDepartmentModalVisible={setIsAddDepartmentModalVisible}
        />
      )}
      {isUpdateDepartmentModalVisible && (
        <UpdateDepartment
          record={record}
          isUpdateDepartmentModalVisible={isUpdateDepartmentModalVisible}
          setIsUpdateDepartmentModalVisible={setIsUpdateDepartmentModalVisible}
        />
      )}
    </SiderDemo>
  );
};

export default Departments;
