import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { MdOutlineDisabledVisible } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../Components/Layout/SiderDemo";
import AddRoles from "./AddRoles";
import AddPermission from "./AddPermission";
import UpdateRole from "./UpdateRole";
import { AiOutlinePlus } from "react-icons/ai";
const columns = [
  {
    title: "Roles",
    dataIndex: "roles",
    align: "center",
    width: "100",
  },
  // {
  //   title: "Actions",
  //   dataIndex: "roleActions",
  //   align: "center",
  //   width: "200",
  // },
  {
    title: "Permissions",
    dataIndex: "permissions",
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "permissionActions",
    align: "center",
    width: "200",
  },
];

const RolesAndPermission = () => {
  const rolesAndPermissionState = useSelector(
    (state) => state.RoleAndPermissionReducer
  );
  const [isAddRoleModalVisible, setIsAddRoleModalVisible] = useState(false);
  const [isAddPermissionModalVisible, setIsAddPermissionModalVisible] =
    useState(false);
  const [isUpdateRolesModalVisible, setIsUpdateRolesModalVisible] =
    useState(false);
  const [isUpdatePermissionModalVisible, setIsUpdatePermissionModalVisible] =
    useState(false);
  const [record, setRecord] = useState({});
  const [roleType, setRolesAndPermissionType] = useState("roles");

  const dispatch = useDispatch();
  useEffect(() => {
    if (
      isAddPermissionModalVisible === false ||
      isAddRoleModalVisible === false
    ) {
      dispatch({ type: "FETCH_ACTIVE_ROLES_REQUEST" });
    }
  }, [isAddPermissionModalVisible, isAddRoleModalVisible]);

  const data = rolesAndPermissionState.activeRoles?.data?.map((dataObj) => {
    return {
      key: dataObj.id,
      roles: dataObj.roleName,
      // roleActions: (
      //   <Space size="middle">
      //     <Tooltip title="Update role">
      //       <a>
      //         <div
      //           className="bordered"
      //           onClick={() => {
      //             setIsUpdateRolesModalVisible(true);
      //             setRecord(dataObj);
      //           }}
      //         >
      //           <EditOutlined />
      //         </div>
      //       </a>
      //     </Tooltip>
      //     <Tooltip title="Delete role">
      //       <Popconfirm
      //         title="Are you sure to delete this role?"
      //         onConfirm={() => {
      //           dispatch({
      //             type: "DELETE_ROLE_REQUEST",
      //             payload: { id: dataObj.id },
      //           });
      //         }}
      //         onCancel={() => {}}
      //         okText="Yes"
      //         cancelText="No"
      //       >
      //         <a>
      //           <div className="bordered">
      //             <DeleteOutlined />
      //           </div>
      //         </a>{" "}
      //       </Popconfirm>
      //     </Tooltip>
      //     <Tooltip title="Toggle role status">
      //       <a>
      //         <div
      //           className="bordered"
      //           onClick={() => {
      //             const actionStatus =
      //               dataObj.status === "INACTIVE" ? "active" : "inactive";
      //             dispatch({
      //               type: "TOGGLE__STATUS_REQUEST",
      //               payload: { id: dataObj.id, actionStatus },
      //             });
      //           }}
      //         >
      //           <MdOutlineDisabledVisible />
      //         </div>
      //       </a>
      //     </Tooltip>
      //   </Space>
      // ),
      permissions: dataObj.associatedPermissions?.map((permission, index) => {
        return (
          <Tag
            key={index}
            // style={{
            //   display: "flex",
            //   border: "1px solid #ddff00",
            //   backgroundColor: "#00ffdd33",
            //   padding: "0.3rem 0 0.3rem 0.3rem",
            // }}
            color="blue"
          >
            {permission}
          </Tag>
        );
      }),
      permissionActions: (
        <Space size="middle">
          <Tooltip title="Map Permissions to Role">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  setIsAddPermissionModalVisible(true);
                }}
              >
                <AiOutlinePlus />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Update Role">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateRolesModalVisible(true);
                  setRecord(dataObj);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip>
          {/* <Tooltip title="Delete role">
            <Popconfirm
              title="Are you sure to delete this role?"
              onConfirm={() => {
                dispatch({
                  type: "DELETE__REQUEST",
                  payload: { id: dataObj.id },
                });
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a>
                <div className="bordered">
                  <DeleteOutlined />
                </div>
              </a>{" "}
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Toggle role status">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  const actionStatus =
                    dataObj.status === "INACTIVE" ? "active" : "inactive";
                  dispatch({
                    type: "TOGGLE__STATUS_REQUEST",
                    payload: { id: dataObj.id, actionStatus },
                  });
                }}
              >
                <MdOutlineDisabledVisible />
              </div>
            </a>
          </Tooltip> */}
        </Space>
      ),
    };
  });
  console.log("from roles and permission state", rolesAndPermissionState);
  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div className="flexRow">
          <button
            className="button"
            onClick={() => {
              setIsAddRoleModalVisible(true);
            }}
          >
            <span>Add Roles</span>
          </button>
        </div>
        <div style={{ marginTop: "2rem" }}>
          <Table bordered columns={columns} dataSource={data} />
        </div>
      </div>
      <AddRoles
        isAddRoleModalVisible={isAddRoleModalVisible}
        setIsAddRoleModalVisible={setIsAddRoleModalVisible}
      />
      <UpdateRole
        record={record}
        isUpdateRoleModalVisible={isUpdateRolesModalVisible}
        setIsUpdateRoleModalVisible={setIsUpdateRolesModalVisible}
      />
      <AddPermission
        record={record}
        isAddPermissionModalVisible={isAddPermissionModalVisible}
        setIsAddPermissionModalVisible={setIsAddPermissionModalVisible}
      />
    </SiderDemo>
  );
};

export default RolesAndPermission;

// import React, { useEffect, useState } from "react";
// import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
// import {
//   Avatar,
//   Card,
//   Form,
//   Popconfirm,
//   Segmented,
//   Select,
//   Space,
//   Table,
//   Tag,
//   Tooltip,
// } from "antd";
// import { MdOutlineDisabledVisible } from "react-icons/md";
// import { useDispatch, useSelector } from "react-redux";
// import SiderDemo from "../../Components/Layout/SiderDemo";
// import AddRoles from "./AddRoles";
// import AddPermission from "./AddPermission";
// const { Option } = Select;

// const RolesAndPermission = () => {
//   const rolesAndPermissionState = useSelector(
//     (state) => state.RoleAndPermissionReducer
//   );
//   const [isAddRoleModalVisible, setIsAddRoleModalVisible] = useState(false);
//   const [isAddPermissionModalVisible, setIsAddPermissionModalVisible] =
//     useState(false);
//   const [role, setRole] = useState(
//     rolesAndPermissionState.roles?.data?.[0]?.roleName
//   );
//   const [permission, setPermission] = useState(
//     rolesAndPermissionState.roles?.data?.[0]?.associatedPermissions
//   );
//   const [record, setRecord] = useState({});

//   const dispatch = useDispatch();
//   const myFunction = () => {
//     const findRole = rolesAndPermissionState.roles?.data?.filter((dataObj) => {
//       console.log("from findrole inside find", dataObj, "and", role);

//       return dataObj.id === role.id;
//     });
//     console.log("from findrole", findRole);
//     setRole(rolesAndPermissionState.roles?.data?.[0].roleName);
//     setPermission(
//       rolesAndPermissionState.roles?.data?.[0].associatedPermissions
//     );
//   };
//   useEffect(() => {
//     dispatch({ type: "FETCH_ACTIVE_ROLES_REQUEST" });
//   }, [isAddPermissionModalVisible]);
//   const parmissionAdded = () => {
//     const findRole = rolesAndPermissionState.roles?.data?.find((dataObj) => {
//       return dataObj.id === role.id;
//     });
//     // setRole(findRole);
//   };
//   console.log("from rolesAndPermission state", rolesAndPermissionState);
//   console.log("From role permsion role adsfkj", role);
//   return (
//     <SiderDemo>
//       <div className="container" style={{ border: "2px solid red" }}>
//         <Card style={{ width: "100%" }}>
//           <div className="backgroundCard">
//             <div
//               style={{
//                 backgroundColor: "white",
//                 color: "#777",
//                 padding: "0.5rem",
//                 fontSize: "18px",
//                 fontWeight: "400",
//               }}
//             >
//               Manage Roles and Permission
//             </div>
//             <div
//               className="flexRowWithoutStyle"
//               style={{ justifyContent: "space-between" }}
//             >
//               <span style={{ fontSize: 16 }}>Roles</span>
//               <Tooltip title="Add Role">
//                 <PlusOutlined
//                   style={{ fontSize: "20px", color: "blue" }}
//                   onClick={() => {
//                     setIsAddRoleModalVisible(true);
//                   }}
//                 />
//               </Tooltip>
//             </div>
//             <Select
//               onChange={(e) => {
//                 console.log("From onchange role", e);
//               }}
//               defaultValue={1}
//             >
//               {rolesAndPermissionState.activeRoles?.data?.map((dataObj) => {
//                 return (
//                   <Option key={dataObj.id}>
//                     <div
//                       onClick={() => {
//                         setRecord(dataObj);
//                         setRole(dataObj.roleName);
//                         setPermission(dataObj.associatedPermissions);
//                       }}
//                     >
//                       {dataObj.roleName}
//                     </div>
//                   </Option>
//                 );
//               })}
//             </Select>
//             <div
//               className="flexRowWithoutStyle"
//               style={{ justifyContent: "space-between" }}
//             >
//               <span style={{ fontSize: 16 }}>Permission</span>
//               <Tooltip title="Add Permission">
//                 <PlusOutlined
//                   className="iconColor"
//                   style={{ fontSize: "20px", color: "blue" }}
//                   onClick={() => {
//                     setIsAddPermissionModalVisible(true);
//                   }}
//                 />
//               </Tooltip>
//             </div>
//             <div
//               className="backgroundCard"
//               style={{ backgroundColor: "white" }}
//             >
//               {permission?.map((dataObj, index) => {
//                 return (
//                   <div
//                     key={index}
//                     style={{
//                       border: "1px solid #ddff00",
//                       backgroundColor: "#00ffdd33",
//                       padding: "0.3rem 0 0.3rem 0.3rem",
//                     }}
//                   >
//                     {dataObj}
//                   </div>
//                 );
//               })}
//             </div>
//           </div>
//         </Card>
//       </div>

//       {/* <AddRolesAndPermission
//         isAddRolesAndPermissionModalVisible={
//           isAddRolesAndPermissionModalVisible
//         }
//         setIsAddRolesAndPermissionModalVisible={
//           setIsAddRolesAndPermissionModalVisible
//         }
//       />
//       <UpdateRolesAndPermission
//         record={record}
//         isUpdateRolesAndPermissionModalVisible={
//           isUpdateRolesAndPermissionModalVisible
//         }
//         setIsUpdateRolesAndPermissionModalVisible={
//           setIsUpdateRolesAndPermissionModalVisible
//         }
//       /> */}
//       <AddRoles
//         isAddRoleModalVisible={isAddRoleModalVisible}
//         setIsAddRoleModalVisible={setIsAddRoleModalVisible}
//       />
//       <AddPermission
//         record={record}
//         role={role}
//         setRole={setRole}
//         permission={permission}
//         setPermission={setPermission}
//         parmissionAdded={parmissionAdded}
//         isAddPermissionModalVisible={isAddPermissionModalVisible}
//         setIsAddPermissionModalVisible={setIsAddPermissionModalVisible}
//       />
//     </SiderDemo>
//   );
// };

// export default RolesAndPermission;
