import {
  Card,
  Divider,
  Drawer,
  Image,
  Modal,
  Input,
  Tooltip,
  Checkbox,
  Button,
  Tag,
  Form,
  Select,
} from "antd";
import React from "react";
import { useState } from "react";
import { formattedDate, formattedDateTime } from "../../HelperFunction";

const CaseDetails = ({ visaRecord }) => {
  const [current, setCurrent] = useState("educationMigration");
  const CompanyCasePackage = visaRecord?.casePackages?.filter((dataObj) => {
    if (
      dataObj?.packageType === "COMPANY" ||
      dataObj?.packageType === "INSTITUTE"
    ) {
      return dataObj;
    } else {
      return;
    }
  });
  const inhouseCasePackage = visaRecord?.casePackages?.filter((dataObj) => {
    if (dataObj?.packageType === "IN_HOUSE") {
      return dataObj;
    } else {
      return;
    }
  });

  const caseDetailsTabList = [
    {
      key: "educationMigration",
      tab: "Education/Migration",
    },
    {
      key: "inhousePackage",
      tab: "Inhouse package",
    },
    {
      key: "thirdPartyPackage",
      tab: "Third Party Package",
    },
  ];
  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };
  console.log("inhouse package", inhouseCasePackage);
  console.log("CompanyCasePackage package", CompanyCasePackage);
  return (
    <Card
      className="caseCard"
      style={{ margin: 0, padding: 0 }}
      bordered={false}
      size={"small"}
      tabList={caseDetailsTabList}
      activeTabKey={current}
      onTabChange={(key) => {
        setCurrent(key);
      }}
    >
      {current === "educationMigration" && (
        <div className="flexRowWithFlexStart">
          <Card className="clientCard" style={{ width: "100%" }}>
            <div className="flexColumnWithoutStyle" style={{ width: "100%" }}>
              <div className="flexRowSpaceBetween">
                Visa Type:<span>{visaRecord?.visaCategory}</span>
              </div>
              <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
              <div className="flexRowSpaceBetween">
                Visa Subclass:
                <span>{visaRecord?.visaSubClass?.subClass}</span>
              </div>

              <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
              {visaRecord?.visaCategory === "EDUCATION" ? (
                <>
                  <div className="flexRowSpaceBetween">
                    Institute:
                    <span>{visaRecord?.institute?.instituteName ?? "N/A"}</span>
                  </div>
                  <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
                  <div className="flexRowSpaceBetween">
                    Course:
                    <span>{visaRecord?.courses?.name ?? "N/A"}</span>
                  </div>
                  <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
                  <div className="flexRowSpaceBetween">
                    Degree:
                    <span>{visaRecord?.courses?.degree?.name ?? "N/A"}</span>
                  </div>
                  <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
                  <div className="flexRowSpaceBetween">
                    College Intake:
                    <span>
                      {visaRecord?.collegeIntake?.intakeSessionName ?? "N/A"}
                    </span>
                  </div>
                  {/* <Divider orientationMargin="0" style={{ margin: "0.5rem" }} /> */}
                  {/* <div className="flexRowSpaceBetween">
                        Discount:
                        <span>{visaRecord?.discount ?? "N/A"}</span>
                      </div> */}
                  <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

                  <div className="flexRowSpaceBetween">
                    Want to pay for:
                    <span>{visaRecord?.wantToPayFor ?? "N/A"}</span>
                  </div>
                  {/* <Divider orientationMargin="0" style={{ margin: "0.5rem" }} /> */}
                </>
              ) : null}

              <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
              <div className="flexRowSpaceBetween">
                Deadline:
                <span>
                  {visaRecord?.deadlineDate
                    ? formattedDate(visaRecord?.deadlineDate)
                    : "N/A"}
                </span>
              </div>
              <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

              <div className="flexRowSpaceBetween">
                Case Status:
                {visaRecord?.workFlowStatusDto?.statusName ? (
                  <Tag
                    color={
                      visaRecord?.workFlowStatusDto?.colorCode
                        ? visaRecord?.workFlowStatusDto?.colorCode
                        : "green"
                    }
                  >
                    {visaRecord?.workFlowStatusDto?.statusName}
                  </Tag>
                ) : (
                  "N/A"
                )}
              </div>
              <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

              <div className="flexRowSpaceBetween">
                Visa Outcome:
                {visaRecord?.visaOutcome ? (
                  <Tag
                    color={
                      visaRecord?.visaOutcomeColorCode
                        ? visaRecord?.visaOutcomeColorCode
                        : "white"
                    }
                  >
                    {visaRecord?.visaOutcome}
                  </Tag>
                ) : (
                  "N/A"
                )}
              </div>
              <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
              <div className="flexRowSpaceBetween">
                Description:
                <span>{visaRecord?.description ?? "N/A"}</span>
              </div>
              <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
              <div className="flexRowSpaceBetween">
                Prior note:
                <span>{visaRecord?.priorNote ?? "N/A"}</span>
              </div>
              <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

              <div className="flexRowSpaceBetween">
                Added Date:
                <span>{formattedDateTime(visaRecord?.addedOn) ?? "N/A"}</span>
              </div>
              <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

              <div className="flexRowSpaceBetween">
                Added By:<span>{visaRecord?.addedBy ?? "N/A"}</span>
              </div>
            </div>
          </Card>
          <Card className="clientCard" style={{ width: "100%" }}>
            <div className="flexRowSpaceBetween">
              consultants:
              <span>
                {visaRecord?.consultant?.map((dataObj) => {
                  return (
                    <Tooltip
                      showArrow={false}
                      title={
                        <>
                          {dataObj.emailId && (
                            <div>
                              <span>Email:</span>
                              <span>{dataObj.emailId}</span>
                            </div>
                          )}
                          {dataObj.phoneNumber && (
                            <div>
                              <span>Phone No.:</span>
                              <span>{dataObj.phoneNumber}</span>
                            </div>
                          )}
                        </>
                      }
                    >
                      <Tag key={dataObj.id} color="blue">
                        {dataObj.firstName + " " + dataObj.lastName}
                      </Tag>
                    </Tooltip>
                  );
                })}
              </span>
            </div>
            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
            <div className="flexRowSpaceBetween">
              Case Packages:
              <span>
                {visaRecord?.casePackages?.map((dataObj) => {
                  return (
                    <Tooltip
                      showArrow={false}
                      title={
                        <>
                          {dataObj.unitPrice && (
                            <div>
                              <span>Unit Price.:</span>
                              <span>{dataObj.unitPrice}</span>
                            </div>
                          )}
                        </>
                      }
                    >
                      <Tag key={dataObj.id} color="blue">
                        {dataObj.packageName}
                      </Tag>
                    </Tooltip>
                  );
                })}
              </span>
            </div>
            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

            <div className="flexRowSpaceBetween">
              Super Agent:
              <span>
                {visaRecord?.superAgent?.map((dataObj) => {
                  return (
                    <Tooltip
                      showArrow={false}
                      title={
                        <>
                          {dataObj.email && (
                            <div>
                              <span>Email:</span>
                              <span>{dataObj.email}</span>
                            </div>
                          )}
                          {dataObj.phoneNumber && (
                            <div>
                              <span>Phone No.:</span>
                              <span>{dataObj.phoneNumber}</span>
                            </div>
                          )}
                          {dataObj.mobileNumber && (
                            <div>
                              <span>Mobile No.:</span>
                              <span>{dataObj.mobileNumber}</span>
                            </div>
                          )}
                        </>
                      }
                    >
                      <Tag
                        key={dataObj.id}
                        color="blue"
                        style={{ margin: "3px" }}
                      >
                        {dataObj.name}
                      </Tag>
                    </Tooltip>
                  );
                })}
              </span>
            </div>
            {visaRecord?.visaCategory === "EDUCATION" &&
            visaRecord?.isOffShore === false ? (
              <>
                <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

                <div className="flexRowSpaceBetween">
                  Onshore Fee:
                  <span>{visaRecord?.courses?.onShoreFee ?? "N/A"}</span>
                </div>
                <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
                <div className="flexRowSpaceBetween">
                  Onshore Application Fee:
                  <span>
                    {visaRecord.courses?.onshoreApplicationFee ?? "N/A"}
                  </span>
                </div>
                <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
                <div className="flexRowSpaceBetween">
                  Onshore Material Fee:
                  <span>
                    {visaRecord?.courses?.onshoreMaterialFee ?? "N/A"}
                  </span>
                </div>
                <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
                <div className="flexRowSpaceBetween">
                  Onshore Additional Fee:
                  <span>
                    {visaRecord?.courses?.onshoreAdditionalFee ?? "N/A"}
                  </span>
                </div>

                {visaRecord.courses?.onshoreIsDiscount === true && (
                  <>
                    {visaRecord.courses?.onshoreDiscountAmount !== "" &&
                      visaRecord.courses?.onshoreDiscountAmount !== null &&
                      visaRecord.courses?.onshoreDiscountAmount !== undefined &&
                      visaRecord.courses?.onshoreDiscountType !== "" &&
                      visaRecord.courses?.onshoreDiscountType !== null &&
                      visaRecord.courses?.onshoreDiscountType !== undefined && (
                        <>
                          <Divider
                            orientationMargin="0"
                            style={{ margin: "0.5rem" }}
                          />
                          <div className="flexRowSpaceBetween">
                            Onshore Discount:
                            <span>{`${
                              visaRecord.courses?.onshoreDiscountAmount
                            } ${
                              visaRecord.courses?.onshoreDiscountType === "FLAT"
                                ? "FLAT"
                                : visaRecord.courses?.onshoreDiscountType ===
                                  "PERCENTAGE"
                                ? "%"
                                : null
                            }`}</span>
                          </div>
                        </>
                      )}
                  </>
                )}

                {visaRecord.courses?.onshoreIsScholarship === true && (
                  <>
                    <Divider
                      orientationMargin="0"
                      style={{ margin: "0.5rem" }}
                    />
                    {visaRecord.courses?.onshoreScholarshipAmount !== "" &&
                      visaRecord.courses?.onshoreScholarshipAmount !== null &&
                      visaRecord.courses?.onshoreScholarshipAmount !==
                        undefined &&
                      visaRecord.courses?.onshoreScholarshipType !== "" &&
                      visaRecord.courses?.onshoreScholarshipType !== null &&
                      visaRecord.courses?.onshoreScholarshipType !==
                        undefined && (
                        <div className="flexRowSpaceBetween">
                          Onshore Scholarship:
                          <span>{`${
                            visaRecord.courses?.onshoreScholarshipAmount
                          } ${
                            visaRecord.courses?.onshoreScholarshipType ===
                            "FLAT"
                              ? "FLAT"
                              : visaRecord.courses?.onshoreScholarshipType ===
                                "PERCENTAGE"
                              ? "%"
                              : null
                          }`}</span>
                        </div>
                      )}
                  </>
                )}
              </>
            ) : null}
            {visaRecord?.visaCategory === "EDUCATION" &&
            visaRecord?.isOffShore === true ? (
              <>
                <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
                <div className="flexRowSpaceBetween">
                  Offshore Fee:
                  <span>{visaRecord?.courses?.offShoreFee ?? "N/A"}</span>
                </div>
                <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
                <div className="flexRowSpaceBetween">
                  Offshore Application Fee:
                  <span>
                    {visaRecord.courses?.offshoreApplicationFee ?? "N/A"}
                  </span>
                </div>
                <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
                <div className="flexRowSpaceBetween">
                  Offshore Material Fee:
                  <span>
                    {visaRecord?.courses?.offshoreMaterialFee ?? "N/A"}
                  </span>
                </div>
                <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
                <div className="flexRowSpaceBetween">
                  Offshore Additional Fee:
                  <span>
                    {visaRecord?.courses?.offshoreAdditionalFee ?? "N/A"}
                  </span>
                </div>

                {visaRecord.courses?.offshoreIsDiscount === true && (
                  <>
                    {visaRecord.courses?.offshoreDiscountAmount !== "" &&
                      visaRecord.courses?.offshoreDiscountAmount !== null &&
                      visaRecord.courses?.offshoreDiscountAmount !==
                        undefined &&
                      visaRecord.courses?.offshoreDiscountType !== "" &&
                      visaRecord.courses?.offshoreDiscountType !== null &&
                      visaRecord.courses?.offshoreDiscountType !==
                        undefined && (
                        <>
                          <Divider
                            orientationMargin="0"
                            style={{ margin: "0.5rem" }}
                          />
                          <div className="flexRowSpaceBetween">
                            Onshore Discount:
                            <span>{`${
                              visaRecord.courses?.offshoreDiscountAmount
                            } ${
                              visaRecord.courses?.offshoreDiscountType ===
                              "FLAT"
                                ? "FLAT"
                                : visaRecord.courses?.offshoreDiscountType ===
                                  "PERCENTAGE"
                                ? "%"
                                : null
                            }`}</span>
                          </div>
                        </>
                      )}
                  </>
                )}

                {visaRecord.courses?.offshoreIsScholarship === true && (
                  <>
                    {visaRecord.courses?.offshoreScholarshipAmount !== "" &&
                      visaRecord.courses?.offshoreScholarshipAmount !== null &&
                      visaRecord.courses?.offshoreScholarshipAmount !==
                        undefined &&
                      visaRecord.courses?.offshoreScholarshipType !== "" &&
                      visaRecord.courses?.offshoreScholarshipType !== null &&
                      visaRecord.courses?.offshoreScholarshipType !==
                        undefined && (
                        <>
                          <Divider
                            orientationMargin="0"
                            style={{ margin: "0.5rem" }}
                          />
                          <div className="flexRowSpaceBetween">
                            Onshore Scholarship:
                            <span>{`${
                              visaRecord.courses?.offshoreScholarshipAmount
                            } ${
                              visaRecord.courses?.offshoreScholarshipType ===
                              "FLAT"
                                ? "FLAT"
                                : visaRecord.courses
                                    ?.offshoreScholarshipType === "PERCENTAGE"
                                ? "%"
                                : null
                            }`}</span>
                          </div>
                        </>
                      )}
                  </>
                )}
              </>
            ) : null}
            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

            <div className="flexRowSpaceBetween">
              Grand Total:
              <span>{` ${visaRecord.totalAmount ?? 0} ${
                visaRecord?.currency ?? ""
              }`}</span>
            </div>
            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

            <div className="flexRowSpaceBetween">
              Current pay amount:
              <span>
                {visaRecord?.currentPayAmount ?? 0}
                {" " + visaRecord?.currency ?? ""}
              </span>
            </div>
          </Card>
        </div>
      )}

      {current === "inhousePackage" && (
        <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
          {inhouseCasePackage?.length > 0 ? (
            inhouseCasePackage?.map((dataObj) => {
              const quantity = visaRecord?.casePackagesDetailsDtoList?.find(
                (item) => {
                  if (dataObj?.id === item?.id) {
                    return item;
                  } else {
                    return null;
                  }
                }
              );
              return (
                <div
                  key={dataObj?.id}
                  style={{
                    backgroundColor: "#eeeeee55",
                    width: "48%",
                    borderRadius: "0.5rem",
                    padding: "0.5rem",
                    marginBottom: "0.5rem",
                  }}
                >
                  {dataObj?.company?.name !== undefined && (
                    <>
                      {detailFun(
                        "Company",
                        `${
                          dataObj?.company?.name
                            ? dataObj?.company?.name
                            : "N/A"
                        }`
                      )}
                    </>
                  )}
                  {dataObj?.institute?.name !== undefined && (
                    <>
                      {detailFun(
                        "Company",
                        `${
                          dataObj?.institute?.name
                            ? dataObj?.institute?.name
                            : "N/A"
                        }`
                      )}
                    </>
                  )}
                  {detailFun(
                    "Package Name",
                    `${dataObj?.packageName ? dataObj?.packageName : "N/A"}`
                  )}

                  {detailFun(
                    "Unit Price",
                    `${
                      dataObj?.unitPrice
                        ? `${dataObj?.unitPrice} ${
                            dataObj?.currency ? dataObj?.currency : ""
                          }`
                        : "N/A"
                    }`
                  )}
                  {dataObj?.gst !== "" &&
                    dataObj?.gst !== null &&
                    dataObj?.gst !== undefined && (
                      <>
                        {detailFun(
                          "GST/VAT",
                          `${
                            dataObj?.gst
                              ? `${dataObj?.gst} ${
                                  dataObj?.isGstPercentage === true
                                    ? `% `
                                    : dataObj?.isGstFlat
                                    ? `${
                                        dataObj?.currency
                                          ? dataObj?.currency
                                          : ""
                                      } FLAT`
                                    : ""
                                }`
                              : "N/A"
                          }`
                        )}
                      </>
                    )}
                  {dataObj?.commission !== "" &&
                    dataObj?.commission !== null &&
                    dataObj?.commission !== undefined && (
                      <>
                        {detailFun(
                          "Commission",
                          `${
                            dataObj?.commission
                              ? `${dataObj?.commission} ${
                                  dataObj?.isCommissionPercentage === true
                                    ? `% `
                                    : dataObj?.isCommissionFlat
                                    ? `${
                                        dataObj?.currency
                                          ? dataObj?.currency
                                          : ""
                                      } FLAT`
                                    : ""
                                }`
                              : "N/A"
                          }`
                        )}
                      </>
                    )}
                  {detailFun("Quantity", `${quantity?.quantity}`)}
                  {detailFun(
                    "Price",
                    `${quantity?.totalPrice} ${
                      dataObj?.currency ? dataObj?.currency : ""
                    }`
                  )}
                  {detailFun(
                    "Actual GST/VAT taken",
                    `${quantity?.actualGSTTaken ?? 0} ${
                      dataObj?.currency ? dataObj?.currency : ""
                    }`
                  )}
                </div>
              );
            })
          ) : (
            <div style={{ textAlign: "center" }}>
              No Inhouse Packages Available.
            </div>
          )}
        </div>
      )}
      {current === "thirdPartyPackage" && (
        <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
          {CompanyCasePackage?.length > 0 ? (
            CompanyCasePackage?.map((dataObj) => {
              const quantity = visaRecord?.casePackagesDetailsDtoList?.find(
                (item) => {
                  if (dataObj?.id === item?.id) {
                    return item;
                  } else {
                    return null;
                  }
                }
              );
              return (
                <div
                  key={dataObj?.id}
                  style={{
                    backgroundColor: "#eeeeee55",
                    width: "48%",
                    borderRadius: "0.5rem",
                    padding: "0.5rem",
                    marginBottom: "0.5rem",
                  }}
                >
                  {dataObj?.company?.name !== undefined && (
                    <>
                      {detailFun(
                        "Company",
                        `${
                          dataObj?.company?.name
                            ? dataObj?.company?.name
                            : "N/A"
                        }`
                      )}
                    </>
                  )}
                  {dataObj?.institute?.name !== undefined && (
                    <>
                      {detailFun(
                        "Company",
                        `${
                          dataObj?.institute?.name
                            ? dataObj?.institute?.name
                            : "N/A"
                        }`
                      )}
                    </>
                  )}
                  {detailFun(
                    "Package Name",
                    `${dataObj?.packageName ? dataObj?.packageName : "N/A"}`
                  )}

                  {detailFun(
                    "Unit Price",
                    `${
                      dataObj?.unitPrice
                        ? `${dataObj?.unitPrice} ${
                            dataObj?.currency ? dataObj?.currency : ""
                          }`
                        : "N/A"
                    }`
                  )}
                  {dataObj?.gst !== "" &&
                    dataObj?.gst !== null &&
                    dataObj?.gst !== undefined && (
                      <>
                        {detailFun(
                          "GST/VAT",
                          `${
                            dataObj?.gst
                              ? `${dataObj?.gst} ${
                                  dataObj?.isGstPercentage === true
                                    ? `% `
                                    : dataObj?.isGstFlat
                                    ? `${
                                        dataObj?.currency
                                          ? dataObj?.currency
                                          : ""
                                      } FLAT`
                                    : ""
                                }`
                              : "N/A"
                          }`
                        )}
                      </>
                    )}
                  {dataObj?.commission !== "" &&
                    dataObj?.commission !== null &&
                    dataObj?.commission !== undefined && (
                      <>
                        {detailFun(
                          "Commission",
                          `${
                            dataObj?.commission
                              ? `${dataObj?.commission} ${
                                  dataObj?.isCommissionPercentage === true
                                    ? `% `
                                    : dataObj?.isCommissionFlat
                                    ? `${
                                        dataObj?.currency
                                          ? dataObj?.currency
                                          : ""
                                      } FLAT`
                                    : ""
                                }`
                              : "N/A"
                          }`
                        )}
                      </>
                    )}
                  {detailFun("Quantity", `${quantity?.quantity}`)}
                  {detailFun(
                    "Price",
                    `${quantity?.totalPrice} ${
                      dataObj?.currency ? dataObj?.currency : ""
                    }`
                  )}
                  {detailFun(
                    "Actual GST/VAT taken",
                    `${quantity?.actualGSTTaken ?? 0 ?? 0} ${
                      dataObj?.currency ? dataObj?.currency : ""
                    }`
                  )}
                </div>
              );
            })
          ) : (
            <div style={{ textAlign: "center" }}>
              No Third Party Packages Available.
            </div>
          )}
        </div>
      )}
    </Card>
  );
};

export default CaseDetails;
