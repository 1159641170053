import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  leadEmailTemplates: {},
  caseEmailTemplates: {},
  appointmentEmailTemplates: {},
  reminderExpiryWorkingHour: {},
  clientEmailTemplates: {},
  autoreminderData: {},
  isImportedOnce: false,
  institutesFromAdmin: {},
  leadStatuses: {},
};

const OrganizationSettingsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_LEAD_EMAIL_TEMPLATE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_LEAD_EMAIL_TEMPLATE_REQUEST_SUCCESS": {
      return {
        ...state,
        leadEmailTemplates: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_LEAD_EMAIL_TEMPLATE_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to load template.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_CASE_EMAIL_TEMPLATE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_CASE_EMAIL_TEMPLATE_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        caseEmailTemplates: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_CASE_EMAIL_TEMPLATE_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to load template.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_APPOINTMENT_EMAIL_TEMPLATE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_APPOINTMENT_EMAIL_TEMPLATE_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        appointmentEmailTemplates: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_APPOINTMENT_EMAIL_TEMPLATE_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to load template.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_CLIENT_EMAIL_TEMPLATE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_CLIENT_EMAIL_TEMPLATE_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        clientEmailTemplates: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_CLIENT_EMAIL_TEMPLATE_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to load template.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_EMAIL_TEMPLATE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_EMAIL_TEMPLATE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Template update.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_EMAIL_TEMPLATE_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to update template.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_DASHBOARD_SETTINGS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_DASHBOARD_SETTINGS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Dashboard settings updated.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_DASHBOARD_SETTINGS_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to update dashboard settings.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "IMPORT_DROPDOWN_DATA_FROM_HEAD_OFFICE_REQUEST": {
      return {
        ...state,
        isImportedOnce: true,
        isLoading: true,
      };
    }

    case "IMPORT_DROPDOWN_DATA_FROM_HEAD_OFFICE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Data imported.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "IMPORT_DROPDOWN_DATA_FROM_HEAD_OFFICE_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to import data.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "IMPORT_INSTITUTE_DATA_FROM_ADMIN_REQUEST": {
      return {
        ...state,
        isImportedOnce: true,
        isLoading: true,
      };
    }

    case "IMPORT_INSTITUTE_DATA_FROM_ADMIN_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Institute data imported from admin.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "IMPORT_INSTITUTE_DATA_FROM_ADMIN_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to import institute data from admin.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_PREFIX_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_PREFIX_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Prefix updated.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_PREFIX_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to update prefix.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_REMINDER_EXIPRY_WORKING_HOUR_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_REMINDER_EXIPRY_WORKING_HOUR_REQUEST_SUCCESS": {
      return {
        ...state,
        reminderExpiryWorkingHour: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_REMINDER_EXIPRY_WORKING_HOUR_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load settings.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_EXPIRY_REMINDER_WORKING_HOUR_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_EXPIRY_REMINDER_WORKING_HOUR_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Reminder updated");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_EXPIRY_REMINDER_WORKING_HOUR_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to save changes.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "SET_DEFAULT_CURRENCY_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "SET_DEFAULT_CURRENCY_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Default currency updated.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "SET_DEFAULT_CURRENCY_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to update default currency.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_AUTOREMINDER_SETTINGS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_AUTOREMINDER_SETTINGS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Autoreminder settings updated.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_AUTOREMINDER_SETTINGS_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to update autoreminder settings.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_AUTOREMINDER_DATA_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_AUTOREMINDER_DATA_REQUEST_SUCCESS": {
      return {
        ...state,
        autoreminderData: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_AUTOREMINDER_DATA_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load autoreminder settings data.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "CHANGE_TIMEZONE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "CHANGE_TIMEZONE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Timezone changed.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "CHANGE_TIMEZONE_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to change timezone.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADDITIONAL_EMAILS_FROM_GENERAL_SETTINGS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADDITIONAL_EMAILS_FROM_GENERAL_SETTINGS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Additional email updated.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADDITIONAL_EMAILS_FROM_GENERAL_SETTINGS_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to update additional email.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "SEARCH_INSTITUTE_FROM_ADMIN_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "SEARCH_INSTITUTE_FROM_ADMIN_REQUEST_SUCCESS": {
      return {
        ...state,
        institutesFromAdmin: action.payload,
        isLoading: false,
      };
    }
    case "SEARCH_INSTITUTE_FROM_ADMIN_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load institutes from admin.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "IMPORT_INSTITUTES_FROM_ADMIN_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "IMPORT_INSTITUTES_FROM_ADMIN_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Institute imported.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "IMPORT_INSTITUTES_FROM_ADMIN_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to import institute.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_OPENING_SCHEDULE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_OPENING_SCHEDULE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Opening schedule updated.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_OPENING_SCHEDULE_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to update opening schedule.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_ALL_LEAD_STATUSES_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_ALL_LEAD_STATUSES_REQUEST_SUCCESS": {
      return {
        ...state,
        leadStatuses: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ALL_LEAD_STATUSES_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to .");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "SET_LEAD_STATUS_AS_DEFAULT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "SET_LEAD_STATUS_AS_DEFAULT_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Lead status set as default.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "SET_LEAD_STATUS_AS_DEFAULT_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to set lead status as default.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_COMPANY_DOMAIN_URL_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_COMPANY_DOMAIN_URL_REQUEST_SUCCESS": {
      action.payload.message && message.success(action.payload.message);

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_COMPANY_DOMAIN_URL_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to update domain.");

      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default OrganizationSettingsReducer;
