import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AiOutlinePlus } from "react-icons/ai";
import AddReferral from "../Leads/Referral/AddReferral";
import AddAboutUs from "../Leads/AboutUs/AddAboutUs";
import { date, date00, dateUTC, formattedDate } from "../../HelperFunction";
import { useEffect } from "react";
import AddCampaign from "../Contacts/AddCampaign";
const { Option } = Select;

const UpdateCustomerInfo = ({
  record,
  isUpdateCustomerInfoModalVisible,
  setIsUpdateCustomerInfoModalVisible,
  setIsClientDetailsModelVisible,
}) => {
  const leadState = useSelector((state) => state.LeadReducer);
  const [firstname, setFirstname] = useState(record?.lastName);
  const [lastname, setLastname] = useState(record?.email);
  const [email, setEmail] = useState(record?.firstName);
  const [mobile, setMobile] = useState(record?.mobile);
  const [dateOfBirth, setDateOfBirth] = useState(record?.dateOfBirth);
  const [passportNumber, setpassportNumber] = useState(record?.passportNumber);
  const [phone, setPhone] = useState(record?.phone);
  const [referral, setReferral] = useState(record?.referral);
  const [aboutUs, setAboutUs] = useState(record?.aboutUs);
  const [address, setAddress] = useState(record?.address);
  const [sexStatus, setSexStatus] = useState(record?.sexStatus);
  const [maritalStatus, setMaritalStatus] = useState(record?.maritalStatus);
  const [sendByEmail, setSendByEmail] = useState(record?.sendByEmail);
  const [updateCustomerInfoData, setUpdateCustomerInfoData] = useState({
    visaType: record?.visaType,
    visaStatus: record?.visaStatus,
    visaExpiryDate: record?.visaExpiryDate,
    passportExpiryDate: record?.passportExpiryDate,

    campaign: record?.campaign?.id,
    campaignName: record?.campaign?.name,
  });

  const [countryOfResidence, setCountryOfResidence] = useState(
    record?.countryOfResidence
  );
  useEffect(() => {
    if (isUpdateCustomerInfoModalVisible === true) {
      setFirstname(record?.firstName);
      setLastname(record?.lastName);
      setEmail(record?.email);
      setMobile(record?.mobile);
      setDateOfBirth(record?.dateOfBirth);
      setpassportNumber(record?.passportNumber);
      setPhone(record?.phone);
      setReferral(record?.referral);
      setAboutUs(record?.aboutUs);
      setAddress(record?.address);
      setSexStatus(record?.sexStatus);
      setMaritalStatus(record?.maritalStatus);
      setSendByEmail(record?.sendByEmail);
      setCountryOfResidence(record?.countryOfResidence);
    }
  }, [isUpdateCustomerInfoModalVisible]);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isAddAboutUsModalVisible, setIsAddAboutUsModalVisible] =
    useState(false);
  const [isAddReferralModalVisible, setIsAddReferralModalVisible] =
    useState(false);
  const [isAddCampaignModalVisible, setIsAddCampaignModalVisible] =
    useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState();

  const updateCustomerInfoSubmitHandler = (value) => {
    const dob = value.dateOfBirth ? dateUTC(value.dateOfBirth) : null;

    console.log("from ", value);
    const formData = {
      ...value,
      dob,
      visaType: updateCustomerInfoData.visaType,
      visaStatus: updateCustomerInfoData.visaStatus,
      visaExpiryDate: updateCustomerInfoData.visaExpiryDate
        ? date00(updateCustomerInfoData.visaExpiryDate)
        : null,
      passportExpiryDate: updateCustomerInfoData.passportExpiryDate
        ? date00(updateCustomerInfoData.passportExpiryDate)
        : null,
      campaign: updateCustomerInfoData.campaign,
    };
    dispatch({
      type: "UPDATE_CUSTOMER_INFO_REQUEST",
      payload: { id: record.id, formData },
    });
    // form.resetFields();
    setIsUpdateCustomerInfoModalVisible(false);
  };
  console.log("record form update client", record);
  return (
    <Drawer
      title="Update Customer Info"
      open={isUpdateCustomerInfoModalVisible}
      onClose={() => {
        setIsUpdateCustomerInfoModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Form
        layout="vertical"
        onFinish={updateCustomerInfoSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
        // fields={[
        //   {
        //     name: ["firstName"],
        //     value: firstname,
        //   },
        //   {
        //     name: ["lastName"],
        //     value: lastname,
        //   },
        //   {
        //     name: ["email"],
        //     value: email,
        //   },
        //   {
        //     name: ["mobile"],
        //     value: mobile,
        //   },
        //   {
        //     name: ["passportNumber"],
        //     value: passportNumber,
        //   },
        //   {
        //     name: ["phone"],
        //     value: phone,
        //   },
        //   {
        //     name: ["referralName"],
        //     value: referral?.emailId,
        //   },
        //   {
        //     name: ["aboutUsName"],
        //     value: aboutUs?.aboutUs,
        //   },
        //   {
        //     name: ["address"],
        //     value: address,
        //   },
        //   {
        //     name: ["maritalStatus"],
        //     value: maritalStatus,
        //   },
        //   {
        //     name: ["sendByEmail"],
        //     value: sendByEmail,
        //   },
        //   {
        //     name: ["sexStatus"],
        //     value: sexStatus,
        //   },
        // ]}
        initialValues={{
          firstName: record?.firstName,
          lastName: record?.lastName,
          email: record?.email,
          mobile: record?.mobile,
          passportNumber: record?.passportNumber,
          dateOfBirth: record?.dob ? moment(record?.dob) : null,
          phone: record?.phone,
          aboutUs: record?.aboutUs?.id,
          referral: record?.referral?.id,
          address: record?.address,
          maritalStatus: record?.maritalStatus,
          sexStatus: record?.sexStatus,
          sendByEmail: record?.sendByEmail,
          countryOfResidence: record?.countryOfResidence,
          visaType: record?.visaType,
          visaStatus: record?.visaStatus,
          campaign: record?.campaign?.id,
          visaExpiryDate: record?.visaExpiryDate
            ? moment(record?.visaExpiryDate)
            : null,
          passportExpiryDate: record?.passportExpiryDate
            ? moment(record?.passportExpiryDate)
            : null,
        }}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="First Name"
            name={"firstName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter first name!",
              },
            ]}
          >
            <Input
              value={firstname}
              placeholder="First Name"
              onChange={(e) => {
                setFirstname(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name={"lastName"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter last name!",
            //   },
            // ]}
          >
            <Input
              value={lastname}
              placeholder="Last Name"
              onChange={(e) => {
                setLastname(e.target.value);
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Email"
            name={"email"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     type: "email",
            //     message: "Please enter valid email",
            //   },
            // ]}
          >
            <Input
              value={email}
              type="email"
              placeholder="E.g. name@domainname.com"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            label="Mobile Number"
            name={"mobile"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter mobile number",
              },
            ]}
          >
            <Input
              value={mobile}
              type="number"
              placeholder="Mobile Number"
              onChange={(e) => {
                setMobile(e.target.value);
              }}
            />
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Date of Birth"
            name={"dateOfBirth"}
            style={{ width: "100%" }}
            //   rules={[
            //     {
            //       required: true,
            //       message: "Please pick birth date!",
            //     },
            //   ]}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              disabledDate={(currentDate) => {
                const date = new Date();
                return currentDate > date;
              }}
              onChange={(e) => {
                setDateOfBirth(formattedDate(e));
              }}
            />
          </Form.Item>
          <Form.Item
            label="Passport Number"
            name={"passportNumber"}
            style={{ width: "100%" }}
            //   rules={[
            //     {
            //       required: true,
            //       message: "Please enter a valid passport number !",
            //     },
            //   ]}
          >
            <Input
              value={passportNumber}
              type="number"
              placeholder="passport number"
              onChange={(e) => {
                setpassportNumber(e.target.value);
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Visa Type"
            name={"visaType"}
            style={{ width: "100%" }}

            //   rules={[
            //     {
            //       required: true,
            //       message: "Please pick birth date!",
            //     },
            //   ]}
          >
            <Input
              placeholder="Visa Type"
              onChange={(e) => {
                setUpdateCustomerInfoData((previousData) => {
                  return {
                    ...previousData,
                    visaType: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Visa Status"
            name={"visaStatus"}
            style={{ width: "100%" }}
          >
            <Select
              placeholder="Visa Status"
              onChange={(e) => {
                setUpdateCustomerInfoData((previousData) => {
                  return {
                    ...previousData,
                    visaStatus: e,
                  };
                });
              }}
            >
              <Option value="IN_PROGRESS">In Progress</Option>
              <Option value="EXPIRED">Expired</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "flex-start", gap: "1rem", width: "100%" }}
          >
            <Form.Item
              label="Visa Expiry Date"
              name={"visaExpiryDate"}
              style={{ width: "100%" }}
            >
              <DatePicker
                format="DD-MM-YYYY"
                onChange={(e) => {
                  setUpdateCustomerInfoData((previousData) => {
                    return {
                      ...previousData,
                      visaExpiryDate: e,
                    };
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Passport Expiry Date"
              name={"passportExpiryDate"}
              style={{ width: "100%" }}
            >
              <DatePicker
                format="DD-MM-YYYY"
                onChange={(e) => {
                  setUpdateCustomerInfoData((previousData) => {
                    return {
                      ...previousData,
                      passportExpiryDate: e,
                    };
                  });
                }}
              />
            </Form.Item>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{ gap: "0.3rem", width: "100%" }}
          >
            <Form.Item
              label="Campaign"
              name={"campaign"}
              style={{ width: "100%" }}
            >
              <Select>
                {leadState.leadsDropdown?.campaign?.map((dataObj) => {
                  return (
                    <Option key={dataObj.id} value={dataObj.id}>
                      <div
                        onClick={() => {
                          setUpdateCustomerInfoData((previousData) => {
                            return {
                              ...previousData,
                              campaign: dataObj.id,
                              campaignName: dataObj.campaignName,
                            };
                          });
                        }}
                      >
                        {dataObj.campaignName}
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddCampaignModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Phone Number"
            name={"phone"}
            style={{ width: "100%" }}
            rules={[
              {
                pattern: /^[\d]{9,11}$/,
                message: "Phone number should contain 9 to 11 numbers",
              },
            ]}
          >
            <Input
              value={phone}
              placeholder="Phone Number"
              onChange={(e) => {
                setPhone(e.target.value);
              }}
            />
          </Form.Item>
          <div
            className="flexRowWithoutStyle"
            style={{ gap: "0.3rem", width: "100%" }}
          >
            <Form.Item
              label="Referral"
              name={"referral"}
              style={{ width: "100%" }}
              //   rules={[
              //     {
              //       required: true,
              //       message: "Please select a referral !",
              //     },
              //   ]}
            >
              <Select value={referral}>
                {leadState.leadsDropdown?.references?.map((dataObj) => {
                  return (
                    <Option key={dataObj.id} value={dataObj.id}>
                      <div
                        onClick={(e) => {
                          setReferral(dataObj);
                        }}
                      >
                        {dataObj.firstName} {dataObj.lastName}
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddReferralModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <div
            className="flexRowWithoutStyle"
            style={{ gap: "0.3rem", width: "100%" }}
          >
            <Form.Item
              label="About Us"
              name={"aboutUs"}
              style={{ width: "100%" }}
              //   rules={[
              //     {
              //       required: true,
              //       message: "Please select option!",
              //     },
              //   ]}
            >
              <Select value={aboutUs}>
                {leadState.leadsDropdown?.aboutUs?.map((dataObj) => {
                  return (
                    <Option key={dataObj.id} value={dataObj.id}>
                      <div
                        onClick={(e) => {
                          setAboutUs(e.target.value);
                        }}
                      >
                        {dataObj.aboutUs}
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddAboutUsModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
          <Form.Item
            label="Address"
            name={"address"}
            style={{ width: "100%" }}
            //   rules={[
            //     {
            //       required: true,
            //       message: "Please enter a address !",
            //     },
            //   ]}
          >
            <Input
              value={address}
              placeholder="address"
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Gender"
            name={"sexStatus"}
            style={{ width: "100%" }}
            //   rules={[
            //     {
            //       required: true,
            //       message: "Please select sex status!",
            //     },
            //   ]}
          >
            <Select
              value={sexStatus}
              onChange={(e) => {
                console.log(e, "fdsldkfj");
                setSexStatus(e);
              }}
            >
              <Option value="MALE">Male</Option>
              <Option value="FEMALE">Female</Option>
              <Option value="OTHERS">Others</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Marital Status"
            name={"maritalStatus"}
            style={{ width: "100%" }}
            //   rules={[
            //     {
            //       required: true,
            //       message: "Please select a maritalStatus!",
            //     },
            //   ]}
          >
            <Select
              value={maritalStatus}
              onChange={(e) => {
                setMaritalStatus(e);
              }}
            >
              <Option value="SINGLE">Single</Option>
              <Option value="MARRIED">Married</Option>
              <Option value="DIVORCED">Divorced</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "flex-start", gap: "1rem" }}
        >
          <Form.Item
            label="Country of Residence"
            name={"countryOfResidence"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter photo!",
            //   },
            // ]}
          >
            <Input
              placeholder="Please enter country of residence!"
              onChange={(e) => {
                setCountryOfResidence(e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item
            name={"sendByEmail"}
            valuePropName="checked"
            style={{ width: "100%" }}
            onChange={(e) => {
              setSendByEmail(e.target.checked);
            }}
          >
            <Checkbox>Send Email/SMS</Checkbox>
          </Form.Item>
        </div>
        <Form.Item
          name="clientInfoNext"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                console.log("value frm head agent", value);
                console.log(
                  "is percentage frm head agent",
                  getFieldValue("mobile"),
                  getFieldValue("email")
                );
                if (
                  (getFieldValue("email") === undefined ||
                    getFieldValue("email") === "" ||
                    getFieldValue("email") === null) &&
                  (getFieldValue("mobile" === undefined) ||
                    getFieldValue("mobile") === "" ||
                    getFieldValue("mobile") === null)
                ) {
                  return Promise.reject(
                    new Error("Please enter either email or mobile number!")
                  );
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
          hasFeedback
        >
          <Button name="clientInfoNext" type="primary" htmlType="submit">
            Update Customer Info
          </Button>
        </Form.Item>
      </Form>
      {isAddReferralModalVisible && (
        <AddReferral
          isAddReferralModalVisible={isAddReferralModalVisible}
          setIsAddReferralModalVisible={setIsAddReferralModalVisible}
        />
      )}
      {isAddAboutUsModalVisible && (
        <AddAboutUs
          isAddAboutUsModalVisible={isAddAboutUsModalVisible}
          setIsAddAboutUsModalVisible={setIsAddAboutUsModalVisible}
        />
      )}
      {isAddCampaignModalVisible && (
        <AddCampaign
          isAddCampaignModalVisible={isAddCampaignModalVisible}
          setIsAddCampaignModalVisible={setIsAddCampaignModalVisible}
        />
      )}
    </Drawer>
  );
};

export default UpdateCustomerInfo;
