import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  campaign: {},
  activeCampaign: {},
  formDetails: {},
  leadsDropdown: {},
  qrUrlLink: "",
  appointmentFormDetails: {},
  timingDropdown: [],
  companyLogoAndFooterDetails: {},
  counsellorForAdate: [],
};

const CampaignReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_CAMPAIGN_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_CAMPAIGN_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        campaign: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_CAMPAIGN_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_ACTIVE_CAMPAIGN_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_ACTIVE_CAMPAIGN_REQUEST_SUCCESS": {
      return {
        ...state,
        activeCampaign: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ACTIVE_CAMPAIGN_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load active campaigns.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_CAMPAIGN_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_CAMPAIGN_REQUEST_SUCCESS": {
      action.payload?.response?.data?.message &&
        message.error(action.payload?.response?.data?.message);

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_CAMPAIGN_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add Campaign.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_CAMPAIGN_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_CAMPAIGN_REQUEST_SUCCESS": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.success("Success! Campaign updated.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_CAMPAIGN_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to update Campaign.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_CAMPAIGN_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DELETE_CAMPAIGN_REQUEST_SUCCESS": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.success("Success! Campaign deleted.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_CAMPAIGN_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to delete Campaign.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_CAMPAIGN_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "TOGGLE_CAMPAIGN_STATUS_REQUEST_SUCCESS": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.success("Success! Campaign status changed.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_CAMPAIGN_STATUS_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to change Campaign status.");

      message.error("error");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_LEAD_FROM_CAMPAIGN_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_LEAD_FROM_CAMPAIGN_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Lead Added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_LEAD_FROM_CAMPAIGN_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add lead.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_QR_CODE_OF_CAMPAIGN_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_QR_CODE_OF_CAMPAIGN_REQUEST_SUCCESS": {
      return {
        ...state,
        qrUrlLink: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_QR_CODE_OF_CAMPAIGN_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load QR code.");

      return {
        ...state,
        isLoading: false,
      };
    }

    case "FETCH_FORM_DETAILS_OF_CAMPAIGN_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_FORM_DETAILS_OF_CAMPAIGN_REQUEST_SUCCESS": {
      return {
        ...state,
        formDetails: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_FORM_DETAILS_OF_CAMPAIGN_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load form details.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_CAMPAIGN_FORM_DROPDOWN_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_CAMPAIGN_FORM_DROPDOWN_REQUEST_SUCCESS": {
      return {
        ...state,
        leadsDropdown: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_CAMPAIGN_FORM_DROPDOWN_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load campaign dropdowns.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_APPOINTMENT_FORM_FIELDS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_APPOINTMENT_FORM_FIELDS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Appointment form added for the campaign.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_APPOINTMENT_FORM_FIELDS_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error(
            "Error! Unable to add appointment form fields for a campaign."
          );

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_APPOINTMENT_FORM_DETAILS_OF_CAMPAIGN_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_APPOINTMENT_FORM_DETAILS_OF_CAMPAIGN_REQUEST_SUCCESS": {
      return {
        ...state,
        appointmentFormDetails: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_APPOINTMENT_FORM_DETAILS_OF_CAMPAIGN_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error(
            "Error! Unable to load appointment details of campaign."
          );

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_TIMING_DROPDOWN_FOR_CAMPAIGN_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_TIMING_DROPDOWN_FOR_CAMPAIGN_REQUEST_SUCCESS": {
      return {
        ...state,
        timingDropdown: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_TIMING_DROPDOWN_FOR_CAMPAIGN_REQUEST_FAILURE": {
      action.payload?.response?.data?.data?.message
        ? message.error(action.payload?.response?.data?.data?.message)
        : message.error("Error! Consultant is not available on this date!");
      return {
        ...state,
        timingDropdown: [],
        isLoading: false,
      };
    }
    case "CLEAR_CAMPAIGN_AVAILABLE_DATE_AND_TIME": {
      return {
        ...state,
        timingDropdown: [],
      };
    }
    case "ADD_APPOINTMENT_FROM_CAMPAIGN_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ADD_APPOINTMENT_FROM_CAMPAIGN_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Appointment added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_APPOINTMENT_FROM_CAMPAIGN_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add an appointment.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_COMPANY_DETAILS_FOR_LOGO_AND_FOOTER_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_COMPANY_DETAILS_FOR_LOGO_AND_FOOTER_REQUEST_SUCCESS": {
      return {
        ...state,
        companyLogoAndFooterDetails: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_COMPANY_DETAILS_FOR_LOGO_AND_FOOTER_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load company details.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_COUNSELLOR_FOR_APPOINTMENT_DATE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_COUNSELLOR_FOR_APPOINTMENT_DATE_REQUEST_SUCCESS": {
      action.payload?.length > 0
        ? message.success("Counsellor found for the given date!")
        : message.warn("Counsellor not available for the given date!");

      return {
        ...state,
        counsellorForAdate: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_COUNSELLOR_FOR_APPOINTMENT_DATE_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.warn(action.payload?.response?.data?.message)
        : message.warn("Unable to find counsellor for the given date!");

      return {
        ...state,
        counsellorForAdate: [],
        isLoading: false,
      };
    }
    case "FETCH_QR_CODE_OF_CONSULTANT_OF_APPOINTMENT_FORM_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_QR_CODE_OF_CONSULTANT_OF_APPOINTMENT_FORM_REQUEST_SUCCESS": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_QR_CODE_OF_CONSULTANT_OF_APPOINTMENT_FORM_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load link for consultant.");

      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default CampaignReducer;
