import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
  TimePicker,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import {
  dateAndTime,
  dateAndTimeUTC,
  formattedDate,
  time,
} from "../../HelperFunction";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { AiOutlinePlus } from "react-icons/ai";
import AddCountry from "../Leads/Country/AddCountry";
const { Option } = Select;

const AddEmployee = ({
  AddEmployeeTitle,
  isConsultantFrmConsultant,
  isAddEmployeeModalVisible,
  setIsAddEmployeeModalVisible,
  employeRecord,
}) => {
  const store = useSelector((state) => {
    return {
      employeeState: state.LeadReducer,
    };
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "FETCH_LEADS_DROPDOWN_REQUEST",
    });
    dispatch({
      type: "FETCH_REMINDER_EXIPRY_WORKING_HOUR_REQUEST",
      isSuccessFn: true,
      successFunction: successFunction,
    });
  }, []);
  const successFunction = (response) => {
    const makeSameScheduleDays =
      response?.data?.openingSchedule?.scheduleDays?.length > 0 &&
      response?.data?.openingSchedule?.scheduleDays?.map((dataObj) => {
        return {
          days: dataObj?.scheduleDays,
          start: dataObj.startTime,
          end: dataObj?.endTime,
          breakStartTime: dataObj?.breakStartTime,
          breakEndTime: dataObj?.breakEndTime,
        };
      });

    response?.data?.openingSchedule?.scheduleDays?.length > 0 &&
      setScheduleDay(makeSameScheduleDays);
  };
  const [isAddCountryModalVisible, setIsAddCountryModalVisible] =
    useState(false);
  const [isConsultant, setIsConsultant] = useState(false);
  const [employeeData, setEmployeeData] = useState({
    countryId: null,
    countryName: null,
    callingCode: null,
  });
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  useEffect(() => {
    if (isAddEmployeeModalVisible === true) {
      setIsConsultant(isConsultantFrmConsultant);

      setScheduleDay([
        {
          days: "",
          start: "",
          end: "",
          breakStartTime: "",
          breakEndTime: "",
        },
      ]);
    }
  }, [isAddEmployeeModalVisible]);
  const [scheduleDay, setScheduleDay] = useState([
    {
      days: "",
      start: "",
      end: "",
      breakStartTime: "",
      breakEndTime: "",
    },
  ]);

  const addEmployeeSubmitHandler = (value) => {
    const dob = value.birthDate ? dateAndTimeUTC(value.birthDate) : null;
    const scheduleDays = isConsultant && scheduleDay;
    const formData = {
      ...value,
      dob,
      country: employeeData?.countryName ? employeeData?.countryName : null,
    };
    dispatch({
      type: "ADD_CONSULTANT_REQUEST",
      payload: { values: formData, payload2: { scheduleDays: scheduleDays } },
    });
    form.resetFields();
    setScheduleDay(null);
    setIsAddEmployeeModalVisible(false);
  };
  const removeScheduledDayInput = (index) => {
    const list = [...scheduleDay];
    list.splice(index, 1);
    setScheduleDay(list);
  };
  const addScheduledDayInput = () => {
    setScheduleDay([
      ...scheduleDay,
      {
        days: "",
        start: "",
        end: "",
        breakStartTime: "",
        breakEndTime: "",
      },
    ]);
  };
  const handleScheduleDayChange = (e, index) => {
    const list = [...scheduleDay];
    list[index].days = e;
    setScheduleDay(list);
  };
  const handleScheduleStartTimeChange = (e, index) => {
    const list = [...scheduleDay];
    list[index].start = moment(e).format("HH:mm:00");
    setScheduleDay(list);
  };
  const handleScheduleEndTimeChange = (e, index) => {
    const list = [...scheduleDay];
    list[index].end = moment(e).format("HH:mm:00");
    setScheduleDay(list);
  };
  const handleScheduleBreakStartTimeChange = (e, index) => {
    const list = [...scheduleDay];
    list[index].breakStartTime = moment(e).format("HH:mm:00");
    setScheduleDay(list);
  };
  const handleScheduleBreakEndTimeChange = (e, index) => {
    const list = [...scheduleDay];
    list[index].breakEndTime = moment(e).format("HH:mm:00");
    setScheduleDay(list);
  };

  return (
    <Drawer
      title={AddEmployeeTitle}
      open={isAddEmployeeModalVisible}
      onClose={() => {
        setIsAddEmployeeModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Form
        layout="vertical"
        onFinish={addEmployeeSubmitHandler}
        colon={true}
        form={form}
        className={"drawerStyle"}
        initialValues={{
          isConsultant: isConsultant,
          country: employeRecord.country,
          callingCode: employeRecord.countryCode,
        }}
        // fields={[
        //   {
        //     name: ["isConsultant"],
        //     value: isConsultant,
        //   },
        //   {
        //     name: ["callingCode"],
        //     value: employeeData.callingCode,
        //   },
        // ]}
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="First Name"
            name={"firstName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter first name!",
              },
            ]}
          >
            <Input placeholder="Please enter first name!" />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name={"lastName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter last name!",
              },
            ]}
          >
            <Input placeholder="Please enter last name!" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Gender"
            name={"gender"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select gender!",
              },
            ]}
          >
            <Select>
              <Option value="MALE">Male</Option>
              <Option value="FEMALE">Female</Option>
              <Option value="OTHERS">Others</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Date of Birth"
            name={"birthDate"}
            style={{ width: "100%" }}
            validateStatus={error ? "error" : undefined}
            help={error}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please pick birth date!",
            //   },
            // ]}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              disabledDate={(currentDate) => {
                const date = new Date();
                return currentDate > date;
              }}
              onChange={(e) => {
                if (e) {
                  const age = moment().diff(e, "years");
                  if (age < 18) {
                    setError("Employee must be above 18 years old.");
                    form.setFields([
                      {
                        name: "birthDate",
                        errors: ["Employee must be at least 18 years old."],
                      },
                    ]);
                  } else {
                    setError("");
                    form.setFields([
                      {
                        name: "birthDate",
                        errors: [],
                      },
                    ]);
                  }
                }
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Email"
            name={"email"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter valid email!",
              },
            ]}
          >
            <Input type="email" placeholder="e.g, name@domainname.com" />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name={"phoneNumber"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter phone number!",
              },
              {
                pattern: /^[\d]{9,11}$/,
                message: "Mobile number should contain 9 to 11 numbers",
              },
            ]}
          >
            <Input type="number" placeholder="Please enter phone number!" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <div
            className="flexRowWithoutStyle"
            style={{ gap: "0.3rem", width: "100%" }}
          >
            <Form.Item
              label="Country"
              name={"country"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter a country!",
                },
              ]}
            >
              <Select>
                {store.employeeState?.leadsDropdown?.countries?.map(
                  (dataObj) => {
                    return (
                      <Option key={dataObj.id}>
                        <div
                          onClick={(e) => {
                            setEmployeeData((previousData) => {
                              return {
                                ...previousData,
                                countryId: dataObj.id,
                                countryName: dataObj.countryName,
                                callingCode: dataObj.callingCode,
                              };
                            });
                            form.setFieldsValue({
                              callingCode: dataObj.callingCode,
                            });
                          }}
                          className={"selectOptionDiv"}
                        >
                          {dataObj.countryName}
                        </div>
                      </Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddCountryModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
          <Form.Item
            label="Country Code"
            name={"callingCode"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter calling code!",
              },
            ]}
          >
            <Input readOnly placeholder="Calling Code" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Address Line 1"
            name={"address"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter address line 1!",
              },
            ]}
          >
            <Input placeholder="Address Line 1" />
          </Form.Item>
          <Form.Item
            label="Address Line 2"
            name={"address2"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter address line 2!",
            //   },
            // ]}
          >
            <Input placeholder="Address Line 2" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          {employeeData?.countryName?.toLowerCase() === "australia" ? (
            <Form.Item label="State" name={"state"} style={{ width: "100%" }}>
              <Select>
                <Option value="NSW|New South Wales">New South Wales</Option>
                <Option value="QLD|Queensland">Queensland</Option>
                <Option value="SA|South Australia">South Australia</Option>
                <Option value="TAS|Tasmania">Tasmania</Option>
                <Option value="VIC|Victoria">Victoria</Option>
                <Option value="WA|Western Australia">Western Australia</Option>
                <Option value="ACT|Australian Capital Territory">
                  Australian Capital Territory
                </Option>
                <Option value="NT|Northern Territory">
                  Northern Territory
                </Option>
              </Select>
            </Form.Item>
          ) : (
            <Form.Item label="State" name={"state"} style={{ width: "100%" }}>
              <Input placeholder="State" />
            </Form.Item>
          )}
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item label="Suburb" name={"suburb"} style={{ width: "100%" }}>
            <Input placeholder="Suburb" />
          </Form.Item>

          <Form.Item
            label="Post Code/Zip Code"
            name={"zipCode"}
            style={{ width: "100%" }}
          >
            <Input type="number" placeholder="Zip Code" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Password"
            name={"password"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter password!",
              },
            ]}
          >
            <Input.Password placeholder="Password!" />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name={"confirmPassword"}
            dependencies={["password"]}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please confirm the password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Password and confirm password do not match!")
                  );
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password placeholder="Confirm Password!" />
          </Form.Item>
        </div>
        <Form.Item name={"isConsultant"} valuePropName="checked">
          <Checkbox
            onChange={(e) => {
              setIsConsultant(e.target.checked);
            }}
          >
            Is Consultant
          </Checkbox>
        </Form.Item>

        {isConsultant && (
          <>
            <div
              style={{
                backgroundColor: "#eeeeee55",
                borderRadius: "0.5rem",
                padding: "0.5rem",
              }}
            >
              <h3>Schedule Days:</h3>
              {scheduleDay?.map((singleDay, index) => {
                return (
                  <div key={index}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "0.5rem",
                          width: "100%",
                        }}
                      >
                        <Form.Item
                          label="Schedule Day"
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please select a day!",
                            },
                          ]}
                        >
                          <Select
                            name={"days"}
                            onChange={(e) => {
                              handleScheduleDayChange(e, index);
                            }}
                            value={scheduleDay[index].days}
                            options={[
                              {
                                value: "SUNDAY",
                                label: "Sunday",
                              },
                              {
                                value: "MONDAY",
                                label: "Monday",
                              },
                              {
                                value: "TUESDAY",
                                label: "Tuesday",
                              },
                              {
                                value: "WEDNESDAY",
                                label: "Wednesday",
                              },
                              {
                                value: "THURSDAY",
                                label: "Thursday",
                              },
                              {
                                value: "FRIDAY",
                                label: "Friday",
                              },
                              {
                                value: "SATURDAY",
                                label: "Saturday",
                              },
                            ]}
                          />
                        </Form.Item>

                        <Form.Item
                          label="Start Time"
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please pick a start time in that day!",
                            },
                          ]}
                        >
                          <TimePicker
                            name={"start"}
                            value={
                              scheduleDay[index].start
                                ? moment(
                                    `${scheduleDay[index].start}`,
                                    "HH:mm:00"
                                  ).local()
                                : null
                            }
                            onChange={(value) => {
                              handleScheduleStartTimeChange(value, index);
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          label="End Time"
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please pick a end time in that day!",
                            },
                          ]}
                        >
                          <TimePicker
                            name={"end"}
                            value={
                              scheduleDay[index].end
                                ? moment(
                                    `${scheduleDay[index].end}`,
                                    "HH:mm:00"
                                  ).local()
                                : null
                            }
                            onChange={(e) => {
                              handleScheduleEndTimeChange(e, index);
                            }}
                            // value={moment(scheduleDay[index].end).format(
                            //   "HH:mm:ss"
                            // )}
                          />
                        </Form.Item>

                        <Form.Item
                          label="Break Start Time"
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message:
                                "Please pick a break start time in that day!",
                            },
                          ]}
                        >
                          <TimePicker
                            // onChange={(e) => {

                            //   handleScheduleStartTimeChange(e, index);
                            // }}
                            // value={moment(scheduleDay[index].start).format(
                            //   "HH:mm:ss"
                            // )}
                            name={"breakStartTime"}
                            value={
                              scheduleDay[index].breakStartTime
                                ? moment(
                                    `${scheduleDay[index].breakStartTime}`,
                                    "HH:mm:00"
                                  ).local()
                                : null
                            }
                            onChange={(value) => {
                              handleScheduleBreakStartTimeChange(value, index);
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Break End Time"
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please pick a end time in that day!",
                            },
                          ]}
                        >
                          <TimePicker
                            name={"breakEndTime"}
                            value={
                              scheduleDay[index].breakEndTime
                                ? moment(
                                    `${scheduleDay[index].breakEndTime}`,
                                    "HH:mm:00"
                                  ).local()
                                : null
                            }
                            onChange={(e) => {
                              handleScheduleBreakEndTimeChange(e, index);
                            }}
                            // value={moment(scheduleDay[index].end).format(
                            //   "HH:mm:ss"
                            // )}
                          />
                        </Form.Item>
                      </div>
                      {scheduleDay.length > 1 && (
                        <Button
                          color="red"
                          onClick={() => removeScheduledDayInput(index)}
                          style={{
                            padding: "0",
                            borderRadius: "50%",
                            height: "1.5rem",
                            width: "1.5rem",
                          }}
                        >
                          <CloseOutlined />
                        </Button>
                      )}
                    </div>
                    {scheduleDay.length - 1 === index && (
                      <Button
                        block
                        type="dashed"
                        onClick={addScheduledDayInput}
                      >
                        <PlusOutlined />
                        Add Day
                      </Button>
                    )}
                  </div>
                );
              })}
            </div>
          </>
        )}
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            {AddEmployeeTitle ?? "Add Employee"}
          </Button>
        </Form.Item>
      </Form>
      {isAddCountryModalVisible && (
        <AddCountry
          isAddCountryModalVisible={isAddCountryModalVisible}
          setIsAddCountryModalVisible={setIsAddCountryModalVisible}
        />
      )}
    </Drawer>
  );
};

export default AddEmployee;
