import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
const { Option } = Select;

const AddEducationVisa = ({
  isAddEducationVisaModalVisible,
  setIsAddEducationVisaModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const addEducationVisaSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      subClass: value.subClass,
      visaCategory: "EDUCATION",
    };
    dispatch({ type: "ADD_EDUCATION_VISA_REQUEST", payload: formData });
    form.resetFields();
    setIsAddEducationVisaModalVisible(false);
  };
  return (
    <Modal
      title="Add Education Visa"
      open={isAddEducationVisaModalVisible}
      onCancel={() => {
        setIsAddEducationVisaModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addEducationVisaSubmitHandler}
        colon={true}
        form={form}
      >
        <Form.Item
          label="Visa class"
          name={"subClass"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter visa class name!",
            },
          ]}
        >
          <Input placeholder="Visa class name" />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Education Visa
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddEducationVisa;
