import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Collapse,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
const { Option } = Select;
const { Panel } = Collapse;

const AddCasePackage = ({
  from,
  companyRecord,
  instituteRecord,
  isAddPackageModalVisible,
  setIsAddPackageModalVisible,
}) => {
  const store = useSelector((state) => {
    return {
      leadState: state.LeadReducer,
      caseState: state.VisasReducer,
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "FETCH_LEADS_DROPDOWN_REQUEST",
      payload: {
        fromInstituteVisaCase: true,
        visaSuccessFunction: visaSuccessFunction,
      },
    });
    dispatch({
      type: "FETCH_CASES_DROPDOWN_REQUEST",
    });
  }, []);
  const visaSuccessFunction = (response) => {
    console.log("defaultSelectedCurrency", response.data);
    setPackageData((previousData) => {
      return {
        ...previousData,
        currency: response?.data?.defaultSelectedCurrency,
      };
    });
  };
  const [form] = Form.useForm();
  const [packageData, setPackageData] = useState({
    packageType:
      from === "CASE"
        ? "IN_HOUSE"
        : from === "COMPANY"
        ? "COMPANY"
        : from === "INSTITUTE"
        ? "INSTITUTE"
        : "IN_HOUSE",
    casePackageName: null,
    unitPrice: null,
    payType: null,
    commission:
      from === "COMPANY"
        ? companyRecord?.commission
        : from === "INSTITUTE"
        ? instituteRecord?.commission
        : null,
    commissionType:
      from === "COMPANY"
        ? companyRecord?.commissionType
        : from === "INSTITUTE"
        ? instituteRecord?.commissionType
        : "PERCENTAGE",
    isGst: false,
    gst: null,
    gstType: "PERCENTAGE",
    currency: null,
    companyId: companyRecord?.id,
    companyName: companyRecord?.companyName,
    instituteId: instituteRecord?.id ?? null,
    instituteName: instituteRecord?.instituteName ?? null,
  });
  const addCasePackageSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      casePackageName: packageData.casePackageName,
      unitPrice: packageData.unitPrice,
      payType: packageData.payType,
      currency: packageData.currency,
      gst: packageData.gst,
      company: packageData.companyId,
      commission: packageData.commission,
      packageType: packageData.packageType,
      hasGst: packageData.isGst,
      isGstFlat:
        packageData.gstType === "FLAT"
          ? true
          : packageData.gstType === "PERCENTAGE"
          ? false
          : null,
      isCommissionFlat:
        packageData.commissionType === "FLAT"
          ? true
          : packageData.commissionType === "PERCENTAGE"
          ? false
          : null,
      institute: packageData.instituteId,
    };
    dispatch({ type: "ADD_CASE_PACKAGE_REQUEST", payload: formData });
    // form.resetFields();
    setIsAddPackageModalVisible(false);
  };
  console.log(
    "store.caseState?.caseDropdown?.institute",
    store.caseState?.caseDropdown?.institute
  );
  console.log("packageData", packageData);
  return (
    <Drawer
      title="Add Package"
      open={isAddPackageModalVisible}
      onClose={() => {
        setIsAddPackageModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Form
        layout="vertical"
        onFinish={addCasePackageSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
        fields={[
          {
            name: ["packageType"],
            value: packageData.packageType,
          },
          {
            name: ["selectedCompany"],
            value: packageData.companyName,
          },
          {
            name: ["selectedInstitute"],
            value: packageData.instituteName,
          },
          {
            name: ["casePackageName"],
            value: packageData.casePackageName,
          },
          {
            name: ["unitPrice"],
            value: packageData.unitPrice,
          },
          {
            name: ["payType"],
            value: packageData.payType,
          },
          {
            name: ["commission"],
            value: packageData.commission,
          },
          {
            name: ["commissionType"],
            value: packageData.commissionType,
          },
          {
            name: ["isGst"],
            value: packageData.isGst,
          },
          {
            name: ["gst"],
            value: packageData.gst,
          },
          {
            name: ["gstType"],
            value: packageData.gstType,
          },
          {
            name: ["currency"],
            value: packageData.currency,
          },
        ]}
      >
        <Form.Item
          label="Package Type"
          name={"packageType"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter case package type!",
            },
          ]}
        >
          <Radio.Group
            onChange={(e) => {
              if (e.target.value === "IN_HOUSE") {
                setPackageData((previousData) => {
                  return {
                    ...previousData,
                    packageType: e.target.value,
                    instituteId: null,
                    instituteName: null,
                    companyId: null,
                    companyName: null,
                  };
                });
              } else if (e.target.value === "COMPANY") {
                setPackageData((previousData) => {
                  return {
                    ...previousData,
                    packageType: e.target.value,
                    instituteId: null,
                    instituteName: null,
                  };
                });
              } else if (e.target.value === "INSTITUTE") {
                setPackageData((previousData) => {
                  return {
                    ...previousData,
                    packageType: e.target.value,
                    companyId: null,
                    companyName: null,
                  };
                });
              }
            }}
          >
            <Radio value={"IN_HOUSE"}>Inhouse</Radio>
            <Radio value={"COMPANY"}>Company</Radio>
            <Radio value={"INSTITUTE"}>Institute</Radio>
          </Radio.Group>
        </Form.Item>
        {packageData.packageType === "COMPANY" && (
          <Form.Item
            label="Select Company"
            name={"selectedCompany"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a company!",
              },
            ]}
          >
            <Select>
              {store.caseState?.caseDropdown?.companyResponseList?.map(
                (dataObj) => {
                  return (
                    <Option key={dataObj.id}>
                      <div
                        key={dataObj.id}
                        onClick={() => {
                          setPackageData((previousData) => {
                            return {
                              ...previousData,
                              companyId: dataObj.id,
                              companyName: dataObj.fullName,
                            };
                          });
                        }}
                        className={"selectOptionDiv"}
                      >
                        {dataObj.fullName}
                      </div>
                    </Option>
                  );
                }
              )}
            </Select>
          </Form.Item>
        )}
        {packageData.packageType === "INSTITUTE" && (
          <Form.Item
            label="Select Institute"
            name={"selectedInstitute"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a institute!",
              },
            ]}
          >
            <Select>
              {store.caseState?.caseDropdown?.institute?.map((dataObj) => {
                return (
                  <Option key={dataObj.id}>
                    <div
                      onClick={() => {
                        setPackageData((previousData) => {
                          return {
                            ...previousData,
                            instituteId: dataObj.id,
                            instituteName: dataObj.name,
                          };
                        });
                      }}
                      className={"selectOptionDiv"}
                    >
                      {dataObj.name}
                    </div>
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        )}
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Package Name"
            name={"casePackageName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter case package name!",
              },
            ]}
          >
            <Input
              placeholder="Package Name"
              onChange={(e) => {
                setPackageData((previousData) => {
                  return {
                    ...previousData,
                    casePackageName: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <div style={{ display: "flex", width: "100%", gap: "0.5rem" }}>
            <Form.Item
              label="Unit Price"
              name={"unitPrice"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter unit price!",
                },
              ]}
            >
              <Input
                type="number"
                placeholder="Unit Price"
                onChange={(e) => {
                  setPackageData((previousData) => {
                    return {
                      ...previousData,
                      unitPrice: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Pay type"
              name={"payType"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter unit price!",
                },
              ]}
            >
              <Select
                type="number"
                placeholder="Pay Type"
                onChange={(e) => {
                  setPackageData((previousData) => {
                    return {
                      ...previousData,
                      payType: e,
                    };
                  });
                }}
              >
                <Option value="ONE_TIME">One Time</Option>
                <Option value="WEEKLY">Weekly</Option>
                <Option value="FORTNIGHTLY">Fortnightly</Option>
                <Option value="MONTHLY">Monthly</Option>
                <Option value="QUATERLY">Quaterly</Option>
                <Option value="HALF_YEARLY">Half Yearly</Option>
                <Option value="YEARLY">Yearly</Option>
              </Select>
            </Form.Item>
          </div>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            name={"isGst"}
            valuePropName="checked"
            style={{ width: "100%" }}
          >
            <Checkbox
              onChange={(e) => {
                setPackageData((previousData) => {
                  return {
                    ...previousData,
                    isGst: e.target.checked,
                  };
                });
              }}
            >
              Is GST/VAT included?
            </Checkbox>
          </Form.Item>
          {packageData.isGst && (
            <div style={{ display: "flex", width: "100%", gap: "0.5rem" }}>
              <Form.Item
                label="GST/VAT amount"
                name={"gst"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter GST!",
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder="GST"
                  onChange={(e) => {
                    setPackageData((previousData) => {
                      return {
                        ...previousData,
                        gst: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label={"GST/VAT Type"}
                name={"gstType"}
                style={{ width: "100%" }}
              >
                <Radio.Group
                  onChange={(e) => {
                    setPackageData((previousData) => {
                      return {
                        ...previousData,
                        gstType: e.target.value,
                      };
                    });
                  }}
                >
                  <Radio value={"FLAT"}>is Flat</Radio>
                  <Radio value={"PERCENTAGE"}>is Percentage</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          )}
        </div>
        {/* <Divider orientation="left">For Office Only</Divider> */}
        <Collapse size="small" style={{ marginBottom: "1rem" }}>
          <Panel header="For Office Only" key="1">
            {packageData.packageType === "INSTITUTE" && (
              <div style={{ display: "flex", width: "100%", gap: "0.5rem" }}>
                <Form.Item
                  label="Commission"
                  name={"commission"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter unit price!",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Commission"
                    onChange={(e) => {
                      setPackageData((previousData) => {
                        return {
                          ...previousData,
                          commission: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label={"Commission Type"}
                  name={"commissionType"}
                  style={{ width: "100%" }}
                >
                  <Radio.Group
                    onChange={(e) => {
                      setPackageData((previousData) => {
                        return {
                          ...previousData,
                          commissionType: e.target.value,
                        };
                      });
                    }}
                  >
                    <Radio value={"FLAT"}>is Flat</Radio>
                    <Radio value={"PERCENTAGE"}>is Percentage</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            )}
            {packageData.packageType === "COMPANY" && (
              <div style={{ display: "flex", width: "100%", gap: "0.5rem" }}>
                <Form.Item
                  label="Commission"
                  name={"commission"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter unit price!",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Commission"
                    onChange={(e) => {
                      setPackageData((previousData) => {
                        return {
                          ...previousData,
                          commission: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label={"Commission Type"}
                  name={"commissionType"}
                  style={{ width: "100%" }}
                >
                  <Radio.Group
                    onChange={(e) => {
                      setPackageData((previousData) => {
                        return {
                          ...previousData,
                          commissionType: e.target.value,
                        };
                      });
                    }}
                  >
                    <Radio value={"FLAT"}>is Flat</Radio>
                    <Radio value={"PERCENTAGE"}>is Percentage</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            )}
            <Form.Item
              label="Currency"
              name={"currency"}
              style={{ width: "100%" }}
              // rules={[
              //   {
              //     required: true,
              //     message: "Please enter currency!",
              //   },
              // ]}
            >
              <Select
                defaultValue={
                  store.leadState.leadsDropdown?.defaultSelectedCurrency
                }
                onChange={(e) => {
                  setPackageData((previousData) => {
                    return {
                      ...previousData,
                      currency: e,
                    };
                  });
                }}
              >
                <Option>
                  {store.leadState.leadsDropdown?.defaultSelectedCurrency}
                </Option>
              </Select>
            </Form.Item>
          </Panel>
        </Collapse>
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Package
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddCasePackage;
