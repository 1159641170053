import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { MdOutlineDisabledVisible } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../../Components/Layout/SiderDemo";
import AddEducationVisa from "./AddEducationVisa";
import UpdateEducationVisa from "./UpdateEducationVisa";
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    align: "center",
  },
  {
    title: "Sub Class",
    dataIndex: "subClass",
    align: "center",
  },

  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
  },
];

const EducationVisa = () => {
  const educationVisasState = useSelector((state) => state.VisaClassReducer);
  const [isAddEducationVisaModalVisible, setIsAddEducationVisaModalVisible] =
    useState(false);
  const [
    isUpdateEducationVisaModalVisible,
    setIsUpdateEducationVisaModalVisible,
  ] = useState(false);
  const [record, setRecord] = useState({});

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "FETCH_EDUCATION_VISAS_REQUEST" });
  }, []);
  useEffect(() => {
    if (
      isAddEducationVisaModalVisible === false ||
      isUpdateEducationVisaModalVisible === false
    ) {
      dispatch({ type: "FETCH_EDUCATION_VISAS_REQUEST" });
    }
  }, [isAddEducationVisaModalVisible, isUpdateEducationVisaModalVisible]);
  //if (educationVisasType === "educationVisas") {
  const data = educationVisasState.educationVisas?.data?.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj.id,
      subClass: dataObj.subClass,

      actions: (
        <Space size="middle">
          <Tooltip title="Update educationVisas">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateEducationVisaModalVisible(true);
                  setRecord(dataObj);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete educationVisas">
            <Popconfirm
              title="Are you sure to delete this educationVisas?"
              onConfirm={() => {
                dispatch({
                  type: "DELETE_EDUCATION_VISAS_REQUEST",
                  payload: { id: dataObj.id },
                });
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a>
                <div className="bordered">
                  <DeleteOutlined />
                </div>
              </a>{" "}
            </Popconfirm>
          </Tooltip>
          {/* <Tooltip title="Toggle educationVisas status">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus =
                        dataObj.status=== "INACTIVE" ? "active" : "inactive";
                      dispatch({
                        type: "TOGGLE_EDUCATION_VISAS_STATUS_REQUEST",
                        payload: { id: dataObj.id, actionStatus },
                      });
                    }}
                  >
                    <MdOutlineDisabledVisible />
                  </div>
                </a>
              </Tooltip> */}
        </Space>
      ),
    };
  });
  //   } else {
  //     data = educationVisasState.activeEducationVisas?.data?.map((dataObj) => {
  //       return {
  //     key: dataObj.id,
  //     : dataObj.,
  //     : dataObj.,
  //     : dataObj.,
  //     : dataObj.,
  //     : dataObj.,
  //     : dataObj.,
  //     : dataObj.,
  //     : dataObj.,
  //         : dataObj.? (
  //           <Tag color="#00ff00aa">YES</Tag>
  //         ) : (
  //           <Tag color="#ff0000aa">NO</Tag>
  //         ),
  //         :
  //           dataObj.=== "ACTIVE" ? (
  //             <p className="greenTag">{dataObj.}</p>
  //           ) : (
  //             <p className="redTag">{dataObj.}</p>
  //           ),
  //         actions: (
  //           <Space size="middle">
  //             <Tooltip title="Update educationVisas">
  //               <a>
  //                 <div
  //                   className="bordered"
  //                   onClick={() => {
  //                     setIsUpdateEducationVisaModalVisible(true);
  //                     setRecord(dataObj);
  //                   }}
  //                 >
  //                   <EditOutlined />
  //                 </div>
  //               </a>
  //             </Tooltip>
  //             <Tooltip title="Delete educationVisas">
  //               <Popconfirm
  //                 title="Are you sure to delete this educationVisas?"
  //                 onConfirm={() => {
  //                   dispatch({
  //                     type: "DELETE_EDUCATION_VISAS_REQUEST",
  //                     payload: { id: dataObj.id },
  //                   });
  //                 }}
  //                 onCancel={() => {}}
  //                 okText="Yes"
  //                 cancelText="No"
  //               >
  //                 <a>
  //                   <div className="bordered">
  //                     <DeleteOutlined />
  //                   </div>
  //                 </a>{" "}
  //               </Popconfirm>
  //             </Tooltip>
  //             <Tooltip title="Toggle educationVisas status">
  //               <a>
  //                 <div
  //                   className="bordered"
  //                   onClick={() => {
  //                     const actionStatus =
  //                       dataObj.=== "INACTIVE" ? "active" : "inactive";
  //                     dispatch({
  //                       type: "TOGGLE_EDUCATION_VISAS_STATUS_REQUEST",
  //                       payload: { id: dataObj.id, actionStatus },
  //                     });
  //                   }}
  //                 >
  //                   <MdOutlineDisabledVisible />
  //                 </div>
  //               </a>
  //             </Tooltip>
  //           </Space>
  //         ),
  //       };
  //     });
  //   }
  console.log("from educationVisas state", educationVisasState);
  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div className="flexRow">
          <button
            className="button"
            onClick={() => {
              setIsAddEducationVisaModalVisible(true);
            }}
          >
            <span>Add Education Visa</span>
          </button>
        </div>
        <div style={{ marginTop: "2rem" }}>
          <Table bordered columns={columns} dataSource={data} />
        </div>
      </div>
      <AddEducationVisa
        isAddEducationVisaModalVisible={isAddEducationVisaModalVisible}
        setIsAddEducationVisaModalVisible={setIsAddEducationVisaModalVisible}
      />
      <UpdateEducationVisa
        record={record}
        isUpdateEducationVisaModalVisible={isUpdateEducationVisaModalVisible}
        setIsUpdateEducationVisaModalVisible={
          setIsUpdateEducationVisaModalVisible
        }
      />
    </SiderDemo>
  );
};

export default EducationVisa;
