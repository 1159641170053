import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
const { Option } = Select;

const FileUpload = ({
  item,
  driveType,
  parentId,
  isFileUploadModalVisible,
  setIsFileUploadModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectedFile, setSelectedFile] = useState();

  const fileUploadSubmitHandler = (value) => {
    console.log("from ", value);
    console.log(selectedFile, "selected file");
    const isRoot = parentId === undefined;
    let fileUploadData = new FormData();
    fileUploadData.append("fileContent", selectedFile);
    fileUploadData.append("fileName", value.fileName);
    parentId !== undefined && fileUploadData.append("parentId", parentId);
    fileUploadData.append("driveType", driveType);
    fileUploadData.append("isRoot", isRoot);
    console.log(
      selectedFile,
      fileUploadData.getAll("fileName"),
      "lien number 27"
    );
    dispatch({ type: "FILE_UPLOAD_REQUEST", payload: fileUploadData });
    form.resetFields();
    setIsFileUploadModalVisible(false);
  };
  console.log("from file upload parendtID", parentId);
  console.log("from file upload item", item);
  console.log("from file upload sf", selectedFile);
  return (
    <Modal
      title="Upload File"
      open={isFileUploadModalVisible}
      onCancel={() => {
        setIsFileUploadModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={fileUploadSubmitHandler}
        colon={true}
        form={form}
      >
        <Form.Item
          label="File Name"
          name={"fileName"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter a file name!",
            },
          ]}
        >
          <Input placeholder="fileName" />
        </Form.Item>
        <Form.Item
          label="File Content"
          name={"fileContent"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter file content!",
            },
          ]}
        >
          <input
            type={"file"}
            onChange={(e) => {
              console.log(e.target.files[0]);
              setSelectedFile(e.target.files[0]);
            }}
          />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Upload File
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FileUpload;
