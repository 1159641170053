import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  departments: {},
  activeDepartments: {},
};

const DepartmentReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_DEPARTMENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_DEPARTMENT_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        departments: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_DEPARTMENT_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_ACTIVE_DEPARTMENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_ACTIVE_DEPARTMENT_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        activeDepartments: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ACTIVE_DEPARTMENT_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_DEPARTMENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_DEPARTMENT_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Department added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_DEPARTMENT_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to add Department.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_DEPARTMENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_DEPARTMENT_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Department updated.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_DEPARTMENT_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to update Department.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_DEPARTMENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DELETE_DEPARTMENT_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Department deleted.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_DEPARTMENT_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to delete Department.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_DEPARTMENT_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "TOGGLE_DEPARTMENT_STATUS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Department status changed.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_DEPARTMENT_STATUS_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to change Department status.");

      message.error("error");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ASSIGN_ROLES_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ASSIGN_ROLES_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success!  Roles assigned to department.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ASSIGN_ROLES_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to assign roles to department");

      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default DepartmentReducer;
